<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';

@Options({
    name: 'DecimalFormatter',
})
export default class DecimalFormatter extends Vue
{
    @Prop() public property: number;
    @Prop() public features: KeyValuePair[];
    @Prop() public isOnGrid: boolean;
    @Prop() public isInTable: boolean;

    public get precision(): number
    {
        return Number.parseInt(this.features.find((p) => p.key == 'precision')?.value ?? '0');
    }

    public get prefix(): string
    {
        return this.features.find((p) => p.key == 'prefix')?.value ?? '';
    }

    public get suffix(): string
    {
        return this.features.find((p) => p.key == 'suffix')?.value ?? '';
    }

    public get value(): string
    {
        return this.property != null
            ? `${this.prefix} ${this.precision == 0 ? this.property : this.$filters.number(this.property, this.precision)} ${this.suffix}`
            : '-';
    }
}
</script>

<template>
    <span class="text-nowrap d-block" :class="{ 'text-end': isOnGrid || isInTable }">
        {{ value }}
    </span>
</template>
