<script lang="ts" setup>
import { computed, useSlots } from 'vue';

const props = defineProps({
  "label": null,
  "boldLabel": { type: Boolean, default: false },
  "boldValue": { type: Boolean, default: false },
  "tooltip": { default: '' },
  "colorLabel": { default: '' },
  "colorValue": { default: '' },
  "icon": { default: '' },
  "overflow": { type: Boolean,  }
});

const slots = useSlots();

const hasDefaultSlot = computed(() => slots.default);

const icon = computed(() => props.icon || '');
const label = computed(() => props.label || '');
const colorLabel = computed(() => `color: ${props.colorLabel || 'var(--bs-body-color)'}`);
const colorValue = computed(() => `color: ${props.colorValue || 'var(--bs-body-color)'}`);

const isIconCodeProper = computed(() =>
{
    const iconRegex = new RegExp('^fa[a-z-\\s]+$');

    return iconRegex.test(icon.value);
});
</script>

<template>
    <div class="details-label">
        <div :style="colorLabel" class="key" :class="{ bold: boldLabel }">
            <ideo-tooltip :tooltip="tooltip" position="right" class="d-inline-block">
                <span class="pe-1">{{ label }}:</span>
            </ideo-tooltip>
        </div>
        <div :style="colorValue" class="value" :class="{ bold: boldValue, 'overflow-hidden': overflow }">
            <ideo-tooltip :tooltip="tooltip" position="right" class="mw-100">
                <div class="pe-1">
                    <slot v-if="hasDefaultSlot" />
                    <span v-else>-</span>
                </div>
            </ideo-tooltip>
            <ideo-tooltip :tooltip="tooltip" position="right" class="mw-100">
                <i v-if="isIconCodeProper" class="ms-2 pe-1" :class="icon" />
            </ideo-tooltip>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.details-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: break-spaces;
    font-size: 0.8125rem;

    .key {
        width: clamp(100px, 150px, 230px);
        margin-right: clamp(10px, 20px, 30px);
    }

    .value {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .bold {
        font-weight: 600;
    }

    @media (min-width: 768px) {
        flex-direction: row;
    }

    @media (min-width: 1440px) {
        .key {
            width: clamp(100px, 170px, 230px);
        }
    }
}
</style>
