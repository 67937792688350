<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, AddressType, AddressEntryData } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { ApiQueryCondition, KeyElement, MatchQueryCondition, AndQueryCondition } from '@/helpers/ApiQueryBuilder';

defineOptions({
    name: `${Definition.type}-filter`
});

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});

const { $t } = useLocalization();

const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as AndQueryCondition);
const entry = computed<AddressEntryData>({
    get()
    {
        if (condition.value)
        {
            const clauses = condition.value.and.map(p => p as MatchQueryCondition);

            return {
                country: clauses.find(p => p.match.field.endsWith('Country'))?.match.value,
                province: clauses.find(p => p.match.field.endsWith('Province'))?.match.value,
                city: clauses.find(p => p.match.field.endsWith('City'))?.match.value,
                zip: clauses.find(p => p.match.field.endsWith('Zip'))?.match.value,
                street: clauses.find(p => p.match.field.endsWith('Street'))?.match.value,
                building: clauses.find(p => p.match.field.endsWith('Building'))?.match.value,
                apartment: clauses.find(p => p.match.field.endsWith('Apartment'))?.match.value,
            };
        }

        return {
            country: null,
            province: null,
            city: null,
            zip: null,
            street: null,
            building: null,
            apartment: null,
        };
    },
    set(value)
    {
        const clauses: MatchQueryCondition[] = [];

        if (condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                and: []
            });
        }

        if (value.country)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.Country`,
                    value: value.country
                }
            });
        }

        if (value.province)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.Province`,
                    value: value.province
                }
            });
        }

        if (value.city)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.City`,
                    value: value.city
                }
            });
        }

        if (value.zip)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.Zip`,
                    value: value.zip
                }
            });
        }

        if (value.street)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.Street`,
                    value: value.street
                }
            });
        }

        if (value.building)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.Building`,
                    value: value.building
                }
            });
        }

        if (value.apartment)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.Apartment`,
                    value: value.apartment
                }
            });
        }

        if (clauses.length > 0)
            condition.value.and = clauses;
        else
            reset();
    }
});
const country = computed({
    get: () => entry.value.country,
    set: (value) => entry.value = {...entry.value, country: value}
});
const province = computed({
    get: () => entry.value.province,
    set: (value) => entry.value = {...entry.value, province: value}
});
const city = computed({
    get: () => entry.value.city,
    set: (value) => entry.value = {...entry.value, city: value}
});
const zip = computed({
    get: () => entry.value.zip,
    set: (value) => entry.value = {...entry.value, zip: value}
});
const street = computed({
    get: () => entry.value.street,
    set: (value) => entry.value = {...entry.value, street: value}
});
const building = computed({
    get: () => entry.value.building,
    set: (value) => entry.value = {...entry.value, building: value}
});
const apartment = computed({
    get: () => entry.value.apartment,
    set: (value) => entry.value = {...entry.value, apartment: value}
});
const display = computed(() =>
{
    return Object.values(entry.value).filter(p => p != null).length > 0 ? $t('[[[Podano adres]]]') : null;
});
const label = computed(() => props.builder.localization.translate(component.value.label));

const reset = (): void =>
{
    model.value.remove(p => p.__key == component.value.id);
};
</script>

<template>
    <ideo-filter
        :value="entry"
        :display="display"
        :name="component.name"
        :label="label"
        :index="index"
        :visible="component.showFilter"
        menu-class="p-0"
        @clear="reset"
    >
        <div class="filter">
            <div class="row" v-if="component.hasStreet">
                <div class="col-12">
                    <input type="text" class="form-control py-3" v-model="street" :placeholder="$t(component.getStreetLabel())">
                </div>
            </div>
            <div class="row" v-if="component.hasBuilding || component.hasApartment">
                <div class="col" v-if="component.hasBuilding">
                    <input type="text" class="form-control py-3" v-model="building" :placeholder="$t(component.getBuildingLabel())">
                </div>
                <div class="col-5" v-if="component.hasApartment">
                    <input type="text" class="form-control py-3" v-model="apartment" :placeholder="$t(component.getApartmentLabel())">
                </div>
            </div>
            <div class="row" v-if="component.hasCity || component.hasZip">
                <div class="col" v-if="component.hasCity">
                    <input type="text" class="form-control py-3" v-model="city" :placeholder="$t(component.getCityLabel())">
                </div>
                <div class="col-5" v-if="component.hasZip">
                    <input type="text" class="form-control py-3" v-model="zip" :placeholder="$t(component.getZipLabel())">
                </div>
            </div>
            <div class="row" v-if="component.hasProvince">
                <div class="col" v-if="component.hasProvince">
                    <input type="text" class="form-control py-3" v-model="province" :placeholder="$t(component.getProvinceLabel())">
                </div>
            </div>
            <div class="row" v-if="component.hasCountry">
                <div class="col" v-if="component.hasCountry">
                    <input type="text" class="form-control py-3" v-model="country" :placeholder="$t(component.getCountryLabel())">
                </div>
            </div>
        </div>
    </ideo-filter>
</template>

<style lang="css" scoped>
.filter{
    overflow: hidden;
    border-radius: var(--bs-border-radius);
}
.row {
    --bs-gutter-x: 1px;
    --bs-gutter-y: 1px;
    background-color: var(--bs-border-color);
    margin-bottom: var(--bs-gutter-y);
}
.form-control, .form-control:focus {
    background-color: var(--bs-body-bg);
    border-radius: 0;
    border: none;
}
</style>
