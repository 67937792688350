<template>
    <component :is="tag" :class="customClasses">
        <slot name="default"></slot>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-dropdown-header'
})
export default class IdeoDropdownHeader extends Vue
{
    @Prop({ default: 'h6', validator: (value: string) => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value) })
    public tag: string;

    @Prop({
        default: null,
        validator: (value: string) => ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].includes(value)
    })
    public variant: string;

    public get customClasses(): Record<string, boolean>
    {
        return {
            'dropdown-header': !('class' in this.$attrs),
            [`text-${this.variant}`]: this.variant != null
        };
    }
}
</script>
