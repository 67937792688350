import { Plugin } from 'vue';
import { Settings } from 'luxon';

const DateTimePlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;

        vue.$config('i18n').then((config: any) =>
        {
            Settings.defaultZone = "local";
            Settings.defaultLocale = vue.$i18n.shortLocale();
        });
    }
};

export default DateTimePlugin;
