// Blueprints
import { Blueprint } from "../../base/blueprints/Blueprint";
import { Definition as TableDefinition } from '../../../forms/blueprints/logito-table';

// Entries
import { Entry } from "../entries/Entry";
import { RootEntry, RootEntryAbstract, instanceOfRootEntry } from "../entries/RootEntry";
import { EntryFactory, instanceOfEntryFactory } from "../traits/EntryFactory";
import { ValidEntry, instanceOfValidEntry } from "../entries/ValidEntry";

// Managers
import { SchemaManager } from "../../base/managers/SchemaManager";

// --------------------------------------------------

export class DocumentManager
{
    private root: RootEntry;
    private schema: SchemaManager;
    private identity: () => number;

    public constructor(schemaManager: SchemaManager)
    {
        this.schema = schemaManager;
        this.setRootEntry(null);
    }

    public get id(): number
    {
        return this.identity ? this.identity() : 0;
    }

    public setIdentity(callback: () => number): void
    {
        this.identity = callback;
    }

    public getRootEntry(): Entry
    {
        return this.root;
    }

    public setRootEntry(entry: Entry|null): void
    {
        const blueprint = this.schema.getBlueprint();

        if (instanceOfEntryFactory(blueprint))
        {
            if (entry instanceof RootEntryAbstract)
            {
                this.root = entry;
            }
            else
            {
                if (entry == null)
                    entry = blueprint.createEntry({});

                if (!instanceOfRootEntry(entry))
                    throw new Error("Argument 'entry' must be of type RootEntry.");

                entry = this.prepareRootEntry(entry as RootEntry);

                this.root = entry as RootEntry;
            }
        }
    }

    private prepareRootEntry(entry: RootEntry): RootEntry
    {
        const blueprint = this.schema.getBlueprint() as EntryFactory<any>;
        const root = blueprint.createEntry({});

        this.schema.components(null, blueprint).forEach(blueprint =>
        {
            if (instanceOfEntryFactory(blueprint))
            {
                const data = blueprint.name in entry ? entry[blueprint.name].data : null;
                const parent = this.schema.parent(blueprint);

                if (parent?.type !== TableDefinition.type)
                    root[blueprint.name] = blueprint.createEntry(data);

            }
        });

        return root;
    }

    public initEntry(blueprint: Blueprint & EntryFactory<any>, entry: any, typeCheck: (entry: any) => boolean, index: number = -1): any
    {
        const parent = this.schema.parent(blueprint);
        const root = parent?.type !== TableDefinition.type ? this.root : (this.root[parent.name] ??= []);

        if (!(blueprint.name in root && typeCheck(root[blueprint.name])))
        {
            if (index > -1)
                root.data[index][blueprint.name] = Object.assign(entry, root.data[index][blueprint.name]);
            else
                root[blueprint.name] = entry;
        }

        return index > -1 ? root.data[index][blueprint.name] : root[blueprint.name];
    }

    public getEntry(blueprint: Blueprint & EntryFactory<any>): Entry
    {
        if (!(blueprint.name in this.root))
            this.root[blueprint.name] = blueprint.createEntry(null);

        return this.root[blueprint.name];
    }

    public replaceEntry(blueprint: Blueprint & EntryFactory<any>, factory: (original: Entry) => Entry): Entry
    {
        return (this.root[blueprint.name] = factory(this.getEntry(blueprint)));
    }

    public renameEntry(name: string, old: string): void
    {
        if (old && old.length > 0 && old in this.root)
        {
            this.root[name] = this.root[old];

            delete this.root[old];
        }
    }

    public removeEntry(name: string): void
    {
        if (name in this.root)
        {
            delete this.root[name];
        }
    }

    public setErrors(errors: Record<string, string[]>): void
    {
        Object.keys(this.root).forEach(property =>
        {
            if (instanceOfValidEntry(this.root[property]))
            {
                const entry = this.root[property] as ValidEntry<any>;

                if (entry.type === TableDefinition.type)
                {
                    entry.data.forEach((row: any, index: number) =>
                    {
                        Object.keys(row).forEach(key =>
                        {
                            const name = `${property.toLowerCase()}.${index}.${key.toLowerCase()}.`;
                            const entry = row[key] as ValidEntry<any>;

                            entry.errors = {};

                            Object.entries(errors).filter(([key]) => key.startsWith(name)).forEach(([key, messages]) =>
                            {
                                entry.errors[key.substring(name.length)] = messages;
                            });
                        });
                    });
                }
                else
                {
                    const name = `${property.toLowerCase()}.`;

                    entry.errors = {};

                    Object.entries(errors).filter(([key]) => key.startsWith(name)).forEach(([key, messages]) =>
                    {
                        entry.errors[key.substring(name.length)] = messages;
                    });
                }
            }
        });
    }
}
