import { Blueprint, instanceOfBlueprint } from './Blueprint';

export interface AggregateBlueprint extends Blueprint
{
    components: Blueprint[];
}

export const instanceOfAggregateBlueprint = (object: any): object is AggregateBlueprint =>
{
    return instanceOfBlueprint(object) && 'components' in object;
};
