<script lang="ts" setup>
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, AttachmentsType, AttachmentsEntry } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});
</script>

<template>
    {{ $t('[[[Pliki: %0|||{0}]]]', (props.value?.length ?? 0).toString()) }}
</template>
