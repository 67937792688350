<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { KeyValuePair } from "@/helpers/Interfaces";
import { Options, Vue } from "vue-class-component";
import Participant from "@/modules/low-code/components/Participant.vue";

@Options({
    name: "UserGroupArray",
    components: { Participant },
})
export default class UserGroupArray extends Vue
{
    @Prop() public property: Record<string, string>[];
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;

    public getElements(targets: any[], visible: boolean = true): any[]
    {
        if (!targets) return [];

        return visible ? targets.slice(0, 5) : targets.slice(5);
    }
}
</script>

<template>
    <div v-if="Array.isArray(property)" class="d-flex align-items-center">
        <template v-if="property.length === 1">
            <UserAvatar
                :src="$filters.baseurl(`storage/files/${property[0].pictureUrl}`)"
                :username="`${property[0].value}`"
                rounded="circle"
                class="user-avatar"
            />
            <span class="ms-2 user-name">{{ property[0].value }}</span>
        </template>
        <template v-else>
            <Participant v-for="user in getElements(property)" :key="user.id" :user="{ user }" custom-class="cursor-pointer" />
            <IdeoDropdown
                v-if="getElements(property, false).length"
                boundary="body"
                variant="secondary"
                :top-offset="5"
                no-caret
            >
                <template #button-content>
                    <i class="fas fa-plus me-1"></i>
                    <span>{{ getElements(property, false).length }}</span>
                </template>
                <template #default>
                    <IdeoDropdownItem
                        v-for="(user, userIndex) in getElements(property, false)"
                        :key="userIndex"
                    >
                        <UserAvatar
                            :src="$filters.baseurl(`storage/files/${user?.pictureUrl}`)"
                            :username="user?.value"
                            :key="user?.pictureUrl"
                            class="cursor-pointer"
                        />
                        <div class="ms-2">
                            <p class="mb-0">
                                <strong class="text-primary cursor-pointer">
                                    <span>{{ user?.value }}</span>
                                </strong>
                            </p>
                        </div>
                    </IdeoDropdownItem>
                </template>
            </IdeoDropdown>
        </template>
    </div>
    <span v-else>-</span>
</template>
