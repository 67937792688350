<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from '@/helpers/Decorators';
import { ParticipantModel } from '@/modules/logito/office/services/RegisterService';
import Pager from '@/helpers/Pager';
import { Resource } from '@/helpers/Interfaces';
import Participant from './Participant.vue';
import ParticipantAdder from './ParticipantAdder.vue';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';
import EntityService from '@/modules/common/services/EntityService';
import { FieldContract } from '@/components/dynamic-details/blueprints/field/index';

@Options({
    components: { Participant, ParticipantAdder },
})
export default class ParticipantList extends Vue
{
    @Prop({ default: null, required: true }) public publicId: string;
    @Prop({ default: '', required: true }) public licence: string;
    @Prop({ default: false, required: true }) public canManageParticipants: boolean;
    @Prop({ default: null, required: true }) public blueprint: FieldContract;
    public limit: number = 5;
    public users: Resource<ParticipantModel>[] = [];
    public loading: boolean = false;
    public type: string = '';

    @Ref('add-participant')
    public addParticipantModal: () => IdeoModal;

    public get pager(): Pager
    {
        return new Pager(1, 20, '', 'DESC');
    }

    public async created(): Promise<void>
    {
        await this.getParticipantsList();
    }

    public async getParticipantsList(): Promise<void>
    {
        try
        {
            const { items } = await EntityService.getEntityParticipants(this.publicId, this.licence, this.pager);

            this.users = items;
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public async participantsAdded(): Promise<void>
    {
        this.addParticipantModal().hide();
        await this.getParticipantsList();
    }

    public async addParticipantsList(): Promise<void>
    {
        try
        {
            this.loading = true;
            this.$events.$emit('share');
            this.loading = false;
        }
        catch (ex)
        {
            this.loading = false;
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public async deleteParticipant(item: ParticipantModel): Promise<void>
    {
        try
        {
            this.loading = true;
            await EntityService.deleteParticipant(item.publicId);
            await this.getParticipantsList();
            this.$events.$emit('refetchData');
            this.loading = false;
        }
        catch (ex)
        {
            this.loading = false;
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }
}
</script>

<template>
    <div class="mb-0">
        <div class="participant d-flex flex-wrap align-items-center">
            <template v-if="users && users.length">
                <div v-for="(item, index) in users" :key="index">
                    <participant
                        :key="index"
                        v-if="!limit || index < limit"
                        :user="item.result"
                        :index="'participant-list-' + index"
                        :can-delete="canManageParticipants"
                        @delete="deleteParticipant(item.result)"
                    />
                </div>
            </template>
            <template v-else>
                <div class="me-2">
                    {{ '-' }}
                </div>
            </template>
            <template v-if="limit && users.length > limit">
                <div
                    @click="limit = null"
                    class="d-flex align-items-center me-1 cursor-pointer"
                    :title="$t('[[[Pokaż więcej]]]')"
                >
                    <span class="mb-1"> <i class="d-inline fas fa-plus"></i> {{ users.length - limit }} </span>
                </div>
            </template>
            <ideo-button
                v-if="canManageParticipants"
                variant="outline-light"
                :title="$t('[[[Udostępnij]]]')"
                @click="addParticipantModal().show()"
            >
                <i class="fas fa-share-alt text-primary icon-btn-bigger"></i>
            </ideo-button>
        </div>
        <ideo-modal ref="add-participant" :title="$t('[[[Udostępnij dla]]]')" centered>
            <template #default>
                <participant-adder
                    :public-id="publicId"
                    :licence="licence"
                    :event-bus="$events"
                    @submited="participantsAdded"
                    :type="type"
                    :share-for="blueprint.shareFor"
                />
            </template>
            <template #modal-footer="{ cancel }">
                <ideo-button variant="success" @click="addParticipantsList" :disabled="loading">
                    {{ $t('[[[Zapisz]]]') }}
                </ideo-button>
                <ideo-button variant="secondary" @click="cancel()">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>

<style lang="scss">
.participant {
    &__item {
        position: relative;
        margin-right: 15px;
    }

    .share__icon {
        text-decoration: none;

        i {
            top: -3px;
            left: -2px;
            font-size: 16px;
        }
    }
}
</style>
