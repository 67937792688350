<template>
    <fieldset class="d-flex flex-wrap gap-2" :class="customClass" :disabled="disabled">
        <slot name="default">
            <ideo-form-radio v-for="(option, i) in options" :key="i" :value="option[valueField]" :disabled="isDisabled(option)" :checked="currentValue" @change="onChange">
                {{ option[textField] }}
            </ideo-form-radio>
        </slot>
    </fieldset>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import IdeoFormRadio from './IdeoFormRadio.vue';

@Options({
    name: 'ideo-form-radio-group',
    components: {
        'ideo-form-radio': IdeoFormRadio
    }
})
export default class IdeoFormRadioGroup extends Vue
{
    public currentValue: any = null;

    @Prop()
    public checked: any;

    @Prop()
    public modelValue: any;

    @Prop({ default: 'value' })
    public valueField: string;

    @Prop({ default: 'text' })
    public textField: string;

    @Prop({ default: (): any => [] })
    public options: Record<string, any>[];

    @Prop({ default: false })
    public stacked: boolean;

    @Prop({ default: false })
    public disabled: boolean;

    public get customClass(): Record<string, boolean>
    {
        return {
            'flex-row': this.stacked === false,
            'flex-column': this.stacked !== false
        };
    }

    public isDisabled(option: Record<string, any>): boolean
    {
        if (this.disabled == true)
            return true;

        if ('disabled' in option)
            return option.disabled;

        return false;
    }

    public onChange(value: any): void
    {
        this.currentValue = value;
        this.updateModel();
    }

    @Emit('change')
    @Emit('update:modelValue')
    public updateModel(): any
    {
        return this.currentValue;
    }

    @Watch('checked', { immediate: true })
    public onCheckedChanged(value: any[]): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }

    @Watch('modelValue', { immediate: true })
    public onModelChanged(value: any[]): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }
}
</script>
