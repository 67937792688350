import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';

export const Definition: BlueprintDefinition = {
    type: 'line',
    name: '[[[Linia]]]',
    icon: 'fa-horizontal-rule',
    group: 'primary',
    position: 10
};

export interface LineContract extends Blueprint, HasWidth
{
}

export class LineType implements LineContract
{
    public id: string;
    public type: string;
    public name: string;
    public minWidth: number;
    public width: number;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.minWidth = 6;
        this.width = 6;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = 6;
    }
}
