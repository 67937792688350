<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { ListItemModel } from "@/modules/logito/staff/services/PeopleService";

@Options({
    name: 'StaffModal',
    emits: ['close']
})
export default class StaffModal extends Vue
{
    public opened: boolean = false;

    @Prop({ default: null })
    public user: ListItemModel;

    public getFacePictureUrl(item: ListItemModel): string
    {
        return item.facePicture && item.facePicture.url ? item.facePicture.url : '';
    }

    public get teamsIcon(): string
    {
        return '/static/img/microsoft-teams.svg';
    }

    public openModal(): void
    {
        this.opened = true;
    }

    public closeModal(): void
    {
        this.opened = false;
        this.$emit('close');
    }
}
</script>

<template>
    <Teleport to="#view">
        <div v-if="opened && user" class="staff-modal-overlay" @click.self="closeModal">
            <div class="staff-modal">
                <div class="staff-modal__content">
                    <button class="close-button" @click.stop="closeModal">
                        <i class="fas fa-close"></i>
                    </button>
                    <div class="left-side">
                        <UserAvatar
                            :src="$filters.baseurl(getFacePictureUrl(user))"
                            :username="user.name || ''"
                            :size="150"
                        />
                        <IdeoBadge v-if="user.absence" variant="success">
                            {{ user.absence.type.value }}
                        </IdeoBadge>
                        <a :href="`https://teams.microsoft.com/l/chat/0/0?users=${user.email}`" target="_blank" :title="$t('[[[Wyślij wiadomość Microsoft Teams]]]')">
                            <img :src="teamsIcon" style="height:25px;width:25px;">
                        </a>
                    </div>
                    <div class="right-side">
                        <div class="main-info mb-3">
                            <p class="right-side__username">{{ user.name }}</p>
                            <p v-if="user.jobPosition">{{ user.jobPosition.value }}</p>
                            <IdeoBadge v-if="user.unit" variant="dark">
                                {{ user.unit.value }}
                            </IdeoBadge>
                        </div>
                        <div class="details-group">
                            <label>{{ $t('[[[Pokój]]]') }}:</label>
                            <span>{{ user.roomNumber }}</span>
                        </div>
                        <div class="details-group">
                            <label>{{ $t('[[[Email]]]') }}:</label>
                            <a :href="`mailto:${user.email}`">{{ user.email }}</a>
                        </div>
                        <div class="details-group">
                            <label>{{ $t('[[[Tel.]]]') }}:</label>
                            <span>{{ user.phoneNumber }}</span>
                        </div>
                        <div class="details-group">
                            <label>{{ $t('[[[Kom.]]]') }}:</label>
                            <span>{{ user.cellNumber }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
.dark-theme{
    .close-button{
        color: #afbdd1;
    }
}

.staff-modal-overlay {
    width: 100%;
    height: 100%;
    z-index: 10020;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(var(--bs-black-rgb), 0.5);
    display: flex;
    justify-content: center;
    align-items: start;
}

.staff-modal {
    margin-top: 100px;
    border-radius: 5px;
    overflow: hidden;
    min-width: 90%;
    box-shadow: 5px 1px 59px -33px var(--modal-shadow);
    border: 1px solid var(--user-card-border);
    background: var(--bs-white);

    @include media-breakpoint-up(sm)
    {
        min-width: 650px;
        margin-top: 150px;
    }

    &__content{
        display: flex;
        position: relative;
        flex-direction: column;

        @include media-breakpoint-up(sm){
            flex-direction: row;
        }

        .close-button{
            background: transparent;
            border: 0;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 1.5rem;
            z-index: 50;
            padding: 4px;
        }

        .left-side {
            padding: 15px 35px;
        }

        .right-side {
            padding: 30px;
        }

        .left-side{
            background-color: var(--left-side-color);
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.9375rem;
        }

        .right-side{
            background-color: var(--right-side-color);
            color: var(--right-side-font);
            position: relative;
            font-size: .875rem;
            flex-grow: 3;

            p {
                margin-bottom: .3125rem;
            }

            a {
                font-weight: 600;
                color: var(--bs-primary);
            }

            &__username{
                font-weight: 600;
                font-size: 1rem;
            }

            .details-group{
                display: flex;

                label {
                    margin-bottom: 0.5rem;
                    flex-basis: 42px;
                    margin-right: 0.5rem;
                }

                span {
                    font-weight: 600;
                }

                &:last-of-type {
                    label {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
