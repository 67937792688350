<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';
import { DateFormatterEnum } from '@/components/common/dynamic-grid/helpers/GridEnums';

@Options({
    name: 'DateTimeFormatter',
})
export default class DateTimeFormatter extends Vue
{
    @Prop({ default: '' }) public property: string;
    @Prop({ default: (): KeyValuePair[] => ([]) }) public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;

    public format: string = 'dd-MM-yyyy HH:mm';
    public zone: string = 'local';

    public get templateContent(): Record<string, any>
    {
        const attributes: Record<string, any> = {};

        const DateSettings = this.features.find((feature) => feature.key === DateFormatterEnum.DateTime);

        if (DateSettings)
        {
            const value: Record<string, any> = JSON.parse(DateSettings.value);

            attributes.is = 'span';
            this.format = value.format;
            this.zone = value.zone;
        }
        else
        {
            attributes.is = 'span';
        }

        return attributes;
    }
}
</script>

<template>
    <div>
        <component :is="templateContent.is" v-bind="{ ...templateContent }" class="text-nowrap">
            {{ $filters.datetime(property, format, zone) || '-' }}
        </component>
    </div>
</template>
