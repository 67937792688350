enum DictionaryType {
    Invalid = 0,
    Roles = 1,
    Actions = 2,
    Members = 3,
    Statuses = 4,
    Conditions = 5,
    Endpoints = 6,
    Variables = 11,
}

enum GatewayTypes {
    Invalid = 'Invalid',
    DecisionGate = 'DecisionGate',
    SingleCheckGate = 'SingleCheckGate',
    LiveCheckGate = 'LiveCheckGate',
    ParallelGate = 'ParallelGateway',
    ExclusiveGate = 'ExclusiveGateway',
    InclusiveGate = 'InclusiveGateway',
    Fetch = 'Fetch',
    Join = 'Join',
}

enum FormButtonStyle {
    Invalid = 'Invalid',
    Acceptance = 'Acceptance',
    Return = 'Return',
    Rejection = 'Rejection',
}

enum AbsenceTypeColor {
    Accepted = 'success',
    AwaitingAccept = 'blue',
}

export { DictionaryType, GatewayTypes, FormButtonStyle, AbsenceTypeColor };
