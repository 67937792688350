<template>
    <div>
        <div v-if="original && (original.value || original.title)" class="original-value small">
            <span v-if="original.value || original.title">{{ original.value || original.title }}, </span>
            <span>{{ original.module }}</span>
        </div>
        <div v-if="current && (current.value || current.title)" class="current-value small">
            <span v-if="current.value || current.title">{{ current.value || current.title }}, </span>
            <span>{{ current.module }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';
import { RelatedDocument } from '@/modules/core/forms/services/FormsService';

@Options({
    name: 'DynamicDocumentFormatter'
})
export default class DynamicDocumentFormatter extends Vue
{
    @Prop({ default: null }) public original: RelatedDocument;
    @Prop({ default: null }) public current: RelatedDocument;
    @Prop() public definition: ChangelogDefinitionModel;
}
</script>
