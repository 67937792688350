<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { FormEntry } from '../form';
import { LogitoAttachmentsEntry, LogitoAttachmentsType, instanceOfLogitoAttachmentsEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import properties from '../../properties';

defineOptions({
    name: 'logito-attachments-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const entryData = ref(new LogitoAttachmentsEntry()) as Ref<LogitoAttachmentsEntry>;

entryData.value = props.form.document.initEntry(
    props.blueprint,
    entryData.value,
    instanceOfLogitoAttachmentsEntry,
    props.index
);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value;
    },
    set(values: any[])
    {
        if (values == null)
            values = [];

        if (!Array.isArray(values))
            values = [values];

        entryData.value.data = values;
    }
});

const blueprint = computed(() => props.blueprint);
const required = computed(() => props.form.expressions.required(props.blueprint));
const extensions = computed(() => props.blueprint.extensions.split(',').map(p => p.trim()).filter(p => !!p));
</script>

<template>
    <form-component-wrapper class="logito-attachments-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <fileupload
                    v-model="value"
                    :id="blueprint.id"
                    :max-size="blueprint.maxFileSize"
                    :max-files="blueprint.maxFiles"
                    :extensions="extensions"
                    :dark="false"
                    :data-test="blueprint.id"
                ></fileupload>
                <form-error-message :entry="entryData" name="values" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-text
                v-model="blueprint.extensions"
                :label="$t('[[[Dozwolone rozszerzenia]]]')"
                placeholder="jpg, jpeg, png"
            />
            <field-numeric
                v-model="blueprint.maxFileSize"
                :label="$t('[[[Maksymalny rozmiar pliku w MB]]]')"
                :min="0"
                :max="100"
            />
            <field-numeric
                v-model="blueprint.maxFiles"
                :label="$t('[[[Maksymalna ilość plików]]]')"
                :min="1"
                :max="60"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
