<script lang="ts" setup>
import { computed, watch } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { handleException } from '@/helpers/Utils';
import Pager from '@/helpers/Pager';
import FieldSelect from './FieldSelect.vue';
import FormsService from '@/modules/studio/forms/services/FormsService';
import { computedAsync } from '@vueuse/core';

defineOptions({
    name: 'field-form',
    components: {
        'field-select': FieldSelect
    }
});

const model = defineModel<number|null>();
const props = defineProps({
  "label": null,
  "category": null,
  "invalidFeedback": { type: Function, default: null },
  "required": { type: Boolean, default: false },
  "disabled": { type: Boolean, default: false }
});

const { $alert } = useAlerts();
const { $log } = useLogging();
const category = computed(() => props.category);

watch(category, (value: number, old: number): void =>
{
    if (value != old)
    {
        model.value = null;
    }
});

const options = computedAsync(async () =>
{
    try
    {
        const pager = new Pager(1, 10000, 'Title', 'ASC');
        const response = await FormsService.getOptions(pager, props.category, null, null);

        return [{ value: null, text: '' }, ...response.items];
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });

        return [];
    }
});
</script>

<template>
    <field-select
        v-model="model"
        :options="options"
        :label="props.label"
        :invalid-feedback="props.invalidFeedback"
        :required="props.required"
        :disabled="props.disabled"
        v-if="options"
    />
</template>
