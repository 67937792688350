<template>
    <ideo-modal ref="import-lines-modal" :title="$t('[[[Importuj]]]')" centered>
        <template #default>
            <ideo-form-group :label="$t('[[[Importuj linie opisu z pliku]]]')" required>
                <fileupload v-model="importFile" :max-files="1"></fileupload>
            </ideo-form-group>
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button name="btnConfirmAccept" variant="success" @click="importJson" :disabled="importing || !importFile">
                {{ $t('[[[Importuj]]]') }}
            </ideo-button>
            <ideo-button variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from '@/helpers/Decorators';
import InvoiceService from "@/modules/low-code/services/InvoicesService";
import IdeoModal from "@/components/ideo/modal/IdeoModal.vue";
import { Resource as FileResource } from "@/modules/core/files/services/StorageService";

@Options({
    components: {},
    emits: ['reload'],
})

export default class ImportLinesModal extends Vue
{
    @Prop({ default: '' }) private documentId: string;
    @Prop({ default: null }) private endpointId: number;
    @Prop({ default: '' }) private module: string;

    private importFile: FileResource = null;
    private importing: boolean = false;

    @Ref('import-lines-modal')
    private documentModal: () => IdeoModal;

    public mounted(): void
    {
        this.$events.$on('import-lines-modal', (item: number) =>
        {
            this.documentModal().show();
        });
    }

    private async importJson(): Promise<void>
    {
        // if (!this.importFile || !this.importFile.upload)
        //     return;

        try
        {
            this.importing = true;
            await InvoiceService.importLines(this.module, this.documentId, this.importFile.upload);

            this.$alert.success(this.$t('[[[Import zakończony pomyślnie.]]]'));

            this.documentModal().hide();
            this.$emit('reload');
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [this.$alert.warning(this.$t(Object.values(ex.data.errors).find(error => error).toString()))]
            });
        }
        finally
        {
            this.importing = false;
        }
    }

    public unmounted(): void
    {
        this.$events.$off('import-lines-modal');
    }
}
</script>

<style lang="scss">
</style>
