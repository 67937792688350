<script lang="ts" setup>
import { ref, computed, watch, Ref } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useClipboard } from '@vueuse/core';
import { KeyValue } from '@/helpers/Interfaces';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { RegisterType, RegisterEntry, instanceOfRegisterEntry } from '.';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { Definition as CompanyDefintion } from '../logito-company';
import { Definition as DateDefinition } from '../date';
import RegisterDocumentsService from '@/modules/core/register-documents/services/RegisterDocumentsService';
import properties from '../../properties';
import { DateTime } from 'luxon';
import { isEqual } from 'lodash';

defineOptions({
    name: 'register-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $alert } = useAlerts();
const { $t } = useLocalization();
const { copy, copied } = useClipboard();

const entryData = ref(new RegisterEntry()) as Ref<RegisterEntry>;
const signatureReserved = ref('');
const disableReservation = ref(true);

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfRegisterEntry, props.index);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value;
    },
    set(value: KeyValue<string, string> | null)
    {
        entryData.value.data = value;
    },
});

const blueprint = computed(() => props.blueprint);
const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const required = computed(() => props.form.expressions.required(props.blueprint));
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));
const design = computed(() => props.form.designMode());
const endpoint = computed(() =>
    !design.value
        ? `simple-dictionary/${props.entry.module}/form/${props.entry.formId}/${props.entry.actionName}/${blueprint.value.name}`
        : null
);
const isStart = computed(() => props.entry.actionName === 'bpmn-start');

const components = computed(() =>
    props.form.schema
        .components([CompanyDefintion.type], blueprint.value)
        .map((p) => ({ value: p.name, text: props.form.localization.translate((p as HasLabel).label) }))
);

const dateFields = computed(() =>
    props.form.schema
        .components([DateDefinition.type], blueprint.value)
        .map((p) => ({ value: p.name, text: props.form.localization.translate((p as HasLabel).label) }))
);

const scopeBindWith = computed(() =>
{
    if (blueprint.value.bindWith)
    {
        return components.value
            .where((p) => blueprint.value.bindWith.includes(p.value))
            .select((p) => ({ [p.value]: props.entry[p.value]?.data?.key ?? '0' }))
            .toArray()
            .first();
    }

    return {};
});

const scopeSignaturableDateField = computed(() =>
{
    if (blueprint.value.signaturableDateField)
    {
        return dateFields.value
            .where((p) => blueprint.value.signaturableDateField.includes(p.value))
            .select((p) => ({ [p.value]: props.entry[p.value]?.data ?? DateTime.utc() }))
            .toArray()
            .first();
    }

    return {};
});

const issueDate = computed(() =>
{
    if (!blueprint.value.signaturableDateField)
        return DateTime.utc();

    return scopeSignaturableDateField.value[blueprint.value.signaturableDateField];
});
const signaturableDateFieldOptions = computed(() => ([{ value: null, text: '' }, ...dateFields.value]));

const reserveSignature = async () =>
{
    try
    {
        const response = await RegisterDocumentsService.reserveSignature(value.value.key, issueDate.value);

        disableReservation.value = true;
        signatureReserved.value = response;
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.danger($t('[[[Błąd podczas rezerwacji]]]'));
        else if (ex.code === 422)
        {
            const errors = ex.data.errors;

            $alert.warning(errors?.registerId?.[0] ?? $t('[[[Błąd podczas rezerwacji]]]'));
        }
    }
};

const getRegisterReservation = async () =>
{
    try
    {
        signatureReserved.value = '';
        disableReservation.value = true;

        if (value.value == null || value.value.key == null || issueDate.value == null) return;

        const response = await RegisterDocumentsService.getReservation(value.value.key, issueDate.value);

        if (response == null || response == '') disableReservation.value = false;
        else disableReservation.value = true;

        signatureReserved.value = response;
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.danger($t('[[[Błąd podczas eksportowania]]]'));
    }
};

const onRegisterChanged = async () =>
{
    await getRegisterReservation();
};

watch(scopeBindWith, (newValue, oldValue) =>
{
    if (isEqual(newValue, oldValue))
        return;

    value.value = null;
    getRegisterReservation();
});

watch(scopeSignaturableDateField, (newValue, oldValue) =>
{
    if (isEqual(newValue, oldValue))
        return;

    getRegisterReservation();
});
</script>

<template>
    <form-component-wrapper class="register-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <div class="d-flex">
                    <ideo-select
                        v-model="value"
                        :id="blueprint.id"
                        :disabled="readonly"
                        :placeholder="placeholder"
                        :endpoint="endpoint"
                        :preselect-when-one-option="isStart"
                        :data-test="blueprint.id"
                        :narrow-options="scopeBindWith"
                        class="flex-fill"
                        @update:modelValue="onRegisterChanged"
                    />
                    <ideo-button
                        variant="primary"
                        icon="fas fa-sort-numeric-down-alt"
                        class="ms-2"
                        :disabled="disableReservation"
                        @click="reserveSignature"
                    />
                </div>
                <div v-if="signatureReserved?.length" class="d-flex mt-1">
                    <ideo-tooltip
                        :tooltip="!copied ? $t('[[[Kopiuj sygnaturę]]]') : $t('[[[Sygnatura skopiowana]]]')"
                        position="right"
                    >
                        <span class="me-1 text-primary cursor-pointer" @click="copy(signatureReserved)">
                            {{ signatureReserved }}
                        </span>
                    </ideo-tooltip>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <field-select
                v-model="blueprint.bindWith"
                :label="$t('[[[Powiąż ze spółką]]]')"
                :options="components"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'bindWith')"
            />
            <field-select
                v-model="blueprint.signaturableDateField"
                :label="$t('[[[Numeruj używając licznika wg daty]]]')"
                :options="signaturableDateFieldOptions"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'signaturableDateField')"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>
