import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { KeyValuePair, KeyValue } from '@/helpers/Interfaces';

export const Definition: BlueprintDefinition = {
    type: 'user-group',
    name: '[[[Grupa użytkowników]]]',
    icon: 'fa-solid fa-person',
    group: 'logito',
    position: 5,
};

export enum UserGroupTypes {
    Unit = 1,
    Team,
    ProcessGroup,
}

export interface UserGroupContract
    extends Blueprint,
    VisibleBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasWidth,
    HasPlaceholder,
    HasHelp {
    multiselect: boolean;
    groupTypes: any;
}

export interface KeyValueWithType extends KeyValue<string, string> {
    type: UserGroupTypes;
}

export class UserGroupEntry extends ValidEntry<KeyValueWithType[]>
{
    public type: string = Definition.type;
    public data: KeyValueWithType[] = [];
    public settings: KeyValue<UserGroupTypes, string> = null;

    public constructor(data: any = null, settings: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(
        blueprint: UserGroupContract,
        form: FormBuilderContract,
        preprocess: ProcessCallback
    ): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        const value = this.data;

        return entry(value);
    }

    public validate(blueprint: UserGroupContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const value = this.data;

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && (value === null || !value.length))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }
}

export const instanceOfUserGroupEntry = (object: any): object is UserGroupEntry =>
{
    return object && 'type' in object && object.type === Definition.type;
};

export class UserGroupType implements UserGroupContract, ValidatableBlueprint, EntryFactory<UserGroupEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public placeholder: Record<string, string>;
    public errors: ValidationErrors;
    public dataSource: string | KeyValuePair;
    public help: Record<string, string>;
    public multiselect: boolean;
    public groupTypes: any;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
        this.showLabel = true;
        this.label = { 'pl-PL': 'Grupa użytkowników' };
        this.placeholder = { 'pl-PL': 'Wybierz...' };
        this.required = AlwaysChoice.Always;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.help = {};
        this.errors = {};
        this.multiselect = false;
        this.groupTypes = this.newGroupTypes();
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): UserGroupEntry
    {
        return new UserGroupEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (Object.values(this.groupTypes ?? {}).every((v) => v === false))
        {
            this.errors.groupTypes = ['[[[Musisz wybrać przynajmniej jedną grupę.]]]'];
        }

        return {
            [this.name]: this.errors,
        };
    }

    private newGroupTypes(): any
    {
        const data = Object.values(UserGroupTypes).filter((v) => isNaN(Number(v)));

        const groupTypes: any = {};

        data.forEach((p) => (groupTypes[p] = true));

        return groupTypes;
    }
}
