<template>
    <div class="d-flex align-items-center">
        <div v-if="Array.isArray(formatedOriginal) && formatedOriginal.length" class="d-flex align-items-center">
            <Participant
                v-for="(user, userIndex) in formatedOriginal"
                :key="userIndex"
                :user="user"
                :index="'change-log-original-' + userIndex"
                :removed="true"
            />
        </div>

        <div v-if="Array.isArray(formatedCurrent) && formatedCurrent.length" class="d-flex align-items-center">
            <Participant
                v-for="(user, userIndex) in formatedCurrent"
                :key="userIndex"
                :user="user"
                :index="'change-log-current-' + userIndex"
                :added="true"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { KeyValuePair } from "@/helpers/Interfaces";
import { Options, Vue } from "vue-class-component";
import Participant from "@/modules/low-code/components/Participant.vue";
import { ParticipantModel } from "@/modules/logito/office/services/RegisterService";

@Options({
    name: "UserFormatterArray",
    components: { Participant },
})
export default class UserFormatterArray extends Vue
{
    @Prop()
    public original: KeyValuePair;

    @Prop()
    public current: KeyValuePair;

    public get formatedOriginal(): ParticipantModel[]
    {
        if (Array.isArray(this.original))
        {
            return this.original.map((item) => ({ user: item, audit: null, publicId: null, roles: null }));
        }

        return [];
    }

    public get formatedCurrent(): ParticipantModel[]
    {
        if (Array.isArray(this.current))
        {
            return this.current.map((item) => ({ user: item, audit: null, publicId: null, roles: null }));
        }

        return [];
    }
}
</script>
