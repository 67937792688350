<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from "@/helpers/Decorators";
import ModulesService from '@/modules/low-code/services/ModulesService';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

@Options({
    components: {  }
})
export default class ParticipantList extends Vue
{
    @Prop({ default: null, required: true }) public publicId: string;
    @Prop({ default: "", required: true }) public licence: string;
    @Prop({ default: false, required: true }) public canToggle: boolean;
    @Prop({ default: false }) public value: boolean;

    @Ref('toggle-private-modal')
    public togglePrivateModal: () => IdeoModal;

    public get tooltipText(): string
    {
        let tooltip = this.value == true
            ? 'Dokument Prywatny - nie jest widoczny dla osób pracujących w zastępstwie innej osoby.'
            : 'Dokument nie jest Prywatny - jest widoczny dla osób pracujących w zastępstwie innej osoby.';

        if (!this.canToggle)
            tooltip += `\n\nNie masz dostępu do zmiany prywatności dla tego dokumentu`;

        return tooltip;
    }

    public async togglePrivate(): Promise<void>
    {
        try
        {
            await ModulesService.toggleIsPrivate(this.licence, this.publicId);
            this.togglePrivateModal().hide();
            this.$events.$emit("refetchData");
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message)
            });
        }
    }
}
</script>

<template>
    <ideo-tooltip :tooltip="tooltipText" :multiline="true">
        <div v-if="value">
            <i :class="`fa-solid fa-lock-keyhole closed ${canToggle ? 'can-toggle' : ''}`" @click.stop="canToggle && togglePrivateModal().show()" />
        </div>
        <div v-else>
            <i :class="`fa-solid fa-lock-keyhole-open open ${canToggle ? 'can-toggle' : ''}`" @click.stop="canToggle && togglePrivateModal().show()" />
        </div>
    </ideo-tooltip>
    <ideo-modal ref="toggle-private-modal" :title="$t('[[[Zmiana prywatności]]]')" centered>
        <template #default>
            {{
                value == true
                    ? $t('[[[Czy chcesz usunąć ograniczenie dostępu do dokumentu w zastępstwie?]]]')
                    : $t('[[[Czy chcesz ograniczyć dostęp do dokumentu w zastępstwie?]]]')
            }}
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button variant="success" @click.stop="togglePrivate()">
                {{ $t('[[[Zapisz]]]') }}
            </ideo-button>
            <ideo-button variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>

<style lang="scss">
.closed {
    font-size: 20px;
}

.open {
    font-size: 20px;
    color: lightgrey;
}

.can-toggle {
    cursor: pointer;
}
</style>
