<script lang="ts" setup>
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, DictionaryType } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});
</script>

<template>
    <span class="badge bg-success me-1" v-if="props.value?.Text">
        {{ props.value?.Text }}
    </span>
    <span v-else>-</span>
</template>
