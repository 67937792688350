<script lang="ts" setup>
import { ref, reactive, computed, onMounted, type Ref } from 'vue';
import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { persistState, restoreState } from '@/plugins/viewstate';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';
import { handleException } from '@/helpers/Utils';
import { PublicationOptions, PublicationStyle } from '@/helpers/Enums';
import { FormContract } from '@/components/forms/blueprints/form';
import { FluentFormBuilder } from '@/components/forms';
import FormsService from '@/modules/studio/forms/services/FormsService';
import DocumentsService, { FilterModel, ListItemModel, checkPermissions } from '../../services/DocumentsService';
import { FeaturePermissions, SectionItem } from '../../../modules/services/types';
import { instanceOfHasPresenter } from '@/components/builder/form/traits/HasPresenter';

const props = defineProps({
  "symbol": null,
  "section": null,
  "routeKey": null,
  "embedded": { type: Boolean, default: false },
  "preview": { type: Boolean, default: false }
});

const emit = defineEmits(["add", "edit"]);

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();

const loaded = ref(false);
const toolbar = computed(() => props.embedded ? 'header' : 'toolbar');
const section = computed(() => props.section);
const columns = computed(() => section.value.columns);
const filter = reactive(Form.create<FilterModel>({
    dateFromUtc: null,
    dateDueUtc: null,
    onlyMine: false,
    status: null,
    builder: {
        fields: ["*"],
        query: [],
        pagination: {
            index: 1,
            size: 20
        },
        sort: {
            field: 'Id',
            order: 'ASC'
        },
        populate: [
            'DateCreatedUtc',
            'Status',
            'Version'
        ],
        publication: 'All'
    }
}));
const pager = reactive(new Pager(1, 20, 'Id', 'ASC')) as Pager;
const items = ref<ListItemModel[]>([]);
const itemsMeta = ref(new Map<number, Record<string, boolean>>());
const permissions = ref<Record<string, boolean>>({});
const versionable = ref<boolean>(false);

const blueprint = ref(null) as Ref<FormContract>;
const builder = ref(FluentFormBuilder
    .setup(false, true)
    .blueprint(() => blueprint.value, () => section.value.formId)
    .entry(() => null, () => 0)
    .make());

const sortingOptions = computed(() => ([
    { value: 'Id', text: '[[[Id]]]' },
    { value: 'DateCreatedUtc', text: '[[[Data dodania]]]' }
]));

const routeKey = computed(() => props.routeKey);

onMounted(async () =>
{
    restoreState(routeKey.value, filter, pager, columns.value);

    await loadForm();
    await loadData();
});

function allowed(feature: FeaturePermissions, meta: Record<string, boolean> = null)
{
    return checkPermissions(meta || permissions.value, feature);
}

async function loadForm()
{
    try
    {
        const result = await FormsService.fetchPublished(section.value.formId);

        versionable.value = result.isVersionable;
        blueprint.value = result.definition;
        builder.value.update();
        blueprint.value = builder.value.getBlueprint() as FormContract;

        permissions.value = await DocumentsService.indexMeta(props.symbol, props.section.symbol);
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function loadData()
{
    loaded.value = false;
    items.value = [];

    if (!props.symbol || !props.section.formId || !blueprint.value || props.preview)
    {
        pager.setTotalRows(0);
        items.value = [];

        return;
    }

    try
    {
        filter.builder.sort.field = pager.getSort();
        filter.builder.sort.order = pager.getOrder() as any;
        filter.builder.pagination.index = pager.getPageIndex();
        filter.builder.pagination.size = pager.getPageSize();
        filter.builder.fields = builder.value.schema.components()
            .filter(c => columns.value.enabled?.includes(c.name))
            .map(c => instanceOfHasPresenter(c) ? c.getColumnExpression() : c.name)
            .filter(p => p != null);

        const response = await DocumentsService.index(props.symbol, props.section.symbol, filter.data(), pager);

        pager.setTotalRows(response.totalRows);
        items.value = response.items;
    }
    catch (ex)
    {
        pager.setTotalRows(0);
        items.value = [];
        $log.debug(ex);
    }
    finally
    {
        persistState(routeKey.value, filter, pager, columns.value);
        loaded.value = true;
    }
}

function gotoForm(item: ListItemModel = null)
{
    if (item != null)
        emit('edit', item.meta.Version);
    else
        emit('add');
}

async function deleteItem(item: ListItemModel)
{
    try
    {
        await DocumentsService.remove(props.symbol, props.section.symbol, item.meta.Version);
        await loadData();

        $alert.success($t('[[[Dokument został usunięty.]]]'));
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function exportData()
{
    loaded.value = false;

    try
    {
        await DocumentsService.exportToExcel(props.symbol, props.section.symbol, filter.data());
    }
    catch (ex)
    {
        $log.debug(ex);
    }
    finally
    {
        loaded.value = true;
    }
}

async function loadMeta(item: ListItemModel)
{
    try
    {
        if (!itemsMeta.value.has(item.meta.Version))
        {
            const meta = await DocumentsService.fetchMeta(props.symbol, props.section.symbol, item.meta.Version);

            itemsMeta.value.set(item.meta.Version, meta);
        }
    }
    catch (ex)
    {
        itemsMeta.value.set(item.meta.Version, {});
        $log.debug(ex);
    }
}

async function onEdit(item: ListItemModel)
{
    await loadMeta(item);

    if (allowed(section.value.features.update, itemsMeta.value.get(item.meta.Version)))
    {
        gotoForm(item);
    }
    else
    {
        $alert.warning($t('[[[Nie masz uprawnień do tego dokumentu]]]'));
    }
}

async function reload()
{
    await loadData();
}

defineExpose({
    reload
});
</script>

<template>
    <data-card :sticky-footer="!props.embedded">
        <!-- Header -->
        <template #[toolbar]>
            <ideo-panel :column="false" :stretch="false" :start="true">
                <template #start>
                    <action-bar :auto-header="false">
                        <action-button
                            variant="primary"
                            icon="fas fa-plus"
                            :text="$t('[[[Dodaj dokument]]]')"
                            :disabled="!allowed(section.features.create)"
                            @click="gotoForm()"
                            v-if="section.settings.canAdd"
                        />
                    </action-bar>
                    <ideo-dropdown no-icon right :class="{'ms-3': desktop}" v-if="section.settings.canExport">
                        <template #button-content>
                            <i class="fa-regular fa-ellipsis-vertical"></i>
                        </template>
                        <ideo-dropdown-item @click="exportData()">
                            <i class="far fa-file-export"></i>
                            {{ $t('[[[Eksport do Excela]]]') }}
                        </ideo-dropdown-item>
                    </ideo-dropdown>
                </template>
                <ideo-inline-filters
                    :filter="filter"
                    :settings="section.filters"
                    :advanced="!props.embedded"
                    :route-key="routeKey"
                    @change="loadData()"
                    v-if="section.settings.enableFilters && section.formId > 0"
                >
                    <ideo-filter-checkbox v-model="filter.onlyMine" name="onlyMine" :label="$t('[[[Moje dokumenty]]]')" />
                    <ideo-filter-select v-model="filter.status" name="status" :label="$t('[[[Status]]]')" :options="PublicationOptions()" value-field="value" text-field="text" :size="5" v-if="versionable" />
                    <ideo-filter-datetime v-model:gte="filter.dateFromUtc" v-model:lte="filter.dateDueUtc" name="dateRange" :label="$t('[[[Data dodania]]]')" :range="true" :multi="true" :visible="false" />
                    <form-filters v-model="filter.builder.query" :builder="builder" />
                </ideo-inline-filters>
                <template #end>
                    <list-view-settings v-if="section.settings.customisableColumns" />
                    <data-sorting :pager="pager" :options="sortingOptions" @change="loadData()" class="ms-1" v-if="mobile"></data-sorting>
                </template>
            </ideo-panel>
        </template>
        <!-- Tabela -->
        <template #default>
            <form-list-view
                layout="table"
                :loaded="loaded" :builder="builder" :customisable="section.settings.customisableColumns" :sticky-footer="!props.embedded"
                :items="items" :columns="columns" :pager="pager" @change="loadData()"
                :row-click="(item: ListItemModel) => onEdit(item)"
                :row-class="(item: ListItemModel) => ({
                    ...(versionable ? PublicationStyle('data-{0}', item.meta.Status) : {})
                })"
                :column-value="(item: ListItemModel, column: string) => item.result[column] || '-'"
            >
                <template #row-start="{item}: any">
                    <list-view-item name="__Id" :title="$t('[[[Id]]]')" sort="Id" width="50">
                        {{ item.id }}
                    </list-view-item>
                    <list-view-item name="__DateCreatedUtc" :title="$t('[[[Data dodania]]]')" sort="DateCreatedUtc" width="120">
                        {{ $filters.datetime(item.meta.DateCreatedUtc) }}
                    </list-view-item>
                </template>
                <template #row-end="{item}: any">
                    <list-view-buttons name="__Buttons" width="50" @show="loadMeta">
                        <list-view-button
                            :title="$t('[[[Edytuj]]]')"
                            :disabled="!itemsMeta.has(item.meta.Version) || !allowed(section.features.update, itemsMeta.get(item.meta.Version))"
                            @click="onEdit(item)"
                        >
                            <i class="icon far fa-pencil-alt"></i> {{ $t('[[[Edytuj]]]') }}
                        </list-view-button>
                        <list-view-button
                            :title="$t('[[[Usuń]]]')"
                            :disabled="!itemsMeta.has(item.meta.Version) || !allowed(section.features.delete, itemsMeta.get(item.meta.Version))"
                            :message="$t('[[[Potwierdzenie usunięcia]]]')"
                            @confirm="deleteItem"
                        >
                            <i class="icon far fa-times"></i> {{ $t('[[[Usuń]]]') }}
                        </list-view-button>
                    </list-view-buttons>
                </template>
            </form-list-view>
        </template>
        <!-- Footer -->
        <template #footer>
            <pagination-bar :pager="pager" @change="loadData()" />
        </template>
    </data-card>
</template>

<style scoped>
:deep(.toggle::before) {
    font-family: var(--fa-font-family);
    content: "\f142";
    margin: 0 2px;
}
:deep(.toggle::after) {
    display: none;
}
</style>
