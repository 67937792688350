<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { defineStore } from 'pinia';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, RelatedSelfType } from '.';
import DocumentsService from '@/modules/studio/documents/services/DocumentsService';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});

const useStore = defineStore(props.component.id, () =>
{
    const data = ref<{
        ids: number[];
        options: Option<number>[];
        loaded: boolean;
    }>({
        ids: [],
        options: [],
        loaded: false
    });

    function push(id: number)
    {
        data.value.loaded = false;
        data.value.ids.push(id);
    }

    function clear()
    {
        data.value.loaded = true;
        data.value.ids = [];
    }

    const fetch = useDebounceFn(async () =>
    {
        try
        {
            if (data.value.ids.length > 0)
                data.value.options = await DocumentsService.getOptions(props.builder.blueprintId, props.component.id, [], data.value.ids.map(p => Number(p)), null, 10000);
            else
                data.value.options = [];
        }
        catch (ex)
        {
            data.value.options = [];
        }
        finally
        {
            clear();
        }
    },
    500);

    return { data, push, fetch };
});

const store = useStore();
const loaded = computed(() => store.data.loaded);
const display = computed(() =>
{
    return store.data.options
        .filter(p => props.value?.Value == p.value)
        .map(p => p.text);
});

onMounted(() =>
{
    if (props.value?.Value)
        store.push(props.value.Value);

    store.fetch();
});
</script>

<template>
    <template v-if="loaded && display.length > 0">
        <span class="badge bg-primary me-1" v-for="(text, i) in display" :key="i">
            {{ text }}
        </span>
    </template>
    <template v-else-if="loaded">
        <span>-</span>
    </template>
    <template v-else>
        <i class="fa-duotone fa-spinner fa-spin"></i>
    </template>
</template>
