<template>
    <div class="vdatetime-popup">
        <div class="vdatetime-popup__header">
            <div class="vdatetime-popup__title" v-if="title">{{ title }}</div>
            <div class="vdatetime-popup__year" @click="showYear" v-if="type !== 'time'">{{ year }}</div>
            <div class="vdatetime-popup__date" @click="showMonth" v-if="type !== 'time'">{{ dateFormatted }}</div>
        </div>
        <div class="vdatetime-popup__body">
            <datetime-year-picker
                v-if="step === 'year'"
                @change="onChangeYear"
                :min-date="minDatetime"
                :max-date="maxDatetime"
                :year="year"
            ></datetime-year-picker>
            <datetime-month-picker
                v-if="step === 'month'"
                @change="onChangeMonth"
                :min-date="minDatetime"
                :max-date="maxDatetime"
                :year="year"
                :month="month"
            ></datetime-month-picker>
            <datetime-calendar
                v-if="step === 'date'"
                @change="onChangeDate"
                :year="year"
                :month="month"
                :day="day"
                :min-date="minDatetime"
                :max-date="maxDatetime"
                :week-start="weekStart"
            ></datetime-calendar>
            <datetime-time-picker
                v-if="step === 'time'"
                @change="onChangeTime"
                :hour="hour"
                :minute="minute"
                :use12-hour="use12Hour"
                :hour-step="hourStep"
                :minute-step="minuteStep"
                :min-time="minTime"
                :max-time="maxTime"
            ></datetime-time-picker>
        </div>
        <div class="vdatetime-popup__actions">
            <div class="vdatetime-popup__actions__button vdatetime-popup__actions__button--cancel" @click="cancel">
                <slot name="button-cancel__internal" :step="step">{{ phrases.cancel }}</slot>
            </div>
            <div class="vdatetime-popup__actions__button vdatetime-popup__actions__button--confirm" @click="confirm" data-test="acceptDate">
                <slot name="button-confirm__internal" :step="step">{{ phrases.ok }}</slot>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DateTime } from 'luxon';
import { createFlowManager, createFlowManagerFromType } from './util';
import DatetimeCalendar from './DatetimeCalendar.vue';
import DatetimeTimePicker from './DatetimeTimePicker.vue';
import DatetimeYearPicker from './DatetimeYearPicker.vue';
import DatetimeMonthPicker from './DatetimeMonthPicker.vue';

const KEY_TAB = 9;
const KEY_ENTER = 13;
const KEY_ESC = 27;

export default {
    components: {
        DatetimeCalendar,
        DatetimeTimePicker,
        DatetimeYearPicker,
        DatetimeMonthPicker
    },

    props: {
        datetime: {
            type: DateTime,
            required: true
        },
        phrases: {
            type: Object,
            default: () => ({
                cancel: 'Cancel',
                ok: 'Ok'
            })
        },
        type: {
            type: String,
            default: 'date'
        },
        use12Hour: {
            type: Boolean,
            default: false
        },
        hourStep: {
            type: Number,
            default: 1
        },
        minuteStep: {
            type: Number,
            default: 1
        },
        minDatetime: {
            type: DateTime,
            default: null
        },
        maxDatetime: {
            type: DateTime,
            default: null
        },
        auto: {
            type: Boolean,
            default: false
        },
        weekStart: {
            type: Number,
            default: 1
        },
        flow: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: null
        }
    },

    emits: ['confirm', 'cancel'],

    data()
    {
        const flowManager = this.flow.length > 0
            ? createFlowManager(this.flow)
            : createFlowManagerFromType(this.type);

        return {
            newDatetime: this.datetime,
            flowManager,
            step: flowManager.first(),
            timePartsTouched: []
        };
    },

    computed: {
        year()
        {
            return this.newDatetime.year;
        },
        month()
        {
            return this.newDatetime.month;
        },
        day()
        {
            return this.newDatetime.day;
        },
        hour()
        {
            return this.newDatetime.hour;
        },
        minute()
        {
            return this.newDatetime.minute;
        },
        dateFormatted()
        {
            return this.newDatetime.setLocale(this.$i18n.locale()).toLocaleString({
                month: 'long',
                day: 'numeric'
            });
        },
        minTime()
        {
            return (
                this.minDatetime &&
                this.minDatetime.year === this.year &&
                this.minDatetime.month === this.month &&
                this.minDatetime.day === this.day
            ) ? this.minDatetime.toFormat('HH:mm') : null;
        },
        maxTime()
        {
            return (
                this.maxDatetime &&
                this.maxDatetime.year === this.year &&
                this.maxDatetime.month === this.month &&
                this.maxDatetime.day === this.day
            ) ? this.maxDatetime.toFormat('HH:mm') : null;
        }
    },

    created()
    {
        document.addEventListener('keydown', this.onKeyDown);
    },

    beforeUnmount()
    {
        document.removeEventListener('keydown', this.onKeyDown);
    },

    methods: {
        nextStep()
        {
            this.step = this.flowManager.next(this.step);
            this.timePartsTouched = [];

            if (this.step === 'end')
            {
                this.$emit('confirm', this.newDatetime);
            }
        },
        showYear()
        {
            this.step = 'year';
            this.flowManager.diversion('date');
        },
        showMonth()
        {
            this.step = 'month';
            this.flowManager.diversion('date');
        },
        confirm()
        {
            this.nextStep();
        },
        cancel()
        {
            this.$emit('cancel');
        },
        onChangeYear(year)
        {
            this.newDatetime = this.newDatetime.set({ year });

            if (this.auto)
            {
                this.nextStep();
            }
        },
        onChangeMonth(month)
        {
            this.newDatetime = this.newDatetime.set({ month });

            if (this.auto)
            {
                this.nextStep();
            }
        },
        onChangeDate(year, month, day)
        {
            this.newDatetime = this.newDatetime.set({ year, month, day });

            if (this.auto)
            {
                this.nextStep();
            }
        },
        onChangeTime({ hour, minute, suffixTouched })
        {
            if (suffixTouched)
            {
                this.timePartsTouched.suffix = true;
            }

            if (Number.isInteger(hour))
            {
                this.newDatetime = this.newDatetime.set({ hour });
                this.timePartsTouched.hour = true;
            }

            if (Number.isInteger(minute))
            {
                this.newDatetime = this.newDatetime.set({ minute });
                this.timePartsTouched.minute = true;
            }

            const goNext = this.auto && this.timePartsTouched.hour && this.timePartsTouched.minute && (
                this.timePartsTouched.suffix ||
                !this.use12Hour
            );

            if (goNext)
            {
                this.nextStep();
            }
        },
        onKeyDown(event)
        {
            switch (event.keyCode)
            {
                case KEY_ESC:
                case KEY_TAB:
                    this.cancel();
                    break;

                case KEY_ENTER:
                    this.nextStep();
                    break;
            }
        }
    }
};
</script>

<style lang="scss">
.vdatetime-popup {
    box-sizing: border-box;
    z-index: 100000;
    position: fixed;
    top: calc(50% - 230px);
    left: 50%;
    transform: translateX(-50%);
    width: 360px;
    max-width: calc(100% - 30px);
    box-shadow: var(--bs-box-shadow);
    color: var(--bs-body-color);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    line-height: 1.18;
    background: var(--bs-body-bg);
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

    & * {
        box-sizing: border-box;
    }
}

.vdatetime-popup__header {
    padding: 18px 30px;
    background: var(--bs-primary);
    color: var(--bs-white);
    font-size: 32px;
}

.vdatetime-popup__title {
    margin-bottom: 8px;
    font-size: 21px;
    font-weight: 300;
}

.vdatetime-popup__year {
    font-weight: 300;
    font-size: 14px;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
        opacity: 1;
    }
}

.vdatetime-popup__date {
    line-height: 1;
    cursor: pointer;
}

.vdatetime-popup__actions {
    padding: 0 20px 10px 30px;
    text-align: right;
}

.vdatetime-calendar__month__weekday {
    cursor: default;
    user-select: none;
}

.vdatetime-calendar__current--month {
    user-select: none;
}

.vdatetime-popup__actions__button {
    display: inline-block;
    border: none;
    padding: 10px 20px;
    background: transparent;
    font-size: 16px;
    color: var(--bs-link-color) !important;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: var(--bs-link-hover-color) !important;
    }
}
</style>
