import { Form } from '@/helpers/Form';
import Pager from "@/helpers/Pager";

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            companyId: null,
            registerId: null,
            dateCreatedFromUtc: null,
            dateCreatedToUtc: null,
            dateShippmentFromUtc: null,
            dateShippmentToUtc: null,
            title: null,
            signatureInternal: null,
            barcode: null,
            contractorId: null,
            status: null,
            shipmentType: null,
            shipmentMethod: null
        }),
        pager: new Pager(1, 50, 'DateCreatedUtc', 'DESC')
    }
};
