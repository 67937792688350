<template>
    <span :class="customClasses">
        <slot name="default"></slot>
    </span>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-badge'
})
export default class IdeoBadge extends Vue
{
    @Prop({
        default: 'secondary',
        validator: (value: string) => [
            'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
        ].includes(value)
    })
    public variant: string;

    public get customClasses(): Record<string, boolean>
    {
        return {
            'badge': true,
            [`bg-${this.variant}`]: true
        };
    }
}
</script>
