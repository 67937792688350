<template>
    <div style="min-height: 36px;" class="filter-dropdown-object">
        <ideo-dropdown
            variant="secondary"
            boundry="body"
            no-flip
            no-caret
            ref="dropdown"
            @show="dropdownShown"
            @hide="opened = false"
            :toggle-class="toggleClasses"
            :menu-class="menuClasses"
            :container-class="containerClasses"
            :top-offset="6"
        >
            <template #button-content>
                <div class="label-value">
                    <span v-if="label" class="label" :class="{ 'label--bigger': !modelValue}">
                        {{ label }}
                        {{ modelValue ? ':' : '' }}
                    </span>
                    <span class="value">{{ modelValue ? modelValue : "" }}</span>
                </div>
                <i class="fas fa-chevron-down arrow ms-2" :class="{ 'opened-arrow': opened }"></i>
                <div v-if="modelValue" class="btn-remove ms-2" @click.stop="clearSearchData">
                    <i class="fas fa-times"></i>
                </div>
            </template>
            <div class="radius-4" :class="{'show' : opened }">
                <div class="dropdown__input-row">
                    <div class="dropdown__input-wrapper">
                        <input
                            :value="modelValue"
                            :type="type"
                            :placeholder="placeholder + '...'"
                            class="me-2 search-bar flex-grow-1"
                            ref="search-bar"
                            @input="updateValue"
                            @keydown.enter="applyFilters"
                        />
                        <span class="border-0 bg-transparent p-1">
                            <button type="button" class="border-0 p-0 bg-transparent clear-button text-dark" v-if="modelValue" @click.stop="clearSearchData">
                                <span class="fas fa-times"></span>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </ideo-dropdown>
    </div>
</template>

<script lang="ts">
import IdeoDropdown from '@/components/ideo/dropdown/IdeoDropdown.vue';
import { Emit, Prop, Ref } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'FiltersDropdownInput',
    emits: ['update:modelValue']
})
export default class FiltersDropdownInput extends Vue
{
    @Ref('search-bar')
    public input: () => HTMLInputElement;

    @Ref('dropdown')
    public dropdown: () => IdeoDropdown;

    @Prop({ default: "" })
    public modelValue: string;

    @Prop({ default: "" })
    public placeholder: string;

    @Prop({ default: "" })
    public label: string;

    @Prop({ default: "text" })
    public type: string;

    @Prop({ default: "" })
    public filterEmitId: string;

    public opened: boolean = false;

    public get toggleClasses(): Record<string, boolean>
    {
        return {
            'filter-button': true,
            'bg-primary border-primary': !!this.modelValue || this.opened
        };
    }

    public get menuClasses(): Record<string, boolean>
    {
        return {
            'p-0': true,
            'filter-dropdown-object__dropdown': true,
            'border-0': true
        };
    }

    public get containerClasses(): Record<string, boolean>
    {
        return {
            'filter-container': true
        };
    }

    public applyFilters(): void
    {
        this.dropdown().hide();

        if (this.filterEmitId)
            this.$events.$emit(this.filterEmitId);
        else
            this.$events.$emit('applyFilters');
    }

    public dropdownShown(): void
    {
        this.opened = true;
        setTimeout(() =>
        {
            this.input().focus();
        }, 1);
    }

    public clearSearchData(): void
    {
        this.input().focus();
        this.$emit("update:modelValue", "");
        this.$events.$emit('refetchData', { resetPageIndex: true });
    }

    @Emit('update:modelValue')
    public updateValue(e: Event): string
    {
        return (e.target as HTMLInputElement).value;
    }
}
</script>
