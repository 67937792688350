<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import { KeyValue, Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { ExchangeRatesEntry, ExchangeRatesType, instaceOfExchangeRatesEntry } from '.';
import { DateTime } from 'luxon';
import ExchangeRatesService, {
    KeyValueIsoSymbol,
    ExchangeRate,
} from '@/modules/low-code/services/ExchangeRatesService';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { Definition as DateDefintion } from '../date';
import properties from '../../properties';

@Options({
    name: 'exchange-rates-blueprint',
    components: {
        ...properties,
    },
})
export default class ExchangeRatesBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilderContract;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: ExchangeRatesType;
    @Prop({ default: null }) public entry!: FormEntry;
    @Prop() public index: number;

    public entryData: ExchangeRatesEntry = new ExchangeRatesEntry();
    public pager: Pager = new Pager(1, 999, '', 'DESC');
    public currencies: KeyValueIsoSymbol[] = [];

    public get fetchOptions(): boolean
    {
        return !this.design;
    }

    public get value(): ExchangeRate
    {
        return this.entryData.data;
    }

    public set value(value: ExchangeRate)
    {
        this.entryData.data = value;
    }

    public get currency(): KeyValueIsoSymbol
    {
        const defaultCurrency = this.currencies.find((currency) => currency.isoSymbol == this.value?.currencyFrom);

        return defaultCurrency;
    }

    public set currency(value: KeyValueIsoSymbol)
    {
        this.value.currencyFrom = value?.isoSymbol ?? '';
    }

    public get date(): DateTime
    {
        if (typeof this.value.date === 'string')
        {
            return DateTime.fromISO(this.value.date);
        }

        return this.value.date;
    }

    public set date(value: DateTime)
    {
        this.value.date = value?.toISO?.() ?? '';
    }

    public get design(): boolean
    {
        return this.form.designMode();
    }

    public get components(): Option[]
    {
        return this.form.schema
            .components([DateDefintion.type], this.blueprint)
            .map((p) => ({ value: p.name, text: this.form.localization.translate((p as HasLabel).label) }));
    }

    public get scope(): KeyValue<string, DateTime | null>
    {
        if (this.blueprint.bindWith)
        {
            return this.components
                .where((p) => this.blueprint.bindWith.includes(p.value))
                .select((p) => ({ key: p.value, value: this.entry[p.value]?.value }))
                .toArray()
                .first();
        }

        return { key: '', value: null };
    }

    public get visible(): boolean
    {
        return this.form.expressions.visible(this.blueprint);
    }

    public get required(): boolean
    {
        return this.form.expressions.required(this.blueprint);
    }

    public get placeholder(): string
    {
        return this.form.localization.translate(this.blueprint.placeholder);
    }

    public async created(): Promise<void>
    {
        this.entryData = this.form.document.initEntry(
            this.blueprint,
            this.entryData,
            instaceOfExchangeRatesEntry,
            this.index
        );
        await this.getOptions();
        this.setDefaultCurrency();
        this.getExchangeRate();
    }

    public async getOptions(): Promise<any>
    {
        const result = await ExchangeRatesService.GetCurrencies(this.pager);

        this.currencies = result.items.map((item) => item.result);
    }

    public async getExchangeRate(): Promise<void>
    {
        if (this.currency)
        {
            try
            {
                let date = this.date?.isValid
                    ? this.date.toISODate()
                    : this.currency.isoSymbol === this.blueprint.isoSymbol
                        ? DateTime.now().toISODate()
                        : '';

                if (!date)
                {
                    date = this.value.date = this.scope.value?.isValid ? this.scope.value.toISO() : DateTime.now().toISO();
                }

                if (date)
                {
                    this.value = await ExchangeRatesService.GetExchangeRate(
                        this.currency.isoSymbol,
                        this.blueprint.isoSymbol,
                        date
                    );

                    if (typeof this.value.date !== 'string' && this.value.date.toISODate() === '1970-01-01')
                    {
                        this.value.date = this.scope.value?.isValid ? this.scope.value.toISO() : DateTime.now().toISO();
                    }
                }
            }
            catch (ex)
            {
                this.value.key = '';
                this.value.rate = 0;
            }
        }
    }

    public setDefaultCurrency(): void
    {
        if (this.currency) return;

        const defaultCurrency = this.currencies.find((currency) => currency.isoSymbol == this.blueprint.isoSymbol);

        if (defaultCurrency) this.currency = defaultCurrency;
    }

    @Watch('bindDate')
    public setDate(newValue: KeyValue<string, DateTime>): void
    {
        if (newValue.value)
        {
            this.date = newValue.value;
            this.getExchangeRate();
        }
    }
}
</script>

<template>
    <form-component-wrapper class="exchangeRates-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <ideo-select
                    v-model="currency"
                    :id="blueprint.id"
                    :placeholder="placeholder"
                    :options="currencies"
                    :data-test="blueprint.id"
                    @update:model-value="
                        () => {
                            getExchangeRate();
                        }
                    "
                ></ideo-select>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
            <div v-show="currency?.isoSymbol != blueprint.isoSymbol">
                <ideo-form-group :label="$t('[[[Data Kursu]]]')">
                    <ideo-datetime type="date" v-model="date" @update:modelValue="getExchangeRate" />
                </ideo-form-group>
                <ideo-form-group :label="$t('[[[Kurs]]]')">
                    <ideo-form-input :value="value?.rate ?? ''" type="text" :placeholder="$t('[[[Kurs]]]')" disabled />
                </ideo-form-group>
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <field-text
                v-model="blueprint.isoSymbol"
                :label="$t('[[[Symbol ISO waluty domyślnej]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'isoSymbol')"
                required
            />
            <field-select
                v-model="blueprint.bindWith"
                :label="$t('[[[Pole z datą wg której liczymy kurs]]]')"
                :options="components"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>
