<script lang="ts" setup>
import { Option } from '@/helpers/Interfaces';
import { ref, computed, watch, onMounted } from 'vue';
import debounce from 'lodash/debounce';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';

type ValueType = null|string|number|string[]|number[];

const props = defineProps({
  "name": { default: '' },
  "trackBy": { default: 'value' },
  "label": { default: 'text' },
  "placeholder": { default: '' },
  "multiple": { type: Boolean, default: false },
  "disabled": { type: Boolean, default: false },
  "refresh": { type: Boolean, default: false },
  "limit": { default: 10 },
  "modelValue": { default: null },
  "fetch": { type: Function,  },
  "search": { type: Function,  }
});

const emit = defineEmits(["update:modelValue", "changed"]);

const multiselect = ref(null);
const loading = ref(false);
const options = ref<Record<string, any>[]>([]);
const selected = ref<null|Record<string, any>|Record<string, any>[]>(null);
const valueModel = computed<number[]>({
    get: (): number[] =>
    {
        return props.multiple
            ? (props.modelValue ?? []) as number[]
            : props.modelValue
                ? [props.modelValue as number]
                : [];
    },
    set: (value: number[]) =>
    {
        if (props.multiple)
            emit('update:modelValue', value);
        else
            emit('update:modelValue', value.first());

        emit('changed', selected.value);

        multiselect.value.$el.querySelector('input').dispatchEvent(new Event('input', {
            bubbles: true
        }));
    }
});
const selectedModel = computed<Record<string, any>[]>({
    get: (): Record<string, any>[] =>
    {
        return props.multiple
            ? (selected.value ?? []) as Record<string, any>[]
            : selected.value != null
                ? [selected.value as Record<string, any>]
                : [];
    },
    set: (value: Record<string, any>[]) =>
    {
        if (props.multiple)
            selected.value = value;
        else
            selected.value = value.first();
    }
});

const fetchModel = async (values: number[]): Promise<void> =>
{
    if (values.none())
    {
        selectedModel.value = [];
    }

    values.forEach(value =>
    {
        if (value != null && selectedModel.value.where(p => props.trackBy in p && p[props.trackBy] == value).none())
        {
            props.fetch(value).then(result =>
            {
                if (result != null)
                {
                    if (props.multiple)
                        selectedModel.value = [...selectedModel.value, result];
                    else
                        selectedModel.value = [result];
                }
            });
        }
    });
};

const searchOptions = async (query: string): Promise<void> =>
{
    loading.value = true;
    options.value = await props.search(query, props.limit);
    loading.value = false;
};

const onSearchChanged = debounce(async (query: string): Promise<void> =>
{
    await searchOptions(query);
},
500);

watch(selectedModel, (value, old) =>
{
    const c1 = value.select(p => props.trackBy in p && p[props.trackBy]).orderBy(p => p).toArray();
    const c2 = (old ?? []).select(p => props.trackBy in p && p[props.trackBy]).orderBy(p => p).toArray();

    if (c1.toString() != c2.toString())
    {
        valueModel.value = value.select(p => props.trackBy in p && p[props.trackBy]).toArray();
    }
});

watch(valueModel, (value) =>
{
    fetchModel(value);
});

watch(() => props.disabled, (value) =>
{
    if (value == false)
    {
        fetchModel(valueModel.value);
    }
});

onMounted(() =>
{
    fetchModel(valueModel.value);
});

const clear = (): void =>
{
    selectedModel.value = [];
    multiselect.value.deactivate();
};

const open = async (): Promise<void> =>
{
    if (props.refresh || options.value.length == 0)
    {
        await searchOptions('');
    }
};

const close = (): void =>
{
    if (props.refresh)
    {
        if (selectedModel.value.none())
        {
            options.value = [];
        }
        else
        {
            options.value = selectedModel.value;
        }
    }
};
</script>

<template>
    <Multiselect
        v-model="selected"
        :options="options"
        :name="props.name"
        :label="props.label"
        :track-by="props.trackBy"
        :loading="loading"
        @search-change="onSearchChanged"
        @open="open()"
        @close="close()"
        :placeholder="props.placeholder || $t('[[[wybierz...]]]')"
        :select-label="''"
        :selected-label="''"
        :deselect-label="''"
        :multiple="props.multiple"
        :searchable="true"
        :options-limit="props.limit"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="true"
        :max-height="300"
        :show-no-results="true"
        :hide-selected="false"
        :disabled="props.disabled"
        ref="multiselect"
    >
        <template #noOptions><div class="text-center">{{ $t('[[[Lista jest pusta]]]') }}</div></template>
        <template #noResult><div class="text-center">{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</div></template>
        <template #singleLabel="{ option }"><slot name="selected" :option="option"></slot></template>
        <template #option="{ option }"><slot name="option" :option="option"></slot></template>
        <template #beforeList>
            <li class="multiselect__element" @click="clear">
                <span class="multiselect__option empty">&nbsp;</span>
            </li>
        </template>
    </Multiselect>
</template>
