import { axios } from '@/plugins/axios';
import { KeyValuePair, Pagination, Resource } from "@/helpers/Interfaces";
import Pager from '@/helpers/Pager';
import { merge } from 'lodash';

export default class TaxLineService
{
    public static async saveTaxLine(form: TaxLineModel): Promise<TaxLineResponse>
    {
        return (await axios.post(`finance/invoices/tax-lines`, form)).data;
    }

    public static async save(params: TaxLineModel): Promise<TaxLineDetails>
    {
        return (await axios.post('simple-command/tax-lines/', params)).data;
    }

    public static async getTaxLines(publicId: string, type: string, pager: Pager): Promise<Pagination<Resource<TaxLineModel>>>
    {
        return (await axios.get(`simple-query/${type}/${publicId}/tax-lines-list`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async getTaxCodes(): Promise<TaxCodeModel[]>
    {
        return (await axios.get(`finance/invoices/dictionary/tax-codes`)).data;
    }

    public static async getTaxAccounts(companyId: number, search: string): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/dictionary/tax-accounts`, {
            params: { companyId, search }
        })).data;
    }
}

export interface TaxLineModel {
    netAmount: number | string;
    vatAmount: string | number;
    grossAmount: number | string;
    taxCode: TaxCodeModel;
}

export interface TaxLineResponse {
    lineId: string;
}

export interface TaxCodeModel {
    key: string;
    value: string;
    rate: number;
}

export interface TaxLineDetails {
    publicId: string;
}
