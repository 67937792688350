import { $t } from '@/plugins/localization';
import { KeyValue, KeyValuePair, Option } from './Interfaces';

enum PublicationEnum {
    Draft = 'Draft',
    Finished = 'Finished',
    Rejected = 'Rejected',
    Accepted = 'Accepted',
    Active = "Active",
    Archived = "Archived",
}

const PublicationOptions = (): Option[] => ([
    { value: PublicationEnum.Draft, text: $t('[[[Szkic]]]'), icon: 'fas fa-pen-circle text-info' },
    { value: PublicationEnum.Finished, text: $t('[[[Do akceptacji]]]'), icon: 'fas fa-clock text-warning' },
    { value: PublicationEnum.Rejected, text: $t('[[[Zwrócony]]]'), icon: 'fas fa-times-circle text-danger' },
    { value: PublicationEnum.Accepted, text: $t('[[[Zaakceptowany]]]'), icon: 'fas fa-check-circle text-success' }
]);

const AllPublicationOptions = (): Option[] => (
    [ { value: null, text: $t('[[[Wszystkie]]]') } ].concat(PublicationOptions())
);

const PublicationName = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.text || PublicationEnum[value];
const PublicationIcon = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.icon || '';
const PublicationStyle = (classTemplate: string, status: PublicationEnum): Record<string, boolean> =>
{
    const classes: Record<string, boolean> = {};
    const re = new RegExp('\\{0\\}', 'g');

    classes[classTemplate.replace(re, 'info')] = status === PublicationEnum.Draft;
    classes[classTemplate.replace(re, 'warning')] = status === PublicationEnum.Finished;
    classes[classTemplate.replace(re, 'danger')] = status === PublicationEnum.Rejected;
    classes[classTemplate.replace(re, 'success')] = status === PublicationEnum.Accepted;

    return classes;
};

export {
    PublicationEnum,
    PublicationOptions,
    PublicationName,
    PublicationStyle,
    PublicationIcon,
    AllPublicationOptions
};

// ----------------------------------------------------------------------------------------------------

enum EntityState {
    Deleted = 'Deleted',
    Modified = 'Modified',
    Added = 'Added',
    Trashed = 'Trashed',
    Recovered = 'Recovered'
}

const EntityStateOptions = (): Option[] => ([
    { value: EntityState.Deleted, text: $t('[[[Usunięcie]]]') },
    { value: EntityState.Modified, text: $t('[[[Modyfikacja]]]') },
    { value: EntityState.Added, text: $t('[[[Dodanie]]]') },
    { value: EntityState.Trashed, text: $t('[[[Kosz]]]') },
    { value: EntityState.Recovered, text: $t('[[[Przywrócenie]]]') }
]);

const EntityStateName = (value: EntityState): string => EntityStateOptions().find(p => p.value === value)?.text || EntityState[value];

export {
    EntityState,
    EntityStateOptions,
    EntityStateName
};

// ----------------------------------------------------------------------------------------------------

enum AccountType {
    Local = 'Local',
    Ldap = 'Ldap',
    NoLogon = 'NoLogon',
}

export {
    AccountType
};

// ----------------------------------------------------------------------------------------------------

enum DictionaryType {
    CoreUsers = 'CoreUsers',
    CoreRoles = 'CoreRoles',
    CoreTeams = 'CoreTeams',

    CoreJobPositions = 'CoreJobPositions',
    CoreCountries = 'CoreCountries',
    CoreCities = 'CoreCities',
    CoreProvinces = 'CoreProvinces',
    CoreCurrencies = 'CoreCurrencies',
    ContactsEventTypes = 'ContactsEventTypes',
    CalendarResourceCategories = 'CalendarResourceCategories',

    InvoicePaymentMethod = 'Invoice_PaymentMethod',
    InvoicePaymentForm = 'Invoice_PaymentForm',
    InvoiceTaxCodes = 'Invoice_TaxCodes',
    InvoiceControlCodeTypes = 'Invoice_ControlCodeTypes',
    InvoiceDocumentType = 'Invoice_DocumentType',

    ReportType = "ReportType",
    ReportSource = "ReportSource",
    ReporterDemand = "ReporterDemand",
    ShippingCompany = "ShippingCompany",
    PostalService = "PostalService"

    // CoreJobPositions = 'CoreJobPositions',
    // CoreCountries = 'CoreCountries',
    // CoreCities = 'CoreCities',
    // CoreProvinces = 'CoreProvinces',
    // ContactsEventTypes = 'ContactsEventTypes',
    // CalendarResourceCategories = 'CalendarResourceCategories'
}

const DictionaryTypeOptions = (): Option[] => ([
    { value: DictionaryType.CoreUsers, text: $t('[[[Administracja - Użytkownicy]]]') },
    { value: DictionaryType.CoreRoles, text: $t('[[[Administracja - Role]]]') },
    { value: DictionaryType.CoreTeams, text: $t('[[[Administracja - Zespoły]]]') }
]);

const DictionaryTypeName = (value: DictionaryType): string =>
{
    const item = DictionaryTypeOptions().filter(p => p.value === value);

    return item.length ? item[0].text : value;
};

export {
    DictionaryType,
    DictionaryTypeOptions,
    DictionaryTypeName
};

// ----------------------------------------------------------------------------------------------------

enum QueueStage {
    DISPATCH = 100,
    QUEUED = 200,
    DELAYED = 300,
    RETRY = 400,
    REJECTED = 500,
    PROCESSING = 600,
    SUCCEEDED = 700,
    FAILED = 800
}

const QueueStageOptions = (): Option<QueueStage>[] => ([
    { value: QueueStage.DISPATCH, text: $t('[[[Wysłane]]]') },
    { value: QueueStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: QueueStage.DELAYED, text: $t('[[[Zaplanowane]]]'), icon: 'fad fa-clock text-info' },
    { value: QueueStage.RETRY, text: $t('[[[Powtórzone]]]') },
    { value: QueueStage.REJECTED, text: $t('[[[Odrzucone]]]') },
    { value: QueueStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: QueueStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: QueueStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const QueueStageName = (value: QueueStage): string => QueueStageOptions().find(p => p.value === value)?.text || QueueStage[value];

export {
    QueueStage,
    QueueStageOptions,
    QueueStageName
};

// ----------------------------------------------------------------------------------------------------

enum JobStage {
    QUEUED = 210,
    PROCESSING = 610,
    SUCCEEDED = 620,
    FAILED = 630
}

const JobStageOptions = (): Option<JobStage>[] => ([
    { value: JobStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: JobStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: JobStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: JobStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const JobStageName = (value: JobStage): string => JobStageOptions().find(p => p.value === value)?.text || JobStage[value];

export {
    JobStage,
    JobStageOptions,
    JobStageName
};

// --------------------------------------------------

enum SubstitutionOrigin{
    Admin = "core-substitutions",
    Account = "account-substitution",
    CreateAdmin = "core-substitutions-add",
    CreateAccount = "account-substitutions-add",
    EditAdmin = "core-substitutions-edit",
    EditAccount = "account-substitutions-edit"
}

export {
    SubstitutionOrigin
};

// -------------------------------------------

const ColorOptions: () => KeyValue<string, string>[] = () =>
{
    return [
        { key: 'primary', value: $t('[[[primary]]]') },
        { key: 'secondary', value: $t('[[[secondary]]]') },
        { key: 'success', value: $t('[[[success]]]') },
        { key: 'danger', value: $t('[[[danger]]]') },
        { key: 'warning', value: $t('[[[warning]]]') },
        { key: 'info', value: $t('[[[info]]]') },
        { key: 'light', value: $t('[[[light]]]') },
        { key: 'dark', value: $t('[[[dark]]]') }
    ];
};

export { ColorOptions };

// ----------------------------------------------------------------------------------------------------

enum DocumentType {
    Invoice = 1,
    Contract = 3,
    LetterIncoming = 4,
    LetterOutgoing = 5,
    Complaint = 6,
    HrRequest = 7,
    Document = 8,
    ToSend = 9,
    Absence = 10
}

export {
    DocumentType
};


enum NotificationDocType{
    Invoice = "Invoice",
    Contract = "Contract",
    LetterIncoming = "LetterIncoming",
    LetterOutgoing = "LetterOutgoing",
    Complaint = "Complaint",
    HrRequest = "HrRequest"
}

export {
    NotificationDocType
};

// ----------------------------------------------------------------------------------------------------

const ContractTypes: KeyValuePair[] = [
    { key: 'FcContract', value: $t('[[[umowę]]]') },
    { key: 'FcContractAnnex', value: $t('[[[aneks]]]') },
    { key: 'FnIncoming', value: $t('[[[pismo przychodzące]]]') },
    { key: 'FnInvoice', value: $t('[[[fakturę]]]') },
    { key: 'FnInvoiceLine', value: $t('[[[linię]]]') },
    { key: 'FnInvoiceTaxLine', value: $t('[[[linię]]]') },
    { key: 'FcDocumentParticipant', value: $t('[[[uczestnika]]]') },
    { key: 'FcComplaint', value: $t('[[[reklamacje]]]') }
];

const contractType = (key: string): any => ContractTypes.find((item: any) => item.key === key)?.value;

export {
    ContractTypes,
    contractType
};

// ----------------------------------------------------------------------------------------------------

enum RequestType {
    VacationLeave = 'Urlop wypoczynkowy',
    VacationLeaveOnDemand = 'Urlop Wypoczynkowy na żądanie',
    SpecialLeave = 'Urlop okolicznościowy',
    SickLeave = 'Zwolnienie lekarskie',
    ChildCare = 'Opieka nad dzieckiem',
    BusinessTrip = 'Wniosek o wyjazd służbowy/wyjście służbowe',
    HomeDelegation = 'Delegacja',
    HomeOffice = 'Praca zdalna',
    MedicalLeave = '',
    CareLeave = '',
    ParentalLeave = '',
    ExtPostMaternityLeave = '',
    UnpaidLeave = '',
    ExcusedAbsencePaid = '',
    ExcusedAbsenceUnpaid = 'Nieobecność usprawiedliwiona niepłatna',
    UnexcusedAbsence = 'Nieobecność nieusprawiedliwiona',
}

const RequestStatus = {
    Draft: { variant: 'warning', text: $t('[[[Wersja robocza]]]') },
    AwaitingAccept: { variant: 'primary', text: $t('[[[Oczekuje na akceptacje]]]') },
    Accepted: { variant: 'success', text: $t('[[[Zaakceptowany]]]') },
    Rejected: { variant: 'danger', text: $t('[[[Odrzucony]]]') },
    Deleted: { variant: 'dark', text: $t('[[[Usunięty]]]') },
    AwaitingResignation: { variant: 'danger', text: $t('[[[Do rezygnacji]]]') },
    Resigned: { variant: 'danger', text: $t('[[[Rezygnacja]]]') }
};

export {
    RequestType,
    RequestStatus
};

// ----------------------------------------------------------------------------------------------------

enum NotificationStage {
    Unreaded = 'Unreaded',
    Readed = 'Readed',
    Success = 'Success',
    Warning = 'Warning',
    Error = 'Error'
}

const NotificationStageOptions = (): Option<NotificationStage>[] => ([
    { value: NotificationStage.Unreaded, text: $t('[[[Nieprzeczytane]]]') },
    { value: NotificationStage.Readed, text: $t('[[[Przeczytane]]]') },
    { value: NotificationStage.Success, text: $t('[[[Potwierdzenia]]]') },
    { value: NotificationStage.Warning, text: $t('[[[Ostrzeżenia]]]') },
    { value: NotificationStage.Error, text: $t('[[[Błędy]]]') },
]);

const NotificationStageName = (value: NotificationStage): string => NotificationStageOptions().find(p => p.value === value)?.text || NotificationStage[value];

export {
    NotificationStage,
    NotificationStageOptions,
    NotificationStageName
};

