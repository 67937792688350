<template>
    <layout>
        <template #code>500</template>
        <template #header>Houston, we have a problem!</template>
        <template #description>The page you are looking for is temporarily unavailable.</template>
    </layout>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ErrorLayout from '@/views/layouts/ErrorLayout.vue';

@Options({
    name: 'error-page-500',
    components: {
        'layout': ErrorLayout
    }
})
export default class ErrorPage500 extends Vue
{
}
</script>
