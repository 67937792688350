<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { SimplePartner } from '@/modules/core/dictionaries/services/DictionaryService';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'ContractorFormatter'
})
export default class ContractorFormatter extends Vue
{
    @Prop({ default: null })
    public original: SimplePartner;

    @Prop({ default: null })
    public current: SimplePartner;

    @Prop()
    public definition: ChangelogDefinitionModel;
}
</script>

<template>
    <div>
        <div class="original-value small" v-if="original && (original.value || original.name)">
            <span>{{ original.value || original.name }}</span>
        </div>
        <div class="current-value small" v-if="current && (current.value || current.name)">
            <span>{{ current.value || current.name }}</span>
        </div>
    </div>
</template>
