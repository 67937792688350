import { EntityType } from '@/components/common/dynamic-changelog/ChangelogEnums';
import { KeyValue, KeyValuePair, Pagination, Resource } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { axios } from '@/plugins/axios';
import { merge } from 'lodash';

export default class ChangelogService
{
    public static async fetchChangelogDefinition(type: EntityType, id: string, pager: Pager): Promise<Pagination<Resource<KeyValue<string, ChangelogDefinitionModel>>>>
    {
        return (await axios.get(`simple-query/${type}/${id}/change-log-schema`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async fetchChangelogData(type: EntityType, id: string, pager: Pager): Promise<Pagination<Resource<ChangelogDataModel>>>
    {
        return (await axios.get(`simple-query/${type}/${id}/change-log-data`, {
            params: merge({}, pager.data())
        })).data;
    }
}

export type ChangelogValueType = { baseType: string, features: KeyValuePair[] };

export interface ChangelogDefinitionValueModel{
    headerName: string,
    order: number,
    sort: string,
    type: ChangelogValueType,
    visibility: true,
}

export interface ChangelogDefinitionModel{
    key: string,
    value: KeyValue<string, ChangelogDefinitionValueModel>[]
}

export interface KeyValueMap{
    key: string,
    value: string,
    name: string,
    mapName: string
}

export interface ChangelogDataModel
{
    audit: Record<string, Record<string, any> | string>,
    action: KeyValuePair,
    objectId: KeyValuePair,
    objectType: KeyValueMap,
    original: any,
    current: any;
    publicId?: string
    requestId?: string
}

export interface StatusWithVariant{
    variant: KeyValue<string, string> | null,
    key: string,
    value: string
}

export interface ContractorDetailsModel{
    publicId: string,
    key: string,
    name: string,
    city: string,
    streetPostal: string,
    streetName: string,
    source: KeyValuePair,
    shipmentStatus: KeyValuePair
}

export interface RelatedDocumentsLog
{
    publicId: string;
    licence: string;
    title: string;
}
