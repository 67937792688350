<script lang="ts" setup>
import { ref, computed, watch, Ref } from 'vue';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { LogitoChoiceFieldTypes, LogitoChoiceEntry, LogitoChoiceType, instanceOfLogitoChoiceEntry } from '.';
import AutocompleteService from '@/modules/core/common/services/AutocompleteService';
import properties from '../../properties';

interface LogitoChoiceOption {
    value: string;
    text: string;
    selected: boolean;
    key?: string;
}

defineOptions({
    name: 'logito-choice-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const entryData = ref(new LogitoChoiceEntry()) as Ref<LogitoChoiceEntry>;
const fetchedOptions = ref<LogitoChoiceOption[]>([]);
const isLoaded = ref(false);

const options = computed<LogitoChoiceOption[]>(() =>
{
    if (design.value)
    {
        return props.blueprint.options.map((p) => ({
            key: p.key,
            value: p.value,
            text: props.form.localization.translate(p.text),
            selected: p.selected,
        }));
    }
    else return fetchedOptions.value;
});

entryData.value = props.form.document.initEntry(
    props.blueprint,
    entryData.value,
    instanceOfLogitoChoiceEntry,
    props.index
);

const fieldTypeOptions: Option<LogitoChoiceFieldTypes>[] = [
    { value: LogitoChoiceFieldTypes.Dropdown, text: '[[[Lista rozwijana]]]' },
    { value: LogitoChoiceFieldTypes.Radio, text: '[[[Pojedynczy wybór]]]' },
    { value: LogitoChoiceFieldTypes.Checkbox, text: '[[[Wielokrotny wybór]]]' },
];

const layoutOptions: Option<number>[] = [
    { value: 0, text: '[[[Obok siebie]]]' },
    { value: 1, text: '[[[Jedna kolumna]]]' },
    { value: 2, text: '[[[Dwie kolumny]]]' },
    { value: 3, text: '[[[Trzy kolumny]]]' },
    { value: 4, text: '[[[Cztery kolumny]]]' },
];

const valueSelect = computed({
    get()
    {
        if (entryData.value.data) return options.value.find((option) => entryData.value.data.includes(option.key));

        return options.value.find((option) => defaultValue.value.includes(option.value)) ?? null;
    },
    set(option: LogitoChoiceOption | null)
    {
        entryData.value.data = option ? [option.key] : [];
    },
});

const valueCheckbox = computed({
    get()
    {
        if (entryData.value.data) return entryData.value.data;

        return options.value.filter((option) => defaultValue.value.includes(option.value)).map((p) => p.key);
    },
    set(values: string[])
    {
        entryData.value.data = values;
    },
});

const valueRadiobox = computed({
    get()
    {
        if (entryData.value.data) return entryData.value.data?.first();

        return options.value.find((option) => defaultValue.value.includes(option.value))?.key ?? null;
    },
    set(value: string)
    {
        entryData.value.data = [value];
    },
});

const defaultValue = computed<string[]>(() =>
{
    if (props.blueprint.defaultValue) return [props.form.expressions.executeExpression(props.blueprint.defaultValue)];
    else return options.value.filter((p) => p.selected).map((p) => p.value);
});

const isDropdownField = computed(() => props.blueprint.fieldType == LogitoChoiceFieldTypes.Dropdown);
const isRadioList = computed(() => props.blueprint.fieldType == LogitoChoiceFieldTypes.Radio);
const isCheckboxList = computed(() => props.blueprint.fieldType == LogitoChoiceFieldTypes.Checkbox);

const blueprint = computed(() => props.blueprint);
const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const required = computed(() => props.form.expressions.required(props.blueprint));
const design = computed(() => props.form.designMode());
const endpoint = computed(() =>
{
    if (design.value) return null;

    if (props.entry.fieldIndex)
    {
        return `modules/${props.entry.moduleId}/table-templates/select-options/${props.entry.fieldIndex}/${blueprint.value.name}`;
    }

    return `simple-dictionary/${props.entry.module}/form/${props.entry.formId}/${props.entry.actionName}/${blueprint.value.name}`;
});
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));

const fetchOptions = async () =>
{
    const response = await AutocompleteService.fetchOptionsByCustomEndpoint(
        endpoint.value,
        {},
        new Pager(1, 50, 'Key', 'ASC')
    );

    fetchedOptions.value = response.items.map((p) => ({
        key: p.result.key,
        value: p.result.internalId,
        text: p.result.value,
        selected: blueprint.value.options.find((item) => item.value === p.result.internalId)?.selected ?? false,
    }));
};

watch(
    () => props.blueprint.options.length,
    () => init()
);

const init = async (): Promise<void> =>
{
    !design.value && (await fetchOptions());

    blueprint.value.options = blueprint.value.options.map((p) => ({
        ...p,
        key: options.value.find((option) => option.value === p.value)?.key ?? p.key,
    }));

    isLoaded.value = true;
};

init();
</script>

<template>
    <form-component-wrapper class="logito-choice-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
            <ideo-form-group>
                <ideo-select
                    v-if="isDropdownField"
                    v-model="valueSelect"
                    :id="blueprint.id"
                    :disabled="readonly"
                    :placeholder="placeholder"
                    :options="options"
                    :data-test="blueprint.id"
                    text-field="text"
                    value-field="value"
                    :reload-key="isLoaded"
                />
                <div v-else class="row pt-1" :class="{ [`row-cols-${blueprint.layout}`]: blueprint.layout > 0 }">
                    <div
                        v-for="(option, i) in options"
                        :key="i"
                        :class="{
                            'd-inline py-1 px-3 w-auto': blueprint.layout == 0,
                            'col px-3 py-1': blueprint.layout > 0,
                        }"
                    >
                        <ideo-form-radio
                            v-if="isRadioList"
                            v-model="valueRadiobox"
                            :disabled="readonly"
                            :value="design ? option.value : option.key"
                            :inline="blueprint.layout == 0"
                        >
                            {{ option.text }}
                        </ideo-form-radio>
                        <ideo-form-checkbox
                            v-if="isCheckboxList"
                            v-model="valueCheckbox"
                            :disabled="readonly"
                            :value="design ? option.value : option.key"
                            :inline="blueprint.layout == 0"
                        >
                            {{ option.text }}
                        </ideo-form-checkbox>
                    </div>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </ideo-form-group>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-options
                v-model="blueprint.options"
                :multiple="isCheckboxList"
                :can-select="!blueprint.defaultValue || isCheckboxList"
                :label="$t('[[[Opcje]]]')"
            />
            <field-condition
                v-if="!isCheckboxList"
                v-model="blueprint.defaultValue"
                :form="form"
                :label="$t('[[[Wartość domyślna]]]')"
                type="text"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-text
                    v-if="isDropdownField"
                    v-model="blueprint.placeholder[locale]"
                    :label="$t('[[[Tekst zastępczy]]]')"
                />
            </ideo-form-localize>
            <field-radiolist
                v-if="!isDropdownField"
                v-model="blueprint.layout"
                :options="layoutOptions"
                :label="$t('[[[Układ]]]')"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
