<script lang="ts" setup>
import { computed, inject, Ref } from 'vue';
import { FormType } from '@/helpers/Form';
import { formatDateTime } from '@/helpers/Utils';

const model = defineModel<any|any[]>({
    get(value)
    {
        if (props.range == true)
        {
            return gte.value && lte.value ? [gte.value, lte.value] : [];
        }

        return value;
    },
    set(value)
    {
        if (props.range == true)
        {
            if (value && Array.isArray(value) && value.length == 2)
            {
                gte.value = value.first();
                lte.value = value.last();
            }
            else
            {
                gte.value = null;
                lte.value = null;
            }
        }

        return value;
    }
});
const gte = defineModel<any>('gte');
const lte = defineModel<any>('lte');
const props = defineProps({
  "name": null,
  "label": null,
  "index": { default: 0 },
  "visible": { type: Boolean, default: true },
  "value": { default: undefined },
  "display": { type: [Function, String], default: undefined },
  "menuClass": { default: 'p-0' },
  "dropdown": { type: Boolean, default: true },
  "autoApply": { type: Boolean, default: true },
  "type": { default: 'datetime' },
  "minuteStep": { default: 5 },
  "zone": { default: 'local' },
  "range": { type: Boolean, default: false },
  "multi": { type: Boolean, default: false }
});
const emit = defineEmits(["clear", "click", "open", "close"]);

const datetimeFormat = computed(() => props.type === 'datetime' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');

// eslint-disable-next-line vue/no-dupe-keys
const display = computed(() =>
{
    if (model.value && Array.isArray(model.value) && model.value.length == 2)
    {
        return `${formatDateTime(model.value.first(), 'yyyy-MM-dd')} - ${formatDateTime(model.value.last(), 'yyyy-MM-dd')}`;
    }

    return formatDateTime(model.value, datetimeFormat.value, null, null);
});

const filter = inject<FormType<any>>('filter');
const dirty = inject<Ref<string[]>>('dirty');
const reset = inject<(name?: string) => void>('reset');
const apply = inject<() => void>('apply');

function onClear(propName: string)
{
    model.value = null;
    emit('clear', propName);
}
</script>

<template>
    <ideo-filter
        :value="model"
        :display="display"
        :name="props.name"
        :label="props.label"
        :index="props.index"
        :visible="props.visible"
        :menu-class="props.menuClass"
        :dropdown="props.dropdown"
        :auto-apply="props.autoApply"
        @clear="onClear"
        @click="emit('click')"
        @open="emit('open')"
        @close="emit('close')"
    >
        <slot name="header"></slot>
        <ideo-datetime
            v-model="model"
            :name="props.name"
            :type="props.type"
            :zone="props.zone"
            :minute-step="props.minuteStep"
            :range="props.range"
            :multi="props.multi"
            :inline="true"
            :preview="false"
        />
        <slot name="footer"></slot>
    </ideo-filter>
</template>

<style scoped>
:deep(.dp__theme_light) {
    --dp-background-color: var(--bs-body-bg) !important;
    --dp-menu-border-color: transparent !important;
}
:deep(.dp__theme_dark) {
    --dp-background-color: var(--bs-body-bg) !important;
    --dp-menu-border-color: transparent !important;
}
</style>
