import { Blueprint } from "../../base/blueprints/Blueprint";
import { BlueprintDefinition } from "../blueprints/BlueprintDefinition";

export interface HasDefinition extends Blueprint
{
    setDefinition(definition: BlueprintDefinition): void
}

export const instanceOfHasDefinition = (object: any): object is HasDefinition =>
{
    return object && 'setDefinition' in object;
};
