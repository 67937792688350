<template>
    <h5 class="me-1 my-0" v-if="title">
        <i class="me-2" :class="icon" v-if="icon"></i>
        <span></span>
    </h5>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import sitemap from '@/settings/logito-sitemap';

@Options({
    name: 'action-header'
})
export default class ActionHeader extends Vue
{
    @Prop({ default: false }) public autoHeader: boolean;
    @Prop() public headerTitle: string;
    @Prop() public headerIcon: string;

    public header: any = null;

    public created(): void
    {
        if (this.autoHeader)
        {
            const getHeaderNode = (items: any[], route: string): any =>
            {
                return items.reduce((value: any, item: any) =>
                {
                    if (item && item.route === route)
                    {
                        return item;
                    }

                    if (item && item.children && item.children.length > 0)
                    {
                        return getHeaderNode(item.children, route) || value;
                    }

                    return value;
                },
                null);
            };

            this.header = getHeaderNode(sitemap, this.$route.name.toString());
        }
    }

    public get title(): string
    {
        return this.header ? this.header.name : this.headerTitle;
    }

    public get icon(): string
    {
        return this.header ? this.header.icon : this.headerIcon;
    }
}
</script>
