<script lang="ts" setup>
import { computedAsync } from '@vueuse/core';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { handleException } from '@/helpers/Utils';
import Pager from '@/helpers/Pager';
import FieldSelect from './FieldSelect.vue';
import CategoriesService from '@/modules/studio/categories/services/CategoriesService';

defineOptions({
    name: 'field-category',
    components: {
        'field-select': FieldSelect
    }
});

const model = defineModel<number|null>();
const props = defineProps({
  "label": null,
  "invalidFeedback": { type: Function, default: null },
  "required": { type: Boolean, default: false },
  "disabled": { type: Boolean, default: false }
});

const { $alert } = useAlerts();
const { $log } = useLogging();

const options = computedAsync(async () =>
{
    try
    {
        const pager = new Pager(1, 10000, 'Name', 'ASC');
        const response = await CategoriesService.getOptions(pager, null, null);

        return [{ value: null, text: '' }, ...response.items];
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });

        return [];
    }
});
</script>

<template>
    <field-select
        v-model="model"
        :options="options"
        :label="props.label"
        :invalid-feedback="props.invalidFeedback"
        :required="props.required"
        :disabled="props.disabled"
        v-if="options"
    />
</template>
