<template>
    <div class="d-flex align-items-center">
        <!-- <div v-if="original[0]?.createdBy" class="d-flex align-items-center">
            <IsPrivateUser :value="original[0].value" :user="original[0].createdBy" />
        </div> -->

        <div v-if="current[0]?.createdBy" class="d-flex align-items-center">
            <IsPrivateUser :value="current[0].value" :user="current[0].createdBy" />
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { ChangelogDefinitionModel } from "@/modules/common/services/ChangelogService";
import { Options, Vue } from "vue-class-component";
import IsPrivateUser from "../components/IsPrivateUser.vue";
import { IsPrivateModel } from "../helpers/isPrivateHelper";

@Options({
    name: "IsPrivateFormatter",
    components: { IsPrivateUser },
})
export default class IsPrivateFormatter extends Vue
{
    @Prop()
    public original: IsPrivateModel[];

    @Prop()
    public current: IsPrivateModel[];

    @Prop({ default: null })
    public definition: ChangelogDefinitionModel;
}
</script>
