<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import { TaxLineModel } from '@/modules/low-code/services/TaxLineService';
import Decimal from 'decimal.js';
import Pager from '@/helpers/Pager';
import { cloneDeep } from 'lodash';
import { calculateSummary } from '@/components/common/dynamic-grid/helpers/CalculateSummary';

@Options({
    name: 'VatTable',
    emits: ['update:modelValue'],
})
export default class VatTable extends Vue
{
    @Prop({ default: null }) public modelValue: TaxLineModel[];
    @Prop() public module: string;
    @Prop() public label: string;
    @Prop() public showLabel: boolean;
    @Prop() public required: boolean;
    @Prop() public design: boolean;
    @Prop() public entry: any;

    public form: TaxLineModel = {
        vatAmount: null,
        netAmount: null,
        grossAmount: null,
        taxCode: null,
    };
    public pager: Pager = new Pager(1, 100);

    public summary: { netAmount: string; grossAmount: string; vatAmount: string } = {
        netAmount: '0',
        grossAmount: '0',
        vatAmount: '0',
    };

    public async saveTaxLine(): Promise<void>
    {
        try
        {
            this.$emit('update:modelValue', [...(this.modelValue ?? []), cloneDeep(this.form)]);
            await this.$nextTick();
            this.pager.setTotalRows(this.modelValue.length);
            this.recalculateSummary();
            this.clearForm();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public async deleteTaxLine(taxLine: TaxLineModel): Promise<void>
    {
        const lines = [...this.modelValue];

        lines.remove((p) => p == taxLine);
        this.$emit('update:modelValue', lines);
        await this.$nextTick();
        this.recalculateSummary();
    }

    public setTaxLinesValues(): void
    {
        if (!this.form.taxCode) return;

        const taxRate = this.form.taxCode['rate']
            ? new Decimal(this.form.taxCode['rate']).dividedBy(100).add(1).toDecimalPlaces(3)
            : 0;

        this.form.grossAmount = taxRate
            ? new Decimal(this.form.netAmount).times(taxRate).toDecimalPlaces(2).toString()
            : this.form.netAmount;
        this.form.vatAmount = taxRate
            ? new Decimal(this.form.grossAmount).minus(this.form.netAmount).toDecimalPlaces(2).toString()
            : 0;
    }

    public valueVatChanged(valueVat: number): void
    {
        if (!this.form.taxCode) return;

        this.form.grossAmount = new Decimal(this.form.netAmount).plus(valueVat).toDecimalPlaces(2).toString();
    }

    public grossAmountChanged(grossAmount: number): void
    {
        if (!this.form.taxCode) return;

        this.form.netAmount = new Decimal(grossAmount).minus(this.form.vatAmount).toDecimalPlaces(2).toString();
    }

    public clearForm(): void
    {
        this.form = {
            vatAmount: null,
            netAmount: null,
            grossAmount: null,
            taxCode: null,
        };
    }

    public recalculateSummary(): void
    {
        this.summary = calculateSummary(this.modelValue ?? []);
    }

    public tableErrors(index: number): any
    {
        for (const field in this.entry.errors)
        {
            const [indexError] = field.split('.').last();

            if (+indexError === index)
            {
                return { errors: this.entry.errors[field] };
            }
        }
    }

    @Watch('modelValue', { immediate: true })
    public onResourceChanged(): void
    {
        this.recalculateSummary();
    }
}
</script>

<template>
    <data-card :disable-header="!showLabel">
        <template #header>
            <strong>{{ label }}</strong> <var class="text-danger" v-if="required">*</var>
        </template>
        <template #default>
            <ideo-form-group :label="$t('[[[Wartość netto]]]')">
                <ideo-form-input
                    name="netAmount"
                    v-model="form.netAmount"
                    type="number"
                    step="any"
                    @update:modelValue="setTaxLinesValues"
                />
            </ideo-form-group>

            <ideo-form-group :label="$t('[[[Stawka VAT]]]')">
                <ideo-select
                    v-model="form.taxCode"
                    :placeholder="$t('[[[Stawka VAT]]]')"
                    :endpoint="`costs/dictionary/${module}/definition/tax-codes`"
                    @update:modelValue="setTaxLinesValues"
                />
            </ideo-form-group>

            <ideo-form-group :label="$t('[[[VAT]]]')">
                <ideo-form-input
                    name="vatAmount"
                    v-model="form.vatAmount"
                    type="number"
                    step="any"
                    @update:modelValue="valueVatChanged"
                ></ideo-form-input>
            </ideo-form-group>

            <ideo-form-group :label="$t('[[[Wartość brutto]]]')">
                <ideo-form-input name="grossAmount" v-model="form.grossAmount" type="number" step="any" />
            </ideo-form-group>

            <ideo-button
                variant="success"
                :disabled="!form.netAmount || !form.grossAmount || form.taxCode === null"
                name="btnSaveTaxLine"
                @click="saveTaxLine"
            >
                {{ $t('[[[Dodaj]]]') }}
            </ideo-button>

            <!-- Table -->
            <div class="scroll">
                <table v-if="!design && modelValue?.length" class="table table-bordered mt-3">
                    <thead>
                        <tr>
                            <th>{{ $t('[[[Stawka VAT]]]') }}</th>
                            <th>{{ $t('[[[Wartość netto]]]') }}</th>
                            <th>{{ $t('[[[VAT]]]') }}</th>
                            <th>{{ $t('[[[Wartość brutto]]]') }}</th>
                            <th class="actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in modelValue" :key="index">
                            <td class="text-nowrap">
                                {{ item.taxCode?.value }}

                                <small
                                    v-if="tableErrors(index)"
                                    class="form-text text-danger text-wrap table-error d-block"
                                >
                                    {{ tableErrors(index).errors[0] }}
                                </small>
                            </td>
                            <td class="text-nowrap text-lg-end">
                                {{ $filters.currencyFormat(item.netAmount) }}
                            </td>
                            <td class="text-nowrap text-lg-end">
                                {{ $filters.currencyFormat(item.vatAmount) }}
                            </td>
                            <td class="text-nowrap text-lg-end">
                                {{ $filters.currencyFormat(item.grossAmount) }}
                            </td>
                            <td class="text-nowrap text-lg-end">
                                <div class="d-flex justify-content-end">
                                    <ideo-dropdown dropleft boundary="body" variant="light" no-flip no-caret>
                                        <template #button-content>
                                            <i class="fas fa-ellipsis-vertical bigger"></i>
                                        </template>
                                        <ideo-dropdown-item-button @click="deleteTaxLine(item)">
                                            <i class="fas fa-times"></i> {{ $t('[[[Usuń]]]') }}
                                        </ideo-dropdown-item-button>
                                    </ideo-dropdown>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>
                                {{ $t('[[[Suma]]]') }}
                            </th>
                            <th class="text-nowrap text-lg-end">
                                {{ $filters.currencyFormat(summary.netAmount) }}
                            </th>
                            <th class="text-nowrap text-lg-end">{{ $filters.currencyFormat(summary.vatAmount) }}</th>
                            <th class="text-nowrap text-lg-end">{{ $filters.currencyFormat(summary.grossAmount) }}</th>
                            <th></th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </template>
    </data-card>
</template>

<style lang="scss" scoped>
.table {
    table-layout: fixed;
    width: 100%;
    min-width: 400px;

    thead,
    tfoot {
        tr {
            height: 36.5px;
            background: var(--border-color-nav);

            th {
                &.actions {
                    width: 58px;
                }
            }
        }
    }

    &--border {
        background: var(--border-color-nav);
        border: 1px solid var(--bs-table-border-color);
    }
}
</style>
