export default {
    namespaced: true,
    state: {
        routes: [],
        firstVisit: true,
        filtersActive: false
    },
    mutations: {
        SET_ROUTE: (state: State, routeName: string): void =>
        {
            if (state.routes.length > 10)
            {
                state.routes = state.routes.filter((route, index) => index > 5);
            }

            state.routes = [...state.routes, routeName];
        },
        POP_ROUTE: (state: State): void =>
        {
            state.routes.pop();
        },
        changeFilterActive(state: any, value: boolean): void
        {
            state.filtersActive = value;
        }
    },
    getters: {
        GET_ROUTES: (state: any): string =>
        {
            return state.routes;
        }
    }
};

interface State {
    routes: string[],
    firstVisit: boolean
}
