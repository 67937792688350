<template>
    <aside class="menu-widget bg-body overflow-hidden" v-click-away="onClickAway">
        <div class="menu-widget-up">
            <div class="menu-widget-header">
                <h5 class="menu-widget-title">{{ $t('[[[Widgety]]]') }}</h5>
                <button type="button" class="menu-widget-close-button" @click.stop="onClickAway">
                    <i class="fas fa-close"></i>
                </button>
            </div>
            <div class="menu-widget-search">
                <input
                    v-model="filter.search"
                    type="text"
                    class="form-control"
                    :placeholder="$t('[[[Wyszukaj...]]]')"
                    @input="searchWidgets"
                />
                <i class="icon fa-solid fa-magnifying-glass"></i>
            </div>
        </div>
        <div class="menu-widget-body scroll">
            <div
                v-for="widget in filtredAvailableWidgets"
                :key="widget.publicId"
                v-bind="getAttributes(widget)"
                class="new-widget grid-stack-item menu-widget-widget bg-body-secondary"
            >
                <div
                    aria-role="button"
                    class="grid-stack-item-content menu-widget-widget-content"
                    @click.stop="$emit('add', widget)"
                >
                    <div class="menu-widget-widget-left-section bg-body">
                        <img v-if="getWidgetImage(widget)" :src="getWidgetImage(widget)" alt="widget-image" />
                        <i v-else class="fas fa-empty-set fs-2"></i>
                    </div>
                    <div class="menu-widget-widget-right-section">
                        <h6>{{ getWidgetName(widget) }}</h6>
                        <span v-if="widget.description">{{ getWidgetDescription(widget) }}</span>
                    </div>
                </div>
            </div>
            <p v-if="!availableWidgets.length">{{ $t('[[[Nie znaleziono dostępnych widgetów]]]') }}</p>
        </div>
    </aside>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { getWidgetType } from '@/modules/core/home/inc/helpers';
import { Debounce, Emit, Prop } from '@/helpers/Decorators';
import { Form } from '@/helpers/Form';
import { directive as ClickAway } from 'vue3-click-away';
import HomeService, { FormFilter, Widget, APIWidget, WidgetTypeEnum } from '@/modules/core/home/services/HomeService';

@Options({
    name: 'MenuWidget',
    emits: ['close', 'add', 'getAvailableWidgets', 'refresh'],
    directives: { ClickAway },
})
export default class MenuWidget extends Vue
{
    @Prop({ default: (): Array<Widget> => []}) public widgets: Array<Widget>;

    public filter = Form.create<FormFilter>({ search: '' });
    public availableWidgets: Array<APIWidget> = [];

    public get filtredAvailableWidgets(): Array<APIWidget>
    {
        return this.availableWidgets?.filter(
            (availableWidget) => this.widgets.findIndex((widget) => widget.publicId === availableWidget.publicId) === -1
        );
    }

    public created(): void
    {
        this.getAvailableWidgets();
    }

    public async getAvailableWidgets(filter?: FormFilter): Promise<void>
    {
        try
        {
            const response = await HomeService.getAvailableWidgets(filter);

            this.availableWidgets = response;
            this.$emit('refresh');
        }
        catch (ex: any)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public getAttributes(widget: APIWidget): Record<string, string | boolean>
    {
        return {
            id: `i${widget.publicId}`,
            'gs-id': `i${widget.publicId}`,
            'gs-w': widget.width?.key,
            'gs-h': widget.height?.key,
            'gs-no-resize': true,
        };
    }

    public getWidgetImage(widget: APIWidget): string
    {
        const type = getWidgetType(widget);

        switch (type)
        {
            case WidgetTypeEnum.List:
                return '/static/img/widgets/list.png';
            case WidgetTypeEnum.Count:
                return '/static/img/widgets/counter.png';
            case WidgetTypeEnum.NewEmployees:
                return '/static/img/widgets/employees.png';
            case WidgetTypeEnum.ActiveSubstitutions:
                return '/static/img/widgets/substitutions.png';
            case WidgetTypeEnum.Chart:
                return '/static/img/widgets/chart.png';
            case WidgetTypeEnum.DoubleCounter:
                return '/static/img/widgets/double_counter.png';
            default:
                return '/static/img/widgets/double_counter.png';
        }
    }

    public getWidgetName(widget: APIWidget): string
    {
        return this.$i18n.currentTranslation(widget.name);
    }

    public getWidgetDescription(widget: APIWidget): string
    {
        return this.$i18n.currentTranslation(widget.description);
    }

    @Debounce(500)
    public searchWidgets(): void
    {
        this.getAvailableWidgets(this.filter.formatData());
    }

    @Emit('close')
    public async onClickAway(): Promise<void>
    {
        if (this.filter.search)
        {
            this.filter.clear();
            this.searchWidgets();
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-widget {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0px;
    width: 320px;
    transition: transform 0.25s ease-in;
    box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.15);
    z-index: 10001;
    display: flex;
    flex-direction: column;

    &-up {
        padding: 14.5px 10px;
    }

    &-header {
        position: relative;
        margin-bottom: 14.5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-title {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.25rem;
        margin: 0;
    }

    &-close-button {
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        background-color: transparent;
        border: none;
    }

    &-body {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 10px;
        padding: 0 10px 10px;
    }

    &-search {
        position: relative;
        i {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }

        input {
            padding-right: 30px;
        }
    }

    &-widget {
        border-radius: 4px;
        user-select: none;
        opacity: 1;
        cursor: pointer;
    }

    &-widget-content {
        padding: 10px;
        display: flex;
    }

    &-widget-left-section {
        width: 120px;
        height: 120px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        flex-shrink: 0;

        img {
            max-width: 100px;
        }
    }

    &-widget-right-section {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        overflow: hidden;

        h6 {
            font-size: 0.75rem;
            font-weight: 600;
            margin-bottom: 2px;
        }

        span {
            font-size: 0.688rem;
            word-wrap: break-word;
            margin-bottom: 2px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        b {
            font-weight: 600;
        }
    }
}
</style>
