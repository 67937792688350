<script lang="ts" setup>
import { ref, reactive, computed, onMounted, onUnmounted } from 'vue';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { useRoute } from 'vue-router';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { $t } from '@/plugins/localization';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { KeyValuePair } from '@/helpers/Interfaces';
import Cookies from 'js-cookie';
import * as pdfjsLib from 'pdfjs-dist';
import { FrontActionModal } from '@/components/common/dynamic-grid/helpers/GridEnums';
import { BpmnEmitOptions } from '@/modules/low-code/process/services/BpmnService';
import SimplySignService, { SignDocumentModel } from '@/modules/logito/signatures/services/SimplySignService';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

const { $events } = useEvents();
const { $alert } = useAlerts();
const route = useRoute();

const isPdf = ref<boolean>(false);
const modal = ref<IdeoModal>(null);
const canvasPDF = ref<HTMLCanvasElement>(null);
const redirectUrl = ref<string>(null);
const cards = ref<KeyValuePair[]>([]);
const document = ref<ArrayBuffer>(null);
const pager = reactive<Pager>(new Pager(1, 1, 'Id', 'DESC'));

const form = reactive(
    Form.create<SignDocumentModel>({
        pin: '',
        cardNumber: null,
        accessToken: null,
        sign: {
            custom: null,
        },
    })
);

const action = reactive(
    Form.create<BpmnEmitOptions>({
        id: '',
        licence: '',
        actionName: '',
        buttonId: '',
        displayMode: 'Modal',
        displaySize: 'Medium',
        title: $t('[[[Podpisz dokument]]]'),
        buttonName: $t('[[[Akceptuj]]]'),
        buttonVariant: 'success',
        buttonIcon: '',
        groupAction: false,
    })
);

const isSigned = computed(() => form.sign.custom !== null);
const isAccessToken = computed(() => form.accessToken);

const authorize = async () =>
{
    if (redirectUrl.value)
    {
        Cookies.set('docRedirectUrl', route.fullPath);
        window.open(redirectUrl.value, '_self');
    }
};

const readAsDataURL = (blob: Blob) =>
{
    return new Promise<ArrayBuffer>((resolve, reject) =>
    {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = reject;

        reader.readAsDataURL(blob);
    });
};

const renderPDF = async (_pageNumber?: number) =>
{
    const loadingTask = pdfjsLib.getDocument(document.value);

    try
    {
        // Wait for PDF to load
        const pdf = await loadingTask.promise;
        const numberOfPages = pdf.numPages;
        const pageNumber = _pageNumber ?? pdf.numPages;

        pager.setPageIndex(pageNumber);
        pager.setTotalRows(numberOfPages);

        // Fetch the first page
        const page = await pdf.getPage(pageNumber);

        // Specify the scale to render PDF
        const scale = 1;

        // Get viewport for the page at desired scale
        const viewport = page.getViewport({ scale });

        // Set canvas dimensions to match PDF viewport
        const canvas = canvasPDF.value;
        const context = canvas.getContext('2d');

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page onto canvas
        const renderContext = {
            canvasContext: context,
            viewport: viewport,
        };

        await page.render(renderContext).promise;
    }
    catch (ex: any)
    {
        $alert.warning($t('[[[Błąd podczas renderowania PDF]]]'));
    }
};

const handleClick = (event: MouseEvent) =>
{
    const canvas = canvasPDF.value;
    const rect = canvas.getBoundingClientRect();
    const x = Math.round(event.clientX - rect.left);
    const y = Math.round(event.clientY - rect.top);
    const page = pager.getPageIndex();

    form.sign.custom = { x, y, page };
};

const fetchDocument = async () =>
{
    const response = await SimplySignService.getDocument(action.licence, action.id);

    isPdf.value = response.type === 'application/pdf';

    const data = await readAsDataURL(response);

    document.value = data;
};

const fetchRedirectUrl = async () =>
{
    try
    {
        const response = await SimplySignService.getRedirectUrl(action.id, action.buttonId, action.licence);

        redirectUrl.value = response;
    }
    catch (ex: any)
    {
        if (ex.code === 400 || ex.code === 422 || ex.code === 500)
        {
            $alert.warning($t('[[[Nie udało się pobrać url do podpisu dokumentu]]]'));
        }
    }
};

const fetchUserCards = async () =>
{
    const response = (await SimplySignService.getUserCards(form.accessToken)) ?? [];

    cards.value = response;

    if (cards.value.length > 0) form.cardNumber = cards.value[0];
};

const onSubmit = async () =>
{
    try
    {
        form.wait();

        const model = cloneDeep(form.formatData());

        await SimplySignService.signDocument(action.id, action.buttonId, action.licence, model);

        $alert.success($t('[[[Złożenie podpisu zostało zakończone sukcesem]]]'));
        modal.value.hide();

        setTimeout(() =>
        {
            $events.$emit('refetchData');
            form.reset();
        }, 500);
    }
    catch (ex: any)
    {
        if (ex.code === 400)
        {
            $alert.warning(ex.message);
        }
        else if (ex.code === 422)
        {
            form.$errors.record(ex.data.errors);
            $alert.warning($t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]'));
        }
        else if (ex.code === 500)
        {
            $alert.error($t('[[[Wystąpił problem w komunikacji z Api dostawcy podpisów. Kliknij ponownie Podpisz.]]]'));
        }
    }
    finally
    {
        form.continue();
    }
};

onMounted(() =>
{
    pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.mjs',
        import.meta.url,
    ).toString();

    $events.$on(FrontActionModal.SignDocumentSimplySign, async (_action: BpmnEmitOptions) =>
    {
        action.withData(_action);
        form.accessToken = Cookies.get('simplySignAccessToken');

        await Promise.allSettled([fetchDocument(), fetchRedirectUrl()]);

        if (isPdf.value && form.accessToken)
        {
            fetchUserCards();
            renderPDF();
        }

        modal.value.show();
    });
});

onUnmounted(() => $events.$off(FrontActionModal.SignDocumentSimplySign));
</script>

<template>
    <ideo-modal
        ref="modal"
        :title="action.title"
        centered
        :size="isAccessToken ? 'xxl' : 'lg'"
        @hidden="form.clear()"
    >
        <template #default>
            <template v-if="isPdf">
                <ideo-form
                    v-if="isAccessToken"
                    :id="FrontActionModal.SignDocumentSimplySign"
                    @input="form.$errors.clear($event.target.name)"
                    @submit.prevent="onSubmit"
                >
                    <div class="row">
                        <div class="col-lg-6 col-xl-4">
                            <ideo-form-group
                                :label="$t('[[[Karta]]]')"
                                :invalid-feedback="form.$errors.first('cardNumber')"
                                :state="form.$errors.state('cardNumber')"
                                required
                            >
                                <ideo-select v-model="form.cardNumber" boundary="body" :options="cards" />
                            </ideo-form-group>
                            <ideo-form-group
                                :label="$t('PIN')"
                                :invalid-feedback="form.$errors.first('pin')"
                                :state="form.$errors.state('pin')"
                                required
                            >
                                <ideo-form-input
                                    v-model="form.pin"
                                    type="password"
                                    name="pin"
                                    autocomplete="new-password"
                                />
                            </ideo-form-group>
                        </div>
                        <div class="col-lg-6 col-xl-8 d-flex flex-column position-relative">
                            <div class="align-self-center">
                                <div class="d-flex justify-content-center">
                                    <ideo-form-group
                                        :invalid-feedback="form.$errors.first('sign.custom')"
                                        :state="form.$errors.state('sign.custom')"
                                    >
                                        <data-card>
                                            <template #header>
                                                <strong>{{ $t('[[[Podgląd]]]') }}</strong>
                                            </template>
                                            <template #default>
                                                <div class="position-relative h-100">
                                                    <strong
                                                        v-if="form.sign.custom?.page === pager.getPageIndex()"
                                                        :style="{
                                                            top: `${form.sign.custom.y}px`,
                                                            left: `${form.sign.custom.x}px`,
                                                            fontFamily: 'helvetica',
                                                            fontSize: '8px',
                                                        }"
                                                        class="position-absolute text-danger"
                                                    >
                                                        {{ $t('[[[Podpis]]]') }}<br />
                                                        {{ DateTime.now().toFormat('yyyy.MM.dd HH:mm:ss') }}
                                                    </strong>
                                                    <canvas ref="canvasPDF" @click="handleClick"></canvas>
                                                </div>
                                            </template>
                                            <template #footer>
                                                <div class="d-flex flex-nowrap justify-content-between w-100">
                                                    <pagination :pager="pager" @change="renderPDF" />
                                                    <div class="d-flex align-items-center">
                                                        <pagination-info
                                                            :pager="pager"
                                                            class="ms-2 d-none d-sm-block d-md-block d-lg-block"
                                                        />
                                                    </div>
                                                </div>
                                            </template>
                                        </data-card>
                                    </ideo-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </ideo-form>
                <div v-else class="h6">
                    {{ $t('[[[Aby podpisać dokument zaloguj się do usługi SimplySign klikając przycisk poniżej.]]]') }}
                </div>
            </template>
            <template v-else>
                <ideo-alert show variant="info" class="mb-2">
                    {{ $t('[[[Nieprawidłowy format dokumentu. Aby podpisać dokument przekonwertuj go na format PDF]]]') }}
                </ideo-alert>
            </template>
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button v-if="!isAccessToken" variant="success" :disabled="!redirectUrl || !isPdf" @click="authorize">
                {{ $t('[[[Zaloguj do SimplySign]]]') }}
            </ideo-button>
            <ideo-button
                v-else
                type="submit"
                :variant="action.buttonVariant"
                :form="FrontActionModal.SignDocumentSimplySign"
                :disabled="!form.active() || !isPdf || !isSigned"
            >
                <i v-if="action.buttonIcon" class="me-2" :class="action.buttonIcon"></i>
                <span>{{ action.buttonName }}</span>
            </ideo-button>
            <ideo-button variant="secondary" @click="cancel">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>
