<script lang="ts" setup>
import { computed, ref, getCurrentInstance } from 'vue';
import max from 'lodash/max';
import { increment as incrementFn } from '../blueprints/numeric';
import { MinMaxValue } from '@/components/builder/form/types/MinMaxValue';

const instance = getCurrentInstance();

defineOptions({
    name: 'field-minmax'
});

const props = defineProps({
  "label": null,
  "modelValue": null,
  "append": { default: null },
  "naturals": { type: Boolean, default: false },
  "step": { default: 0 },
  "precision": { default: 0 },
  "min": { default: () => Number.MIN_SAFE_INTEGER + 1 },
  "max": { default: () => Number.MAX_SAFE_INTEGER - 1 }
});

const emit = defineEmits(["update:modelValue"]);

const focused = ref(false);

const uid = computed(() => `form-field-${instance.uid}`);

const increment = computed(() => incrementFn(props.step, props.precision));

const rangeMin = computed(() => props.naturals === true ? Math.max(0, props.min) : props.min);
const rangeMax = computed(() => props.max);
const minValue = computed(() => (props.modelValue.min || 0).toFixed(props.precision));
const maxValue = computed(() => (props.modelValue.max || 0).toFixed(props.precision));

const triggerInput = (value: MinMaxValue): void =>
{
    emit('update:modelValue', value);
};

const update = (value: MinMaxValue): void =>
{
    if (!focused.value)
    {
        triggerInput(value);
    }
};

const updateMin = (e: any): void =>
{
    const value = parseFloat(e.target.value || 0);

    update({
        min: max([rangeMin.value, value]),
        max: max([props.modelValue.max, value])
    });
};

const updateMax = (e: any): void =>
{
    const value = parseFloat(e.target.value || 0);

    update({
        min: props.modelValue.min,
        max: max([props.modelValue.min, value])
    });
};

const focus = (e: any): void =>
{
    focused.value = true;
};

const blur = (e: any): void =>
{
    focused.value = false;

    if ((e.target.id as string).endsWith('min'))
        updateMin(e);

    if ((e.target.id as string).endsWith('max'))
        updateMax(e);
};
</script>

<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div class="row">
            <div class="col-6">
                <ideo-input-group :prepend="$t('[[[od]]]')" :append="append">
                    <input type="number" :min="rangeMin" :max="rangeMax" :step="step" :id="`${uid}-min`" class="form-control" :value="minValue" @input="updateMin" @focus="focus" @blur="blur">
                </ideo-input-group>
            </div>
            <div class="col-6">
                <ideo-input-group :prepend="$t('[[[do]]]')" :append="append">
                    <input type="number" :min="modelValue.min" :max="rangeMax" :step="increment" :id="`${uid}-max`" class="form-control" :value="maxValue" @input="updateMax" @focus="focus" @blur="blur">
                </ideo-input-group>
            </div>
        </div>
    </div>
</template>
