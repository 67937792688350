export class StateStore
{
    private static readonly DESIGN: string = 'design';
    private static readonly INTERNAL: string = 'internal';

    private state: Record<string, boolean> = {};

    public constructor()
    {
        this.state[StateStore.DESIGN] = false;
        this.state[StateStore.INTERNAL] = false;
    }

    public get design(): boolean
    {
        return this.state[StateStore.DESIGN];
    }

    public set design(value: boolean)
    {
        this.state[StateStore.DESIGN] = value;
    }

    public get internal(): boolean
    {
        return this.state[StateStore.INTERNAL];
    }

    public set internal(value: boolean)
    {
        this.state[StateStore.INTERNAL] = value;
    }
}
