<template>
    <div class="d-flex flex-column small">
        <div class="d-flex align-items-center mb-2">
            <div class="changelog-label">
                <strong>{{ $t("[[[Spółka]]]") }}:</strong>
            </div>
            <span class="original-value" v-if="original">{{ original.company.value }}</span>
            <span class="current-value">{{ current?.company?.value }}</span>
        </div>
        <div class="d-flex align-items-center">
            <div class="changelog-label">
                <strong>{{ $t("[[[Rejestr]]]") }}:</strong>
            </div>
            <span class="original-value" v-if="original">{{ original.register.value }}</span>
            <span class="current-value">{{ current.register.value }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'DocumentAuditFormatter'
})
export default class DocumentAuditFormatter extends Vue
{
    @Prop({ default: null })
    public current: Record<string, any>;

    @Prop({ default: null })
    public original: Record<string, any>;

    @Prop({ default: null })
    public definition: ChangelogDefinitionModel;
}
</script>
