<script lang="ts" setup>
import { computed } from 'vue';
import { Option } from '@/helpers/Interfaces';
import { DetailsBuilderContract } from '@/components/builder/details';
import { FormatBlueprint } from '@/components/builder/details/blueprints/FormatBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import FieldChoice from './FieldChoice.vue';
import FieldCondition from './FieldCondition.vue';

defineOptions({
    name: 'field-visible',
    components: {
        'field-choice': FieldChoice,
        'field-condition': FieldCondition
    }
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "label": { default: null }
});
const blueprint = computed(() => props.blueprint);

const formatOptions: Option<AlwaysChoice | WhenChoice>[] = [
    { value: AlwaysChoice.Always, text: '[[[Zawsze]]]' },
    { value: WhenChoice.When, text: '[[[Warunkowo]]]' }
];
</script>

<template>
    <div>
        <field-choice v-model="blueprint.format" :options="formatOptions" :label="label || $t('[[[Widoczność formatowania]]]')" />
        <field-condition v-model="blueprint.formatWhen" :label="label" :show-label="false" :details="details" :when="blueprint.format" />
    </div>
</template>
