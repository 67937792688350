import { KeyValuePair } from "@/helpers/Interfaces";

export interface InvoicesReturnTypesModel {
    id: number;
    text: string;
    permission: string;
}

enum InvoiceType {
    CostInvoice = 1,
    WithOrder = 2,
    WithOrderCorrection = 3,
    Correction = 4,
}

const InvoicesReturnToTypes: InvoicesReturnTypesModel[] = [
    { id: 1, text: 'Zwróć do edycji', permission: 'canReturnToEdit' },
    { id: 2, text: 'Zwróć do opisu', permission: 'canReturnToDescription' }
];

const InvoiceStatusVariants: KeyValuePair[] = [
    { key: 'Invalid', value: 'danger' },
    { key: 'HeaderEdit', value: 'secondary' },
    { key: 'HeaderEditing', value: 'secondary' },
    { key: 'InDescription', value: 'warning' },
    { key: 'BossAccept', value: 'info' },
    { key: 'LinesAccept', value: 'info' },
    { key: 'Accounting', value: 'secondary' },
    { key: 'Accounted', value: 'success' },
    { key: 'PresidentAccepted', value: 'success' },
    { key: 'ToPresidentAccept', value: 'info' },
    { key: 'CoordinatorAwaiting', value: 'secondary' },
    { key: 'Deleted', value: 'danger' },
    { key: null, value: 'secondary' }
];

const HistoryStatusVariants: KeyValuePair[] = [
    { key: 'Success', value: 'success' },
    { key: 'Fail', value: 'danger' }
];

const PaymentStatus: KeyValuePair[] = [
    { key: 'Paid', value: 'success' },
    { key: 'NotPaid', value: 'danger' }
];

const BpmnInvoiceDescriptionFormType: string = 'invoice-description';

export { InvoiceType, InvoicesReturnToTypes, InvoiceStatusVariants, HistoryStatusVariants, PaymentStatus, BpmnInvoiceDescriptionFormType };
