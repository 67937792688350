<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import { KeyValuePair } from '@/helpers/Interfaces';

const props = defineProps({
  "property": { default: '' },
  "features": { default: (): KeyValuePair[] => [] },
  "isOnGrid": { type: Boolean, default: false }
});

const el = ref<HTMLSpanElement>(null);
const isTextClamped = ref(false);

const value = computed(() => cleanHTML(props.property));

const cleanHTML = (html: string): string =>
{
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const tempDiv = document.createElement('div');

    tempDiv.innerHTML = doc.body.innerHTML;

    return tempDiv.textContent || "";
};

const checkIfClamped = () =>
{
    if (el.value)
    {
        const element = el.value;

        isTextClamped.value = element.scrollHeight > element.clientHeight;
    }
};

useResizeObserver(el, checkIfClamped);
</script>

<template>
    <div>
        <template v-if="property">
            <div v-if="!isOnGrid" v-html="property" class="rich-text border p-2"></div>
            <template v-else>
                <ideo-tooltip v-if="isTextClamped" :tooltip="value" multiline position="bottom">
                    <span ref="el" class="property-value">{{ value }}</span>
                </ideo-tooltip>
                <span v-else ref="el" class="property-value">{{ value }}</span>
            </template>
        </template>
        <span v-else>-</span>
    </div>
</template>

<style scoped>
.rich-text {
    max-width: 300px;
}

:deep(p) {
    margin: 0;
    padding: 0;
}

.property-value {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
