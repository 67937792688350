<template>
    <div class="filter-dropdown-object">
        <div class="basic-input-wrapper" :class="{ 'filter-selected': !!modelValue}">
            <input class="text-dark" :type="type" v-model="value" @input="valueChanged" :placeholder="placeholder + '...'" @keydown.enter="applyFilters" />
            <span class="fas fa-search d-block px-2" v-if="!modelValue" />
            <button class="border-0 bg-transparent text-dark px-2" v-else @click="clearInput">
                <span class="fas fa-times" />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { $t } from "@/plugins/localization";
@Options({
    name: 'FilterBasicInput',
    emits: ['update:modelValue']
})
export default class FilterBasicInput extends Vue
{
    @Prop({ default: "" })
    public modelValue: string;

    public value: string = "";

    @Prop({ default: 'text' })
    public type: string;

    @Prop({ default: (): string => $t("[[[Wyszukaj]]]") })
    public placeholder: string;

    @Prop({ default: (): string => null })
    public filterEmitId: string;

    public valueChanged(): void
    {
        this.$emit("update:modelValue", this.value);
    }

    public applyFilters(): void
    {
        if (this.filterEmitId)
            this.$events.$emit(this.filterEmitId);
        else
            this.$events.$emit('applyFilters');
    }

    public clearInput(): void
    {
        this.value = "";
        this.$emit("update:modelValue", "");
        this.$events.$emit('refetchData');
    }
}
</script>

<style lang="scss" scoped>
    .basic-input-wrapper{
        display: flex;
        min-width: 100px;
        align-items: center;
        height: 100%;
        border-radius: 4px;
        border: 2px solid rgb(158, 156, 156, 0.4);
        padding: 6px 12px;
        @include media-breakpoint-up(sm){
            padding: 5px 12px;
        }
        &.filter-selected{
            border-color: $primary;
        }
        &:focus-within{
            border-color: $primary;
        }
        input{
            border: 0;
            flex-grow: 1;
            padding-inline: 5px;
            background-color: transparent;
        }
    }
    .default-theme, .c-legacy-theme{
        .basic-input-wrapper{
            input{
                &::placeholder{
                    color: $dark;
                }
            }
        }
    }
    .dark-theme{
        .basic-input-wrapper{
            input{
                &::placeholder{
                    color: $light;
                }
            }
        }
    }
</style>
