import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

const requireComponents = import.meta.glob('@/components/forms/blueprints/**/Blueprint.vue', { eager: true });
const components = Object.values(requireComponents);

const requireFilters = import.meta.glob('@/components/forms/blueprints/**/Filter.vue', { eager: true });
const filters = Object.values(requireFilters);

const requirePresenters = import.meta.glob('@/components/forms/blueprints/**/Presenter.vue', { eager: true });
const presenters = Object.values(requirePresenters);

const requireTypes = import.meta.glob('@/components/forms/blueprints/**/index.ts', { eager: true });
const definitions = Object.values(requireTypes).select((p: any) => p.Definition).toArray();
const types = Object.assign({}, ...Object.values(requireTypes).map((module: any) =>
{
    const type = upperFirst(camelCase(`${module.Definition.type}-type`));

    return {[type]: module[type]};
}));

export {
    components,
    filters,
    presenters,
    definitions,
    types
};
