<template>
    <div class="substitute__item">
        <div v-if="tooltip" @mouseover="tooltipVisible = true" @mouseleave="tooltipVisible = false">
            <div class="substitute__icon bg-dark">
                <i class="fas fa-z text-white"></i>
            </div>
            <div class="substitute__tooltip" :class="{ visible: tooltipVisible }">
                <div class="text-start mb-0">
                    <strong class="d-block">{{ $t('[[[zastępował: ]]]') }}{{ user }}</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: "Substitute",
})
export default class Substitute extends Vue
{
    @Prop({ default: null }) public user: string;
    @Prop({ default: true }) public tooltip: boolean;

    public tooltipVisible: boolean = false;
}
</script>

<style lang="scss" scoped>
.substitute {
    &__item {
        position: relative;
        margin-right: 15px;
    }
    &__icon {
        position: absolute;
        top: 50%;
        right: -6px;
        width: 15px;
        height: 15px;
        transform: translateY(-50%);
        border-radius: 9999px;
        font-size: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__tooltip {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        padding: 5px 10px;
        white-space: nowrap;
        border-radius: 5px;
        background: $black;
        opacity: 0.9;
        z-index: 99;
        color: $white;
        font-size: 11px;

        @media screen and (max-width: 640px) {
            top: -100%;
            max-width: 250px;
            white-space: initial;
        }

        &.visible,
        &:focus-within {
            display: block;
        }

        .popover {
            background: $gray-900;
            border: 1px solid #333;
            b {
                color: $white;
            }
            left: auto !important;
        }
    }
}
</style>
