import { RouteRecordRaw } from 'vue-router';
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

const routes: Array<RouteRecordRaw> = [
    // --------------------------------------------------------------------------
    // Kontrahenci
    // --------------------------------------------------------------------------
    {
        path: 'bs-partners',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'bs-partners-list',
                name: 'crm-bs-partners-list',
                meta: { auth: true, breadcrumbs: '[[[Lista kontrahentów]]]' },
                component: () => import('@/modules/logito/crm/views/List.vue'),
            },
            {
                path: 'bs-partners-form',
                name: 'crm-bs-partners-form',
                meta: { auth: true, breadcrumbs: '[[[Dodaj kontrahenta]]]' },
                component: () => import('@/modules/logito/crm/views/Form.vue'),
            },
            {
                path: 'bs-partners-form/:publicId',
                name: 'crm-bs-partners-list-edit',
                meta: { auth: true, breadcrumbs: '[[[Edytuj kontrahenta]]]' },
                props: true,
                component: () => import('@/modules/logito/crm/views/Form.vue'),
            },
            {
                path: 'bs-partners-details/:publicId',
                name: 'crm-bs-partners-details',
                meta: { auth: true, breadcrumbs: '[[[Szczegóły kontrahenta]]]' },
                component: () => import('@/modules/logito/crm/views/Details.vue'),
            },
        ],
    },
];

export default routes;
