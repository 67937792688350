<script lang="ts" setup>
import { IMetaModel } from '@/modules/core/common/services/GridService';

defineOptions({
    name: 'grid-actions',
});

const props = defineProps({
  "licence": { default: '' },
  "actions": { default: () => ({}) },
  "moduleId": { default: '' },
  "except": { default: (): string[] => [] }
});
</script>

<template>
    <view-action-button
        v-if="Object.entries(props.actions)?.length"
        :permissions="props.actions"
        :licence="props.licence"
        :module-id="props.moduleId"
        :except-list-headers="props.except"
    />
</template>
