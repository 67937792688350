<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { AuthModel } from "@/modules/core/auth/services/AuthService";
declare const OneSignal: any;

@Options({})
export default class PushNotifications extends Vue
{
    public get userIdentity(): AuthModel
    {
        return this.$auth.user();
    }

    public mounted(): void
    {
        OneSignal.push(() =>
        {
            OneSignal.init({
                appId: import.meta.env.VITE_APP_ONESIGNAL,
                allowLocalhostAsSecureOrigin: !import.meta.env.PROD
            });

            OneSignal.on('subscriptionChange', (isSubscribed: boolean) =>
            {
                if (isSubscribed == true && this.userIdentity)
                {
                    OneSignal.sendTags({
                        subscriberId: this.userIdentity.publicId
                    });
                }
            });
        });

        this.subscribe();
    }

    public subscribe(): void
    {
        if (!OneSignal.isPushNotificationsSupported())
        {
            this.$alert.success(this.$t('[[[Przeglądarka z której korzystasz nie wspiera powiadomień PUSH]]]'));

            return;
        }

        OneSignal.showNativePrompt();
    }
}
</script>
