export default {
    namespaced: true,
    state: {
        expanded: [],
        activeNodeId: null
    },
    getters: {
        getExpandedNodes(state: State): number[]
        {
            return state.expanded;
        },
        getActiveNode(state: State): number
        {
            return state.activeNodeId;
        }
    },
    mutations: {
        SET_EXPANDED(state: State, nodes: number[]): void
        {
            state.expanded = nodes;
        },
        SET_ACTIVE_NODE(state: State, id: number): void
        {
            state.activeNodeId = id;
        }
    }
};

interface State
{
    expanded: number[],
    activeNodeId: number,
}
