export class EventManager
{
    private subscriptions = new Map<string, Function[]>();

    public constructor()
    {
    }

    public subscribe(eventName: string, callback: Function): void
    {
        const callbacks = this.subscriptions.get(eventName);

        if (callbacks)
        {
            callbacks.push(callback);
        }
        else
        {
            this.subscriptions.set(eventName, [callback]);
        }
    }

    public emit(eventName: string, ...args: any[]): void
    {
        const subscriptions = this.subscriptions.get(eventName);

        if (subscriptions)
        {
            subscriptions.forEach(callback => callback(...args));
        }
    }

    public release(eventName: string, callback: Function): void
    {
        const subscriptions = this.subscriptions.get(eventName);

        if (subscriptions)
        {
            this.subscriptions.set(eventName, subscriptions.filter(p => p !== callback));
        }
    }
}

export class Events
{
    public static FOCUS = 'focus';
    public static INSERT = 'insert';
}
