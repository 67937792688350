<script lang="ts" setup>
import { computed, inject, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { OneDriveType } from '.';
import { IMetaModel } from '@/modules/core/common/services/GridService';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DetailsBuilderContract } from '@/components/builder/details';
import List from './components/List.vue';
import properties from '../../properties';

defineOptions({
    name: 'OneDriveBlueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const route = useRoute();

const actions = inject<Ref<Record<string, IMetaModel>>>('actions', null);

const blueprint = computed(() => props.blueprint);
const licence = computed(() => route.params.licence as string);
const publicId = computed(() => route.params.publicId as string);
const design = computed(() => props.details.designMode());
</script>

<template>
    <dynamic-details-component-wrapper :details="details" :parent="parent" :blueprint="blueprint">
        <template #default>
            <List
                v-if="!design"
                :blueprint-name="blueprint.name"
                :document-id="publicId"
                :parent-type="licence"
                :actions="actions"
            />
            <div v-else>
                <list-view :items="[]" :preload="false">
                    <template #row>
                        <list-view-item :title="$t('[[[Data udostępnienia]]]')" />
                        <list-view-item :title="$t('[[[Dokument]]]')" />
                        <list-view-item :title="$t('[[[Udostępniono dla]]]')" />
                        <list-view-item :title="$t('[[[Status]]]')" />
                        <list-view-item :title="$t('[[[Data zatrzymania udostępniania]]]')" />
                    </template>
                </list-view>
            </div>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <dynamic-details-accordion>
                <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                    <field-visible :details="details" :blueprint="blueprint" />
                </dynamic-details-accordion-item>
            </dynamic-details-accordion>
        </template>
    </dynamic-details-component-wrapper>
</template>
