import { KeyValuePair } from '@/helpers/Interfaces';
import { axios } from '@/plugins/axios';
import { merge } from 'lodash';

export default class FiltersDefinitionService
{
    public static async fetchFiltersDefinition(requestUrl: string, actionName: string = "list", parentType: string = null, parentId: string = null, sitemapId: string = null, extraParams?: any): Promise<FilterDefinitionModel>
    {
        if (parentType != null)
        {
            return (await axios.get(`simple-schema/${parentType}/${parentId}/${requestUrl}/param`, {
                params: merge({ sitemapId }, extraParams)
            })).data;
        }

        return (await axios.get(`simple-schema/${requestUrl}/${actionName}/param`, {
            params: merge({ sitemapId }, extraParams)
        })).data;
    }

    public static async fetchFiltersDefinitionNew(url: string): Promise<FilterDefinitionModel>
    {
        return (await axios.get(`simple-schema/${url}/param`)).data;
    }

    public static async saveFilterDefinition(definition: FilterDefinitionModel, requestUrl: string, actionName: string = "list", parentType: string = null, parentId: string = null, sitemapId: string = null): Promise<FilterDefinitionModel>
    {
        if (parentType != null)
        {
            return (await axios.post(`simple-schema/${parentType}/${parentId}/${requestUrl}/param`, {
                filters: definition
            }, { params: { sitemapId }})).data;
        }

        return (await axios.post(`simple-schema/${requestUrl}/${actionName}/param`, {
            filters: definition
        }, { params: { sitemapId }})).data;
    }

    public static async saveFilterDefinitionNew(endpoint: string, definition: FilterDefinitionModel, sitemapId: string = null): Promise<FilterDefinitionModel>
    {
        return (await axios.post(`simple-schema/${endpoint}/param`, {
            filters: definition
        }, { params: { sitemapId }})).data;
    }

    public static async deleteConfiguration(requestUrl: string, actionName: string = "list", parentType: string = null, parentId: string = null, sitemapId: string = null): Promise<void>
    {
        if (parentType != null)
        {
            return (await axios.delete(`simple-schema/${parentType}/${parentId}/${requestUrl}/param`, {
                params: { sitemapId }
            })).data;
        }

        return (await axios.delete(`simple-schema/${requestUrl}/${actionName}/param`)).data;
    }

    public static async deleteConfigurationNew(endpoint: string, sitemapId: string = null): Promise<void>
    {
        return (await axios.delete(`simple-schema/${endpoint}/param`, {
            params: { sitemapId }
        })).data;
    }
}

export interface FilterTypeModel{
    baseType: string,
    features: KeyValuePair[]
}

export type Filter = { type: FilterTypeModel, headerName: string, order: number, visibility: boolean, dataSource: string };

export interface FilterDefinitionModel
{
    [filterName: string]: Filter
}

export interface AttributesModel{
    is?: string,
    property: any
    features: KeyValuePair[]
}
