import { KeyValuePair } from '@/helpers/Interfaces';

const DocumentsStatusVariants: KeyValuePair[] = [
    { key: 'OcrEnded', value: 'warning' },
    { key: 'None', value: 'danger' },
    { key: null, value: 'secondary' },
    { key: 'Deleted', value: 'danger' },
    { key: 'Accepted', value: 'success' },
    { key: 'Draft', value: 'warning' },
    { key: 'OcrProgress', value: 'secondary' },
    { key: 'AwaitingAccept', value: 'secondary' },
];

export { DocumentsStatusVariants };
