<template>
    <div class="d-flex flex-wrap file-formatter" v-if="isArray">
        <file-list class="text-danger me-1" :files="original" v-if="original && original.length" />
        <file-list class="text-success me-1" :files="current" v-if="current && current.length" />
    </div>
    <div class="d-flex flex-wrap file-formatter" v-else>
        <file-list class="text-danger me-1" :files="[original]" v-if="original && original.contentLength" />
        <file-list class="text-success me-1" :files="[current]" v-if="current && current.contentLength" />
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Resource } from '@/modules/core/files/services/StorageService';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';
import FileList from '@/components/common/FileList.vue';
@Options({
    name: 'FileFormatter',
    components: { FileList }
})
export default class FileFormatter extends Vue
{
    @Prop({ default: null })
    public original: Resource | Resource[];

    @Prop({ default: null })
    public current: Resource | Resource[];

    @Prop({ default: "" })
    public title: string;

    @Prop()
    public definition: ChangelogDefinitionModel;

    public get isArray(): boolean
    {
        return Array.isArray(this.original) || Array.isArray(this.current);
    }
}
</script>
