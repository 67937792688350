<template>
    <div class="date-wrapper filter-dropdown-object">
        <ideo-dropdown
            variant="secondary"
            :toggle-class="toggleClasses"
            :menu-class="menuClasses"
            :container-class="containerClasses"
            boundry="body"
            no-flip
            no-caret
            :top-offset="4"
            @show="dropdownShown"
            @hide="opened = false"
        >
            <template #button-content>
                <div class="label-value">
                    <span
                        class="label"
                        :class="{
                            'label--bigger': mode === 'range' ? !dateFrom && !dateTo : !daysBeforeNow && !daysAfterNow,
                        }"
                    >
                        {{ label }}
                    </span>
                    <span v-if="mode === 'range' ? dateFrom || dateTo : daysBeforeNow || daysAfterNow" class="value">
                        {{ buttonDateValue }}
                    </span>
                </div>
                <i class="fas fa-chevron-down arrow ms-2" :class="{ 'opened-arrow': opened }"></i>
                <div
                    v-if="mode === 'range' ? !!dateFrom || !!dateTo : !!daysBeforeNow || !!daysAfterNow"
                    class="btn-remove ms-2"
                    @click.stop="clearSearchData"
                >
                    <i class="fas fa-times"></i>
                </div>
            </template>
            <div
                class="date-wrapper-dropdown-content w-100 d-flex align-items-center flex-column p-2"
                style="width: max-content"
            >
                <ideo-form-radio-group class="mb-2" stacked>
                    <ideo-form-radio v-model="mode" :name="`filter-data-mode-${dataId}`" value="range">
                        {{ $t('[[[Zakres dat]]]') }}
                    </ideo-form-radio>
                    <ideo-form-radio v-model="mode" :name="`filter-data-mode-${dataId}`" value="computed">
                        {{ $t('[[[Wyliczone daty]]]') }}
                    </ideo-form-radio>
                </ideo-form-radio-group>
                <label class="w-100 mb-0 mb-md-2 text-center me-2 me-md-0">{{ $t('[[[Pomiędzy]]]') }}:</label>
                <div class="width-between">
                    <div v-if="mode === 'range'" class="d-flex flex-column flex-md-row justify-content-center gap-2">
                        <ideo-datetime v-model="dateFrom" :placeholder="dateFromPlaceholder" @update:modelValue="dateFromChanged" />
                        <span class="align-self-center">-</span>
                        <ideo-datetime v-model="dateTo" :placeholder="dateToPlaceholder" @update:modelValue="dateToChanged" />
                    </div>
                    <div v-else class="d-flex flex-column flex-md-row justify-content-center gap-2">
                        <ideo-form-group :label="$t('[[[Dziś minus]]]')" nospace>
                            <ideo-form-input
                                v-model="daysBeforeNow"
                                type="number"
                                @update:modelValue="daysBeforeNowChanged"
                            />
                            <template #description>
                                <span>{{ $t('[[[dni]]]') }}</span>
                            </template>
                        </ideo-form-group>
                        <span class="align-self-end pause">-</span>
                        <ideo-form-group :label="$t('[[[Dziś plus]]]')" nospace>
                            <ideo-form-input
                                v-model="daysAfterNow"
                                type="number"
                                @update:modelValue="daysAfterNowChanged"
                            />
                            <template #description>
                                <span>{{ $t('[[[dni]]]') }}</span>
                            </template>
                        </ideo-form-group>
                    </div>
                </div>
            </div>
        </ideo-dropdown>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import FilterDate from '@/components/common/new-filters/components/FiltersDate.vue';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { $t } from '@/plugins/localization';

@Options({
    name: 'DateWrapper',
    components: { FilterDate },
    emits: ['update:dateFromUtc', 'update:dateToUtc', 'update:daysBeforeNow', 'update:daysAfterNow'],
})
export default class DateWrapper extends Vue
{
    @Prop({ default: (): string => $t('[[[Data]]]') }) public label: string;
    @Prop({ default: 'local' }) public zone: string;
    @Prop() public filterDateFrom: any;
    @Prop() public filterDateTo: any;
    @Prop() public filterDaysBeforeNow: any;
    @Prop() public filterDaysAfterNow: any;
    @Prop() public dataId: string;

    public dateFrom: string = null;
    public dateTo: string = null;
    public daysBeforeNow: number = null;
    public daysAfterNow: number = null;
    public dateFromPlaceholder = $t('[[[Data od]]]');
    public dateToPlaceholder = $t('[[[Data do]]]');
    public opened: boolean = false;
    public mode: 'range' | 'computed' = 'range';

    public get buttonDateValue(): string
    {
        if (this.mode === 'range')
        {
            return `${this.$filters.date(this.dateFrom, 'dd-MM-yyyy', this.zone)} ${
                this.dateTo ? '-' : ''
            } ${this.$filters.date(this.dateTo, 'dd-MM-yyyy', this.zone)}`;
        }
        else
        {
            return `${this.daysBeforeNow ? `-${this.daysBeforeNow} ${$t('[[[dni]]]')}` : ''} ${
                this.daysAfterNow ? `+${this.daysAfterNow} ${$t('[[[dni]]]')}` : ''
            }`;
        }
    }

    public get toggleClasses(): Record<string, boolean>
    {
        return {
            'filter-button': true,
            'bg-primary border-primary':
                !!this.dateFrom || !!this.dateTo || !!this.daysBeforeNow || !!this.daysAfterNow || this.opened,
        };
    }

    public get menuClasses(): Record<string, boolean>
    {
        return {
            'p-0': true,
            'filter-dropdown-object__dropdown': true,
            'border-0': true,
        };
    }

    public get containerClasses(): Record<string, boolean>
    {
        return {
            'filter-container': true,
        };
    }

    public dropdownShown(): void
    {
        this.opened = true;
    }

    public mounted(): void
    {
        if (this.dateFrom || this.dateTo)
        {
            this.mode = 'range';
        }
        else if (this.daysBeforeNow || this.daysAfterNow)
        {
            this.mode = 'computed';
        }
    }

    public clearSearchData(): void
    {
        this.dateFrom = null;
        this.dateTo = null;
        this.daysBeforeNow = null;
        this.daysAfterNow = null;
        this.dateFromChanged();
        this.dateToChanged();
        this.daysBeforeNowChanged();
        this.daysAfterNowChanged();
        this.$events.$emit('refetchData', { resetPageIndex: true });
    }

    @Emit('update:dateFromUtc')
    public dateFromChanged(): string | null
    {
        // return value ? DateTime.fromISO(value).setZone('UTC') : null;
        return this.dateFrom ? this.dateFrom : null;
    }

    @Emit('update:dateToUtc')
    public dateToChanged(): string | null
    {
        return this.dateTo ? this.dateTo : null;
    }

    @Emit('update:daysBeforeNow')
    public daysBeforeNowChanged(): number
    {
        return this.daysBeforeNow;
    }

    @Emit('update:daysAfterNow')
    public daysAfterNowChanged(): number
    {
        return this.daysAfterNow;
    }

    @Watch('filterDateFrom', { immediate: true })
    public filterDateFromChanged(value: string): void
    {
        this.dateFrom = value;

        if (!value)
        {
            this.dateFrom = null;
            this.dateFromChanged();
        }
    }

    @Watch('filterDateTo', { immediate: true })
    public filterDateToChanged(value: string): void
    {
        this.dateTo = value;

        if (!value)
        {
            this.dateTo = null;
            this.dateToChanged();
        }
    }

    @Watch('filterDaysBeforeNow', { immediate: true })
    public filterDaysBeforeNowChanged(value: number): void
    {
        this.daysBeforeNow = value;

        if (!value)
        {
            this.daysBeforeNow = null;
            this.daysBeforeNowChanged();
        }
    }

    @Watch('filterDaysAfterNow', { immediate: true })
    public filterDaysAfterNowChanged(value: number): void
    {
        this.daysAfterNow = value;

        if (!value)
        {
            this.daysAfterNow = null;
            this.daysAfterNowChanged();
        }
    }

    @Watch('mode')
    public onModeChanged(newValue: 'range' | 'computed')
    {
        if (newValue === 'computed')
        {
            if (this.dateFrom || this.dateTo)
            {
                this.dateFrom = null;
                this.dateTo = null;
                this.dateFromChanged();
                this.dateToChanged();
            }
        }
        else if (newValue === 'range')
        {
            if (this.daysBeforeNow || this.daysAfterNow)
            {
                this.daysBeforeNow = null;
                this.daysAfterNow = null;
                this.daysBeforeNowChanged();
                this.daysAfterNowChanged();
            }
        }
    }
}
</script>

<style scoped>
.width-between {
    min-width: 260px;
}

.pause {
    margin-bottom: 27px;
}
</style>
