import calendarInvitations from "@/modules/logito/calendar/store/invitations";
import calendarCategories from "@/modules/logito/calendar/store/categories";

export default {
    calendar: {
        namespaced: true,
        modules: {
            calendarInvitations: calendarInvitations,
            calendarCategories: calendarCategories
        }
    }
};
