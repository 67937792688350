<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { ChangelogDefinitionModel, ChangelogDefinitionValueModel } from "@/modules/common/services/ChangelogService";
import { entries } from "lodash";
import { Options, Vue } from "vue-class-component";

// Formatters
import TextFormatter from "./utils/TextFormatter.vue";
import FileFormatter from "./utils/FileFormatter.vue";
import DocumentAuditFormatter from "./utils/audits/DocumentAuditFormatter.vue";
import AuditFormatter from "./utils/audits/AuditFormatter.vue";
import DateTimeFormatter from "./utils/DateTimeFormatter.vue";
import KeyValueFormatter from "./utils/KeyValueFormatter.vue";
import KeyValueFormatterArray from "./utils/KeyValueFormatterArray.vue";
import StatusFormatter from "./utils/StatusFormatter.vue";
import UserFormatter from "./utils/UserFormatter.vue";
import UserFormatterArray from "./utils/UserFormatterArray.vue";
import ContractorWithShipmentFormatter from "./lists/ContractorWithShipmentFormatter.vue";
import ContractorFormatter from "./utils/ContractorFormatter.vue";
import ContractorFormatterArray from "./utils/ContractorFormatterArray.vue";
import BooleanFormatter from "./utils/BooleanFormatter.vue";
import TableFormatter from "./utils/TableFormatter.vue";
import TableVatFormatter from "./utils/TableVatFormatter.vue";
import ExchangeRateFormatter from "./utils/ExchangeRateFormatter.vue";
import DynamicDocumentFormatter from "./utils/DynamicDocumentFormatter.vue";
import DynamicDocumentArrayFormatter from "./utils/DynamicDocumentArrayFormatter.vue";
import RelatedDocumentsFormatter from "./utils/RelatedDocumentsFormatter.vue";
import IsPrivateFormatter from "./utils/IsPrivateFormatter.vue";

@Options({
    name: "ChangesRenderer",
    components: {
        TextFormatter,
        FileFormatter,
        DocumentAuditFormatter,
        DateTimeFormatter,
        KeyValueFormatter,
        KeyValueFormatterArray,
        StatusFormatter,
        UserFormatter,
        UserFormatterArray,
        ContractorWithShipmentFormatter,
        ContractorFormatter,
        ContractorFormatterArray,
        BooleanFormatter,
        AuditFormatter,
        TableFormatter,
        TableVatFormatter,
        ExchangeRateFormatter,
        DynamicDocumentFormatter,
        DynamicDocumentArrayFormatter,
        RelatedDocumentsFormatter,
        IsPrivateFormatter
    },
})
export default class ChangesRenderer extends Vue
{
    @Prop({ default: null })
    public current: any;

    @Prop({ default: null })
    public original: any;

    @Prop({ default: null })
    public definition: ChangelogDefinitionModel[];

    @Prop({ default: "" })
    public map: string;

    public get entityEntries(): [string, unknown][]
    {
        const keys = entries(this.current);

        return keys.length ? keys : [];
    }

    public GetTitle(key: string): string
    {
        const definition = this.definition.find((def) => def.key === this.map);
        const value = definition.value.find((elem) => elem.key.toLowerCase() === key.toLowerCase());

        return value.value.headerName;
    }

    public GetDefinition(key: string): ChangelogDefinitionValueModel
    {
        const definition = this.definition.find((def) => def.key === this.map);
        const value = definition.value.find((elem) => elem.key.toLowerCase() === key.toLowerCase());

        return value.value;
    }

    public GetProperFormatter(key: string): string
    {
        const definition = this.definition.find((def) => def.key === this.map);
        const value = definition.value.find((elem) => elem.key.toLowerCase() === key.toLowerCase());

        if (key === "audit")
        {
            switch (value.value.type.baseType)
            {
                case "AuditModel": {
                    return "AuditFormatter";
                }
                case "DocumentAuditModel":
                    return "DocumentAuditFormatter";
            }
        }

        const type: string = value.value.type.baseType || null;

        switch (type)
        {
            case "String":
                return "TextFormatter";
            case "Ideo.Core.App.Areas.Admin.Core.Models.Storage.ResourceConfidential[]":
            case "ResourceConfidential":
                return "FileFormatter";
            case "DateTime":
                return "DateTimeFormatter";
            case "GenericKeyValuePair":
            case "DynamicDictionaryFront":
                return "KeyValueFormatter";
            case "GenericKeyValuePair[]":
            case "DynamicDictionaryFront[]":
                return "KeyValueFormatterArray";
            case "StatusKeyValuePair":
                return "StatusFormatter";
            case "Ideo.Logito.UserDisplayModel":
            case "UserDisplayModel":
                return "UserFormatter";
            case "Ideo.Logito.UserDisplayModel[]":
            case "UserDisplayModel[]":
            case "ActiveTargetFront[]":
                return "UserFormatterArray";
            case "Ideo.Core.App.Areas.Office.Outgoings.Models.ContractorWithShipmentDetails[]":
                return "ContractorWithShipmentFormatter";
            case "SimplePartner":
            case "ReporterDetails":
                return "ContractorFormatter";
            case "SimplePartner[]":
                return "ContractorFormatterArray";
            case "Boolean":
                return "BooleanFormatter";
            case "UserGroupRaw[]":
                return "UserFormatterArray";
            case "IControlAggregate":
                return "TableFormatter";
            case "ExchangeRateFront":
                return "ExchangeRateFormatter";
            case "ResourceConfidential[]":
                return "FileFormatter";
            case "Ideo.Logito.App.VatTableLine[]":
                return "TableVatFormatter";
            case "DynamicDocumentFront":
                return "DynamicDocumentFormatter";
            case "DynamicDocumentFront[]":
                return "DynamicDocumentArrayFormatter";
            case "Ideo.Logito.LowCode.DocumentRelationships.Query.RelatedDocumentLog[]":
                return "RelatedDocumentsFormatter";
            case "IsPrivateLog":
                return "IsPrivateFormatter";
            default:
                return "TextFormatter";
        }
    }

    public GetTableSchema(itemKey: string): { tableSchema: { body: { [key: string]: unknown } } } | null
    {
        const schema = this.definition[0].value.reduce((obj, item) =>
        {
            obj[item.key] = item.value;

            return obj;
        }, {});

        const tableSchema = {
            body: Object.fromEntries(
                Object.entries(schema)
                    .filter(([key]) => key.startsWith(itemKey) && key !== itemKey)
                    .map(([key, value]) => [key.split("_").last(), value])
            ),
        };

        if (!Object.keys(tableSchema.body).length) return null;

        return { tableSchema };
    }

    public isVisible(item: [string, any]): boolean
    {
        const definition = this.definition.find((def) => def.key === this.map);

        if (item[0].toLowerCase() === "publicid") return false;

        if (item[0].toLocaleLowerCase() === "audit") return true;

        const value = definition.value.find((elem) => elem.key.toLowerCase() === item[0].toLowerCase());

        return value?.value?.visibility;
    }
}
</script>

<template>
    <div class="d-flex flex-column">
        <div
            v-for="(item, index) in entityEntries"
            :key="index"
            class="d-flex align-items-center"
            :class="{ 'mb-2': index !== entityEntries.length - 1 }"
        >
            <div v-if="item[0] !== 'audit' && isVisible(item)" class="changelog-label small">
                <strong class="me-2">{{ GetTitle(item[0]) }}:</strong>
            </div>
            <component
                v-if="isVisible(item)"
                :is="GetProperFormatter(item[0])"
                :current="item[1]"
                :original="original[item[0]]"
                :definition="GetDefinition(item[0])"
                v-bind="GetTableSchema(item[0])"
            ></component>
        </div>
    </div>
</template>
