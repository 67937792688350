import { RouteRecordRaw } from 'vue-router';
import ModuleLayout from '@/views/layouts/ModuleLayout.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: 'module',
        redirect: '/error/404',
        component: ModuleLayout,
        children: [
            // // --------------------------------------------------------------------------
            // // Zestawienie dokumentów
            // // --------------------------------------------------------------------------

            // Widok
            {
                path: ':licence/:mode/:sitemapId',
                name: 'module-view',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/View.vue'),
            },

            // Formularz
            {
                path: ':licence/form',
                name: 'module-form',
                meta: { auth: true },
                props: (route) => ({ ...route.params, id: route.query.id }),
                component: () => import('@/modules/low-code/views/Form.vue'),
            },
            {
                path: ':licence/details/:publicId/form',
                name: 'module-form-dynamic',
                meta: { auth: true },
                props: (route) => ({ ...route.params, id: route.query.id }),
                component: () => import('@/modules/low-code/views/Form.vue'),
            },
            {
                path: ':licence/edit/:publicId/form',
                name: 'module-form-dynamic-start',
                meta: { auth: true },
                props: (route) => ({ ...route.params, id: route.query.id }),
                component: () => import('@/modules/low-code/views/Form.vue'),
            },

            // Szczegóły
            {
                path: ':licence/details/:publicId',
                name: 'module-form-details',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/Details.vue'),
            },

            // --------------------------------------------------------------------------
            // ADMINISTRACJA
            // --------------------------------------------------------------------------

            // Ustawienia
            {
                path: ':moduleId/settings',
                name: 'module-settings',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/settings/Form.vue'),
            },

            // Wersje
            {
                path: ':moduleId/versions',
                name: 'module-versions',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/versions/views/List.vue'),
            },

            // Procesy
            {
                path: ':moduleId/processes',
                name: 'module-processes',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/process/views/List.vue'),
            },
            {
                path: ':moduleId/processes/add',
                name: 'module-processes-add',
                meta: { auth: true },
                props: route => ({ ...route.params, versionId: route.query.versionId }),
                component: () => import('@/modules/low-code/process/views/Form.vue'),
            },
            {
                path: ':moduleId/processes/edit/:publicId',
                name: 'module-processes-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/process/views/Details.vue'),
            },

            // Rejestry
            {
                path: ':moduleId/registers',
                name: 'module-registers',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/register-documents/views/ParentList.vue'),
            },
            {
                path: ':moduleId/registers-add',
                name: 'module-registers-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/register-documents/views/ParentForm.vue'),
            },
            {
                path: ':moduleId/registers-edit/:publicId',
                name: 'module-registers-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/register-documents/views/ParentForm.vue'),
            },

            // Źródła danych
            {
                path: ':moduleId/integrators',
                name: 'module-integrators',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/register-data-sources/views/ParentList.vue'),
            },
            {
                path: ':moduleId/integrators-add',
                name: 'module-integrators-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/register-data-sources/views/ParentForm.vue'),
            },
            {
                path: ':moduleId/integrators-edit/:publicId',
                name: 'module-integrators-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/register-data-sources/views/ParentForm.vue'),
            },

            // Szablony wydruku
            {
                path: ':moduleId/print-templates',
                name: 'module-print-templates',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/print-templates/List.vue'),
            },
            {
                path: ':moduleId/print-templates-add',
                name: 'module-print-templates-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/print-templates/Form.vue'),
            },
            {
                path: ':moduleId/print-templates-edit/:publicId',
                name: 'module-print-templates-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/print-templates/Form.vue'),
            },

            // Powiadomienia weryfikacyjne
            {
                path: ':moduleId/document-notification-templates',
                name: 'module-document-notification-templates',
                meta: { auth: true },
                props: true,
                component: () =>
                    import('@/modules/low-code/views/administration/document-notification-templates/List.vue'),
            },
            {
                path: ':moduleId/document-notification-templates-add',
                name: 'module-document-notification-templates-add',
                meta: { auth: true },
                props: true,
                component: () =>
                    import('@/modules/low-code/views/administration/document-notification-templates/Form.vue'),
            },
            {
                path: ':moduleId/document-notification-templates-edit/:publicId',
                name: 'module-document-notification-templates-edit',
                meta: { auth: true },
                props: true,
                component: () =>
                    import('@/modules/low-code/views/administration/document-notification-templates/Form.vue'),
            },

            // Formularze dynamiczne
            {
                path: ':moduleId/forms',
                name: 'module-dynamic-forms',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/forms/views/List.vue'),
            },
            {
                path: ':moduleId/forms/add',
                name: 'module-dynamic-forms-add',
                props: true,
                meta: { auth: true },
                component: () => import('@/modules/low-code/forms/views/Form.vue'),
            },
            {
                path: ':moduleId/forms/edit/:publicId',
                name: 'module-dynamic-forms-edit',
                props: true,
                meta: { auth: true },
                component: () => import('@/modules/low-code/forms/views/Form.vue'),
            },

            // Pola formularzy
            {
                path: ':moduleId/field-configs',
                name: 'module-field-configs',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/field-configs/List.vue'),
            },
            {
                path: ':moduleId/field-configs/default-settings/details',
                name: 'module-field-configs-default-settings-details',
                meta: { auth: true },
                props: true,
                component: () =>
                    import('@/modules/low-code/views/administration/field-configs/default-settings/Details.vue'),
            },
            {
                path: ':moduleId/field-configs/default-settings/:schemaType',
                name: 'module-field-configs-default-settings-grid-filter',
                meta: { auth: true },
                props: true,
                component: () =>
                    import('@/modules/low-code/views/administration/field-configs/default-settings/GridFilter.vue'),
            },

            // Statusy
            {
                path: ':moduleId/statuses',
                name: 'module-statuses',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/statuses/List.vue'),
            },

            // Czynności
            {
                path: ':moduleId/actions',
                name: 'module-actions',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/actions/List.vue'),
            },

            // Grupy użytkowników
            {
                path: ':moduleId/groups',
                name: 'module-groups',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/members/List.vue'),
            },

            // Definicje opisu
            {
                path: ':licence/:moduleId/line-definitions',
                name: 'module-line-definitions',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/line-definitions/views/LineDefinitionForm.vue'),
            },

            // Szablony opisu
            {
                path: ':moduleId/description-templates',
                name: 'module-description-templates',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/description-templates/List.vue'),
            },
            {
                path: ':moduleId/description-templates-add',
                name: 'module-description-templates-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/description-templates/Form.vue'),
            },
            {
                path: ':moduleId/description-templates/:publicId',
                name: 'module-description-templates-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/description-templates/Form.vue'),
            },

            // Widgety
            {
                path: ':moduleId/widgets',
                name: 'module-widgets',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/widgets/List.vue'),
            },
            {
                path: ':moduleId/widgets-add',
                name: 'module-widgets-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/widgets/Form.vue'),
            },
            {
                path: ':moduleId/widgets-edit/:publicId',
                name: 'module-widgets-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/widgets/Form.vue'),
            },

            // Klucze unikalne
            {
                path: ':moduleId/unique-keys',
                name: 'module-unique-keys',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/unique-keys/List.vue'),
            },

            // Szablony tabel
            {
                path: ':moduleId/table-templates',
                name: 'module-table-templates',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/table-templates/List.vue'),
            },
            {
                path: ':moduleId/table-templates-add',
                name: 'module-table-templates-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/table-templates/Form.vue'),
            },
            {
                path: ':moduleId/table-templates-edit/:publicId',
                name: 'module-table-templates-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/table-templates/Form.vue'),
            },
        ],
    },
];

export default routes;
