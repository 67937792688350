import { RouteRecordRaw } from 'vue-router';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        path: 'studio',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Formularze
            {
                path: 'forms',
                name: 'studio-forms',
                meta: { auth: true },
                component: () => import('@/modules/studio/forms/views/FormsList.vue')
            },
            {
                path: 'forms/add',
                name: 'studio-forms-add',
                meta: { auth: true },
                component: () => import('@/modules/studio/forms/views/FormsForm.vue')
            },
            {
                path: 'forms/:id(\\d+)',
                name: 'studio-forms-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreDynamicForm' },
                component: () => import('@/modules/studio/forms/views/FormsForm.vue')
            },
            // Wpisy
            {
                path: 'documents/:categoryId(\\d+)?/:formId(\\d+)?',
                name: 'studio-entries',
                meta: { auth: true },
                component: () => import('@/modules/studio/entries/views/EntriesList.vue')
            },
            {
                path: 'documents/add/:categoryId(\\d+)/:formId(\\d+)',
                name: 'studio-entries-add',
                props: true,
                meta: { auth: true },
                component: () => import('@/modules/studio/entries/views/EntriesForm.vue')
            },
            {
                path: 'documents/edit/:id(\\d+)',
                name: 'studio-entries-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreDynamicFormEntry' },
                component: () => import('@/modules/studio/entries/views/EntriesForm.vue')
            },
            // Komponenty
            {
                path: 'components',
                name: 'studio-components',
                meta: { auth: true },
                component: () => import('@/modules/studio/components/views/ComponentList.vue')
            },
            {
                path: 'components/add',
                name: 'studio-components-add',
                meta: { auth: true },
                component: () => import('@/modules/studio/components/views/ComponentForm.vue')
            },
            {
                path: 'components/:id(\\d+)',
                name: 'studio-components-edit',
                meta: { auth: true },
                component: () => import('@/modules/studio/components/views/ComponentForm.vue')
            },
            // Źródła danych
            {
                path: 'sources',
                name: 'studio-datasources',
                meta: { auth: true },
                component: () => import('@/modules/studio/data-sources/views/DataSourcesList.vue')
            },
            {
                path: 'sources/add',
                name: 'studio-datasources-add',
                meta: { auth: true },
                component: () => import('@/modules/studio/data-sources/views/DataSourceForm.vue')
            },
            {
                path: 'sources/:id(\\d+)',
                name: 'studio-datasources-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreDataSource' },
                component: () => import('@/modules/studio/data-sources/views/DataSourceForm.vue')
            },
            // Konfiguracaj modułów
            {
                path: 'modules',
                name: 'studio-modules',
                meta: { auth: true },
                component: () => import('@/modules/studio/modules/views/ModuleList.vue')
            },
            {
                path: 'modules/add',
                name: 'studio-modules-add',
                meta: { auth: true },
                component: () => import('@/modules/studio/modules/views/ModuleForm.vue')
            },
            {
                path: 'modules/:id(\\d+)',
                name: 'studio-modules-edit',
                meta: { auth: true },
                component: () => import('@/modules/studio/modules/views/ModuleForm.vue')
            },
            // Headless API
            {
                path: 'endpoints',
                name: 'studio-endpoints',
                meta: { auth: true },
                component: () => import('@/modules/studio/endpoints/views/ApiList.vue')
            },
            {
                path: 'endpoints/add',
                name: 'studio-endpoints-add',
                meta: { auth: true },
                component: () => import('@/modules/studio/endpoints/views/ApiForm.vue')
            },
            {
                path: 'endpoints/:id(\\d+)',
                name: 'studio-endpoints-edit',
                meta: { auth: true },
                component: () => import('@/modules/studio/endpoints/views/ApiForm.vue')
            },
            // Webhooks
            {
                path: 'webhooks',
                name: 'studio-webhooks',
                meta: { auth: true },
                component: () => import('@/modules/studio/webhooks/views/WebhookList.vue')
            },
            {
                path: 'webhooks/add',
                name: 'studio-webhooks-add',
                meta: { auth: true },
                component: () => import('@/modules/studio/webhooks/views/WebhookForm.vue')
            },
            {
                path: 'webhooks/:id(\\d+)',
                name: 'studio-webhooks-edit',
                meta: { auth: true },
                component: () => import('@/modules/studio/webhooks/views/WebhookForm.vue')
            },
            {
                path: 'webhooks/:id(\\d+)/logs',
                name: 'studio-webhooks-logs',
                meta: { auth: true },
                component: () => import('@/modules/studio/webhooks/views/WebhookLogs.vue')
            },
            // Automatyzacja
            {
                path: 'automations',
                name: 'studio-automations',
                meta: { auth: true },
                component: () => import('@/modules/studio/automations/views/AutomationList.vue')
            },
            {
                path: 'automations/add',
                name: 'studio-automations-add',
                meta: { auth: true },
                component: () => import('@/modules/studio/automations/views/AutomationForm.vue')
            },
            {
                path: 'automations/:id(\\d+)',
                name: 'studio-automations-edit',
                meta: { auth: true },
                component: () => import('@/modules/studio/automations/views/AutomationForm.vue')
            },
            // Moduły
            {
                path: 'module',
                name: 'studio-docs',
                meta: { auth: true },
                component: () => import('@/modules/studio/documents/views/Index.vue'),
                children: [
                    {
                        path: ':symbol([a-z-]+)+',
                        name: 'studio-docs-main',
                        meta: { auth: true },
                        component: () => import('@/modules/studio/documents/views/Module.vue'),
                    },
                    {
                        path: ':symbol([a-z-]+)+/add',
                        name: 'studio-docs-add',
                        meta: { auth: true },
                        component: () => import('@/modules/studio/documents/views/Module.vue'),
                    },
                    {
                        path: ':symbol([a-z-]+)+/:id(\\d+)',
                        name: 'studio-docs-edit',
                        meta: { auth: true },
                        component: () => import('@/modules/studio/documents/views/Module.vue'),
                    }
                ]
            },
        ]
    }
] as Array<RouteRecordRaw>;
