<template>
    <div v-if="groupedData.length || (isSitemap && items.datasets.length)" class="w-100 h-100 d-grid align-items-center">
        <canvas v-if="!isSum(chartType) || isSitemap" ref="chart"></canvas>
        <div v-else class="d-flex flex-column align-items-center">
            <div
                v-if="sourceConfig.icon"
                :style="`background: ${getColor(sourceConfig.iconColor?.key, true)}`"
                class="icon-background"
            >
                <i :style="`color: ${getColor(sourceConfig.iconColor?.key)}`" class="icon" :class="iconClass"></i>
            </div>
            <span :style="`color: ${getColor(sourceConfig.numberColor?.key)}`" class="number">{{ $filters.currencyFormat(sum, precision) }}</span>
        </div>
    </div>
    <span v-else>{{ $t('[[[Brak danych dla wykresu]]]') }}</span>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { APIWidget, SourceConfigChart } from "@/modules/core/home/services/HomeService";
import HomeService from '@/modules/core/home/services/HomeService';
import Chart, { ChartOptions, ChartType, ChartData as IChartData } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { KeyValue } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';
import { months, quarters, getColor, colors } from '../../inc/helpers';
import { useThemeStore } from '@/store/theme';
import GridService, { Data, ChartData } from '@/modules/core/common/services/GridService';
import { WidgetTypeEnum } from '@/modules/core/home/services/HomeService';
import WidgetDataHelper from '@/modules/low-code/components/helpers/WidgetDataHelper';

interface GroupData extends KeyValue<string, string> {
    amount: number;
}

@Options({
    name: 'ChartWidget',
})
export default class ChartWidget extends Vue
{
    @Prop({ default: () => {} })
    public config: APIWidget;

    @Prop({ default: () => ('') })
    public emitId: string;

    @Prop({ default: () => (Form.create({})) })
    public filter: any;

    public url: string = '';
    public pager = new Pager(1, 999);
    public groupedData: Array<GroupData> = [];
    public features: Array<KeyValue<string, string>> = [];
    public chartType: ChartType | 'sum' = 'bar';
    public sitemapData: Data<[{ data: ChartData }]> = null;
    public chart: any;

    public get isSitemap(): boolean
    {
        return this.config.sourceType.key == WidgetTypeEnum.Sitemap;
    }

    public get items(): IChartData
    {
        return WidgetDataHelper.getWidgetData(this.sitemapData);
    }

    public get sourceConfig(): SourceConfigChart
    {
        return this.config?.sourceConfig as SourceConfigChart;
    }

    public get precision(): number
    {
        const value = this.features.find((p) => p.key === 'precision')?.value;

        if (value != null) return +value;

        return 0;
    }

    public get sum(): number
    {
        return this.groupedData[0].amount;
    }

    public get iconClass(): string
    {
        return this.sourceConfig.icon;
    }

    public get themeMode(): string
    {
        return useThemeStore().theme;
    }

    public created(): void
    {
        this.url = this.config.dataEndpoint;
        this.chartType = (this.sourceConfig?.chartType?.key?.toLowerCase?.() as ChartType) ?? 'bar';

        if (this.emitId)
        {
            this.$events.$on(this.emitId, async () =>
            {
                await this.getData();
                this.chart.destroy();
                this.initChart();
            });
        }
    }

    public async mounted(): Promise<void>
    {
        await this.getData();
        this.initChart();
    }

    public async getData(): Promise<void>
    {
        try
        {
            if (!this.isSitemap)
            {
                const result = await HomeService.getChartWidgetData(this.url, this.filter.formatData());

                this.groupedData = result.data;
                this.features = result.features;
            }
            else
            {
                const response = await GridService.fetchChart(this.config.licenseUrl, this.config.sitemapEntryDetails.publicId, this.filter.formatData());

                if (!response) return;

                this.sitemapData = response;
                this.chartType = response.config.chartType.key.toLowerCase() as ChartType;
            }
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public getColor = getColor;

    public getColorByIndex(index: number): string
    {
        const keys = Object.keys(colors);
        const indexMod = index % keys.length;
        const colorName = keys[indexMod];
        const color = getColor(colorName);

        return color;
    }

    public isSum(chartType: ChartType | 'sum'): chartType is 'sum'
    {
        return chartType === 'sum';
    }

    public initChart(): void
    {
        if (this.isSitemap || (!this.isSum(this.chartType) && this.groupedData.length))
        {
            const labels = this.getLabels();
            const options: ChartOptions = {
                scales: this.chartType === 'bar' && {
                    y: {
                        beginAtZero: true,
                        grid: {
                            borderWidth: 0,
                        },
                        ticks: {
                            font: {
                                size: 14,
                                family: 'Inter',
                                weight: '500',
                            },
                            padding: 10,
                        },
                    },
                    x: {
                        ticks: {
                            callback: (_, index) =>
                            {
                                return labels[index].length > 12
                                    ? labels[index].substring(0, 12) + '...'
                                    : labels[index];
                            },
                            font: {
                                size: 14,
                                family: 'Inter',
                                weight: '500',
                            },
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: this.isSitemap || (this.chartType === 'pie' && true),
                        labels: {
                            padding: 20,
                            font: {
                                size: 13,
                                family: 'Inter',
                                weight: '500',
                            },
                            usePointStyle: true,
                        },
                        position: 'bottom',
                    },
                    datalabels: {
                        color: '#000',
                        display: this.isSitemap ? (this.sitemapData.config.showValues ?? false) : (this.sourceConfig.showValues ?? false),
                        formatter(value)
                        {
                            return value || '';
                        },
                    },
                },
                maintainAspectRatio: false,
                // @ts-ignore
                cutout: '65%',
            };

            const ctx = (this.$refs.chart as HTMLCanvasElement).getContext('2d');

            if (this.isSitemap)
            {
                this.chart = new Chart(ctx, {
                    type: this.chartType === 'pie' ? 'doughnut' : 'bar',
                    data: this.items,
                    options,
                    plugins: [ChartDataLabels],
                });
            }
            else
            {
                const data = this.groupedData.map((item) => item.amount);
                const backgroundColor = new Array(labels.length).fill('').map((_, index) => this.getColorByIndex(index));

                // Chart bar
                this.chart = new Chart(ctx, {
                    type: this.chartType === 'pie' ? 'doughnut' : this.chartType,
                    data: {
                        labels,
                        datasets: [
                            {
                                data,
                                backgroundColor,
                                borderWidth: 0,
                                weight: 0.3,
                            },
                        ],
                    },
                    options,
                    plugins: [ChartDataLabels],
                });

                this.setChartColors(chart, this.themeMode);
            }
        }
    }

    public setChartColors(chart: Chart, themeMode: string): void
    {
        chart.options.plugins.legend.labels.color = themeMode === 'dark' ? '#AFBDD1' : '#23282C';

        if (this.chartType === 'bar')
        {
            chart.options.scales.x.ticks.color = themeMode === 'dark' ? '#AFBDD1' : '#5F5F5F';
            chart.options.scales.y.ticks.color = themeMode === 'dark' ? '#AFBDD1' : '#5F5F5F';
            chart.options.scales.y.grid.color = themeMode === 'dark' ? '#1B2027' : '#EEEEEE';
        }

        chart.update();
    }

    public getLabels(): string[]
    {
        if (this.isSitemap)
            return this.items.labels as string[];

        if (this.sourceConfig.dateGroupType == null || this.sourceConfig.dateGroupType?.key === 'Date')
            return this.groupedData.map((item) => item.value ?? this.$t('[[[Brak]]]'));

        if (this.sourceConfig.dateGroupType.key === 'Month')
            return this.groupedData.map((item) =>
            {
                const date = DateTime.fromMillis(Number.parseInt(item.key));

                return months().find((p) => p.key == date.month).value + ' ' + date.year;
            });

        if (this.sourceConfig.dateGroupType.key === 'Quarter')
            return this.groupedData.map((item) =>
            {
                const date = DateTime.fromMillis(Number.parseInt(item.key));

                return quarters().find((p) => p.key == date.quarter).value + ' ' + date.year;
            });

        if (this.sourceConfig.dateGroupType.key === 'Year')
            return this.groupedData.map((item) =>
            {
                const date = DateTime.fromMillis(Number.parseInt(item.key));

                return date.year.toString();
            });
    }

    @Watch('themeMode')
    public onThemeModeChange(newValue: string): void
    {
        const chart = Chart.getChart(this.$refs.chart as HTMLCanvasElement);

        this.setChartColors(chart, newValue);
    }

    public async beforeUnmount(): Promise<void>
    {
        if (this.emitId)
            this.$events.$off(this.emitId);
    }
}
</script>

<style lang="scss" scoped>
.icon-background {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;

    .icon {
        font-size: 1.5rem;
    }
}

.number {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.25;
}
</style>
