<template>
    <div class="form-group mb-1">
        <div class="input-group">
            <input
                type="text"
                class="form-control pointer"
                :value="modelValue"
                :readonly="true"
                :placeholder="placeholder"
                @click="openEditor"
            />
            <button type="button" class="btn btn-secondary" @click="openEditor">
                <i class="fas fa-cog"></i>
            </button>
        </div>
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>

        <ideo-modal v-model="modal" id="expression-modal" :title="placeholder" size="xl" centered scrollable>
            <template #default>
                <textarea v-model="buffer" class="form-control h-100" :rows="5" spellcheck="false"></textarea>
            </template>
            <template #helper>
                <div class="legend">
                    <h5 class="fw-bold2 mb-3">{{ $t('[[[Wyrażenia do pól wyliczanych]]]') }}</h5>
                    <p class="mb-0">
                        {{ $t('[[[Kod musi zwracać kończyć się wyrażeniem, które zwraca wartość wybranego typu]]]') }}
                    </p>
                    <p class="mb-0">{{ $t('[[[Dane tabeli przechowywane są w obiekcie table]]]') }}</p>
                    <p class="mb-0">{{ $t('[[[Obiekt ma strukturę Dictionary<string, object>[] z C#]]]') }}</p>
                    <p class="mb-8">
                        {{
                            $t(
                                '[[[Wartości poszczególnych komórek tabeli są w formacie zgodnym z tym, co dana kontrolka zwraca na front.]]]'
                            )
                        }}
                    </p>
                    <div>
                        <h6>{{ $t('[[[Odwołanie do wartości pola numerycznego]]]') }}</h6>
                        <pre>table[0].single(p => p.Key == "Numeric1").Value</pre>
                        <h6>
                            {{
                                $t(
                                    '[[[Odwołanie do klucza pierwszego wybranego terminu słownika w polu Słownik Logito]]]'
                                )
                            }}
                        </h6>
                        <pre>table[0].single(p => p.Key == "LogitoDictionary1").Value[0].Key</pre>
                        <h6>{{ $t('[[[Odwołanie do pól terminu słownika (np. stawka VAT)]]]') }}</h6>
                        <pre>table[i].single(p => p.Key == 'LogitoDictionary2').Value.First().Properties.single(p => p.Key == 'rate').Value</pre>
                        <h6>{{ $t('[[[Obliczenie godzin między datami z pierwszego i ostatniego wiersza]]]') }}</h6>
                        <pre>
                                    let start = table[0].single(p => p.Key == "Date1").Value;
                                    let end = table[table.Count - 1].single(p => p.Key == "Date1").Value;
                                    end.Subtract(start).TotalHours;
                                </pre>
                        <h6>{{ $t('[[[Skrypt zliczający sumą wartości z pola numerycznego]]]') }}</h6>
                        <pre>
                                    let sum = 0;
                                    for (let i = 0; i &lt; table.Count; i++)
                                    {
                                    &nbsp;&nbsp;sum += table[i].single(p => p.Key == "Numeric1").Value;
                                    }
                                    sum;
                                </pre>
                    </div>
                </div>
            </template>
            <template #modal-footer>
                <button class="btn btn-primary" @click.stop.prevent="saveExpression">
                    {{ $t('[[[Zapisz]]]') }}
                </button>
                <ideo-button variant="secondary" @click.stop.prevent="closeEditor">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';

@Options({
    name: 'field-expression',
})
export default class FieldComputed extends Vue
{
    @Prop() public placeholder: string;
    @Prop() public modelValue: string;
    @Prop() public invalidFeedback: (value: string) => string;

    public modal = false;
    public buffer: string = null;

    public openEditor(): void
    {
        this.buffer = this.modelValue;
        this.modal = true;
    }

    public closeEditor(): void
    {
        this.buffer = '';
        this.modal = false;
    }

    public saveExpression(): void
    {
        this.triggerInput(this.buffer);
        this.closeEditor();
    }

    @Emit('update:modelValue')
    public triggerInput(value: string): string
    {
        return value;
    }
}
</script>
