<template>
    <ideo-modal ref="search-engine-modal" size="lg" :title="$t('[[[Wyszukiwarka adresów ADE]]]')" centered>
        <template #default>
            <div v-if="showSearch">
                <list-view v-if="searchResults" :items="searchResults" :show-header="false">
                    <template #row="{ item }">
                        <list-view-item>
                            <div class="d-flex flex-column">
                                <h2>{{ item.nameOrEntity }}</h2>
                                <p class="mb-1">{{ $t('[[[Adres do eDoręczeń: ]]]') }}<strong>{{ item.recipientEda }}</strong></p>
                                <p v-if="item.correspondenceAddress" class="mb-1">{{ $t('[[[Adres korespądencyjny: ]]]') }}<strong>{{ item.correspondenceAddress }}</strong></p>
                                <p v-if="item.headquartersAddress" class="mb-1">{{ $t('[[[Adres siedziby: ]]]') }}<strong>{{ item.headquartersAddress }}</strong></p>
                                <div>
                                    <span v-if="item.regon" class="d-inline-block me-1">{{ $t('[[[REGON: ]]]') }} <strong>{{ item.regon }}</strong></span>
                                    <span v-if="item.nip" class="d-inline-block me-1">{{ $t('[[[NIP: ]]]') }}<strong>{{ item.nip }}</strong></span>
                                    <span v-if="item.pesel" class="d-inline-block me-1">{{ $t('[[[PESEL: ]]]') }}<strong>{{ item.pesel }}</strong></span>
                                    <span v-if="item.krs">{{ $t('[[[KRS: ]]]') }}<strong>{{ item.krs }}</strong></span>
                                </div>
                            </div>
                        </list-view-item>
                        <list-view-item width="100">
                            <ideo-button variant="info" @click="onRowClick(item)">{{ $t('[[[Wybierz]]]') }}</ideo-button>
                        </list-view-item>
                    </template>
                </list-view>
                <ideo-alert variant="info" v-else show>
                    {{ $t('[[[Brak wyników wyszukiwania]]]') }}
                </ideo-alert>
            </div>
            <div v-else>
                <ideo-form-group :label="$t('[[[Wybierz typ odbiorcy]]]')" :invalid-feedback="form.$errors.first('searchType')" :state="form.$errors.state('searchType')" required>
                    <ideo-select
                        v-model="form.searchType"
                        :placeholder="$t('[[[Typ odbiorcy]]]')"
                        :options="searchTypesOptions"
                        @update:model-value="form.$errors.clear('${index}-Module')"
                    />
                </ideo-form-group>
                <FormAutocomplete
                    v-model="form.searchMode"
                    :custom-endpoint="`crm/dictionary/edoreczenia/search-modes`"
                    :placeholder="$t('[[[Typ wyszukiwania]]]')"
                    :is-radio-box="true"
                    :initial-load="true"
                    :radiobox-stacked="false"
                    radio-box-name="searchMode"
                    class="mb-3"
                    @change="form.$errors.clear('searchMode')"
                />
                <div v-if="form.searchMode?.key == 'Id'">
                    <div v-if="form.searchType?.key == 'Individual'">
                        <ideo-form-group :label="$t('[[[Imię]]]')" :invalid-feedback="form.$errors.first('name')" :state="form.$errors.state('name')" required>
                            <ideo-form-input v-model="form.name" type="text" name="name"></ideo-form-input>
                        </ideo-form-group>
                        <ideo-form-group :label="$t('[[[Nazwisko]]]')" :invalid-feedback="form.$errors.first('surname')" :state="form.$errors.state('surname')" required>
                            <ideo-form-input v-model="form.surname" type="text" name="surname"></ideo-form-input>
                        </ideo-form-group>
                        <ideo-form-group :label="$t('[[[PESEL]]]')" :invalid-feedback="form.$errors.first('pesel')" :state="form.$errors.state('pesel')" required>
                            <ideo-form-input v-model="form.pesel" type="text" name="pesel"></ideo-form-input>
                        </ideo-form-group>
                    </div>
                    <div v-if="form.searchType?.key == 'Company'">
                        <ideo-form-group :label="$t('[[[NIP]]]')" :invalid-feedback="form.$errors.first('nip')" :state="form.$errors.state('nip')">
                            <ideo-form-input v-model="form.nip" type="text" name="nip"></ideo-form-input>
                        </ideo-form-group>
                        <ideo-form-group :label="$t('[[[Numer REGON]]]')" :invalid-feedback="form.$errors.first('regon')" :state="form.$errors.state('regon')">
                            <ideo-form-input v-model="form.regon" type="text" name="regon"></ideo-form-input>
                        </ideo-form-group>
                        <ideo-form-group :label="$t('[[[Numer KRS]]]')" :invalid-feedback="form.$errors.first('krs')" :state="form.$errors.state('krs')">
                            <ideo-form-input v-model="form.krs" type="text" name="krs"></ideo-form-input>
                        </ideo-form-group>
                    </div>
                </div>
                <div v-if="form.searchMode?.key == 'Address'">
                    <div v-if="form.searchType?.key == 'Individual'">
                        <ideo-form-group :label="$t('[[[Imię]]]')" :invalid-feedback="form.$errors.first('name')" :state="form.$errors.state('name')" required>
                            <ideo-form-input v-model="form.name" type="text" name="name"></ideo-form-input>
                        </ideo-form-group>
                        <ideo-form-group :label="$t('[[[Nazwisko]]]')" :invalid-feedback="form.$errors.first('surname')" :state="form.$errors.state('surname')" required>
                            <ideo-form-input v-model="form.surname" type="text" name="surname"></ideo-form-input>
                        </ideo-form-group>
                    </div>
                    <ideo-form-group v-if="form.searchType?.key == 'Company'" :label="$t('[[[Nazwa]]]')" :invalid-feedback="form.$errors.first('name')" :state="form.$errors.state('name')" required>
                        <ideo-form-input v-model="form.name" type="text" name="name"></ideo-form-input>
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Miejscowość]]]')" :invalid-feedback="form.$errors.first('address.City')" :state="form.$errors.state('address.City')" required>
                        <ideo-form-input v-model="form.address.city" type="text" name="address.city"></ideo-form-input>
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Numer budynku]]]')" :invalid-feedback="form.$errors.first('address.BuildingNumber')" :state="form.$errors.state('address.BuildingNumber')" required>
                        <ideo-form-input v-model="form.address.buildingNumber" type="text" name="address.buildingNumber"></ideo-form-input>
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Numer lokalu]]]')" :invalid-feedback="form.$errors.first('address.FlatNumber')" :state="form.$errors.state('address.FlatNumber')">
                        <ideo-form-input v-model="form.address.flatNumber" type="text" name="address.flatNumber"></ideo-form-input>
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Ulica]]]')" :invalid-feedback="form.$errors.first('address.Street')" :state="form.$errors.state('address.Street')">
                        <ideo-form-input v-model="form.address.street" type="text" name="address.street"></ideo-form-input>
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Kod pocztowy]]]')" :invalid-feedback="form.$errors.first('address.PostalCode')" :state="form.$errors.state('address.PostalCode')">
                        <ideo-form-input v-model="form.address.postalCode" type="text" name="address.postalCode"></ideo-form-input>
                    </ideo-form-group>
                </div>
            </div>
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button v-if="!showSearch" variant="success" @click="search()" :disabled="!form.searchType || !form.searchMode">
                {{ $t('[[[Szukaj]]]') }}
            </ideo-button>
            <ideo-button v-else variant="info" @click="returnToFirstPhase()">
                {{ $t('[[[Szukaj ponownie]]]') }}
            </ideo-button>
            <ideo-button variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Ref } from "@/helpers/Decorators";
import IdeoModal from "@/components/ideo/modal/IdeoModal.vue";
import { Form } from "@/helpers/Form";
import { FrontActionModal } from '@/components/common/dynamic-grid/helpers/GridEnums';
import SearchEngineService, { FormModel, SearchResultModel } from '@/modules/core/dictionary-terms/services/SearchEngineService';
import { KeyValuePair } from '@/helpers/Interfaces';


@Options({
    components: {
        IdeoModal,
    },
})
export default class SearchEngineModal extends Vue
{
    @Ref('search-engine-modal')
    public modal: () => IdeoModal;

    public form = Form.create<FormModel>({
        searchType: null,
        searchMode: null,
        nip: '',
        regon: '',
        krs: '',
        pesel: '',
        name: '',
        surname: '',
        address: {
            city: '',
            buildingNumber: '',
            flatNumber: '',
            street: '',
            postalCode: ''
        }
    });

    public showSearch : boolean = false;
    public searchResults : SearchResultModel[] = [];
    public organizationType : string = '';
    public searchTypesOptions : KeyValuePair[] = [];

    public async mounted(): Promise<void>
    {
        this.organizationType = await SearchEngineService.getEdoreczeniaOrganizationType();
        this.searchTypesOptions = (await SearchEngineService.getEdoreczeniaSearchTypes()).items.map(x => x.result);

        this.$events.$on(FrontActionModal.EDoreczeniaSearchEngine, async (businessPartnerForm: any) =>
        {
            this.form.clear();
            this.showSearch = false;
            this.fillWithDataFromContractorForm(businessPartnerForm);
            this.show();
        });
    }

    public onRowClick(item: any): void
    {
        this.hide();
        this.$emit('selected', item);
    }

    public async search(): Promise<void>
    {
        try
        {
            const response = await SearchEngineService.search(this.form.formatData());

            this.searchResults = response;
            this.showSearch = true;
        }

        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$scrollTo('.invalid-field'),
                    this.$alert.warning(this.$t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]')),
                ],
            });
        }
    }

    public fillWithDataFromContractorForm(businessPartnerForm: any): void
    {
        this.form.searchType = this.searchTypesOptions[0];

        const orgTypeLower = this.organizationType.toLowerCase();

        if (orgTypeLower === 'company' && businessPartnerForm.commonType === 1) return;

        if (orgTypeLower === 'public' && businessPartnerForm.commonType === 1)
        {
            this.form.name = businessPartnerForm.givenName;
            this.form.surname = businessPartnerForm.surname;
            this.form.searchType = this.searchTypesOptions.find(x => x.key === 'Individual');
        }

        if (businessPartnerForm.commonType === 0)
        {
            this.form.name = businessPartnerForm.organizationName;
            this.form.nip = businessPartnerForm.nip;
        }

        this.form.address.city = businessPartnerForm.city;
        this.form.address.buildingNumber = businessPartnerForm.houseID;
    }

    public returnToFirstPhase(): void
    {
        this.showSearch = false;
    }

    public show(): void
    {
        this.modal().show();
    }

    public hide(): void
    {
        this.modal().hide();
    }
}
</script>
