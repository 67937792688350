import { axios } from '@/plugins/axios';
import { KeyValuePair, Resource } from "@/helpers/Interfaces";

export default class DocumentRelationshipsService
{
    public static async createRelation(documentId: string, licence: string, form: CreateModel): Promise<Resource<DetailsModel>>
    {
        return (await axios.post(`simple-command/${licence}/${documentId}/related-documents`, form)).data;
    }

    public static async canLink(documentId: string, licence: string, blueprintId: string): Promise<boolean>
    {
        return (await axios.get(`${licence}/${documentId}/related-documents/can-link/${blueprintId}`)).data;
    }
}

export interface CreateModel {
    blueprintId: string;
    document: KeyValuePair | string;
}

export interface DetailsModel {
    publicId: string;
    sourceDocumentId: string;
    destinationDocumentId: string;
}
