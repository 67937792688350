<template>
    <div class="dashboard">
        <div class="toolbar sticky-top py-2 px-2 px-md-3 mt-n3 mx-n2 mx-md-n3 d-flex bg-body-tertiary">
            <ActionButton
                v-if="isEditMode && widgets.length"
                variant="primary"
                icon="fas fa-rectangle-history-circle-plus"
                :text="$t('[[[Dodaj widget]]]')"
                @click.stop="openMenu"
            />
            <ActionButton
                v-if="isLayoutChanged"
                variant="success"
                icon="fas fa-check-circle"
                type="button"
                :text="$t('[[[Zapisz ustawienia widgetów]]]')"
                @click="saveSettings"
            />
            <ActionButton
                v-if="widgets.length"
                variant="secondary"
                :icon="!isEditMode ? 'fas fa-pen-to-square' : 'fas fa-check-circle'"
                :text="toogleEditText"
                :class="{ active: isEditMode }"
                @click.stop="toogleEdit"
            />
        </div>
        <Transition name="fade">
            <div v-if="isLoaded" class="dashboard-container d-flex flex-column flex-fill">
                <div class="grid-stack h-100">
                    <base-widget
                        v-for="widget in widgets"
                        :key="widget.publicId"
                        :widget="widget"
                        :is-edit-mode="isEditMode"
                        @delete-widget="deleteWidget(widget.publicId)"
                    />
                </div>
                <div
                    v-if="!widgets.length"
                    class="dashboard-empty flex-fill d-flex align text-center flex-column justify-content-center align-items-center"
                >
                    <i class="dashboard-icon fa-duotone fa-grid-horizontal"></i>
                    <h2 class="dashboard-h2 dashboard-h2-margin-bottom">
                        {{ $t("[[[Ten dashboard nie zawiera jeszcze żadnych widgetów]]]") }}
                    </h2>
                    <p class="dashboard-p">
                        {{ $t("[[[Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.]]]") }}
                    </p>
                    <ActionButton
                        variant="primary"
                        icon="fas fa-rectangle-history-circle-plus"
                        class="dashboard-button"
                        :text="$t('[[[Dodaj widget]]]')"
                        @click.stop="openMenu"
                    />
                </div>
            </div>
            <div v-else class="position-absolute top-50 start-50 translate-middle">
                <loader />
            </div>
        </Transition>
        <Transition name="slide">
            <menu-widget
                v-if="isMenuWidget"
                :widgets="widgets"
                @add="addWidget"
                @close="isMenuWidget = false"
                @refresh="setupDragIn"
            />
        </Transition>
        <widget-settings-modal @update-widget="updateWidget" />

        <process-owner-modal />
        <process-accept-modal />
        <process-return-modal />
        <process-finish-description-modal />
        <process-sign-document-simply-sign-modal />
        <process-dynamic-modal :level="1" />
    </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, onUnmounted, ref, watch, computed } from "vue";
import HomeService, { Widget, APIWidget, WidgetModel } from "@/modules/core/home/services/HomeService";
import { cloneDeep, isEqual, merge } from "lodash";
import { useAlerts } from "@/plugins/alerts";
import { useLocalization } from "@/plugins/localization";
import { useLogging } from "@/plugins/logging";

import { GridStack, GridStackOptions, GridItemHTMLElement, GridStackNode } from "gridstack";
import "gridstack/dist/gridstack.css";

import Loader from "@/components/common/Loader.vue";
import BaseWidget from "@/modules/core/home/components/BaseWidget.vue";
import MenuWidget from "@/modules/core/home/components/MenuWidget.vue";
import WidgetSettingsModal from "@/modules/core/home/components/modals/WidgetSettingsModal.vue";

import ProcessDynamicModal from '@/modules/low-code/components/modals/process/BPMNDynamicModal.vue';
import ProcessFinishDescriptionModal from '@/modules/low-code/components/modals/process/BPMNFinishDescriptionModal.vue';
import ProcessSignDocumentSimplySignModal from '@/modules/low-code/components/modals/process/BPMNSignDocumentSimplySignModal.vue';
import ProcessAcceptModal from '@/modules/low-code/components/modals/process/BPMNAcceptModal.vue';
import ProcessReturnModal from '@/modules/low-code/components/modals/process/BPMNReturnModal.vue';
import ProcessOwnerModal from '@/modules/low-code/components/modals/process/BPMNOwnerModal.vue';

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();

const props = defineProps({
  "sitemapId": null
});

let grid: GridStack | null = null;
const CELL_HEIGHT = 135;

const isMenuWidget = ref(false);
const isMounted = ref(false);
const isLayoutChanged = ref(false);
const isEditMode = ref(false);
const isLoaded = ref(false);
const widgets = ref<Array<Widget>>([]);
const copyWidgets = ref<Array<Widget>>([]);
const publicIdSettings = ref<string>("");

const toogleEditText = computed(() =>
{
    return `${!isEditMode.value ? $t("Edytuj") : $t("Zakończ edycje")}`;
});

const toogleEdit = ({ value }: { value?: boolean }): void =>
{
    isEditMode.value = value ?? !isEditMode.value;
    isEditMode.value && openMenu();
    grid.setStatic(!isEditMode.value);
};

const openMenu = (): void =>
{
    isMenuWidget.value = true;

    if (!isEditMode.value) toogleEdit({ value: true });
};

const getWidgets = async (): Promise<void> =>
{
    try
    {
        isLoaded.value = false;

        const widgetsGet = await HomeService.getSettings(props.sitemapId);

        publicIdSettings.value = widgetsGet.publicId;

        if (!widgetsGet.config?.length) return;

        const widgetConfigs = (await HomeService.getWidgets(widgetsGet.config.map(p => p.publicId))).map(p => p.result);

        for (const item of widgetsGet.config)
        {
            const widgetConfig = widgetConfigs.find(p => p.publicId == item.publicId);

            widgetConfig.sourceConfig = merge(widgetConfig.sourceConfig, item.sourceConfig);

            const singleWidget: Widget = {
                publicId: item.publicId,
                name: widgetConfig?.name,
                w: +item.width || +widgetConfig?.width?.key,
                h: +item.height || +widgetConfig?.height?.key,
                x: item.x,
                y: item.y,
                config: widgetConfig,
            };

            widgets.value.push(singleWidget);
        }

        copyWidgets.value = cloneDeep(widgets.value);
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error($t("[[[Nie udało się pobrać widgetu/ów]]]"));
        else $log.debug(ex);
    }
    finally
    {
        isLoaded.value = true;
    }
};

const addWidget = (availableWidget: APIWidget, x?: number, y?: number): void =>
{
    if (widgets.value.some((widget: Widget) => widget.publicId === availableWidget.publicId))
    {
        $alert.warning($t("[[[Nie możesz dodać tego samego widgetu dwa razy]]]"));

        return;
    }

    const widget: Widget = {
        publicId: availableWidget.publicId,
        name: availableWidget.name,
        w: +availableWidget.width?.key,
        h: +availableWidget.height?.key,
        config: availableWidget,
        x: x ?? 0,
        y: y ?? 0,
    };

    widgets.value.push(widget);

    nextTick(() =>
    {
        grid?.makeWidget(`#x${widget.publicId}`);
    });
};

const updateWidget = async (data: WidgetModel, publicId: string): Promise<void> =>
{
    const widgetIndex = widgets.value.findIndex((item: Widget) => item.publicId === publicId);

    if (widgetIndex === -1) return;

    widgets.value[widgetIndex].w = +data.width;
    widgets.value[widgetIndex].h = +data.height;
    widgets.value[widgetIndex].config.sourceConfig = typeof data.sourceConfig === "string" ? JSON.parse(data.sourceConfig) : data.sourceConfig;

    grid?.update(`#x${publicId}`, { h: +data.height, w: +data.width });
};

const updateWidgetPosition = (_: Event, items: GridItemHTMLElement | GridStackNode | GridStackNode[]): any =>
{
    if (Array.isArray(items))
    {
        items.forEach((item) =>
        {
            const widget = widgets.value.find((w: Widget) => w.publicId === `${item.id}`.slice(1));

            if (!widget) return;

            widget.x = item.x;
            widget.y = item.y;
        });
    }
};

const deleteWidget = async (widgetID: string): Promise<void> =>
{
    grid?.removeWidget(`#x${widgetID}`, false);

    const index = widgets.value.findIndex((item: Widget) => item.publicId === widgetID);

    widgets.value.splice(index, 1);
};

const saveSettings = async (): Promise<void> =>
{
    try
    {
        const girdToSave = grid.save();

        if (Array.isArray(girdToSave))
        {
            const settings = {
                config: girdToSave.map((item) => ({
                    publicID: `${item.id}`.slice(1),
                    x: item.x,
                    y: item.y,
                    width: item.w,
                    height: item.h,
                })),
                sitemapId: props.sitemapId
            };

            if (publicIdSettings.value)
            {
                await HomeService.saveSettings(publicIdSettings.value, settings);
                $alert.success($t("[[[Udało się zapisać ustawienia widgetów]]]"));
            }
            else
            {
                await HomeService.initSettings(settings);
                $alert.success($t("[[[Udało się zapisać ustawienia widgetów]]]"));
            }

            toogleEdit({ value: false });

            copyWidgets.value = cloneDeep(widgets.value);
            isLayoutChanged.value = false;
        }
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.warning(ex.message);
        else $log.debug(ex);
    }
};

const initGrid = async (): Promise<void> =>
{
    const gridConfig: GridStackOptions = {
        acceptWidgets: true,
        staticGrid: !isEditMode.value, // Disable drag
        cellHeight: CELL_HEIGHT, // Height of one column
        float: false, // Put widget whereever you want
    };

    grid = GridStack.init(gridConfig);

    grid.on("change", (event: Event, items: GridItemHTMLElement | GridStackNode | GridStackNode[]) => updateWidgetPosition(event, items));

    grid.on("dropped", async (_0, _1, newWidget) =>
    {
        nextTick(() =>
        {
            grid?.removeWidget(`#${newWidget.id}`, true);
        });

        const publicId = `${newWidget.id}`.slice(1);
        const widgetConfig = (await HomeService.getWidgets([publicId])).map(p => p.result).first();

        if (widgetConfig == null) return;

        widgetConfig.width = { key: `${newWidget.w}`, value: `${newWidget.w}`.padStart(2, "0") };
        widgetConfig.height = { key: `${newWidget.h}`, value: `${newWidget.h}` };

        addWidget(widgetConfig, newWidget.x, newWidget.y);
    });
};

const setupDragIn = (): void =>
{
    if (grid != null)
    {
        nextTick(() =>
        {
            GridStack.setupDragIn(".new-widget", { appendTo: "body", helper: "clone" });
        });
    }
};

watch(widgets.value, (newValue: Array<Widget>) =>
{
    if (!isEqual(newValue, copyWidgets.value) && isMounted.value) isLayoutChanged.value = true;
    else if (isEqual(newValue, copyWidgets.value) && isMounted.value) isLayoutChanged.value = false;
});


onMounted(async () =>
{
    await getWidgets();
    isMounted.value = true;
    initGrid();
});

onUnmounted(() =>
{
    grid.destroy();
});
</script>

<style lang="scss" scoped>
.dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
        min-height: 49.5px;
    }

    &-h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;

        &-margin-bottom {
            margin-bottom: 10px;
        }
    }

    &-p {
        margin-bottom: 10px;
    }

    &-container {
        margin: -10px;
    }

    &-icon {
        font-size: 9rem;
        margin-bottom: 3px;
        color: #9ea4ac;
    }

    &-empty {
        padding: 3.5rem 1rem;
        position: absolute;
        left: 50%;
        pointer-events: none;
        transform: translateX(-50%);
        width: 100%;
    }

    &-button {
        pointer-events: all;
    }
}
</style>
