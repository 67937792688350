<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { ProcessFlow } from '@/modules/low-code/inc/ProcessFlow';

@Options({
    components: {},
})
export default class StepWizard extends Vue
{
    @Prop({ default: [] }) public publicId: string;
    @Prop() public processFlow: ProcessFlow;

    public options: KeyValuePair[] = [];

    public async created(): Promise<void>
    {
        await this.getOptions();
    }

    public async getOptions(): Promise<void>
    {
        try
        {
            this.options = this.processFlow.nodes;
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
    }

    public get currentStateIndex(): number
    {
        const options = this.options.map((item: any) => item.key);

        return options.indexOf(this.processFlow.currentPriority?.toString?.());
    }

    public getVariant(index: number): string
    {
        if (this.currentStateIndex > index)
        {
            return 'success';
        }
        else if (this.currentStateIndex === index)
        {
            return 'info';
        }
        else
        {
            return 'light';
        }
    }
}
</script>

<template>
    <div v-if="options && options.length" class="sw-main sw-theme-dots">
        <ul class="nav step-anchor">
            <li class="nav-item" v-for="(item, index) in options" :key="index">
                <div
                    class="nav-link"
                    :class="{
                        done: currentStateIndex > index,
                        active: currentStateIndex === index,
                        inactive: currentStateIndex < index,
                    }"
                >
                    <span style="font-size: 11px">{{ item.value || $t('[[[Brak nazwy]]]') }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
// Color Settings
$container-border-color: #eeeeee;
$nav-line-text-color: #428bca;
$nav-line-bg-color: #f3f3f5;
$nav-active-primary-color: var(--bs-primary);
$nav-inactive-secondary-color: #999999;
$nav-done-primary-color: #5cb85c;
$nav-disabled-primary-color: #f9f9f9;
$nav-danger-primary-color: #d9534f;

.sw-theme-dots {
    // Navigation style
    & > .nav {
        position: relative;
        justify-content: space-between;

        &::before {
            content: ' ';
            position: absolute;
            top: 18px;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: $container-border-color;
            border-radius: 3px;
            z-index: 1;
        }

        .nav-link {
            position: relative;
            margin-top: 35px;
            padding: 0 0.25rem 0.25rem 0.25rem;
            width: auto;
            max-width: 170px;
            text-align: center;
            word-break: normal;
            cursor: default;

            .badge {
                white-space: normal;
            }

            small {
                font-weight: bold;
            }

            &::before {
                content: ' ';
                position: absolute;
                display: block;
                top: -25px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: none;
                background: $nav-line-bg-color;
                color: $nav-line-text-color;
                text-decoration: none;
                z-index: 98;
            }

            &::after {
                content: ' ';
                position: absolute;
                display: block;
                top: -23px;
                margin: 0 auto;
                left: 0;
                right: 0;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                z-index: 99;
            }

            &.inactive {
                color: $nav-inactive-secondary-color;

                &::after {
                    background-color: $nav-inactive-secondary-color;
                }
            }

            &.active {
                color: $nav-active-primary-color !important;

                &::after {
                    background-color: $nav-active-primary-color !important;
                }
            }

            &.done {
                color: $nav-done-primary-color !important;

                &::after {
                    background-color: $nav-done-primary-color !important;
                }
            }

            &.disabled {
                color: $nav-disabled-primary-color;

                &::after {
                    background-color: $nav-disabled-primary-color;
                }
            }

            &.danger {
                color: $nav-danger-primary-color;

                &::after {
                    background-color: $nav-danger-primary-color;
                }
            }
        }
    }

    .badge {
        font-weight: 400;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.dark-theme {
    .sw-theme-dots {
        color: #afbdd1;

        & > .nav {
            &::before {
                background-color: #afbdd1;
            }

            .nav-link {
                &.inactive {
                    color: #738194;
                }

                &::after {
                    background: #738194;
                }

                &::before {
                    background: #afbdd1;
                    color: #afbdd1;
                }
            }
        }
    }
}
</style>
