import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';

export default abstract class ComponentHelper
{
    public static isManualLoad(blueprint: Blueprint): boolean
    {
        if (blueprint.hasOwnProperty('isActive'))
        {
            if (blueprint.hasOwnProperty('isCounter') && (blueprint as any).isCounter)
                return false;

            if (blueprint.hasOwnProperty('isDefaultTab') && (blueprint as any).isDefaultTab)
                return false;

            return true;
        }

        return false;
    }
}
