<script lang="ts" setup>
import { computed } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, ChoicePlusType, ChoicePlusEntryData, FREE_ANSWER_VALUE } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});

const items = computed(() =>
{
    return props.component.options
        .filter(p => props.value?.Values.includes(p.value))
        .map(p => props.builder.localization.translate(p.text));
});
const display = computed(() =>
{
    return [...items.value, props.value?.Values.includes(FREE_ANSWER_VALUE) ? props.value?.Answer : null].filter(p => p != null);
});
</script>

<template>
    <span class="badge bg-info me-1" v-for="(text, i) in display" :key="i">
        {{ text }}
    </span>
</template>
