<script lang="ts">
import { Options } from "vue-class-component";
import { Prop } from "@/helpers/Decorators";
import { Form, FormType } from "@/helpers/Form";
import LoaderMixin from "./loaderMixin";
import Pager from "@/helpers/Pager";
import CommentsService, { CommentsListItemModel } from "../services/CommentsService";
import Substitute from '@/modules/core/substitutions/views/Substitute.vue';

@Options({
    name: "Comments",
    components: { Substitute },
})
export default class Comments extends LoaderMixin
{
    @Prop({ default: "" }) public objectId: string;
    @Prop({ default: "", required: true }) public moduleName: string;
    @Prop({ default: false }) public design: boolean;
    @Prop({ default: "" }) public blueprintName: string;

    public form: FormType<any> = Form.create<any>({
        comment: "",
    });

    public comments: CommentsListItemModel[] = [];
    public filter = Form.create({});
    public pager = new Pager(1, 10, "dateCreatedUtc", "DESC");

    public get lastPage(): boolean
    {
        return this.pager.getPageSize() >= this.pager.getTotalRows();
    }

    public async created(): Promise<void>
    {
        this.pager.setTotalRows(0);
        !this.design && (await this.init());
    }

    public showInfo(id: number): void
    {
        this.$events.$emit("fetchUser", { id });
    }

    public async fetchData(): Promise<void>
    {
        try
        {
            const { totalRows, items } = await CommentsService.getCommentsList(this.objectId, this.moduleName, this.filter.data(), this.pager);

            this.pager.setTotalRows(totalRows);
            this.comments = items.map((item) => item.result);
        }
        catch (ex)
        {
            this.comments = [];
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public fetchNextPart(): void
    {
        this.pager.setPageSize(this.pager.getPageSize() + 10);
        this.fetchData();
    }

    public async saveComment(): Promise<void>
    {
        this.form.wait();

        try
        {
            await CommentsService.saveComment(this.objectId, this.moduleName, this.form.comment);
            await this.fetchData();
            this.$alert.success(this.$t("[[[Komentarz został dodany.]]]"));
            this.form.clear();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$scrollTo(".invalid-feedback"),
                    this.$alert.warning(this.$t("[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]")),
                ],
            });
        }
        finally
        {
            this.form.continue();
        }
    }
}
</script>

<template>
    <div class="row">
        <div class="col-md-12" @keydown="form.$errors.clear(($event.target as HTMLFormElement).name)">
            <ideo-form-group :invalid-feedback="form.$errors.first('comment')" :state="form.$errors.state('comment')" nospace>
                <ideo-form-textarea v-model="form.comment" :placeholder="$t('[[[Wpisz komentarz...]]]')" :rows="5" />
            </ideo-form-group>
            <ideo-button variant="success" size="sm" @click="saveComment" class="rounded mt-2">
                <i class="fa fa-plus me-1"></i>
                {{ $t("[[[Dodaj komentarz]]]") }}
            </ideo-button>
        </div>
        <div class="col-md-12 mt-3" v-if="comments && comments.length">
            <ul class="list-group">
                <li class="list-group-item list-group-item-action" v-for="(item, index) in comments" :key="index">
                    <div class="d-flex align-items-start mb-1">
                        <div class="d-flex align-items-center flex-shrink-0">
                            <UserAvatar
                                :username="item.author.user.value"
                                :src="$filters.baseurl(`storage/files/${item.author.user.pictureUrl}`)"
                                class="cursor-pointer"
                                @click="showInfo(item.author.user.key)"
                            />
                            <Substitute
                                v-if="item.author.impersonator?.key"
                                :user="item.author.impersonator?.value"
                            />
                        </div>
                        <div class="ps-2">
                            <p class="mb-0 text-primary fw-bold cursor-pointer" @click="showInfo(item.author.user.key)">
                                {{ item.author.user.value }}
                            </p>
                            <p class="mb-0 text-muted small">{{ $filters.datetime(item.author.dateUtc) }}</p>
                            <p class="mb-0">{{ item.comment }}</p>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="d-flex justify-content-center col-12">
                <ideo-button :disabled="lastPage" variant="primary" size="sm" @click="fetchNextPart" class="rounded mt-3">
                    <i class="fa fa-plus me-1"></i>
                    {{ $t("[[[Pokaż więcej komentarzy]]]") }}
                </ideo-button>
            </div>
        </div>
        <portal v-if="blueprintName" :to="`tab-counter-${blueprintName}`">
            {{ pager.getTotalRows() }}
        </portal>
    </div>
</template>
