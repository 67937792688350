import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    // --------------------------------------------------------------------------
    // Biuro
    // --------------------------------------------------------------------------
    {
        path: 'office',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // --------------------------------------------------------------------------
            // Do wprowadzenia
            // --------------------------------------------------------------------------
            {
                path: 'register-tasks',
                name: 'office-registerTasks',
                meta: { auth: true },
                component: () => import('@/modules/logito/office/views/register-tasks/List.vue'),
            },

            // --------------------------------------------------------------------------
            // Raporty
            // --------------------------------------------------------------------------

            // Dziennik korespondencji
            {
                path: 'register-search',
                name: 'office-registerSearch',
                meta: { auth: true },
                component: () => import('@/modules/logito/office/views/register-search/List.vue'),
            },

            // Budżet
            {
                path: 'budget',
                name: 'office-budget',
                meta: { auth: true },
                component: () => import('@/modules/logito/office/views/reports/Budget.vue'),
            },
        ],
    },
];

export default routes;
