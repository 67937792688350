<script lang="ts" setup>
import { ref, reactive, computed, onMounted, onUnmounted } from 'vue';
import { useEvents } from '@/plugins/events';
import { useLocalization } from '@/plugins/localization';
import { useAlerts } from '@/plugins/alerts';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { JsonHelperModel } from '../inc/Interfaces';
import { KeyValuePair } from '@/helpers/Interfaces';
import { FrontActionModal } from '@/components/common/dynamic-grid/helpers/GridEnums';
import DictionaryTermsService, { FormModel, DynamicFormData } from '@/modules/core/dictionary-terms/services/DictionaryTermsService';
import JsonForm from '../components/JsonForm.vue';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

const emit = defineEmits(["reload"]);

const props = defineProps({
  "dictionaryTypeId": { default: null },
  "editDictionary": { type: Boolean, default: true },
  "event": { type: Boolean, default: false }
});

const { $alert } = useAlerts();
const { $events } = useEvents();
const { $t } = useLocalization();

const modal = ref<IdeoModal | null>(null);

const id = ref<string | null>(null);
const jsonHelper = ref<JsonHelperModel[]>([]);
const dictionaryTypePager = ref(new Pager(1, 64, '', 'ASC'));
const dictionaryTypeOptions = ref<KeyValuePair[]>([]);
const dynamicFormData = ref<DynamicFormData>(null);

const form = reactive(
    Form.create<FormModel>({
        id: 0,
        activeToUtc: null,
        name: {},
        dictionaryType: null,
        description: '',
        position: 9999,
        value: null,
        publicId: '',
    })
);

const isEditMode = computed(() => id.value !== null && !!id.value);
const title = computed(() => (isEditMode.value ? $t('[[[Edytuj]]]') : $t('[[[Dodaj]]]')));

const setNewJson = (jsonForm: any) =>
{
    form.value = JSON.stringify(formatData(jsonForm));
    form.$errors.clear('value');
};

const getJson = async () =>
{
    if (dynamicFormData.value != null)
        jsonHelper.value = await DictionaryTermsService.getJsonForDynamicForm(dynamicFormData.value.licence, dynamicFormData.value.endpointId, dynamicFormData.value.fieldName);
    else if (form.dictionaryType)
        jsonHelper.value = await DictionaryTermsService.getJson(form.dictionaryType?.key);
};

const updateDictionaryType = () =>
{
    getJson();
    form.$errors.clear('dictionaryType');
};

const formatData = (initial: any) =>
{
    const keys = ['key'];
    const data = {} as any;

    for (const item in initial)
    {
        data[item] = initial[item] as any;

        const isKeyValuePair = data[item] ? Object.keys(data[item]).some((key) => keys.includes(key)) : false;
        const isArray = data[item] ? Array.isArray(data[item]) : false;

        if (isKeyValuePair || isArray)
        {
            keys.forEach((key: string, index) =>
            {
                if (isArray)
                    data[item] =
                        data[item][index] && data[item][index][key]
                            ? data[item].map((item: any) => item.key)
                            : data[item];
                else
                    data[item] =
                        data[item] &&
                        (data[item][key] ||
                            data[item][key] === null ||
                            data[item][key] === 0 ||
                            data[item][key] === false)
                            ? data[item][key]
                            : data[item];
            });
        }
    }

    return data;
};

const fetchDictionaryTypeOptions = async () =>
{
    const response = dynamicFormData.value != null
        ? await DictionaryTermsService.optionsForDynamicForm(dynamicFormData.value.licence, dynamicFormData.value.endpointId, dynamicFormData.value.fieldName)
        : await DictionaryTermsService.options(dictionaryTypePager.value.data());

    dictionaryTypeOptions.value = response.items.map((item) => item.result);
};

const loadData = async () =>
{
    form.value = '';

    if (id.value)
    {
        try
        {
            const response = await DictionaryTermsService.fetch(id.value);

            form.withData(response.result);
        }
        catch (ex: any)
        {
            $alert.warning(ex.message);
        }
    }
};

const onSubmit = async () =>
{
    form.wait();

    try
    {
        if (!isEditMode.value)
        {
            const response = dynamicFormData.value != null
                ? await DictionaryTermsService.createForDynamicForm(dynamicFormData.value.licence, dynamicFormData.value.endpointId, dynamicFormData.value.fieldName, form.formatData())
                : await DictionaryTermsService.create(form.formatData());

            $alert.success($t('[[[Termin został dodany.]]]'));
            form.withData(response.result);
            emit('reload', response.result);
        }
        else
        {
            await DictionaryTermsService.update(id.value, form.formatData());
            $alert.success($t('[[[Termin został zaktualizowany.]]]'));
            emit('reload');
        }

        $events.$emit('refetchData');
        modal.value?.hide();
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.danger(ex.message);
        else if (ex.code === 422) form.$errors.record(ex.data.errors);
    }
    finally
    {
        form.continue();
    }
};

const showModal = async (_id: string | null) =>
{
    form.clear();
    id.value = _id;

    await fetchDictionaryTypeOptions();

    form.dictionaryType = dictionaryTypeOptions.value.find((item) => item.key === props.dictionaryTypeId);
    id.value && await loadData();

    getJson();
    modal.value?.show();
};

const showModalForDynamicForm = async (licence: string, endpointId: string, fieldName: string) =>
{
    form.clear();
    dynamicFormData.value = {
        licence: licence,
        endpointId: endpointId,
        fieldName: fieldName
    };

    await fetchDictionaryTypeOptions();

    form.dictionaryType = dictionaryTypeOptions.value.first();

    getJson();
    modal.value?.show();
};

onMounted(() =>
{
    if (props.event) $events.$on(FrontActionModal.EditCreate, showModal);
});

onUnmounted(() =>
{
    if (props.event) $events.$off(FrontActionModal.EditCreate);
});

defineExpose({
    showModal,
    showModalForDynamicForm
});
</script>

<template>
    <IdeoModal ref="modal" :title="title" centered>
        <template #default>
            <IdeoForm
                id="dictonary-terms-modal-form"
                @submit.prevent="onSubmit()"
                @input="form.$errors.clear($event.target.name)"
            >
                <IdeoFormGroup v-if="isEditMode" :label="$t('[[[Id]]]')">
                    <IdeoFormInput v-model="form.id" type="text" disabled />
                </IdeoFormGroup>
                <IdeoFormGroup
                    :label="$t('[[[Termin]]]')"
                    :invalid-feedback="form.$errors.first('dictionaryType')"
                    :state="form.$errors.state('dictionaryType')"
                    required
                >
                    <IdeoSelect
                        v-model="form.dictionaryType"
                        :options="dictionaryTypeOptions"
                        :placeholder="$t('[[[Wybierz typ]]]')"
                        :disabled="!!id || !editDictionary"
                        @update:modelValue="updateDictionaryType"
                    />
                </IdeoFormGroup>
                <IdeoFormLocalize v-slot="{ locale }">
                    <IdeoFormGroup
                        :label="$t('[[[Nazwa]]]')"
                        :invalid-feedback="form.$errors.first('name')"
                        :state="form.$errors.state('name')"
                        required
                    >
                        <IdeoFormInput v-model="form.name[locale]" type="text" name="name" v-focus />
                    </IdeoFormGroup>
                </IdeoFormLocalize>
                <template v-if="form.dictionaryType">
                    <JsonForm
                        :dictionary="form.dictionaryType.key"
                        :helper="jsonHelper"
                        :errors="form.$errors"
                        :value="form.value"
                        :is-edit="isEditMode"
                        @jsonChanged="setNewJson"
                    />
                </template>
                <IdeoFormGroup
                    :label="$t('[[[Opis]]]')"
                    :invalid-feedback="form.$errors.first('description')"
                    :state="form.$errors.state('description')"
                >
                    <IdeoFormTextarea
                        v-model="form.description"
                        name="description"
                        rows="5"
                        max-rows="10"
                        :state="form.description && form.description.length <= 500"
                        :placeholder="$t('[[[Maksymalnie można wprowadzić 500 znaków]]]')"
                        class="scroll field-valid"
                    />
                </IdeoFormGroup>
                <IdeoFormGroup
                    :label="$t('[[[Data dezaktywacji]]]')"
                    :invalid-feedback="form.$errors.first('activeToUtc')"
                    :state="form.$errors.state('activeToUtc')"
                >
                    <IdeoDatetime v-model="form.activeToUtc" type="datetime" name="activeToUtc" />
                </IdeoFormGroup>
            </IdeoForm>
        </template>
        <template #modal-footer="{ cancel }">
            <IdeoButton type="submit" variant="success" :disabled="!form.active()" form="dictonary-terms-modal-form">
                {{ $t('[[[Zapisz]]]') }}
            </IdeoButton>
            <IdeoButton variant="secondary" @click="cancel()">{{ $t('[[[Anuluj]]]') }}</IdeoButton>
        </template>
    </IdeoModal>
</template>
