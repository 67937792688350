<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import Pager from '@/helpers/Pager';
import { RelatedDocumentsContract } from '.';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DetailsBuilderContract } from '@/components/builder/details';
import { KeyValuePair } from '@/helpers/Interfaces';
import FormsService from '@/modules/studio/forms/services/FormsService';
import RelatedDocuments from '@/modules/low-code/components/RelatedDocuments.vue';
import properties from '../../properties';
import FieldConfigsService, { FieldModel } from '@/modules/low-code/services/FieldConfigsService';
import ColumnsCustomizer from '@/components/common/dynamic-grid/ColumnsCustomizer.vue';
import FieldFilters from '@/components/forms/properties/FieldFilters.vue';

@Options({
    name: 'RelatedDocumentsBlueprint',
    components: {
        RelatedDocuments,
        ColumnsCustomizer,
        FieldFilters,
        ...properties,
    },
})
export default class RelatedDocumentsBlueprint extends Vue
{
    @Prop({ default: null }) public details!: DetailsBuilderContract;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: RelatedDocumentsContract;

    public moduleOptions: any[] = [];
    public selectedModules: KeyValuePair[] = [];
    public filters: any = null;

    public get licence(): string
    {
        return this.$route.params.licence as string;
    }

    public get publicId(): string
    {
        return this.$route.params.publicId as string;
    }

    public get design(): boolean
    {
        return this.details.designMode();
    }

    public get isColumns(): boolean
    {
        return this.blueprint.modules.length == 1;
    }

    public get columnsArray(): FieldModel[]
    {
        const result = this.blueprint.columns != null
            ? Object.values(this.blueprint.columns).filter(p => p.visibility).orderBy(p => p.order).toArray()
            : [];

        return result;
    }

    public async created(): Promise<void>
    {
        if (this.design)
        {
            await this.fetchModuleOptions();
            this.selectedModules = this.blueprint.modules?.map?.((p) => this.moduleOptions.find((x) => x.key == p));
            this.filters = this.blueprint.filtersJson ? JSON.parse(this.blueprint.filtersJson) : null;
        }
    }

    public updated(): void
    {
        this.updateModule(this.selectedModules);
    }

    public async fetchModuleOptions(): Promise<void>
    {
        this.moduleOptions = (await FormsService.getModuleOptions(new Pager(1, 999))).items.map((p) => p.result);
    }

    public async getColumns(moduleId: string): Promise<void>
    {
        if (!moduleId)
        {
            this.blueprint.columns = null;

            return;
        }

        try
        {
            const columns = await FieldConfigsService.getFieldConfigDefaultSettings(
                moduleId,
                'Grid',
                'search'
            );

            this.blueprint.columns ??= columns;
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    @Watch('selectedModules')
    public async updateModules(): Promise<void>
    {
        this.blueprint.modules = this.selectedModules?.map?.((p) => p.key);
    }

    @Watch('filters', { deep: true })
    public async updateFiltersJson(): Promise<void>
    {
        this.blueprint.filtersJson = this.filters ? JSON.stringify(this.filters) : null;
    }

    public async updateModule(value: KeyValuePair[]): Promise<void>
    {
        if (value.length == 1)
            await this.getColumns(value[0].key);
        else
            await this.getColumns(null);
    }
}
</script>

<template>
    <dynamic-details-component-wrapper :details="details" :parent="parent" :blueprint="blueprint">
        <template #default>
            <related-documents
                v-if="!design"
                :blueprint-name="blueprint.name"
                :document-id="publicId"
                :parent-type="licence"
                :allow-bindings="blueprint.allowBindings"
                :blueprint-id="blueprint.id"
                :show-filters="blueprint.showFilters"
            />
            <div v-else>
                <list-view v-if="!isColumns" :items="[]" :preload="false">
                    <template #row>
                        <list-view-item :title="$t('[[[Data utworzenia]]]')" />
                        <list-view-item :title="$t('[[[Autor]]]')" />
                        <list-view-item :title="$t('[[[Moduł]]]')" />
                        <list-view-item :title="$t('[[[Tytuł]]]')" />
                        <list-view-item :title="$t('[[[Spółka]]]')" />
                        <list-view-item :title="$t('[[[Rejestr]]]')" />
                        <list-view-item :title="$t('[[[Sygnatura]]]')" />
                        <list-view-item :title="$t('[[[Status]]]')" />
                    </template>
                </list-view>
                <list-view v-else :items="[]" :preload="false">
                    <template #row>
                        <list-view-item v-for="(column, i) in columnsArray" :key="i" :title="column.headerName" />
                    </template>
                </list-view>
            </div>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <ideo-form-group>
                <ideo-form-checkbox v-model="blueprint.allowBindings">
                    {{ $t('[[[Pokaż fukncję dowiązania dokumentów wg uprawnień]]]') }}
                </ideo-form-checkbox>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Moduły]]]')">
                <ideo-select v-model="selectedModules" :options="moduleOptions" :multiselect="true" @update:model-value="updateModule" />
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Kto może wiązać dokumenty]]]')">
                <ideo-form-checkbox v-model="blueprint.allowAuthor">
                    {{ $t('[[[Autor dokumentu]]]') }}
                </ideo-form-checkbox>
                <ideo-form-checkbox v-model="blueprint.allowTargets">
                    {{ $t('[[[Osoby które realizują obecnie zadanie w obiegu dokumentu]]]') }}
                </ideo-form-checkbox>
                <ideo-form-checkbox v-model="blueprint.allowAll">
                    {{ $t('[[[Osoby które mają dostęp do dokumentu]]]') }}
                </ideo-form-checkbox>
            </ideo-form-group>
            <field-visible :details="details" :blueprint="blueprint" />
            <field-checkbox v-model="blueprint.showFilters" :label="$t('[[[Pokaż filtry na liście]]]')" />
            <field-filters
                v-if="isColumns"
                v-model="filters"
                :selected-modules="selectedModules"
                :label="$t('[[[Filtry]]]')"
            />
            <div v-if="isColumns">
                <portal-target name="view-manager-columns" ref="target" />
                <columns-customizer :columns="blueprint.columns" />
            </div>
        </template>
    </dynamic-details-component-wrapper>
</template>
