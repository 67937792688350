<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { DateTime } from 'luxon';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'DateTimeFormatter'
})
export default class DateTimeFormatter extends Vue
{
    @Prop()
    public current: DateTime;

    @Prop()
    public original: DateTime;
}
</script>

<template>
    <div class="d-flex align-items-center small date-formatter">
        <span class="original-value text-nowrap" v-if="original">
            {{ $filters.datetime(original.toString()) }}
        </span>
        <span class="current-value text-nowrap" v-if="current">
            {{ $filters.datetime(current.toString()) }}
        </span>
    </div>
</template>
