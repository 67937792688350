// Blueprints
import { Blueprint, BlueprintConstructor } from '../blueprints/Blueprint';
import { RootBlueprint } from '../blueprints/RootBlueprint';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

// --------------------------------------------------

export class BlueprintFactory
{
    protected types: Record<string, BlueprintConstructor>;

    public constructor(types: Record<string, BlueprintConstructor>)
    {
        this.types = types;
    }

    protected typeName(type: string): string
    {
        return upperFirst(camelCase(`${type}-type`));
    }

    public create(type: string, id: string, name: string): Blueprint
    {
        return new this.types[this.typeName(type)](id, name);
    }

    public createRoot(id: string, name: string): RootBlueprint
    {
        return undefined;
    }

    public fromContract(contract: Blueprint): Blueprint
    {
        let blueprint: Blueprint = null;

        blueprint = this.create(contract.type, contract.id, contract.name);
        blueprint = Object.assign(blueprint, contract);

        return blueprint;
    }
}
