import { axios } from '@/plugins/axios';
import { Resource, Statement } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';

/**
 * SubstitutionService
 */
export default class SubstitutionService
{
    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: string): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`simple-fetch/substitutions/${id}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel, formType: string): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`simple-command/${formType}`, model)).data;
    }

    /**
     * @param id number
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(id: string, model: FormModel, formType: string): Promise<Statement>
    {
        return (await axios.put<Statement>(`simple-command/${formType}/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: string): Promise<Statement>
    {
        return (await axios.delete(`simple-command/substitutions/${id}`)).data as Statement;
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    dateBeginUtc: DateTime;
    dateEndUtc: DateTime;
    isPending: boolean;
    canBeDeleted: boolean;
    replacedByUserId: number;
    userBeingReplacedId: number;
    replacedByUserFirstName: string;
    replacedByUserLastName: string;
    userBeingReplacedFirstName: string;
    userBeingReplacedLastName: string;
    facePictureUrl: string;
}

export interface FilterModel
{
    userBeingReplaced: number;
    replacingUser: number;
    "dateBeginUtc.FromUtc": DateTime;
    "dateBeginUtc.ToUtc": DateTime;
    "dateBeginUtc.DaysBeforeNow": DateTime;
    "dateBeginUtc.DaysAfterNow": DateTime;
    "dateEndUtc.FromUtc": DateTime;
    "dateEndUtc.ToUtc": DateTime;
    "dateEndUtc.DaysBeforeNow": DateTime;
    "dateEndUtc.DaysAfterNow": DateTime;
    active?: boolean;
}

export interface FormModel
{
    dateBeginUtc: DateTime;
    dateEndUtc: DateTime;
    description: string;
    replacedByUserId: number;
    userBeingReplacedId: number;
    modules: number;
}

export interface AvailableActions{
    canDelete: boolean,
    canEditFull: boolean,
    canEditSimple: boolean
}
