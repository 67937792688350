<script lang="ts" setup>
import { computed, inject, Ref } from 'vue';
import { DocumentPreviewType } from '.';
import { Resource as FileResource } from "@/modules/core/files/services/StorageService";
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DetailsBuilderContract } from '@/components/builder/details';
import FileReader from "@/views/partials/components/attachments/FIleReader.vue";
import properties from '../../properties';

defineOptions({
    name: 'DocumentPreviewBlueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const filePreview = inject<Ref<FileResource>>('file-preview', null);

const blueprint = computed(() => props.blueprint);
const design = computed(() => props.details.designMode());
</script>

<template>
    <dynamic-details-component-wrapper :details="details" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div v-if="!design">
                <file-reader v-if="filePreview && filePreview.url" :src="filePreview.url" />
            </div>
            <div v-else class="d-flex justify-content-center align-items-center h-100">
                <i class="far fa-ballot-check fa-8x"></i>
            </div>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <dynamic-details-accordion>
                <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                    <field-visible :details="details" :blueprint="blueprint" />
                </dynamic-details-accordion-item>
            </dynamic-details-accordion>
        </template>
    </dynamic-details-component-wrapper>
</template>

<style lang="scss" scoped>
.fa-ballot-check {
    color: #edeff1;
}
</style>
