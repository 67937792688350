import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'validator',
    name: '[[[Walidator]]]',
    icon: 'fa-solid fa-circle-check',
    group: 'logito',
    position: 12,
};

export enum ValidationMode {
    Blocking,
    Informational,
}

export interface ValidatorContract extends Blueprint, VisibleBlueprint, HasLabel, HasWidth {
    mode: string;
    errorMessage: Record<string, string>;
    confirmationMessage: Record<string, string>;
    validationScript: string;
}

export class ValidatorEntry extends ValidEntry<boolean>
{
    public type: string = Definition.type;
    public data: boolean = false;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(
        blueprint: ValidatorContract,
        form: FormBuilderContract,
        preprocess: ProcessCallback
    ): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry(this.data);
    }

    public validate(blueprint: ValidatorContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        return this.valid();
    }
}

export const instanceOfValidatorEntry = (object: any): object is ValidatorEntry =>
{
    return object && 'type' in object && object.type === Definition.type;
};

export class ValidatorType implements ValidatorContract, ValidatableBlueprint, EntryFactory<ValidatorEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public width: number;
    public help: string;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public errors: ValidationErrors;
    public mode: string;
    public errorMessage: Record<string, string>;
    public confirmationMessage: Record<string, string>;
    public validationScript: string;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.label = { 'pl-PL': 'Walidator' };
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
        this.showLabel = true;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.mode = ValidationMode[ValidationMode.Blocking];
        this.errorMessage = {};
        this.confirmationMessage = {};
        this.validationScript = '';
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): ValidatorEntry
    {
        return new ValidatorEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors,
        };
    }
}
