import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { HasDescription } from '@/components/builder/form/traits/HasDescription';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasTitle } from '@/components/builder/form/traits/HasTitle';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';

export const Definition: BlueprintDefinition = {
    type: 'section',
    name: '[[[Sekcja]]]',
    icon: 'fa-square',
    group: 'functionalities',
    position: 2,
};

export enum SectionColors {
    None = 'none',
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Danger = 'danger',
    Light = 'light',
    Dark = 'dark',
}

export interface SectionContract
    extends AggregateBlueprint,
    VisibleBlueprint,
    HasTitle,
    HasDescription,
    HasWidth,
    HasHelp {
    color: SectionColors;
}

export class SectionType implements SectionContract
{
    public id: string;
    public type: string;
    public name: string;
    public title: Record<string, string>;
    public showTitle: boolean;
    public description: Record<string, string>;
    public color: SectionColors;
    public components: Blueprint[];
    public help: Record<string, string>;
    public width: number;
    public minWidth: number;
    public exceptWidth: number[];
    public visible: AlwaysChoice | WhenChoice;
    public visibleWhen: string;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.title = {};
        this.showTitle = false;
        this.description = {};
        this.color = SectionColors.None;
        this.components = [];
        this.width = 0;
        this.minWidth = 3;
        this.exceptWidth = [5];
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }
}
