import peopleSearchList from './staffSearchList';
import staffTree from './staffTree';

export default {
    info: {
        namespaced: true,
        modules: {
            people: peopleSearchList,
            staffTree: staffTree
        }
    }
};
