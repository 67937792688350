<template>
    <div class="h-100 d-flex align-items-center">
        <div class="custom-control custom-checkbox btn btn-secondary btn-active-none" @click="updateModel">
            <label class="three-state-label me-2" :class="stateClass" :for="uid">{{ label }}</label>
            <input type="checkbox" class="three-state-checkbox" :checked="isChecked" :id="uid" />
        </div>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'three-state-checkbox'
})
export default class ThreeStateCheckbox extends Vue
{
    private currentValue: boolean | null = null;

    @Prop({ default: "" })
    public modelValue: boolean;

    @Prop({ default: "" })
    public label: string;

    public get uid(): string
    {
        return `__IDEO__${this.$.uid}__`;
    }

    public get isChecked(): boolean
    {
        if (this.currentValue === null) return false;

        return true;
    }

    public get stateClass(): string
    {
        return this.currentValue === null ? 'empty' : this.currentValue ? 'checked' : 'denied';
    }

    public valueChanged(value: boolean | null): void
    {
        this.$emit("update:modelValue", value);
    }

    public updateModel(): void
    {
        this.currentValue = this.currentValue === null ? true : this.currentValue === true ? false : null;
        this.valueChanged(this.currentValue);
    }

    @Watch('modelValue', { immediate: true })
    public onModelChanged(value: any[] | any): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }
}
</script>

<style lang="scss" scoped>
    .custom-control {
        padding-left: 0.75rem !important;
        padding-right: 2rem;
        position: relative;
        --right-postion: 10px;
    }
    .three-state-checkbox {
        appearance: none;
        position: absolute;
        right: var(--right-postion);
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
    }

    .three-state-label{
        margin: 0;

        &::before{
            content: "";
            color: #fff;
            font-size: 8px;
            position: absolute;
            display: flex;
            pointer-events: none;
            width: 1rem;
            height: 1rem;
            font-weight: bold;
            border: 1px solid #869fac;
            background-color: #fff;
            right: var(--right-postion);
            font-family: var(--fa-font-family);
            top: 50%;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);
            border-radius: 4px;
        }
        &.checked{
            &::before {
                background-color: $primary;
                content: "\f00c";
                border-color: var(--hover-color);
            }
        }

        &.denied{
            &::before {
                background-color: $danger;
                content: "\f00d";
                border-color: $danger;
            }
        }
    }
</style>
