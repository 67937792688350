import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPresenter } from '@/components/builder/form/traits/HasPresenter';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { isImage, resizeImage, convertImage } from '@/helpers/Images';
import { except } from '@/helpers/Utils';

export const Definition: BlueprintDefinition = {
    type: 'attachments',
    name: '[[[Załączniki]]]',
    icon: 'fa-upload',
    group: 'primary',
    position: 6,
    disabled: true
};

export class AttachmentsEntry extends ValidEntry<any[]>
{
    public type: string = Definition.type;
    public data: any[] = null;
    public hasValues?: boolean = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: AttachmentsContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry(this.data?.map(p => except(p, 'upload', 'thumb')));
    }

    public validate(blueprint: AttachmentsContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && (this.data == null || this.data.length == 0))
            {
                this.errors.values = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }

    public async prepare(blueprint: AttachmentsContract, file: File)
    {
        try
        {
            if (isImage(file))
            {
                if (blueprint.optimizeImage)
                    file = await resizeImage(file, blueprint.imageMaxSize, blueprint.convertTo);
                else if (blueprint.convertTo != null)
                    file = await convertImage(file, blueprint.convertTo);
            }
        }
        catch (ex) { /** ignore */ }

        return file;
    }

    public async upload(blueprint: AttachmentsContract, storageService: (file: File, index: number) => Promise<any>): Promise<void>
    {
        const attachments = this.data || [];

        for (let i = 0; i < attachments.length; i++)
        {
            const attachment = attachments[i];

            if (attachment && attachment.upload)
            {
                attachment.upload = await this.prepare(blueprint, attachment.upload);
                attachments[i] = await storageService(attachment.upload, i);
            }
        }

        this.data = attachments;
    }
}

export const instanceOfAttachmentsEntry = (object: any): object is AttachmentsEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface AttachmentsContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth, HasFilter, HasPresenter
{
    extensions: string;
    maxFileSize: number;
    maxFiles: number;
    optimizeImage: boolean;
    imageMaxSize: number;
    convertTo: null|'webp';
}

export class AttachmentsType implements AttachmentsContract, ValidatableBlueprint, EntryFactory<AttachmentsEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public extensions: string;
    public maxFileSize: number;
    public maxFiles: number;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;
    public optimizeImage: boolean;
    public imageMaxSize: number;
    public convertTo: null|'webp';

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Załączniki' };
        this.showLabel = true;
        this.extensions = '';
        this.maxFileSize = 10;
        this.maxFiles = 1;
        this.help = {};
        this.minWidth = 2;
        this.width = 0;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
        this.optimizeImage = false;
        this.imageMaxSize = 2560;
        this.convertTo = null;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): AttachmentsEntry
    {
        return new AttachmentsEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }

    public getColumnExpression(): string|null
    {
        return `${this.name}$..Id`;
    }
}
