import { Data, ChartData } from '@/modules/core/common/services/GridService';
import { ChartData as IChartData } from 'chart.js/auto';
import { DateTime } from 'luxon';
import { months, quarters, getColor, colors } from '@/modules/core/home/inc/helpers';
import { uniqBy } from 'lodash';
import { $t } from '@/plugins/localization';

export default class WidgetDataHelper
{
    public static getWidgetData(data: Data<[{ data: ChartData }]>): IChartData
    {
        if (!data) return { labels: [], datasets: [] };

        const labels = data.series.map((item) =>
        {
            return item.documents.map((el) =>
            {
                return el.data.data.map((element) =>
                {
                    let value = element.value;

                    if (item.config.dateGroupType == null || item.config.dateGroupType?.key === 'Date')
                    {
                        value = element.value ?? $t('[[[Brak]]]');
                    }
                    else if (item.config.dateGroupType?.key === 'Month')
                    {
                        const date = DateTime.fromMillis(Number.parseInt(element.key));

                        value = months().find((p) => p.key == date.month).value + ' ' + date.year;
                    }
                    else if (item.config.dateGroupType?.key === 'Quarter')
                    {
                        const date = DateTime.fromMillis(Number.parseInt(element.key));

                        value = quarters().find((p) => p.key == date.quarter).value + ' ' + date.year;
                    }
                    else if (item.config.dateGroupType?.key === 'Year')
                    {
                        const date = DateTime.fromMillis(Number.parseInt(element.key));

                        value = date.year.toString();
                    }

                    return {
                        ...element,
                        value,
                    };
                });
            }).flat();
        }).flat();

        const labelsUnique = uniqBy(labels, 'key');

        const result = data.series.reduce(
            (acc, item, _, array) =>
            {
                const backgroundColor =
                array.length > 1
                    ? getColor(item.color)
                    : new Array(item.documents[0]?.data.data.length).fill('').map((_, index) => this.getColorByIndex(index));

                const datasetLabel = item.name;

                const _data = item.documents[0]?.data.data ?? [];

                const dataset = {
                    label: datasetLabel,
                    data: labelsUnique.map((label) =>
                    {
                        const value = _data.find((el) => el.key === label.key);

                        return value ? value.amount : 0;
                    }),
                    backgroundColor: backgroundColor,
                    weight: 0.3,
                };

                acc.datasets.push(dataset);

                return acc;
            },
            { labels: [], datasets: [] }

        );

        result.labels = labelsUnique.map((item) => item.value);

        return result;
    }

    private static getColorByIndex(index: number): string
    {
        const keys = Object.keys(colors);
        const indexMod = index % keys.length;
        const colorName = keys[indexMod];
        const color = getColor(colorName);

        return color;
    }
}
