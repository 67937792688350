<script lang="ts" setup>
import { computed } from 'vue';
import { computedAsync } from '@vueuse/core';
import FormsService from '@/modules/studio/forms/services/FormsService';
import { FluentFormBuilder } from '@/components/forms';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { handleException } from '@/helpers/Utils';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { Option } from '@/helpers/Interfaces';
import FieldSelect from './FieldSelect.vue';
import { FormBuilderContract } from '@/components/builder/form';

defineOptions({
    name: 'field-component',
    components: {
        'field-select': FieldSelect
    }
});

const props = defineProps({
  "form": null,
  "label": null,
  "modelValue": null,
  "required": { type: Boolean, default: false },
  "disabled": { type: Boolean, default: false },
  "description": { default: null },
  "options": { default: null },
  "formId": { default: null },
  "componentType": { default: null },
  "componentFilter": { type: Function, default: null },
  "invalidFeedback": { type: Function,  }
});

const emit = defineEmits(["update:modelValue"]);

const model = computed({
    get() { return props.modelValue; },
    set(value) { emit('update:modelValue', value); }
});

const { $alert } = useAlerts();
const { $log } = useLogging();

const options = computedAsync(async () =>
{
    if (props.options)
        return props.options;

    try
    {
        if (!props.formId) return;

        const result = await FormsService.fetchPublished(props.formId);
        const builder = FluentFormBuilder
            .setup(false, true)
            .blueprint(() => result.definition, () => props.formId)
            .entry(() => null, () => 0)
            .make();

        return builder.schema.components(props.componentType)
            .filter(p => props.componentFilter ? props.componentFilter(p) : true)
            .map(p => ({ value: p.id, text: props.form.localization.translate((p as HasLabel).label) }));
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });

        return [];
    }
});
</script>

<template>
    <field-select
        v-model="model"
        :options="options"
        :label="props.label"
        :invalid-feedback="props.invalidFeedback"
        :required="props.required"
        :disabled="props.disabled"
        :description="props.description"
        v-if="options"
    />
</template>
