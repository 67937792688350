<script lang="ts" setup>
import { computed } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, TextFieldTypes, TextType } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});

const display = computed(() =>
{
    if (props.component.fieldType == TextFieldTypes.Password)
        return '******';

    return props.value;
});
</script>

<template>
    {{ display }}
</template>
