import { axios } from '@/plugins/axios';
import { Pagination, Statement, Resource } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { SectionItem } from './types';
import { DateTime } from 'luxon';

export default class ModulesService
{
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<ListItemModel>>
    {
        return (await axios.get<Pagination<ListItemModel>>(`admin/studio/modules`, {
            params: {...filter, ...pager.data()}
        })).data;
    }

    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/studio/modules/${id}`)).data;
    }

    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/studio/modules`, model)).data;
    }

    public static async update(id: number, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/studio/modules/${id}`, model)).data;
    }

    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/studio/modules/${id}`)).data as Statement;
    }

    public static async export(id: number): Promise<any>
    {
        const filename = `module-${DateTime.now().toFormat('yyyy-MM-dd_HH-mm-ss')}.json`;

        return axios
            .get(`admin/studio/modules/${id}/export`)
            .then(res =>
            {
                if (res.status == 200)
                {
                    const blob = new Blob([JSON.stringify(res.data, null, 4)], { type : 'application/json' });

                    if ((window.navigator as any).msSaveOrOpenBlob)
                    {
                        (navigator as any).msSaveBlob(blob, filename);
                    }
                    else
                    {
                        const a = document.createElement('a');

                        document.body.appendChild(a);
                        a.href = window.URL.createObjectURL(blob);
                        a.download = filename;
                        a.target = '_blank';
                        a.click();
                        a.remove();
                    }
                }
            });
    }

    public static async import(definition: any): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/studio/modules/import`, definition)).data;
    }
}

export interface FilterModel
{
    name: string;
}

export interface ListItemModel
{
    id: number;
    name: string;
    icon: string;
    isActive: string;
    isHidden: string;
}

export interface FormModel
{
    id: number;
    categoryId: number;
    name: string;
    icon: string;
    isActive: boolean;
    isHidden: boolean;
    sections: SectionItem[];
}
