<template>
    <div class="form-group">
        <label v-if="label">{{ label }}<var v-if="required">*</var></label>
        <div class="input-group">
            <input
                type="text"
                class="form-control pointer"
                :value="modelValue"
                :readonly="true"
                :placeholder="placeholder"
                @click="openEditor"
            />
            <button type="button" class="btn btn-secondary" @click="openEditor">
                <i class="fas fa-cog"></i>
            </button>
        </div>
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>

        <ideo-modal v-model="modal" id="expression-modal" :title="placeholder" size="xl" centered scrollable>
            <template #default>
                <textarea v-model="buffer" class="form-control h-100" :rows="5" spellcheck="false"></textarea>
            </template>
            <template #helper>
                <div class="legend">
                    <h5 class="fw-bold2 mb-3">{{ $t('[[[Skrypt walidacyjny]]]') }}</h5>
                    <p class="mb-0">
                        {{ $t('[[[Kod musi kończyć się wyrażeniem, które zwraca wartość typu boolean]]]') }}
                    </p>
                    <p class="mb-0">{{ $t('[[[W obrębie skryptu możliwe jest korzystanie ze wszystkich serwisów dostępnych w skryptach np. w źródłach danych]]]') }}</p>
                    <p class="mb-0">{{ $t('[[[Dostępne są propki Form i Entry zawierające odpowiednio strukturę formularza oraz dane z wypełnionego formularza]]]') }}</p>
                    <p class="mb-0">{{ $t('[[[W obiekcie data dostępne są propki isEdit (bool, czy dokument jest w edycji) i processId]]]') }}</p>
                    <p />
                    <div>
                        <h6>
                            {{ $t('[[[Odwołanie do wartości pola numerycznego]]]') }}
                        </h6>
                        <pre>Entry.Numeric1.Data</pre>
                        <h6>
                            {{ $t('[[[Odwołanie do klucza pierwszego wybranego terminu słownika w polu Słownik Logito]]]') }}
                        </h6>
                        <pre>Entry.LogitoDictionary1.Data[0].Key</pre>
                        <h6>{{ $t('[[[Sprawdzenie, czy wybrano konkretną opcję w polu wyboru]]]') }}</h6>
                        <pre>Entry.LogitoChoice1.Data.Contains(Form.LogitoChoice1.Options.single(p => p.Value == '1').Key);
                        </pre>
                        <h6>{{ $t('[[[Skrypt sprawdzający, czy data w polu jest w przeszłości i czy kwota jest większa niż 1000]]]') }}</h6>
                        <pre>
let amountCondition = Entry.Numeric1.Data > 1000;
let dateCondition = Entry.Date1.Data.CompareTo(DateTime.UtcNow) == -1;

amountCondition && dateCondition;
                        </pre>
                    </div>
                </div>
            </template>
            <template #modal-footer>
                <button class="btn btn-primary" @click.stop.prevent="saveExpression">
                    {{ $t('[[[Zapisz]]]') }}
                </button>
                <ideo-button variant="secondary" @click.stop.prevent="closeEditor">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';

@Options({
    name: 'field-validator-expression',
})
export default class FieldValidatorExpression extends Vue
{
    @Prop() public placeholder: string;
    @Prop() public modelValue: string;
    @Prop() public invalidFeedback: (value: string) => string;
    @Prop({ default: null }) public label: string;
    @Prop({ default: false }) public required: boolean;

    public modal = false;
    public buffer: string = null;

    public openEditor(): void
    {
        this.buffer = this.modelValue;
        this.modal = true;
    }

    public closeEditor(): void
    {
        this.buffer = '';
        this.modal = false;
    }

    public saveExpression(): void
    {
        this.triggerInput(this.buffer);
        this.closeEditor();
    }

    @Emit('update:modelValue')
    public triggerInput(value: string): string
    {
        return value;
    }
}
</script>
