<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance, onMounted, onUnmounted } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { DetailsBuilderContract } from '@/components/builder/details';
import { DetailsContract } from '.';
import { RowContract } from '../row';
import { last as _last} from 'lodash';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import properties from '../../properties';
import { Events } from '@/components/builder/base/managers/EventManager';

const instance = getCurrentInstance();

defineOptions({
    name: 'DetailsBlueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "builder": null
});

const { $t } = useLocalization();

const refreshKey = ref(1);

const details = computed(() => props.builder as DetailsBuilderContract);
const blueprint = computed(() => props.builder.blueprint as DetailsContract);
const design = computed(() => props.builder.design);
const rows = computed(() => blueprint.value.components as RowContract[]);
const last = computed(() => _last(rows.value));
const selected = computed(() => refreshKey.value > 0 && details.value.clipboard.isDetailsSelected(blueprint.value) && design.value);

if (blueprint.value.components.length == 0)
{
    details.value.layout.addRow(blueprint.value);
}

onMounted(() =>
{
    details.value.events.subscribe(Events.FOCUS, onFocus);
});

onUnmounted(() =>
{
    details.value.events.release(Events.FOCUS, onFocus);
});

function onFocus()
{
    if (details.value.clipboard.isDetailsSelected(blueprint.value))
    {
        refreshKey.value++;
    }
}

const selectDetails = (): void =>
{
    details.value.clipboard.selectDetails(blueprint.value);
};

const addRow = (before: RowContract): void =>
{
    const row = details.value.layout.addRow(blueprint.value, before);

    details.value.clipboard.selectPlaceholder(row, null);
};

const addComponent = (definition: BlueprintDefinition, before: RowContract): void =>
{
    const row = details.value.layout.addRow(blueprint.value, before);

    details.value.layout.addComponent(row, definition);
};

const dropComponent = (component: Blueprint, before: RowContract): void =>
{
    const row = details.value.layout.addRow(blueprint.value, before);

    details.value.clipboard.cut(component);
    details.value.clipboard.paste(row);
};

watch(() => last.value?.components.length, (length: number): void =>
{
    if (length && length > 0)
    {
        details.value.layout.addRow(blueprint.value);
    }
});

watch(selected, (value: boolean): void =>
{
    if (value)
    {
        details.value.dom.offsetTop(instance);
    }
});
</script>

<template>
    <dynamic-details-component
        class="details-component"
        :class="{ design, selected }"
        :details="details"
        :blueprint="blueprint"
        @click="selectDetails"
    >
        <template v-for="(component, index) in blueprint.components" :key="component.id">
            <dynamic-details-design-ruller
                v-if="blueprint.components.length > 0 && design"
                :title="$t('[[[Wstaw kontrolkę]]]')"
                :details="details"
                :before="component"
                @insert="addRow"
                @add="addComponent"
                @drop="dropComponent"
            />
            <component
                :is="details.schema.designer(`dynamic-details-${component.type}`)"
                :details="details"
                :parent="blueprint"
                :blueprint="blueprint.components[index]"
            />
        </template>
        <portal v-if="design && selected" to="dynamic-details-toolbox">
            <dynamic-details-design-panel :details="details" :key="blueprint.name">
                <h3 class="details-name">{{ $t('[[[Szczegóły]]]') }}</h3>
                <dynamic-details-accordion>
                    <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                        <ideo-form-localize v-slot="{ locale }">
                            <field-text
                                v-model="blueprint.title[locale]"
                                :label="$t('[[[Tytuł]]]')"
                                :invalid-feedback="() => details.schema.errorMessage(blueprint, 'title')"
                            />
                        </ideo-form-localize>
                    </dynamic-details-accordion-item>
                </dynamic-details-accordion>
            </dynamic-details-design-panel>
        </portal>
    </dynamic-details-component>
</template>

<style lang="scss" scoped>
.details-component {
    &.design {
        margin-top: 24px;
        border: 2px solid var(--bs-border-color);
    }

    &.selected {
        border-color: var(--bs-success);
    }
}

.details-name {
    height: 46px;
        margin: 0;
        padding: 0 0 10px;
        font-size: 22.75px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--bs-border-color);
}
</style>
