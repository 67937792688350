export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2024-10-08T12:59:03.560Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2024-10-08T12:59:05.268Z",
    "Language": "pl-PL",
    "Messages": {
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        " - klient http z następującymi metodami:": "",
        " - powoduje wywołanie akcji jako użytkownik systemowy": "",
        " - pozwala na wyciąganie wszystkich serwisów": "",
        " - tablica ze wszystkimi akcjami procesu": "",
        " - tablica ze wszystkimi akcjami procesu (tylko dla skryptów timerowych)": "",
        " - ustawia ciało zapytania jako przekazany obiekt": "",
        " - ustawia endpointa na określony url z wybraną metodą HTTP": "",
        " - wysyła zapytanie i zwraca odpowiedź": "",
        " - zawiera dane dokumentu sprzed zapisu formularza w takim samym formacie jak document": "",
        " - zawiera pole BaseUrl z nazwą licencji w postaci, w jakiej trafia do url": "",
        " - zawiera pole PublicId oraz DbData, które jest słownikiem zawierającym wszystkie indeksowane pola dokumentu": "",
        " - zawiera pole PublicId oraz DbDataDynamic, które jest słownikiem zawierającym wszystkie indeksowane pola dokumentu": "",
        " - zawiera pole value z id aktualnego procesu": "",
        " z ": "",
        ".": "",
        "...": "",
        "' + file.filename + '": "",
        "' + func.name + '": "",
        "(Przekroczyłeś limit aktywnych kont użytkowników, skontaktuj się z dostawcą systemu w celu zmiany warunków licencji)": "",
        "%0": "",
        "%0 z %1": "",
        "%0 znaków": "",
        "+3 dni": "",
        "<Brak nazwy>": "",
        "${copied ? 'Skopiowano' : 'Kopiuj'}": "",
        "${el.text}": "",
        "${form.variants > 1 ? 'Generuj obrazy' : 'Generuj obraz'}": "",
        "${getCurrentScope().label}": "",
        "${item.entityName}": "",
        "${item.text}": "",
        "${item.value}": "",
        "${loading ? 'Ładowanie elementów...' : 'Nie znaleziono żadnych elementów.'}": "",
        "${props.inputPlaceholder}": "",
        "${str}": "",
        "${text}": "",
        "${tokenVisibility ? 'Ukryj' : 'Pokaż'} token": "",
        "1 godzina": "",
        "1:1": "",
        "10 minut": "",
        "12 godzin": "",
        "15 minut": "",
        "2 dni": "",
        "2 miesiące": "",
        "2 tygodnie": "",
        "24 godziny": "",
        "3 dni": "",
        "3 godziny": "",
        "3 miesiące": "",
        "30 minut": "",
        "4 dni": "",
        "4:7": "",
        "5 dni": "",
        "5 minut": "",
        "6 dni": "",
        "6 godzin": "",
        "7:4": "",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "",
        "Aby dodawać linie do szablonu musisz najpierw zapisać formularz.": "",
        "Aby edytować tylko bieżącą wersję roboczą utwórz nową wersję procesu": "",
        "Aby móc przetestować poprawność kluczy, najpierw zapisz formularz.": "",
        "Aby podpisać dokument zaloguj się do usługi mSzafir klikając przycisk poniżej.": "",
        "Aby podpisać dokument zaloguj się do usługi SimplySign klikając przycisk poniżej.": "",
        "Aby sprawdzić strukturę danych, które powinny zostać przekazane do importu, pobierz przykładowy plik przyciskiem \"Pobierz szablon\"": "",
        "Aby zawężyć wybór terminów dostępnych na liście możesz wyfiltrować wpisy na podstawie pól formularza zdefiniowanych w definicji słownika.": "",
        "Aby zobaczyć aktualny podgląd szablonu musisz go zapisać": "",
        "Adaptive sampling": "",
        "Administracja": "",
        "Administracja - Kraje": "",
        "Administracja - Role": "",
        "Administracja - Stanowiska pracy": "",
        "Administracja - Użytkownicy": "",
        "Administracja - Zespoły": "",
        "Administratorzy": "",
        "Adres": "",
        "Adres do eDoręczeń: ": "",
        "Adres domenowy": "",
        "Adres e-mail": "",
        "Adres e-mail potwierdzony": "",
        "Adres email": "",
        "Adres email nadawcy": "",
        "Adres IP": "",
        "Adres IP: %0": "",
        "Adres korespądencyjny: ": "",
        "Adres serwera": "",
        "Adres siedziby: ": "",
        "Adres URL": "",
        "Akcent": "",
        "Akceptacja": "",
        "Akceptacja dokumentów": "",
        "Akceptacja dokumentu": "",
        "Akceptacja własnych dokumentów": "",
        "Akceptacja wszystkich dokumentów": "",
        "Akceptowany format danych": "",
        "Akceptuj": "",
        "Akceptujący": "",
        "Akceptujący 1 powyżej limitu": "",
        "Akceptujący 2 powyżej limitu": "",
        "Akcja": "",
        "Akcja systemowa": "",
        "Akcja wykonana pomyślnie": "",
        "Akcje": "",
        "Akcje zostały zapisane": "",
        "Aktualizacja: {0}": "",
        "Aktualizuj": "",
        "Aktualna sesja": "",
        "Aktualne hasło": "",
        "Aktualne urządzenie": "",
        "Aktywna": "",
        "Aktywna do": "",
        "Aktywne": "",
        "Aktywne kanały komunikacji": "",
        "Aktywne sesje": "",
        "Aktywne źródła": "",
        "Aktywni": "",
        "Aktywność": "",
        "Aktywny": "",
        "Aktywny filtr:": "",
        "Aktywnych kont": "",
        "Aktywuj OCR": "",
        "Aktywuj tłumaczenia": "",
        "Aktywuj zestaw inteligentnych narzędzi": "",
        "Alert": "",
        "Alias": "",
        "Alias może zawierać tylko znaki alfanumeryczne oraz znak podkreślenia": "",
        "Alias został przepięty.": "",
        "aneks": "",
        "Angielski": "",
        "Ankieta": "",
        "Anonimizacja kontrahenta rozpoczęta. W zależności od ilości dokumentów proces ten może potrwać do kilkunastu minut.": "",
        "Anonimizuj": "",
        "Anonimizuj dane osobowe": "",
        "Anonimowy": "",
        "Anuluj": "",
        "Aplikacja": "",
        "Aplikacja zostanie zrestartowana w przeciągu maksymalnie 60 sekund.": "",
        "application/json": "",
        "application/x-www-form-urlencoded": "",
        "Archiwizuj przez maksymalnie (dni)": "",
        "Asystent": "",
        "Asystent AI": "",
        "Asystent został dodany.": "",
        "Asystent został zaktualizowany.": "",
        "Asystenta AI": "",
        "Atrybut": "",
        "Atrybut 1": "",
        "Atrybut 10": "",
        "Atrybut 2": "",
        "Atrybut 3": "",
        "Atrybut 4": "",
        "Atrybut 5": "",
        "Atrybut 6": "",
        "Atrybut 7": "",
        "Atrybut 8": "",
        "Atrybut 9": "",
        "Autentykacja SimplySign": "",
        "Automatyzacja": "",
        "Autor": "",
        "Autor dokumentu": "",
        "Azure AD": "",
        "Azure AI": "",
        "Azure AI Vision": "",
        "Azure Application Insights": "",
        "Azure OpenAI": "",
        "Basic Auth": "",
        "Baza danych": "",
        "Baza danych tego klienta jest nieaktualna.": "",
        "Bazy danych": "",
        "Bez formularza": "",
        "Bezpieczeństwo": "",
        "Bezpieczne połączenie SSL": "",
        "Bezpośrednio do członków wybranej grupy (np. działu)": "",
        "Bieżący dokument": "",
        "Biuro": "",
        "Blokada konta": "",
        "Blokowanie kont": "",
        "Blokująca": "",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "",
        "Błąd importu. Zaimportowano wierszy: ": "",
        "Błąd podczas eksportowania": "",
        "Błąd podczas generowania dokumentu": "",
        "Błąd podczas oznaczania jako nieodczytany": "",
        "Błąd podczas pobierania": "",
        "Błąd podczas pobierania danych": "",
        "Błąd podczas pobierania listy": "",
        "Błąd podczas pobierania schematu": "",
        "Błąd podczas pobierania szczegółów": "",
        "Błąd podczas pobierania udostępnionych dokumentów": "",
        "Błąd podczas pobierania użytkownika": "",
        "Błąd podczas renderowania PDF": "",
        "Błąd podczas rezerwacji": "",
        "Błąd podczas wykonywania akcji": "",
        "Błąd podczas zatrzymywania udostępniania": "",
        "Błąd walidacji": "",
        "Błędne": "",
        "Błędny": "",
        "Błędy": "",
        "Błędy walidacji należy przypisać do \"entry.errors.value\" w postaci tablicy komunikatów.": "",
        "Brak": "",
        "Brak aktywnego filtru": "",
        "Brak cennika dla wybranego dostawcy.": "",
        "Brak danych": "",
        "Brak danych dla wykresu": "",
        "Brak dodanych kolumn": "",
        "Brak dostępnych akcji": "",
        "Brak działu nadrzędnego": "",
        "Brak elementów": "",
        "Brak kluczy": "",
        "Brak kolekcji": "",
        "Brak kolumn": "",
        "Brak limitu": "",
        "Brak nazwy": "",
        "Brak nowych powiadomień.": "",
        "Brak odpowiedzi": "",
        "Brak opcji": "",
        "Brak pracowników w danym dziale": "",
        "Brak przesyłki": "",
        "Brak wartości wyświetlanej": "",
        "Brak wersji": "",
        "Brak wpisów": "",
        "Brak wyboru": "",
        "Brak wymaganych danych": "",
        "Brak wyników": "",
        "Brak wyników wyszukiwania": "",
        "Brak wyników!": "",
        "Brak załączników": "",
        "Brak zdefiniowanych ról.": "",
        "Brak zdefiniowanych zespołów.": "",
        "Bramka SMS": "",
        "Budżet": "",
        "Budżet i opis dokumentu": "",
        "Cache będzie przechowywany z wykorzystaniem serwera Redis.": "",
        "Cache po stronie serwera": "",
        "Cache-Control": "",
        "Cel aktywny": "",
        "Cel został dodany.": "",
        "cel został usunięty.": "",
        "Cel został zaktualizowany.": "",
        "Cele": "",
        "Cena": "",
        "Cena w dolarach sumy tokenów wejściowych i wyjściowych.": "",
        "Cennik Azure OpenAI": "",
        "Cennik AzureAI": "",
        "Cennik OpenAI": "",
        "Centrum powiadomień": "",
        "Checkbox": "",
        "Ciemny": "",
        "Client ID": "",
        "Client Secret": "",
        "co": "",
        "Co 10 dni": "",
        "Co 10 minut": "",
        "Co 12 godzin": "",
        "Co 15 minut": "",
        "Co 2 godziny": "",
        "Co 2 minuty": "",
        "Co 2 tygodnie": "",
        "Co 20 minut": "",
        "Co 3 godziny": "",
        "co 3 miesiące": "",
        "Co 3 miesiące": "",
        "co 30 dni": "",
        "Co 30 minut": "",
        "Co 4 miesiące": "",
        "Co 5 dni": "",
        "Co 5 minut": "",
        "Co 6 godzin": "",
        "co 6 miesięcy": "",
        "Co chcesz wygenerować?": "",
        "Co drugi dzień": "",
        "Co drugi miesiąc": "",
        "Co godzinę": "",
        "Co miesiąc": "",
        "Co minutę": "",
        "Co pół roku": "",
        "co rok": "",
        "Co tydzień": "",
        "Codziennie": "",
        "Cofnięto prywatność dokumentu": "",
        "Connection String": "",
        "Coś poszło nie tak": "",
        "Cron": "",
        "Cyfry": "",
        "Czas": "",
        "Czas pomiędzy": "",
        "Czas rozpoczęcia": "",
        "Czas trwania (minuty)": "",
        "Czas trwania blokady": "",
        "Czas uruchomienia": "",
        "Czas zakończenia": "",
        "Czat z asystentem": "",
        "Czerwiec": "",
        "Częstotliwość wykonywania (Cron)": "",
        "Członkowie zespołu": "",
        "Cztery kolumny": "",
        "Czwartek": "",
        "Czy aby na pewno chesz przywrócić ustawienia domyślne?": "",
        "Czy aby na pewno chesz zrestartować aplikacje?": "",
        "Czy chcesz ograniczyć dostęp do dokumentu w zastępstwie?": "",
        "Czy chcesz usunąć ograniczenie dostępu do dokumentu w zastępstwie?": "",
        "Czy na pewno chcesz dezaktywować spółkę?": "",
        "Czy na pewno chcesz rozgrupować te pola?": "",
        "Czy na pewno chcesz usunąć szablon?": "",
        "Czy na pewno chcesz usunąć tego kontrahenta?": "",
        "Czy na pewno chcesz usunąć ten dokument?": "",
        "Czy na pewno chcesz usunąć ten obiekt?": "",
        "Czy na pewno chcesz usunąć ten rekord?": "",
        "Czy na pewno chcesz usunąć ten widget?": "",
        "Czy na pewno chcesz usunąć to pole wspólne?": "",
        "Czy na pewno chcesz usunąć widget?": "",
        "Czy na pewno chcesz zanonimizować dane osobowe tego kontrahenta?": "",
        "Czy napewno chcesz usunąć tą zmienną środowiskową?": "",
        "Czy napewno chcesz usunąć ten szablon": "",
        "Czynności": "",
        "czynność": "",
        "Czynność": "",
        "Czynność zostanie wpisana w historii po wznowieniu obiegu": "",
        "Dalej": "",
        "DALL-E 3": "",
        "Dane": "",
        "Dane do zapisania": "",
        "Dane formularza są przechowywane w obiekcie Entry.": "",
        "Dane osobowe": "",
        "Dane podstawowe": "",
        "Dane tabeli przechowywane są w obiekcie table": "",
        "Dane w tabeli": "",
        "Dane wejściowe": "",
        "Dane wyjściowe": "",
        "Dane z tego pola są przechowywane w bazie w szyfrowanej formie": "",
        "danger": "",
        "dark": "",
        "Data": "",
        "Data aktywacji": "",
        "Data anonimizacji": "",
        "Data dezaktywacji": "",
        "Data do": "",
        "Data do walidacji": "",
        "Data do walidacji budżetu i dostępnych kodów opisu dokumentu": "",
        "Data dodania": "",
        "Data dokumentu": "",
        "Data edycji": "",
        "Data i czas": "",
        "Data końcowa": "",
        "Data Kursu": "",
        "Data modyfikacji": "",
        "Data od": "",
        "Data opuszczenia zespołu": "",
        "Data otrzymania": "",
        "Data powiadomienia": "",
        "Data rejestracji": "",
        "Data rozpoczęcia": "",
        "Data udostępnienia": "",
        "Data uruchomienia": "",
        "Data uruchomienia (UTC)": "",
        "Data usunięcia": "",
        "Data utworzenia": "",
        "Data utworzenia wersji": "",
        "Data utworzenia: ": "",
        "Data użycia": "",
        "Data wygaśnięcia": "",
        "Data wykonania": "",
        "Data wylogowania": "",
        "Data wysyłki": "",
        "Data wywołania": "",
        "Data zakończenia": "",
        "Data zakończenia blokady konta": "",
        "Data zalogowania": "",
        "Data zapytania": "",
        "Data zatrudnienia": "",
        "Data zatrzymania udostępniania": "",
        "Data zdarzenia": "",
        "Data zwolnienia": "",
        "Data: %0 | Status: %1": "",
        "Debug": "",
        "Debugger": "",
        "Dedykowane": "",
        "DeepL": "",
        "Definicja": "",
        "Definicja (JSON)": "",
        "Definicja formularza znajduje się w obiekcie Form.": "",
        "Definicja pól JSON": "",
        "Definicja została zaktualizowana.": "",
        "Definicję pól zapisujemy jako obiekt JSON, którego klucze to klucze pól formularza, a wartości to obiekty z właściwościami konfigurującymi dane pole.": "",
        "DELETE: Usunięcie wpisu": "",
        "Design Studio": "",
        "Dezaktywacja spółki spowoduje trwałe usunięcie powiązania z pracownikami. ": "",
        "Dezaktywuj": "",
        "Diagram": "",
        "Dla dokumentów o sygnaturach": "",
        "Dla kogo": "",
        "Dla wybranych dokumentów nie znaleziono wspólnych akcji do wykonania": "",
        "Długość czasu przechowywania": "",
        "Długość trwania sesji [min": "",
        "dni": "",
        "Dni": "",
        "do": "",
        "Do": "",
        "Do akceptacji": "",
        "do czasu ręcznego odblokowania": "",
        "Do działu": "",
        "Do grupy użytkowników": "",
        "Do kierowników działu autora dokumentu": "",
        "Do osoby wykonującej akcję oraz do osoby wybranej na formularzu": "",
        "Do osoby wykonującej bieżącą akcję": "",
        "Do przełożonego użytkownika wykonującego wskazany krok": "",
        "Do rezygnacji": "",
        "Do użytkownika": "",
        "Do użytkownika wykonującego wskazany krok": "",
        "Do użytkownika z najmniejszą ilością zadań w grupie (np. zespole)": "",
        "Do użytkownika z pola dokumentu": "",
        "Do użytkownika/grupy użytkowników wskazanej w polach dokumentu": "",
        "Do użytkownika/grupy użytkowników wskazanej w wybranym kroku na wskazanym formularzu": "",
        "Do użytkownika/grupy użytkowników/kontrahenta/maila wskazanego w polach dokumentu": "",
        "Do użytkowników wykonujących obecny krok z wyjątkiem osoby wykonującej akcję": "",
        "Do użytkowników z wybranego działu": "",
        "Do wprowadzenia": "",
        "do wszystkich zaproszonych": "",
        "Do wybranego użytkownika": "",
        "Do wybranego użytkownika i działu": "",
        "Do zespołu": "",
        "Do zespołu/działu wybranego w polu dokumentu": "",
        "doc['Nazwa pola'": "",
        "Docs": "",
        "Dodaj": "",
        "Dodaj / Edytuj": "",
        "Dodaj akcję": "",
        "Dodaj asystenta": "",
        "Dodaj atrybut": "",
        "Dodaj automatyzacje": "",
        "Dodaj cel": "",
        "Dodaj czynność": "",
        "Dodaj dni": "",
        "Dodaj do zespołu": "",
        "Dodaj dokument": "",
        "Dodaj dział": "",
        "Dodaj endpoint": "",
        "Dodaj formularz": "",
        "Dodaj funkcję": "",
        "Dodaj grupę": "",
        "Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.": "",
        "Dodaj kategorię": "",
        "Dodaj klienta": "",
        "Dodaj kod kontrolingowy": "",
        "Dodaj komentarz": "",
        "Dodaj komponent": "",
        "Dodaj kontrahenta": "",
        "Dodaj licznik": "",
        "Dodaj logo": "",
        "Dodaj moduł": "",
        "Dodaj nową linię": "",
        "Dodaj nowego kontrahenta": "",
        "Dodaj nowy folder": "",
        "Dodaj nowy termin": "",
        "Dodaj nowy wątek": "",
        "Dodaj plik": "",
        "Dodaj pliki": "",
        "Dodaj pliki asystenta": "",
        "Dodaj podsumowanie": "",
        "Dodaj powiadomienie weryfikacyjne": "",
        "Dodaj pozycję": "",
        "Dodaj przycisk z przekierowaniem do dokumentu": "",
        "Dodaj regułe": "",
        "Dodaj rolę": "",
        "Dodaj scenariusz": "",
        "Dodaj sekcję": "",
        "Dodaj serwer": "",
        "Dodaj shortcode": "",
        "Dodaj skan": "",
        "Dodaj słownik": "",
        "Dodaj słowo": "",
        "Dodaj status": "",
        "Dodaj stronę": "",
        "Dodaj synonimy": "",
        "Dodaj szablon": "",
        "Dodaj termin": "",
        "Dodaj token": "",
        "Dodaj użytkownika": "",
        "Dodaj webhooka": "",
        "Dodaj wersję": "",
        "Dodaj widget": "",
        "Dodaj wiersz": "",
        "Dodaj wpis": "",
        "Dodaj załączniki": "",
        "Dodaj zastępstwo": "",
        "Dodaj zespół": "",
        "Dodaj źródło": "",
        "Dodają kierownicy wybranego działu": "",
        "Dodaje  każda osoba która ma uprawnienie (widzi moduł)": "",
        "Dodaje członek grupy użytkowników": "",
        "Dodaje członek zespołu": "",
        "Dodaje wybrany użytkownik": "",
        "dodał": "",
        "dodane i edytowane przeze mnie": "",
        "Dodanie": "",
        "Dodanie celu": "",
        "Dodanie dokumentu": "",
        "Dodanie działu": "",
        "Dodanie endpointu": "",
        "Dodanie formularza": "",
        "Dodanie klienta": "",
        "Dodanie komponentu": "",
        "Dodanie modułu": "",
        "Dodanie roli": "",
        "Dodanie scenariusza": "",
        "Dodanie serwera": "",
        "Dodanie spółki": "",
        "Dodanie szablonu": "",
        "Dodanie użytkownika": "",
        "Dodanie webhooka": "",
        "Dodanie wpisu": "",
        "Dodanie zastępstwa": "",
        "Dodanie zespołu": "",
        "Dodanie źródła": "",
        "Dodanie źródła danych": "",
        "Dodano": "",
        "Dodano nową wersję": "",
        "dodany": "",
        "Dodanych plików %0 (limit wynosi %1).": "",
        "Dodatkowa weryfikacja": "",
        "Dodatkowe informacje": "",
        "Dodatkowe opcje": "",
        "Dodatkowo dostępny jest obiekt document zawierający dane dokumentu oraz obiekt data z polami ProcessId, ModuleId i ModuleVersionId.": "",
        "Dodawanie": "",
        "Dodawanie dokumentów": "",
        "Dodawanie i edycja własnych dokumentów": "",
        "Dodawanie modułu": "",
        "Dodawanie wartości dwóch kolumn": "",
        "Dokument": "",
        "Dokument %0": "",
        "Dokument jest niepoprawnie opisany": "",
        "Dokument już jest udostępniony": "",
        "Dokument PDF": "",
        "Dokument powiązany": "",
        "Dokument tekstowy": "",
        "Dokument został powiązany.": "",
        "Dokument został udostępniony dla wybranych użytkowników.": "",
        "Dokument został usunięty.": "",
        "Dokumentacja": "",
        "Dokumenty": "",
        "Dokumenty kontrahenta": "",
        "Dokumenty powiązane": "",
        "Dokumenty przekazane do wglądu na zasadzie powyższej nie będą dostępne z poziomu Zestawienia dokumentów i wyszukiwarki globalnej, a jedynie dostępne przez szczegóły dokumentu głównego.": "",
        "Dołącz stack trace": "",
        "Domena": "",
        "Domyślna": "",
        "Domyślna wartość": "",
        "Domyślne": "",
        "Domyślne filtry": "",
        "Domyślnie ukryte": "",
        "Domyślnie widoczne": "",
        "Domyślny": "",
        "Domyślny pager": "",
        "Dopuść brak odpowiedzi": "",
        "Dostawca": "",
        "Dostawca podpisu": "",
        "Dostęp do modułu": "",
        "Dostęp do systemu": "",
        "Dostęp do zmiennych terminu słownika (np. stawka VAT)": "",
        "Dostęp niezdefiniowany": "",
        "Dostęp przyznany": "",
        "Dostęp zabroniony": "",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "",
        "Dostępne": "",
        "Dostępne operatory": "",
        "Dostępne są propki Form i Entry zawierające odpowiednio strukturę formularza oraz dane z wypełnionego formularza": "",
        "Dostępność": "",
        "Dostępny dla modułu": "",
        "Dostępny limit": "",
        "Dotyczy tylko dokumentów z modułów do których użytkownik ma dostęp": "",
        "Dotyczy wznawiania obiegu": "",
        "dowolnej wybranej wersji": "",
        "Dowolny": "",
        "Dozwolone rozszerzenia": "",
        "Dozwolone rozszerzenia: %0.": "",
        "Dozwolone są tylko małe i duże litery": "",
        "Dozwolone są tylko małe litery i znak -": "",
        "Dozwolone typy plików: %0.": "",
        "Drugie imię": "",
        "Drugorzędny": "",
        "Dsn": "",
        "Duplikuj": "",
        "Duża": "",
        "Duży": "",
        "Duży układ siatki": "",
        "Dwie kolumny": "",
        "Dynamicznie": "",
        "Dyrektor": "",
        "Dyrektorzy": "",
        "Dyrektywa max-age=N wskazuje, że odpowiedź pozostaje świeża do N sekund po wygenerowaniu odpowiedzi.": "",
        "Dyrektywa no-store wskazuje, że jakikolwiek cache dowolnego rodzaju nie powinien przechowywać tej odpowiedzi.": "",
        "Dyrektywa PRIVATE wskazuje, że odpowiedź może być przechowywana wyłącznie w prywatnej pamięci podręcznej (np. lokalnej pamięci przeglądarki).": "",
        "Dyrektywa PUBLIC wskazuje, że odpowiedź może być przechowywana we współdzielonej pamięci podręcznej (np. proxy).": "",
        "Dyrektywa s-maxage jest ignorowana przez prywatne pamięci podręczne i zastępuje wartość określoną przez dyrektywę max-age dla współdzielonych pamięci podręcznych.": "",
        "Dyrektywa s-maxage wskazuje, jak długo odpowiedź pozostaje aktualna we współdzielonej pamięci podręcznej (np. proxy).": "",
        "Dyrektywy zakresu": "",
        "Dział": "",
        "Dział aktywny": "",
        "Dział nadrzędny": "",
        "Dział został dodany.": "",
        "Dział został zaktualizowany.": "",
        "Działania masowe": "",
        "Działy": "",
        "Dzielenie": "",
        "Dziennik korespondencji": "",
        "Dzienny": "",
        "dzień": "",
        "Dzień": "",
        "dzień (dni)": "",
        "Dzień miesiąca": "",
        "Dziś minus": "",
        "Dziś plus": "",
        "E-Doręczenia Ade": "",
        "E-mail": "",
        "Edycja": "",
        "Edycja celu": "",
        "Edycja dokumentów": "",
        "Edycja dokumentu": "",
        "Edycja działu": "",
        "Edycja endpointu": "",
        "Edycja formularza": "",
        "Edycja klienta": "",
        "Edycja kodu kontrolingowego": "",
        "Edycja komponentu": "",
        "Edycja modułu": "",
        "Edycja roli": "",
        "Edycja scenariusza": "",
        "Edycja serwera": "",
        "Edycja słownika": "",
        "Edycja szablonu": "",
        "Edycja terminu": "",
        "Edycja użytkownika": "",
        "Edycja w popupie": "",
        "Edycja webhooka": "",
        "Edycja wpisu": "",
        "Edycja wszystkich dokumentów": "",
        "Edycja zastępstwa": "",
        "Edycja zespołu": "",
        "Edycja źródła": "",
        "Edycja źródła danych": "",
        "Edytor": "",
        "Edytowana wersja": "",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "",
        "Edytuj": "",
        "Edytuj akcję": "",
        "Edytuj członka grupy": "",
        "Edytuj dashboard": "",
        "Edytuj dokument": "",
        "Edytuj filtr": "",
        "Edytuj kategorię": "",
        "Edytuj kontrahenta": "",
        "Edytuj pole": "",
        "Edytuj szablon": "",
        "Edytuj termin": "",
        "Edytuj uprawnienia": "",
        "Edytuj wersję": "",
        "Edytuj wpis": "",
        "Eksport": "",
        "Eksport do arkusza Excel": "",
        "Eksport do Excela": "",
        "Eksport dokumentów": "",
        "Eksport użytkowników": "",
        "Eksport wykonany pomyślnie": "",
        "Eksportuj": "",
        "Eksportuj do Excel": "",
        "Eksportuj wersję modułu do pliku": "",
        "Elastic": "",
        "Element sitemap": "",
        "Element został przywrócony.": "",
        "Element został usunięty.": "",
        "Email": "",
        "Email potwierdzony": "",
        "Endpoint": "",
        "Endpoint został dodany.": "",
        "Endpoint został zaktualizowany.": "",
        "Error - błedy wewnętrze SDK": "",
        "Eskportuj": "",
        "Etykieta": "",
        "Etykiety": "",
        "Export XML": "",
        "Exportuj do Excela": "",
        "Exportuj do pliku": "",
        "fakturę": "",
        "Fatal - błedy krytyczne": "",
        "Filtr dla synchronizacji działów": "",
        "Filtr dla synchronizacji użytkowników": "",
        "Filtr domyślny": "",
        "Filtr globalny": "",
        "Filtr po grupie": "",
        "Filtr prywatny": "",
        "Filtr został usunięty": "",
        "Filtr został zapisany": "",
        "Filtrowanie": "",
        "Filtrowanie listy dokumentów": "",
        "Filtruj": "",
        "Filtruj po roli": "",
        "filtry": "",
        "Filtry": "",
        "Filtry globalne": "",
        "Filtry systemowe": "",
        "Folder plików": "",
        "Foldery": "",
        "Format dokumentu": "",
        "Formatowanie": "",
        "Formularz": "",
        "Formularz bazuje na nieaktualnej wersji definicji.": "",
        "Formularz dynamiczny": "",
        "Formularz zarchiwizowany": "",
        "Formularz został dodany.": "",
        "Formularz został skopiowany.": "",
        "Formularz został zaktualizowany.": "",
        "Formularz został zapisany": "",
        "Formularze": "",
        "Formularze i pola formularzy": "",
        "Formuła jest nieprawidłowa": "",
        "Funkcja OCR nie jest włączona w konfiguracji systemu": "",
        "Funkcja została dodana.": "",
        "Funkcja została usunięta.": "",
        "Funkcja została zaktualizowana.": "",
        "Funkcje": "",
        "Funkcje Asystent": "",
        "Funkcje asystenta": "",
        "Funkcje Asystenta": "",
        "Funkcje asystenta AI": "",
        "Funkcjonalności": "",
        "Generator obrazów AI": "",
        "Generowanie obrazów": "",
        "Generowanie obrazów zakończone.": "",
        "Generuj dokument z szablonu": "",
        "Generuj metadane": "",
        "Generuj metadane (AI)": "",
        "Generuj nowy obraz": "",
        "Generuj obraz": "",
        "GET: Lista wpisów (sortowanie i stronicowanie)": "",
        "GET: Pobranie danych": "",
        "GET: Pobranie wpisu": "",
        "GET: Schemat (definicja formularza)": "",
        "Godzina rozpoczęcia": "",
        "Godzina zakończenia": "",
        "Godziny": "",
        "Godziny robocze": "",
        "Google Translate V2": "",
        "GPT-4 Vision": "",
        "Graf statusów": "",
        "Grafika": "",
        "Grudzień": "",
        "Grupa": "",
        "Grupa użytkowników": "",
        "grupę": "",
        "Grupuj po": "",
        "Grupuj po okresie": "",
        "Grupuj pola": "",
        "Grupy użytkowników": "",
        "Grupy użytkowników zostały zapisane": "",
        "Harmonogram zadań": "",
        "Harmonogram został zmieniony": "",
        "Hasło": "",
        "Hasło (Klucz ukryty)": "",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "",
        "Hasło użytkownika serwisowego": "",
        "Hasło zostało zmienione. Zaloguj się ponownie": "",
        "Health": "",
        "Hierarchia": "",
        "Historia": "",
        "Historia logowania": "",
        "Historia zadania": "",
        "Historia zapytań AI": "",
        "Host": "",
        "Host dodatkowy": "",
        "Host główny": "",
        "Id": "",
        "ID": "",
        "ID lub typ zadania": "",
        "Id modelu: %0": "",
        "Id obiektu": "",
        "Id sesji": "",
        "Id wpisu": "",
        "ID zadania": "",
        "Id zapytania": "",
        "Id zdarzenia": "",
        "Identyfikator": "",
        "Identyfikator przestrzeni (WorkspaceId)": "",
        "Identyfikator wdrożenia": "",
        "Identyfikator zadania": "",
        "Ikona": "",
        "Ikona nagłówka": "",
        "Ikonka": "",
        "Ilość cyfr w polu numer": "",
        "Ilość kont": "",
        "Ilość modułów": "",
        "Ilość wierszy": "",
        "Ilość wyników do odrzucenia": "",
        "Ilość wyników do pobrania": "",
        "Ilość znaków": "",
        "Ilość znaków odpowiedzi": "",
        "Imię": "",
        "Imię i Nazwisko": "",
        "Implementacja": "",
        "Implementacja metod/zmiennych/stałych, których chcemy używać w skrypcie": "",
        "Import": "",
        "Import danych": "",
        "Import modułu": "",
        "Import tabeli": "",
        "Import użytkowników": "",
        "Import w trakcie przetwarzania...": "",
        "Import wykonany pomyślnie": "",
        "Import zakończony pomyślnie.": "",
        "Import został dodany.": "",
        "Import został odrzucony. Nie zaimportowanego żadnego wpisu": "",
        "Importer wprowadzi do systemu wszystkie wiersze, które przejdą walidację.": "",
        "Importuj": "",
        "Importuj definicję formularza": "",
        "Importuj linie opisu z pliku": "",
        "Importuj linie z pliku": "",
        "Importuj moduł z pliku": "",
        "Importuj proces z pliku": "",
        "Importuj wersję z pliku": "",
        "Indeks": "",
        "Indeks został usunięty.": "",
        "info": "",
        "Info - informacje": "",
        "Informacja": "",
        "Informacyjna": "",
        "Inspekcja": "",
        "Instalacja": "",
        "Instrukcja": "",
        "Instrukcja modelu (prompt).": "",
        "Interpreter kodu": "",
        "Interpreter kodu umożliwia asystentowi pisanie i uruchamianie kodu. To narzędzie może przetwarzać pliki z różnymi danymi i formatowaniem oraz generować pliki.": "",
        "Istniejące firmy": "",
        "Jakość": "",
        "Jakość HD 1024x1024": "",
        "Jakość HD 1024x1792 i 1792x1024": "",
        "Jakość standardowa 1024x1024": "",
        "Jakość standardowa 1024x1792 i 1792x1024": "",
        "Jakość wygnerowanego obrazu.": "",
        "Jasny": "",
        "Jedna kolumna": "",
        "Jest autorem": "",
        "Jeśli kwota przekracza 2000": "",
        "Jeśli pole jest puste, wówczas używana jest wartość domyślna. W przeciwnym wypadku wartość domyślna jest nadpisywana.": "",
        "Jeśli użytkownik należy do grupy użytkowników modułu o id 456": "",
        "Jeśli w polu wyboru wybrano opcję 1": "",
        "Jeżeli": "",
        "Język": "",
        "Kalendarz": "",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "",
        "kanał wymagany": "",
        "Kanały": "",
        "Kanały komunikacji": "",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "",
        "Kanały włączone w ustawieniach aplikacji": "",
        "Karta": "",
        "Katalog": "",
        "Katalog główny": "",
        "Katalog został utworzony": "",
        "Katalog został utworzony.": "",
        "Kategoria": "",
        "Kategoria archiwalna w innych komórkach": "",
        "Kategoria archiwalna w komórce macierzystej": "",
        "Kategoria jest wymagana": "",
        "Kategoria została dodana.": "",
        "Kategoria została usunięta.": "",
        "Kategoria została zaktualizowana.": "",
        "Kategorie": "",
        "Kierownicy": "",
        "Kierownik": "",
        "Kierunek sortowania": "",
        "Klienci": "",
        "Klient aktywny": "",
        "Klient został dodany.": "",
        "Klient został usunięty.": "",
        "Klient został zaktualizowany.": "",
        "Kliknij aby usunąć": "",
        "Klucz": "",
        "Klucz API": "",
        "Klucz główny (PrimaryKey)": "",
        "Klucz prywatny": "",
        "Klucz publiczny": "",
        "Klucze": "",
        "Klucze są nie prawidłowe.": "",
        "Klucze są prawidłowe.": "",
        "Klucze unikalne": "",
        "Kod": "",
        "Kod musi kończyć się wyrażeniem, które zwraca wartość typu boolean": "",
        "Kod musi zwracać kończyć się wyrażeniem, które zwraca wartość wybranego typu": "",
        "Kod pocztowy": "",
        "Kod walidacji": "",
        "Kod wertfikacyjny": "",
        "Kod weryfikacyjny jest nieprawidłowy.": "",
        "Kod został skopiowany do schowka.": "",
        "Kod źródłowy": "",
        "Kody kontrolingowe": "",
        "Kody kontrollingowe": "",
        "Kolejka": "",
        "Kolejne osoby w procesie": "",
        "Kolor": "",
        "Kolor drugorzędny: %0": "",
        "Kolor etykiety": "",
        "Kolor icon na panelu z aplikacjami: %0": "",
        "Kolor ikony": "",
        "Kolor liczby": "",
        "Kolor podstawowy: %0": "",
        "Kolor przewodni obrazu": "",
        "Kolor tekstu aktywnej pozycji menu: %0": "",
        "Kolor tła panelu z aplikacjami: %0": "",
        "Kolor wartości": "",
        "Kolorowana konsola": "",
        "Kolory": "",
        "Kolory zostały przywrócone.": "",
        "Kolory zostały zapisane.": "",
        "Kolumna": "",
        "Kolumna do zsumowania": "",
        "Kolumny": "",
        "Kom": "",
        "Kom.": "",
        "Komentarz": "",
        "Komentarz został dodany.": "",
        "Komentarze": "",
        "Komponent którego wartość zostanie użyta jako nazwa elementu nadrzędnego. Dostępne tylko komponenty typu ``Tekst``.": "",
        "Komponent został dodany.": "",
        "Komponent został usunięty.": "",
        "Komponent został zaktualizowany.": "",
        "Komponent źródłowy": "",
        "Komponenty": "",
        "Komunikat": "",
        "Komunikat błedu": "",
        "Komunikat błędu": "",
        "Komunikat walidacji": "",
        "Konfiguracja": "",
        "Konfiguracja asystenta ai": "",
        "Konfiguracja dla stałych wartości, których chcemy używać w skrypcie": "",
        "Konfiguracja domyślna": "",
        "Konfiguracja harmonogramu": "",
        "Konfiguracja harmonogramu (czas UTC)": "",
        "Konfiguracja kolumn tabeli": "",
        "Konfiguracja logów": "",
        "Konfiguracja zaawansowana": "",
        "Konfiguracja zapytania": "",
        "Konfiguracja została przywrócona dla reguł i celów": "",
        "Koniec": "",
        "Koniec po": "",
        "Konta bez logowania": "",
        "Konta użytkowników": "",
        "Konta użytkowników zostały odblokowane": "",
        "Konta użytkowników zostały przypisane do zespołu": "",
        "Konta użytkowników zostały usunięte z systemu": "",
        "Konta użytkowników zostały zablokowane": "",
        "Kontakt": "",
        "Kontener plików": "",
        "Konto aktywne": "",
        "Konto bankowe": "",
        "Konto bez logowania": "",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "",
        "Kontrahenci": "",
        "Kontrahent": "",
        "Kontrahent został nadpisany.": "",
        "Kontrahent został usunięty.": "",
        "Kontrahent został zapisany.": "",
        "Kontrolka": "",
        "Kontrolka wyboru": "",
        "Kontrolki": "",
        "Kontrolki no-code": "",
        "Kontrolki podstawowe": "",
        "Kontrolki specjalizowane": "",
        "Konwertuj obrazy do formatu WebP": "",
        "Końcowa": "",
        "Kopiuj": "",
        "Kopiuj adres": "",
        "Kopiuj definicję": "",
        "Kopiuj filtr": "",
        "Kopiuj pole": "",
        "Kopiuj sygnaturę": "",
        "Kopiuj z": "",
        "Kosz": "",
        "Kraj": "",
        "Krok": "",
        "Krok minut": "",
        "Krok minut musi być liczbą z zakresu od 1 do 60.": "",
        "Krok procesu": "",
        "Kroki": "",
        "Krótki opis": "",
        "KRS: ": "",
        "Kto dodaje": "",
        "Kto może udostępniać dokumenty": "",
        "Kto może wiązać dokumenty": "",
        "Kurs": "",
        "Kursy walut": "",
        "Kwiecień": "",
        "Kwota": "",
        "Kwota brutto": "",
        "Kwota budżetu": "",
        "Kwota do walidacji tabeli opisu dokumentu": "",
        "Kwota Limitu": "",
        "Kwota netto": "",
        "Kwota opisu nie jest zgodna z kwotą faktury. Różnica: ": "",
        "LDAPS (LDAP over SSL)": "",
        "Lewy panel": "",
        "Licencja": "",
        "Licencja Logito": "",
        "Licencjobiorca": "",
        "Liczba": "",
        "Liczba całkowita": "",
        "Liczba danych wejściowych podana w tokenach.": "",
        "Liczba danych wyjściowych podana w tokenach.": "",
        "Liczba dni": "",
        "Liczba dni na wykonanie kroku": "",
        "Liczba dziesiętna": "",
        "Liczba miesięcy": "",
        "Liczba wariantów": "",
        "Liczba wyników na stronie": "",
        "Licznik zależny od": "",
        "Liczniki": "",
        "Lider": "",
        "light": "",
        "Limit": "",
        "Limit API": "",
        "Limit został dodany.": "",
        "Limit został zaktualizowany.": "",
        "Linia": "",
        "linie": "",
        "linię": "",
        "Lipiec": "",
        "lista": "",
        "Lista": "",
        "Lista dozwolonych / wykluczonych żądań HTTP": "",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "",
        "Lista elementów zostanie zawężona tylko do tych, które są pozwiązane z wybranymi wartościami z zaznaczonych komponentów.": "",
        "Lista ID": "",
        "Lista indeksów": "",
        "Lista jest pusta": "",
        "Lista kontahentów": "",
        "Lista kontrahentów": "",
        "Lista plików": "",
        "Lista powiadomień": "",
        "Lista pracowników": "",
        "Lista rozwijana": "",
        "Listopad": "",
        "Listy": "",
        "Litery": "",
        "Litery i cyfry": "",
        "Log Analytics": "",
        "Logi": "",
        "Logi wydajnościowe": "",
        "Login": "",
        "Logito": "",
        "Logo": "",
        "Logowanie": "",
        "Logowanie zakończyło się niepowodzeniem.": "",
        "Logowanie zależności / żądań typu HTTP": "",
        "Loguj wybrane": "",
        "Lokalne": "",
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit.": "",
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit.": "",
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam commodi autem id, veniam, illum temporibus.": "",
        "Lp.": "",
        "Luty": "",
        "Łączenie treści": "",
        "Ma dostęp do dokumentu": "",
        "Maj": "",
        "Maksimum": "",
        "Maksymalna ilość plików": "",
        "Maksymalna rozdzielość %0x%1px": "",
        "Maksymalnie można wprowadzić 500 znaków": "",
        "Maksymalny rozmiar pliku w MB": "",
        "Maksymalny rozmiar pliku: %0MB.": "",
        "Mała": "",
        "Małe znaki": "",
        "Mały": "",
        "Mały układ siatki": "",
        "Mappingi": "",
        "Marzec": "",
        "Metadane": "",
        "Metoda": "",
        "Metoda autentykacji": "",
        "Metoda musi zwracać obiekt \"entry.errors\".": "",
        "Metody": "",
        "Miasto": "",
        "Miejsca po przecinku": "",
        "Miejscowość": "",
        "mies.": "",
        "miesiąc": "",
        "Miesiąc": "",
        "Miesiące": "",
        "Miesięczny": "",
        "Migracje": "",
        "Miniaturki": "",
        "Minimalna długość hasła": "",
        "Minimum": "",
        "Minuty": "",
        "Mnożenie": "",
        "Model": "",
        "Model danych": "",
        "Moderacja danych wejściowych": "",
        "Moduł": "",
        "Moduł dynamiczny": "",
        "Moduł statyczny": "",
        "Moduł został dodany.": "",
        "Moduł został usunięty.": "",
        "Moduł został wyeksportowany.": "",
        "Moduł został zaktualizowany.": "",
        "Moduł został zapisany.": "",
        "Moduły": "",
        "Modyfikacja": "",
        "Modyfikacja dokumentów": "",
        "Modyfikuj istniejący wątek": "",
        "Moje aplikacje": "",
        "Moje dokumenty": "",
        "Moje filtry": "",
        "Moje konto": "",
        "Moje pliki": "",
        "Monitor kolejek": "",
        "Monitoring": "",
        "Monitoruj statystyki .NET": "",
        "Monitoruj żądania HTTP": "",
        "Może zarządzać dokumentami w rejestrze.": "",
        "Może zarządzać fakturami w rejestrze. Powiązane z uprawnieniami \"Może dodawać\", \"Koordynator\", \"Księgowa\".": "",
        "Może zarządzać pismami w rejestrze. Powiązane z opcjami dodawania, przypisywania po przyjęciu, edycji.": "",
        "Może zarządzać pismami w rejestrze. Powiązane z opcją dodawania pism. Edycja dostępna jest tylko dla autora. Wysyłanie i dodawanie zwrotek.": "",
        "Może zarządzać rejestrem": "",
        "Może zarządzać reklamacjami w rejestrze. Powiązane z uprawnieniami \"Może dodawać\", \"Koordynator\", \"Specialista przepływu statusów\".": "",
        "Może zarządzać umowami w rejestrze. Powiązane z opcją dodawania umów.": "",
        "Możesz podać nową nazwę pliku.": "",
        "Możesz wybrać maksymalnie 10 elementów": "",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są synchronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość wielokrotnego wyboru": "",
        "Mój kalendarz": "",
        "Mój tytuł": "",
        "Musisz wybrać przynajmniej jedną grupę.": "",
        "Na diagramie procesu występują błędy. Sprawdź zakładkę \"Błędy\".": "",
        "Na pewno usunąć wybrane elementy?": "",
        "Na widoku nie będą dostępne akcje dokumentów łącznie z linkiem do szczegółów": "",
        "Nadaj dostęp do dokumentów powiązanych osobom, które mają dostęp do dokumentu głównego": "",
        "Nadawanie uprawnień do dokumentów": "",
        "Nadawanie uprawnień do własnych dokumentów": "",
        "Nadawanie uprawnień do wszystkich dokumentów": "",
        "Nadawca": "",
        "Nadpisz": "",
        "Nagłówek": "",
        "Nagłówek dokumentu": "",
        "Najstarsza wiadomość": "",
        "Narzędzia": "",
        "Narzędzie integruje się z platformą SMSAPI.pl": "",
        "Następna strona": "",
        "Nazwa": "",
        "Nazwa (wymagane)": "",
        "Nazwa aliasu": "",
        "Nazwa asystenta": "",
        "Nazwa bazy danych": "",
        "Nazwa działu": "",
        "Nazwa filtra": "",
        "Nazwa formularza": "",
        "Nazwa funkcji": "",
        "Nazwa indeksu": "",
        "Nazwa jest wymagana": "",
        "Nazwa katalogu": "",
        "Nazwa katalogu została zmieniona.": "",
        "Nazwa klienta": "",
        "Nazwa kolumny": "",
        "Nazwa kolumny jest wymagana.": "",
        "Nazwa kolumny z opisem": "",
        "Nazwa kolumny z wartością": "",
        "Nazwa komponentu": "",
        "Nazwa konta (Klucz dostępu)": "",
        "Nazwa kontrahenta": "",
        "Nazwa modelu": "",
        "Nazwa modułu": "",
        "Nazwa musi mieć co najmniej 3 znaki": "",
        "Nazwa musi mieć co najmniej 5 znaków": "",
        "Nazwa na grafie": "",
        "Nazwa nadawcy": "",
        "Nazwa narzędzia": "",
        "Nazwa nie może być dłuższa niż 30 znaków": "",
        "Nazwa nie może być dłuższy niż 50 znaków": "",
        "Nazwa pliku": "",
        "Nazwa pliku została zmieniona.": "",
        "Nazwa pola": "",
        "Nazwa pola na formularzu": "",
        "Nazwa powiadomienia": "",
        "Nazwa przycisku": "",
        "Nazwa przycisku ``Dalej``": "",
        "Nazwa przycisku ``Wstecz``": "",
        "Nazwa przycisku ``Wyślij``": "",
        "Nazwa roli": "",
        "Nazwa sekcji w menu": "",
        "Nazwa sekcji...": "",
        "Nazwa serii": "",
        "Nazwa serwera": "",
        "Nazwa skrócona": "",
        "Nazwa spółki": "",
        "Nazwa szablonu": "",
        "Nazwa typu": "",
        "Nazwa urządzenia": "",
        "Nazwa użytkownika": "",
        "Nazwa użytkownika serwisowego": "",
        "Nazwa w indeksie": "",
        "Nazwa wątku": "",
        "Nazwa webhooka": "",
        "Nazwa wersji": "",
        "Nazwa zadania": "",
        "Nazwa zakładki": "",
        "Nazwa zasobu": "",
        "Nazwa zespołu": "",
        "Nazwa źródła": "",
        "Nazwa źródła danych": "",
        "Nazwisko": "",
        "Nazwy wartości": "",
        "Nicość - nie zapisuj": "",
        "Nie": "",
        "Nie loguj wybranych": "",
        "Nie masz uprawnień do panelu administracyjnego.": "",
        "Nie masz uprawnień do tego dokumentu": "",
        "Nie masz uprawnień do tego widoku. Stan widoku mógł ulec zmianie, odśwież i sprawdź lub spróbuj ponownie później.": "",
        "Nie możesz dodać tego samego widgetu dwa razy": "",
        "Nie możesz modyfikować profilu zastępowanego użytkownika.": "",
        "Nie możesz modyfikować uprawnień": "",
        "Nie można usunąć swojego konta.": "",
        "Nie odnaleziono kontrahenta o podanym numerze NIP w bazie GUS.": "",
        "Nie podano ID procesu, którego dotyczy formularz": "",
        "Nie podano wymaganej ilości znaków: %0.": "",
        "Nie udało się pobrać akcji": "",
        "Nie udało się pobrać historii": "",
        "Nie udało się pobrać listy celów": "",
        "Nie udało się pobrać listy działów": "",
        "Nie udało się pobrać listy filtrów": "",
        "Nie udało się pobrać listy kanałów": "",
        "Nie udało się pobrać listy kolejek": "",
        "Nie udało się pobrać listy powiadomień": "",
        "Nie udało się pobrać listy ról": "",
        "Nie udało się pobrać listy serwerów baz danych": "",
        "Nie udało się pobrać listy serwerów plików": "",
        "Nie udało się pobrać listy spółek": "",
        "Nie udało się pobrać listy typów danych.": "",
        "Nie udało się pobrać listy typów modeli": "",
        "Nie udało się pobrać listy wpisów.": "",
        "Nie udało się pobrać listy wydziałów": "",
        "Nie udało się pobrać listy zespołów": "",
        "Nie udało się pobrać obrazu.": "",
        "Nie udało się pobrać powiadomień.": "",
        "Nie udało się pobrać szczegółów": "",
        "Nie udało się pobrać url do podpisu dokumentu": "",
        "Nie udało się pobrać ustawień kont użytkowników": "",
        "Nie udało się pobrać widgetu/ów": "",
        "Nie udało się usunąć szablonu": "",
        "Nie udało się utworzyć nowej wersji": "",
        "nie udało się wykonać akcji": "",
        "Nie udało się wykonać akcji": "",
        "Nie udało się wysłać pliku": "",
        "Nie udało się załadować widgetu AI.": "",
        "Nie udało się zapisać filtra": "",
        "Nie udało się zapisać formularza": "",
        "Nie udało się zapisać szablonu": "",
        "Nie wszystkie pola zostały wypelnione prawidłowo.": "",
        "Nie wszystkie pola zostały wypełnione prawidłowo.": "",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "",
        "Nie wszystkie wymagane pola zostały wypełnione": "",
        "Nie wybrano żadnego użytkownika.": "",
        "Nie wybrano żadnych filtrów": "",
        "Nie wymaga uwierzytelniania": "",
        "nie wymuszaj zmiany hasła": "",
        "Nie zdefiniowano żadnych ról.": "",
        "Nie zdefiniowano żadnych spółek.": "",
        "Nie znaleziono dostępnych widgetów": "",
        "Nie znaleziono filtrów.": "",
        "Nie znaleziono formularza.": "",
        "Nie znaleziono kolumn.": "",
        "Nie znaleziono pasującego widgetu": "",
        "Nie znaleziono pasujących elementów.": "",
        "Nie znaleziono żadnego pola.": "",
        "Nie znaleziono żadnych akcji": "",
        "Nie znaleziono żadnych plików": "",
        "Nie znaleziono żadnych użytkowników.": "",
        "Nie znaleziono żadnych wyników.": "",
        "Nieaktywne": "",
        "Nieaktywni": "",
        "Nieaktywny": "",
        "niedozwolone rozszerzenie pliku (%0)": "",
        "niedozwolony typ pliku (%0)": "",
        "Niedziela": "",
        "Nieobsłużony status importu.": "",
        "Nieopublikowany": "",
        "Niepoprawna nazwa kolumny.": "",
        "Niepoprawne wyrażenie regularne": "",
        "Niepotwierdzeni": "",
        "Nieprawidłowa definicja": "",
        "Nieprawidłowy adres email": "",
        "Nieprawidłowy adres URL": "",
        "Nieprawidłowy format dokumentu. Aby podpisać dokument przekonwertuj go na format PDF": "",
        "Nieprzeczytane": "",
        "Niestandardowy błąd": "",
        "Nieudane": "",
        "Nieudane próby logowania": "",
        "Niewidoczne": "",
        "Niezgodne sumy kwot wg stawek vat z tabelką VAT": "",
        "Nieznane narzędzie": "",
        "Nieznany model": "",
        "Nieznany typ pliku": "",
        "Nigdy": "",
        "Nikomu nie przydzielono uprawnień": "",
        "NIP": "",
        "NIP: ": "",
        "no-store (wyłącza cache)": "",
        "Nowa data": "",
        "Nowe hasło": "",
        "np. (&(objectClass=organizationalUnit))": "",
        "np. (&(objectClass=user)": "",
        "Nr budynku": "",
        "Nr konta bankowego": "",
        "Nr lokalu": "",
        "Nr telefonu": "",
        "Numer budynku": "",
        "Numer dokumentu będzie uzupełniany zerami wiodącymi": "",
        "Numer domu": "",
        "Numer kolejny": "",
        "Numer KRS": "",
        "Numer lokalu": "",
        "Numer mieszkania": "",
        "Numer pokoju": "",
        "Numer REGON": "",
        "Numer sesji": "",
        "Numer telefonu": "",
        "Numeruj używając licznika wg daty": "",
        "OAuth2 dla Exchange Online": "",
        "Obecnie edytowana wersja procesu jest używana w wersjach modułu": "",
        "Obiekt": "",
        "Obiekt %0 został %1": "",
        "Obiekt ma strukturę Dictionary<string, object>[] z C#": "",
        "Obliczenie godzin między datami z pierwszego i ostatniego wiersza": "",
        "Obok siebie": "",
        "Obraz został pomyślnie opisany": "",
        "Obraz został wycięty.": "",
        "Obraz został wygenerowany.": "",
        "Obsługiwane formaty plików: txt, md, pdf, docx.": "",
        "OCR": "",
        "Oczekuje na akceptacje": "",
        "Oczekuje na wysłanie": "",
        "od": "",
        "Od": "",
        "od %0": "",
        "Odbierz uprawnienie": "",
        "Odbiorca": "",
        "Odblokuj": "",
        "Odejmowanie": "",
        "Odpowiedzi": "",
        "Odpowiedzi: %0 na %1": "",
        "Odpowiedź": "",
        "Odpowiedź dowolna": "",
        "Odrzucone": "",
        "Odrzucony": "",
        "Odrzuć": "",
        "Odstęp": "",
        "Odśwież": "",
        "Odtwórz indeks": "",
        "Odtwórz wszystkie indeksy": "",
        "Odwołanie do klucza pierwszego wybranego terminu słownika w polu Słownik Logito": "",
        "Odwołanie do pól terminu słownika (np. stawka VAT)": "",
        "Odwołanie do wartości pola numerycznego": "",
        "Odznacz": "",
        "Odznacz wszystkie": "",
        "Ogólne": "",
        "Ok": "",
        "Omnitool": "",
        "OneDrive": "",
        "Opcje": "",
        "OpenAI": "",
        "Operacje": "",
        "Opiekun": "",
        "Opis": "",
        "Opis błędu": "",
        "Opis dokumentu": "",
        "Opis faktury": "",
        "Opis funkcji": "",
        "Opis kodami kontrolingowymi": "",
        "Opis kompletny": "",
        "Opis obrazu": "",
        "Opis pola (drobny tekst pod polem na formularzu)": "",
        "Opis zespołu": "",
        "Opis źródła": "",
        "Opisy": "",
        "Optymalizuj rozdzielczość obrazów": "",
        "Opublikowano wersję": "",
        "Opublikowany": "",
        "Oraz hasło": "",
        "Oraz nowe hasło": "",
        "Organizacja": "",
        "Organizacja została usunięta.": "",
        "Orientacja wydruku": "",
        "Osoba": "",
        "Osoba zastępowana": "",
        "Osoba zastępująca": "",
        "Osoby które mają dostęp do dokumentu": "",
        "Osoby które realizują obecnie zadanie w obiegu dokumentu": "",
        "Osoby zaproszone": "",
        "Ostatnia aktywność": "",
        "Ostatnia strona": "",
        "ostatnie sprawdzenie": "",
        "Ostrzeżenia": "",
        "Ostrzeżenie": "",
        "Otwórz": "",
        "Otwórz licznik na kolejny rok": "",
        "Otwórz wyszukiwarkę": "",
        "Oznacz": "",
        "Oznacz jako nieodczytany": "",
        "Oznacz jako nieprzeczytane": "",
        "Oznacz jako przeczytane": "",
        "Oznacz powiadomienia": "",
        "Oznacz wszystkie jako przeczytane": "",
        "Oznacz wybrane jako nieprzeczytane": "",
        "Oznacz wybrane jako przeczytane": "",
        "Paleta kolorów": "",
        "Parametry funkcji": "",
        "Parametry, wymagane do wykonania zapytania:": "",
        "Pasek": "",
        "Pasek postępu": "",
        "Październik": "",
        "Pełne dane": "",
        "Pełny": "",
        "PESEL": "",
        "PESEL: ": "",
        "PH": "",
        "Piątek": "",
        "Pierwiastkowanie wartości kolumny": "",
        "Pierwsza strona": "",
        "Pigułki": "",
        "Piktogram": "",
        "pismo przychodzące": "",
        "Plik audio": "",
        "Plik binarny": "",
        "Plik CSS": "",
        "Plik CSV": "",
        "Plik DOC": "",
        "Plik DOCX": "",
        "Plik GIF": "",
        "Plik HTML": "",
        "Plik importowy": "",
        "Plik jest wysyłany": "",
        "Plik JPEG": "",
        "Plik JPG": "",
        "Plik MP4": "",
        "Plik PNG": "",
        "Plik skompresowany": "",
        "Plik tekstowy json": "",
        "Plik tekstowy txt": "",
        "Plik zawiera niepoprawne dane w komórkach": "",
        "Plik ZIP": "",
        "Plik został dodany.": "",
        "Plik został usunięty.": "",
        "Plik został wysłany": "",
        "Pliki": "",
        "Pliki Asystent": "",
        "Pliki Asystenta": "",
        "Pliki asystenta AI": "",
        "Pliki do wysłania: %0": "",
        "Pliki prywatne": "",
        "Pliki publiczne": "",
        "Pliki współdzielone": "",
        "Pliki: %0": "",
        "po %0 nieudanych próbach logowania": "",
        "Po jednym synonimie w wierszu": "",
        "Po wybraniu tej opcji konta użytkowników zostaną usunięte z systemu. Czy chcesz kontynuować?": "",
        "Po zakończonym imporcie wyświetli się podsumowanie o ilości zaimportowanych/odrzuconych rekordów oraz lista błędów dla wierszy odrzuconych": "",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "",
        "Pobierz": "",
        "Pobierz dane z GUS": "",
        "Pobierz szablon": "",
        "Początkowa ilość wierszy": "",
        "Poczta": "",
        "Podaj NIP": "",
        "Podaj nową nazwę": "",
        "Podaj swój adres email": "",
        "Podana nazwa jest zajęta.": "",
        "Podana wartość jest nieprawidłowa.": "",
        "Podanie nazwy jest wymagane.": "",
        "Podano adres": "",
        "Podano nieprawidłowe dane": "",
        "Podano nieprawidłowe dane.": "",
        "Podczas pierszego uruchomienia wymagane jest utworzenie konta głównego administratora.": "",
        "Podgląd": "",
        "Podgląd dokumentu": "",
        "Podgląd formularza": "",
        "Podgląd jest dostępny jedynie po zapisaniu szablonu": "",
        "Podgląd przycisku": "",
        "Podgląd tabeli": "",
        "Podkreślenie": "",
        "Podpis": "",
        "Podpisz dokument": "",
        "Podstawowe": "",
        "Podstawowe operacje na datach": "",
        "Podstawowe operacje na liczbach": "",
        "Podstawowe operacje tekstowe": "",
        "Podstawowe operacje warunkowe": "",
        "Podstawowy": "",
        "Podwładni": "",
        "Podziedziałek - Piątek": "",
        "Podziel widok terminarza wg": "",
        "Pogrubienie etykiety": "",
        "Pogrubienie wartości": "",
        "Pojedynczy wybór": "",
        "Pokaż": "",
        "Pokaż etykietę": "",
        "Pokaż etykiety bez wartości": "",
        "Pokaż filtry": "",
        "Pokaż filtry na liście": "",
        "Pokaż fukncję dowiązania dokumentów wg uprawnień": "",
        "Pokaż godziny robocze": "",
        "Pokaż historię": "",
        "Pokaż kolumnę z liczbą porządkową": "",
        "Pokaż numery stron": "",
        "Pokaż pager": "",
        "Pokaż pełne godziny": "",
        "Pokaż przycisk ``Wyślij``": "",
        "Pokaż przycisk dla akcji": "",
        "Pokaż przycisk dodawania": "",
        "Pokaż przycisk operacji grupowych": "",
        "Pokaż tylko moje dokumenty": "",
        "Pokaż tytuł formularza": "",
        "Pokaż tytuł sekcji": "",
        "Pokaż tytuł strony": "",
        "Pokaż tytuły stron": "",
        "Pokaż w koncie użytkownika": "",
        "Pokaż wartości na wykresie": "",
        "Pokaż więcej": "",
        "Pokaż więcej komentarzy": "",
        "pokaż więcej...": "",
        "Pokaż załączniki dokumentów powiązanych": "",
        "Pokaż załączniki dokumentu": "",
        "Pokój": "",
        "Pola": "",
        "Pola do zgrupowania": "",
        "Pola dokumentu": "",
        "Pola mapowane": "",
        "Pola mapowane (excel)": "",
        "Pola należące do grupy": "",
        "Pola wspólne": "",
        "Pola wyliczane": "",
        "Pola zostały zgrupowane.": "",
        "Pole": "",
        "Pole \"%0\" jest wymagane.": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "",
        "Pole \"%0\" zawiera niedozwoloną wartość.": "",
        "Pole \"Drugie imię\" jest wymagane.": "",
        "Pole \"Imię\" jest wymagane.": "",
        "Pole \"Nazwisko\" jest wymagane.": "",
        "Pole \"Tytuł\" jest wymagane.": "",
        "Pole do filtrowania przy imporcie tabeli": "",
        "Pole formularza": "",
        "Pole jest wymagane": "",
        "Pole może zawierać jedynie cyfry.": "",
        "Pole może zawierać jedynie litery i cyfry.": "",
        "Pole może zawierać jedynie litery.": "",
        "Pole może zawierać tylko ciało metody walidującej dane w postaci natywnego kodu JavaScript.": "",
        "Pole nie może zawierać spacji": "",
        "Pole powiązania dokumentu": "",
        "Pole priorytetowe": "",
        "Pole skopiowane": "",
        "Pole tekstowe": "",
        "Pole typu \"%0\" jest niedozwolone w tym formularzu.": "",
        "Pole wspólne zostało dodane.": "",
        "Pole wspólne zostało usunięte": "",
        "Pole wspólne zostało zaktualizowane.": "",
        "Pole wymagane": "",
        "Pole z datą": "",
        "Pole z datą wg której liczymy kurs": "",
        "Pole z tabelą VAT": "",
        "Pole zawiera": "",
        "Pole zostało rozgrupowane.": "",
        "Pole zostało zaktualizowane.": "",
        "Polski": "",
        "Pomiędzy": "",
        "Pomijanie kolejkowania": "",
        "Pomiń pierwszy wiersz excela": "",
        "Pomiń wysłanie powiadomienia w przypadku braku dokumentów": "",
        "Pomoc": "",
        "Pomyślnie przywrócono konfigurację domyślną": "",
        "Pomyślnie uruchomiono proces reindeksacji": "",
        "Pomyślnie usunięto rekord": "",
        "Pomyślnie usunięto wersje": "",
        "Pomyślnie zapisano konfigurację filtrów": "",
        "Pomyślnie zapisano konfigurację kolumn": "",
        "Poniedziałek": "",
        "Poprawne": "",
        "Poprawny": "",
        "Poprzednia strona": "",
        "Porównaj %0": "",
        "Porównaj z edytowaną wersją": "",
        "Porównania": "",
        "Porównanie": "",
        "Porównywana wersja": "",
        "Port": "",
        "Posiada załączniki": "",
        "POST: Lista wpisów (dowolne wyszukiwanie)": "",
        "POST: Upload załączników": "",
        "POST: Utworzenie wpisu": "",
        "POST: Zapisanie zmian": "",
        "Potwierdzenia": "",
        "Potwierdzenie przywrócenia": "",
        "Potwierdzenie usunięcia": "",
        "Potwierdzenie wylogowania użytkownika": "",
        "Potwierdzenie zmiany statusu": "",
        "Potwierdź": "",
        "Potwierdź akceptację": "",
        "Powiadomienia": "",
        "Powiadomienia weryfikacyjne": "",
        "Powiadomienia zbiorcze": "",
        "Powiadomienia zostały skonfigurowane.": "",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "",
        "Powiadomienie testowe zostało wysłane.": "",
        "Powiadomienie weryfikacyjne": "",
        "Powiadomienie zostało usunięte": "",
        "Powiąż": "",
        "Powiąż dokument": "",
        "Powiąż z polem typu grupa użytkowników": "",
        "Powiąż ze spółką": "",
        "Powiększ": "",
        "Powinno się dodać dyrektywę PRIVATE dla treści personalizowanych dla użytkownika, zwłaszcza dla odpowiedzi otrzymywanych po zalogowaniu i z uwzględnieniem uprawnień.": "",
        "powrót": "",
        "Powrót": "",
        "Powtarzaj co": "",
        "Powtórz hasło": "",
        "Powtórzone": "",
        "Powyższe obiekty zawierają tylko właściwości i są pozbawione wszystkich metod.": "",
        "Poziom dostępu do webhooka": "",
        "Poziom dostępu użytkownika": "",
        "Poziom logowania błedów": "",
        "Poziomy logowania": "",
        "Pozostałe": "",
        "Pozostało %0 znaków.": "",
        "Pozwalaj na grupowe wykonanie akcji": "",
        "Pozwalaj wiązać tylko niezakończone dokumenty": "",
        "Pozwól dodawać terminy użytkownikom": "",
        "Pozwól na dodawanie wierszy": "",
        "Pozwól na uzupełnianie danych z pliku excel": "",
        "Pozycja": "",
        "pół roku": "",
        "Pracownicy": "",
        "Pracownik": "",
        "Prawy panel": "",
        "Predefiniowane tła panelu z aplikacjami": "",
        "Predefiniowane zestawy kolorów": "",
        "primary": "",
        "Priorytet": "",
        "private (tylko cache przeglądarki)": "",
        "Procent": "",
        "Proces": "",
        "Proces i elementy podstawowe (statusy, czynności, akcje, grupy użytkowników)": "",
        "Proces został dodany.": "",
        "Proces został edytowany.": "",
        "Proces został zapisany.": "",
        "Profil użytkownika": "",
        "Profil został zaktualizowany.": "",
        "Projekt": "",
        "Prometheus": "",
        "Propka": "",
        "Propki": "",
        "Proporcje obrazu": "",
        "Proste uprawnienia": "",
        "Próbkowanie": "",
        "Prywatne": "",
        "Przebudowanie indeksów": "",
        "Przebudowanie indeksównnOperacja może trwać od kilku sekund do kilku godzin w zależności od rozmiaru indeksów.nnNależy używać tylko w przypadku konieczności zaaplikowania zmian dokonanych w słownikach synonimów lub słów wykluczonych.": "",
        "Przeciągnij i upuść aby posortować pliki": "",
        "Przeczytane": "",
        "przed": "",
        "Przedrostek": "",
        "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "",
        "Przeindeksowanie danych": "",
        "Przeindeksowanie danychnnOperacja może trwać od kilku sekund do kilku godzin w zależności od ilości danych w bazie.nnNależy używać tylko w przypadku zauważenia braku danych w indeksach.": "",
        "Przejdź do konfiguracji, aby włączyć narzędzia": "",
        "Przekaż do właściciela": "",
        "Przekierowanie": "",
        "Przekieruj do dokumentu": "",
        "Przekieruj po kliknięciu w widget": "",
        "Przekieruj przycisk więcej do": "",
        "Przekroczono dozwoloną ilość znaków: %0.": "",
        "przekroczony dozwolony rozmiar pliku (%0 MB)": "",
        "Przełącznik": "",
        "Przełożeni": "",
        "Przełożony": "",
        "Przeniesienie wybranych elementów": "",
        "przeniesiony do kosza": "",
        "Przenieś": "",
        "Przenieś${selected.length > 1 ? ' zaznaczone' : ''}": "",
        "przeniósł do kosza": "",
        "Przepisz aktualne stawki za 1000 tokenów z cennika OpenAI.": "",
        "Przepisz limit konta OpenAI.": "",
        "Przepnij alias": "",
        "Przesuń do góry": "",
        "Przesuń na dół": "",
        "Przesyłanie plików powiodło się.": "",
        "Przetestuj": "",
        "Przetestuj poprawność zapisanych kluczy": "",
        "Przetestuj ustawienia poczty": "",
        "Przetestuj ustawienia Sms": "",
        "Przetłumacz moduł": "",
        "Przetłumacz słownik": "",
        "Przetwarzane": "",
        "Przybornik": "",
        "Przykład": "",
        "Przykład listy elementów pola typu tablica:": "",
        "Przykład tabelki z powtarzanymi elementami:": "",
        "Przykład użycia historii w formie tabeli": "",
        "Przykład:": "",
        "Przykład: domena\\użytkownik": "",
        "Przykład: użytkownik@domena": "",
        "Przykładowa struktura danych": "",
        "Przykładowy JSON:": "",
        "Przykładowy skrypt dla słownika z polami unit i type": "",
        "Przykładowy skrypt sprawdzający, czy upłynął termin dokumentu w polu 1_Date1": "",
        "Przykładowy skrypt wywołujący akcję systemową o nazwie \"End\"": "",
        "Przykładowy skrypt:": "",
        "Przykłady": "",
        "Przykłady konfiguracji": "",
        "Przykłady odwołania się do pierwszego elementu w tablicy:": "",
        "Przypisanie do zespołu": "",
        "Przypisanie ról": "",
        "Przypomnienie": "",
        "Przypomnienie hasła": "",
        "Przyrostek": "",
        "Przywrócenie": "",
        "przywrócił z kosza": "",
        "przywrócony z kosza": "",
        "Przywróć": "",
        "Przywróć domyślną listę kolumn": "",
        "Przywróć domyślne": "",
        "Przywróć filtr": "",
        "Przywróć ustawienia domyślne reguł i celów": "",
        "Przyznaj dostęp": "",
        "public (cache przeglądarki i proxy)": "",
        "Publiczne": "",
        "Publikuj": "",
        "PUT: Aktualizacja wpisu": "",
        "Pytania": "",
        "Q1": "",
        "Q2": "",
        "Q3": "",
        "Q4": "",
        "Query string": "",
        "Radio": "",
        "Ranga": "",
        "Raporty": "",
        "Realizuje obecnie zadanie w obiegu dokumentu": "",
        "ReCaptcha": "",
        "Regex walidujący poprawność wartości w polu (tylko dla typu String)": "",
        "Region": "",
        "Regon": "",
        "REGON": "",
        "REGON: ": "",
        "Reguła": "",
        "Reguła filtracyjna": "",
        "Reguła końcowa": "",
        "Reguła został dodana.": "",
        "reguła została usunięta.": "",
        "Reguła została zaktualizowana.": "",
        "Reguły": "",
        "Rejestr": "",
        "Rejestr zmian": "",
        "Rejestr został dodany.": "",
        "Rejestr zostały zapisany.": "",
        "Rejestry": "",
        "reklamacje": "",
        "Relacyjne": "",
        "Repozytorium plików": "",
        "Request body": "",
        "Resetuj": "",
        "Resetuj układ": "",
        "RESTful API": "",
        "Rezygnacja": "",
        "Roczny": "",
        "Rodzaj akcji": "",
        "Rodzaj odpowiedzi": "",
        "Rodzaj źródła": "",
        "rok": "",
        "Rok": "",
        "Rola": "",
        "Rola aktywna": "",
        "Rola domyślna": "",
        "Rola została dodana.": "",
        "Rola została usunięta.": "",
        "Rola została zaktualizowana.": "",
        "Role i uprawnienia": "",
        "Role użytkownika": "",
        "Role zespołu": "",
        "Role zostały przypisane": "",
        "Root": "",
        "Roszerzenia plików": "",
        "Roszerzenia plików rozdzielone przecinkami": "",
        "Rozdzielczość": "",
        "Rozdzielczość wygnerowanego obrazu.": "",
        "Rozgrupuj": "",
        "Rozgrupuj pola": "",
        "Rozmiar": "",
        "Rozmiar okna modalnego": "",
        "Rozmiar okna popup": "",
        "Rozmiar pliku": "",
        "Rozmiar wydruku": "",
        "Rozmiar zdjęcia został zmieniony.": "",
        "Rozmieść równomiernie": "",
        "Rozpoczęto tłumaczenie modułu. Może to potrwać do kilku minut.": "",
        "Rozpoznaj tekst wprowadzonego dokumentu (OCR) dla wskazanych pól modułu": "",
        "Rozszerzony - błedy ważne": "",
        "Równość": "",
        "Satysfakcja": "",
        "secondary": "",
        "Sekcja": "",
        "Sentry": "",
        "Serie danych": "",
        "Server Side Cache": "",
        "Serwer bazy danych": "",
        "Serwer dostępny": "",
        "Serwer plików": "",
        "Serwer został dodany.": "",
        "Serwer został usunięty.": "",
        "Serwer został zaktualizowany.": "",
        "Serwery plików": "",
        "Sesja": "",
        "Sesja nr %0": "",
        "Sesja użytkownika": "",
        "Sheduler": "",
        "Sierpień": "",
        "Silnik skryptowy": "",
        "Sitemap": "",
        "Skala 1-5": "",
        "Skan został dodany.": "",
        "Skopiuj kod": "",
        "Skrócona nazwa": "",
        "Skrypt": "",
        "Skrypt dla wartości domyślnych": "",
        "Skrypt filtrujący": "",
        "Skrypt musi być napisany w JS i musi definiować obiekt o nazwie filter, który powinien składać się z pól o nazwach zgodnych z kluczami pól formularza i wartościami wg. których nastąpi filtrowanie.": "",
        "Skrypt po dodaniu dokumentu": "",
        "Skrypt po przetworzeniu dokumentu": "",
        "Skrypt powinien kończyć się wyrażeniem zwracającym wartość logiczną (true/false)": "",
        "Skrypt powinien zwrócić wartość typu bool, określającą czy dane pole powinno być widoczne czy nie.": "",
        "Skrypt sprawdzający, czy data w polu jest w przeszłości i czy kwota jest większa niż 1000": "",
        "Skrypt walidacyjny": "",
        "Skrypt współdzielony": "",
        "Skrypt zliczający sumą wartości z pola numerycznego": "",
        "Skrypty": "",
        "słowa": "",
        "Słowa wykluczone": "",
        "Słownik": "",
        "Słownik dowolny": "",
        "Słownik Logito": "",
        "Słownik systemowy": "",
        "Słownik został dodany.": "",
        "Słownik został zaktualizowany.": "",
        "Słowniki": "",
        "Słowo": "",
        "Słowo bazowe": "",
        "Słowo wykluczone": "",
        "Słowo zostało dodane.": "",
        "Słowo zostało usunięte.": "",
        "Słowo zostało zaktualizowane.": "",
        "Sobota": "",
        "Sortowanie": "",
        "Sortowanie malejąco": "",
        "Sortowanie rosnąco": "",
        "Specjalizowane": "",
        "Specyfikacja": "",
        "Sposób prezentacji zakładek": "",
        "Sposób wyświetlania": "",
        "Spólka": "",
        "Spółka": "",
        "Spółka została dezaktywowana.": "",
        "Spółka została dodana.": "",
        "Spółka została zaktualizowana.": "",
        "Spółki": "",
        "Spółki użytkownika": "",
        "Sprawdzenie czy pusty": "",
        "Sprawdzenie, czy pole dokumentu ma wartość": "",
        "Sprawdzenie, czy wybrano konkretną opcję w polu wyboru": "",
        "Stała wartość": "",
        "Stan powiadomienia": "",
        "Standardowa szczegółowość": "",
        "Standardowe uprawnienia": "",
        "Stanowiska pracy": "",
        "Stanowisko": "",
        "Stanowisko pracy": "",
        "Start": "",
        "status": "",
        "Status": "",
        "Status dokumentu został zmieniony.": "",
        "Status edytowanej wersji dokumentu": "",
        "Status formularza został zmieniony.": "",
        "Status modułu": "",
        "Status webhooka": "",
        "Status został zapisany": "",
        "Statusy": "",
        "Statusy do walidacji": "",
        "Statusy do walidacji budżetu": "",
        "Stawka VAT": "",
        "Stopka": "",
        "Strona": "",
        "Strona %0": "",
        "Strona główna": "",
        "Strona www": "",
        "Strona została usunięta.": "",
        "Struktura organizacyjna": "",
        "Studio": "",
        "Stwierdzenie": "",
        "Styczeń": "",
        "Styl obrazu": "",
        "Styl przycisku": "",
        "success": "",
        "Sukces": "",
        "Suma": "",
        "Suma danych wejściowych i wyjściowych podana w tokenach.": "",
        "Suma tokenów": "",
        "Sygnatura": "",
        "Sygnatura skopiowana": "",
        "Symbol": "",
        "Symbol ISO waluty domyślnej": "",
        "Symbol jest wymagany": "",
        "Symbol jest zajęty": "",
        "Symbol klienta": "",
        "Symbol musi mieć co najmniej 3 znaki": "",
        "Symbol musi mieć co najmniej 5 znaków": "",
        "Symbol nie może być dłuższy niż 10 znaków": "",
        "Symbol nie może być dłuższy niż 20 znaków": "",
        "Symbol w adresie URL": "",
        "Synchronizowane z bazą AD": "",
        "Synonimy": "",
        "Synonimy zostały dodane.": "",
        "Synonimy zostały usunięte.": "",
        "Synonimy zostały zaktualizowane.": "",
        "System": "",
        "Szablon": "",
        "Szablon CSV": "",
        "Szablon modułu": "",
        "Szablon numeracji": "",
        "Szablon powiadomienia": "",
        "Szablon tabeli został dodany": "",
        "Szablon tabeli został usunięty": "",
        "Szablon tabeli został zaktualizowany": "",
        "Szablon word": "",
        "Szablon XML": "",
        "Szablon został dodany.": "",
        "Szablon został edytowany.": "",
        "Szablon został usunięty": "",
        "Szablon został usunięty.": "",
        "Szablon został zaktualizowany.": "",
        "Szablony": "",
        "Szablony dokumentów": "",
        "Szablony tabeli": "",
        "Szacowana kwota:": "",
        "Szczególy importu": "",
        "Szczegółowość obrazu": "",
        "Szczegółowy - błedy krytyczne": "",
        "Szczegóły": "",
        "Szczegóły asystenta": "",
        "Szczegóły elementu": "",
        "Szczegóły formularza": "",
        "Szczegóły kontrahenta": "",
        "Szczegóły pliku": "",
        "Szczegóły spółki": "",
        "Szczegóły terminu": "",
        "Szczegóły zadania": "",
        "Szczegóły zapytania": "",
        "Szczegóły zdarzenia": "",
        "Szczegóły zdarzenia nr %0": "",
        "Szerokość": "",
        "Szerokość (w calach)": "",
        "Szkic": "",
        "Szkic można usunąć, tylko i wyłącznie wtedy gdy posiadamy chociaż 1 aktywną wersję. Czy chcesz kontynuować?": "",
        "Szukaj": "",
        "Szukaj pełnotekstowo w plikach:": "",
        "Szukaj pełnotekstowo w polach modułu:": "",
        "Szukaj ponownie": "",
        "Szukaj we wszystkich wersjach": "",
        "Szukana fraza": "",
        "Szukane słowo": "",
        "Średni": "",
        "Średnia": "",
        "Środa": "",
        "Środowisko": "",
        "Ta operacja nie jest odwracalna i spowoduje utratę danych kontrahenta.": "",
        "Tabela": "",
        "Tabela VAT": "",
        "Tablica elementów do wyboru, uzupełnienie tej właściwości spowoduje, że pole będzie traktowane jako select zamiast input": "",
        "Tagi": "",
        "Tak": "",
        "Tak/Nie": "",
        "Tekst": "",
        "Tekst alternatywny": "",
        "Tekst potwierdzający wymuszenie dodania dokumentu": "",
        "Tekst zastępczy": "",
        "Tel": "",
        "Tel.": "",
        "Telefon": "",
        "Telefon komórkowy": "",
        "Telefon wewnętrzny": "",
        "Ten dashboard nie zawiera jeszcze żadnych widgetów": "",
        "Ten element nie zawiera menu kontekstowego.": "",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "",
        "Tenant ID": "",
        "Teraz": "",
        "Termin": "",
        "Termin cykliczny": "",
        "Termin jednorazowy": "",
        "Termin prywatny": "",
        "Termin publiczny": "",
        "Termin został dodany pomyślnie.": "",
        "Termin został dodany.": "",
        "Termin został usunięty.": "",
        "Termin został zaaktualizowany.": "",
        "Termin został zaktualizowany.": "",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "",
        "Terminy słowników": "",
        "Test konfiguracji": "",
        "Test ustawień pomyślnie zakończony.": "",
        "Testowy adres email": "",
        "Testowy numer telefonu": "",
        "Testuj": "",
        "Testuj zapytanie": "",
        "Tłumacz": "",
        "Tłumaczenia": "",
        "Tłumaczenie słownika rozpoczęte. Ta operacja może potrwać do kilku minut.": "",
        "To pole jest wymagane": "",
        "Token": "",
        "Token został usunięty.": "",
        "Tokeny": "",
        "Tokeny dostępu": "",
        "Tokeny wejściowe": "",
        "Tokeny wyjściowe": "",
        "Tooltip": "",
        "Trace - najwięcej szczegółów": "",
        "Tracing": "",
        "Treści odpowiedzi są wymagane": "",
        "Treści pytań są wymagane": "",
        "Treść": "",
        "Treść szablonu": "",
        "Trigger": "",
        "Trwa autentykacja z SimplySign...": "",
        "Trwa generowanie obrazów": "",
        "Trwa generowanie obrazu": "",
        "Trwa generowanie opisu dla wybranego obrazu": "",
        "Trwa przekierowanie...": "",
        "Tryb konserwacji systemu": "",
        "Tryb walidacji": "",
        "Tryb wysyłki": "",
        "Tryb wyświetlania formularza": "",
        "Tryb zapisu dokumentu": "",
        "Trzy kolumny": "",
        "Twoje hasło wygasło. Zmień je na nowe.": "",
        "Tworzenie dokumentów": "",
        "Twój token": "",
        "tydzień": "",
        "Tydzień": "",
        "tydzień (tygodnie) w następującym dniu": "",
        "Tygodniowy": "",
        "tylko do nowo zaproszonych": "",
        "Tylko do odczytu": "",
        "Tylko dokumenty o wskazanym statusie są zliczane i porównywane z kwotą budżetu": "",
        "tylko dokumenty wersjonowane": "",
        "Tylko liderzy": "",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "",
        "Typ": "",
        "Typ danych": "",
        "Typ dokumentu": "",
        "Typ formularza": "",
        "Typ kodu": "",
        "Typ konta": "",
        "Typ kontrahenta": "",
        "Typ licznika": "",
        "Typ listy": "",
        "Typ logiczny": "",
        "Typ logowania": "",
        "Typ mime": "",
        "Typ modułu": "",
        "Typ obiektu": "",
        "Typ odbiorcy": "",
        "Typ pliku": "",
        "Typ pola": "",
        "Typ powiadomienia": "",
        "Typ rejestru": "",
        "Typ serwera": "",
        "Typ słownika": "",
        "Typ szablonu": "",
        "Typ właściwy": "",
        "Typ wykresu": "",
        "Typ wyszukiwania": "",
        "Typ zawartości": "",
        "Typ zmiennej": "",
        "Typ źródła": "",
        "Typ źródła danych": "",
        "typu \"%0\" o ID \"%1\"": "",
        "Typy grup": "",
        "Typy użytkowników": "",
        "Tytuł": "",
        "Tytuł formularza": "",
        "Tytuł formularza jest wymagany": "",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "",
        "Tytuł sekcji": "",
        "Uczestnicy": "",
        "uczestnika": "",
        "Udało się pobrać dokument": "",
        "Udało się wyeksportować dokument": "",
        "Udało się zapisać ustawienia widgetów": "",
        "Udostępnianie zostało zatrzymane": "",
        "Udostępnij": "",
        "Udostępnij dla": "",
        "Udostępnij do": "",
        "Udostępnij dokument w OneDrive do": "",
        "Udostępnij w OneDrive": "",
        "Udostępnił": "",
        "Udostępniono dla": "",
        "Udostępniono dokument w OneDrive": "",
        "Układ": "",
        "Układ domyślny": "",
        "Układ listy": "",
        "Ukryj": "",
        "Ukryj widok": "",
        "Ukryta": "",
        "Ukryty": "",
        "Ulica": "",
        "umowę": "",
        "Upload": "",
        "Upload plików": "",
        "Upłynęła data": "",
        "Uprawnieni użytkownicy": "",
        "Uprawnienia": "",
        "Uprawnienia do dokumentów": "",
        "Uprawnienia indywidualne": "",
        "Uprawnienia zespołów": "",
        "Uprawnione działy": "",
        "Uprawnione zespoły": "",
        "Url": "",
        "Uruchom zadanie": "",
        "Uruchomić gdy": "",
        "Uruchomienie procesu reindeksacji spowoduje czasową niedostępność danych dla użytkowników zależną od ilości dokumentów. Czy na pewno chcesz kontynuowac?": "",
        "Urządzenie zostało usunięte.": "",
        "Usługi": "",
        "Ustaw szerokości proporcjonalnie": "",
        "Ustawienia": "",
        "Ustawienia budżetu zostały zapisane.": "",
        "Ustawienia domyślne": "",
        "Ustawienia domyślne na filtrach": "",
        "Ustawienia domyślne na listach": "",
        "Ustawienia domyślne zostały zaktualizowane": "",
        "Ustawienia domyślne zostały zmienione": "",
        "Ustawienia grida": "",
        "Ustawienia konta": "",
        "Ustawienia modułu": "",
        "Ustawienia ogólne": "",
        "Ustawienia podstawowe": "",
        "Ustawienia powiadomień zostały zaktualizowane.": "",
        "Ustawienia sesji": "",
        "Ustawienia uprawnień zostały zapisane.": "",
        "ustawienia widgetu": "",
        "Ustawienia widgetu": "",
        "Ustawienia wyszukiwania i OCR zostały zapisane.": "",
        "Ustawienia zaawansowane": "",
        "Ustawienia zostały przywrócone.": "",
        "Ustawienia zostały zapisane.": "",
        "Ustawiono dokument jako prywatny": "",
        "Ustwienia szczegółów": "",
        "usunął": "",
        "Usunął": "",
        "Usunięcie": "",
        "Usunięcie dokumentu": "",
        "Usunięcie formularzannOperacja jest nieodwracalna. Zostaną utracone wszystkie powiązane dokumenty!nnCzy na pewno chcesz usunąć wybrany formularz?": "",
        "Usunięcie indeksunnOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany indeks?": "",
        "Usunięcie pola spowoduje usunięcie go z konfiguracji pól wspólnych na wszystkich modułach oraz ze wszystkich wielo-modułowych list dokumentów.": "",
        "Usunięcie wybranych elementów": "",
        "Usunięte": "",
        "Usunięto": "",
        "usunięty": "",
        "Usunięty": "",
        "Usuń": "",
        "Usuń datę": "",
        "Usuń dział": "",
        "Usuń filtr": "",
        "Usuń grupę": "",
        "Usuń indeks": "",
        "Usuń kontrahenta": "",
        "Usuń obiekt": "",
        "Usuń plik": "",
        "Usuń plik z listy": "",
        "Usuń pozycję": "",
        "Usuń sekcję": "",
        "Usuń spacje": "",
        "Usuń urządzenie": "",
        "Usuń urządzenie aby odwołać zapamiętane automatyczne logowanie na swoje konto": "",
        "Usuń z zespołu": "",
        "Usuń${selected.length > 1 ? ' zaznaczone' : ''}": "",
        "Usuwanie dokumentów": "",
        "Usuwanie użytkowników": "",
        "Usuwanie własnych dokumentów": "",
        "Usuwanie wszystkich dokumentów": "",
        "Utworzono powiadomienie weryfikacyjne": "",
        "Utworzony przez": "",
        "Utworzył": "",
        "Utwórz grupy": "",
        "Utwórz katalog": "",
        "Utwórz kopię formularza": "",
        "Utwórz kopię z": "",
        "Utwórz nową wersję": "",
        "Utwórz nowy": "",
        "Utwórz nowy wątek": "",
        "Uwaga brak konto do przydzielenia": "",
        "UWAGA: Brak obsługi TypeScript oraz w kodzie metody nie można robić importów.": "",
        "UWAGA! Funkcja umożliwia natychmiastowe wykonanie skryptu webhook bez dodawania go do kolejki. Pomijanie kolejkowania może prowadzić do przeciążeń systemu i timeoutów, zalecane jest używanie tej opcji tylko w sytuacjach, gdy natychmiastowe wykonanie jest absolutnie konieczne.": "",
        "Uwagi": "",
        "Uwzględnij wydziały podrzędne": "",
        "Użycie historii dokumentu w szablonie": "",
        "Użycie pól dokumentu:": "",
        "Użyte narzędzie": "",
        "Użytkownicy": "",
        "Użytkownicy w tej roli": "",
        "Użytkownik": "",
        "Użytkownik \"%0\" %1 obiekt %2": "",
        "Użytkownik jest liderem zespołu": "",
        "Użytkownik nie może się zalogować dopóki konto nie jest aktywne.": "",
        "Użytkownik nie należy do żadnego zespołu.": "",
        "Użytkownik posiada pełne uprawnienia": "",
        "Użytkownik został dodany do zespołu.": "",
        "Użytkownik został dodany.": "",
        "Użytkownik został usunięty.": "",
        "Użytkownik został wylogowany.": "",
        "Użytkownik został zaktualizowany.": "",
        "Użyty Model": "",
        "VAT": "",
        "W ciele metody walidującej dostępne są poniższe zmienne:": "",
        "W każdy dzień tygodnia": "",
        "W następującym dniu:": "",
        "W obiekcie data dostępne są propki isEdit (bool, czy dokument jest w edycji) i processId": "",
        "W obrębie skryptu dostępne są następujące obiekty:": "",
        "W obrębie skryptu możliwe jest korzystanie ze wszystkich serwisów dostępnych w skryptach np. w źródłach danych": "",
        "W organizacji nie ma jeszcze żadnych działów.": "",
        "W przyszłości": "",
        "W skrupcie dostępny jest obiekt Model z następującymi właściwościami:": "",
        "W skrypcie dostępny jest obiekt \"document\", który zawiera dane dokumentu (tylko w przypadku edycji), obiekt \"rowIndex\", który w propce \"value\" zawiera index dodawanego wiersza oraz obiekt \"formId\", który w propce \"value\" zawiera id formularza:": "",
        "W skrypcie dostępny jest obiekt CURRENTUSER, który zawiera dane zalogowanego użytkownika o następującej strukturze": "",
        "W skrypcie korzystać można ze wszystkich serwisów zgodnie z dokumentacją w źródłach danych.": "",
        "W skryptach procesowych dostępne są następujące obiekty:": "",
        "W skryptach procesowych dostępny jest obiekt Model z następującymi właściwościami:": "",
        "W zespole od %0": "",
        "W zespole od %0 do %1": "",
        "Walidacja": "",
        "Walidacja błędów obejmuje tylko najczęściej występujące przypadki. Aby sprawdzić poprawność procesu należy go przetestować.": "",
        "Walidacja certyfikatu SSL": "",
        "Walidacja danych": "",
        "Walidacja po stronie API": "",
        "Walidator": "",
        "Waliduj z tabelką VAT": "",
        "Warn - ostrzeżenia": "",
        "warning": "",
        "Wartości": "",
        "Wartości od 0 - 1.0 (0 oznacza 0%, a 1 oznacza 100%)": "",
        "Wartości poszczególnych komórek tabeli są w formacie zgodnym z tym, co dana kontrolka zwraca na front.": "",
        "Wartość": "",
        "Wartość brutto": "",
        "Wartość domyślna": "",
        "Wartość domyślna: (&(objectClass=organizationalUnit))": "",
        "Wartość domyślna: (&(objectClass=user)": "",
        "Wartość musi być liczbą całkowitą": "",
        "Wartość musi być liczbą dodatnią": "",
        "Wartość musi być liczbą ujemną": "",
        "Wartość netto": "",
        "Warunek": "",
        "Warunek porównania do daty bieżącej pola w wybranym kroku na wskazanym formularzu": "",
        "Warunek porównania do daty bieżącej pola z dokumentu": "",
        "Warunek sprawdzający, czy pole typu 'Tak/Nie' ma wartość Nie": "",
        "Warunek sprawdzający, czy pole typu 'Tak/Nie' ma wartość Tak": "",
        "Warunek zmienne porównane do pola dokumentu": "",
        "Warunek zmienne porównane do pola w wybranym kroku na wskazanym fomrularzu": "",
        "Warunki logiczne": "",
        "Warunkowe wyświetlanie pól": "",
        "Warunkowo": "",
        "Ważne informacje": "",
        "Ważność": "",
        "Wątek": "",
        "Wątek główny": "",
        "Wątek główny jest kontynuowany automatycznie przez asystenta.": "",
        "Wczytaj szablon": "",
        "Wczytanie szablonu nie powiodło się": "",
        "Webhook": "",
        "Webhook został dodany.": "",
        "Webhook został usunięty.": "",
        "Webhook został zaktualizowany.": "",
        "Webhooki": "",
        "Weekend": "",
        "Wersja": "",
        "Wersja API": "",
        "Wersja językowa": "",
        "Wersja robocza": "",
        "Wersja utowrzona przez": "",
        "Wersje": "",
        "Wersje dokumentu": "",
        "wersji zaakceptowanej": "",
        "Wersjonowanie": "",
        "Wersjonowanie dokumentów włączone": "",
        "Wersjonowanie dokumentów wyłączone": "",
        "Wersjonowanie można zmienić tylko podczas dodawania nowego formularza.": "",
        "Wewnętrznie": "",
        "Wiadomość testowa została wysłana.": "",
        "Widget został dodany": "",
        "Widget został usunięty": "",
        "Widget został zapisany": "",
        "Widgety": "",
        "Widoczne w listach": "",
        "Widoczność": "",
        "Widoczność formatowania": "",
        "Widoczność modułu w menu": "",
        "Widoczność sekcji w menu modułu": "",
        "Widok nieobsługiwany": "",
        "Widok ustawień": "",
        "Widzi wszystkie dokumenty w rejestrze i ich szczegóły": "",
        "Widzi wszystkie faktury w rejestrze i ich szczegóły.": "",
        "Widzi wszystkie nieobecności w rejestrze i ich szczegóły": "",
        "Widzi wszystkie pisma w rejestrze i ich szczegóły": "",
        "Widzi wszystkie reklamacje w rejestrze i ich szczegóły": "",
        "Widzi wszystkie umowy w rejestrze i ich szczegóły": "",
        "Widzi wszystkie wnioski w rejestrze i ich szczegóły": "",
        "Widżety": "",
        "Wielkość kroku": "",
        "Wielokrotny wybór": "",
        "Wiersz": "",
        "Więcej": "",
        "więcej...": "",
        "Więcej...": "",
        "Wizualizacja": "",
        "Wklej komponent": "",
        "Właściwości": "",
        "Właściwości opisujące pole:": "",
        "Właściwości szablonu": "",
        "Włącz": "",
        "Włącz funkcję opisu dokumentów kodami kontrollingowymi": "",
        "Włącz obsługę wprowadzania dokumentów w module biuro": "",
        "Włącz system powiadomień": "",
        "Włącz walidację po stronie API": "",
        "Włącz wyszukiwanie pełnotekstowe w plikach": "",
        "Włączenie moderacji danych wejściowych jest zalecane.": "",
        "Włączone": "",
        "Włączony": "",
        "Województwo": "",
        "Wpis domyślny": "",
        "Wpis nadrzędny": "",
        "Wpis w sitemap": "",
        "Wpis został dodany.": "",
        "Wpis został usunięty.": "",
        "Wpis został zaakceptowany.": "",
        "Wpis został zaktualizowany.": "",
        "Wpisz kod ikony Font Awesome, aby zobaczyć podgląd": "",
        "Wpisz komentarz...": "",
        "Wpisz wiadomość": "",
        "Wprowadzamy URL do seriwsów które chemy wykluczyć. Dane mogą być odzielone przecinkiem lub być w nowej linii": "",
        "Wprowadzona wartość jest nieprawidłowa": "",
        "Wprowadzona wartość jest za długa": "",
        "Wprowadzona wartość jest za krótka": "",
        "Wprowadzona wartość jest za niska": "",
        "Wprowadzona wartość jest za wysoka": "",
        "Wprowadzona wartość nie jest poprawna": "",
        "Wróć do logowania": "",
        "Wróć do strony głównej": "",
        "Wrzesień": "",
        "Wskazuje, jak długo odpowiedź pozostaje aktualna w pamięci serwera Redis.": "",
        "Wstaw cztery kolumny 1/4": "",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "",
        "Wstaw dwie równe kolumny": "",
        "Wstaw kontrolkę": "",
        "Wstaw stronę": "",
        "Wstaw szablon informacyjny ze zdjęciem": "",
        "Wstaw trzy kolumny 1/3": "",
        "Wstaw z dołu": "",
        "Wstaw z góry": "",
        "Wstaw z lewej strony": "",
        "Wstaw z prawej strony": "",
        "Wstecz": "",
        "Wstępne filtrowanie danych": "",
        "Wszyscy": "",
        "Wszyscy pracownicy mogą dodawać nieobecność.": "",
        "Wszyscy pracownicy mogą dodawać wniosek.": "",
        "Wszystkie": "",
        "Wszystkie działy": "",
        "Wszystkie niezapisane zmiany zostaną utracone.": "",
        "Wszystkie próby": "",
        "Wszystko": "",
        "Wtorek": "",
        "wybierz": "",
        "Wybierz": "",
        "wybierz długość": "",
        "Wybierz dokument": "",
        "Wybierz dokumenty aby zobaczyć dostępne akcje": "",
        "Wybierz filtr": "",
        "Wybierz formularz zawierający dodatkowe pola dla kont użytkownków": "",
        "Wybierz grupę": "",
        "Wybierz katalog do którego chcesz przenieść wybrane elementy.": "",
        "wybierz model": "",
        "Wybierz moduł aby ograniczyć wiązanie do wybranego typu dokumentu": "",
        "Wybierz plik": "",
        "Wybierz plik do udostępnienia": "",
        "Wybierz plik lub katalog": "",
        "Wybierz plik.": "",
        "Wybierz pliki": "",
        "Wybierz pliki (do {0})": "",
        "Wybierz serwer": "",
        "Wybierz shortcode": "",
        "Wybierz typ": "",
        "Wybierz typ odbiorcy": "",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "",
        "Wybierz użytkownika": "",
        "Wybierz użytkowników dla których chcesz wykonać akcje": "",
        "Wybierz wątek w którym chcesz prowadzić konwersację.": "",
        "Wybierz wersję": "",
        "Wybierz zaznaczone": "",
        "wybierz...": "",
        "Wybór": "",
        "Wybór języka": "",
        "Wybór wersji": "",
        "Wybór załączników": "",
        "Wybór+": "",
        "Wybrana data jest mniejsza niż dozwolona %0.": "",
        "Wybrana data jest większa niż dozwolona %0.": "",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "",
        "Wybrana liczba jest większa niż dozwolona %0.": "",
        "Wybrane": "",
        "Wybrane %0 na %1": "",
        "Wybrane dokumenty": "",
        "Wybrane elementy zostały przeniesione.": "",
        "Wybrane elementy zostały usunięte.": "",
        "Wybrane pliki": "",
        "Wybrane pliki: {0}": "",
        "Wybrane pole zastąpi pole zgrupowane w konfiguracjach modułu": "",
        "Wybrane: %0": "",
        "Wybrano %0 użytkowników.": "",
        "Wybrano dostawcę Azure AI.": "",
        "Wybrano dostawcę Azure OpenAI.": "",
        "Wybrano dostawcę OpenAI.": "",
        "Wybrano jednego użytkownika.": "",
        "Wybrany język": "",
        "Wybrany użytkownik": "",
        "Wybróbuj możliwości AI": "",
        "Wyczyść": "",
        "Wydarzenie": "",
        "Wydarzenie cykliczne": "",
        "Wydział": "",
        "Wygenerowana odpowiedź przez model AI.": "",
        "Wygenerowane obrazy": "",
        "Wygeneruj dokumentację": "",
        "Wygeneruj metadane dla przesyłanych obrazów": "",
        "Wyklucz logowanie zależności": "",
        "Wykonaj": "",
        "Wykonywana przez skrypt robotyzujący niedostępna dla użytkownika": "",
        "Wykorzystane": "",
        "Wykorzystanie": "",
        "Wykresy": "",
        "Wyliczone daty": "",
        "Wyloguj się": "",
        "Wyloguj zalogowanego obecnie użytkownika": "",
        "Wyłaczone": "",
        "Wymagaj znaków specjalnych w hasłach": "",
        "Wymagalność pola (true, false)": "",
        "Wymagane": "",
        "Wymagane jest ustawienie minimalnej długości hasła": "",
        "Wymagane jest wybranie formularza": "",
        "Wymagane jest wybranie kategorii": "",
        "Wymagane jest wybranie komponentu": "",
        "Wymagane jest wybranie modułu": "",
        "Wymagane jest wybranie powiązania": "",
        "Wymagane jest wybranie sekcji": "",
        "Wymagane jest wybranie źródła danych": "",
        "Wymagany": "",
        "Wymiary": "",
        "Wymuś zmianę hasła": "",
        "Wynik testu": "",
        "Wyniki": "",
        "Wyniki testu zawszę będą ograniczone do maksymalnie 10 pozycji.": "",
        "Wypełnij formularz ostatnio zapisaną wersją": "",
        "Wyrażenia do pól wyliczanych": "",
        "Wyrażenia warunkowe": "",
        "Wyrażenie (wymagane)": "",
        "Wyrażenie regularne": "",
        "Wyrażenie w postaci \"/pattern/flag\" nie jest obsługiwane, użyj same wartości pattern": "",
        "Wysłane": "",
        "Wysoka szczegółowość": "",
        "Wysokość": "",
        "Wysokość (w calach)": "",
        "Wysokość wiersza": "",
        "wystąpieniach": "",
        "Wystąpił błąd podczas ładowania obrazu. Spróbuj ponownie.": "",
        "Wystąpił błąd podczas próby exportu użytkowników.": "",
        "Wystąpił błąd podczas próby importu definicji modułu.": "",
        "Wystąpił błąd podczas próby importu użytkowników.": "",
        "Wystąpił błąd podczas usuwania dokumentu.": "",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "",
        "Wystąpił problem podczas pobierania szablonu": "",
        "Wystąpił problem podczas przesyłania obrazu.": "",
        "Wystąpił problem w komunikacji z Api dostawcy podpisów. Kliknij ponownie Podpisz.": "",
        "Wystąpiły błędy podczas importu procesu": "",
        "Wysyłaj dane o użytkowniku": "",
        "Wysyłaj tym kanałem": "",
        "Wyszukaj": "",
        "Wyszukaj (Ctrl+Q)": "",
        "Wyszukaj aplikacje": "",
        "Wyszukaj w działach podrzędnych": "",
        "Wyszukaj widget": "",
        "Wyszukaj wszędzie": "",
        "Wyszukaj...": "",
        "Wyszukiwanie": "",
        "Wyszukiwanie pełnotekstowe": "",
        "Wyszukiwanie plików": "",
        "Wyszukiwanie plików umożliwia asystentowi korzystanie z wiedzy z plików. Po przesłaniu pliku asystent automatycznie decyduje, kiedy pobrać treść, na podstawie żądań użytkownika.": "",
        "Wyszukiwany ciąg znaków": "",
        "Wyszukiwarka adresów ADE": "",
        "Wyszukiwarka dokumentów": "",
        "Wyślij": "",
        "Wyślij do": "",
        "Wyślij kod": "",
        "Wyślij link": "",
        "Wyślij powiadomienie": "",
        "Wyślij wiadomość Microsoft Teams": "",
        "Wyświetl wszystko": "",
        "Wyświetlaj tylko dokumenty których użytkownik:": "",
        "Wyświetlam": "",
        "Wyświetlamy pierwsze znalezione 10 tyś rekordów, wyników może być więcej, zawęź kryteria wyszukiwania": "",
        "Wyświetlanie dokumentów": "",
        "Wyświetlany układ / dane": "",
        "Wytnij": "",
        "Wywołanie funkcji": "",
        "Wyzeruj licznik": "",
        "Wzorzec": "",
        "Wzorzec cyklu": "",
        "Z uprawnieniami": "",
        "Z uprawnieniami do modułu": "",
        "Zaakceptowane": "",
        "Zaakceptowano": "",
        "Zaakceptowany": "",
        "Zaawansowane uprawnienia": "",
        "Zabierz dostęp": "",
        "Zablokowany": "",
        "Zablokuj": "",
        "Zachowaj oryginalny format obrazów": "",
        "zacznij pisać aby wyszukać użytkownika...": "",
        "Zacznij pisać Form lub Entry aby uruchomić podpowiadanie.": "",
        "Zaczyna sie od": "",
        "Zadanie": "",
        "Zadanie przebudowania indeksów zostało rozpoczęte.": "",
        "Zadanie przeindeksowania danych zostało rozpoczęte.": "",
        "Zadanie zostało uruchomione.": "",
        "Zadanie zostało włączone": "",
        "Zadanie zostało wyłączone": "",
        "Zagrożenie": "",
        "Zaimportowane dane nadpiszą istniejące dane w module": "",
        "Zaimportowane dane zostaną dopisane do istniejących danych w module": "",
        "Zaimportowano": "",
        "Zaimportowano definicję modułu.": "",
        "Zaimportowano listę użytkowników.": "",
        "Zakładka": "",
        "Zakładki": "",
        "Zakolejkowane": "",
        "Zakończ edycje": "",
        "Zakończ sesję": "",
        "Zakończ sesję aby wylogować użytkownika": "",
        "Zakończone": "",
        "Zakres": "",
        "Zakres cyklu": "",
        "Zakres czasu": "",
        "Zakres dat": "",
        "Zaktualizuj formularz": "",
        "Zależna od kontrolki": "",
        "Zalogowany": "",
        "Zalogowany użytkownik": "",
        "Zaloguj do SimplySign": "",
        "Zaloguj się": "",
        "Załączniki": "",
        "Zamiany zostały zapisane.": "",
        "Zamknij": "",
        "Zamknij edycje": "",
        "Zamknij linię": "",
        "Zaokrąglenie": "",
        "Zapamiętaj logowanie": "",
        "Zapamiętane urządzenia": "",
        "Zapisano formularz": "",
        "Zapisuj do celu": "",
        "Zapisywanie obrazów zakończone.": "",
        "Zapisz": "",
        "Zapisz diagram": "",
        "Zapisz diagram w celu wyświetlenia błędów oraz przetestuj dokument": "",
        "Zapisz dokument jako nowy w PDF": "",
        "Zapisz filtr": "",
        "Zapisz filtry": "",
        "Zapisz i wróć": "",
        "Zapisz i zamknij": "",
        "Zapisz jako": "",
        "Zapisz układ": "",
        "Zapisz ustawienia": "",
        "Zapisz ustawienia widgetów": "",
        "Zapisz widget": "",
        "Zapisz wybrane": "",
        "Zapisz wynik wysyłki e-maila (powodzenie/niepowodzenie) we wskazanym polu": "",
        "Zapisz zmiany": "",
        "Zaplanowane": "",
        "Zapomniałeś hasła?": "",
        "Zaproszenia do akceptacji": "",
        "Zaproszenia usunięte/odrzucone": "",
        "Zaproszenie zostało odrzucone": "",
        "Zaproszenie zostało zaakceptowane": "",
        "Zaproś uczestników": "",
        "Zapytanie SQL do pobrania po ID": "",
        "Zapytanie SQL do wyszukiwania": "",
        "Zarchiwizowane": "",
        "Zarządzaj filtrami": "",
        "Zarządzaj kolumnami": "",
        "Zasięg": "",
        "Zastępował": "",
        "zastępował: ": "",
        "Zastępstwa": "",
        "Zastępstwo": "",
        "Zastępstwo wygasło.": "",
        "Zastępstwo wygaśnie za 10 minut.": "",
        "Zastępstwo wygaśnie za 5 minut.": "",
        "Zastępstwo wygaśnie za minutę.": "",
        "Zastępstwo zostało dodane.": "",
        "Zastępstwo zostało usunięte.": "",
        "Zastępstwo zostało zaktualizowane.": "",
        "Zastępuje osobę": "",
        "Zastosuj zmiany w aplikacji": "",
        "Zatrzymaj": "",
        "Zatrzymaj udostępnianie": "",
        "Zatwierdź": "",
        "Zawęź listę dokumentów do dokumentów powiązanych": "",
        "Zawęż listę dokumentów na podstawie kontrahenta": "",
        "Zawiera": "",
        "Zawsze": "",
        "Zaznacz": "",
        "Zaznacz wszystkie": "",
        "Zbieracz kosztów": "",
        "Zdarzenie": "",
        "Zdjęcie": "",
        "Zdjęcie użytkownika": "",
        "Zespoły": "",
        "Zespól": "",
        "Zespół": "",
        "Zespół aktywny": "",
        "Zespół został dodany.": "",
        "Zespół został usunięty.": "",
        "Zespół został zaktualizowany.": "",
        "Zgoda": "",
        "Zgrupuj pola": "",
        "Złożenie podpisu zostało zakończone sukcesem": "",
        "Zmiana akceptantów": "",
        "Zmiana hasła": "",
        "Zmiana prywatności": "",
        "Zmiana rozmiaru obrazu": "",
        "Zmiany ustawień monitoringu wymagają restartu aplikacji.": "",
        "Zmiany zaczną obowiązywać dopiero po przebudowaniu indeksów.": "",
        "Zmiany zostały zapisane.": "",
        "Zmiany zostaną zastosowane w aplikacji w przeciągu kikunastu sekund": "",
        "Zmieniono aktywną wersję": "",
        "Zmieniono nazwę wersji": "",
        "Zmieniono pola wspólne": "",
        "Zmieniono powiadomienie weryfikacyjne": "",
        "Zmieniono ustawienia widgetu": "",
        "Zmienna środowiskowa została usunięta": "",
        "Zmienna została dodana.": "",
        "Zmienna została zaktualizowana.": "",
        "Zmienna została zapisana": "",
        "zmienną": "",
        "Zmienne": "",
        "Zmienne środowiskowe": "",
        "Zmień hasło": "",
        "Zmień nazwę": "",
        "Zmień nazwę katalogu": "",
        "Zmień nazwę pliku": "",
        "Zmień rozmiar": "",
        "Zmień status edytowanej wersji": "",
        "Zmień wersję procesu": "",
        "Zmień widok": "",
        "Zmniejsz": "",
        "zmodyfikował": "",
        "Zmodyfikował": "",
        "Zmodyfikowano": "",
        "zmodyfikowany": "",
        "znaki": "",
        "Znaleziono kontrahentów o podanym NIPie.": "",
        "Zobacz sesję użytkownika": "",
        "Zobacz szczegóły sesji użytkownika": "",
        "Zobacz szczegóły zdarzenia": "",
        "Zobacz wszystkie": "",
        "Zobacz wszystkie zastępstwa": "",
        "zobaczył/a": "",
        "Został przywrócony oryginalny harmonogram": "",
        "Zresetuj hasło": "",
        "Zrestartuj aplikacje": "",
        "Zwrócono": "",
        "Zwrócony": "",
        "Zwróć do edycji": "",
        "Zwróć do opisu": "",
        "Źródła danych": "",
        "Źródła LDAP": "",
        "Źródło": "",
        "Źródło aktywne": "",
        "Źródło danych": "",
        "Źródło danych jest wymagane": "",
        "Źródło danych został dodane.": "",
        "Źródło danych został zapisane.": "",
        "źródło danych zostało dodane.": "",
        "Źródło danych zostało dodane.": "",
        "Źródło danych zostało zaktualizowane.": "",
        "Źródło danych zostało zapisane.": "",
        "Źródło LDAP zostało dodane.": "",
        "Źródło LDAP zostało zaktualizowane.": "",
        "źródło zostało usunięte.": "",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "",
        "Żródło danych": ""
    }
};
