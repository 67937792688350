<script lang="ts" setup>
import { computed } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, DictionaryType } from '.';
import { ApiQueryCondition, KeyElement, TermsQueryCondition } from '@/helpers/ApiQueryBuilder';
import DataSourcesService from '@/modules/studio/data-sources/services/DataSourcesService';
import Pager from '@/helpers/Pager';
import IdeoFilterMultiple from '@/components/filters/types/IdeoFilterMultiple.vue';

defineOptions({
    name: `${Definition.type}-filter`
});

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});

const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as TermsQueryCondition);
const entry = computed({
    get()
    {
        return condition.value?.terms.values || [];
    },
    set(value)
    {
        if (condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                terms: {
                    field: `${component.value.name}.Value`,
                    values: value || []
                }
            });
        }

        condition.value.terms.values = value || [];

        if (condition.value.terms.values.length == 0)
        {
            reset();
        }
    }
});

const label = computed(() => props.builder.localization.translate(component.value.label));

const reset = (): void =>
{
    model.value.remove(p => p.__key == component.value.id);
};

async function search(query: string, limit: number)
{
    try
    {
        const pager = new Pager(1, 10, 'Position', 'ASC');
        const result = await DataSourcesService.getOptions(component.value.sourceId, query, pager);

        return result.items.map(p =>
        {
            return {
                value: p.value,
                text: p.text
            };
        });
    }
    catch (ex)
    {
        return [];
    }
}

async function fetch(ids: (string|number)[])
{
    try
    {
        if (ids.length == 0)
            return [];

        const pager = new Pager(1, 10000, 'Position', 'ASC');
        const result = await DataSourcesService.getOptionsById(component.value.sourceId, ids.map(p => Number(p)), pager);

        return result.items.map(p =>
        {
            return {
                value: p.value,
                text: p.text
            };
        });
    }
    catch (ex)
    {
        return [];
    }
}
</script>

<template>
    <IdeoFilterMultiple
        v-model="entry"
        :name="component.name"
        :label="label"
        :index="index"
        :value="entry"
        :visible="component.showFilter"
        :search="search"
        :fetch="fetch"
        @clear="reset"
    />
</template>
