<script lang="ts" setup>
import FieldWithHelper from '@/components/forms/properties/FieldWithHelper.vue';

const model = defineModel<string>();
</script>

<template>
    <field-with-helper v-model="model" :label="$t('[[[Skrypt dla wartości domyślnych]]]')">
        <template #helper>
            <div class="legend">
                <p class="mb-8">
                    {{
                        $t(
                            '[[[W skrypcie dostępny jest obiekt "document", który zawiera dane dokumentu (tylko w przypadku edycji), obiekt "rowIndex", który w propce "value" zawiera index dodawanego wiersza oraz obiekt "formId", który w propce "value" zawiera id formularza:]]]'
                        )
                    }}
                </p>
                <p></p>
                <div>
                    <pre>
rowIndex: {
    value: indeks dodawanego wiersza
}

formId: {
    value: id formularza
}

document: {
    PublicId: Guid dokumentu,
    DbDataDynamic: słownik zawierający wszystkie indeksowane pola dokumentu
}
                            </pre>
                </div>
                <p></p>
                <p>
                    {{ $t('[[[Przykładowy skrypt:]]]') }}
                </p>
                <p></p>
                <div>
                    <pre>
// Pobranie terminu słownika za pomocą formId, nazwy pola słownikowego w tabeli oraz nazwy terminu słownika.
var dictionaryApi = ApiCast.ToLogitoDictionaryByFormId(formId.value, "LogitoDictionary1", "Polska", '').FirstOrDefault();
var config = {
    Text1: "abc",
    Text2: "bcd"
}
if (dictionaryApi != null) {
    config.LogitoDictionary1 = [{
        key: dictionaryApi.Front.Key,
        value: dictionaryApi.Front.Value
    }]
}
var config2 = {
    Text1: "treść2",
    Text2: "nnn",
}
// Dane z obiektu "config2" załadują się tylko przy wierszu o indexie '3',
// w przeciwnym wypadku załadują się dane z obiektu "config"
if (rowIndex.value == '3') {
    config2;
}
else {
    config;
}

                            </pre>
                </div>
            </div>
        </template>
    </field-with-helper>
</template>
