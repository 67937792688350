<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { Options, Vue } from "vue-class-component";
import { Column } from "@/modules/core/common/services/GridService";
import { calculateSummary } from "@/components/common/dynamic-grid/helpers/CalculateSummary";

// Presenters
import DateTimePresenter from '@/components/dynamic-details/blueprints/field/presenters/DateTime.vue';
import UserWithAvatarPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatar.vue';
import UserWithAvatarArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatarArray.vue';
import TextPresenter from '@/components/dynamic-details/blueprints/field/presenters/Text.vue';
import StatusPresenter from '@/components/dynamic-details/blueprints/field/presenters/StatusWithVariant.vue';
import MoneyPresenter from '@/components/dynamic-details/blueprints/field/presenters/Money.vue';
import BooleanPresenter from '@/components/dynamic-details/blueprints/field/presenters/Boolean.vue';
import GenericKeyValuePairPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePair.vue';
import GenericKeyValuePairArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePairArray.vue';
import DecimalPresenter from '@/components/dynamic-details/blueprints/field/presenters/Decimal.vue';
import AddressPresenter from '@/components/dynamic-details/blueprints/field/presenters/Address.vue';
import MainFilePresenter from '@/components/dynamic-details/blueprints/field/presenters/File.vue';
import ListPresenter from '@/components/dynamic-details/blueprints/field/presenters/List.vue';
import SimplePartnerPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartner.vue';
import SimplePartnerArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartnerArray.vue';
import DurationPresenter from '@/components/dynamic-details/blueprints/field/presenters/Duration.vue';
import ResourcePresenter from '@/components/dynamic-details/blueprints/field/presenters/Resource.vue';
import TablePresenter from '@/components/dynamic-details/blueprints/field/presenters/Table.vue';
import TableVatPresenter from '@/components/dynamic-details/blueprints/field/presenters/TableVat.vue';
import UserGroupArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserGroupArray.vue';
import ExchangeRatePresenter from '@/components/dynamic-details/blueprints/field/presenters/ExchangeRate.vue';
import DynamicDocumentPresenter from '@/components/dynamic-details/blueprints/field/presenters/DynamicDocument.vue';
import IsPrivatePresenter from '@/components/dynamic-details/blueprints/field/presenters/IsPrivate.vue';
import RichTextPresenter from '@/components/dynamic-details/blueprints/field/presenters/RichText.vue';

@Options({
    name: "TableChangeLogFormatter",
    components: {
        FileReader,
        DateTimePresenter,
        UserWithAvatarPresenter,
        UserWithAvatarArrayPresenter,
        TextPresenter,
        StatusPresenter,
        MoneyPresenter,
        BooleanPresenter,
        GenericKeyValuePairPresenter,
        DecimalPresenter,
        AddressPresenter,
        MainFilePresenter,
        ListPresenter,
        SimplePartnerPresenter,
        SimplePartnerArrayPresenter,
        DurationPresenter,
        ResourcePresenter,
        TablePresenter,
        TableVatPresenter,
        GenericKeyValuePairArrayPresenter,
        UserGroupArrayPresenter,
        ExchangeRatePresenter,
        DynamicDocumentPresenter,
        IsPrivatePresenter,
        RichTextPresenter,
    },
})
export default class TableChangeLogFormatter extends Vue
{
    @Prop()
    public original: Record<string, any>[];

    @Prop()
    public current: Record<string, any>[];

    @Prop({ default: null })
    public definition: Column;

    public isTable = false;
    public summaryOriginal: { netAmount: string; grossAmount: string; vatAmount: string } = {
        netAmount: "0",
        grossAmount: "0",
        vatAmount: "0",
    };
    public summaryCurrent: { netAmount: string; grossAmount: string; vatAmount: string } = {
        netAmount: "0",
        grossAmount: "0",
        vatAmount: "0",
    };

    public mounted(): void
    {
        this.calculateSummary();
    }

    public calculateSummary(): void
    {
        this.summaryOriginal = calculateSummary(this.original ?? []);
        this.summaryCurrent = calculateSummary(this.current ?? []);
    }
}
</script>

<template>
    <div class="scroll scroll--overflow-y-hidden" :class="{ 'pb-2': isTable }">
        <IdeoButton variant="light" @click="isTable = !isTable" :title="!isTable ? $t('[[[Pokaż]]]') : $t('[[[Ukryj]]]')">
            <i v-show="!isTable" class="fa-solid fa-eye"></i>
            <i v-show="isTable" class="fa-solid fa-eye-slash"></i>
        </IdeoButton>
        <div v-show="isTable" class="mt-2">
            <table v-if="original" class="table table-bordered table--danger">
                <thead>
                    <tr>
                        <th>{{ $t("[[[Stawka VAT]]]") }}</th>
                        <th>{{ $t("[[[Wartość netto]]]") }}</th>
                        <th>{{ $t("[[[VAT]]]") }}</th>
                        <th>{{ $t("[[[Wartość brutto]]]") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, indexRow) in original" :key="indexRow">
                        <td class="text-nowrap">{{ row.taxCode?.value }}</td>
                        <td class="text-nowrap text-lg-end">
                            {{ $filters.currencyFormat(row.netAmount) }}
                        </td>
                        <td class="text-nowrap text-lg-end">
                            {{ $filters.currencyFormat(row.vatAmount) }}
                        </td>
                        <td class="text-nowrap text-lg-end">
                            {{ $filters.currencyFormat(row.grossAmount) }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>
                            {{ $t("[[[Suma]]]") }}
                        </th>
                        <th class="text-nowrap text-lg-end">
                            {{ $filters.currencyFormat(summaryOriginal.netAmount) }}
                        </th>
                        <th class="text-nowrap text-lg-end">{{ $filters.currencyFormat(summaryOriginal.vatAmount) }}</th>
                        <th class="text-nowrap text-lg-end">{{ $filters.currencyFormat(summaryOriginal.grossAmount) }}</th>
                    </tr>
                </tfoot>
            </table>

            <table v-if="current" class="table table-bordered table--success mb-0">
                <thead>
                    <tr>
                        <th>{{ $t("[[[Stawka VAT]]]") }}</th>
                        <th>{{ $t("[[[Wartość netto]]]") }}</th>
                        <th>{{ $t("[[[VAT]]]") }}</th>
                        <th>{{ $t("[[[Wartość brutto]]]") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, indexRow) in current" :key="indexRow">
                        <td class="text-nowrap">{{ row.taxCode?.value }}</td>
                        <td class="text-nowrap text-lg-end">
                            {{ $filters.currencyFormat(row.netAmount) }}
                        </td>
                        <td class="text-nowrap text-lg-end">
                            {{ $filters.currencyFormat(row.vatAmount) }}
                        </td>
                        <td class="text-nowrap text-lg-end">
                            {{ $filters.currencyFormat(row.grossAmount) }}
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>
                            {{ $t("[[[Suma]]]") }}
                        </th>
                        <th class="text-nowrap text-lg-end">
                            {{ $filters.currencyFormat(summaryCurrent.netAmount) }}
                        </th>
                        <th class="text-nowrap text-lg-end">{{ $filters.currencyFormat(summaryCurrent.vatAmount) }}</th>
                        <th class="text-nowrap text-lg-end">{{ $filters.currencyFormat(summaryCurrent.grossAmount) }}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.table {
    table-layout: fixed;
    max-width: 900px;

    thead,
    tfoot {
        tr {
            height: 36.5px;
            background: var(--border-color-nav);
        }

        th {
            border: 1px solid var(--bs-table-border-color);
            width: 100px;
        }
    }

    tbody {
        td {
            border-width: 1px;
            border: 1px solid var(--bs-table-border-color);
        }
    }

    &--danger {
        thead {
            th {
                border-color: #e51c23;
            }
        }

        tbody {
            td {
                border-color: #e51c23;
            }
        }

        tfoot {
            th {
                border-color: #e51c23 !important;
            }
        }
    }

    &--success {
        thead {
            th {
                border-color: #2eb85c;
            }
        }

        tbody {
            td {
                border-color: #2eb85c;
            }
        }

        tfoot {
            th {
                border-color: #2eb85c !important;
            }
        }
    }
}
</style>
