<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, NumericType } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { ApiQueryCondition, KeyElement, RangeQueryCondition } from '@/helpers/ApiQueryBuilder';

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as RangeQueryCondition);
const entry = computed<{gte?: number, lte?: number}>({
    get()
    {
        if (condition.value)
        {
            return {
                gte: condition.value.range.gte as number,
                lte: condition.value.range.lte as number
            };
        }

        return {
            gte: null,
            lte: null
        };
    },
    set(value)
    {
        if (condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                range: {
                    field: component.value.name
                }
            });
        }

        condition.value.range.gte = value.gte ? Number(value.gte) : null;
        condition.value.range.lte = value.lte ? Number(value.lte) : null;
    }
});
const gte = computed({
    get: () => entry.value.gte,
    set: (value) => entry.value = {...entry.value, gte: value}
});
const lte = computed({
    get: () => entry.value.lte,
    set: (value) => entry.value = {...entry.value, lte: value}
});
const display = computed(() =>
{
    let text = "";

    if (gte.value)
        text += $t(`[[[od %0|||${gte.value}]]] `);

    if (lte.value)
        text += $t(` [[[do %0|||${lte.value}]]]`);

    return text.trim().length > 0 ? text : null;
});
const label = computed(() => props.builder.localization.translate(component.value.label));

const resetMin = (): void =>
{
    gte.value = null;
};
const resetMax = (): void =>
{
    lte.value = null;
};
const reset = (): void =>
{
    model.value.remove(p => p.__key == component.value.id);
};
</script>

<template>
    <ideo-filter
        :value="entry"
        :display="display"
        :name="component.name"
        :label="label"
        :index="index"
        :visible="component.showFilter"
        menu-class="p-0"
        @clear="reset"
    >
        <ideo-input-group class="border-bottom">
            <ideo-form-input v-model="gte" type="number" :min="component.range.min" :max="component.range.max" :step="component.step" :placeholder="$t('[[[Od]]]')" class="bg-body py-3 border-0" />
            <template #append>
                <ideo-button variant="link" icon="fas fa-fw fa-times" @click="resetMin()"></ideo-button>
            </template>
        </ideo-input-group>
        <ideo-input-group>
            <ideo-form-input v-model="lte" type="number" :min="component.range.min" :max="component.range.max" :step="component.step" :placeholder="$t('[[[Do]]]')" class="bg-body py-3 border-0" />
            <template #append>
                <ideo-button variant="link" icon="fas fa-fw fa-times" @click="resetMax()"></ideo-button>
            </template>
        </ideo-input-group>
    </ideo-filter>
</template>
