<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { useMedia } from '@/plugins/media';
import { useLocalization } from '@/plugins/localization';
import { TreeNode } from '../utils';

const route = useRoute();
const media = useMedia();
const { $events } = useEvents();
const { $alert } = useAlerts();
const { $t } = useLocalization();

defineOptions({
    name: 'ideo-tree-node'
});

const props = defineProps({
  "node": null,
  "pages": null,
  "expanded": null,
  "menu": { type: [null, Function],  },
  "active": { type: [null, Function],  },
  "move": { type: Function,  }
});

const emit = defineEmits(["toggle", "click", "sort"]);

const hover = ref(false);
const contextMenu = ref(false);
const menuItems = ref([]);

const hasChildren = computed(() => props.node.hasChildren);
const hasMenu = computed(() => props.menu != null);
const isExpanded = computed(() => hasChildren.value && props.expanded.indexOf(props.node.id) >= 0);
const isCollapsed = computed(() => hasChildren.value && props.expanded.indexOf(props.node.id) === -1);

const isActive = computed(() =>
{
    if (props.active != null && typeof props.active === 'function')
        return props.active(props.node);
    else
        return route.params.id === props.node.id.toString();
});

const container = computed(() => `node-container-${props.node.id}`);

const hidePopover = (node: TreeNode): void =>
{
    if (node === null || node != props.node)
    {
        menuItems.value = [];
        contextMenu.value = false;
        hover.value = false;
    }
};

onMounted(() =>
{
    $events.$on('tree::popover::hide', hidePopover);
});

onBeforeUnmount(() =>
{
    $events.$off('tree::popover::hide', hidePopover);
});

const getMenu = async (node: TreeNode): Promise<any[]> =>
{
    if (props.menu)
        return await props.menu(node);
    else
        return [];
};

const icon = (icon: string): string =>
{
    return props.node.icon ? props.node.icon : icon;
};

const mouseOver = (): void =>
{
    hover.value = true;
};

const mouseOut = (): void =>
{
    if (contextMenu.value == false)
    {
        hover.value = false;
    }
};

const togglePopover = async (): Promise<void> =>
{
    if (contextMenu.value == false)
    {
        $events.$emit('tree::popover::hide', props.node);
    }

    contextMenu.value = !contextMenu.value;

    if (contextMenu.value)
    {
        menuItems.value = await getMenu(props.node);

        if (menuItems.value.length === 0)
        {
            $alert.info($t('[[[Ten element nie zawiera menu kontekstowego.]]]'));
            contextMenu.value = false;
        }
    }
};

const onMenuItem = (node: TreeNode, item: any, confirmed: boolean): void =>
{
    if (confirmed)
    {
        $events.$emit('tree::popover::hide', node);
        togglePopover();
        item.click(node);
    }
};

const onToggle = (node: TreeNode): void =>
{
    emit('toggle', node);
};

const onClick = (node: TreeNode): void =>
{
    emit('click', node);
};

const onSort = (pages: TreeNode[]): void =>
{
    emit('sort', pages);
};
</script>

<template>
    <li :id="container" @mouseover.stop="mouseOver" @mouseout.stop="mouseOut">
        <span class="d-flex align-items-center" :class="{'active': isActive, 'muted': typeof node.isActive === 'boolean' && !node.isActive}">
            <slot name="toggle" :node="node">
                <i class="toggle fa" v-if="!hasChildren"></i>
                <i class="toggle fas fa-caret-down" v-if="isCollapsed" @click="onToggle(node)"></i>
                <i class="toggle fas fa-caret-up" v-if="isExpanded" @click="onToggle(node)"></i>
            </slot>
            <slot name="icon" :node="node">
                <i :class="icon(`fas fa-fw fa-folder fa-swap`)" v-if="!hasChildren"></i>
                <i :class="icon(`fas fa-fw fa-folder`)" v-if="isCollapsed"></i>
                <i :class="icon(`fas fa-fw fa-folder-open fa-swap-opacity`)" v-if="isExpanded"></i>
            </slot>
            <slot name="default" :node="node">
                <a href="#" @click.prevent="onClick(node)" class="flex-fill">{{ node.name }}<span v-if="node.counter >= 0"> [{{ node.counter }}]</span></a>
            </slot>

            <ideo-dropdown
                v-if="hasMenu"
                variant="link"
                no-caret
                size="sm"
                @toggle="togglePopover"
                @hidden="hover = false"
                :hidden="!(hover || media.mobile())"
            >
                <template #button-content>
                    <i class="fa fa-ellipsis-vertical"></i>
                </template>

                <template v-for="(item, index) in menuItems" :key="index">
                    <ideo-dropdown-divider v-if="item.divider" />
                    <ideo-dropdown-item-button
                        v-else
                        :message="item.confirm"
                        :message-placement="item.confirmPlacement"
                        :value="item"
                        @confirm="onMenuItem(node, item, true)"
                        @click.stop="onMenuItem(node, item, !item.confirm)"
                    >
                        <i :class="item.icon"></i>
                        <span>{{ item.name }}</span>
                    </ideo-dropdown-item-button>
                </template>
            </ideo-dropdown>
        </span>
        <ideo-tree-nodes :pages="pages" :expanded="expanded" :parent="node" :menu="menu" :active="active" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort">
            <template #toggle="{node}">
                <slot name="toggle" :node="node"></slot>
            </template>
            <template #icon="{node}">
                <slot name="icon" :node="node"></slot>
            </template>
            <template #default="{node}">
                <slot name="default" :node="node"></slot>
            </template>
        </ideo-tree-nodes>


        <!-- <ideo-popover ref="popover" :show="contextMenu" :target="target" :container="container" placement="bottomright" :local="true">
            <div class="dropdown">
                <div class="dropdown-menu">
                    <template v-for="(item, index) in menuItems" :key="index">
                        <div class="dropdown-divider" v-if="item.divider"></div>
                        <button type="button" :id="menuItemId(index)" class="dropdown-item" :class="item.class" @click="onMenuItem(node, item, !item.confirm)" v-else>
                            <i class="me-2 icon" :class="item.icon"></i> {{ item.name }}
                            <confirmation v-if="item.confirm" :target="menuItemId(index)" :message="item.confirm" :value="item" @confirm="" :placement="item.confirmPlacement || 'right'" />
                        </button>
                    </template>
                </div>
            </div>
        </ideo-popover> -->
    </li>
</template>
