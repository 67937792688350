// Blueprints
import { Blueprint } from "../../base/blueprints/Blueprint";
import { BlueprintDefinition } from "../../base/blueprints/BlueprintDefinition";
import { AggregateBlueprint } from "../../base/blueprints/AggregateBlueprint";
import { RootBlueprint } from "../../base/blueprints/RootBlueprint";
import { PageBlueprint } from "../blueprints/PageBlueprint";
import { RowBlueprint } from "../blueprints/RowBlueprint";

// Managers
import { LayoutManager } from "../../base/managers/LayoutManager";
import { DimensionsManager } from "../../base/managers/DimensionsManager";
import { EventManager, Events } from "../../base/managers/EventManager";
import { SchemaManager } from "../../base/managers/SchemaManager";

// Services
import { FormBlueprintFactory } from "../services/FormBlueprintFactory";

// Traits
import { instanceOfHasWidth } from "../../base/traits/HasWidth";

export class FormLayoutManager extends LayoutManager
{
    protected dimensions: DimensionsManager;

    public constructor(blueprintFactory: FormBlueprintFactory, eventManager: EventManager, schemaManager: SchemaManager, dimensionsManager: DimensionsManager)
    {
        super(blueprintFactory, eventManager, schemaManager);

        this.dimensions = dimensionsManager;
    }

    protected get form(): FormBlueprintFactory
    {
        return this.factory as FormBlueprintFactory;
    }

    public createResizableComponent(parent: AggregateBlueprint, type: string): Blueprint
    {
        const component = super.createComponent(type);

        if (instanceOfHasWidth(component))
        {
            component.setDefaultWidth(this.dimensions.space(parent));
        }

        return component;
    }

    public insertComponent(parent: AggregateBlueprint, component: Blueprint, before: Blueprint = null): void
    {
        super.insertComponent(parent, component, before);

        this.dimensions.autoarrange(parent, false);
    }

    public addComponent(parent: AggregateBlueprint, definition: BlueprintDefinition<any>, before: Blueprint = null): Blueprint
    {
        // utworzenie nowego obiektu danego typu po nazwie
        const component: any = this.createResizableComponent(parent, definition.type);

        if (this.dimensions.available(parent) >= component.width)
        {
            this.insertComponent(parent, component, before);
            this.events.emit(Events.FOCUS, component);

            if (component && 'composite' in component && 'key' in definition)
            {
                component.composite = definition.key;
            }

            return component;
        }

        return null;
    }

    // public updateComponent(component: Blueprint, type: string): Blueprint
    // {
    //     const parent = this.schema.parent(component);
    //     const replacement = this.createResizableComponent(parent, type);

    //     this.insertComponent(parent, replacement, component);
    //     this.removeComponent(component);

    //     return replacement;
    // }

    public addPage(parent: RootBlueprint, before: PageBlueprint = null): PageBlueprint
    {
        const area = this.form.createPage(this.schema.newId(), 'page');

        area.name = this.schema.name(area.type);

        if (before != null)
            parent.components.splice(parent.components.indexOf(before), 0, area);
        else
            parent.components.push(area);

        return area;
    }

    public addRow(parent: AggregateBlueprint, before: RowBlueprint = null): RowBlueprint
    {
        const slot = this.form.createRow(this.schema.newId(), 'row');

        slot.name = this.schema.name(slot.type);

        if (before != null)
        {
            parent.components.splice(parent.components.indexOf(before), 0, slot);
            this.events.emit(Events.FOCUS, slot);
        }
        else
        {
            parent.components.push(slot);
        }

        return slot;
    }

    public addRowBefore(before: RowBlueprint): void
    {
        this.addRow(this.schema.parent(before), before);
    }

    public addRowAfter(after: RowBlueprint): void
    {
        const next = this.schema.next(after);

        if (next != null)
        {
            if ((next as any as RowBlueprint).components.length > 0)
                this.addRow(this.schema.parent(after), next as any as RowBlueprint);
            else
                this.events.emit(Events.FOCUS, next);
        }
    }
}
