import { axios } from '@/plugins/axios';
import { Resource, KeyValuePair, Pagination, Statement, KeyValue } from "@/helpers/Interfaces";
import { DateTime } from 'luxon';
import { SitemapNode } from '@/plugins/sitemap';
import { FormContract } from '@/components/forms/blueprints/form';
import { ColumnDefinitionModel, IMetaModel } from '@/modules/core/common/services/GridService';
import { ListItemModel } from '@/modules/logito/staff/services/PeopleService';
import { SimplePartner, UserDisplayModel } from '@/modules/core/dictionaries/services/DictionaryService';
import { merge } from "lodash";
import Pager from "@/helpers/Pager";

export default class ModulesService
{
    public static async createModule(form: CreateModel): Promise<Resource<DetailsModel>>
    {
        return (await axios.post('simple-command/modules', form)).data;
    }

    public static async getDocumentNotification(notificationGuid: string): Promise<any>
    {
        return (await axios.get(`simple-fetch/document-notification/${notificationGuid}`)).data?.result;
    }

    public static async createDocumentNotification(moduleId: string, documentGuid: string, form: any): Promise<any>
    {
        return (await axios.post(`simple-command/${moduleId}/${documentGuid}/document-notifications`, form)).data;
    }

    public static async updateDocumentNotification(notificationGuid: string, form: any): Promise<any>
    {
        return (await axios.put(`simple-command/document-notification/${notificationGuid}`, form)).data;
    }

    public static async getSharedFields(moduleGuid: string): Promise<SharedFieldsDetailsModel>
    {
        return (await axios.get(`simple-fetch/modules/${moduleGuid}/shared-fields`)).data?.result;
    }

    public static async changeSharedFields(moduleGuid: string, form: any): Promise<any>
    {
        return (await axios.post(`simple-command/modules/${moduleGuid}/shared-fields`, form)).data;
    }

    public static async editModule(publicId: string, form: FormModel): Promise<DetailsModel>
    {
        return (await axios.put(`simple-command/modules/${publicId}`, form)).data;
    }

    public static async editModuleBudgetConfig(publicId: string, form: BudgetConfigForm): Promise<Statement>
    {
        return (await axios.post(`simple-command/modules/${publicId}/budget-config`, form)).data;
    }

    public static async editModuleOcrConfig(publicId: string, form: OcrConfigForm): Promise<Statement>
    {
        return (await axios.post(`simple-command/modules/${publicId}/ocr-config`, form)).data;
    }

    public static async editModulePermissionsConfig(publicId: string, form: PermissionsConfigForm): Promise<Statement>
    {
        return (await axios.post(`simple-command/modules/${publicId}/permissions-config`, form)).data;
    }

    public static async getModuleDetails(publicId: string): Promise<Resource<DetailsModel>>
    {
        return (await axios.get(`simple-fetch/modules/${publicId}`)).data;
    }

    public static async getModuleBudgetConfig(publicId: string): Promise<Resource<BudgetConfigForm>>
    {
        return (await axios.get(`simple-fetch/modules/${publicId}/budget-config`)).data;
    }

    public static async getModulesSitemap(): Promise<SitemapNode[]>
    {
        return (await axios.get('modules/sitemap')).data;
    }

    // public static async fetchForm(licence: string, id: string): Promise<FormContract>
    // {
    //     return (await axios.get(`simple-schema/${licence}/bpmn-start/${id}`)).data;
    // }

    public static async fetchForm(url: string): Promise<FormContract>
    {
        return (await axios.get(`simple-schema/${url}`)).data;
    }

    // public static async fetchFormWithId(licence: string, publicId: string, id: string): Promise<FormContract>
    // {
    //     return (await axios.get(`simple-schema/${licence}/${publicId}/bpmn-start/${id}`)).data;
    // }

    public static async saveForm(licence: string, id: string, model: Record<string, unknown>): Promise<Resource<{ publicId: string}>>
    {
        return (await axios.post(`simple-command/${licence}/${id}/bpmn-start`, model)).data;
    }

    public static async saveFormOffice(licence: string, publicId: string, id: string, model: Record<string, unknown>): Promise<Resource<{ publicId: string}>>
    {
        return (await axios.post(`simple-command/${licence}/${publicId}/${id}/bpmn-office-start`, model)).data;
    }

    public static async generateDoc(licence: string, templateId: string, id: string, model: Record<string, unknown>): Promise<any>
    {
        return (await axios.post(`simple-command/${licence}/${id}/${templateId}/generate`, model)).data;
    }

    public static async generateDocEdit(publicId: string, licence: string, templateId: string, id: string, model: Record<string, unknown>): Promise<any>
    {
        return (await axios.post(`simple-command/${licence}/${publicId}/${id}/${templateId}/generate`, model)).data;
    }

    public static async fetchDetailsData(licence: string, id: string): Promise<Resource<Record<string, unknown>>>
    {
        return (await axios.get(`simple-fetch/${licence}/${id}`)).data;
    }

    public static async fetchDetailsSchema(licence: string, id: string): Promise<ColumnDefinitionModel>
    {
        return (await axios.get(`simple-schema/${licence}/${id}`)).data;
    }

    public static async exportElement(moduleId: string, documentGuid: string, templateGuid: string): Promise<any>
    {
        return (await axios.get(`simple-fetch/${moduleId}/${documentGuid}/${templateGuid}`, { responseType: "blob" })).data;
    }

    public static async fetchUser(id: string): Promise<ListItemModel>
    {
        return (await axios.get(`staff/user/${id}`)).data;
    }

    public static async fetchArchiveData(licence: string, documentId: string, historyId: number): Promise<ArchiveData>
    {
        return (await axios.get(`${licence}/${documentId}/archive-data/${historyId}`)).data;
    }

    public static async fetchGenerationTemplates(licence: string, endpointId: string): Promise<Record<string, IMetaModel>>
    {
        return (await axios.get(`${licence}/generation-templates/${endpointId}`)).data;
    }

    public static async fetchGenerationTemplatesForEdit(licence: string, publicId: string, endpointId: string): Promise<Record<string, IMetaModel>>
    {
        return (await axios.get(`${licence}/${publicId}/generation-templates/${endpointId}`)).data;
    }

    public static async fetchDocument(licence: string, formId: string, actionName: string, blueprintName: string, filter: Record<string, string>, pager: Pager): Promise<Pagination<Resource<DynamicDocument>>>
    {
        const endpoint = `simple-dictionary/${licence}/form/${formId}/${actionName}/${blueprintName}`;

        return (await axios.get(endpoint, {
            params: merge({}, filter, pager.data())
        })).data;
    }

    public static async fetchDefinition(licence: string, documentPublicId: string): Promise<any>
    {
        return (await axios.get(`${licence}/${documentPublicId}/details-schema`)).data;
    }

    public static async toggleIsPrivate(licence: string, documentId: string): Promise<Statement>
    {
        return (await axios.post(`simple-command/${licence}/${documentId}/toggle-private`, {})).data;
    }

    public static async generateDocs(moduleId: string): Promise<any>
    {
        return (await axios.get(`modules/${moduleId}/docs`, { responseType: "blob" })).data;
    }

    public static async reindexModule(moduleId: string): Promise<any>
    {
        return (await axios.get(`modules/admin/${moduleId}/reindex`)).data;
    }

    public static async reindexAllModules(): Promise<any>
    {
        return (await axios.get(`modules/admin/reindex-all`)).data;
    }

    public static async translateModule(moduleId: string, form: TranslateModuleForm): Promise<Statement>
    {
        return (await axios.post(`modules/${moduleId}/translate`, form)).data;
    }

    public static async markAsSeen(licence: string, documentId: string): Promise<Statement>
    {
        return (await axios.get(`${licence}/${documentId}/mark-as-seen`)).data;
    }
}

export interface FormModel {
    name: Record<string, string>;
    shortName: Record<string, string>;
    description: Record<string, string>;
    activeToUtc: DateTime;
    showInOffice: boolean;
}

export interface CreateModel extends FormModel {
    licence: string | KeyValuePair
}

export interface DetailsModel extends CreateModel {
    id: number;
    publicId: string;
    budgetConfig: BudgetConfigForm;
    ocrConfig: OcrConfigForm;
    permissionsConfig: PermissionsConfigForm;
}

export interface FilterModel {
    licence: string | KeyValuePair,
    name: string,
}

export interface ArchiveData
{
    schema: ColumnDefinitionModel,
    data: Resource<Record<string, unknown>>
}

export interface DynamicDocument {
    id: string,
    module: string,
    title: string,
    company: string,
    register: string,
    contractor: SimplePartner,
    dueDate: DateTime,
    amount: number,
    signature: string,
    dateCreatedUtc: DateTime,
    createdBy: UserDisplayModel
}

export interface BudgetConfigForm {
    validationAmountField: string | KeyValuePair;
    validationDateField: string | KeyValuePair;
    validationStatuses: number[] | KeyValuePair[];
    enabled: boolean;
}

export interface ModuleFeatures
{
    costs: boolean;
}

export interface OcrConfigForm
{
    enableFileSearch: boolean;
    fileSearchExtensions: string;
    runOcr: boolean;
    ocrAttachmentFields: string[] | KeyValuePair[]
}

export interface PermissionsConfigForm
{
    inheritByRelated: boolean;
    shareByAuthor: boolean;
    shareByTargets: boolean;
    shareByAny: boolean;
}

export interface TranslateModuleForm
{
    language: string;
}

export interface SharedFieldsDetailsModel
{
    fields: KeyValue<string, KeyValuePair>[];
}
