import { axios } from '@/plugins/axios';
import { KeyValuePair, Pagination, Resource, Statement } from "@/helpers/Interfaces";
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import { DetailsContract } from "@/components/dynamic-details/blueprints/details";

export default class FieldConfigsService
{
    public static async editFieldConfig(publicId: string, form: FormModel): Promise<DetailsModel>
    {
        return (await axios.put(`simple-command/field-config/${publicId}`, form)).data;
    }

    public static async linkFields(moduleId: string, form: LinkFieldsModel): Promise<Statement>
    {
        return (await axios.post(`simple-command/modules/${moduleId}/field-configs-link-fields`, form)).data;
    }

    public static async unlinkFields(publicId: string, form: UnlinkModel): Promise<Statement>
    {
        return (await axios.post(`simple-command/field-config/${publicId}/unlink`, form)).data;
    }

    public static async getFieldConfigDetails(publicId: string): Promise<Resource<DetailsModel>>
    {
        return (await axios.get(`simple-fetch/field-config/${publicId}`)).data;
    }

    public static async getFieldConfigDefaultSettings(moduleId: string, schemaType: string, listType?: string): Promise<Record<string, FieldModel>>
    {
        return (await axios.get(`modules/${moduleId}/field-schemas/${schemaType}${listType ? '/'+ listType : ''}`)).data;
    }

    public static async saveDefaultSettings(form: FormModelDefaultSettings, moduleId: string, schemaType: string, listType?: string): Promise<Statement>
    {
        return (await axios.put(`modules/${moduleId}/field-schemas/${schemaType}${listType ? '/'+ listType : ''}`, form)).data;
    }

    public static async getDefaultSettingsDetails(moduleId: string, processId: string): Promise<FormModelDefaultSettingsDetails>
    {
        return (await axios.get(`modules/${moduleId}/details-schemas/${processId ?? ""}`)).data;
    }

    public static async saveDefaultSettingsDetails(moduleId: string, form: FormModelDefaultSettingsDetails, processId: string): Promise<FormModelDefaultSettingsDetails>
    {
        return (await axios.post(`modules/${moduleId}/details-schemas/${processId ?? ""}`, form)).data;
    }

    public static async getProcessOptions(moduleId: string): Promise<Pagination<Resource<KeyValuePair>>>
    {
        return (await axios.get(`modules/${moduleId}/details-schemas/processes`)).data;
    }
}

export type SchemaType = "grid" | "filter";

export interface FormModel
{
    visibleInList: boolean;
    frontName: Record<string, string>;
    indexName: string;
    isGrouped: boolean;
    linkedFields: string[];
}

export interface FormModelDefaultSettings
{
    fields: Record<string, any>;
}

export interface LinkFieldsModel
{
    fields: KeyValuePair[]
    visibleInList: boolean;
    frontName: Record<string, string>;
}

export interface DetailsModel
{
    publicId: string;
    frontName: string;
    visibleInList: boolean;
    indexName: string;
}

export enum FieldType {
    FormFields,
    GroupedFields,
    SystemFields
}

export interface FieldModel {
    headerName: string;
    headerNameDictionary: Record<string, string>
    order: number;
    visibility: boolean;
    type?: string;
    formName: string
    fieldType: FieldType;
}

export interface FormModelDefaultSettingsDetails
{
    definition: DetailsContract;
}

export interface UnlinkModel {
    priorityField: KeyValuePair | string;
}

export interface DocumentFieldFilter {
    search: string;
    form: KeyValuePair | null;
}
