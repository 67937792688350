<script lang="ts" setup>
import { computed } from 'vue';
import FieldChoice from './FieldChoice.vue';
import FieldCondition from './FieldCondition.vue';
import FieldText from './FieldText.vue';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';

defineOptions({
    name: 'field-error',
    components: {
        'field-choice': FieldChoice,
        'field-condition': FieldCondition,
        'field-text': FieldText
    }
});

const props = defineProps({
  "blueprint": null,
  "form": null,
  "label": { default: null }
});
const blueprint = computed(() => props.blueprint);

const customErrorOptions: Option<NeverChoice | WhenChoice>[] = [
    { value: NeverChoice.Never, text: '[[[Nigdy]]]' },
    { value: WhenChoice.When, text: '[[[Warunkowo]]]' }
];

const isWhenCondition = computed(() => props.blueprint.customError == WhenChoice.When);
</script>

<template>
    <div>
        <field-choice v-model="blueprint.customError" :options="customErrorOptions" :label="label || $t('[[[Niestandardowy błąd]]]')" />
        <field-condition v-model="blueprint.customErrorWhen" :label="label" :show-label="false" :form="form" :when="blueprint.customError" />
        <ideo-form-localize v-if="isWhenCondition" v-slot="{ locale }">
            <field-text v-model="blueprint.customErrorMessage[locale]" :label="$t('[[[Komunikat błędu]]]')" :placeholder="$t('[[[Komunikat błędu]]]')" />
        </ideo-form-localize>
    </div>
</template>
