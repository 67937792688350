import { KeyValuePair } from '@/helpers/Interfaces';

enum ComponentTypeEnum {
    DateTime = 'DateTime',
    UserAvatar = 'UserDisplayModel',
    StatusKeyValuePair = 'StatusKeyValuePair',
    Money = 'Money',
    Address = 'AddressListItemModel',
    GenericKeyValuePair = 'GenericKeyValuePair',
    Boolean = 'Boolean',
    Decimal = 'Decimal',
    MainFile = 'MainFileModel',
    Partner = 'SimplePartner',
    UserAssingment = 'AssingmentUserDisplayModel',
    Duration = 'DurationModel',
    ResourceConfidential = 'ResourceConfidential',
    IControlAggregate = 'IControlAggregate',
    UserGroupRawArray = 'UserGroupRaw[]',
    ExchangeRateFront = 'ExchangeRateFront',
    TableVatPresenter = 'Ideo.Logito.App.VatTableLine[]',

    // New
    UserDisplayModelArray = 'UserDisplayModel[]',
    ActiveTargetFrontArray = 'ActiveTargetFront[]',
    SimplePartnerArray = 'SimplePartner[]',
    GenericKeyValuePairArray = 'GenericKeyValuePair[]',
    DynamicDictionaryFrontArray = 'DynamicDictionaryFront[]',
    ResourceConfidentialArray = 'ResourceConfidential[]',
    DynamicDocumentFront = 'DynamicDocumentFront',
    DynamicDocumentFrontArray = 'DynamicDocumentFront[]',
    RichText = 'RichText',
    ContractorDetailsArray = 'Ideo.Core.App.Areas.Office.Outgoings.Models.ContractorDetails[]',
    ContractorWithShipmentDetailsArray = 'Ideo.Core.App.Areas.Office.Outgoings.Models.ContractorWithShipmentDetails[]',
    TimeSpan = 'TimeSpan',
    String = 'String',
    List = 'List`1',
}

enum BadgeTypesEnum {
    Active = 'active',
    Deadline = 'deadline',
}

export { BadgeTypesEnum, ComponentTypeEnum };

enum DateFormatterEnum {
    DateTime = 'DateTime',
}

export { DateFormatterEnum };

const ResultStatus: KeyValuePair[] = [
    { key: 'Success', value: 'success' },
    { key: 'Failed', value: 'danger' },
    { key: 'Locked', value: 'warning' },
];

export { ResultStatus };

enum EventType {
    Edit = 'edit',
    Delete = 'delete',
    FlowCanAccept = 'flow-canAccept',
    FlowCanSend = 'flow-canSend',
    FlowCanReject = 'flow-canReject',
}

enum FrontAction {
    // Dynamic modules
    FlowModel = 'FlowModel',
    ReturnModel = 'ReturnModel',
    ToOwnerModel = 'ToOwnerModel',
    Object = 'Object',
    FinishDescriptionModel = 'FinishDescriptionModel',
    SignDocumentSimplySign = 'SignDocument-SimplySign',
    SignDocumentMSzafir = 'SignDocument-MSzafir',
    SetTargetsModal = 'SetTargetsModal',
    ResumeModal = 'ResumeModal',
    Subprocess = 'Subprocess',
    ChangeProcess = 'ChangeProcessModal',

    // Static modules
    FireEditCreateModal = 'FireEditCreateModal',
    FireImportModal = 'FireImportModal',
    FireDeleteModal = 'FireDeleteModal',
    ModuleCreate = 'ModuleCreate',
    EntityCreate = 'EntityCreate',
    Redirect = 'Redirect',
    FireFlowAcceptModal = 'FireFlowAcceptModal',
    FireFlowRejectModal = 'FireFlowRejectModal',
    FireFlowSendModal = 'FireFlowSendModal',
    FireTranslateModal = 'Translate',
    MarkAsVisible = 'MarkAsVisible',
    MarkAsNotVisible = 'MarkAsNotVisible',
}

enum FrontActionModal {
    // Dynamic modules
    FlowModel = 'bpmn-accept-modal',
    ReturnModel = 'bpmn-return-modal',
    ToOwnerModel = 'bpmn-owner-modal',
    Object = 'bpmn-dynamic-modal',
    Search = 'bpmn-search-modal',
    FinishDescriptionModel = 'bpmn-finish-description-modal',
    SignDocumentSimplySign = 'bpmn-sign-document-simply-sign-modal',
    SignDocumentMSzafir = 'bpmn-sign-document-mszafir-modal',
    SetTargetsModal = 'bpmn-set-targets-modal',
    ResumeModal = 'bpmn-resume-modal',
    ChangeProcessModal = 'bpmn-change-process-modal',

    // Static modules
    EditCreate = 'edit-create-modal',
    Delete = 'delete-modal',
    FlowAccept = 'accept-modal',
    FlowReject = 'reject-modal',
    FlowSend = 'send-modal',
    Import = 'import-modal',
    EDoreczeniaSearchEngine = 'search-engine-modal',
    TranslateModal = 'translate-modal'
}

enum ViewType {
    Create = 'create',
    Form = 'form',
    Acceptance = 'acceptance',
    Rejection = 'rejection',
    Return = 'return',
    Import = 'import',
    Resume = 'resume',
    Redirect = 'redirect',
    SetTargets = 'settargets',
    Edit = 'edit',
    Delete = 'delete',
    FlowCanAccept = 'flow-canAccept',
    FlowCanReject = 'flow-canReject',
    FlowCanSend = 'flow-canSend',
    Translate = 'translate',
    ChangeProcess = 'changeprocess'
}
export { EventType, FrontAction, FrontActionModal, ViewType };
