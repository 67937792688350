import descriptionTemplates from "./descriptionTemplates";
import descriptionTemplateForm from "./descriptionTemplateForm";

export default {
    finance: {
        namespaced: true,
        modules: {
            descriptionTemplates,
            descriptionTemplateForm
        },
    },
};
