<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';
import { getVariantColor } from '@/components/common/dynamic-grid/helpers/statusColorHelper';

@Options({
    name: 'GenericKeyValuePairArrayFormatter',
})
export default class GenericKeyValuePairArrayFormatter extends Vue
{
    @Prop({ default: (): KeyValuePair[] => [] }) public property: KeyValuePair[];
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;

    public get isBadge(): boolean
    {
        const badge = this.features.find((feature) => feature.key === 'Badge');

        return !!badge;
    }

    public isJSon(jsonString: string): boolean
    {
        try
        {
            const parsed = JSON.parse(jsonString);

            if (parsed && typeof parsed === 'object') return true;
        }
        catch (e)
        {
            return false;
        }
    }

    public getVariant(item: KeyValuePair): string
    {
        const type = this.features.find((elem) => elem.key === 'Badge');
        let func = '';

        if (type && this.isJSon(type.name))
        {
            func = JSON.parse(type.name).func;
        }
        else func = type.value;

        return getVariantColor(item.key, func);
    }

    public getContent(item: KeyValuePair): string
    {
        return item.value;
    }
}
</script>

<template>
    <div v-if="Array.isArray(property)">
        <div v-for="item in property" :key="item.key">
            <span v-if="!isBadge">{{ getContent(item) }}</span>
            <ideo-badge v-else :variant="getVariant(item)" class="status-badge" :title="getContent">
                {{ getContent(item) }}
            </ideo-badge>
        </div>
    </div>
    <span v-else>-</span>
</template>
