import FieldText from './FieldText.vue';
import FieldTabs from './FieldTabs.vue';
import FieldCheckbox from './FieldCheckbox.vue';
import FieldTextarea from './FieldTextarea.vue';
import FieldSelect from './FieldSelect.vue';
import FieldVisible from './FieldVisible.vue';
import FieldFormat from './FieldFormat.vue';
import FieldName from './FieldName.vue';

export { FieldText, FieldTabs, FieldCheckbox, FieldTextarea, FieldSelect, FieldVisible, FieldFormat, FieldName };

export default {
    FieldText,
    FieldTabs,
    FieldCheckbox,
    FieldTextarea,
    FieldSelect,
    FieldVisible,
    FieldFormat,
    FieldName,
};
