<script lang="ts" setup>
import { computed } from 'vue';
import FieldText from '../properties/FieldText.vue';
import { AffixValue } from '@/components/builder/form/types/AffixValue';

defineOptions({
    name: 'field-affix',
    components: {
        'field-text': FieldText
    }
});

const props = defineProps({
  "append": { type: Boolean, default: true },
  "label": { default: null },
  "modelValue": null,
  "prepend": { type: Boolean, default: true }
});

const modelValue = computed(() => props.modelValue);
</script>

<template>
    <div class="row">
        <div class="col-6">
            <field-text v-model="modelValue.prepend" :label="$t('[[[Przedrostek]]]')" v-if="prepend" />
        </div>
        <div class="col-6">
            <field-text v-model="modelValue.append" :label="$t('[[[Przyrostek]]]')" v-if="append" />
        </div>
    </div>
</template>
