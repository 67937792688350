<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';
import { getColor } from '@/modules/core/home/inc/helpers';
import { TimetableItems, TimetableDocument } from '@/modules/core/common/services/GridService';
import { $i18n } from '@/plugins/localization';

const props = defineProps({
  "licence": null,
  "items": null,
  "settings": null,
  "readOnly": { type: Boolean,  }
});

const router = useRouter();

const today = ref(DateTime.utc().toFormat('yyyy-MM-dd'));

const days = computed(() =>
{
    const start = DateTime.fromISO(props.settings.start);
    const end = DateTime.fromISO(props.settings.end);
    const diff = end.diff(start, 'days').toObject().days;

    return Array.from({ length: diff + 1 }, (_, i) => start.plus({ days: i }).toFormat('yyyy-MM-dd'));
});

const dayName = (value: string, format: string = 'yyyy-MM-dd') =>
{
    if (!value || !format) return '';

    const day = new Date(value).toLocaleDateString($i18n.shortLocale(), { weekday: 'short'  });

    return (day.charAt(0).toUpperCase() + day.slice(1)).slice(0, 2);
};

const getDocument = (_documents: TimetableDocument[], day: string) =>
{
    const documents = _documents.filter((document) =>
    {
        const dateFromISO = document.dateFromUtc.setZone('local').toISODate();
        const dateToISO = document.dateToUtc.setZone('local').toISODate();

        const fromDate = DateTime.fromISO(dateFromISO);
        const toDate = DateTime.fromISO(dateToISO);
        const currentDate = DateTime.fromISO(day);

        return currentDate >= fromDate && currentDate <= toDate;
    });

    return documents;
};

const getTooltip = (doc: TimetableDocument) =>
{
    const title = doc.title || '';
    const description = doc.description
        .filter((item) => item.value)
        .map((item) => `${item.key}: ${item.value || '-'}\n`)
        .join('');

    return `${title}${title && description ? `\n\n` : ''}${description}`;
};

const onRowClick = (item: any) =>
{
    if (!item.publicId || props.readOnly) return;

    router.push({
        name: 'module-form-details',
        params: { licence: props.licence, publicId: item.publicId },
    });
};
</script>

<template>
    <div class="scroll mb-2">
        <table class="timetable">
            <thead>
                <tr>
                    <th class="sticky-top"></th>
                    <th
                        v-for="(day, index) in days"
                        :key="index"
                        class="sticky-top"
                        :class="[
                            {
                                active: $filters.date(day, 'yyyy-MM-dd') === $filters.date(today, 'yyyy-MM-dd'),
                            },
                            dayName(day),
                        ]"
                    >
                        {{ dayName(day) }}<br />
                        {{ $filters.date(day, 'dd') }}
                    </th>
                </tr>
            </thead>
            <tbody v-for="item in items" :key="item.key">
                <tr>
                    <td colspan="100%" class="py-2 text-center text-uppercase">
                        <strong>{{ item.value }}</strong>
                    </td>
                </tr>
                <tr v-for="el in item.groups" :key="el.key" class="user-row">
                    <td class="ts-col">
                        <div class="d-flex">
                            <span class="ml-2">{{ el.value }}</span>
                        </div>
                    </td>
                    <td v-for="day in days" :key="day" class="ts-time-col" :class="dayName(day)">
                        <template v-if="getDocument(el.documents, day).length">
                            <div class="btn-wrapper">
                                <ideo-tooltip
                                    v-for="(doc, index) in getDocument(el.documents, day)"
                                    :key="`${doc.publicId}${index}`"
                                    :tooltip="getTooltip(doc)"
                                    :multiline="true"
                                    position="top"
                                    class="btn-tooltip"
                                >
                                    <row-actions
                                        v-if="!readOnly"
                                        :endpoint="`${licence}/${doc.publicId}/actions`"
                                        :id="doc.publicId"
                                        :url="licence"
                                        :style="{
                                            color: getColor(doc.color),
                                            background: getColor(doc.color, true),
                                        }"
                                        :except="[
                                            'document-notifications-create',
                                            'canManageParticipants',
                                            'canUploadOneDrive',
                                            'bpmn-set-targets',
                                            'canSetPrivate',
                                        ]"
                                        class="flex-fill rounded-0 border-0 btn-document"
                                        :options="{
                                            calendar: true,
                                            dropdown: { position: 'left' },
                                        }"
                                    >
                                        <template #button-content>
                                            {{ $filters.date(day, 'dd') }}
                                        </template>
                                        <template #default>
                                            <ideo-dropdown-item-button @click="onRowClick(doc)">
                                                <i class="fas fa-square-list"></i>
                                                <span>{{ $t('[[[Szczegóły]]]') }}</span>
                                            </ideo-dropdown-item-button>
                                        </template>
                                    </row-actions>
                                </ideo-tooltip>
                            </div>
                        </template>
                        <span v-else>{{ $filters.date(day, 'dd') }}</span>
                    </td>
                </tr>
            </tbody>
            <tbody v-if="!items.length">
                <tr>
                    <td colspan="100%" class="text-center">
                        <strong>{{ $t('[[[Brak wyników]]]') }}</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
.timetable {
    display: contents;
    width: 100%;
    word-wrap: break-word;
    border: 0;
    font-size: 13px;

    .ts-col {
        min-width: 185px;
        text-align: left;
    }

    thead {
        td,
        th {
            padding: 10px;
            width: 100%;
            line-height: 1.2;
            text-align: center;
            background: var(--bs-gray-200);
            border: 1px solid var(--bs-gray-300);
            font-weight: bold;
            border-left: 0px;

            &:first-child {
                border-left: 1px solid var(--bs-gray-300);
            }

            &.sticky-top {
                z-index: 9;
            }

            &.active {
                background: var(--bs-primary);
                color: var(--bs-white);
                border-color: var(--bs-primary);
            }
        }
    }

    tbody {
        tr {
            height: 35px;

            &:hover {
                background: var(--bs-light);
            }
        }
    }

    td {
        padding: 6px 12px;
        border: 1px solid var(--bs-gray-300);
        border-top: 0px;
        border-left: 0px;

        &:first-child {
            border-left: 1px solid var(--bs-gray-300);
        }

        @media screen and (max-width: 1440px) {
            padding: 5px;
        }

        &.active {
            background: var(--body-bg);
        }
    }

    .ts-time-col {
        position: relative;
        text-align: center;
        font-size: 10px;

        .btn-wrapper {
            margin: -6px -12px;
            display: flex;
            flex-direction: column;
            min-height: 34px;

            @media screen and (max-width: 1440px) {
                margin: -5px -5px;
            }

            .btn-tooltip {
                width: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                font-size: 0.8125rem;
            }

            .btn-document {
                width: 100%;
                flex-shrink: 0;
                border: 0;
                font-weight: bold;
                font-size: 0.6875rem;
                width: 100%;
                min-height: 25px;
                flex-grow: 1;
            }
        }
    }

    .S,
    .N {
        background: var(--bs-gray-200);
        color: var(--bs-gray-400);
    }
}
</style>
