<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { SimplePartner } from '@/modules/core/dictionaries/services/DictionaryService';
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';

@Options({
    name: 'SimplePartnerFormatter',
})
export default class SimplePartnerFormatter extends Vue
{
    @Prop({ default: null }) public property: SimplePartner;
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;

    public canSeeDetails: boolean = false;

    public async created(): Promise<void>
    {
        this.canSeeDetails =
            (await this.$permissions.any(['Ideo.Logito.Crm.CrmPartnerPermissions@Access'])) && !this.isOnGrid;
    }
}
</script>

<template>
    <div v-if="property">
        <RouterLink v-if="canSeeDetails" :to="{ name: 'crm-bs-partners-details', params: { publicId: property.key } }">
            {{ property.value }}
        </RouterLink>
        <span v-else>{{ property.value }}</span>
    </div>
    <div v-else>-</div>
</template>
