<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { KeyValue, KeyValuePair } from '@/helpers/Interfaces';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { CompanyEntry, CompanyType, instaceOfCompanyEntry } from '.';
import DataSourcesService from '@/modules/studio/data-sources/services/DataSourcesService';
import properties from '../../properties';

defineOptions({
    name: 'company-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const entryData = ref(new CompanyEntry()) as Ref<CompanyEntry>;
const defaultValue = ref(null) as Ref<KeyValuePair>;
const defaultValueOptions = ref([]) as Ref<KeyValuePair[]>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instaceOfCompanyEntry, props.index);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value;
    },
    set(value: KeyValue<string, string> | null)
    {
        entryData.value.data = value;
    },
});

const blueprint = computed(() => props.blueprint);
const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const required = computed(() => props.form.expressions.required(props.blueprint));
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));
const design = computed(() => props.form.designMode());
const endpoint = computed(() =>
{
    if (design.value) return null;

    if (props.entry.fieldIndex)
    {
        return `modules/${props.entry.moduleId}/table-templates/select-options/${props.entry.fieldIndex}/${blueprint.value.name}`;
    }

    return `simple-dictionary/${props.entry.module}/form/${props.entry.formId}/${props.entry.actionName}/${blueprint.value.name}`;
});
const isStart = computed(() => props.entry.actionName === 'bpmn-start');

const fetchDefaultCompany = async (): Promise<void> =>
{
    if (blueprint.value.defaultValue != null)
    {
        if (design.value)
        {
            const result = await DataSourcesService.fetchDefaultCompanySelect(blueprint.value.defaultValue);

            if (result.length > 0)
                defaultValue.value = result[0];
        }
        else if (value.value == null)
        {
            const result = await DataSourcesService.fetchDefaultCompanySelect(blueprint.value.defaultValue);

            if (result.length > 0)
                value.value = result[0];
        }
    }
};

const fetchDefaultCompanyOptions = async (): Promise<void> =>
{
    if (design.value)
    {
        const result = await DataSourcesService.fetchDefaultCompanySelect();

        defaultValueOptions.value = result;
    }
};

const onDefaultValueUpdate = (): void =>
{
    if (defaultValue.value != null)
    {
        blueprint.value.defaultValue = defaultValue.value.key;
    }
};

fetchDefaultCompanyOptions();
fetchDefaultCompany();
</script>

<template>
    <form-component-wrapper class="company-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <ideo-select
                    v-model="value"
                    :id="blueprint.id"
                    :disabled="readonly"
                    :placeholder="placeholder"
                    :endpoint="endpoint"
                    :data-test="blueprint.id"
                    :preselect-when-one-option="isStart"
                />
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <ideo-form-group :label="$t('[[[Wartość domyślna]]]')">
                <ideo-select
                    v-model="defaultValue"
                    :options="defaultValueOptions"
                    @update:modelValue="onDefaultValueUpdate()"
                />
            </ideo-form-group>
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>
