<template>
    <div :class="customClass">
        <slot name="default"></slot>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-button-group'
})
export default class IdeoButtonGroup extends Vue
{
    @Prop({
        default: 'md',
        validator: (value: string) => ['sm', 'md', 'lg'].includes(value)
    })
    public size: string;

    @Prop({ default: false })
    public vertical: boolean;

    public get customClass(): Record<string, boolean>
    {
        return {
            'btn-group': true,
            'btn-group-sm': this.size == 'sm',
            'btn-group-lg': this.size == 'lg',
            'btn-group-vertical': this.vertical !== false
        };
    }
}
</script>
