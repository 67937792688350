<template>
    <div class="h-100 d-flex align-items-center">
        <ideo-form-checkbox :modelValue="modelValue" @change="valueChanged">
            {{ label }}
        </ideo-form-checkbox>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'FiltersCheckbox',
    emits: ['update:modelValue']
})
export default class FilterBasicInput extends Vue
{
    @Prop({ default: "" })
    public modelValue: boolean;

    @Prop({ default: "" })
    public label: string;

    public valueChanged(value: boolean): void
    {
        this.$emit("update:modelValue", value);
    }
}
</script>
