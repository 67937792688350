import { axios } from '@/plugins/axios';
import Pager from '@/helpers/Pager';
import { KeyValuePair, Pagination, Resource } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';
import { merge } from 'lodash';

export default class PeopleService
{
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('simple-query/staff', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    public static async getOrganizations(filter: OrganizationsFilterModel, pager: Pager): Promise<Pagination<ListItemModel>>
    {
        return (await axios.get<Pagination<ListItemModel>>('simple-query/staff-organizations', {
            params: merge({}, filter, pager.data())
        })).data;
    }
}

export interface FilterModel
{
    searchText: string;
    organizationId: number;
    showInactive: boolean;
}

export interface ListItemModel
{
    publicId: string;
    name: string;
    dateModifiedUtc: DateTime;
    isActive: boolean;
    jobPosition: KeyValuePair,
    unit: KeyValuePair,
    roomNumber: string,
    cellNumber: string,
    phoneNumber: string,
    email: string,
    facePicture?: any
}

export interface OrganizationsFilterModel
{
    Expanded: string;
}
