import { defineAsyncComponent, Plugin } from 'vue';
import { components, types } from './Definitions';

// Builder
import { Blueprint } from '../builder/base/blueprints/Blueprint';
import { DetailsBlueprintFactory } from '../builder/details/services/DetailsBlueprintFactory';
import { IdentityCallback } from '../builder/base/types/Identity';
import { DetailsBuilder, DetailsBuilderContract } from '../builder/details';

export class FluentDetailsBuilder
{
    private builder: DetailsBuilder;
    private options: {
        blueprint?: () => Blueprint,
        blueprintId?: IdentityCallback,
    };

    public constructor(builder: DetailsBuilder)
    {
        this.builder = builder;
        this.options = {};
    }

    public static setup(design: boolean = false, internal: boolean = false) : FluentDetailsBuilder
    {
        const blueprintFactory = new DetailsBlueprintFactory(types);
        const detailsBuilder = new DetailsBuilder(blueprintFactory);

        detailsBuilder.design = design;
        detailsBuilder.internal = internal;

        return new FluentDetailsBuilder(detailsBuilder);
    }

    public blueprint(blueprint: () => Blueprint, blueprintId: IdentityCallback = () => 0): FluentDetailsBuilder
    {
        this.options.blueprint = blueprint;
        this.options.blueprintId = blueprintId;

        return this;
    }

    public make(): DetailsBuilderContract
    {
        this.builder.setBlueprint(this.options.blueprint);
        this.builder.setBlueprintIdentity(this.options.blueprintId);
        this.builder.update();

        return this.builder;
    }
}

const DynamicFormsPlugin: Plugin =
{
    install(app)
    {
        app.component('DynamicDetailsDesignPanel', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DesignPanel.vue')));
        app.component('DynamicDetailsDesignPlaceholder', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DesignPlaceholder.vue')));
        app.component('DynamicDetailsDesignRuller', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DesignRuller.vue')));
        app.component('DynamicDetailsDesignToolbar', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DesignToolbar.vue')));
        app.component('DynamicDetailsComponent', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DetailsComponent.vue')));
        app.component('DynamicDetailsComponentWrapper', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DetailsComponentWrapper.vue')));
        app.component('DynamicDetailsDesignStatus', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DesignStatus.vue')));
        app.component('DynamicDetailsToolbox', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DesignPanelTarget.vue')));
        app.component('DynamicDetailsAccordion', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DetailsAccordion.vue')));
        app.component('DynamicDetailsAccordionItem', defineAsyncComponent(() => import('@/components/dynamic-details/helpers/DetailsAccordionItem.vue')));

        components.forEach((component: any) =>
        {
            app.component(`DynamicDetails${component.default.name}`, component.default);
        });
    }
};

export default DynamicFormsPlugin;

