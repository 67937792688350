<script lang="ts" setup>
import { computed } from 'vue';
import { Definition, DateType } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { ApiQueryCondition, KeyElement, RangeQueryCondition } from '@/helpers/ApiQueryBuilder';
import IdeoFilterDatetime from '@/components/filters/types/IdeoFilterDatetime.vue';

defineOptions({
    name: `${Definition.type}-filter`
});

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});

const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as RangeQueryCondition);
const entry = computed<{gte?: string, lte?: string}>({
    get()
    {
        if (condition.value)
        {
            return {
                gte: condition.value.range.gte as string,
                lte: condition.value.range.lte as string
            };
        }

        return {
            gte: null,
            lte: null
        };
    },
    set(value)
    {
        if (condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                range: {
                    field: component.value.name
                }
            });
        }

        condition.value.range.gte = value.gte ? value.gte : null;
        condition.value.range.lte = value.lte ? value.lte : null;
    }
});
const gte = computed({
    get: () => entry.value.gte,
    set: (value) => entry.value = {...entry.value, gte: value}
});
const lte = computed({
    get: () => entry.value.lte,
    set: (value) => entry.value = {...entry.value, lte: value}
});
const type = computed<'datetime'|'date'>(() => component.value.fieldType.toLowerCase() as any);
const label = computed(() => props.builder.localization.translate(component.value.label));

const reset = (): void =>
{
    model.value.remove(p => p.__key == component.value.id);
};
</script>

<template>
    <IdeoFilterDatetime
        v-model:gte="gte"
        v-model:lte="lte"
        :name="component.name"
        :label="label"
        :index="index"
        :value="entry"
        :visible="component.showFilter"
        :type="type"
        :range="true"
        :multi="true"
        @clear="reset"
    />
</template>
