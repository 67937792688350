<script lang="ts" setup>
import { computed } from 'vue';
import { DateTime } from 'luxon';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, DateType } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});

const calendar = computed(() => props.component.fieldType === 'Date');
const format = computed(() => props.component.fieldType === 'DateTime' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');
</script>

<template>
    <span class="d-flex align-items-center" v-if="props.value">
        <i class="fa-light fa-calendar me-2" v-if="calendar"></i>
        <i class="fa-light fa-calendar-clock me-2" v-else></i>
        <span>{{ $filters.datetime(props.value, format) }}</span>
    </span>
    <span v-else>-</span>
</template>

<style scoped>
.fa-calendar-clock, .fa-clock {
    font-size: 16px;
}
</style>
