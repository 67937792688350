<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'BooleanFormatter'
})
export default class BooleanFormatter extends Vue
{
    @Prop()
    public current: boolean;

    @Prop()
    public original: boolean;

    @Prop()
    public definition: ChangelogDefinitionModel;

    public get currentValue(): string
    {
        return this.current ? this.$t("[[[Tak]]]") : this.$t("[[[Nie]]]");
    }

    public get originalValue(): string
    {
        return this.original ? this.$t("[[[Tak]]]") : this.$t("[[[Nie]]]");
    }
}
</script>

<template>
    <div class="small">
        <span class="original-value">{{ originalValue }}</span>
        <span class="current-value">{{ currentValue }}</span>
    </div>
</template>
