<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { StatusModel } from '@/components/common/dynamic-grid/helpers/GridInterfaces';
import { KeyValuePair } from '@/helpers/Interfaces';
import { getVariantColor } from '@/components/common/dynamic-grid/helpers/statusColorHelper';

@Options({
    name: 'StatusWithVariant',
})
export default class StatusWithVariant extends Vue
{
    @Prop({ default: null }) public property: StatusModel;
    @Prop() public features: KeyValuePair[];
    @Prop() public isExpired: boolean;
    @Prop({ default: false }) public isOnGrid: boolean;

    public get getVariant(): string
    {
        const type = this.features.find((elem) => elem.key === 'Badge');
        let func = '';

        if (type)
        {
            func = JSON.parse(type.name).func;
        }

        return this.property?.variant?.key.toLowerCase() || getVariantColor(this.property?.key, func);
    }

    public get getContent(): string
    {
        return this.property?.value || '';
    }
}
</script>

<template>
    <div class="d-flex align-items-center">
        <ideo-badge :variant="getVariant" class="status-badge" :title="getContent">
            {{ getContent }}
        </ideo-badge>
        <ideo-tooltip v-if="isExpired" :tooltip="$t('Przeterminowane')" class="ms-2">
            <i class="fa-solid fa-clock text-danger"></i>
        </ideo-tooltip>
    </div>
</template>

<style scoped>
.status-badge {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
