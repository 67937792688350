import { Resource, Statement, Pagination } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { UserDisplayModel } from '@/modules/core/dictionaries/services/DictionaryService';
import { axios } from '@/plugins/axios';
import { merge } from 'lodash';

export default class CommentsService
{
    public static async saveComment(objectId: string, moduleName: string, comment: string): Promise<Statement>
    {
        return (await axios.post(`simple-command/${moduleName}/${objectId}/comments`, {
            comment: comment
        })).data;
    }

    public static async getCommentsList(objectId: string, moduleName: string, filter: any, pager: Pager): Promise<Pagination<Resource<CommentsListItemModel>>>
    {
        return (await axios.get(`simple-query/${moduleName}/${objectId}/comments`, {
            params: merge({}, filter, pager.data())
        })).data;
    }
}

export interface CommentsListItemModel {
    author: CommentsAuthorModel,
    comment: string;
}

export interface CommentsAuthorModel {
    user: UserDisplayModel;
    impersonator: UserDisplayModel;
    dateUtc: string;
}
