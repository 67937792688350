import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            name: '',
            isActive: false
        }),
        pager: new Pager(1, 20, '', 'ASC')
    }
};
