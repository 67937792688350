import { Blueprint, instanceOfBlueprint } from '../../base/blueprints/Blueprint';
import { NeverChoice } from '../enums/NeverChoice';
import { WhenChoice } from '../enums/WhenChoice';

export interface CustomErrorBlueprint extends Blueprint
{
    customError: NeverChoice | WhenChoice;
    customErrorWhen: string;
    customErrorMessage: Record<string, string>;
}

export const instanceOfCustomErrorBlueprint = (object: any): object is CustomErrorBlueprint =>
{
    return instanceOfBlueprint(object) && 'customError' in object;
};
