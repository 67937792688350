<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Debounce, Prop } from '@/helpers/Decorators';
import { TabsContract } from '../blueprints/tabs';
import { TabContract } from '../blueprints/tab';
import { DetailsBuilderContract } from '@/components/builder/details';
import { Option } from '@/helpers/Interfaces';
import { Definition as HistoryDefinition } from '../blueprints/history';
import { Definition as ChangeLogDefinition } from '../blueprints/change-log';
import { Definition as CommentsDefinition } from '../blueprints/comments';
import { Definition as VerificationNotificationsDefinition } from '../blueprints/verification-notifications';
import { Definition as RelatedDocumentsDefinition } from '../blueprints/related-documents';
import { Definition as OneDriveDefinition } from '../blueprints/one-drive';

@Options({
    name: 'FieldTabs',
})
export default class FieldTabs extends Vue
{
    @Prop() public label: string;
    @Prop({ default: null }) public details!: DetailsBuilderContract;
    @Prop() public blueprint: TabsContract;
    @Prop() public invalidFeedback: (value: string) => string;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get components(): Option[]
    {
        return this.details.schema
            .components(
                [
                    HistoryDefinition.type,
                    ChangeLogDefinition.type,
                    CommentsDefinition.type,
                    VerificationNotificationsDefinition.type,
                    RelatedDocumentsDefinition.type,
                    OneDriveDefinition.type,
                ],
                this.blueprint
            )
            .map((p) => ({ value: p.name, text: p.name }));
    }

    public addTab(item: TabContract): void
    {
        this.$emit('addTab', item);
    }

    public updateTab(value: string, prop: string, id: string, lang?: string): void
    {
        const text = value;
        const index = this.blueprint.components.findIndex((item) => item.id === id);

        this.$emit('updateTab', text, prop, index, lang);
    }

    @Debounce(500)
    public updateTabWithDebounce(value: string, prop: string, id: string, lang?: string): void
    {
        this.updateTab(value, prop, id, lang);
    }

    public deleteTab(item: TabContract): void
    {
        this.$emit('deleteTab', item);
    }

    public updateDefaultTab(tab: string): void
    {
        this.$emit('updateDefaultTab', tab);
    }
}
</script>

<template>
    <ideo-form-localize v-slot="{ locale }">
        <div class="form-group">
            <label v-if="label" :for="uid">{{ $t(label) }}</label>
            <div v-for="(item, index) in blueprint.components" :key="item.id" class="flex-fill">
                <div class="d-flex align-items-start flex-fill flex-shrink-0">
                    <ideo-form-radio
                        :model-value="blueprint.defaultTab"
                        name="default-tab"
                        :value="item.id"
                        class="radio-margin"
                        @update:model-value="updateDefaultTab"
                    ></ideo-form-radio>

                    <div class="d-flex flex-column flex-fill">
                        <div class="d-flex mb-1">
                            <ideo-form-input
                                :model-value="item.text[locale]"
                                type="text"
                                :placeholder="$t('[[[Nazwa zakładki]]]')"
                                @update:model-value="(value: string) => updateTabWithDebounce(value, 'text', item.id, locale)"
                            ></ideo-form-input>

                            <!-- Add tab -->
                            <div class="input-group-append ms-1">
                                <button class="btn btn-outline-light" type="button" @click.prevent="addTab(item)">
                                    <i class="far fa-plus text-success"></i>
                                </button>
                            </div>
                        </div>

                        <div class="d-flex mb-2">
                            <ideo-form-input
                                :model-value="item.icon"
                                type="text"
                                :placeholder="$t('[[[Ikonka]]]')"
                                @update:model-value="(value: string) => updateTabWithDebounce(value, 'icon', item.id)"
                            ></ideo-form-input>

                            <!-- Delete tab -->
                            <div class="input-group-append ms-1">
                                <button
                                    type="button"
                                    class="btn btn-outline-light"
                                    :disabled="blueprint.components.length <= 1"
                                    @click.prevent="deleteTab(item)"
                                >
                                    <i class="far fa-trash text-danger"></i>
                                </button>
                            </div>
                        </div>

                        <ideo-form-checkbox
                            :model-value="item.isCounter"
                            @update:model-value="(value: string) => updateTab(value, 'isCounter', item.id)"
                        >
                            {{ $t('[[[Dodaj licznik]]]') }}
                        </ideo-form-checkbox>

                        <ideo-select
                            v-if="item.isCounter"
                            :model-value="item.counterBindWith"
                            :placeholder="$t('[[[Licznik zależny od]]]')"
                            value-field="value"
                            text-field="text"
                            class="mt-2"
                            :options="components"
                            @update:model-value="(value: string) => updateTab(value, 'counterBindWith', item.id)"
                        ></ideo-select>

                        <hr
                            v-if="blueprint.components.length - 1 !== index"
                            role="separator"
                            aria-orientation="horizontal"
                            class="dropdown-divider my-2"
                        />
                    </div>
                </div>
            </div>
        </div>
    </ideo-form-localize>
</template>

<style lang="scss" scoped>
.input-group-append {
    display: flex;
    flex-direction: column;
}

.radio-margin {
    margin-top: 28px;
}
</style>
