// Managers
import { DocumentManager } from '../form/managers/DocumentManager';
import { ExpressionManager } from '../form/managers/ExpressionManager';
import { DomManager } from '../form/managers/DomManager';
import { DetailsLayoutManager } from './managers/DetailsLayoutManager';
import { DetailsClipboardManager } from './managers/DetailsClipboardManager';
import { LocalizationManager } from './managers/LocalizationManager';

// Services
import { DetailsBlueprintFactory } from './services/DetailsBlueprintFactory';

// Base
import { ContentBuilder, ContentBuilderContract } from '../base';

// --------------------------------------------------

export interface DetailsBuilderContract extends ContentBuilderContract
{
    readonly document: DocumentManager;
    readonly expressions: ExpressionManager;
    readonly dom: DomManager;
    readonly layout: DetailsLayoutManager;
    readonly clipboard: DetailsClipboardManager;
    readonly localization: LocalizationManager;
}

export class DetailsBuilder extends ContentBuilder implements DetailsBuilderContract
{
    private documentManager: DocumentManager;
    private expressionManager: ExpressionManager;
    private domManager: DomManager;
    private localizationManager: LocalizationManager;

    public constructor(blueprintFactory: DetailsBlueprintFactory)
    {
        super(blueprintFactory);

        this.layoutManager = new DetailsLayoutManager(
            blueprintFactory,
            this.eventManager,
            this.schemaManager,
            this.dimensionsManager
        );

        this.clipboardManager = new DetailsClipboardManager(
            this.eventManager,
            this.schemaManager,
            this.layoutManager as DetailsLayoutManager,
            this.dimensionsManager
        );

        this.localizationManager = new LocalizationManager();

        const documentManager = new DocumentManager(
            this.schema
        );
        const expressionManager = new ExpressionManager(
            this.state,
            this.schema,
            documentManager
        );
        const domManager = new DomManager(
            this.schema
        );

        this.documentManager = documentManager;
        this.expressionManager = expressionManager;
        this.domManager = domManager;
    }

    // --------------------------------------------------

    public get document(): DocumentManager
    {
        return this.documentManager;
    }

    public get expressions(): ExpressionManager
    {
        return this.expressionManager;
    }

    public get dom(): DomManager
    {
        return this.domManager;
    }

    public get layout(): DetailsLayoutManager
    {
        return this.layoutManager as DetailsLayoutManager;
    }

    public get clipboard(): DetailsClipboardManager
    {
        return this.clipboardManager as DetailsClipboardManager;
    }

    public get localization(): LocalizationManager
    {
        return this.localizationManager;
    }
}
