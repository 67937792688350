import { KeyValuePair, Statement } from '@/helpers/Interfaces';
import { axios } from '@/plugins/axios';

export default class SimplySignService
{
    public static async getRedirectUrl(publicId: string, endpointId: string, module: string): Promise<string>
    {
        return (await axios.get(`signatures/simply-sign/${module}/${publicId}/${endpointId}/redirectUrl`)).data;
    }

    public static async getAccessToken(code: string): Promise<AccessTokenResponse>
    {
        return (await axios.get(`signatures/simply-sign/accessToken?code=${code}`)).data;
    }

    public static async getUserCards(accessToken: string): Promise<KeyValuePair[]>
    {
        return (await axios.post(`signatures/simply-sign/cards`, { accessToken })).data;
    }

    public static async signDocument(
        publicId: string,
        endpointId: string,
        module: string,
        form: SignDocumentModel
    ): Promise<Statement>
    {
        return (await axios.post(`signatures/simply-sign/${module}/${publicId}/${endpointId}/sign`, form)).data;
    }

    public static async getDocument(licence: string, documentGuid: string): Promise<Blob>
    {
        return (await axios.get(`${licence}/${documentGuid}/document-attachment`, { responseType: 'blob' })).data;
    }
}

export enum SignatureProviders {
    SimplySign,
}

export interface AccessTokenResponse {
    accessToken: string;
    tokenType: string;
    expiresIn: number;
    refreshToken: string;
}

export interface SignDocumentModel {
    accessToken: string;
    pin: string;
    cardNumber: KeyValuePair | string;
    sign: { custom: { x: number; y: number; page: number } };
}
