<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { KeyValuePair, TermKeyValuePair, Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { typeName } from '@/helpers/Utils';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { LogitoDictionaryEntry, LogitoDictionaryType, instanceOfLogitoDictionaryEntry } from '.';
import DataSourcesService from '@/modules/studio/data-sources/services/DataSourcesService';
import { FormModel as DictionaryTermsFormModel } from '@/modules/core/dictionary-terms/services/DictionaryTermsService';
import AddModal from '@/modules/core/dictionary-terms/modals/AddModal.vue';
import properties from '../../properties';

defineOptions({
    name: 'logito-dictionary-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $alert } = useAlerts();

const modal = ref<InstanceType<typeof AddModal> | null>(null);
const entryData = ref(new LogitoDictionaryEntry()) as Ref<LogitoDictionaryEntry>;
const pager = ref(new Pager(1, 999, '', 'DESC')) as Ref<Pager>;
const dictionaryPager = ref(new Pager(1, 20, 'Value', 'ASC'));
const sourceOptions = ref<Option[]>([]);
const defaultTerms = ref<KeyValuePair[] | KeyValuePair>([]);
const reloadKey = ref(0);

entryData.value = props.form.document.initEntry(
    props.blueprint,
    entryData.value,
    instanceOfLogitoDictionaryEntry,
    props.index
);

const blueprint = computed(() => props.blueprint);

const defaultValue = computed({
    get()
    {
        return entryData.value.defaultValue;
    },
    set(value: TermKeyValuePair[])
    {
        entryData.value.defaultValue = value;
    },
});

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        if (!blueprint.value.multiselect) return value != null ? value[0] : defaultValue.value[0] ?? null;

        return value ?? defaultValue.value ?? null;
    },
    set(value: TermKeyValuePair[] | TermKeyValuePair)
    {
        entryData.value.data = Array.isArray(value) ? value : value ? [value] : [];
    },
});

const required = computed(() => props.form.expressions.required(props.blueprint));
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));
const design = computed(() => props.form.designMode());
const endpoint = computed(() =>
{
    if (design.value) return null;

    if (props.entry.fieldIndex)
    {
        return `modules/${props.entry.moduleId}/table-templates/select-options/${props.entry.fieldIndex}/${blueprint.value.name}`;
    }

    return `simple-dictionary/${props.entry.module}/form/${props.entry.formId}/${props.entry.actionName}/${blueprint.value.name}`;
});
const type = computed(() => props.blueprint.dataSource);

const setValue = (_value: DictionaryTermsFormModel) =>
{
    const option: TermKeyValuePair = {
        key: _value.publicId,
        value: props.form.localization.translate(_value.name),
        properties: JSON.parse(_value.value ?? '{}'),
    };

    value.value = option;
    reloadKey.value++;
};

const openModal = () =>
{
    modal.value.showModalForDynamicForm(props.entry.module, props.entry.formId, blueprint.value.name);
};

const updateDataSource = (): void =>
{
    defaultTerms.value = [];
    updateDefaultValue();
};

const updateMultiselect = (value: boolean): void =>
{
    if (value)
    {
        if (defaultTerms.value != null && !Array.isArray(defaultTerms.value))
            defaultTerms.value = [defaultTerms.value as KeyValuePair];
        else defaultTerms.value = [];
    }
    else
    {
        if (Array.isArray(defaultTerms.value) && (defaultTerms.value as KeyValuePair[]).length > 0)
            defaultTerms.value = defaultTerms.value[0];
        else defaultTerms.value = null;
    }

    updateDefaultValue();
};

const updateDefaultValue = (): void =>
{
    blueprint.value.defaultValue = Array.isArray(defaultTerms.value)
        ? defaultTerms.value.map((p) => p.key)
        : defaultTerms.value != null
            ? [defaultTerms.value.key]
            : [];
};

const fetchSourceOptions = async (): Promise<void> =>
{
    try
    {
        const response = await DataSourcesService.fetchDynamicControlsDataSource(
            typeName(blueprint.value.type),
            pager.value
        );

        sourceOptions.value = response.items.map((item) => ({ text: item.value, value: item.key }));
    }
    catch (error)
    {
        $alert.danger((error as any).message);
    }
};

const fetchDefaultTerms = async (): Promise<void> =>
{
    if (blueprint.value.defaultValue?.length)
    {
        const result = await DataSourcesService.fetchDefaultTermsList(
            blueprint.value.dataSource,
            new Pager(1, blueprint.value.defaultValue.length, 'Key', 'asc'),
            null,
            `[${blueprint.value.defaultValue.map((p) => `"${p}"`).join(',')}]`
        );

        defaultTerms.value = result.items.map((p) => p.result);

        if (!blueprint.value.multiselect && defaultTerms.value.length > 0)
        {
            defaultTerms.value = defaultTerms.value[0];
        }
    }
};

const fetchDefaultValue = async (): Promise<void> =>
{
    if (blueprint.value.defaultValue?.length)
    {
        const result = await DataSourcesService.fetchDefaultValuesForDocument(
            endpoint.value,
            new Pager(1, 32, '', 'ASC'),
            `[${blueprint.value.defaultValue.map((p) => `"${p}"`).join(',')}]`
        );

        entryData.value.defaultValue = result.items.map((p) => p.result);
    }
};

const init = async (): Promise<void> =>
{
    if (design.value)
    {
        await fetchSourceOptions();
        await fetchDefaultTerms();
    }
    else
    {
        fetchDefaultValue();
    }
};

init();
</script>

<template>
    <form-component-wrapper class="logitoDictionary-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <div class="d-flex">
                    <ideo-select
                        v-model="value"
                        :id="blueprint.id"
                        :multiselect="blueprint.multiselect"
                        :placeholder="placeholder"
                        :endpoint="endpoint"
                        :data-test="blueprint.id"
                        :pager="dictionaryPager"
                        :clear-button="!blueprint.letUsersAddTerms"
                        :custom-classes="{ 'border-radius-right-none': blueprint.letUsersAddTerms }"
                        :reload-key="reloadKey"
                        :deselect="blueprint.letUsersAddTerms"
                        class="flex-fill"
                    />
                    <ideo-button
                        v-if="blueprint.letUsersAddTerms"
                        variant="success"
                        icon="fas fa-plus"
                        class="border-radius-left-none m-0"
                        @click="openModal"
                    />
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
            <add-modal ref="modal" :dictionary-type-id="type" :edit-dictionary="false" @reload="setValue" />
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-checkbox
                v-model="blueprint.multiselect"
                :label="$t('[[[Wielokrotny wybór]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'multiselect')"
                @update:modelValue="updateMultiselect"
            />
            <field-checkbox
                v-model="blueprint.letUsersAddTerms"
                :label="$t('[[[Pozwól dodawać terminy użytkownikom]]]')"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <field-select
                v-model="blueprint.dataSource"
                :label="$t('[[[Źródło danych]]]')"
                :options="sourceOptions"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'dataSource')"
                :required="true"
                @update:modelValue="updateDataSource"
            />
            <ideo-form-group v-if="blueprint.dataSource" :label="$t('[[[Wartość domyślna]]]')">
                <ideo-select
                    v-model="defaultTerms"
                    :endpoint="`admin/forms/default-dictionary-select/${blueprint.dataSource}`"
                    :multiselect="blueprint.multiselect"
                    @update:modelValue="updateDefaultValue"
                />
            </ideo-form-group>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-with-helper v-model="blueprint.filterScript" :label="$t('[[[Skrypt filtrujący]]]')">
                <template #helper>
                    <div class="legend">
                        <p class="mb-0">
                            {{
                                $t(
                                    '[[[Aby zawężyć wybór terminów dostępnych na liście możesz wyfiltrować wpisy na podstawie pól formularza zdefiniowanych w definicji słownika.]]]'
                                )
                            }}
                        </p>
                        <p></p>
                        <p class="mb-0">
                            {{
                                $t(
                                    '[[[Skrypt musi być napisany w JS i musi definiować obiekt o nazwie filter, który powinien składać się z pól o nazwach zgodnych z kluczami pól formularza i wartościami wg. których nastąpi filtrowanie.]]]'
                                )
                            }}
                        </p>
                        <p></p>
                        <p class="mb-8">
                            {{
                                $t(
                                    '[[[W skrypcie dostępny jest obiekt CURRENTUSER, który zawiera dane zalogowanego użytkownika o następującej strukturze]]]'
                                )
                            }}
                        </p>
                        <p></p>
                        <div>
                            <pre>
                                CURRENTUSER: {
                                    Name: string,
                                    Email: string,
                                    PersonalNumber: string,
                                    PhoneNumber: string,
                                    CellNumber: string,
                                    RoomNumber: string,
                                    UserName: string,
                                    UnitSymbol: string,
                                    UnitName: string
                                }
                            </pre>
                        </div>
                        <p></p>
                        <p>
                            {{ $t('[[[Przykładowy skrypt dla słownika z polami unit i type]]]') }}
                        </p>
                        <p></p>
                        <div>
                            <pre>
                                var filter = {
                                    unit: CURRENTUSER.UnitSymbol,
                                    type: 'TYPE1'
                                }
                            </pre>
                        </div>
                    </div>
                </template>
            </field-with-helper>
        </template>
    </form-component-wrapper>
</template>
