<script lang="ts" setup>
import { computed } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, AddressType } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});

const display = computed(() =>
{
    const lines = [
        `${props.value?.Street ?? ''} ${props.value?.Building ?? ''}${props.value?.Apartment ? `/${props.value?.Apartment}` : ''}`.trim(),
        `${props.value?.Zip ?? ''} ${props.value?.City ?? ''}`.trim(),
        `${props.value?.Province ?? ''} ${props.value?.Country ?? ''}`.trim()
    ];

    return lines.filter(p => p.length > 0).join('; ');
});
</script>

<template>
    <span class="d-flex align-items-end" v-if="display">
        <i class="fa-light fa-location-dot me-2 text-danger"></i>
        <span>{{ display }}</span>
    </span>
    <span v-else>-</span>
</template>

<style scoped>
.fa-location-dot {
    font-size: 16px;
}
</style>
