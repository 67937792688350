<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { PeriodValue } from '@/components/builder/form/types/PeriodValue';

const instance = getCurrentInstance();

defineOptions({
    name: 'field-period'
});

const props = defineProps({
  "label": null,
  "modelValue": null,
  "type": { default: 'date' }
});

const emit = defineEmits(["update:modelValue"]);

const uid = computed(() => `form-field-${instance.uid}`);

const update = (value: PeriodValue): void =>
{
    if (value.min !== null && value.max !== null && value.min > value.max)
    {
        value.max = value.min;
    }

    emit('update:modelValue', value);
};

const updateMin = (value: DateTime | null): void =>
{
    update({
        min: value,
        max: props.modelValue.max
    });
};

const updateMax = (value: DateTime | null): void =>
{
    update({
        min: props.modelValue.min,
        max: value
    });
};
</script>

<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div class="row g-0 pb-2">
            <div class="col-2 p-2 text-end">
                {{ $t('[[[od]]]') }}
            </div>
            <div class="col-10">
                <ideo-datetime :type="type" :id="`${uid}-min`" :modelValue="modelValue.min" @update:modelValue="updateMin" />
            </div>
        </div>
        <div class="row g-0">
            <div class="col-2 p-2 text-end">
                {{ $t('[[[do]]]') }}
            </div>
            <div class="col-10">
                <ideo-datetime :type="type" :id="`${uid}-max`" :modelValue="modelValue.max" @update:modelValue="updateMax" />
            </div>
        </div>
    </div>
</template>
