import Decimal from "decimal.js";

export function calculateSummary(data: Record<string, any>[]): any
{
    return {
        netAmount: data
            .map((p) => new Decimal(p.netAmount))
            .reduce((prev, curr) => (curr = curr.plus(prev)), new Decimal(0))
            .toDecimalPlaces(2)
            .toString(),
        grossAmount: data
            .map((p) => new Decimal(p.grossAmount))
            .reduce((prev, curr) => (curr = curr.plus(prev)), new Decimal(0))
            .toDecimalPlaces(2)
            .toString(),
        vatAmount: data
            .map((p) => new Decimal(p.vatAmount))
            .reduce((prev, curr) => (curr = curr.plus(prev)), new Decimal(0))
            .toDecimalPlaces(2)
            .toString(),
    };
}
