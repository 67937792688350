<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, LikertType } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { ApiQueryCondition, KeyElement, TermQueryCondition, AndQueryCondition } from '@/helpers/ApiQueryBuilder';
import { Option } from '@/helpers/Interfaces';

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const questions = computed<Option<number>[]>(() => component.value.questions.map((value, i) =>
{
    return { value: i, text: props.builder.localization.translate(value) };
}));
const choices = computed<Option<number>[]>(() => [{value: null, text: $t('[[[Brak odpowiedzi]]]')}, ...component.value.choices.map((value, i) =>
{
    return { value: i + 1, text:  props.builder.localization.translate(value) };
})]);
const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as AndQueryCondition);
const entry = computed<Record<number, number>>({
    get()
    {
        if (condition.value)
        {
            return condition.value.and
                .map(p => p as TermQueryCondition & KeyElement)
                .map(p => ({
                    question: Number(p.__key),
                    answer: Number(p.term.value)
                }))
                .record(p => p.question, p => p.answer);
        }

        return {};
    },
    set(value)
    {
        if (condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                and: []
            });
        }

        condition.value.and = Object.entries(value)
            .filter(([_, answerId]) => answerId != null)
            .map(([questionId, answerId]) => ({
                __key: Number(questionId),
                term: {
                    field: `${component.value.name}.${questionId}`,
                    value: answerId.toString()
                }
            }));

        if (condition.value.and.length == 0)
        {
            reset();
        }
    }
});
const display = computed(() =>
{
    const count = Object.entries(entry.value).length;

    if (count > 0)
        return $t('[[[Odpowiedzi: %0 na %1|||{0}|||{1}]]]', count, questions.value.length);

    return null;
});
const label = computed(() => props.builder.localization.translate(component.value.label));

const value = (question: Option<number>): any =>
{
    return entry.value[question.value];
};

const update = (question: Option<number>, value: number): any =>
{
    entry.value = {...entry.value, ...{[question.value]: value}};
};

const reset = (): void =>
{
    model.value.remove(p => p.__key == component.value.id);
};
</script>

<template>
    <ideo-filter
        :value="entry"
        :display="display"
        :name="component.name"
        :label="label"
        :index="index"
        :visible="component.showFilter"
        menu-class="p-0"
        @clear="reset"
    >
        <div class="filters scroll py-1">
            <div v-for="question in questions" :key="question.value" class="question">
                <div>
                    <h6 class="text-uppercase ps-1 ms-2 mt-2 mb-0">
                        {{ question.text }}
                    </h6>
                    <ideo-form-radio-group
                        stacked
                        class="mt-1"
                        :options="choices"
                        :model-value="value(question)"
                        @update:model-value="update(question, $event)"
                    />
                </div>
            </div>
        </div>
    </ideo-filter>
</template>

<style lang="scss" scoped>
.filters {
    max-height: 295px;

    .question:not(:last-child) {
        border-bottom: 1px solid var(--bs-border-color);
    }

    :deep(fieldset) {
        padding: 0;
    }
    :deep(.form-check) {
        margin: 0 !important;
        padding: 0 8px 0 29px;
        border-left: 3px solid transparent;

        &:hover {
            background-color: var(--bs-tertiary-bg);
            border-left-color: var(--bs-primary)
        }

        input {
            margin-top: 12px;
        }
        label {
            margin: 0 !important;
            padding: 8px 0 8px 0;
            display: block;
            cursor: pointer;
        }
    }
}
</style>

