<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'BooleanFormatter',
})
export default class BooleanFormatter extends Vue
{
    @Prop({ default: null }) public property: boolean;
    @Prop({ default: (): KeyValuePair[] => ([]) }) public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;

    public get isBadge(): boolean
    {
        const badge = this.features.find((feature) => feature.key === 'Badge');

        return !!badge;
    }

    public get getContent(): string
    {
        const type = this.features.find((elem) => elem.key === 'Badge');

        let func = '';

        if (type)
        {
            func = type.name;

            if (func === 'enabledBadge')
                return this.property ? this.$t('[[[Opublikowany]]]') : this.$t('[[[Nieopublikowany]]]');
        }

        return this.property ? this.$t('[[[Tak]]]') : this.$t('[[[Nie]]]');
    }

    public get getVariant(): string
    {
        return this.property ? 'success' : 'danger';
    }
}
</script>

<template>
    <div class="boolean-formatter d-flex" v-if="property !== null">
        <span v-if="!isBadge">{{ getContent }}</span>
        <ideo-badge v-else :variant="getVariant" class="status-badge" :title="getContent">
            {{ getContent }}
        </ideo-badge>
    </div>
</template>
