import reportAddedDocuments from './addedDocuments';
import shipmentReport from './shipmentReport';

export default {
    reports: {
        namespaced: true,
        modules: {
            addedDocuments: reportAddedDocuments,
            shipmentReport: shipmentReport
        }
    }
};
