<script lang="ts" setup>
import { computed } from 'vue';

const model = defineModel<any>();
const props = defineProps({
  "size": { default: 'md' },
  "chars": { default: 20 }
});

const customClasses = computed(() => ({
    'form-control': true,
    'form-control-sm': props.size == 'sm',
    'form-control-lg': props.size == 'lg'
}));
</script>

<template>
    <input v-model="model" :class="customClasses" :size="props.chars">
</template>
