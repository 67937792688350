<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { MoneyModel } from '@/components/common/dynamic-grid/helpers/GridInterfaces';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'MoneyFormatter',
})
export default class MoneyFormatter extends Vue
{
    @Prop() public property: MoneyModel;
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;
}
</script>

<template>
    <span class="text-nowrap d-block">
        {{ `${$filters.currencyFormat(property.amount)} ${property.currency}` }}
    </span>
</template>
