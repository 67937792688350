<script lang="ts">
import { Options } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { HistoryItemModel } from "@/modules/logito/office/services/OfficeDocuments";
import { invoiceStatusVariant, historyStatusVariant } from '@/modules/low-code/inc/finance/helpers';
import Pager from '@/helpers/Pager';

import LoaderMixin from "@/modules/logito/office/components/loaderMixin";
import EntityService from '@/modules/common/services/EntityService';
import { ParticipantModel } from "@/modules/logito/office/services/RegisterService";

import UserAvatar from '@/components/common/UserAvatar.vue';
import Participant from "@/modules/low-code/components/Participant.vue";
import Substitute from '@/modules/core/substitutions/views/Substitute.vue';
import FileList from '@/components/common/FileList.vue';
import ArchiveDataModal from '@/modules/low-code/components/ArchiveDataModal.vue';

@Options({
    components: { UserAvatar, Participant, Substitute, FileList, ArchiveDataModal },
})
export default class DocumentHistory extends LoaderMixin
{
    @Prop({ default: '', required: true }) public publicId: string;
    @Prop({ default: '', required: true }) public type: string;
    @Prop({ default: false }) public design: boolean;
    @Prop({ default: "" }) public blueprintName: string;

    public history: HistoryItemModel[] = [];
    public pager = new Pager(1, 999, "DateCreatedUtc", "desc");

    public async created(): Promise<void>
    {
        this.pager.setTotalRows(0);
        !this.design && (await this.init());
    }

    public mounted(): void
    {
        if (this.design) return;

        this.$events.$on('refetchData', this.fetchData);
    }

    public unmounted(): void
    {
        if (this.design) return;
    }

    public invoiceStatusVariant = invoiceStatusVariant;
    public historyStatusVariant = historyStatusVariant;

    public getElements(targets: ParticipantModel[], visible: boolean = true): ParticipantModel[]
    {
        return visible ? targets.slice(0, 5) : targets.slice(5);
    }

    public showInfo(id: string): void
    {
        this.$events.$emit('fetchUser', { id });
    }

    public async fetchData(): Promise<void>
    {
        try
        {
            const response = await EntityService.getEntityHistory(this.publicId, this.type, this.pager);
            const items = response.items ?? [];

            this.pager.setTotalRows(items.length);
            this.history = items.map((item) => item.result);
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: () => this.$alert.danger(this.$t('[[[Nie udało się pobrać historii]]]')),
            });
        }
    }
}
</script>

<template>
    <div>
        <ListView :items="history" :preload="false" class="changelog-table">
            <template #row="{ item, index }">
                <ListViewItem :title="$t('[[[Pracownik]]]')" width="250">
                    <div class="d-flex align-items-center">
                        <UserAvatar
                            :src="$filters.baseurl(`storage/files/${item.created?.user?.pictureUrl}`)"
                            :username="item?.created?.user?.value"
                            :key="item?.created?.user?.pictureUrl"
                            class="cursor-pointer"
                            @click="showInfo(item?.created?.user?.key)"
                        />
                        <Substitute
                            v-if="item?.created?.impersonator?.key"
                            :user="item?.created?.impersonator?.value"
                        />
                        <div class="ms-2">
                            <p class="mb-0">
                                <strong class="text-primary cursor-pointer" @click="showInfo(item?.created?.user?.key)">
                                    <span>{{ item?.created?.user?.value }}</span>
                                </strong>
                            </p>
                            <p
                                class="mb-1 text-sm"
                                v-if="item.action && item.action.value"
                                :variant="invoiceStatusVariant(item.action.key)"
                            >
                                {{ item.action.value }}
                                <IdeoBadge
                                    v-if="item.executeStatus && item.executeStatus.value"
                                    :variant="historyStatusVariant(item.executeStatus.key)"
                                    size="lg"
                                >
                                    {{ item.executeStatus.value }}
                                </IdeoBadge>
                            </p>
                        </div>
                    </div>
                </ListViewItem>
                <ListViewHeader :title="$t('[[[Data]]]')" width="170">
                    {{ $filters.datetime(item.created.dateUtc) }}
                </ListViewHeader>
                <ListViewItem :title="$t('[[[Kolejne osoby w procesie]]]')" width="200">
                    <div class="participant d-flex">
                        <Participant
                            v-for="(user, userIndex) in getElements(item.targets)"
                            :key="userIndex"
                            :user="user"
                            :index="'document-history-' + index + userIndex"
                        />
                        <IdeoDropdown
                            v-if="getElements(item.targets, false).length"
                            boundary="body"
                            variant="secondary"
                            menu-class="scroll"
                            :top-offset="5"
                            no-caret
                        >
                            <template #button-content>
                                <i class="fas fa-plus me-1"></i>
                                <span>{{ getElements(item.targets, false).length }}</span>
                            </template>
                            <template #default>
                                <div
                                    v-for="(user, userIndex) in getElements(item.targets, false)"
                                    :key="userIndex"
                                    class="p-2 d-flex align-items-center"
                                >
                                    <Participant
                                        :user="user"
                                        :index="'document-history-' + index + userIndex"
                                    />
                                    <p class="mb-0">
                                        <strong class="text-primary cursor-pointer">
                                            <span>{{ user?.user?.value }}</span>
                                        </strong>
                                    </p>
                                </div>
                            </template>
                        </IdeoDropdown>
                    </div>
                </ListViewItem>
                <ListViewItem :title="$t('[[[Komentarz]]]')" class="text-wrap">
                    {{ item.comment }}
                    <template v-if="item.attachements">
                        <FileList :files="item.attachements" />
                    </template>
                </ListViewItem>
                <ListViewButtons width="50" icon="fas fa-magnifying-glass" mode="secondary">
                    <IdeoButton v-if="item.hasArchiveData" icon="fa-solid fa-magnifying-glass" @click="$events.$emit('archive-data-modal', item.id, item.modalDisplaySize)" />
                </ListViewButtons>
            </template>
        </ListView>
        <ArchiveDataModal :document-id="publicId" :licence="type" />
        <portal v-if="blueprintName" :to="`tab-counter-${blueprintName}`">
            {{ pager.getTotalRows() }}
        </portal>
    </div>
</template>
