import { axios } from "@/plugins/axios";
import { Pagination, Resource, Statement } from "@/helpers/Interfaces";
import { UserModel } from "@/modules/core/users/services/UsersService";
import Pager from "@/helpers/Pager";
import { merge } from "lodash";

export default class ShareOneDriveService
{
    public static async getSharedDocuments(licence: string, documentId: string, pager: Pager): Promise<Pagination<Resource<Attachment>>>
    {
        return (await axios.get(`simple-query/${licence}/${documentId}/one-drive-attachments`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async shareDocument(filePublicId: string, form: FormModel): Promise<Statement>
    {
        return (await axios.post(`simple-command/files/${filePublicId}/one-drive`, form)).data;
    }

    public static async stopShareDocument(oneDriveDocumentPublicId: string, form: any): Promise<Statement>
    {
        return (await axios.post(`simple-command/one-drive-doc/${oneDriveDocumentPublicId}/close`, form)).data;
    }

    public static async downloadDocument(oneDriveDocumentPublicId: string): Promise<Blob>
    {
        return (await axios.get(`one-drive/${oneDriveDocumentPublicId}/download`, { responseType: "blob" })).data;
    }
}

export interface FormModel {
    users: UserModel[];
    comment: string;
    documentField: string;
}

export interface Attachment {
    attachmentId: string;
}
