<script lang="ts" setup>
import { computed, inject, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { DescriptionControlCodesType } from '.';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DetailsBuilderContract } from '@/components/builder/details';
import DescriptionLines from './components/DescriptionLines.vue';
import properties from '../../properties';

defineOptions({
    name: 'DescriptionControlCodesBlueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const route = useRoute();

const permissions = inject<Ref<Record<string, boolean>>>('permissions', null);

const blueprint = computed(() => props.blueprint);
const licence = computed(() => route.params.licence as string);
const design = computed(() => props.details.designMode());
</script>


<template>
    <dynamic-details-component-wrapper :details="details" :parent="parent" :blueprint="blueprint">
        <template #default>
            <description-lines :design="design" :module="licence" :can-describe="permissions?.canDescribe" />
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <dynamic-details-accordion>
                <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                    <field-visible :details="details" :blueprint="blueprint" />
                </dynamic-details-accordion-item>
            </dynamic-details-accordion>
        </template>
    </dynamic-details-component-wrapper>
</template>
