<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { DynamicDocument } from '@/modules/low-code/services/ModulesService';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'DynamicDocumentFormatter',
})
export default class DynamicDocumentFormatter extends Vue
{
    @Prop({ default: null }) public property: DynamicDocument | null;
    @Prop({ default: (): KeyValuePair[] => [] }) public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;
}
</script>

<template>
    <div v-if="Array.isArray(property)">
        <div v-for="doc in property" :key="doc.id">
            <RouterLink
                v-if="doc.canSee && !isOnGrid"
                :to="{ name: 'module-form-details', params: { publicId: doc.key, licence: doc.licence } }"
            >
                {{ doc.title }}
            </RouterLink>
            <span v-else>{{ doc.title }}</span>
        </div>
    </div>
    <div v-else-if="property">
        <RouterLink
            v-if="property.canSee && !isOnGrid"
            :to="{ name: 'module-form-details', params: { publicId: property.key, licence: property.licence } }"
        >
            {{ property.title }}
        </RouterLink>
        <span v-else>{{ property.title }}</span>
    </div>
</template>

