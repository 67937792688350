<script lang="ts" setup>
import { ref, Ref, computed } from 'vue';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import properties from '@/components/forms/properties';
import { AttachmentsEntry, AttachmentsType, instanceOfAttachmentsEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'attachments-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new AttachmentsEntry()) as Ref<AttachmentsEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfAttachmentsEntry, props.index);

const value = computed({
    get()
    {
        return entryData.value.data;
    },
    set(values: any[])
    {
        if (values == null)
            values = [];

        if (!Array.isArray(values))
            values = [values];

        entryData.value.data = values;
    }
});

const required = computed(() => props.form.expressions.required(props.blueprint));
const extensions = computed(() =>
{
    return props.blueprint.extensions.split(',').map(p => p.trim()).filter(p => !!p);
});
</script>

<template>
    <form-component-wrapper class="attachments-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <fileupload :id="blueprint.id" v-model="value" :max-size="blueprint.maxFileSize" :max-files="blueprint.maxFiles" :extensions="extensions" :dark="false"></fileupload>
                <form-error-message :entry="entryData" name="values" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-text v-model="blueprint.extensions" :label="$t('[[[Dozwolone rozszerzenia]]]')" placeholder="jpg, jpeg, png" />
            <field-numeric v-model="blueprint.maxFileSize" :label="$t('[[[Maksymalny rozmiar pliku w MB]]]')" :min="0" :max="100" />
            <field-numeric v-model="blueprint.maxFiles" :label="$t('[[[Maksymalna ilość plików]]]')" :min="1" :max="20" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.optimizeImage" :label="$t('[[[Optymalizuj rozdzielczość obrazów]]]')" />
            <div class="ms-4 ps-1" v-if="blueprint.optimizeImage">
                <field-numeric v-model="blueprint.imageMaxSize" :label="$t('[[[Maksymalna rozdzielość %0x%1px|||{0}|||{1}]]]', blueprint.imageMaxSize, blueprint.imageMaxSize)" :min="100" :max="4096" append="px" />
            </div>
            <div class="mb-2">
                <ideo-form-radio v-model="blueprint.convertTo" name="convert" :value="null">{{ $t('[[[Zachowaj oryginalny format obrazów]]]') }}</ideo-form-radio>
                <ideo-form-radio v-model="blueprint.convertTo" name="convert" value="webp">{{ $t('[[[Konwertuj obrazy do formatu WebP]]]') }}</ideo-form-radio>
            </div>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
