import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasAffix } from '@/components/builder/form/traits/HasAffix';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { AffixValue } from '@/components/builder/form/types/AffixValue';
import { MinMaxValue } from '@/components/builder/form/types/MinMaxValue';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'numeric',
    name: '[[[Liczba]]]',
    icon: 'fa-hashtag',
    group: 'primary',
    position: 5
};

export enum NumericFieldTypes {
    Integer = 'Integer',
    Decimal = 'Decimal',
    Percent = 'Percent'
}

export class NumericEntry extends ValidEntry<number>
{
    public type: string = Definition.type;
    public data: number = null;
    public min?: number = null;
    public max?: number = null;
    public index?: number = null;
    public toAmountInWords: string = '';

    public constructor(data: any = null, index: number = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }

        this.index = index;
    }

    public async collect(blueprint: NumericContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        const result = await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry(this.data ?? form.expressions.executeExpression(blueprint.defaultValue, this.index));

        // return entry({
        //     type: this.type,
        //     value: this.data ?? form.expressions.executeExpression(blueprint.defaultValue),
        //     ...(result ?? {})
        // });
    }

    public validate(blueprint: NumericContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const value = this.data ?? form.expressions.executeExpression(blueprint.defaultValue, this.index);
        const precision = blueprint.fieldType == NumericFieldTypes.Integer ? 0 : blueprint.precision;

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && value === null)
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (blueprint.range.min !== null && (value !== null && value < blueprint.range.min))
            {
                this.errors.value = [`[[[Wybrana liczba jest mniejsza niż dozwolona %0.|||${blueprint.range.min.toFixed(precision)}]]]`];
            }
            else if (blueprint.range.max !== null && (value !== null && blueprint.range.max < value))
            {
                this.errors.value = [`[[[Wybrana liczba jest większa niż dozwolona %0.|||${blueprint.range.max.toFixed(precision)}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }
}

export const instanceOfNumericEntry = (object: any): object is NumericEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface NumericContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasAffix, HasHelp, HasWidth, HasFilter
{
    fieldType: NumericFieldTypes;
    defaultValue: string;
    range: MinMaxValue;
    precision: number;
    step: number;
}

export class NumericType implements NumericContract, ValidatableBlueprint, EntryFactory<NumericEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public placeholder: Record<string, string>;
    public fieldType: NumericFieldTypes;
    public defaultValue: string;
    public range: MinMaxValue;
    public precision: number;
    public step: number;
    public affix: AffixValue;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Liczba' };
        this.showLabel = true;
        this.placeholder = {};
        this.defaultValue = null;
        this.range = { min: 0, max: 1000000 };
        this.precision = 0;
        this.step = 1;
        this.affix = { prepend: '', append: '' };
        this.help = {};
        this.fieldType = NumericFieldTypes.Integer;
        this.minWidth = 1;
        this.width = 0;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): NumericEntry
    {
        return new NumericEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}

export const increment = (step: number, precision: number): number =>
{
    if (step === 0)
        return 1 / Math.max(Math.pow(10, precision), 1);
    else
        return step;
};
