<template>
    <div class="d-flex align-items-center status-formatter">
        <ideo-badge v-if="original" class="me-3" :variant="getVariantColor(original)">{{ original.value }}</ideo-badge>
        <ideo-badge v-if="current" :variant="getVariantColor(current)">{{ current.value }}</ideo-badge>
    </div>
</template>

<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { ChangelogDefinitionValueModel, StatusWithVariant } from "@/modules/common/services/ChangelogService";
import { Options, Vue } from "vue-class-component";
import { getVariantColor } from "../helpers/variantHelper";

@Options({
    name: "StatusFormatter",
})
export default class StatusFormatter extends Vue
{
    @Prop()
    public original: StatusWithVariant;

    @Prop()
    public current: StatusWithVariant;

    @Prop()
    public definition: ChangelogDefinitionValueModel;

    public getVariantColor(item: StatusWithVariant | null): string
    {
        const func = JSON.parse(this.definition.type.features.find(def => def.key === "Badge")?.value ?? "{}").func;

        return item.variant?.key?.toLowerCase?.() || getVariantColor(item.key, func);
    }
}
</script>
