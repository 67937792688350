import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';

export const Definition: BlueprintDefinition = {
    type: 'changelog',
    name: '[[[Rejestr zmian]]]',
    icon: 'fa-arrow-right-arrow-left',
    group: 'components',
    position: 1,
};

export interface ChangelogContract extends Blueprint, VisibleBlueprint, HasWidth {}

export class ChangelogType implements ChangelogContract
{
    public id: string;
    public type: string;
    public name: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | WhenChoice;
    public visibleWhen: string;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 3;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = width;
    }
}
