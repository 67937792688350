<template>
    <div>
        <div v-for="(doc, index) in original" :key="index">
            <RouterLink v-if="doc && (doc.title)" :to="{ name: 'module-form-details', params: { publicId: doc.publicId, licence: doc.licence } }" class="original-value small">
                {{ doc.title }}
            </RouterLink>
        </div>
        <div v-for="(doc, index) in current" :key="index">
            <RouterLink v-if="doc && (doc.title)" :to="{ name: 'module-form-details', params: { publicId: doc.publicId, licence: doc.licence } }" class="current-value small">
                {{ doc.title }}
            </RouterLink>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { ChangelogDefinitionModel, RelatedDocumentsLog } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'RelatedDocumentsFormatter'
})
export default class RelatedDocumentsFormatter extends Vue
{
    @Prop({ default: null })
    public original: RelatedDocumentsLog[];

    @Prop({ default: null })
    public current: RelatedDocumentsLog[];

    @Prop()
    public definition: ChangelogDefinitionModel;
}
</script>
