<script lang="ts" setup>
import { getCurrentInstance, computed } from 'vue';
import { Option } from '@/helpers/Interfaces';

defineOptions({
    name: 'field-select'
});

defineProps({
  "label": null,
  "options": null,
  "modelValue": null,
  "invalidFeedback": { type: Function,  },
  "required": { type: Boolean,  }
});

const emit = defineEmits(["update:modelValue"]);

const instance = getCurrentInstance();

const uid = computed(() => `form-field-${instance.uid}`);

const update = (e: any): void =>
{
    emit('update:modelValue', e?.target?.value);
};
</script>


<template>
    <div class="form-group">
        <label v-if="label" :for="uid">{{ label }} <var v-if="required">*</var></label>
        <slot />
        <select class="form-control" :id="uid" :value="modelValue" @change="update">
            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ $t(option.text) }}
            </option>
        </select>
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>
    </div>
</template>
