<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { SimplePartner } from '@/modules/core/dictionaries/services/DictionaryService';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'ContractorFormatterArray'
})
export default class ContractorFormatterArray extends Vue
{
    @Prop({ default: [] })
    public original: SimplePartner[];

    @Prop({ default: [] })
    public current: SimplePartner[];

    @Prop()
    public definition: ChangelogDefinitionModel;
}
</script>

<template>
    <div>
        <div v-for="(contractor, index) in original" :key="index">
            <div class="original-value small" v-if="contractor && (contractor.value || contractor.name)">
                <span>{{ contractor.value || contractor.name }}</span>
            </div>
        </div>
        <div v-for="(contractor, index) in current" :key="index">
            <div class="current-value small" v-if="contractor && (contractor.value || contractor.name)">
                <span>{{ contractor.value || contractor.name }}</span>
            </div>
        </div>
    </div>
</template>

