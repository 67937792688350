<template>
    <div>
        <div class="mb-2">{{ message }}</div>
        <div>
            <button class="btn btn-light" @click.stop="onReload">
                {{ update }}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Emit, Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'custom-toast'
})
export default class CustomToast extends Vue
{
    @Prop()
    public message: string;

    @Prop()
    public update: string;

    @Emit('reload')
    public onReload(e: any): any
    {
        return e;
    }
}
</script>
