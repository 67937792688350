import { Resource, Pagination, KeyValuePair } from "@/helpers/Interfaces";
import Pager from "@/helpers/Pager";
import axios from "axios";
import { merge } from "lodash";

export default class AutocompleteService
{
    public static async fetchOptions(module: string, formType: string, propType: string, filter: AutocompleteFilterModel, pager: Pager, narrow?: Record<string, any>, publicId?: string, actionName?: string, sitemapId?: string, extraParams?: any): Promise<Pagination<Resource<KeyValuePair>>>
    {
        const url = publicId != null && publicId != ''
            ? `simple-dictionary/${module}/form/${publicId}/${propType}`
            : actionName != null && actionName != ''
                ? `simple-dictionary/${module}/${formType}/${actionName}/${propType}`
                : `simple-dictionary/${module}/${formType}/${propType}`;

        return (await axios.get(url, {
            params: merge({ sitemapId }, filter, extraParams, pager.data(), narrow)
        })).data;
    }

    public static async fetchOptionsByCustomEndpoint(endpoint: string, filter: Record<string, string>, pager: Pager, narrow?: Record<string, any>, sitemapId?: string, extraParams?: any): Promise<Pagination<Resource<Record<string, string>>>>
    {
        return (await axios.get(endpoint, {
            params: merge({ sitemapId }, filter, extraParams, pager.data(), narrow)
        })).data;
    }
}

export interface AutocompleteFilterModel {
    search: string
}

export interface FetchSettings{
    module: string,
    propType: string,
    actionName: string,
    publicId: string
}

export interface IAdditionalOptions{
    field: string,
    type: string,
    label: string
}
