<template>
    <div :class="customClasses" v-if="flag(show)">
        <slot name="default"></slot>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-alert'
})
export default class IdeoAlert extends Vue
{
    @Prop({ default: false })
    public show: boolean;

    @Prop({
        default: 'secondary',
        validator: (value: string) => ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].includes(value)
    })
    public variant: string;

    public flag(value: any): boolean
    {
        return value !== false;
    }

    public get customClasses(): Record<string, boolean>
    {
        return {
            'alert': true,
            [`alert-${this.variant}`]: true
        };
    }
}
</script>
