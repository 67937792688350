<template>
    <div class="d-flex flex-column small">
        <template v-if="original && original.length">
            <div v-for="(item, index) in original" :key="index">
                <p>
                    {{ index + 1 }}. {{ item.name }} {{ item.city }} {{ item.streetName }} {{ item.streetPostal }}
                    <ideo-badge v-if="item.shipmentStatus && item.shipmentStatus.value != null" :variant="getStatus(item.shipmentStatus.key)"> {{ item.shipmentStatus.value }}</ideo-badge>
                    <ideo-badge v-if="item.shipmentStatus && item.shipmentStatus.value == null" :variant="'info'"> {{ $t('[[[Brak przesyłki]]]') }}</ideo-badge>
                </p>
            </div>
        </template>
        <template v-if="current && current.length">
            <div v-for="(item, index) in current" :key="index">
                <p>
                    {{ index + 1 }}. {{ item.name }} {{ item.city }} {{ item.streetName }} {{ item.streetPostal }}
                    <ideo-badge v-if="item.shipmentStatus && item.shipmentStatus.value !== null" :variant="getStatus(item.shipmentStatus.key)"> {{ item.shipmentStatus.value }}</ideo-badge>
                    <ideo-badge v-if="item.shipmentStatus && item.shipmentStatus.value === null" :variant="'info'"> {{ $t('[[[Brak przesyłki]]]') }}</ideo-badge>
                </p>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { ContractorDetailsModel } from '@/modules/common/services/ChangelogService';
// import { ShipmentStatusOption } from '@/modules/office/outgoings/inc/enumts';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'ContractorWithShippmentFormatter'
})
export default class ContractorWithShipmentFormatter extends Vue
{
    @Prop({ default: null })
    public original: ContractorDetailsModel;

    @Prop({ default: null })
    public current: ContractorDetailsModel;

    public getStatus(value:string):string
    {
        // return ShipmentStatusOption[value]?.badgeVariant || "secondary";
    }
}
</script>
