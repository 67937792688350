<script lang="ts" setup>
import { inject, Ref, watch } from 'vue';
import { FormType } from '@/helpers/Form';

const model = defineModel<boolean>();
const props = defineProps({
  "name": null,
  "label": null,
  "placeholder": { default: null },
  "index": { default: 0 },
  "visible": { type: Boolean, default: true },
  "value": { default: undefined },
  "display": { type: [Function, String], default: undefined },
  "menuClass": { default: 'p-0' },
  "dropdown": { type: Boolean, default: false },
  "autoApply": { type: Boolean, default: false }
});
const emit = defineEmits(["clear", "click", "open", "close"]);

const filter = inject<FormType<any>>('filter');
const dirty = inject<Ref<string[]>>('dirty');
const change = inject<(name?: string) => void>('change');
const reset = inject<(name?: string) => void>('reset');
const apply = inject<() => void>('apply');

watch(model, () =>
{
    change(props.name);
    apply();
});
</script>

<template>
    <ideo-filter
        :value="model"
        :display="display"
        :name="props.name"
        :label="props.label"
        :index="props.index"
        :visible="props.visible"
        :menu-class="props.menuClass"
        :dropdown="props.dropdown"
        :auto-apply="props.autoApply"
        @clear="emit('clear', props.name)"
        @click="emit('click')"
        @open="emit('open')"
        @close="emit('close')"
    >
        <template #label>
            <ideo-form-checkbox v-model="model">{{ props.label }}</ideo-form-checkbox>
        </template>
        <slot name="header"></slot>
        <slot name="footer"></slot>
    </ideo-filter>
</template>

<style scoped>
:deep(.custom-checkbox) {
    margin: 0 !important;
    cursor: pointer;
}
:deep(.form-check-label) {
    margin: 0 !important;
    cursor: pointer;
}
</style>
