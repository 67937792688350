import { axios } from '@/plugins/axios';
import { DateTime } from "luxon";
import { Statement } from "@/helpers/Interfaces";

export default class WorkHoursService
{
    public static async getWorkHours(): Promise<{startTimeUtc: DateTime, endTimeUtc: DateTime}>
    {
        return (await axios.get(`calendar/work-hours`)).data;
    }

    public static async saveWorkHours(model: {startTimeUtc: DateTime, endTimeUtc: DateTime}): Promise<Statement>
    {
        return (await axios.post(`calendar/work-hours`, model)).data;
    }
}
