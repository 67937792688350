// Blueprints
import { Blueprint } from '../blueprints/Blueprint';
import { BlueprintDefinition } from '../blueprints/BlueprintDefinition';
import { AggregateBlueprint } from '../blueprints/AggregateBlueprint';
import { instanceOfRootBlueprint } from '../blueprints/RootBlueprint';

// Managers
import { EventManager, Events } from '../managers/EventManager';
import { SchemaManager } from './SchemaManager';

// Services
import { BlueprintFactory } from '../services/BlueprintFactory';

// --------------------------------------------------

export class LayoutManager
{
    protected factory: BlueprintFactory;
    protected events: EventManager;
    protected schema: SchemaManager;

    public constructor(blueprintFactory: BlueprintFactory, eventManager: EventManager, schemaManager: SchemaManager)
    {
        this.factory = blueprintFactory;
        this.events = eventManager;
        this.schema = schemaManager;
    }

    public createComponent(type: string): Blueprint
    {
        return this.factory.create(
            type,
            this.schema.newId(),
            this.schema.name(type)
        );
    }

    public insertComponent(parent: AggregateBlueprint, component: Blueprint, before: Blueprint = null): void
    {
        if (before != null)
            parent.components.splice(parent.components.indexOf(before), 0, component);
        else
            parent.components.push(component);
    }

    public addComponent(parent: AggregateBlueprint, definition: BlueprintDefinition<any>, before: Blueprint = null): Blueprint
    {
        // utworzenie nowego obiektu danego typu po nazwie
        const component: any = this.createComponent(definition.type);

        this.insertComponent(parent, component, before);
        this.events.emit(Events.FOCUS, component);

        return component;
    }

    // public updateComponent(component: Blueprint, type: string): Blueprint
    // {
    //     const replacement = this.createComponent(type);

    //     this.insertComponent(this.schema.parent(component), replacement, component);
    //     this.removeComponent(component);

    //     return replacement;
    // }

    public removeComponent(component: Blueprint): void
    {
        const parent = this.schema.parent(component);

        parent.components = parent.components.filter(p => p !== component);
    }

    public addBefore(component: Blueprint): void
    {
        const parent = this.schema.parent(component);

        this.events.emit(Events.INSERT, parent, component);
    }

    public addAfter(component: Blueprint): void
    {
        const parent = this.schema.parent(component);
        const next = this.schema.next(component);

        this.events.emit(Events.INSERT, parent, next);
    }

    public moveUp(component: Blueprint): void
    {
        const parent = this.schema.parent(component);
        const index = parent.components.indexOf(component);

        if (index > 0)
        {
            parent.components.splice(index, 1);
            parent.components.splice(index - 1, 0, component);
        }
    }

    public moveDown(component: Blueprint): void
    {
        const parent = this.schema.parent(component);
        const index = parent.components.indexOf(component);
        const length = parent.components.length - (instanceOfRootBlueprint(parent) ? 0 : 1);

        if (index + 1 < length)
        {
            parent.components.splice(index + 2, 0, component);
            parent.components.splice(index, 1);
        }
    }
}
