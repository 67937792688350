<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import BusinessPartnersForm from "@/modules/logito/crm/views/Form.vue";

const emit = defineEmits(["partner-added"]);

const props = defineProps({
  "publicId": null
});

const { $t } = useLocalization();

const modal = ref(null);

const isEditMode = computed(() => props.publicId);
const title = computed(() => (!isEditMode.value ? $t('[[[Dodaj kontrahenta]]]') : $t('[[[Edytuj kontrahenta]]]')));

const showModal = async () =>
{
    modal.value.show();
};

const partnerAdded = async (publicId: string) =>
{
    emit('partner-added', publicId);
    modal.value.hide();
};

defineExpose({
    showModal,
});
</script>

<template>
    <ideo-modal ref="modal" :title="title" size="xl" centered scrollable>
        <business-partners-form
            :modal-mode="true"
            :public-id="publicId"
            @partner-added="partnerAdded"
        />
        <template #modal-footer="{ cancel }">
            <portal-target name="add-partners-modal"></portal-target>
            <ideo-button variant="secondary" @click="cancel">{{ $t('[[[Anuluj]]]') }}</ideo-button>
        </template>
    </ideo-modal>
</template>
