import { ContractTypes, RequestStatus } from "@/helpers/Enums";
import { KeyValuePair } from "@/helpers/Interfaces";
import { InvoiceStatusVariants, PaymentStatus } from "@/modules/low-code/inc/finance/settings";
import { ContractStatusVariants } from "@/modules/logito/office/inc/contracts/settings";
import { DocumentsStatusVariants } from "@/modules/logito/office/inc/register-tasks/settings";
import { ProcessStatusVariants } from "@/modules/low-code/inc/Settings";
import { ResultStatus } from "./GridEnums";

export const getVariantColor = (key: string, type: string): string =>
{
    let variant = "secondary";

    switch (type)
    {
        case "invoiceStatus": {
            variant = InvoiceStatusVariants.find(status => status.key === key)?.value;

            return variant || "secondary";
        }
        case "documentStatus":
        {
            variant = DocumentsStatusVariants.find(status => status.key === key)?.value;

            return variant || "secondary";
        }
        case "contractStatusVariant":
        {
            variant = ContractStatusVariants().find((item: any) => item.key === key)?.value;

            return variant || "secondary";
        }
        case "contractStatus":
        {
            variant = ContractTypes.find((item: any) => item.key === key)?.value;

            return variant || "secondary";
        }
        case "paymentStatus": {
            variant = PaymentStatus.find(status => status.key === key)?.value;

            return variant || "secondary";
        }
        case "processVariant":
        {
            variant = ProcessStatusVariants.find((item: KeyValuePair) => item.key === key)?.value;

            return variant || "secondary";
        }
        case "resultBadge":
        {
            variant = ResultStatus.find(status => status.key === key)?.value;

            return variant || "secondary";
        }
        case "requestBadge":
        {
            variant = RequestStatus[key]?.variant;

            return variant || "secondary";
        }
        case "documentTypeBadge":
        {
            return "primary";
        }
        case "accountType":
        {
            return key === "Local" ? 'success' : 'primary';
        }
        case "versionBadge":
        {
            return key === "Active" ? 'success' : key === "Draft" ? 'warning' : 'danger';
        }
        case "boolBadge":
        {
            return key === "true" ? "success" : "danger";
        }
        case "successBadge":
        {
            return "success";
        }
        case "sourceBadge":
        {
            return "warning";
        }
        case "dynamicFormBadge":
        {
            return key === 'Accepted' ? 'success' : key === 'Draft' ? 'warning' : 'danger';
        }
        case "integratorHistoryBadge":
        {
            return key === 'Success' ? 'success' : 'danger';
        }
        default:
            return "secondary";
    }
};
