<script lang="ts" setup>
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
import { ref, computed, inject, nextTick, getCurrentInstance, onMounted } from 'vue';
import { $t } from '@/plugins/localization';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { Definition as NumericDefinition } from '../blueprints/numeric';
import { Definition as FormDefinition } from '../blueprints/form';
import { Definition as PageDefinition } from '../blueprints/page';
import { Definition as RowDefinition } from '../blueprints/row';
import { Definition as SpacerDefinition } from '../blueprints/spacer';
import { Definition as LineDefinition } from '../blueprints/line';
import { Definition as SectionDefinition } from '../blueprints/section';
import { Definition as ContentDefinition } from '../blueprints/content';
import { Definition as SignatureDefinition } from '../blueprints/signature';
import { Definition as CompositeDefinition } from '../blueprints/composite';
import { Definition as TableDefinition, TableType, TableColumn } from '../blueprints/table';
import { Definition as LogitoTableDefinition, } from '../blueprints/logito-table';
import { definitions } from '../Definitions';
import { evaluate } from 'mathjs';
import { cloneDeep } from 'lodash';

defineOptions({
    name: 'field-columns',
});

const emits = defineEmits(["update:columns", "update:modelValue"]);

const props = defineProps({
  "columns": null,
  "modelValue": null,
  "label": null,
  "form": null,
  "blueprint": null
});

const { columns } = __MACROS_useVModel("columns");

const exceptComponents = [
    TableDefinition.type,
    FormDefinition.type,
    PageDefinition.type,
    RowDefinition.type,
    SpacerDefinition.type,
    LineDefinition.type,
    SectionDefinition.type,
    ContentDefinition.type,
    SignatureDefinition.type,
    CompositeDefinition.type,
    LogitoTableDefinition.type
];
const numericType = NumericDefinition.type;
const buffer = ref<string>(null);
const isModal = ref(false);
const modalData = ref<{ index?: number; field?: string }>({});

const canEdit = inject('canEdit', false);

const instance = getCurrentInstance();
const uid = computed(() => `form-field-${instance.uid}`);

const componentsOptions = computed(() =>
{
    return definitions
        .orderBy((item) => item.group)
        .orderBy((item) => item.position)
        .toArray()
        .filter((item) => !exceptComponents.includes(item.type) && item.group !== 'none' && !item.disabled)
        .map((item) => ({ ...item, name: $t(item.name) }));
});

const textComponent = computed(() =>
{
    return componentsOptions.value.find((item) => item.type === 'text');
});

const value = computed(() => props.modelValue);

const isValid = computed(() =>
{
    if (buffer.value == null || buffer.value == '') return true;

    try
    {
        const _scope = scope(modalData.value.field);

        evaluate(buffer.value, _scope);

        return true;
    }
    catch
    {
        return false;
    }
});

const showColumnSettings = (item: Blueprint) =>
{
    props.form.clipboard.selectComponent(item);
};

const update = (value: Blueprint[]) =>
{
    emits('update:modelValue', value);
};

const onInput = () =>
{
    update(value.value);
};

const scope = (field: string): Record<string, number> =>
{
    const mappedScope: Record<string, number> = {};

    for (const item of value.value)
    {
        if (item.name.toLowerCase().includes(numericType) && item.name != field)
        {
            mappedScope[item.name] = 0;
        }
    }

    return mappedScope;
};

const showModal = (index: number, field: string) =>
{
    isModal.value = true;
    buffer.value = columns.value[index].formula ?? '';
    modalData.value = {
        index,
        field,
    };
};

const closeModal = () =>
{
    isModal.value = false;
    buffer.value = '';
    modalData.value = {};
};

const saveFormula = () =>
{
    if (!isValid.value) return;

    columns.value[modalData.value.index].formula = buffer.value;

    closeModal();
};

const createComponent = (data: { columnName: Record<string, string>; componentDefinition: BlueprintDefinition }): Blueprint =>
{
    return props.form.layout.createComponent(data.componentDefinition.type) as Blueprint;
};

const addColumn = (data?: { columnName?: Record<string, string>; componentDefinition?: BlueprintDefinition; id?: string }) =>
{
    const componentType = data?.componentDefinition ?? textComponent.value;
    const newItem = createComponent({ columnName: data?.columnName ?? {}, componentDefinition: componentType });
    const columnConfig = { columnHeader: data.columnName ?? {}, columnType: componentType };
    const _value = cloneDeep(value.value);
    const index = _value.findIndex((el) => el.id === data?.id);

    if (index > -1)
    {
        _value.splice(index + 1, 0, newItem);
        columns.value.splice(index + 1, 0, columnConfig);
    }
    else
    {
        _value.push(newItem);
        columns.value.push(columnConfig);
    }

    update(_value);
};

const updateColumn = (data: { columnName: Record<string, string>; componentType: string; id: string }) =>
{
    const componentDefinition = definitions.find((item) => item.type === data.componentType);

    const newItem = createComponent({ columnName: data.columnName, componentDefinition });
    const columnConfig = { columnHeader: data.columnName, columnType: componentDefinition };
    const _value = cloneDeep(value.value);
    const index = _value.findIndex((el) => el.id === data.id);

    _value[index] = newItem;
    columns.value[index] = columnConfig;
    update(_value);
};

const deleteColumn = (item: Blueprint) =>
{
    const mainIndex = value.value.findIndex((el) => el.id === item.id);
    const _value = value.value.filter((_, index) => index !== mainIndex);

    columns.value.splice(mainIndex, 1);
    update(_value);
};

const initColumns = async () =>
{
    if (value.value.length !== columns.value.length)
    {
        const _value = columns.value.map((column) =>
            createComponent({ columnName: column.columnHeader, componentDefinition: column.columnType })
        );

        update(_value);
    }

    if (value.value.length) return;

    addColumn({ columnName: { 'pl-PL': $t('[[[Kolumna]]]') + ' 1' } });
    await nextTick();
    addColumn({ columnName: { 'pl-PL': $t('[[[Kolumna]]]') + ' 2' } });
    await nextTick();
    addColumn({ columnName: { 'pl-PL': $t('[[[Kolumna]]]') + ' 3' } });
};

onMounted(() =>
{
    initColumns();
});
</script>

<template>
    <div>
        <ideo-form-localize v-slot="{ locale }">
            <div class="form-group">
                <label :for="uid">{{ label }}</label>
                <div v-for="(item, index) in value" :key="item.id">
                    <div class="d-flex flex-column flex-fill">
                        <div class="d-flex mb-1">
                            <!-- Name of column -->
                            <ideo-form-input
                                v-model="columns[index].columnHeader[locale]"
                                :placeholder="$t('[[[Nazwa kolumny]]]')"
                                @update:model-value="onInput"
                            ></ideo-form-input>

                            <!-- Add columns -->
                            <div class="input-group-append ms-1">
                                <button
                                    class="btn btn-outline-light"
                                    type="button"
                                    @click.prevent="addColumn({ id: item.id })"
                                >
                                    <i class="far fa-plus text-success"></i>
                                </button>
                            </div>
                        </div>
                        <div class="d-flex">
                            <!-- Type of column -->
                            <ideo-form-select
                                :model-value="columns[index].columnType?.type"
                                value-field="type"
                                text-field="name"
                                :options="componentsOptions"
                                :placeholder="$t('[[[Kontrolka]]]')"
                                class="flex-fill"
                                @update:model-value="
                                    updateColumn({
                                        columnName: columns[index].columnHeader,
                                        componentType: $event,
                                        id: item.id,
                                    })
                                "
                            />

                            <!-- Calculator -->
                            <ideo-button
                                v-if="columns[index].columnType.type === 'numeric'"
                                variant="secondary"
                                icon="fas fa-calculator"
                                class="ms-1 me-0"
                                @click="showModal(index, item.name)"
                            />

                            <!-- Columns settings -->
                            <button type="button" class="btn btn-light ms-1 me-0" @click.prevent="showColumnSettings(item)">
                                <i class="far fa-gear"></i>
                            </button>

                            <!-- Remove column -->
                            <div class="input-group-append ms-1">
                                <button
                                    type="button"
                                    class="btn btn-outline-light"
                                    :disabled="modelValue.length <= 1 || !canEdit"
                                    @click.prevent="deleteColumn(item)"
                                >
                                    <i class="far fa-trash text-danger"></i>
                                </button>
                            </div>
                        </div>
                        <ideo-form-checkbox
                            v-if="columns[index].columnType.type === 'numeric'"
                            v-model="columns[index].summary"
                            class="mt-2"
                        >
                            {{ $t('[[[Dodaj podsumowanie]]]') }}
                        </ideo-form-checkbox>
                    </div>
                    <hr
                        v-if="index !== value.length - 1"
                        role="separator"
                        aria-orientation="horizontal"
                        class="dropdown-divider my-2"
                    />
                </div>
                <button
                    v-if="!value.length"
                    class="btn btn-outline-light w-100"
                    type="button"
                    @click.prevent="addColumn({ columnName: { 'pl-PL': $t('[[[Kolumna]]]') + ' 1' } })"
                >
                    <i class="far fa-plus text-success"></i>
                </button>
            </div>
        </ideo-form-localize>

        <ideo-modal v-model="isModal" id="field-columns-modal" :title="modalData.field" size="xl" centered scrollable>
            <template #default>
                <div class="row">
                    <div class="col-lg-7 mb-3 mb-lg-0">
                        <ideo-form-textarea
                            v-model="buffer"
                            class="h-100"
                            :class="{ 'border-danger': !isValid }"
                            type="text"
                        />
                        <div v-if="!isValid" class="bg-danger text-white rounded-bottom mt-n4 px-2 pb-1">
                            {{ $t('[[[Formuła jest nieprawidłowa]]]') }}
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="legend">
                            <h5 class="fw-bold2 mb-3">{{ $t('[[[Podstawowe operacje tekstowe]]]') }}</h5>
                            <p>
                                {{
                                    $t(`[[[Dozwolone są operację dodawania (+), usuwania (-), mnożenia (*), dzielenia (/),
                                potęgowania (^2), pierwiastkowania (sqrt()). Jeżeli chcemy wyliczyć dane na podstawie
                                innej kolumny musimy znać jej nazwę (zwykle jest to Numeric z liczbą na końcu np.
                                Numeric2, możemy to sprawdzić klikając na daną kolumnę i odczytując nazwę z nagłówka
                                zakładki konfiguracyjnej pola) oraz typ kolumny musi być ustawiony na liczbowy.]]]`)
                                }}
                            </p>
                            <p>{{ $t('[[[Przykłady konfiguracji]]]') }}:</p>
                            <div>
                                <h6>{{ $t('[[[Stała wartość]]]') }}</h6>
                                <pre>5</pre>
                                <h6>{{ $t('[[[Dodawanie wartości dwóch kolumn]]]') }}</h6>
                                <pre>Numeric2 + Numeric3</pre>
                                <h6>{{ $t('[[[Pierwiastkowanie wartości kolumny]]]') }}</h6>
                                <pre>sqrt(Numeric2)</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #modal-footer>
                <ideo-button variant="primary" :disabled="!isValid" @click.stop.prevent="saveFormula">
                    {{ $t('[[[Zapisz]]]') }}
                </ideo-button>
                <ideo-button variant="secondary" @click.stop.prevent="closeModal">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>
