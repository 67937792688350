import { KeyValuePair } from '@/helpers/Interfaces';
import { $t } from '@/plugins/localization';

const ContractStatusVariants = (): KeyValuePair[] => [
    { key: 'Obligatory', value: 'success' },
    { key: 'Draft', value: 'warning' },
    { key: 'Terminated', value: 'secondary' },
    { key: 'Expired', value: 'danger' },
    { key: 'Invalid', value: 'danger' },
    { key: 'Deleted', value: 'danger' },
    { key: null, value: 'secondary' },
];

const ContractActions = (): KeyValuePair[] => [
    { key: 'Added', value: $t('[[[Dodano]]]') },
    { key: 'Modified', value: $t('[[[Zmodyfikowano]]]') },
    { key: 'Deleted', value: $t('[[[Usunięto]]]') },
];

const ContractTypes = (): KeyValuePair[] => [
    { key: 'Indefinite', value: 'info' },
    { key: 'Specified', value: 'info' },
    { key: 'Disposable', value: 'info' },
    { key: 'Cyclic', value: 'info' },
];

export { ContractStatusVariants, ContractActions, ContractTypes };
