<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const search = ref('');

const searchDocuments = () => router.push({ name: 'documents', query: { search: search.value } });

watch(router.currentRoute, () => (search.value = router.currentRoute.value.query.search as string), {
    immediate: true,
});
</script>

<template>
    <form @submit.prevent="searchDocuments" class="search-form">
        <div class="search">
            <input v-model="search" type="text" :placeholder="$t('[[[Szukaj]]]')" />
            <button type="submit">
                <i class="fa-solid fa-search"></i>
            </button>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.search-form {
    margin-right: 14px;
    order: 0;
    display: flex;
    align-items: center;
}

.search {
    border: 1px solid var(--ideo-search-border-color);
    border-radius: 3px;
    position: relative;
    width: 125px;
    display: flex;
    background: var(--ideo-search-bg);

    @include media-breakpoint-up(sm) {
        width: 150px;
    }

    @include media-breakpoint-up(md) {
        width: 200px;
    }

    @include media-breakpoint-up(xl) {
        width: 360px;
    }

    input {
        border-radius: 3px;
        border: none;
        padding: 8px 0 8px 10px;
        line-height: 18px;
        flex-grow: 1;
        color: var(--ideo-search-color);
        background: var(--ideo-search-bg);
        width: 100%;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 10px;
        border: none;
        background: transparent;

        i {
            font-size: 1rem;
            color: var(--ideo-search-icon-color);
            cursor: pointer;
        }
    }
}
</style>
