<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { VatTableEntry, VatTableType, instanceOfVatTableEntry } from '.';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { TaxLineModel } from '@/modules/low-code/services/TaxLineService';
import properties from '../../properties';
import VatTable from './VatTable.vue';

defineOptions({
    name: 'vat-table-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const entryData = ref(new VatTableEntry()) as Ref<VatTableEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfVatTableEntry, props.index);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value;
    },
    set(value: TaxLineModel[])
    {
        entryData.value.data = value;
    },
});

const blueprint = computed(() => props.blueprint);
const required = computed(() => props.form.expressions.required(props.blueprint));
const design = computed(() => props.form.designMode());
const label = computed(() => props.form.localization.translate(blueprint.value.label));
</script>

<template>
    <form-component-wrapper class="vatTable-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <vat-table
                    v-model="value"
                    :module="entry?.module"
                    :label="label"
                    :show-label="blueprint.showLabel"
                    :entry="entryData"
                    :required="required"
                    :design="design"
                />
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
            </div>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" :form="form" :blueprint="blueprint" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>
