<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useMedia } from '@/plugins/media';
import { useLocalization } from '@/plugins/localization';
import { FormModel as ModuleModel } from '../../services/SitemapService';
import { SectionItem } from '../../../modules/services/types';
import TableComponent from '../partials/Table.vue';
import FormComponent from '../partials/Form.vue';

const props = defineProps({
  "symbol": null,
  "module": null,
  "section": null,
  "routeKey": { default: null },
  "embedded": { type: Boolean, default: false }
});

const media = useMedia();
const { $t } = useLocalization();

const id = ref<number>(0);
const state = ref<'table' | 'form'>('table');
const table = ref<typeof TableComponent>(null);
const routeKey = computed(() => props.routeKey || props.section.id);
const title = computed(() => id.value == 0 ? $t('[[[Dodaj dokument]]]') : $t('[[[Edytuj dokument]]]'));
const modal = computed({
    get() { return state.value == 'form'; },
    set(value) { state.value = value ? 'form' : 'table'; }
});
const size = computed(() => props.section.settings.editOnModal ? props.section.settings.modalSize : 'xxl');
const fullscreen = computed(() => media.mobile());

async function onChange(value: number)
{
    id.value = value;

    await table.value.reload();
}

async function onExit(refresh: boolean)
{
    modal.value = false;

    if (refresh)
    {
        await table.value.reload();
    }
}

function onAdd()
{
    id.value = 0;
    modal.value = true;
}

function onEdit(value: number)
{
    id.value = value;
    modal.value = true;
}
</script>

<template>
    <TableComponent ref="table" :symbol="symbol" :section="section" :route-key="routeKey" :embedded="props.embedded" @add="onAdd" @edit="onEdit" />
    <ideo-modal
        v-model="modal"
        :size="size"
        :fullscreen="fullscreen"
        :centered="false"
        :static="true"
        :hide-header="true"
        :hide-footer="true"
        :hide-close="true"
        :title="title"
        :scrollable="true"
        body-class="p-0"
        content-class="shadow-lg"
        v-if="state == 'form'"
    >
        <FormComponent :symbol="symbol" :section="section" :id="id" :modal="true" @change="onChange" @exit="onExit" />
    </ideo-modal>
</template>
