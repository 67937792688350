import { DateTime } from 'luxon';
import { getColor } from '@/modules/core/home/inc/helpers';
import { $t } from '@/plugins/localization';
import { EventClickArg, CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { persistState, setSettings, Settings } from '@/plugins/viewstate';
import allLocales from '@fullcalendar/core/locales-all';
import WorkHoursService from '@/modules/logito/calendar/services/WorkHoursService';
import GridService from '@/modules/core/common/services/GridService';
import { createPopper as createPopperInstance } from '@popperjs/core';
import { debounce } from 'lodash';
import { $i18n } from '@/plugins/localization';

export default class CalendarDataHelper
{
    public workHours: any =
        {
            startTimeUtc: null,
            endTimeUtc: null,
        };

    public settings: Settings =
        {
            view: 'timeGridWeek',
            start: DateTime.now().startOf('week').toISODate(),
            end: DateTime.now().endOf('week').toISODate(),
            isActiveWorkHours: false,
        };

    public events: any[] = [];

    public getCalendarData(licence: string, sitemapId: string, filter: any, routeKey: any): CalendarOptions
    {
        const startHour = ('0' + this.workHours.startTimeUtc?.toLocal()?.hour ?? '08').slice(-2);
        const startMinute = ('0' + this.workHours.startTimeUtc?.toLocal()?.minute ?? '00').slice(-2);
        const endHour = ('0' + this.workHours.endTimeUtc?.toLocal()?.hour ?? '16').slice(-2);
        const endMinute = ('0' + this.workHours.endTimeUtc?.toLocal()?.minute ?? '00').slice(-2);

        return {
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            initialView: this.settings.view,
            initialDate: this.settings.start,
            headerToolbar: {
                left: 'prev,next today workingHours',
                center: 'title',
                right: 'timeGridDay,timeGridWeek,dayGridMonth',
            },
            buttonIcons: {
                prev: 'fas fas fa-caret-left',
                next: 'fas fas fa-caret-right',
            },
            customButtons: {
                workingHours: {
                    text: !this.settings.isActiveWorkHours ? $t('[[[Pokaż godziny robocze]]]') : $t('[[[Pokaż pełne godziny]]]'),
                    click: () =>
                    {
                        this.settings.isActiveWorkHours = !this.settings.isActiveWorkHours;
                        setSettings(this.settings);
                    },
                },
            },
            height: 'auto',
            events: this.events,
            nowIndicator: true,
            businessHours: {
                daysOfWeek: [1, 2, 3, 4, 5],
                startTime: `${startHour}:${this.workHours.startTimeUtc?.toLocal()?.minute ?? `${startMinute}`}`,
                endTime: `${endHour}:${this.workHours.endTimeUtc?.toLocal()?.minute ?? `${endMinute}`}`,
            },
            slotMinTime: this.settings.isActiveWorkHours ? `${startHour}:${startMinute}:00` : '00:00:00',
            slotMaxTime: this.settings.isActiveWorkHours ? `${endHour}:${endMinute}:00` : '24:00:00',
            locales: allLocales,
            locale: $i18n.shortLocale(),
            selectable: false,
            eventMouseEnter: (info) => this.createTooltip(info),
            eventMouseLeave: this.destroyTooltip,
            datesSet: (info) =>
            {
                const start = DateTime.fromISO(info.view.currentStart.toISOString()).toISODate();
                const end = DateTime.fromISO(info.view.currentEnd.toISOString()).toISODate();
                const view = info.view.type;

                if (this.settings.start !== start || this.settings.end !== end || this.settings.view !== view)
                {
                    this.settings.start = start;
                    this.settings.end = end;
                    this.settings.view = view;

                    this.debouncedLoadData(licence, sitemapId, filter, routeKey);
                }

                setSettings(this.settings);
            },
        };
    }

    public fetchWorkHours = async () =>
    {
        const response = await WorkHoursService.getWorkHours();

        this.workHours.startTimeUtc = response.startTimeUtc;
        this.workHours.endTimeUtc = response.endTimeUtc;
    };

    public loadData = async (licence: string, sitemapId: string, filter: any, routeKey: any = null) =>
    {
        try
        {
            const response = await GridService.fetchCalendarSeries(
                licence,
                sitemapId,
                DateTime.fromISO(this.settings.start).setZone('utc').toISO(),
                DateTime.fromISO(`${this.settings.end}T23:59:59.999`).setZone('utc').toISO(),
                filter.formatData()
            );

            if (!response?.series?.length) return;

            const documents = [];

            for (const item of response.series)
            {
                const color = getColor(item.color);

                for (const document of item.documents)
                {
                    const id = document.publicId;
                    const licence = document.licence;
                    const title = document.title;
                    const description = document.description
                        .filter((item) => item.value)
                        .map(
                            (item, index, array) =>
                                `<div class='${index !== array?.length - 1 ? 'mb-1' : ''}'>${item.key}: ${
                                    item.value || '-'
                                }</div>`
                        )
                        .join('');
                    const start = document.dateFromUtc?.toString?.();
                    const end = document.dateToUtc?.toString?.();

                    documents.push({
                        id,
                        licence,
                        title,
                        description,
                        start,
                        end,
                        color,
                        className: 'cursor-pointer',
                    });
                }
            }

            this.events = documents ?? [];
        }
        catch
        {
            this.events = [];
        }
        finally
        {
            if (routeKey != null)
                persistState(routeKey.value, filter, null, null, this.settings);
        }
    };

    public debouncedLoadData = debounce(this.loadData, 500);

    public createTooltip = (event: EventClickArg) =>
    {
        if (!event.event.extendedProps.description) return;

        const tooltip = document.createElement('span');

        tooltip.className = 'tooltip-calendar';
        tooltip.innerHTML = event.event.extendedProps.description;

        document.body.appendChild(tooltip);

        const placement = event.view.type === 'dayGridMonth' ? 'right' : 'top';

        createPopperInstance(event.el, tooltip, {
            placement,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 4],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        mainAxis: false,
                    },
                },
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: placement === 'top' ? ['top', 'bottom'] : ['right', 'left'],
                    },
                },
                {
                    name: 'arrow',
                },
            ],
        });
    };

    public destroyTooltip = () =>
    {
        const tooltips = document.querySelectorAll('.tooltip-calendar');

        tooltips.forEach((tooltip) => tooltip.remove());
    };
}
