import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { Option } from '@/helpers/Interfaces';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';

export const Definition: BlueprintDefinition = {
    type: "tab",
    name: "[[[Zakładka]]]",
    icon: "fa-bookmark",
    group: "none",
};

export interface TabContract extends AggregateBlueprint, HasWidth {
    text: Record<string, string>;
    icon: string;
    isCounter: boolean;
    counterBindWith: Option;
    isActive: boolean;
}

export class TabType
{
    public id: string;
    public type: string;
    public name: string;
    public width: number;
    public minWidth: number;
    public components: Blueprint[];
    public text: Record<string, string>;
    public icon: string;
    public isCounter: boolean;
    public counterBindWith: Option;
    public isActive: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 6;
        this.minWidth = 1;
        this.components = [];
        this.isCounter = false;
        this.counterBindWith = null;
        this.text = {};
        this.icon = Definition.icon;
        this.isActive = false;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public setDefinition(definition: BlueprintDefinition): void
    {
        this.text = definition?.text ?? { 'pl-PL': 'Zakładka' };
        this.icon = definition?.icon ?? this.icon;
    }
}
