import Pager from '@/helpers/Pager';
import { axios } from '@/plugins/axios';
import { VersionModel } from '../../inc/types';

export default {
    namespaced: true,
    state: {
        version: null,
        pager: new Pager(1, 100, 'dateCreatedUtc', 'DESC'),
        canEditProcess: false,
    } as State,
    mutations: {
        SET_VERSION(state: State, version: VersionModel): void
        {
            state.version = version;

            if (state.version && state.version.publicId)
                axios.defaults.headers.common['ModuleVersion'] = state.version.publicId;
            else delete axios.defaults.headers.common['ModuleVersion'];
        },
        SET_PROCESS_EDITION(state: State, value: boolean): void
        {
            state.canEditProcess = value;
        },
    },
};

interface State {
    version: VersionModel;
    pager: Pager;
    canEditProcess: boolean;
}
