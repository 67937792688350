<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { KeyValue } from '@/helpers/Interfaces';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { UserGroupEntry, UserGroupType, instanceOfUserGroupEntry, UserGroupTypes, KeyValueWithType } from '.';
import properties from '../../properties';

defineOptions({
    name: 'user-group-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "form": null,
  "parent": null,
  "blueprint": null,
  "entry": null,
  "index": null
});

const { $t } = useLocalization();

const blueprint = computed(() => props.blueprint);

const entryData = ref(new UserGroupEntry()) as Ref<UserGroupEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfUserGroupEntry, props.index);

const value = computed({
    get()
    {
        if (!props.blueprint.multiselect)
        {
            return entryData.value.data?.[0] ?? null;
        }

        return entryData.value.data ?? null;
    },
    set(value: KeyValueWithType | KeyValueWithType[])
    {
        if (!props.blueprint.multiselect && !Array.isArray(value))
        {
            entryData.value.data = value ? [value] : null;
        }
        else if (props.blueprint.multiselect && Array.isArray(value))
        {
            entryData.value.data = value;
        }
        else
        {
            entryData.value.data = null;
        }
    }
});

const type = computed({
    get()
    {
        return entryData.value.settings;
    },
    set(value: KeyValue<UserGroupTypes, string>)
    {
        entryData.value.settings = value;
    }
});

const required = computed(() => props.form.expressions.required(props.blueprint));
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));
const design = computed(() => props.form.designMode());
const endpoint = computed(() =>
{
    if (design.value) return null;

    if (props.entry.fieldIndex)
    {
        return `modules/${props.entry.moduleId}/table-templates/select-options/${props.entry.fieldIndex}/${props.blueprint.name}`;
    }

    return `simple-dictionary/${props.entry.module}/form/${props.entry.formId}/${props.entry.actionName}/${props.blueprint.name}`;
});

const preselect = computed(() =>
{
    if (!design.value)
    {
        if (Array.isArray(value.value) && value.value.length === 0) return true;

        if (!Array.isArray(value.value) && !value.value) return true;
    }

    return false;
});

const typeOptions = computed(() =>
{
    return [
        {
            key: UserGroupTypes.Team,
            value: $t('[[[Zespół]]]'),
        },
        {
            key: UserGroupTypes.ProcessGroup,
            value: $t('[[[Grupy użytkowników]]]'),
        },
        {
            key: UserGroupTypes.Unit,
            value: $t('[[[Dział]]]'),
        },
    ];
});

const allowedTypeOptions = computed(() =>
{
    const result: KeyValue<number, string>[] = [];

    typeOptions.value.forEach((p) =>
    {
        if (props.blueprint.groupTypes[UserGroupTypes[p.key]] === true) result.push(p);
    });

    return result;
});

const clearInputs = () =>
{
    value.value = null;
    type.value = allowedTypeOptions.value?.length ? allowedTypeOptions.value[0] : null;
};

const getGroupTypeStringKey = (key: number): string => UserGroupTypes[key];

const setSettings = () =>
{
    const _type = Array.isArray(value.value) ? value.value[0]?.type : value.value?.type;

    type.value = typeOptions.value.find((p) => p.key === _type) ?? null;
};

!preselect.value && setSettings();
</script>

<template>
    <form-component-wrapper class="userGroup-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <div class="row">
                    <ideo-select
                        v-model="value"
                        :id="blueprint.id"
                        :multiselect="blueprint.multiselect"
                        :filters="type?.key ? { [`${blueprint.name}_type`]: type.key } : null"
                        :placeholder="placeholder"
                        :endpoint="endpoint"
                        :clear-button="false"
                        class="col-sm-6 pe-sm-0"
                        custom-classes="border-radius-right-sm-none"
                        :data-test="blueprint.id"
                    >
                        <template #option="{ option }">
                            <user-avatar
                                :src="$filters.baseurl(`storage/files/${option.pictureUrl}`)"
                                :username="option.value"
                                rounded="circle"
                            />
                            <span class="ms-2 me-1">{{ option.value }}</span>
                            <span v-if="option.unit">({{ option.unit }})</span>
                        </template>
                    </ideo-select>
                    <div class="d-flex col-sm-6 ps-sm-0 mt-1 mt-sm-0">
                        <ideo-select
                            v-model="type"
                            :placeholder="$t('[[[Typ]]]')"
                            :deselect="false"
                            :clear-button="false"
                            :preselect-first-option="preselect"
                            :options="allowedTypeOptions"
                            class="flex-fill"
                            custom-classes="border-radius-right-none border-radius-left-sm-none"
                            @update:modelValue="value = null"
                        />
                        <div class="input-group-append h-100">
                            <button
                                type="button"
                                class="btn btn-light border-radius-left-none h-100"
                                @click.stop="clearInputs"
                            >
                                <i class="fas fa-fw fa-xmark"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <form-help :form="form" :blueprint="blueprint" />
                    <form-error-message :entry="entryData" name="value" />
                    <form-error-message :entry="entryData" name="custom" />
                </div>
            </div>
        </template>

        <template #properties>
            <field-name v-model="blueprint.name" :form="form" :blueprint="blueprint" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-checkbox
                v-model="blueprint.multiselect"
                :label="$t('[[[Wielokrotny wybór]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'multiselect')"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <ideo-form-group :label="$t('[[[Typy grup]]]')" :invalid-feedback="$t(form.schema.errorMessage(blueprint, 'groupTypes'))">
                <template v-for="option in typeOptions" :key="option.key">
                    <field-checkbox
                        v-model="blueprint.groupTypes[getGroupTypeStringKey(option.key)]"
                        :label="option.value"
                    />
                </template>
            </ideo-form-group>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
        </template>
    </form-component-wrapper>
</template>
