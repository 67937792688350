<template>
    <div class="w-100 h-100 d-grid align-items-center">
        <div class="d-flex flex-column align-items-center">
            <div
                v-if="sourceConfig.icon"
                :style="`background: ${getColor(sourceConfig.iconColor?.key, true)}`"
                class="icon-background"
            >
                <i :style="`color: ${getColor(sourceConfig.iconColor?.key)}`" class="icon" :class="iconClass"></i>
            </div>
            <span :style="`color: ${getColor(sourceConfig.numberColor?.key)}`" class="number">{{ getValue }}</span>
            <p v-if="config.description" class="text-center fw-semibold mt-2 mb-0">{{ $i18n.currentTranslation(config.description) }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import HomeService, { APIWidget, SourceConfigCount } from "@/modules/core/home/services/HomeService";
import { getColor, Color } from '@/modules/core/home/inc/helpers';
import GridService, { ColumnDefinitionModel } from '@/modules/core/common/services/GridService';
import { KeyValue } from '@/helpers/Interfaces';

@Options({
    name: 'CountWidget',
})
export default class CountWidget extends Vue
{
    @Prop({ default: () => ({}) })
    public config: APIWidget;

    @Prop({ default: () => ('') })
    public emitId: string;

    @Prop({ default: () => (Form.create({})) })
    public filter: any;

    public url: string = '';
    public pager: Pager | null = null;
    public customColumns: ColumnDefinitionModel | null = null;
    public value: number = null;
    public features: Array<KeyValue<string, string>> = [];

    public defaultColor: Color = 'pink';

    public get sourceConfig(): SourceConfigCount
    {
        return this.config?.sourceConfig as SourceConfigCount;
    }

    public get iconClass(): string
    {
        return this.sourceConfig.icon;
    }

    public get precision(): number
    {
        if (this.features)
        {
            const value = this.features.find((p) => p.key === 'precision')?.value;

            if (value != null) return +value;
        }

        return 0;
    }

    public get getValue(): string
    {
        return this.$filters.currencyFormat(this.value, this.precision);
    }

    public async created(): Promise<void>
    {
        this.url = this.config.dataEndpoint;

        await this.fetchData();

        if (this.emitId)
        {
            this.$events.$on(this.emitId, () =>
            {
                this.fetchData();
            });
        }
    }

    public getColor = getColor;

    public async fetchData(): Promise<boolean>
    {
        try
        {
            const result = await HomeService.getCountWidgetData(this.url, this.filter.formatData());

            this.value = result.value;
            this.features = result.features;
        }
        catch (ex)
        {
            this.value = null;
        }

        return true;
    }

    public async beforeUnmount(): Promise<void>
    {
        if (this.emitId)
            this.$events.$off(this.emitId);
    }
}
</script>

<style lang="scss" scoped>
.icon-background {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
}

.icon {
    font-size: 1.5rem;
}

.number {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.25;
}
</style>
