<template>
    <div
        class="loader"
        :style="
            `
            width: ${size}px;
            height: ${size}px;
            border: ${size / 6}px solid #f3f3f3;
            border-top: ${size / 6}px solid #3498db;
            `
        "
    ></div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { Prop } from "@/helpers/Decorators";

@Options({
    name: 'loader'
})
export default class IdeoSpinner extends Vue
{
    @Prop({ default: 20 }) public size: number;
}
</script>

<style scoped>
.loader {
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
