<script lang="ts" setup>
import { computed, ref } from 'vue';
import { Definition, PersonalType, PersonalEntryData } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { ApiQueryCondition, KeyElement, MatchQueryCondition, AndQueryCondition } from '@/helpers/ApiQueryBuilder';

defineOptions({
    name: `${Definition.type}-filter`
});

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});

const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as AndQueryCondition);
const entry = computed<PersonalEntryData>({
    get()
    {
        if (condition.value)
        {
            const clauses = condition.value.and.map(p => p as MatchQueryCondition);

            return {
                title: clauses.find(p => p.match.field.endsWith('Title'))?.match.value,
                givenName: clauses.find(p => p.match.field.endsWith('GivenName'))?.match.value,
                middleName: clauses.find(p => p.match.field.endsWith('MiddleName'))?.match.value,
                surname: clauses.find(p => p.match.field.endsWith('Surname'))?.match.value,
            };
        }

        return {
            title: null,
            givenName: null,
            middleName: null,
            surname: null
        };
    },
    set(value)
    {
        const clauses: MatchQueryCondition[] = [];

        if (condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                and: []
            });
        }

        if (value.title)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.Title`,
                    value: value.title
                }
            });
        }

        if (value.givenName)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.GivenName`,
                    value: value.givenName
                }
            });
        }

        if (value.middleName)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.MiddleName`,
                    value: value.middleName
                }
            });
        }

        if (value.surname)
        {
            clauses.push({
                match: {
                    field: `${component.value.name}.Surname`,
                    value: value.surname
                }
            });
        }

        if (clauses.length > 0)
            condition.value.and = clauses;
        else
            reset();
    }
});
const title = computed({
    get: () => entry.value.title,
    set: (value) => entry.value = {...entry.value, title: value}
});
const givenName = computed({
    get: () => entry.value.givenName,
    set: (value) => entry.value = {...entry.value, givenName: value}
});
const middleName = computed({
    get: () => entry.value.middleName,
    set: (value) => entry.value = {...entry.value, middleName: value}
});
const surname = computed({
    get: () => entry.value.surname,
    set: (value) => entry.value = {...entry.value, surname: value}
});

const resetTitle = (): void => title.value = null;
const resetGivenName = (): void => givenName.value = null;
const resetMiddleName = (): void => middleName.value = null;
const resetSurname = (): void => surname.value = null;

const display = computed(() => `${title.value ?? ''} ${givenName.value ?? ''} ${middleName.value ?? ''} ${surname.value ?? ''}`.trim() || null);
const label = computed(() => props.builder.localization.translate(component.value.label));

const reset = (): void =>
{
    model.value.remove(p => p.__key == component.value.id);
};
</script>

<template>
    <ideo-filter
        :value="entry"
        :display="display"
        :name="component.name"
        :label="label"
        :index="index"
        :visible="component.showFilter"
        menu-class="p-0"
        @clear="reset"
    >
        <ideo-input-group class="border-bottom" v-if="component.hasTitle">
            <ideo-form-input v-model="title" :placeholder="$t('[[[Tytuł]]]')" class="bg-body py-3 border-0" />
            <template #append>
                <ideo-button variant="link" icon="fas fa-fw fa-times" @click="resetTitle"></ideo-button>
            </template>
        </ideo-input-group>
        <ideo-input-group class="border-bottom">
            <ideo-form-input v-model="givenName" :placeholder="$t('[[[Imię]]]')" class="bg-body py-3 border-0" />
            <template #append>
                <ideo-button variant="link" icon="fas fa-fw fa-times" @click="resetGivenName"></ideo-button>
            </template>
        </ideo-input-group>
        <ideo-input-group class="border-bottom" v-if="component.hasMiddleName">
            <ideo-form-input v-model="middleName" :placeholder="$t('[[[Drugie imię]]]')" class="bg-body py-3 border-0" />
            <template #append>
                <ideo-button variant="link" icon="fas fa-fw fa-times" @click="resetMiddleName"></ideo-button>
            </template>
        </ideo-input-group>
        <ideo-input-group>
            <ideo-form-input v-model="surname" :placeholder="$t('[[[Nazwisko]]]')" class="bg-body py-3 border-0" />
            <template #append>
                <ideo-button variant="link" icon="fas fa-fw fa-times" @click="resetSurname"></ideo-button>
            </template>
        </ideo-input-group>
    </ideo-filter>
</template>
