import { axios } from '@/plugins/axios';
import Pager from "@/helpers/Pager";
import { Pagination, Resource, KeyValuePair } from "@/helpers/Interfaces";
import merge from "lodash/merge";
import { DateTime } from 'luxon';
import { UserModel } from "@/modules/core/users/services/UsersService";

export default class ControlCodesService
{
    public static async getList(pager: Pager, filter: any): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('finance/invoices/control-codes/list', {
            params: merge({}, pager.data(), filter)
        })).data;
    }

    public static async getControlCodeDetails(publicId: string): Promise<Resource<ControlCodesDetailsModel>>
    {
        return (await axios.get(`simple-fetch/control-codes/${publicId}`)).data;
    }

    public static async saveControlCode(params: CreateModel): Promise<CreateModel>
    {
        return (await axios.post('simple-command/control-codes/', params)).data;
    }

    public static async editControlCode(publicId: string, params: CreateModel): Promise<CreateModel>
    {
        return (await axios.put(`simple-command/control-codes/${publicId}`, params)).data;
    }

    public static async checkPermissions(publicId: string): Promise<AvailableActions>
    {
        return (await axios.get(`finance/invoices/control-codes/${publicId}/actions`)).data;
    }

    public static async getControlTypes(): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/dictionary/control-types`)).data;
    }

    public static async getControlCodes(type: number, companyId: number, issueDate: DateTime, query: string): Promise<KeyValuePair[]>
    {
        return (await axios.post(`costs/dictionary/control-codes/${type}`, {
            companyId: companyId,
            issueDate: issueDate,
            search: query
        })).data;
    }

    public static async isBudgetPosition(publicId: string): Promise<boolean>
    {
        return (await axios.get(`costs/dictionary/control-code/${publicId}/is-budget`)).data;
    }
}

export interface FilterModel
{
    name: string;
    isActive: boolean;
}

export interface CreateModel {
    publicId?: string;
    code:string;
    name:string;
    companyId:number |KeyValuePair;
    dateFromUtc:DateTime | string;
    dateToUtc?:DateTime | string;
    limit?:number;
    acceptorBelow:UserModel | number;
    acceptorAbove:UserModel | number;
    acceptorAbove2:UserModel | number;
    type: KeyValuePair|number;
    budgetAmount: number;
}

export interface ListItemModel {
    publicId: string;
    code:string;
    name:string;
    companyId:number |KeyValuePair;
    limit:number;
    isActive:boolean;
    acceptorBelow:UserModel;
    acceptorAbove:UserModel;
    acceptorAbove2:UserModel | number;
}

export interface ControlCodesDetailsModel {
    code:string;
    name:string;
    companyId:number |KeyValuePair;
    dateFromUtc:DateTime;
    dateToUtc:DateTime;
    limit:number;
    acceptorBelow:UserModel;
    acceptorAbove:UserModel;
    acceptorAbove2:UserModel | number;
    type:KeyValuePair|number;
    audit:AuditModel;
    budgetAmount: number;
}

export interface AuditModel
{
    created:CreatorAuditModel;
    modified:EditorAuditModel;
}

export interface CreatorAuditModel
{
    user:UserModel;
    impersonator:UserModel;
    dateUtc:DateTime;
}
export interface EditorAuditModel
{
    user:UserModel;
    impersonator:UserModel;
    dateUtc:DateTime;
}

export interface AvailableActions
{
    canAccept: boolean;
    canAccount: boolean;
    canChangeOwner: boolean;
    canDelete: boolean;
    canDescribe: boolean;
    canEditHeader: boolean;
    canReturnToDescription: boolean;
    canReturnToEdit: boolean;
    canSendToAccept: boolean;
    canSendToDescribe: boolean;
}
