<template>
    <div class="participant__item">
        <user-avatar
            :src="$filters.baseurl(`storage/files/${user.pictureUrl}`)"
            :username="user.value || ''"
            rounded="circle"
        />
        <div @mouseover="tooltipVisible = true" @mouseleave="tooltipVisible = false">
            <div class="participant__icon text-black" :class="value ? 'bg-success' : 'bg-danger'">
                <i v-if="value" class="fa-solid fa-lock-keyhole"></i>
                <i v-else class="fa-solid fa-lock-keyhole-open"></i>
            </div>
            <div class="participant__tooltip" :class="{ visible: tooltipVisible }">
                <div class="text-start mb-0">
                    <strong class="d-block">{{ user.value }}</strong>
                </div>
                <div v-if="value">
                    {{ $t('[[[Ustawiono dokument jako prywatny]]]') }}
                </div>
                <div v-else>
                    {{ $t('[[[Cofnięto prywatność dokumentu]]]') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from "@/helpers/Decorators";
import { UserDisplayModel } from '@/modules/core/dictionaries/services/DictionaryService';

@Options({
    components: {}
})
export default class IsPrivateUser extends Vue
{
    @Prop({ default: false }) public value: boolean;
    @Prop({ default: null }) public user: UserDisplayModel;

    public tooltipVisible: boolean = false;
}
</script>

<style lang="scss" scoped>
.participant {
    &__item {
        position: relative;
        margin-right: 15px;
    }
    &__icon {
        position: absolute;
        top: 50%;
        right: -6px;
        width: 15px;
        height: 15px;
        transform: translateY(-50%);
        border-radius: 50%;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__tooltip {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        padding: 5px 10px;
        white-space: nowrap;
        border-radius: 5px;
        background: $black;
        opacity: 0.9;
        z-index: 99;
        color: $white;
        font-size: 11px;

        @media screen and (max-width: 640px) {
            top: -100%;
            max-width: 250px;
            white-space: initial;
        }

        &.visible,
        &:focus-within {
            display: block;
        }

        .popover {
            background: $gray-900;
            border: 1px solid #333;
            b {
                color: $white;
            }
            left: auto !important;
        }
    }
}
</style>
