// Base
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { AggregateBlueprint, instanceOfAggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { RootAbstract } from '@/components/builder/base/blueprints/RootBlueprint';

// Blueprints
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { DetailsBlueprint } from '@/components/builder/details/blueprints/DetailsBlueprint';

// Traits
import { HasTitle } from '@/components/builder/form/traits/HasTitle';

export const Definition: BlueprintDefinition = {
    type: "details",
    name: "[[[Szczegóły]]]",
    group: "none",
};

export interface DetailsContract extends DetailsBlueprint, AggregateBlueprint, HasTitle {}

export class DetailsType extends RootAbstract implements DetailsContract
{
    public kind: 'details' = 'details' as const;
    public id: string;
    public type: string;
    public name: string;
    public components: Blueprint[] = [];
    public title: Record<string, string>;
    public showTitle: boolean;

    public constructor(id: string, name: string)
    {
        super();

        this.id = id;
        this.name = name;
        this.type = Definition.type;
        this.components = [];
        this.title = { 'pl-PL': 'Szczegóły' };
        this.showTitle = false;
    }
}

export const instanceOfDetailsType = (object: any): object is DetailsType =>
{
    return instanceOfAggregateBlueprint(object) && 'type' in object && object.type === Definition.type;
};
