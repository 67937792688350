<script lang="ts" setup>
import { getCurrentInstance, computed } from 'vue';

defineOptions({
    name: 'field-editor'
});

defineProps({
  "label": null,
  "modelValue": { default: '' }
});

const emit = defineEmits(["update:modelValue"]);

const instance = getCurrentInstance();

const uid = computed(() => `form-field-${instance.uid}`);

const update = (value: string): void =>
{
    emit('update:modelValue', value);
};
</script>

<template>
    <div class="form-group">
        <label :for="uid" v-if="label">{{ label }}</label>
        <editor :id="uid" :modelValue="modelValue" @update:modelValue="update"></editor>
    </div>
</template>
