<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { UserDisplayModel } from '@/modules/core/dictionaries/services/DictionaryService';
import { Options, Vue } from 'vue-class-component';

@Options({ name: "UserWithAvatarFormatter" })
export default class UserWithAvatarFormatter extends Vue
{
    @Prop({ default: null }) public property: UserDisplayModel;
    @Prop({ default: (): KeyValuePair[] => ([]) }) public features: KeyValuePair[];

    public showInfo(): void
    {
        this.$events.$emit('fetchUser', { id: this.property.key });
    }
}
</script>

<template>
    <div v-if="property" class="d-flex align-items-center">
        <UserAvatar
            :src="$filters.baseurl(`storage/files/${property.pictureUrl}`)"
            :username="`${property.value}`"
            rounded="circle"
            :key="property.key"
            class="user-avatar"
            @click="showInfo"
        />
        <span class="ms-2 user-name" @click="showInfo">{{ property.value }}</span>
    </div>
</template>

<style lang="scss">
.details-label {
    .user-avatar {
        cursor: pointer;
    }

    .user-name {
        cursor: pointer;
        color: var(--bs-link-color);

        &:hover {
            color: var(--bs-link-hover-color);
        }
    }
}
</style>
