<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';
import { getVariantColor } from '@/components/common/dynamic-grid/helpers/statusColorHelper';

@Options({
    name: 'GenericKeyValuePairFormatter',
})
export default class GenericKeyValuePairFormatter extends Vue
{
    @Prop({ default: null }) public property: KeyValuePair;
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;

    public get isBadge(): boolean
    {
        const badge = this.features.find((feature) => feature.key === 'Badge');

        return !!badge;
    }

    public get getVariant(): string
    {
        const type = this.features.find((elem) => elem.key === 'Badge');
        let func = '';

        if (type && this.isJSon(type.name))
        {
            func = JSON.parse(type.name).func;
        }
        else func = type.value;

        return getVariantColor(this.property.key, func);
    }

    public get getContent(): string
    {
        return this.property.value ?? '-';
    }

    public isJSon(jsonString: string): boolean
    {
        try
        {
            const parsed = JSON.parse(jsonString);

            if (parsed && typeof parsed === 'object') return true;
        }
        catch (e)
        {
            return false;
        }
    }
}
</script>

<template>
    <div v-if="property">
        <span v-if="!isBadge">{{ getContent }}</span>
        <ideo-badge v-else :variant="getVariant" class="status-badge" :title="getContent">
            {{ getContent }}
        </ideo-badge>
    </div>
    <span v-else>-</span>
</template>

