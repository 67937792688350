<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'DurationFormatter',
})
export default class DurationFormatter extends Vue
{
    @Prop({ default: null }) public property: { daysCount: number; minutesCount: number };
    @Prop() public features: KeyValuePair[];
    @Prop() public isOnGrid: boolean;

    public getValue(): number
    {
        const format = JSON.parse(this.features.find((def) => def.key === 'Settings')?.value)?.format;

        if (format === 'mm') return this.property.minutesCount;

        if (format === 'HH') return this.property.minutesCount / 60;

        return this.property.daysCount;
    }
}
</script>

<template>
    <div v-if="property">
        {{ getValue() }}
    </div>
</template>
