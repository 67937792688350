import { Vue } from 'vue-class-component';

class LoaderMixin extends Vue
{
    public loading: boolean = true;

    // public async created(): Promise<void>
    // {
    //     // await this.init();
    // }

    public async init(): Promise<void>
    {
        this.loading = true;

        try
        {
            await this.fetchData();
        }
        catch (error)
        {
            this.$log.error(error);
        }
        finally
        {
            setTimeout(() =>
            {
                this.loading = false;
            }, 500);
        }
    }

    public async fetchData(): Promise<void>
    {}
}
export default LoaderMixin;
