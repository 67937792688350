import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: 'services',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Klienci
            {
                path: 'tenants',
                name: 'saas-tenants',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/saas/tenants/views/TenantsList.vue'),
            },
            {
                path: 'tenants/add',
                name: 'saas-tenants-add',
                meta: { auth: true },
                component: () => import('@/modules/saas/tenants/views/TenantForm.vue'),
            },
            {
                path: 'tenants/:id(\\d+)',
                name: 'saas-tenants-edit',
                meta: { auth: true },
                component: () => import('@/modules/saas/tenants/views/TenantForm.vue'),
            },

            // Serwery baz danych
            {
                path: 'databases',
                name: 'saas-databases',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/saas/databases/views/DatabasesList.vue'),
            },
            {
                path: 'databases/add',
                name: 'saas-databases-add',
                meta: { auth: true },
                component: () => import('@/modules/saas/databases/views/DatabaseForm.vue'),
            },
            {
                path: 'databases/:id(\\d+)',
                name: 'saas-databases-edit',
                meta: { auth: true },
                component: () => import('@/modules/saas/databases/views/DatabaseForm.vue'),
            },

            // Serwery plików
            {
                path: 'storages',
                name: 'saas-storages',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/saas/storages/views/StoragesList.vue'),
            },
            {
                path: 'storages/add',
                name: 'saas-storages-add',
                meta: { auth: true },
                component: () => import('@/modules/saas/storages/views/StorageForm.vue'),
            },
            {
                path: 'storages/:id(\\d+)',
                name: 'saas-storages-edit',
                meta: { auth: true },
                component: () => import('@/modules/saas/storages/views/StorageForm.vue'),
            },
        ],
    },
];

export default routes;
