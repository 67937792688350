<template>
    <button ref="$el" type="button" @click.prevent.stop="onClick">
        <i :class="icon" :title="title"></i>
        <confirmation :message="confirm" :target="$refs.$el" placement="auto" :value="{}" @confirm="onConfirm" v-if="loaded && confirm.length > 0" />
    </button>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';

@Options({
    name: 'action-icon'
})
export default class ActionIcon extends Vue
{
    @Prop() public icon: string;
    @Prop() public title: string;
    @Prop({ default: '' }) public confirm: string;

    public loaded: boolean = false;

    public mounted(): void
    {
        this.loaded = true;
    }

    public onClick(e: any): void
    {
        if (this.confirm.length == 0)
        {
            this.onConfirm(e);
        }
    }

    @Emit('click')
    public onConfirm(e: any): any
    {
        return e;
    }
}
</script>

<style lang="scss" scoped>
button {
    border: none;
    background: transparent;
    padding: 0;
}
</style>
