<script lang="ts" setup>
import { ref, computed } from "vue";
import { useMixins } from '@/plugins/mixins';
import { useLocalization } from '@/plugins/localization';
import { createPopper as createPopperInstance, Instance as Popper } from '@popperjs/core';
import { ParticipantModel, Seen } from '@/modules/logito/office/services/RegisterService';

const emit = defineEmits(["delete"]);

const props = defineProps({
  "user": null,
  "index": { default: '' },
  "customClass": { default: '' },
  "tooltip": { type: Boolean, default: true },
  "canDelete": { type: Boolean,  },
  "added": { type: Boolean,  },
  "removed": { type: Boolean,  }
});

const { $filters } = useMixins();
const { $t } = useLocalization();

const popper = ref<Popper>(null);
const icon = ref<HTMLElement>(null);
const menu = ref<HTMLElement>(null);

const isSomeoneHaveSeen = computed(() => props.user.seenBy?.length > 0);

const deleteItem = (user: ParticipantModel): void =>
{
    emit('delete', user);
};

const messageSeenBy = (seenBy: Seen): string =>
{
    const user = seenBy.seenBy;
    const impersonator = seenBy.seenByImpersonator;
    const date = $filters.datetime(seenBy.seenDateUtc);

    return `${user.value} ${impersonator ? `(${impersonator.value})` : ''} ${$t('[[[zobaczył/a]]]')} ${date}`;
};

const createTooltip = () =>
{
    popper.value = createPopperInstance(icon.value, menu.value, {
        strategy: 'fixed',
        placement: 'right',
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['top'],
                },
            }
        ],
    });
};

const destroyTooltip = () =>
{
    if (popper.value)
    {
        popper.value.destroy();
    }
};

const show = (): void =>
{
    createTooltip();
    menu.value.setAttribute('data-show', '');
};

const hide = (): void =>
{
    destroyTooltip();
    menu.value.removeAttribute('data-show');
};
</script>

<template>
    <div class="participant__item">
        <user-avatar
            :src="$filters.baseurl(`storage/files/${user.user ? user.user.pictureUrl : user.user.pictureUrl}`)"
            :username="user.user.value || ''"
            rounded="circle"
            :class="customClass"
        />
        <div v-if="tooltip" class="participant__container" @mouseenter="show" @mouseleave="hide">
            <div ref="icon" class="participant__icon text-white" :class="added ? 'bg-success' : removed ? 'bg-danger' : 'bg-dark'">
                <i v-if="added" class="fa fa-check"></i>
                <i v-else-if="removed" class="fa fa-minus"></i>
                <template v-else>
                    <i v-if="isSomeoneHaveSeen" class="fas fa-eye"></i>
                    <i v-else class="fas fa-info"></i>
                </template>
            </div>
            <teleport to="body">
                <div ref="menu" class="participant__tooltip" @mouseenter="show" @mouseleave="hide">
                    <div class="text-start mb-0">
                        <strong class="d-block">{{ user.user ? user.user.value : '' }}</strong>
                        <template v-if="user?.seenBy">
                            <div v-for="seenBy in user.seenBy" :key="seenBy.seenBy.key">
                                {{ messageSeenBy(seenBy) }}
                            </div>
                        </template>
                        <template v-if="user && user.roles && user.roles.length">
                            <div v-for="(role, roleIndex) in user.roles" :key="roleIndex">
                                {{ $t('[[[Rola]]]') }}: {{ role.value }}
                                <template v-if="roleIndex + 1 < user.roles.length">, </template>
                            </div>
                        </template>
                        <div v-if="user && user.audit">
                            {{ $t('[[[Udostępnił]]]') }}: {{ user.audit.user.name }} {{ $t('[[[Data]]]') }}:
                            {{ $filters.datetime(user.audit.dateUtc) }}
                        </div>
                        <slot />
                        <ideo-button
                            v-if="canDelete"
                            class="my-1"
                            :id="`btn-delete-${index}`"
                            size="sm"
                            variant="danger"
                            :title="$t('[[[Odbierz uprawnienie]]]')"
                        >
                            {{ $t('[[[Odbierz uprawnienie]]]') }}
                        </ideo-button>
                        <confirmation
                            v-if="canDelete"
                            :message="$t('[[[Potwierdzenie usunięcia]]]')"
                            :target="`btn-delete-${index}`"
                            :placement="'right'"
                            @confirm="deleteItem(user)"
                        />
                    </div>
                </div>
            </teleport>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.participant {
    &__item {
        position: relative;
        margin-right: 15px;
    }

    &__container {
        position: absolute;
        top: 50%;
        right: -6px;
        transform: translateY(-50%);
    }

    &__icon {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__tooltip {
        display: none;
        padding: 5px 10px;
        white-space: wrap;
        border-radius: 5px;
        background: var(--bs-black);
        opacity: 0.9;
        color: var(--bs-white);
        font-size: 11px;
        max-width: 270px;
        z-index: 9999;

        &[data-show] {
            display: block;
        }
    }
}
</style>
