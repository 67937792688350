<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, LikertType } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});

const { $t } = useLocalization();

const display = computed(() =>
{
    const count = Object.entries(props.value).length;

    if (count > 0)
        return $t('[[[Odpowiedzi: %0 na %1|||{0}|||{1}]]]', count, props.component.questions.length);

    return null;
});
</script>

<template>
    {{ display }}
</template>
