import { KeyValue } from '@/helpers/Interfaces';
import { APIWidget, SitemapEntryType, WidgetTypeEnum } from '@/modules/core/home/services/HomeService';
import { $t } from '@/plugins/localization';
import { kebabCase } from 'lodash';

export const getWidgetType = (widget: APIWidget): string =>
{
    if (widget.staticWidgetType)
        return widget.staticWidgetType?.key;

    if (widget.sourceType?.key == 'Sitemap')
    {
        const widgetType = getWidgetTypeBySitemapEntry(widget.sitemapEntryDetails?.entryType ?? null);

        if (widgetType)
            return widgetType;
    }

    return widget.sourceType?.key;
};

export const getWidgetTypeBySitemapEntry = (entryType: string): string =>
{
    switch (entryType)
    {
        case SitemapEntryType.Grid:
            return WidgetTypeEnum.List;
        default:
            return entryType;
    }

    return null;
};

export const months = (): KeyValue<number, string>[] => [
    { key: 1, value: $t('[[[Styczeń]]]') },
    { key: 2, value: $t('[[[Luty]]]') },
    { key: 3, value: $t('[[[Marzec]]]') },
    { key: 4, value: $t('[[[Kwiecień]]]') },
    { key: 5, value: $t('[[[Maj]]]') },
    { key: 6, value: $t('[[[Czerwiec]]]') },
    { key: 7, value: $t('[[[Lipiec]]]') },
    { key: 8, value: $t('[[[Sierpień]]]') },
    { key: 9, value: $t('[[[Wrzesień]]]') },
    { key: 10, value: $t('[[[Październik]]]') },
    { key: 11, value: $t('[[[Listopad]]]') },
    { key: 12, value: $t('[[[Grudzień]]]') },
];

export const quarters = (): KeyValue<number, string>[] => [
    { key: 1, value: $t('[[[Q1]]]') },
    { key: 2, value: $t('[[[Q2]]]') },
    { key: 3, value: $t('[[[Q3]]]') },
    { key: 4, value: $t('[[[Q4]]]') },
];

export type Color =
    | 'pink'
    | 'blue'
    | 'green'
    | 'orange'
    | 'purple'
    | 'sea'
    | 'dark-green'
    | 'red'
    | 'light-green'
    | 'yellow'
    | 'light-purple'
    | 'light-yellow';

export const colors: Record<Color, string> = {
    blue: '--w-color-blue',
    yellow: '--w-color-yellow',
    green: '--w-color-green',
    orange: '--w-color-orange',
    purple: '--w-color-purple',
    sea: '--w-color-sea',
    red: '--w-color-red',
    'light-green': '--w-color-light-green',
    'light-purple': '--w-color-light-purple',
    'dark-green': '--w-color-dark-green',
    pink: '--w-color-pink',
    'light-yellow': '--w-color-light-yellow',
};

export const getColor = (name: string, background = false): string =>
{
    const nameKebab = kebabCase(name) as Color;
    const rootVariables = getComputedStyle(document.querySelector(':root'));
    const opacity = background ? 0.2 : 1;
    const colorVariable = colors[nameKebab] ?? colors.blue;
    const colorValue = rootVariables.getPropertyValue(colorVariable);

    return `rgba(${colorValue}, ${opacity})`;
};
