<script lang="ts" setup>
import { computed } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, PersonalType } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});

const display = computed(() => `${props.value?.Title ?? ''} ${props.value?.GivenName ?? ''} ${props.value?.MiddleName ?? ''} ${props.value?.Surname ?? ''}`.trim());
</script>

<template>
    <span v-if="display">
        {{ display }}
    </span>
    <span v-else>-</span>
</template>
