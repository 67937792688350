<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { diffChars, diffWords, diffLines, Change } from 'diff';
import { datesToString, isProxy } from '@/helpers/Utils';
import { escape, isArray, isObject } from 'lodash';
import dot from 'dot-object';

const props = defineProps({
  "current": null,
  "version": { default: null }
});

const emit = defineEmits(["close"]);

const visible = ref(false);
const mode = ref(1);
const modes = [
    { key: 0, text: '[[[znaki]]]', algorithm: diffChars },
    { key: 1, text: '[[[słowa]]]', algorithm: diffWords },
    { key: 2, text: '[[[linie]]]', algorithm: diffLines }
];
const modeName = computed(() => modes.find(p => p.key == mode.value).text);
const modeDiff = computed(() => modes.find(p => p.key == mode.value).algorithm);
const changes = computed(() =>
{
    const current = dot.dot(datesToString(props.current));
    const compare = dot.dot(datesToString(props.version));
    const keys = [...Object.keys(current), ...Object.keys(compare)].filter((v, i, a) => a.indexOf(v) === i);
    const values = keys
        .map(key =>
        {
            const currentValue = serialize(current[key]);
            const compareValue = serialize(compare[key]);

            return {
                key: key,
                value: currentValue != compareValue ? modeDiff.value(currentValue, compareValue) : null
            };
        })
        .filter(p => p.value != null);

    return values;
});

watch(() => props.version, () =>
{
    visible.value = props.version != null;
});

function getText(diff: Change[])
{
    let text = '';

    !isProxy(diff) && diff.forEach(part =>
    {
        const color = part.added ? 'text-success' : part.removed ? 'text-danger' : '';

        text += `<span class="${color}">${escape(part.value)}</span>`;
    });

    return text;
}

function serialize(value: any)
{
    if (value && (isObject(value) || isArray(value)))
    {
        return JSON.stringify(value);
    }

    if (value)
    {
        return `${value}`;
    }

    return '';
}

function close(cancel: () => void)
{
    cancel();
    emit('close');
}
</script>

<template>
    <ideo-modal v-model="visible" size="xl" static scrollable>
        <template #modal-header>
            <div class="d-flex px-2 w-100">
                <span class="text-danger me-auto">{{ $t('[[[Bieżący dokument]]]') }}</span>
                <span class="text-success">{{ $t('[[[Porównywana wersja]]]') }}</span>
            </div>
        </template>
        <div v-for="(item, index) in changes" :key="item.key" :class="['p-2', {'bg-light-subtle': index % 2 == 0}]">
            <h6>{{ item.key }}</h6>
            <div v-html="getText(item.value)"></div>
        </div>
        <template #modal-footer="{ cancel }">
            <div class="d-flex justify-content-between w-100">
                <ideo-dropdown :text="$t('[[[Porównaj %0|||{0}]]]', $t(modeName))" right>
                    <ideo-dropdown-item-button v-for="item in modes" :key="item.key" @click="mode = item.key">
                        {{ $t('[[[Porównaj %0|||{0}]]]', $t(item.text)) }}
                    </ideo-dropdown-item-button>
                </ideo-dropdown>
                <button type="button" class="btn btn-primary" @click.stop="close(cancel)">{{ $t('[[[Zamknij]]]') }}</button>
            </div>
        </template>
    </ideo-modal>
</template>
