export default class FlowManager
{
    private flow: string[];
    private endStatus: string;
    private diversionNext: string;

    public constructor(flow: string[] = [], endStatus: string = null)
    {
        this.flow = flow;
        this.endStatus = endStatus;
        this.diversionNext = null;
    }

    public step(index: number): string
    {
        return this.flow.length > index ? this.flow[index] : this.endStatus;
    }

    public first(): string
    {
        return this.step(0);
    }

    public next(current: string): string
    {
        if (this.diversionNext)
        {
            const next = this.diversionNext;

            this.diversionNext = null;

            return next;
        }

        return this.step(this.flow.indexOf(current) + 1);
    }

    public diversion(next: string): void
    {
        this.diversionNext = next;
    }
}
