import { axios } from '@/plugins/axios';
import Pager from "@/helpers/Pager";
import { KeyValue, Pagination, Resource, Statement, KeyValuePair } from "@/helpers/Interfaces";
import merge from "lodash/merge";
import { UserModel } from "@/modules/core/users/services/UsersService";
import { DateTime } from 'luxon';
import { TaxLineModel } from "@/modules/low-code/services/TaxLineService";
import { DocumentModel } from "@/modules/logito/office/services/OfficeDocuments";
import { SimplePartner } from '@/modules/core/dictionaries/services/DictionaryService';

interface ProcessActionsButton {
    can: boolean;
    buttonName: KeyValuePair;
}

interface BpmnActionButton {
    url: string;
    buttonName: KeyValuePair;
    formType: string,
    endpointId: number,
    formId: number;
}

export default class InvoicesService
{
    public static async getProformas(companyId: number, issuerPublicId: string): Promise<KeyValuePair[]>
    {
        return (await axios.get<Promise<KeyValuePair[]>>('finance/invoices/list/proforms',
            {
                params: { companyId: companyId, issuerPublicId: issuerPublicId }
            })).data;
    }

    public static async getMyTasksList(pager: Pager, filter: any): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('finance/invoices/list/my-tasks', {
            params: merge({}, pager.data(), filter)
        })).data;
    }

    public static async getPendingList(pager: Pager, filter: any): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('finance/invoices/list/pending', {
            params: merge({}, pager.data(), filter)
        })).data;
    }

    public static async getSearchList(pager: Pager, filter: any): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('finance/invoices/list/search', {
            params: merge({}, pager.data(), filter)
        })).data;
    }

    public static async getScannedList(pager: Pager, filter: any): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('finance/invoices/list/scanned', {
            params: merge({}, pager.data(), filter)
        })).data;
    }

    public static async getInvoiceDetails(publicId: string): Promise<Resource<InvoiceDetailsModel>>
    {
        return (await axios.get(`finance/invoices/${publicId}`)).data;
    }

    public static async saveInvoice(params: CreateModel): Promise<CreateModel>
    {
        return (await axios.post('finance/invoices', params)).data;
    }

    public static async editInvoice(publicId: string, params: CreateModel): Promise<CreateModel>
    {
        return (await axios.post(`finance/invoices/${publicId}`, params)).data;
    }

    public static async getPaymentForms(): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/dictionary/payment-forms`)).data;
    }

    public static async getDocumentTypes(): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/dictionary/document-types`)).data;
    }

    public static async getPaymentMethods(): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/dictionary/payment-methods`)).data;
    }

    public static async getPaymentStates(): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/dictionary/payment-states`)).data;
    }

    public static async getInvoiceStates(): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/dictionary/invoice-states`)).data;
    }

    public static async checkPermissions(publicId: string): Promise<AvailableActions>
    {
        return (await axios.get(`finance/invoices/${publicId}/actions`)).data;
    }

    public static async sendToOwner(publicId: string, userId: number, comment: string, attachments: string[]): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/change-owner`, {
            userId: userId,
            comment: comment,
            attachments: attachments
        })).data;
    }

    public static async sendToExplain(publicId: string, userId: number, comment: string): Promise<Statement>
    {
        return (await axios.post(`finance/invoices/${publicId}/to-explain`, {
            userId: userId,
            comment: comment
        })).data;
    }

    public static async explain(publicId: string, comment: string): Promise<Statement>
    {
        return (await axios.post(`finance/invoices/${publicId}/explain`, {
            comment: comment
        })).data;
    }

    public static async delete(publicId: string, comment: string): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/remove`, {
            comment: comment
        })).data;
    }

    public static async sendToDescribe(publicId: string, userId: number, comment: string): Promise<Statement>
    {
        return (await axios.post(`finance/invoices/${publicId}/to-describe`, {
            userId: userId,
            comment: comment
        })).data;
    }

    public static async sendToAccept(publicId: string, comment: string, attachments: string[]): Promise<Statement>
    {
        return (await axios.post(`finance/invoices/${publicId}/to-accept`, {
            comment: comment,
            attachments: attachments
        })).data;
    }

    public static async accept(publicId: string, comment: string, attachments: string[]): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/accept`, {
            comment: comment,
            attachments: attachments
        })).data;
    }

    public static async approve(publicId: string, comment: string): Promise<Statement>
    {
        return (await axios.post(`finance/invoices/${publicId}/approve`, {
            comment: comment
        })).data;
    }

    public static async pay(publicId: string, payDate: string, attachments: string[], comment: string): Promise<Statement>
    {
        return (await axios.post(`finance/invoices/${publicId}/pay`, {
            payDate: payDate,
            attachments: attachments,
            comment: comment
        })).data;
    }

    public static async groupPay(invoices: string[], model: any): Promise<Statement>
    {
        return (await axios.post(`finance/invoices/list/pay`, {
            invoices: invoices,
            model: model
        })).data;
    }

    public static async returnToEdit(publicId: string, comment: string): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/return-secretary`, {
            comment: comment
        })).data;
    }

    public static async returnToDescription(publicId: string, comment: string): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/return-owner`, {
            comment: comment
        })).data;
    }

    public static async getDescriptionLinesHeaders(documentId: string, licence: string): Promise<DocumentLineDefinition>
    {
        return (await axios.get(`costs/dictionary/${licence}/definition/description-lines/${documentId}`)).data;
    }

    public static async getDescriptionLines(moduleName:string, publicId: string, pager: Pager): Promise<Pagination<Resource<DescriptionLineDetailsModel>>>
    {
        return (await axios.get(`simple-query/${moduleName}/${publicId}/descriptions`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async saveDescriptionLines(moduleName:string, documentId: string, form: DescriptionLineFormModel): Promise<Statement>
    {
        return (await axios.post(`simple-command/${moduleName}/${documentId}/descriptions`, form)).data;
    }

    public static async editLine(rowPublicId: string, form: DescriptionLineFormModel): Promise<Statement>
    {
        return (await axios.put(`simple-command/descript/${rowPublicId}`, form)).data;
    }

    public static async deleteLine(rowPublicId: string): Promise<Statement>
    {
        return (await axios.delete(`simple-command/descript/${rowPublicId}`)).data;
    }

    /**
     * @returns Promise<Statement>
     */
    public static async saveBpmnDescriptionLines(endpointId: number, documentId: string, form: DescriptionLineFormModel): Promise<Statement>
    {
        return (await axios.post(`office/documents/${documentId}/process/${endpointId}/invoice-description`, form)).data;
    }

    /**
     * @returns Promise<Statement>
     */
    public static async editBpmnLine(endpointId: number, documentId: string, form: DescriptionLineFormModel, rowPublicId: string): Promise<Statement>
    {
        return (await axios.put(`office/documents/${documentId}/process/${endpointId}/invoice-description/${rowPublicId}`, form)).data;
    }

    /**
     * @returns Promise<Statement>
     */
    public static async deleteBpmnLine(endpointId: number, documentId: string, rowPublicId: string): Promise<Statement>
    {
        return (await axios.delete(`office/documents/${documentId}/process/${endpointId}/invoice-description/${rowPublicId}`)).data;
    }

    public static async getRateForCurrencyByDate(filter:ExchangeRateFilter): Promise<ExchangeRate>
    {
        return (await axios.get(`finance/invoices/dictionary/exchange-rates`, {
            params: merge({}, filter)
        })).data;
    }

    public static async getAccountInfo(documentId: string): Promise<AccountModel>
    {
        return (await axios.get(`finance/invoices/${documentId}/account/external`)).data;
    }

    public static async accountManual(documentId: string, form: AccountModel): Promise<Statement>
    {
        return (await axios.post(`finance/invoices/${documentId}/account/manual`, form)).data;
    }

    public static async accountExternal(documentId: string, form: AccountModel): Promise<Statement>
    {
        return (await axios.post(`finance/invoices/${documentId}/account/external`, form)).data;
    }

    public static async getInvoiceTypes(): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/dictionary/invoice-types`)).data;
    }

    public static async getBankAccounts(contractorId: string, companyId: number): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/dictionary/${contractorId}/${companyId}/bank-accounts`)).data;
    }

    public static async getDefaultOwner(contractor: string): Promise<KeyValuePair>
    {
        return (await axios.get(`finance/invoices/dictionary/${contractor}/default-owner`)).data;
    }

    public static async getLog(publicId: string): Promise<any>
    {
        return (await axios.get(`office/documents/${publicId}/change-log`)).data;
    }

    public static async getExcel(filter: any): Promise<any>
    {
        return (await axios({
            method: 'get',
            url: 'finance/invoices/list/excel',
            headers:
                {
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
            responseType: 'blob',
            params: filter
        })).data;
    }

    public static async getToPayList(pager: Pager, filter: any): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('finance/invoices/list/to-pay', {
            params: merge({}, pager.data(), filter)
        })).data;
    }

    public static async getInvoiceFlow(publicId: string): Promise<KeyValuePair[]>
    {
        return (await axios.get(`finance/invoices/${publicId}/flow`)).data;
    }

    public static async importLines(module: string, publicId: string, file: File) : Promise<Statement>
    {
        const form = new FormData();

        form.append('file', file);

        return (await axios.post(`description/${module}/${publicId}/import`, form)).data;
    }

    public static async applyTemplate(module: string, publicId: string, templateId: string): Promise<Statement>
    {
        return (await axios.post(`description/${module}/${publicId}/apply-template/${templateId}`)).data;
    }
}

export interface CreateModel {
    publicId?: string;
    registerId: 0 | KeyValuePair;
    dateSale: DateTime | string;
    dateReceipt: DateTime | string;
    dateIssue: DateTime | string;
    datePayment: DateTime | string;
    dateRate: DateTime | string;
    ownerId: UserModel | number;
    currency: string | KeyValuePair;
    netAmount: Amount | number;
    grossAmount: Amount | number;
    description: string;
    signatureIncoming: string;
    signatureCorrection: string;
    orderNumber: string;
    issuerId: number | string | SimplePartner;
    isAcceptedSimilarityRisk: boolean;
    proform?: string | KeyValuePair;
    invoiceType: number | string;
    paymentForm: number | KeyValuePair;
    lines: string[];
    fileId: string;
    files: string[];
}

export interface ListItemModel {
    publicId: string;
    status: KeyValuePair;
    signatureIncoming: string;
    signatureInternal: string;
    issuer: string;
    issueDate: string;
    paymentDate: string;
    currency: string;
    netAmount: number
    grossAmount: number;
    description: string
}

export interface InvoiceDetailsModel extends DocumentModel {
    proform: KeyValuePair | string;
    accountingDate: string;
    dateReceipt: DateTime | string;
    dateSale: DateTime | string;
    issueDate: DateTime | string;
    paymentDate: DateTime | string;
    audit: Audit;
    owner: UserModel;
    netAmount: Amount;
    grossAmount: Amount;
    taxAmount: Amount;
    description: string;
    documentId: number;
    signatureIncoming: string;
    signatureInternal: string;
    signatureCorrection: string;
    orderNumber: string;
    signatureExport: string;
    issuer: SimplePartner;
    source: KeyValuePair;
    status: KeyValuePair;
    invoiceType: KeyValuePair;
    paymentForm: KeyValuePair;
    paymentStatus: KeyValuePair;
    created: string;
    vatInvoice?: KeyValuePair | string;
    ocrUrl: string;
    account: KeyValuePair;
    barcode: string;
    ksefNumber: string;
}

export interface Currency {
    amount: number;
    currency: string;
}

export interface Amount {
    originalCurrency: Currency;
    calculatedCurrency: Currency;
    dateCalculated: string;
    rate: number;
}

export interface Audit {
    company: KeyValue<number, string>;
    register: KeyValuePair;
    created: AuditDate;
    modified: AuditDate;
}

export interface AuditDate {
    user: UserModel;
    impersonator: UserModel;
    dateUtc: string;
}

export interface AvailableActions {
    canEditHeader: boolean;
    canDescribe: boolean;
    canAccount: boolean;
    canExplain?: boolean;
    canSendToExplain?: boolean;
    canApprove?: boolean;
    canPay: boolean;
    accept: ProcessActionsButton;
    reject: ProcessActionsButton;
    remove: ProcessActionsButton;
    returnSecretary: ProcessActionsButton;
    returnToOwner: ProcessActionsButton;
    toOwner: ProcessActionsButton;
    bpmn: BpmnActionButton[];
    canPrint?: boolean;
}

export interface DescriptionLineFormModel {
    attributes: DescriptionLineCellFormModel[];
    taxCode: number | KeyValuePair;
    description: string;
    netAmount: number
}

export interface DescriptionLineCellFormModel {
    definitionCellId: number;
    controlCodeId: number | KeyValuePair;
}

export interface DescriptionLineDetailsModel {
    attributes: DescriptionLineCellDetailsModel[];
    taxCode: number | KeyValuePair;
    description: string;
    netAmount: number;
    publicId?: string;
}

export interface DescriptionLineCellDetailsModel {
    key: string;
    value: string;
    cellId: number;
}

export interface CellDefinition {
    cellId: number;
    propertyName: string;
    displayName: string;
    enabled: boolean;
    type: number;
    required: boolean;
}

export interface ExchangeRate{
    rate:number;
    date:DateTime;
}
export interface ExchangeRateFilter{
    currencyFrom:string;
    currencyTo:string;
    date:DateTime;
}

export interface AccountModel {
    reportPeriod: string;
    paymentMethod: 0;
    comment: string;
    lines: TaxLineModel[];
    contractor: KeyValuePair;
    contractorAccount: number;
    documentType: number;
    externalSignature: string;
    attachments?: string[];
    accountType?: number;
}

export interface DocumentLineDefinition {
    attributes: CellDefinition[],
    vatEnabled: boolean,
    validationAmount: number
    vatLines: Record<string, number>;
}
