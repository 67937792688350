<script lang="ts" setup>
import { ref, reactive, computed, onMounted, onUnmounted } from 'vue';
import { useEvents } from '@/plugins/events';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import AddModal from './AddModal.vue';

const props = defineProps({
  "parentType": null,
  "documentId": null,
  "blueprintName": null
});

const { $events } = useEvents();

const url = 'document-notifications';
const actionObjectType = 'document-notification';
const modal = ref<InstanceType<typeof AddModal> | null>(null);
const pager = reactive(new Pager(1, 10, 'Id', 'DESC')) as Pager;
const filter = reactive(Form.create({}));

const endpoint = computed(() => `${props.parentType}/${props.documentId}/${url}`);

const addNotification = (guid?: string) =>
{
    modal.value?.showModal(guid);
};

onMounted(() =>
{
    $events.$on('edit-create-modal', addNotification);
});

onUnmounted(() =>
{
    $events.$off('edit-create-modal', addNotification);
});
</script>

<template>
    <div>
        <dynamic-grid :pager="pager" :filter="filter" :endpoint="endpoint" :manual-init="true">
            <template #header>
                <ideo-panel :column="false" :stretch="false" :start="desktop">
                    <template #start>
                        <action-button
                            variant="success"
                            icon="fa fa-plus"
                            :text="$t('[[[Dodaj powiadomienie weryfikacyjne]]]')"
                            @click="addNotification()"
                        />
                    </template>
                    <template #end>
                        <export-manager
                            :excel="{ endpoint }"
                            :filter="filter"
                            :pager="pager"
                        />
                    </template>
                </ideo-panel>
            </template>

            <template #actions="{ item }">
                <row-actions
                    :endpoint="`${actionObjectType}/${item.result?.publicId}/actions`"
                    :except="['document-notifications-create']"
                    :id="item.result.publicId"
                    :url="actionObjectType"
                />
            </template>

            <template #modal>
                <add-modal ref="modal" :url="parentType" :public-id="documentId" />
            </template>
        </dynamic-grid>

        <portal v-if="blueprintName" :to="`tab-counter-${blueprintName}`">
            {{ pager.getTotalRows() }}
        </portal>
    </div>
</template>
