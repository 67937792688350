<script lang="ts" setup>
import { ref, computed, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';
import { useSignalR } from '@/plugins/signalr';
import { useAlerts } from '@/plugins/alerts';
import { useMixins } from '@/plugins/mixins';
import { useEvents } from '@/plugins/events';
import { useLocalization } from '@/plugins/localization';
import { MessageModel } from '@/modules/core/common/services/NotificationsService';
import Pager from '@/helpers/Pager';

const router = useRouter();
const { $alert } = useAlerts();
const { $handleException, $filters } = useMixins();
const { $t } = useLocalization();
const { $events } = useEvents();

const { channel } = useSignalR();
const server = channel('notifications');

const dropdownRef = ref<any>(null);
const timeout = ref<any>(null);
const messages = ref<MessageModel[]>([]);
const total = ref(0);

const messagesList = computed<MessageModel[]>(() => messages.value.slice(0, 5));
const displayTotal = computed<string>(() => total.value > 99 ? '99+' : total.value.toString());

const loadData = async (): Promise<boolean> =>
{
    try
    {
        const result: any = await server.invoke.getMessages({ isRead: false, search: '' }, new Pager(1, 5, 'Id', 'DESC').data());

        messages.value = result.items.map((p: any) => p.result);
        total.value = result.totalRows;
    }
    catch (ex)
    {
        $handleException(ex, {
            0: (ex: any)=> $alert.danger($t('[[[Nie udało się pobrać powiadomień.]]]'))
        });
    }

    return true;
};

onBeforeMount(async (): Promise<void> =>
{
    await loadData();
});

const read = async (message: MessageModel): Promise<void> =>
{
    const args = message.payload.args;

    if (!message.isRead)
    {
        message.isRead = true;

        await server.invoke.markAsRead(message.uid);

        if (timeout.value)
            clearTimeout(timeout.value);

        timeout.value = setTimeout(async (): Promise<void> =>
        {
            await loadData();
            $events.$emit('notifications::reload-list');

            if (args?.licence && args?.publicId)
            {
                router.push({ name: "module-form-details", params: { licence: args.licence, publicId: args.publicId }});
                dropdown.value.hide();
            }
        }, 1000);
    }
};

const forceDownload = (url: string, fileName: string): void =>
{
    location.href = $filters.baseurl(`${url}?dl`);
};

server.on.messageReceived = async (payload: any): Promise<void> =>
{
    total.value++;
    messages.value.unshift({
        id: 0,
        uid: payload.uid,
        dateCreatedUtc: DateTime.utc(),
        payload,
        isRead: false
    });

    ($alert as any)[payload.level](payload.data.message);

    const { action, url, filename } = payload.args ?? {};

    switch (action)
    {
        case 'download':
            forceDownload(url, filename);
            break;
    }
};

onMounted(() =>
{
    $events.$on('notifications::reload-dropdown', loadData);
});

onBeforeUnmount(() =>
{
    $events.$off('notifications::reload-dropdown', loadData);
});

const onShowAllClick = async (): Promise<void> =>
{
    dropdownRef.value.hide();

    await router.push({name: 'core-account-notifications', query: { status: 'unreaded' }});
    $events.$emit('notifications::reload-list');
};
</script>

<template>
    <ideo-dropdown ref="dropdownRef" class="notifications-dropdown" toggle-class="navbar-toggler" variant="menu" no-caret right>
        <template #button-content>
            <span class="position-relative">
                <i class="fas fa-bell"></i>
                <span class="badge text-bg-danger position-absolute top-0 start-100 translate-middle ms-2 z-3" v-if="total > 0">{{ displayTotal }}</span>
            </span>
        </template>

        <div class="text-center mt-2 mb-3" v-if="total === 0">
            {{ $t('[[[Brak nowych powiadomień.]]]') }}
        </div>
        <template v-else>
            <ideo-dropdown-item-button
                v-for="item in messagesList"
                :key="item.id"
                @click.stop="read(item)"
                prevent-close
            >
                <i v-if="item.isRead" class="icon far fa-spinner-third fa-spin text-muted"></i>
                <i v-else-if="item.payload.level === 'success'" class="icon far fa-check-circle text-success"></i>
                <i v-else-if="item.payload.level == 'info'" class="icon far fa-info-circle text-info"></i>
                <i v-else-if="item.payload.level == 'warning'" class="icon far fa-exclamation-circle text-warning"></i>
                <i v-else-if="item.payload.level == 'error'" class="icon far fa-exclamation-triangle text-danger"></i>

                <div>
                    <span class="title">
                        {{ item.payload.data.message }}
                    </span>

                    <span class="date">
                        {{ $filters.datetime(item.dateCreatedUtc) }}
                    </span>
                </div>
            </ideo-dropdown-item-button>
        </template>

        <div class="dropdown-divider"></div>

        <ideo-button @click="onShowAllClick" variant="link" block class="show-all">
            {{ $t('[[[Zobacz wszystkie]]]') }}
        </ideo-button>
    </ideo-dropdown>
</template>

<style lang="scss">
.notifications-dropdown {
    position: relative;
    z-index: 9999;
    font-size: 20px;

    .dropdown {
        &-menu {
            padding: 0.5rem 1rem;
            width: 300px;
            z-index: 10001;
            max-height: calc(100vh - 65px);
            overflow: auto;
        }
        &-toggle {
            i.fa-bell {
                font-size: 1.125rem;
                color: var(--bs-navbar-color);
            }
        }
        &-item {
            white-space: unset;
            padding: 0.625rem;
            border-radius: 0.313rem;
            background: var(--bs-tertiary-bg);
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            &:hover {
                background: var(--bs-secondary-bg);
            }
            i {
                font-size: 1rem;
            }
            .title, .date {
                display: block;
                color: var(--bs-body-color);
            }
            .title {
                margin-bottom: 0.625rem;
            }
        }
        .show-all {
            text-transform: unset;
            font-size: 0.875rem;
            font-weight: 400;
        }
    }
}
</style>
