<template>
    <component :is="tag" :class="customClasses" :to="to" :data-dd="$.uid" v-bind="attrs">
        <slot name="default"></slot>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-dropdown-item',
    inheritAttrs: false
})
export default class IdeoDropdownItem extends Vue
{
    @Prop({ default: false })
    public active: boolean;

    @Prop({ default: 'active' })
    public activeClass: string;

    @Prop({ default: false })
    public disabled: boolean;

    @Prop({ default: false })
    public smaller: boolean;

    @Prop({
        default: null,
        validator: (value: string) => ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].includes(value)
    })
    public variant: string;

    public get tag(): string
    {
        return ('to' in this.$attrs) ? 'router-link' : 'a';
    }

    public get to(): any
    {
        return ('to' in this.$attrs) ? this.$attrs.to : undefined;
    }

    public get attrs(): any
    {
        return this.tag == 'a' ? { ...this.$attrs, href: this.$attrs.href || '#' } : this.$attrs;
    }

    public get customClasses(): Record<string, boolean>
    {
        return {
            'dropdown-item': true,
            'disabled': this.disabled,
            'py-2': this.smaller,
            [this.activeClass]: this.active,
            [`text-${this.variant}`]: this.variant != null
        };
    }
}
</script>
