<script lang="ts" setup>
import { computed } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, RelatedParentType } from '.';
import { ApiQueryCondition, KeyElement, TermsQueryCondition } from '@/helpers/ApiQueryBuilder';
import DocumentsService from '@/modules/studio/documents/services/DocumentsService';
import IdeoFilterMultiple from '@/components/filters/types/IdeoFilterMultiple.vue';

defineOptions({
    name: `${Definition.type}-filter`
});

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});

const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as TermsQueryCondition);
const entry = computed({
    get()
    {
        return condition.value?.terms.values.map(p => Number(p)) || [];
    },
    set(value)
    {
        if (condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                terms: {
                    field: `${component.value.name}.Value`,
                    values: value?.map(p => p.toString()) || []
                }
            });
        }

        condition.value.terms.values = value?.map(p => p.toString()) || [];

        if (condition.value.terms.values.length == 0)
        {
            reset();
        }
    }
});

const label = computed(() => props.builder.localization.translate(component.value.label));

const reset = (): void =>
{
    model.value.remove(p => p.__key == component.value.id);
};

async function search(query: string, limit: number)
{
    try
    {
        return await DocumentsService.getOptions(props.builder.blueprintId, props.component.id, [], null, query, limit);
    }
    catch (ex)
    {
        return [];
    }
}

async function fetch(ids: (string|number)[])
{
    try
    {
        if (ids.length == 0)
            return [];

        return await DocumentsService.getOptions(props.builder.blueprintId, props.component.id, [], ids.map(p => Number(p)), null, 10000);
    }
    catch (ex)
    {
        return [];
    }
}
</script>

<template>
    <IdeoFilterMultiple
        v-model="entry"
        :name="component.name"
        :label="label"
        :index="index"
        :value="entry"
        :visible="component.showFilter"
        :search="search"
        :fetch="fetch"
        @clear="reset"
    />
</template>
