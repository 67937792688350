<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from '@/helpers/Decorators';
import { Form } from '@/helpers/Form';
import { BPCommonType, BPCommonTypeOptions, ContractorRole, ContractorRoleOptions } from '@/modules/logito/crm/inc/Enums';
import BusinessPartnerService, {
    DetailModel,
    FormModel,
    GusModel,
    AddressModel,
} from '@/modules/logito/crm/services/BusinessPartnerService';
import Pager from '@/helpers/Pager';
import merge from 'lodash/merge';
import Permissions from '@/settings/permissions';
import { FrontActionModal } from '@/components/common/dynamic-grid/helpers/GridEnums';
import { KeyValuePair } from '@/helpers/Interfaces';
import DetailsLabel from '@/components/common/DetailsLabel.vue';
import AddModal from '@/modules/core/dictionary-terms/modals/AddModal.vue';
import SearchEngineModal from '@/modules/core/dictionary-terms/modals/SearchEngineModal.vue';
import SearchEngineService from '@/modules/core/dictionary-terms/services/SearchEngineService';

@Options({
    components: { DetailsLabel, AddModal, SearchEngineModal },
    emits: ['partnerAdded'],
})
export default class BusinessPartnersForm extends Vue
{
    @Prop({ default: false }) public modalMode: boolean;
    @Prop({ default: '' }) public publicId: string;
    @Prop({ default: '' }) public partnerId: string;

    @Ref("modal")
    public modal: () => InstanceType<typeof AddModal> | null;

    public form = Form.create<FormModel>({
        nip: '',
        givenName: '',
        surname: '',
        organizationName: '',
        shortName: '',
        commonType: 0,
        country: null,
        city: '',
        postalCode: '',
        street: '',
        houseID: '',
        additionalHouseID: '',
        isDefault: false,
        roles: [0],
        site: '',
        email: '',
        phone: '',
        bankAccount: '',
        manager: null,
        regon: '',
        externalNumber: '',
        eDoreczeniaAde: '',
        hasVisaCard: false,
        currency: null,
        externalName: '',
        role: 0
    });

    public partnerChecked: boolean = false;
    public duplicatePartners: DetailModel[] = [];
    public showDuplicatePartners: boolean[] = [];
    public permissions: any = {};
    public reloadKey = 0;
    public eDoreczeniaEnabled: boolean = false;

    public roles: { item: number; name: string }[] = [
        { item: 1, name: 'PH' },
        { item: 2, name: 'Dostawca' },
        { item: 3, name: 'Zbieracz kosztów' },
        { item: 4, name: 'Pracownik' },
    ];

    public get isNipRequired(): boolean
    {
        // @ts-ignore
        return this.form.country != null && this.form.country?.iso == 'PL';
    }

    public get dictionaryType(): KeyValuePair
    {
        return {
            key: '2',
            value: this.$t('[[[Administracja - Kraje]]]'),
        };
    }

    public gusLoader: boolean = false;

    public get bpCommonTypeOptions(): typeof BPCommonTypeOptions
    {
        return BPCommonTypeOptions;
    }

    public get roleOptions(): typeof ContractorRoleOptions
    {
        return ContractorRoleOptions;
    }

    public get isOrganisationType(): boolean
    {
        return this.form.commonType === BPCommonType.Organisation;
    }

    public get isEditMode(): boolean
    {
        return !!(this.publicId || this.$route.params.companyId);
    }

    public get isCountryRequired(): boolean
    {
        return this.form.commonType === BPCommonType.Organisation;
    }

    public get saveBtnText(): string
    {
        return this.partnerChecked && this.duplicatePartners?.length
            ? this.$t('[[[Dodaj nowego kontrahenta]]]')
            : this.$t('[[[Zapisz]]]');
    }

    public get organizationInvalidFeedback(): string
    {
        return this.form.$errors.first('displayText') || this.form.$errors.first('organizationName');
    }

    public get organizationState(): boolean
    {
        return this.form.$errors.state('displayText') && this.form.$errors.state('organizationName');
    }

    public async created(): Promise<void>
    {
        await this.getPartnerDetailsForEdit();
        this.getEdoreczeniaEnabled();

        if (!this.isEditMode) this.permissions.canGusFind = await BusinessPartnerService.canGusFind();
    }

    public async canAddOrEdit(): Promise<boolean>
    {
        return await this.$permissions.all([Permissions.Logito.Dictionaries.All]);
    }

    public openDictionaryModal(id: string = null): void
    {
        this.modal().showModal(id);
    }

    public async refetchSelectData(): Promise<void>
    {
        this.reloadKey++;
    }

    public async getPartnerDetailsForEdit(): Promise<void>
    {
        if (!this.isEditMode) return;

        try
        {
            const response = await BusinessPartnerService.getPartnerDetails(this.publicId);

            response.result.commonType = BPCommonType[Number.parseInt((response.result.commonType as KeyValuePair)['key'])];
            response.result.role = ContractorRole[Number.parseInt((response.result.role as KeyValuePair)['key'])];
            this.permissions = response.meta;

            const defaultAddress = await this.getDefaultAddress(this.publicId);

            const data = merge(response.result, defaultAddress);

            this.loadFormData(data);
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors),
            });
        }
    }

    public async getDefaultAddress(id: string): Promise<AddressModel | undefined>
    {
        try
        {
            const { items } = await BusinessPartnerService.getAddressList(id, new Pager(1, 50, '', 'ASC'));
            const data = items.map((item) => item.result).find((item) => item.isDefault);

            return data;
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: () => this.$alert.danger(this.$t('[[[Nie udało się pobrać szczegółów]]]')),
            });
        }
    }

    public async findGus(): Promise<void>
    {
        this.gusLoader = true;

        const isNumber = !isNaN(Number(this.form.nip.charAt(0)));

        this.form.nip = isNumber ? this.form.nip.replace(/ /g, '') : this.form.nip;

        try
        {
            const { result = null } = await BusinessPartnerService.findGus(this.form.nip);

            if (!result)
            {
                this.$alert.warning(this.$t('[[[Nie odnaleziono kontrahenta o podanym numerze NIP w bazie GUS.]]]'));

                return;
            }

            result.commonType = this.form.commonType;

            const adeTemp = this.form.eDoreczeniaAde;

            this.form.clear();
            this.loadFormData(result);
            this.form.eDoreczeniaAde = adeTemp;
            this.form.$errors.clear();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                429: (ex: any) => this.$alert.warning(ex.message),
            });
        }
        finally
        {
            this.gusLoader = false;
        }
    }

    public async checkPartner(): Promise<void>
    {
        try
        {
            const formData = this.form.data();
            const form = JSON.parse(JSON.stringify(formData));

            if (!form.nip) return;

            const duplicatedPartners = (
                await BusinessPartnerService.searchDetails(form.nip, new Pager(1, 50, 'Id', 'asc'))
            ).items;

            for (const duplicatedPartner of duplicatedPartners)
            {
                const id = duplicatedPartner.result.publicId;

                if (id == null) return;

                duplicatedPartner.result.defaultAddress = await this.getDefaultAddress(
                    duplicatedPartner.result.publicId
                );
            }

            this.duplicatePartners = duplicatedPartners?.map((partner) => partner?.result);
            this.partnerChecked = true;

            if (this.duplicatePartners?.length)
            {
                this.$alert.info(this.$t('[[[Znaleziono kontrahentów o podanym NIPie.]]]'));
                await this.$nextTick();
                this.$scrollTo('.duplicated-partners');
            }
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public async saveForm(): Promise<void>
    {
        if (!this.partnerChecked && !this.isEditMode)
        {
            await this.checkPartner();

            if (this.duplicatePartners?.length) return;
        }

        const publicId = this.publicId;

        try
        {
            this.form.wait();

            const result = !this.isEditMode
                ? (await BusinessPartnerService.addPartner(this.form.formatData())).result
                : (await BusinessPartnerService.editPartner(publicId, this.form.formatData())).result;

            if (this.modalMode) this.$emit('partnerAdded', !this.isEditMode ? result?.publicId : publicId);
            else this.redirect({ name: 'crm-bs-partners-list' });

            this.$alert.success(this.$t('[[[Kontrahent został zapisany.]]]'));
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$scrollTo('.invalid-field'),
                    this.$alert.warning(this.$t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]')),
                ],
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public async overridePartner(publicId: string): Promise<void>
    {
        const formData = this.form.data();
        const form = JSON.parse(JSON.stringify(formData));

        form.manager = form.manager?.id;
        form.country = form.country?.key;

        try
        {
            await BusinessPartnerService.editPartner(publicId, form);

            if (this.modalMode)
            {
                this.$emit('partnerAdded', publicId);
            }
            else
            {
                this.redirect({ name: 'crm-bs-partners-list' });
            }

            this.$alert.success(this.$t('[[[Kontrahent został nadpisany.]]]'));
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$scrollTo('.invalid-field'),
                    this.$alert.warning(this.$t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]')),
                ],
            });
        }
    }

    public async getEdoreczeniaEnabled(): Promise<void>
    {
        this.eDoreczeniaEnabled = await SearchEngineService.getEdoreczeniaEnabled();
    }

    public loadFormData(result: GusModel | DetailModel | AddressModel): void
    {
        const form: FormModel = this.form.data();

        for (const property in form)
        {
            if (result[property]) form[property] = result[property];
        }

        this.form.withData(form);
    }

    public clearErrors(name: string): void
    {
        this.form.$errors.clear(name);
    }

    public contractorTypeChange(): void
    {
        this.form.$errors.clear();
        this.form.continue();
    }

    public openSearchEngineModal(): void
    {
        this.$events.$emit(FrontActionModal.EDoreczeniaSearchEngine, this.form);
    }

    public handleModalResult(item: any): void
    {
        this.form.eDoreczeniaAde = item.recipientEda;
    }
}
</script>

<template>
    <DataCard :disable-toolbar="modalMode">
        <template #toolbar>
            <ActionBar :toggle-footer="true">
                <BackButton toolbar :fallback="{ name:'crm-bs-partners-list' }" />
                <ActionButton
                    toolbar
                    submit
                    variant="success"
                    icon="fas fa-check-circle"
                    :text="saveBtnText"
                    :disabled="!form.active()"
                    @click="saveForm"
                />
            </ActionBar>
        </template>
        <template #default>
            <form @submit.prevent="saveForm()">
                <PlaceholderForm :loading="form.loading()" @keydown="form.$errors.clear($event.target.name)">
                    <div class="row">
                        <div class="col-md-6">
                            <IdeoFormGroup
                                :label="$t('[[[Typ kontrahenta]]]')"
                                :invalid-feedback="form.$errors.first('commonType')"
                                :state="form.$errors.state('commonType')"
                                @change="contractorTypeChange()"
                                required
                            >
                                <IdeoFormRadioGroup
                                    v-model="form.commonType"
                                    :options="bpCommonTypeOptions()"
                                ></IdeoFormRadioGroup>
                            </IdeoFormGroup>
                            <IdeoFormGroup
                                :label="$t('[[[Rola]]]')"
                                :invalid-feedback="form.$errors.first('role')"
                                :state="form.$errors.state('role')"
                                required
                            >
                                <IdeoFormRadioGroup
                                    v-model="form.role"
                                    :options="roleOptions()"
                                />
                            </IdeoFormGroup>
                            <IdeoFormGroup
                                v-if="isOrganisationType"
                                :label="$t('[[[NIP]]]')"
                                :invalid-feedback="form.$errors.first('nip')"
                                :state="form.$errors.state('nip')"
                                :required="isNipRequired"
                            >
                                <IdeoFormInput
                                    v-model="form.nip"
                                    name="nip"
                                    :placeholder="$t('[[[Podaj NIP]]]')"
                                    @update:modelValue="partnerChecked = false"
                                />
                                <div class="d-flex align-items-center mt-1">
                                    <IdeoButton
                                        v-if="permissions.canGusFind === true"
                                        variant="success"
                                        @click="findGus"
                                    >
                                        {{ $t('[[[Pobierz dane z GUS]]]') }}
                                    </IdeoButton>
                                    <IdeoSpinner
                                        v-if="gusLoader"
                                        variant="primary"
                                        class="ms-2"
                                        :size="20"
                                    ></IdeoSpinner>
                                </div>
                            </IdeoFormGroup>
                            <template v-if="isOrganisationType">
                                <IdeoFormGroup
                                    :label="$t('[[[Regon]]]')"
                                    :invalid-feedback="form.$errors.first('regon')"
                                    :state="form.$errors.state('regon')"
                                >
                                    <IdeoFormInput type="text" v-model="form.regon" name="regon" />
                                </IdeoFormGroup>
                                <IdeoFormGroup
                                    :label="$t('[[[Nazwa kontrahenta]]]')"
                                    :invalid-feedback="organizationInvalidFeedback"
                                    :state="organizationState"
                                    required
                                >
                                    <IdeoFormInput
                                        type="text"
                                        v-model="form.organizationName"
                                        name="organizationName"
                                    />
                                </IdeoFormGroup>
                                <IdeoFormGroup
                                    style="display: none"
                                    :label="$t('[[[Skrócona nazwa]]]')"
                                    :invalid-feedback="form.$errors.first('shortName')"
                                    :state="form.$errors.state('shortName')"
                                >
                                    <IdeoFormInput type="text" v-model="form.shortName" name="shortName" />
                                </IdeoFormGroup>
                                <IdeoFormGroup
                                    :label="$t('[[[Strona www]]]')"
                                    :invalid-feedback="form.$errors.first('site')"
                                    :state="form.$errors.state('site')"
                                >
                                    <IdeoFormInput type="text" v-model="form.site" name="site" />
                                </IdeoFormGroup>
                            </template>
                            <template v-else>
                                <IdeoFormGroup
                                    :label="$t('[[[Imię]]]')"
                                    :invalid-feedback="form.$errors.first('givenName')"
                                    :state="form.$errors.state('givenName')"
                                    required
                                >
                                    <IdeoFormInput type="text" v-model="form.givenName" name="givenName" />
                                </IdeoFormGroup>
                                <IdeoFormGroup
                                    :label="$t('[[[Nazwisko]]]')"
                                    :invalid-feedback="form.$errors.first('surname')"
                                    :state="form.$errors.state('surname')"
                                    required
                                >
                                    <IdeoFormInput type="text" v-model="form.surname" name="surname" />
                                </IdeoFormGroup>
                            </template>
                            <IdeoFormGroup
                                :label="$t('[[[Numer zewnętrzny]]]')"
                                :invalid-feedback="form.$errors.first('externalNumber')"
                                :state="form.$errors.state('externalNumber')"
                            >
                                <IdeoFormInput type="text" v-model="form.externalNumber" name="externalNumber" />
                            </IdeoFormGroup>
                            <IdeoFormGroup
                                :label="$t('[[[Nazwa kontrahenta w systemie FK]]]')"
                                :invalid-feedback="form.$errors.first('externalName')"
                                :state="form.$errors.state('externalName')"
                            >
                                <IdeoFormInput type="text" v-model="form.externalName" name="externalName" />
                            </IdeoFormGroup>
                            <IdeoFormGroup
                                v-if="eDoreczeniaEnabled"
                                :label="$t('[[[E-Doręczenia Ade]]]')"
                                :invalid-feedback="form.$errors.first('eDoreczeniaAde')"
                                :state="form.$errors.state('eDoreczeniaAde')"
                            >
                                <div class="d-flex">
                                    <IdeoFormInput
                                        type="text"
                                        v-model="form.eDoreczeniaAde"
                                        name="eDoreczeniaAde"
                                        disabled
                                        class="flex-fill custom-class-for-input"
                                        custom-classes="border-radius-right-none"
                                    />

                                    <IdeoButton
                                        variant="success"
                                        type="button"
                                        class="border-radius-left-none"
                                        :title="$t('[[[Otwórz wyszukiwarkę]]]')"
                                        @click="openSearchEngineModal"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </IdeoButton>
                                </div>
                                <SearchEngineModal @selected="handleModalResult" />
                            </IdeoFormGroup>
                            <IdeoFormGroup
                                :label="$t('[[[E-mail]]]')"
                                :invalid-feedback="form.$errors.first('email')"
                                :state="form.$errors.state('email')"
                            >
                                <IdeoFormInput type="text" v-model="form.email" name="email" />
                            </IdeoFormGroup>
                            <IdeoFormGroup
                                :label="$t('[[[Nr telefonu]]]')"
                                :invalid-feedback="form.$errors.first('phone')"
                                :state="form.$errors.state('phone')"
                            >
                                <IdeoFormInput type="text" v-model="form.phone" name="phone" />
                            </IdeoFormGroup>
                            <IdeoFormGroup
                                :label="$t('[[[Nr konta bankowego]]]')"
                                :invalid-feedback="form.$errors.first('bankAccount')"
                                :state="form.$errors.state('bankAccount')"
                            >
                                <IdeoFormInput type="text" v-model="form.bankAccount" name="bankAccount" />
                            </IdeoFormGroup>
                            <IdeoFormGroup
                                :label="$t('[[[Opiekun]]]')"
                                :invalid-feedback="form.$errors.first('manager')"
                                :state="form.$errors.state('manager')"
                                nospace
                            >
                                <IdeoSelect
                                    v-model="form.manager"
                                    :placeholder="$t('[[[Opiekun]]]')"
                                    :initial-load="isEditMode"
                                    :endpoint="`simple-dictionary/crm/form/manager`"
                                    @update:modelValue="clearErrors('manager')"
                                >
                                    <template #option="{ option }">
                                        <UserAvatar
                                            :src="$filters.baseurl(`storage/files/${option.pictureUrl}`)"
                                            :username="option.value"
                                            rounded="circle"
                                        />
                                        <span class="ms-2 me-1">{{ option.value }}</span>
                                        <span v-if="option.unit">({{ option.unit }})</span>
                                    </template>
                                </IdeoSelect>
                            </IdeoFormGroup>
                            <IdeoFormGroup>
                                <IdeoFormCheckbox v-model="form.hasVisaCard">
                                    {{ $t('[[[Posiada kartę VISA]]]') }}
                                </IdeoFormCheckbox>
                            </IdeoFormGroup>
                            <IdeoFormGroup
                                :label="$t('[[[Waluta]]]')"
                                :invalid-feedback="form.$errors.first('currency')"
                                :state="form.$errors.state('currency')"
                                required
                                nospace
                            >
                                <div class="d-flex">
                                    <IdeoSelect
                                        v-model="form.currency"
                                        :placeholder="$t('[[[Waluta]]]')"
                                        :initial-load="isEditMode"
                                        :reload-key="reloadKey"
                                        :clear-button="false"
                                        :deselect="true"
                                        class="flex-fill"
                                        custom-classes="border-radius-right-none"
                                        :endpoint="`common/dictionary/currencies`"
                                        @update:model-value="clearErrors('currency')"
                                    />

                                    <IdeoButton
                                        v-if="!isEditMode && canAddOrEdit()"
                                        variant="success"
                                        type="button"
                                        class="border-radius-left-none"
                                        :class="{ 'more-appends': canAddOrEdit() }"
                                        :title="$t('[[[Dodaj kontrahenta]]]')"
                                        @click="openDictionaryModal(null)"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </IdeoButton>
                                    <IdeoButton
                                        v-if="canAddOrEdit() && isEditMode"
                                        class="border-radius-left-none"
                                        variant="success"
                                        type="button"
                                        :title="$t('[[[Edytuj kontrahenta]]]')"
                                        @click="openDictionaryModal((form.currency as KeyValuePair)?.key)"
                                    >
                                        <i class="fa fa-edit"></i>
                                    </IdeoButton>
                                </div>
                                <AddModal
                                    :dictionary-type="{
                                        key: '3',
                                        value: $t('[[[Finanse - Waluty]]]'),
                                    }"
                                    :edit-dictionary="false"
                                    @reload="refetchSelectData"
                                />
                            </IdeoFormGroup>
                        </div>
                        <div class="col-md-6">
                            <div class="card" style="display: none">
                                <div class="card-header">
                                    <strong>{{ $t('Role kontrahenta') }}</strong>
                                </div>
                                <div class="card-body">
                                    <IdeoFormGroup
                                        class="mb-0"
                                        :invalid-feedback="form.$errors.first('role')"
                                        :state="form.$errors.state('role')"
                                    >
                                        <ideo-form-checkbox-group
                                            v-model="form.roles"
                                            :options="roles"
                                            class="mb-3"
                                            value-field="item"
                                            text-field="name"
                                        ></ideo-form-checkbox-group>
                                    </IdeoFormGroup>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <strong>{{ $t('Adres kontrahenta') }}</strong>
                                </div>
                                <div class="card-body">
                                    <IdeoFormGroup
                                        :label="$t('[[[Miasto]]]')"
                                        :invalid-feedback="form.$errors.first('city')"
                                        :state="form.$errors.state('city')"
                                        :required="isOrganisationType"
                                    >
                                        <IdeoFormInput type="text" v-model="form.city" name="city" />
                                    </IdeoFormGroup>
                                    <IdeoFormGroup
                                        :label="$t('[[[Ulica]]]')"
                                        :invalid-feedback="form.$errors.first('street')"
                                        :state="form.$errors.state('street')"
                                        :required="isOrganisationType"
                                    >
                                        <IdeoFormInput type="text" v-model="form.street" name="street" />
                                    </IdeoFormGroup>
                                    <IdeoFormGroup
                                        :label="$t('[[[Nr budynku]]]')"
                                        :invalid-feedback="form.$errors.first('houseId')"
                                        :state="form.$errors.state('houseId')"
                                        :required="isOrganisationType"
                                    >
                                        <IdeoFormInput type="text" v-model="form.houseID" name="houseId" />
                                    </IdeoFormGroup>
                                    <IdeoFormGroup
                                        :label="$t('[[[Nr lokalu]]]')"
                                        :invalid-feedback="form.$errors.first('additionalHouseId')"
                                        :state="form.$errors.state('additionalHouseId')"
                                    >
                                        <IdeoFormInput
                                            type="text"
                                            v-model="form.additionalHouseID"
                                            name="additionalHouseId"
                                        />
                                    </IdeoFormGroup>
                                    <IdeoFormGroup
                                        :label="$t('[[[Kod pocztowy]]]')"
                                        :invalid-feedback="form.$errors.first('postalCode')"
                                        :state="form.$errors.state('postalCode')"
                                        :required="isOrganisationType"
                                    >
                                        <IdeoFormInput type="text" v-model="form.postalCode" name="postalCode" />
                                    </IdeoFormGroup>
                                    <IdeoFormGroup
                                        :label="$t('[[[Kraj]]]')"
                                        :invalid-feedback="form.$errors.first('country')"
                                        :state="form.$errors.state('country')"
                                        :required="isCountryRequired"
                                        nospace
                                    >
                                        <div class="d-flex">
                                            <IdeoSelect
                                                v-model="form.country"
                                                :placeholder="$t('[[[Kraj]]]')"
                                                :initial-load="isEditMode"
                                                :reload-key="reloadKey"
                                                :clear-button="false"
                                                :deselect="true"
                                                class="flex-fill"
                                                custom-classes="border-radius-right-none"
                                                :endpoint="`common/dictionary/countries`"
                                                @update:model-value="clearErrors('country')"
                                            />

                                            <IdeoButton
                                                v-if="!isEditMode && canAddOrEdit()"
                                                variant="success"
                                                type="button"
                                                class="border-radius-left-none"
                                                :class="{ 'more-appends': canAddOrEdit() }"
                                                :title="$t('[[[Dodaj kontrahenta]]]')"
                                                @click="openDictionaryModal(null)"
                                            >
                                                <i class="fa fa-plus"></i>
                                            </IdeoButton>
                                            <IdeoButton
                                                v-if="canAddOrEdit() && isEditMode"
                                                class="border-radius-left-none"
                                                variant="success"
                                                type="button"
                                                :title="$t('[[[Edytuj kontrahenta]]]')"
                                                @click="openDictionaryModal((form.country as KeyValuePair)?.key)"
                                            >
                                                <i class="fa fa-edit"></i>
                                            </IdeoButton>
                                        </div>
                                        <AddModal
                                            ref="modal"
                                            :dictionary-type-id="dictionaryType?.key"
                                            :edit-dictionary="false"
                                            @reload="refetchSelectData"
                                        />
                                    </IdeoFormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </PlaceholderForm>
            </form>
            <div v-if="duplicatePartners && duplicatePartners.length" class="row mt-4">
                <div :class="modalMode ? 'col-lg-12' : 'col-lg-6'">
                    <div class="card duplicated-partners">
                        <div class="card-header">
                            <strong>{{ $t('[[[Istniejące firmy]]]') }}</strong>
                        </div>
                        <div class="list-group" role="tablist">
                            <div
                                v-for="(item, index) in duplicatePartners"
                                :key="index"
                                no-body
                                class="list-group-item"
                            >
                                <label
                                    class="header d-flex fs-5 justify-content-between align-items-center text-start fw-bold cursor-pointer m-0"
                                    :class="{ collapsed: showDuplicatePartners[index] }"
                                >
                                    <span>{{ item.name }}</span>
                                    <i class="fas fa-chevron-down"></i>
                                    <input class="d-none" type="checkbox" v-model="showDuplicatePartners[index]" />
                                </label>
                                <IdeoCollapse
                                    v-model="showDuplicatePartners[index]"
                                    :id="`accordion-${index}`"
                                    visible
                                    accordion="my-accordion"
                                    role="tabpanel"
                                >
                                    <div style="max-width: 400px">
                                        <DetailsLabel class="mb-1 mt-2" :label="$t('[[[NIP]]]')">
                                            {{ item.nip }}
                                        </DetailsLabel>
                                        <DetailsLabel class="mb-1" :label="$t('[[[REGON]]]')">
                                            {{ item.regon }}
                                        </DetailsLabel>
                                        <DetailsLabel class="mb-1" :label="$t('[[[E-mail]]]')">
                                            {{ item.email }}
                                        </DetailsLabel>
                                        <DetailsLabel class="mb-1" :label="$t('[[[Telefon]]]')">
                                            {{ item.phone }}
                                        </DetailsLabel>
                                        <DetailsLabel class="mb-1" :label="$t('[[[Strona www]]]')">
                                            {{ item.site }}
                                        </DetailsLabel>
                                        <DetailsLabel
                                            v-if="item.defaultAddress"
                                            class="mb-0"
                                            :title="$t('[[[Adres]]]')"
                                        >
                                            <p class="mb-0">
                                                {{ item.defaultAddress.postalCode }} {{ item.defaultAddress.city }}
                                            </p>
                                            <p class="mb-0">{{ item.defaultAddress.street }}</p>
                                            <p>
                                                {{ item.defaultAddress.houseID }}
                                                <span v-if="item.defaultAddress.additionalHouseID">
                                                    {{ item.defaultAddress.additionalHouseID }}
                                                </span>
                                            </p>
                                        </DetailsLabel>
                                        <div class="mt-3 d-flex">
                                            <IdeoButton variant="success" @click="overridePartner(item.publicId)">
                                                {{ $t('[[[Nadpisz]]]') }}
                                            </IdeoButton>
                                            <IdeoButton
                                                variant="warning"
                                                @click="
                                                    $router.push({
                                                        name: 'crm-bs-partners-list-edit',
                                                        params: { publicId: item.publicId },
                                                    })
                                                "
                                            >
                                                {{ $t('[[[Edytuj]]]') }}
                                            </IdeoButton>
                                        </div>
                                    </div>
                                </IdeoCollapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <portal to="add-partners-modal" v-if="modalMode">
                <IdeoButton variant="success" @click="saveForm">{{ saveBtnText }}</IdeoButton>
            </portal>
        </template>
    </DataCard>
</template>

<style lang="scss">
.duplicated-partners {
    .list-group-item {
        :not(.collapsed) {
            transition: all 0.1s;
            &.header {
                color: var(--primary);
            }
            .fas {
                transform: rotate(180deg);
            }
        }
    }
}
</style>
