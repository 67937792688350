import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { FieldModel } from '@/modules/low-code/services/FieldConfigsService';

export const Definition: BlueprintDefinition = {
    type: 'related-documents',
    name: '[[[Dokumenty powiązane]]]',
    icon: 'fa-file',
    group: 'components',
    position: 4,
};

export interface RelatedDocumentsContract extends Blueprint, VisibleBlueprint, HasWidth {
    allowBindings: boolean;
    modules: string[];
    allowAuthor: boolean;
    allowTargets: boolean;
    allowAll: boolean;
    columns: Record<string, FieldModel>;
    filtersJson: string;
    showFilters: boolean;
}

export class RelatedDocumentsType implements RelatedDocumentsContract
{
    public id: string;
    public type: string;
    public name: string;
    public width: number;
    public minWidth: number;
    public allowBindings: boolean;
    public modules: string[];
    public allowAuthor: boolean;
    public allowTargets: boolean;
    public allowAll: boolean;
    public visible: AlwaysChoice | WhenChoice;
    public visibleWhen: string;
    public columns: Record<string, FieldModel>;
    public filtersJson: string;
    public showFilters: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 3;
        this.allowBindings = false;
        this.modules = [];
        this.allowAuthor = false;
        this.allowTargets = false;
        this.allowAll = false;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.columns = {};
        this.filtersJson = null;
        this.showFilters = false;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = 6;
    }
}
