<script lang="ts" setup>
import { computed, watch } from 'vue';
import lodashLast from 'lodash/last';
import properties from '@/components/dynamic-details/properties';
import { RowContract } from '../row';
import { SectionType, SectionColors } from '.';
import { useLocalization } from '@/plugins/localization';
import { Option } from '@/helpers/Interfaces';
import { DetailsBuilderContract } from '@/components/builder/details';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';

const { $t } = useLocalization();

defineOptions({
    name: 'SectionBlueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

if (props.blueprint.components.length == 0)
{
    props.details.layout.addRow(props.blueprint);
}

const design = computed(() => props.details.designMode());
const title = computed(() => props.details.localization.translate(blueprint.value.title) || $t('[[[Sekcja]]]'));
const description = computed(() => props.details.localization.translate(blueprint.value.description));

const rows = computed<RowContract[]>(() => props.blueprint.components as RowContract[]);
const last = computed<RowContract>(() => lodashLast(rows.value));

const colorOptions: Option<SectionColors>[] = [
    {value: SectionColors.None, text: $t('[[[Brak]]]')},
    {value: SectionColors.Success, text: $t('[[[Sukces]]]')},
    {value: SectionColors.Info, text: $t('[[[Informacja]]]')},
    {value: SectionColors.Warning, text: $t('[[[Ostrzeżenie]]]')},
    {value: SectionColors.Danger, text: $t('[[[Zagrożenie]]]')},
    {value: SectionColors.Light, text: $t('[[[Jasny]]]')},
    {value: SectionColors.Dark, text: $t('[[[Ciemny]]]')},
];

const addRow = (before: RowContract): void =>
{
    props.details.layout.addRow(props.blueprint, before);
};

const addComponent = (definition: BlueprintDefinition, before: RowContract): void =>
{
    const row = props.details.layout.addRow(props.blueprint, before);

    props.details.layout.addComponent(row, definition);
};

const dropComponent = (component: Blueprint, before: RowContract): void =>
{
    const row = props.details.layout.addRow(props.blueprint, before);

    props.details.clipboard.cut(component);
    props.details.clipboard.paste(row);
};

const lastComponentsLength = computed(() => last.value.components.length);
const blueprintWidth = computed(() => props.blueprint.width);

watch(lastComponentsLength, (length: number): void =>
{
    if (length && length > 0)
    {
        props.details.layout.addRow(props.blueprint);
    }
});

watch(blueprintWidth, (): void =>
{
    props.blueprint.components.forEach((row: any) => props.details.dimensions.autoarrange(row, false));
});
</script>

<template>
    <dynamic-details-component-wrapper class="section-component" :details="details" :parent="parent" :blueprint="blueprint" :disabled="false">
        <template #default>
            <fieldset :disabled="false" :class="{'design': design, [`alert alert-${blueprint.color}`]: blueprint.color != 'none'}">
                <legend v-if="(blueprint.title && blueprint.showTitle) || design" :class="{'text-secondary': !blueprint.showTitle}">
                    {{ title }}
                </legend>
                <p class="description" v-if="description">{{ description }}</p>
                <div class="rows">
                    <template v-for="(component, index) in rows" :key="component.id">
                        <dynamic-details-design-ruller :title="$t('[[[Wstaw kontrolkę]]]')" :details="details" :before="component" @insert="addRow" @add="addComponent" @drop="dropComponent" v-if="component.components.length > 0" />
                        <component :is="details.schema.designer(`dynamic-details-${component.type}`)" :details="details" :parent="blueprint" :blueprint="rows[index]"></component>
                    </template>
                </div>
            </fieldset>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <dynamic-details-accordion>
                <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                    <ideo-form-localize v-slot="{ locale }">
                        <field-text v-model="blueprint.title[locale]" :label="$t('[[[Tytuł sekcji]]]')" />
                    </ideo-form-localize>
                    <field-checkbox v-model="blueprint.showTitle" :label="$t('[[[Pokaż tytuł sekcji]]]')" />
                    <ideo-form-localize v-slot="{ locale }">
                        <field-textarea v-model="blueprint.description[locale]" :label="$t('[[[Opis]]]')" />
                    </ideo-form-localize>
                    <field-select v-model="blueprint.color" :options="colorOptions" :label="$t('[[[Akcent]]]')" />
                    <field-visible :details="details" :blueprint="blueprint" />
                </dynamic-details-accordion-item>
            </dynamic-details-accordion>
        </template>
    </dynamic-details-component-wrapper>
</template>

<style lang="scss" scoped>
.section-component {
    fieldset {
        display: block;
        margin: 0;

        > legend {
            display: block;
            font-size: 1rem;
            font-weight: 400;
            margin: 0 0 1rem 0;
        }

        &.alert {
            padding: 0.625rem;
        }

        &.design {
            padding: 0;

            > legend {
                padding: 0 0.75rem;
                margin: 0.75rem 0 0.25rem 0;
            }

            > p {
                padding: 0.75rem;
                margin: 0;
            }
        }
    }
}
</style>
