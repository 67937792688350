<template>
    <ideo-form-multiselect
        class="description-lines-multiselect"
        :modelValue="modelValue"
        :default-value="modelValue"
        :options="options"
        @search-change="getList"
        :track-by="'value'"
        :label="'value'"
        :open-direction="'bottom'"
        @update:modelValue="onValueChanged"
        :delete-button="false"
        :tooltip="true"
        :autocomplete="true"
    />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "@/helpers/Decorators";
import { Option, KeyValuePair } from "@/helpers/Interfaces";
import ControlCodesService from '@/modules/core/control-codes/services/ControlCodesService';
import { DateTime } from 'luxon';

@Options({
    name: 'description-lines-multiselect',
    components: {},
    emits: ['update:modelValue', 'changed']
})
export default class DescriptionLinesMultiselect extends Vue
{
    @Prop({ default: null }) public modelValue: any;
    @Prop({ default: null }) public type: number;
    @Prop({ default: null }) public companyId: number;
    @Prop({ default: null }) public issueDate: DateTime;

    public options: KeyValuePair[] = [];

    public async created(): Promise<void>
    {
        await this.getList();
    }

    protected async getList(query: string = ''): Promise<void>
    {
        this.options = await ControlCodesService.getControlCodes(this.type, this.companyId, this.issueDate, query);
    }

    public onValueChanged(value: Option): void
    {
        this.$emit('update:modelValue', value);
        this.$emit('changed', value);
    }
}
</script>

<style lang="scss">
.description-lines-multiselect {
    min-height: auto;
    .multiselect__tags {
        min-height: 36px;
        max-height: 36px;
    }
}
</style>
