<script lang="ts" setup>
import { ref, computed } from 'vue';

const props = defineProps({
  "label": null,
  "from": null,
  "to": null
});

const emit = defineEmits(["refetchData", "update:from", "update:to"]);

const opened = ref(false);

const isSet = computed(() => !!props.from || props.to);

const value = computed(() =>
{
    return isSet.value ? `${props.from ?? ''} ${props.from && props.to ? '-' : ''} ${props.to ?? ''}` : '';
});

const toggleClasses = computed(() =>
{
    return {
        'filter-button': true,
        'bg-primary border-primary': !!props.from || props.to || opened.value,
    };
});

const menuClasses = computed(() =>
{
    return {
        'p-0': true,
        'filter-dropdown-object__dropdown': true,
        'border-0': true,
    };
});

const containerClasses = computed(() =>
{
    return {
        'filter-container': true,
    };
});

const dropdownShown = () =>
{
    opened.value = true;
};

const clearSearchData = () =>
{
    emit('update:from', '');
    emit('update:to', '');
    emit('refetchData', { resetPageIndex: true });
};
</script>

<template>
    <div class="filter-dropdown-object">
        <ideo-dropdown
            variant="secondary"
            :toggle-class="toggleClasses"
            :menu-class="menuClasses"
            :container-class="containerClasses"
            boundry="body"
            no-flip
            no-caret
            :top-offset="4"
            @show="dropdownShown"
            @hide="opened = false"
        >
            <template #button-content>
                <div class="label-value">
                    <span v-if="label" class="label" :class="{ 'label--bigger': !isSet }">
                        {{ label }}
                        {{ isSet ? ':' : '' }}
                    </span>
                    <span class="value">{{ value }}</span>
                </div>
                <i class="fas fa-chevron-down arrow ms-2" :class="{ 'opened-arrow': opened }"></i>
                <div v-if="isSet" class="btn-remove ms-2" @click.stop="clearSearchData">
                    <i class="fas fa-times"></i>
                </div>
            </template>
            <template #default>
                <div
                    style="width: max-content"
                    class="date-wrapper-dropdown-content w-100 d-flex align-items-center flex-column p-2"
                >
                    <div class="width-between">
                        <div class="d-flex flex-column flex-md-row justify-content-center gap-2">
                            <ideo-form-group :label="$t('[[[Od]]]')" nospace>
                                <ideo-form-input
                                    :model-value="from"
                                    type="number"
                                    step="any"
                                    @update:modelValue="emit('update:from', $event)"
                                />
                            </ideo-form-group>
                            <span class="align-self-end pause">-</span>
                            <ideo-form-group :label="$t('[[[Do]]]')" nospace>
                                <ideo-form-input
                                    :model-value="to"
                                    type="number"
                                    step="any"
                                    @update:modelValue="emit('update:to', $event)"
                                />
                            </ideo-form-group>
                        </div>
                    </div>
                </div>
            </template>
        </ideo-dropdown>
    </div>
</template>

<style scoped>
.width-between {
    min-width: 260px;
}

.pause {
    margin-bottom: 10px;
}
</style>
