import { Plugin } from "vue";

export const scrollTo = (key: string): void =>
{
    const elem = document.querySelector(key);

    if (elem)
    {
        elem.scrollIntoView({
            behavior: 'smooth',
            block: "center"
        });
    }
};

const ScrollTo: Plugin =
{
    install(app)
    {
        const vue = app.config.globalProperties;

        vue.$scrollTo = scrollTo;
    }
};

export default ScrollTo;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $scrollTo: (name: string) => void;
    }
}
