import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Statement, Resource, Option } from '@/helpers/Interfaces';

export default class CategoriesService
{
    public static async getCategories(filter: CategoryFilterModel, pager: Pager): Promise<Pagination<CategoryItemModel>>
    {
        return (await axios.get<Pagination<CategoryItemModel>>(`admin/studio/categories`, {
            params: merge({}, filter, pager.data())
        })).data;
    }

    public static async fetchCategory(id: number): Promise<Resource<CategoryModel>>
    {
        return (await axios.get<Resource<CategoryModel>>(`admin/studio/categories/${id}`)).data;
    }

    public static async addCategory(model: CategoryModel): Promise<CategoryModel>
    {
        return (await axios.post<CategoryModel>(`admin/studio/categories`, model)).data;
    }

    public static async updateCategory(model: CategoryModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/studio/categories/${model.id}`, model)).data;
    }

    public static async removeCategory(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/studio/categories/${id}`)).data as Statement;
    }

    public static async getOptions(pager: Pager, id: number | null, search: string = null): Promise<Pagination<Option<number>>>
    {
        return (await axios.get<Pagination<Option<number>>>('admin/studio/categories/options', {
            params: merge({}, pager.data(), { id: id, search: search })
        })).data;
    }
}

export interface CategoryFilterModel
{
    name: string;
}

export interface CategoryItemModel
{
    id: number;
    name: string;
    position: number;
}

export interface CategoryModel
{
    id: number;
    name: string;
    position: number;
}
