import { DateTime, Info, Settings } from 'luxon';
import * as ws from 'weekstart';
import FlowManager from './FlowManager';

export function capitalize(value: string): string
{
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function datetimeFromISO(value: string): DateTime
{
    const datetime = DateTime.fromISO(value).toUTC();

    return datetime.isValid ? datetime : null;
}

export function monthDays(year: number, month: number, weekStart: number): number[]
{
    const monthDate = DateTime.local(year, month, 1);
    let firstDay = monthDate.weekday - weekStart;

    if (firstDay < 0)
    {
        firstDay += 7;
    }

    let lastDay = (weekStart - monthDate.weekday - monthDate.daysInMonth) % 7;

    if (lastDay < 0)
    {
        lastDay += 7;
    }

    return Array(monthDate.daysInMonth + firstDay + lastDay)
        .fill(undefined)
        .map((value, index) =>
            (index + 1 <= firstDay || index >= firstDay + monthDate.daysInMonth) ? null : (index + 1 - firstDay)
        );
}

export function monthDayIsDisabled(minDate: DateTime, maxDate: DateTime, year: number, month: number, day: number): boolean
{
    const date = DateTime.fromObject({ year, month, day}, {zone: 'UTC'});

    minDate = minDate ? startOfDay(minDate.setZone('UTC', { keepLocalTime: true })) : null;
    maxDate = maxDate ? startOfDay(maxDate.setZone('UTC', { keepLocalTime: true })) : null;

    return (minDate && date < minDate) || (maxDate && date > maxDate);
}

export function monthIsDisabled(minDate: DateTime, maxDate: DateTime, year: number, month: number): boolean
{
    return (minDate && minDate > DateTime.utc(year, month, DateTime.utc(year, month).daysInMonth)) ||
         (maxDate && maxDate < DateTime.utc(year, month, 1));
}

export function yearIsDisabled(minDate: DateTime, maxDate: DateTime, year: number): boolean
{
    const minYear = minDate ? minDate.year : null;
    const maxYear = maxDate ? maxDate.year : null;

    return (minYear && year < minYear) ||
         (maxYear && year > maxYear);
}

export function timeComponentIsDisabled(min: number, max: number, component: number): boolean
{
    return (min !== null && component < min) ||
         (max !== null && component > max);
}

export function weekdays(weekStart: number): string[]
{
    if (--weekStart < 0)
    {
        weekStart = 6;
    }

    let weekDays = Info.weekdays('short').map(weekday => capitalize(weekday));

    weekDays = weekDays.concat(weekDays.splice(0, weekStart));

    return weekDays;
}

export function months(): string[]
{
    return Info.months().map(month => capitalize(month));
}

export function hours(step: number): number[]
{
    return Array(Math.ceil(24 / step)).fill(undefined).map((item, index) => index * step);
}

export function minutes(step: number): number[]
{
    return Array(Math.ceil(60 / step)).fill(undefined).map((item, index) => index * step);
}

export function years(current: number): number[]
{
    return Array(201).fill(undefined).map((item, index) => current - 100 + index);
}

export function pad(number: number): string
{
    return number < 10 ? `0${number}` : `${number}`;
}

export function startOfDay(datetime: DateTime): DateTime
{
    return datetime.startOf('day');
}

export function createFlowManager(flow: string[]): FlowManager
{
    return new FlowManager(flow, 'end');
}

export function createFlowManagerFromType(type: string): FlowManager
{
    let flow = [];

    switch (type)
    {
        case 'datetime':
            flow = ['date', 'time'];
            break;
        case 'time':
            flow = ['time'];
            break;
        default:
            flow = ['date'];
    }

    return new FlowManager(flow, 'end');
}

export function weekStart(): number
{
    const firstDay = ws.getWeekStartByLocale(Settings.defaultLocale);

    return firstDay === 0 ? 7 : firstDay;
}
