<script lang="ts" setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useSitemap, SitemapNode } from '@/plugins/sitemap';
import { useMedia } from '@/plugins/media';
import { useAuthStore } from '@/store/auth';
import { useElementSize } from '@vueuse/core';
import { onClickOutside } from '@vueuse/core';
import { LOGO, useThemeStore } from '@/store/theme';
import MenuList from '@/views/partials/navigation/MenuList.vue';

const media = useMedia();

defineProps({
  "collapsed": { type: Boolean, default: false },
  "hover": { type: Boolean, default: false }
});

const emit = defineEmits(["toggleMenu"]);

const { $sitemap } = useSitemap();
const router = useRouter();
const route = useRoute();
const auth = useAuthStore();
const loaded = ref(false);
const sitemap = ref<SitemapNode[]>([]);
const toggled = ref<SitemapNode[]>([]);
const current = ref<SitemapNode>(null);

const isMoreAppsMenu = ref(false);
const isAllApps = ref(false);
const moreAppsMenu = ref(null);
const search = ref('');

const ROOT_ELEMENT_HEIGHT = 60;
const rootList = ref<HTMLDivElement | null>(null);
const { height } = useElementSize(rootList);

const version = computed(() =>
{
    return auth.identity?.systemVersion;
});

const style = computed(() =>
{
    const { theme } = useThemeStore();

    return theme;
});
const logo = computed(() => LOGO[style.value]);

onMounted(async () =>
{
    const sitemapNodes = await $sitemap.all();

    hideNodesByImpersonating(sitemapNodes);
    hideNodesByAccountType(sitemapNodes);

    $sitemap.onReload(async () =>
    {
        loaded.value = false;
        sitemap.value = await $sitemap.all();
        loaded.value = true;
    });

    await $sitemap.reload();
});

const hideNodesByImpersonating = (sitemapNodes?: any): void =>
{
    if (auth.impersonated != null)
    {
        sitemapNodes.forEach((p: any) =>
        {
            if (p.hasOwnProperty('hiddenInImpersonated') && p.hiddenInImpersonated)
                p.visible = false;

            if (p.hasOwnProperty('children') && p.children.length > 0)
                hideNodesByImpersonating(p.children);
        });
    }
};

const hideNodesByAccountType = (sitemapNodes?: any): void =>
{
    const accountType = auth.identity.accountType;

    sitemapNodes.forEach((p: any) =>
    {
        if (p.hasOwnProperty('hiddenForAccountType'))
        {
            if (p.hiddenForAccountType.includes(accountType))
                p.visible = false;
        }

        if (p.hasOwnProperty('children') && p.children.length > 0)
            hideNodesByAccountType(p.children);
    });
};

onUnmounted(() =>
{
    $sitemap.purge();
});

watch(
    router.currentRoute,
    async (value, old) =>
    {
        if (value !== old)
        {
            toggled.value = await $sitemap.crumbs(value);
            current.value = toggled.value.first();
        }
    },
    { immediate: true }
);

const submenu = () => children(current.value);

const active = (node: SitemapNode): boolean =>
{
    return $sitemap.active(node, route, true);
};

const url = (node: SitemapNode): any =>
{
    return $sitemap.url(node, route);
};

const root = (): SitemapNode[] =>
{
    return sitemap.value.filter((p) => p.visible);
};

const filtredRoot = (): SitemapNode[] =>
{
    return root().filter(
        (p) =>
            (p.name && p.name.toLowerCase().includes(search.value.toLowerCase())) ||
            (p.short && p.short.toLowerCase().includes(search.value.toLowerCase()))
    );
};

const rootListCount = computed((): number =>
{
    return Math.floor((height.value - ROOT_ELEMENT_HEIGHT * 2) / ROOT_ELEMENT_HEIGHT);
});

const rootVisible = (): SitemapNode[] =>
{
    return root().slice(0, rootListCount.value);
};

const moreMenuApps = (): SitemapNode[] =>
{
    return root().slice(rootListCount.value);
};

const moreAppsActive = computed(() =>
{
    const isActive = moreMenuApps().some((p) => active(p));

    return isMoreAppsMenu.value || isActive;
});

const children = (node: SitemapNode): SitemapNode[] =>
{
    return node?.children.filter((p) => p.visible) || [];
};

const changeRoot = (node: SitemapNode, recurency?: boolean): void =>
{
    if (node.route || node.url)
    {
        router.push(url(node));
    }
    else
    {
        const items = children(node);

        if (items.length > 0)
        {
            changeRoot(items.first(), true);
        }
    }

    if (!recurency && children(node).length === 0)
    {
        if (media.mobile()) emit('toggleMenu', false);
    }

    toggleAllAppsMenu(false);
    toggleMoreAppsMenu(false);
};

const toggle = (node: any): void =>
{
    if (children(node).length > 0)
    {
        if (expanded(node))
        {
            toggled.value = toggled.value.filter((p) => p !== node);
        }
        else
        {
            toggled.value = [];
            $sitemap.path(node).forEach((p) => toggled.value.push(p));
        }
    }
    else
    {
        router.push(url(node));

        if (media.mobile()) emit('toggleMenu', false);
    }
};

const expanded = (node: any): boolean =>
{
    return toggled.value.includes(node);
};

onClickOutside(moreAppsMenu, () => toggleMoreAppsMenu(false));

const toggleAllAppsMenu = (value?: boolean): void =>
{
    isAllApps.value = value ?? !isAllApps.value;
};

const toggleMoreAppsMenu = (value?: boolean): void =>
{
    isMoreAppsMenu.value = value ?? !isMoreAppsMenu.value;
};
</script>

<template>
    <div id="menu" :class="{ collapsed: collapsed, hover: hover }">
        <div id="appsmenu">
            <ideo-tooltip :tooltip="media.mobile() ? null : $t('[[[Strona główna]]]')" position="right">
                <header class="logo d-flex justify-content-center align-items-center">
                    <router-link :to="{ name: 'dashboard' }">
                        <img :src="logo.small" />
                    </router-link>
                </header>
            </ideo-tooltip>
            <div ref="rootList" class="d-flex flex-column flex-fill justify-content-between flex-shrink-1">
                <ul>
                    <ideo-tooltip :tooltip="$t('[[[Moje aplikacje]]]')" position="right">
                        <li class="menu-item" @click="toggleAllAppsMenu()">
                            <i class="fas fa-grid-round-4"></i>
                        </li>
                    </ideo-tooltip>

                    <ideo-tooltip
                        v-for="(node, i) in rootVisible()"
                        :key="i"
                        :tooltip="media.mobile() ? null : $t(node.name)"
                        position="right"
                    >
                        <li class="menu-item" :class="{ active: active(node) }" @click="changeRoot(node)">
                            <i :class="node.icon"></i>
                            <small>{{ $t(node.short) }}</small>
                        </li>
                    </ideo-tooltip>
                </ul>

                <div ref="moreAppsMenu">
                    <button
                        v-if="moreMenuApps().length > 0"
                        type="button"
                        class="more"
                        :class="{ active: moreAppsActive }"
                        @click="toggleMoreAppsMenu()"
                    >
                        <i class="fas fa-ellipsis"></i>
                    </button>

                    <portal to="more-apps-menu">
                        <ul v-show="isMoreAppsMenu" id="more-apps-menu" class="scroll">
                            <ideo-tooltip
                                v-for="(node, i) in moreMenuApps()"
                                :key="i"
                                :tooltip="media.mobile() ? null : $t(node.name)"
                                :position="i % 3 === 0 ? 'right' : i % 3 === 1 ? 'bottom' : 'left'"
                            >
                                <li
                                    class="menu-item menu-item--secondary"
                                    :class="{ active: active(node) }"
                                    @click="changeRoot(node)"
                                >
                                    <i :class="node.icon"></i>
                                    <small>{{ $t(node.short) }}</small>
                                </li>
                            </ideo-tooltip>
                        </ul>
                    </portal>
                </div>
            </div>
        </div>

        <div id="sitemenu" v-if="loaded">
            <header class="d-flex align-items-center text-body-emphasis">
                {{ $t(current?.name) }}
            </header>
            <section class="scroll">
                <menu-list
                    :nodes="submenu()"
                    :active="active"
                    :toggle="toggle"
                    :url="url"
                    :children="children"
                    :expanded="expanded"
                />
                <portal-target name="menu-section"></portal-target>
            </section>
            <footer class="d-flex justify-content-center align-items-center">Logito {{ version }}</footer>
        </div>

        <!-- All apps -->
        <portal to="all-apps-menu">
            <div v-show="isAllApps" id="all-apps">
                <div class="header">
                    <ideo-tooltip :tooltip="media.mobile() ? null : $t('[[[Strona główna]]]')" position="right">
                        <header class="logo">
                            <router-link :to="{ name: 'dashboard' }">
                                <img :src="logo.small" />
                            </router-link>
                        </header>
                    </ideo-tooltip>
                    <div class="d-flex flex-fill align-items-center justify-content-between gap-3">
                        <h2 class="text">{{ $t('[[[Moje aplikacje]]]') }}</h2>
                        <button type="button" @click="toggleAllAppsMenu(false)">
                            <i class="far fa-close"></i>
                        </button>
                    </div>
                </div>

                <div class="search-container">
                    <div class="menu-item" @click="toggleAllAppsMenu()">
                        <i class="fas fa-grid-round-4"></i>
                    </div>
                    <div class="search search-input">
                        <i class="fa-solid fa-search"></i>
                        <input v-model="search" :placeholder="$t('[[[Wyszukaj aplikacje]]]')" type="text" />
                    </div>
                </div>

                <div class="scroll pb-5">
                    <ul>
                        <ideo-tooltip
                            v-for="(node, i) in filtredRoot()"
                            :key="i"
                            :tooltip="media.mobile() ? null : $t(node.name)"
                            position="bottom"
                        >
                            <li
                                class="menu-item menu-item--tertiary"
                                :class="{ active: active(node) }"
                                @click="changeRoot(node)"
                            >
                                <i :class="node.icon"></i>
                                <small>{{ $t(node.short) }}</small>
                            </li>
                        </ideo-tooltip>
                    </ul>
                </div>
            </div>
        </portal>
    </div>
</template>

<style lang="scss" scoped>
.menu-item {
    height: var(--ideo-nav-width-collapsed);
    width: var(--ideo-nav-width-collapsed);
    padding-right: 2px;
    border-left: 2px solid transparent;
    color: var(--ideo-nav-color);
    transition: background-color 0.25s cubic-bezier(0, 1, 0.5, 1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover {
        border-left-color: var(--bs-border-color);
        background-color: var(--ideo-menu-bg);
        color: var(--ideo-nav-color-active);
    }

    &.active {
        border-left-color: var(--bs-primary);
        background-color: var(--ideo-menu-bg);
        color: var(--ideo-nav-color-active);
    }

    small {
        display: block;
        width: calc(var(--ideo-nav-width-collapsed) - 4px);
        margin: 2px auto 0 auto;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:has(small) i {
        margin-top: 4px;
    }

    i {
        font-size: 1.5rem;
    }

    &--secondary {
        color: var(--bs-gray-200);
        width: 80px;
        height: 80px;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: var(--bs-gray-800);
            color: var(--bs-primary);
        }

        &.active {
            background-color: var(--bs-gray-800);
            color: var(--bs-primary);
        }
    }

    &--tertiary {
        color: var(--ideo-nav-color);
        width: 80px;
        height: 80px;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: var(--bs-gray-900);
            color: var(--bs-primary);
        }

        &.active {
            background-color: var(--bs-gray-900);
            color: var(--bs-primary);
        }
    }
}

#menu {
    --ideo-menu-item-space: 4px;
    --ideo-menu-icon-space: 20px;

    display: flex;
    width: var(--ideo-nav-width-default);

    header {
        height: var(--ideo-header-height);
    }

    section {
        height: calc(100dvh - var(--ideo-header-height) - var(--ideo-footer-height));
    }

    footer {
        height: var(--ideo-footer-height);
    }

    #appsmenu {
        height: 100vh;
        display: flex;
        flex-direction: column;

        ul {
            width: var(--ideo-nav-width-collapsed);
            height: calc(100dvh - var(--ideo-header-height) - 60px);
            padding: 0;
            margin: 0;
            list-style-type: none;
            flex-shrink: 0;

            li.logo {
                height: var(--ideo-header-height);
                cursor: inherit;
            }
        }

        .more {
            height: var(--ideo-nav-width-collapsed);
            width: var(--ideo-nav-width-collapsed);
            border: none;
            background-color: var(--ideo-nav-bg);
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--ideo-nav-color);
            transition: background-color 0.25s cubic-bezier(0, 1, 0.5, 1);

            i {
                font-size: 1.5rem;
            }

            &.active {
                background-color: var(--ideo-nav-more-bg);
                color: var(--bs-primary);
            }

            &:hover {
                background-color: var(--ideo-nav-more-bg);
                color: var(--bs-primary);
            }
        }
    }

    &.collapsed {
        &:not(.hover) #appsmenu li.active {
            background-color: var(--bs-tertiary-bg);
        }
    }

    #sitemenu {
        width: calc(var(--ideo-nav-width-default) - var(--ideo-nav-width-collapsed));
        background-color: var(--ideo-menu-bg);
        border-right: 1px solid var(--bs-border-color);
        color: var(--bs-body-color);

        > header {
            padding: 0 1rem;
            font-size: 20px;
            font-weight: 600;
        }

        section {
            padding-top: 0.5rem;
        }
    }
}

#more-apps-menu {
    background-color: var(--ideo-nav-more-bg);
    height: 320px;
    width: calc(var(--ideo-nav-width-default) - var(--ideo-nav-width-collapsed));
    position: absolute;
    bottom: 0;
    left: var(--ideo-nav-width-collapsed);
    z-index: 99999;
    display: grid;
    grid-template-columns: repeat(3, 80px);
    grid-template-rows: repeat(4, 80px);
    margin: 0;
    padding: 0;
    border-right: 1px solid var(--bs-border-color);
}

#all-apps {
    height: 100vh;
    background: var(--ideo-nav-bg);
    position: absolute;
    top: 0;
    left: 0;
    width: var(--ideo-nav-width-default);
    display: flex;
    flex-direction: column;
    z-index: 99999;
    border-right: 1px solid var(--bs-border-color);

    .header {
        height: var(--ideo-header-height);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        flex-shrink: 0;

        .logo {
            width: var(--ideo-nav-width-collapsed);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .text {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 700;
            margin: 0;
            margin-left: 20px;
            color: var(--ideo-nav-color);
        }

        button {
            background: none;
            border: none;
            color: var(--ideo-nav-color);
            font-size: 1.25rem;
            padding: 4px;
            margin: -4px;
        }
    }

    .search-container {
        display: flex;
        align-items: center;
        width: 100%;
        padding-right: 20px;

        .search-input {
            margin-left: 20px;
            flex-grow: 1;
            height: var(--ideo-nav-width-collapsed);
            background: var(--ideo-search-bg);
            border: 1px solid var(--ideo-search-border-color);
            color: var(--ideo-search-color);
            height: 36px;
            border-radius: 4px;
            padding: 8px 10px;
            font-size: 0.875rem;
            display: flex;
            align-items: center;
            font-weight: 400;

            i {
                color: var(--ideo-search-icon-color);
                margin-right: 15px;
                font-size: 1rem;
            }

            input {
                background: var(--ideo-search-bg);
                color: var(--bs-body-color);
                padding: 0;
                border: none;
                flex-grow: 1;
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        flex-shrink: 0;
        display: grid;
        place-content: safe center;
        width: 100%;
        column-gap: 10px;
        grid-template-columns: repeat(3, 80px);
        grid-auto-rows: 80px;
    }
}
</style>
