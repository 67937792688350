<script lang="ts" setup>
import { computed } from 'vue';
import { SpacerType } from '.';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DetailsBuilderContract } from '@/components/builder/details';
import properties from '../../properties';

defineOptions({
    name: 'SpacerBlueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const blueprint = computed(() => props.blueprint);
</script>

<template>
    <dynamic-details-component-wrapper
        class="spacer-component"
        :details="details"
        :parent="parent"
        :blueprint="blueprint"
    >
        <template #properties>
            <field-name v-model="blueprint.name" />
        </template>
    </dynamic-details-component-wrapper>
</template>

<style lang="scss" scoped>
.spacer-component {
    min-height: 40px;
}
</style>
