<script lang="ts" setup>
import { computed } from 'vue';
import FieldChoice from './FieldChoice.vue';
import FieldCondition from './FieldCondition.vue';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';

defineOptions({
    name: 'field-required',
    components: {
        'field-choice': FieldChoice,
        'field-condition': FieldCondition
    }
});

const props = defineProps({
  "blueprint": null,
  "form": null,
  "label": { default: null }
});
const blueprint = computed(() => props.blueprint);

const requiredOptions: Option<AlwaysChoice | NeverChoice | WhenChoice>[] = [
    { value: AlwaysChoice.Always, text: '[[[Zawsze]]]' },
    { value: NeverChoice.Never, text: '[[[Nigdy]]]' },
    { value: WhenChoice.When, text: '[[[Warunkowo]]]' }
];
</script>

<template>
    <div>
        <field-choice v-model="blueprint.required" :options="requiredOptions" :label="label || $t('[[[Pole wymagane]]]')" />
        <field-condition v-model="blueprint.requiredWhen" :label="label" :show-label="false" :form="form" :when="blueprint.required" />
    </div>
</template>
