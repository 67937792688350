<template>
    <div class="d-flex align-items-end key-value-formatter">
        <span class="small original-value" v-if="original">{{ original.value }}</span>
        <span class="small current-value" v-if="current">{{ current.value }}</span>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'KeyValueFormatter'
})
export default class KeyValueFormatter extends Vue
{
    @Prop()
    public original: KeyValuePair | null;

    @Prop()
    public current: KeyValuePair | null;

    @Prop({ default: null })
    public definition: ChangelogDefinitionModel;
}
</script>
