<script lang="ts" setup>
import { ref, reactive, computed, watch } from 'vue';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { Form } from '@/helpers/Form';
import ModuleService from '@/modules/low-code/services/ModulesService';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

const props = defineProps({
  "publicId": null,
  "url": null
});

const { $events } = useEvents();
const { $alert } = useAlerts();
const { $t } = useLocalization();

const modal = ref<InstanceType<typeof IdeoModal> | null>(null);
const notificationGuid = ref('');

const form = reactive(
    Form.create({
        content: null,
        name: '',
        recipientType: null,
        type: null,
        template: null,
        sendDate: null,
        user: null,
        team: null,
    })
);

const title = computed(() => $t('[[[Powiadomienie weryfikacyjne]]]'));
const isEditMode = computed(() => !!notificationGuid.value);

const fetchNotification = async (): Promise<void> =>
{
    try
    {
        const response = await ModuleService.getDocumentNotification(notificationGuid.value);

        form.withData(response);
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.warning(ex.message);
    }
};

const onSubmit = async () =>
{
    try
    {
        form.wait();

        if (!isEditMode.value)
        {
            await ModuleService.createDocumentNotification(props.url, props.publicId, form.formatData());
            $alert.success($t('[[[Utworzono powiadomienie weryfikacyjne]]]'));
        }
        else
        {
            await ModuleService.updateDocumentNotification(notificationGuid.value, form.formatData());
            $alert.success($t('[[[Zmieniono powiadomienie weryfikacyjne]]]'));
        }

        modal.value?.hide();
        $events.$emit('refetchData');
        form.clear();
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.warning(ex.message);
        else if (ex.code === 422)
        {
            form.$errors.record(ex.data.errors);
            $alert.warning($t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]'));
        }
    }
    finally
    {
        form.continue();
    }
};

watch(
    () => form.template,
    (newValue: { content: string; key: string; value: string }) =>
    {
        form.content = newValue?.content ?? '';
    }
);

const showModal = async (guid?: string) =>
{
    notificationGuid.value = guid;

    if (notificationGuid.value) fetchNotification();

    form.clear();
    modal.value?.show();
};

defineExpose({
    showModal,
});
</script>

<template>
    <IdeoModal ref="modal" :title="title" centered>
        <template #default>
            <form
                id="modal-document-notification"
                @submit.prevent="onSubmit"
                @keydown="form.$errors.clear(($event.target as HTMLInputElement).name)"
            >
                <!-- Type -->
                <IdeoFormGroup
                    name="type"
                    :label="$t('[[[Typ powiadomienia]]]')"
                    :invalid-feedback="form.$errors.first('type')"
                    :state="form.$errors.state('type')"
                    required
                >
                    <FormAutocomplete
                        v-model="form.type"
                        :custom-endpoint="`simple-dictionary/${url}/form/${publicId}/document-notifications/type`"
                        :placeholder="$t('[[[Typ powiadomienia]]]')"
                        :is-radio-box="true"
                        :initial-load="true"
                        radio-box-name="typeNotification"
                        @change="form.$errors.clear('type')"
                    />
                </IdeoFormGroup>

                <template v-if="form.type?.key === 'Dictionary'">
                    <!-- Template -->
                    <IdeoFormGroup
                        name="template"
                        :label="$t('[[[Szablon powiadomienia]]]')"
                        :invalid-feedback="form.$errors.first('template')"
                        :state="form.$errors.state('template')"
                        required
                    >
                        <IdeoSelect
                            v-model="form.template"
                            :placeholder="$t('[[[Szablon powiadomienia]]]')"
                            :endpoint="`simple-dictionary/${url}/form/${publicId}/document-notifications/template`"
                            @update:modelValue="form.$errors.clear('template')"
                        />
                    </IdeoFormGroup>
                </template>

                <template v-if="form.type?.key === 'Manual'">
                    <!-- SendDate -->
                    <IdeoFormGroup
                        name="sendDate"
                        :label="$t('[[[Data wysyłki]]]')"
                        :invalid-feedback="form.$errors.first('sendDate')"
                        :state="form.$errors.state('sendDate')"
                        required
                    >
                        <IdeoDatetime
                            v-model="form.sendDate"
                            type="date"
                            @update:model-value="form.$errors.clear('sendDate')"
                        />
                    </IdeoFormGroup>
                </template>

                <!-- Name -->
                <IdeoFormGroup
                    :label="$t('[[[Nazwa]]]')"
                    :invalid-feedback="form.$errors.first('name')"
                    :state="form.$errors.state('name')"
                    required
                >
                    <IdeoFormInput v-model="form.name" type="text" name="name" />
                </IdeoFormGroup>

                <!-- Content -->
                <IdeoFormGroup
                    :label="$t('[[[Treść]]]')"
                    :invalid-feedback="form.$errors.first('content')"
                    :state="form.$errors.state('content')"
                    required
                >
                    <IdeoFormTextarea
                        v-model.trim="form.content"
                        name="content"
                        :disabled="form.type?.key == 'Dictionary'"
                    />
                </IdeoFormGroup>

                <!-- RecipientType  -->
                <IdeoFormGroup
                    name="recipientType"
                    :label="$t('[[[Typ odbiorcy]]]')"
                    :invalid-feedback="form.$errors.first('recipientType')"
                    :state="form.$errors.state('recipientType')"
                    required
                >
                    <IdeoSelect
                        v-model="form.recipientType"
                        :placeholder="$t('[[[Typ odbiorcy]]]')"
                        :endpoint="`simple-dictionary/${url}/form/${publicId}/document-notifications/recipientType`"
                        @update:modelValue="form.$errors.clear('recipientType')"
                    />
                </IdeoFormGroup>

                <template v-if="form.recipientType?.key === 'User'">
                    <IdeoFormGroup
                        :label="$t('[[[Użytkownik]]]')"
                        :invalid-feedback="form.$errors.first('user')"
                        :state="form.$errors.state('user')"
                    >
                        <IdeoSelect
                            v-model="form.user"
                            :placeholder="$t('[[[Użytkownik]]]')"
                            :endpoint="`simple-dictionary/${url}/form/${publicId}/document-notifications/user`"
                            @update:modelValue="form.$errors.clear('user')"
                        >
                            <template #option="{ option }">
                                <UserAvatar
                                    :src="$filters.baseurl(`storage/files/${option.pictureUrl}`)"
                                    :username="option.value"
                                    rounded="circle"
                                />
                                <span class="ms-2 me-1">{{ option.value }}</span>
                                <span v-if="option.unit">({{ option.unit }})</span>
                            </template>
                        </IdeoSelect>
                    </IdeoFormGroup>
                </template>

                <template v-if="form.recipientType?.key === 'Group'">
                    <IdeoFormGroup
                        :label="$t('[[[Grupa]]]')"
                        :invalid-feedback="form.$errors.first('team')"
                        :state="form.$errors.state('team')"
                    >
                        <IdeoSelect
                            v-model="form.team"
                            :placeholder="$t('[[[Grupa]]]')"
                            :endpoint="`simple-dictionary/${url}/form/${publicId}/document-notifications/team`"
                            @update:modelValue="form.$errors.clear('team')"
                        />
                    </IdeoFormGroup>
                </template>
            </form>
        </template>
        <template #modal-footer="{ cancel }">
            <IdeoButton type="submit" variant="success" form="modal-document-notification" :disabled="!form.active()">
                {{ $t('[[[Zatwierdź]]]') }}
            </IdeoButton>
            <IdeoButton variant="secondary" @click="cancel()">{{ $t('[[[Anuluj]]]') }}</IdeoButton>
        </template>
    </IdeoModal>
</template>
