<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance, onMounted, onUnmounted } from 'vue';
import lodashLast  from 'lodash/last';
import { RowType } from '.';
import { DetailsContract } from '../details';
import { DetailsBuilderContract } from '@/components/builder/details';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import { Events } from '@/components/builder/base/managers/EventManager';

defineOptions({
    name: 'RowBlueprint',
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const refreshKey = ref(1);
const design = computed(() => props.details.designMode());
const last = computed(() => lodashLast(props.parent.components) == props.blueprint);
const selected = computed(() => refreshKey.value > 0 && props.details.clipboard.isRowSelected(props.blueprint) && design.value);

onMounted(() =>
{
    props.details.events.subscribe(Events.FOCUS, onFocus);
});

onUnmounted(() =>
{
    props.details.events.release(Events.FOCUS, onFocus);
});

function onFocus()
{
    if (props.details.clipboard.isRowSelected(props.blueprint))
    {
        refreshKey.value++;
    }
}

const selectPlaceholder = (component: Blueprint = null): void =>
{
    props.details.clipboard.selectPlaceholder(props.blueprint, component);
};

const addComponent = (definition: BlueprintDefinition, before: Blueprint): void =>
{
    props.details.layout.addComponent(props.blueprint, definition, before);
};

const dropComponent = (component: Blueprint, before: Blueprint): void =>
{
    props.details.clipboard.cut(component);
    props.details.clipboard.paste(props.blueprint, before);
};

const instance = getCurrentInstance();

watch(selected, (value: boolean): void =>
{
    if (value)
    {
        props.details.dom.offsetTop(instance);
    }
    else
    {
        if (props.blueprint.components.length == 0 && !selected.value && !last.value)
        {
            props.details.layout.removeComponent(props.blueprint);
        }
    }
});

const components = computed(() => props.blueprint.components);

watch(components, (value: Blueprint[], old: Blueprint[]): void =>
{
    if (value.length == 0 && old.length > 0)
    {
        props.details.layout.removeComponent(props.blueprint);
    }
});
</script>

<template>
    <dynamic-details-component class="row p-0" :class="{ 'g-0': design }" :details="details" :blueprint="blueprint">
        <template v-for="(component, index) in blueprint.components" :key="component.id">
            <dynamic-details-design-placeholder
                :title="$t('Wstaw kontrolkę')"
                :details="details"
                :parent="blueprint"
                :before="component"
                @select="selectPlaceholder(component)"
                @add="addComponent"
                @drop="dropComponent"
            />
            <component
                :is="details.schema.designer(`dynamic-details-${component.type}`)"
                :details="details"
                :parent="blueprint"
                :blueprint="blueprint.components[index]"
            ></component>
        </template>
        <dynamic-details-design-placeholder
            :title="$t('Wstaw kontrolkę')"
            :details="details"
            :parent="blueprint"
            :last="true"
            @select="selectPlaceholder()"
            @add="addComponent"
            @drop="dropComponent"
        />
    </dynamic-details-component>
</template>
