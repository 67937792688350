<template>
    <div class="d-flex align-items-center" style="gap: 0.4rem">
        <div v-if="original && original.value" class="original-value d-flex align-items-center">
            <user-avatar
                :src="$filters.baseurl(`storage/files/${original.pictureUrl}`)"
                :username="original.value"
                rounded="circle"
            />
            <span class="ms-2">
                {{ original.value }}
            </span>
        </div>
        <div v-if="current && current.value" class="current-value d-flex align-items-center">
            <user-avatar
                :src="$filters.baseurl(`storage/files/${current.pictureUrl}`)"
                :username="current.value || ''"
                rounded="circle"
            />
            <span class="ms-2">
                {{ current.value }}
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { KeyValuePair } from "@/helpers/Interfaces";
import { Options, Vue } from "vue-class-component";
@Options({
    name: "UserFormatter",
})
export default class UserFormatter extends Vue
{
    @Prop()
    public original: KeyValuePair;

    @Prop()
    public current: KeyValuePair;
}
</script>
