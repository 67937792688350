import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { KeyValue, KeyValuePair } from '@/helpers/Interfaces';

export const Definition: BlueprintDefinition = {
    type: 'related-document',
    name: '[[[Dokument powiązany]]]',
    icon: 'far fa-clipboard-list',
    group: 'logito',
    position: 8,
};

export interface RelatedDocumentContract
    extends Blueprint,
    VisibleBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    ReadonlyBlueprint,
    HasLabel,
    HasWidth,
    HasHelp,
    HasPlaceholder {
    modules: string[] | KeyValuePair[];
    onlyUnfinished: boolean;
    multiselect: boolean;
    allowAuthor: boolean;
    allowTargets: boolean;
    allowAll: boolean;
    fieldMappings: Record<string, string>;
    filtersJson: string;
    importFilterField: string;
    hasFilterByContractor: boolean;
    hasFilterByRelatedDocuments: boolean;
    contractorFilter: KeyValue<string, string>;
    relatedDocumentsFilter: string;
}

export class RelatedDocumentEntry extends ValidEntry<KeyValuePair[]>
{
    public type: string = Definition.type;
    public data: KeyValuePair[] = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(
        blueprint: RelatedDocumentContract,
        form: FormBuilderContract,
        preprocess: ProcessCallback
    ): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        if (blueprint.multiselect && Array.isArray(this.data))
        {
            const data = this.data.map(item => item.key);

            return entry(data);
        }
        else if (!blueprint.multiselect && this.data != null && this.data.length > 0)
        {
            const data = this.data[0].key;

            return entry(data);
        }

        return entry(null);
    }

    public validate(blueprint: RelatedDocumentContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }
}

export const instaceOfRelatedDocumentEntry = (object: any): object is RelatedDocumentEntry =>
{
    return object && 'type' in object && object.type === Definition.type;
};

export class RelatedDocumentType
implements RelatedDocumentContract, ValidatableBlueprint, EntryFactory<RelatedDocumentEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public fetchModule: string;
    public fetchProp: string;
    public guid: string;
    public processGuid: string;
    public showLabel: boolean;
    public width: number;
    public minWidth: number;
    public help: Record<string, string>;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public placeholder: Record<string, string>;
    public modules: string[] | KeyValuePair[];
    public onlyUnfinished: boolean;
    public multiselect: boolean;
    public allowAuthor: boolean;
    public allowTargets: boolean;
    public allowAll: boolean;
    public fieldMappings: Record<string, string>;
    public filtersJson: string;
    public importFilterField: string;
    public hasFilterByContractor: boolean;
    public hasFilterByRelatedDocuments: boolean;
    public contractorFilter: KeyValue<string, string>;
    public relatedDocumentsFilter: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
        this.fetchModule = '';
        this.fetchProp = '';
        this.guid = '';
        this.processGuid = '';
        this.showLabel = true;
        this.label = { 'pl-PL': 'Dokumenty powiązane' };
        this.placeholder = { 'pl-PL': 'Wybierz...' };
        this.required = AlwaysChoice.Always;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.modules = [];
        this.onlyUnfinished = false;
        this.help = {};
        this.multiselect = false;
        this.allowAuthor = false;
        this.allowTargets = false;
        this.allowAll = false;
        this.fieldMappings = {};
        this.filtersJson = null;
        this.importFilterField = null;
        this.hasFilterByContractor = false;
        this.hasFilterByRelatedDocuments = false;
        this.contractorFilter = { key: null, value: null };
        this.relatedDocumentsFilter = null;
        this.errors = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): RelatedDocumentEntry
    {
        return new RelatedDocumentEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (this.hasFilterByContractor)
        {
            if (!this.contractorFilter.key || !this.contractorFilter.value)
                this.errors.contractorFilter = ['[[[Pole jest wymagane]]]'];
        }

        if (this.hasFilterByRelatedDocuments)
        {
            if (!this.relatedDocumentsFilter)
                this.errors.relatedDocumentsFilter = ['[[[Pole jest wymagane]]]'];
        }

        return {
            [this.name]: this.errors,
        };
    }
}
