import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    // --------------------------------------------------------------------------
    // Kalendarz
    // --------------------------------------------------------------------------
    {
        path: 'calendar',
        redirect: { name: 'calendar' },
        component: EmptyLayout,
        children: [
            {
                path: '',
                name: 'calendar',
                meta: { auth: true, breadcrumbs: '[[[Kalendarz]]]' },
                component: (): Promise<any> => import('@/modules/logito/calendar/views/Calendar.vue'),
            },
            {
                path: 'invitations',
                name: 'calendar-invitations',
                meta: { auth: true, breadcrumbs: '[[[Zaproszenia do akceptacji]]]' },
                component: (): Promise<any> => import('@/modules/logito/calendar/views/InvitationsToAccept.vue'),
            },
            {
                path: 'invitation-details/:publicId',
                name: 'calendar-invitation-details',
                meta: { auth: true, breadcrumbs: '[[[Szczegóły terminu]]]' },
                component: (): Promise<any> => import('@/modules/logito/calendar/views/InvitationDetails.vue'),
            },
        ],
    },
];

export default routes;
