import { axios } from '@/plugins/axios';
import { Resource, Statement, KeyValuePair } from "@/helpers/Interfaces";
import { ParticipantModel } from "@/modules/logito/office/services/RegisterService";

export default class OfficeDocumentsService
{
    public static async getAttachmentFile(publicId: string): Promise<Resource<MainFileModel>>
    {
        return (await axios.get(`office/documents/${publicId}/main-file`)).data;
    }

    public static async getAttachmentsFiles(publicId: string): Promise<MainFileModel[]>
    {
        return (await axios.get(`office/documents/${publicId}/files`)).data;
    }

    public static async accept(publicId: string, comment: string, attachments: string[]): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/accept`, {
            comment: comment,
            attachments: attachments
        })).data;
    }

    public static async ownerProcess(publicId: string, formId: number, form: ToOwnerModel): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/process/${formId}/owner`, form)).data;
    }

    public static async sendToOwner(publicId: string, userId: number, comment: string, attachments: string[] = []): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/change-owner`, {
            userId: userId,
            comment: comment,
            attachments: attachments
        })).data;
    }

    public static async checkPermissions(): Promise<boolean>
    {
        return (await axios.get('office/documents/actions')).data;
    }

    public static async reject(publicId: string, comment: string, attachments: string[]): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/reject`, {
            comment: comment,
            attachments: attachments
        })).data;
    }

    public static async remove(publicId: string, comment: string, attachments: string[]): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/remove`, {
            comment: comment,
            attachments: attachments
        })).data;
    }

    public static async returnProcess(publicId: string, formId: number, form: ReturnModel): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/process/${formId}/return`, form)).data;
    }

    public static async returnSecretary(publicId: string, comment: string, attachments: string[]): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/return-secretary`, {
            comment: comment,
            attachments: attachments
        })).data;
    }

    public static async returnOwner(publicId: string, comment: string, attachments: string[]): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/return-owner`, {
            comment: comment,
            attachments: attachments
        })).data;
    }

    public static async getDocumentHistory(publicId: string): Promise<HistoryItemModel[]>
    {
        return (await axios.get(`office/documents/${publicId}/history`, {
        })).data;
    }

    public static async getDocumentStatuses(): Promise<KeyValuePair[]>
    {
        return (await axios.get(`office/documents/dictionary/document-statuses`)).data;
    }

    public static async simpleProcess(publicId: string, formId: number, form: FlowModel): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/process/${formId}/simple`, form)).data;
    }

    public static async lastPerson(publicId: string): Promise<KeyValuePair>
    {
        return (await axios.get(`office/documents/${publicId}/last-person`, {
        })).data;
    }

    public static async finishDescription(publicId: string, formId: number, form: FlowModel): Promise<Statement>
    {
        return (await axios.post(`office/documents/${publicId}/process/${formId}/finish-description`, form)).data;
    }
}

export interface MainFileModel {
    url: string;
    name: string;
    publicId: string;
    upload?: File;
    thumb?: string;
}

export interface HistoryItemModel {
    action: KeyValuePair;
    created: {
        user: ApiUser,
        impersonator: ApiUser,
        dateUtc: string
    };
    targets: ParticipantModel[];
    dateCreatedUtc: string;
    comment: string;
    attachements: ResourceConfidential[];
    executeStatus?: KeyValuePair;
    hasArchiveData: boolean;
}

export interface ResourceConfidential {
    publicId: string;
    resourceType: string;
    name: string;
    contentType: string;
    contentLength: number;
    url: string;
    isImage: boolean;
}

export interface DocumentModel {
    barcode: string;
}

export interface ToOwnerModel {
    userId: number;
    comment: string;
    attachments: ResourceConfidential[];
}

export interface ReturnModel {
    comment: string;
    attachments: ResourceConfidential[];
}

export interface FlowModel {
    comment: string;
    attachments: ResourceConfidential[];
}

export interface ApiUser {
    key: string,
    value: string,
    pictureUrl?: string
    id: number
}
