<script lang="ts" setup>
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["update:modelValue"]);
import { ref } from 'vue';
import { kebabCase } from 'lodash';
import { ModuleOption } from '@/modules/studio/forms/services/FormsService';
import LogitoFilters from '@/components/common/dynamic-grid-new/filters/Filters.vue';
import IdeoDropdown from '@/components/ideo/dropdown/IdeoDropdown.vue';

defineProps({
  "modelValue": null,
  "label": null,
  "selectedModules": null
});

const model = __MACROS_useVModel("modelValue");

const dropdown = ref<InstanceType<typeof IdeoDropdown>>(null);

const hide = () => dropdown.value.hide();
const lowerLicence = (module: ModuleOption): string => kebabCase(module.licence);
</script>

<template>
    <div class="form-group d-flex flex-column align-items-start">
        <label class="d-block">{{ label }}</label>
        <ideo-dropdown
            ref="dropdown"
            variant="outline-light"
            boundry="#view"
            :top-offset="6"
            :dropleft="!phone"
            no-caret
            class="mb-2"
        >
            <template #button-content>
                <i class="far fa-plus text-success"></i>
            </template>
            <template #default>
                <portal-target name="logito-filters-settings" />
                <div class="p-2 d-flex justify-content-end">
                    <ideo-button variant="secondary" class="text-nowrap" @click="hide">
                        {{ $t('[[[Zamknij]]]') }}
                    </ideo-button>
                </div>
            </template>
        </ideo-dropdown>

        <logito-filters
            v-model:filters="model.modelValue"
            :visibility="false"
            :endpoint="`${lowerLicence(selectedModules[0])}/search`"
            :request-url="lowerLicence(selectedModules[0])"
        />
    </div>
</template>
