<template>
    <div class="user-avatar--wrapper" :style="[style, customStyle]" aria-hidden="true">
        <img v-if="isImage" :src="src" loading="lazy" style="object-fit: cover" class="w-100 h-100" @error="onImgError" />
        <span v-else>{{ userInitial }}</span>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

const getInitials = (username: string): string =>
{
    if (username)
    {
        const parts = username.split(/[ -]/);
        let initials = '';

        for (let i = 0; i < parts.length; i++)
        {
            initials += parts[i].charAt(0);
        }

        if (initials.length > 3 && initials.search(/[A-Z]/) !== -1)
        {
            initials = initials.replace(/[a-z]+/g, '');
        }

        initials = initials.substr(0, 3).toUpperCase();

        return initials;
    }

    return "";
};

@Options({
    name: 'user-avatar',
    emits: ['avatar-initials']
})
export default class UserAvatar extends Vue
{
    @Prop({ default: ' ' }) public username: string;
    @Prop({ default: '' }) public initials: string;
    @Prop({ default: '' }) public backgroundColor: string;
    @Prop({ default: '' }) public color: string;
    @Prop({ default: 36 }) public size: number;
    @Prop({ default: 16 }) public fontSize: number;
    @Prop({ default: 80 }) public lighten: number;
    @Prop() public src: string;
    @Prop() public customStyle: any;

    public backgroundColors: string[] = [
        '#F44336', '#FF4081', '#9C27B0', '#673AB7',
        '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688',
        '#4CAF50', '#8BC34A', '#CDDC39', '#FFC107',
        '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B'];

    public imgError: boolean = false;

    protected get background(): string
    {
        return !this.isImage ? this.backgroundColor || this.randomBackgroundColor(this.username?.length || 0, this.backgroundColors) : '';
    }

    protected get fontColor(): string
    {
        return !this.isImage ? this.color || this.lightenColor(this.background, this.lighten) : '';
    }

    public get isImage(): boolean
    {
        const imageReg = (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(this.src);

        return !this.imgError && Boolean(this.src) && imageReg;
    }

    public get style(): any
    {
        const style = {};

        const imgBackgroundAndFontStyle = {
            background: `transparent url('${this.src}') no-repeat center center / cover`
        };

        const initialBackgroundAndFontStyle = {
            background: this.background,
            color: this.fontColor
        };

        const backgroundAndFontStyle = (this.isImage)
            ? imgBackgroundAndFontStyle
            : initialBackgroundAndFontStyle;

        const size = {
            minWidth: `${this.size}px`,
            width: `${this.size}px`,
            height: `${this.size}px`,
            fontSize: `${this.fontSize}px`
        };

        Object.assign(style, backgroundAndFontStyle, size);

        return style;
    }

    public get userInitial(): string
    {
        return !this.isImage ? this.initials || getInitials(this.username) : '';
    }

    public mounted(): void
    {
        if (!this.isImage)
        {
            this.$emit('avatar-initials', this.username, this.userInitial);
        }
    }

    public initial(): any
    {
        return getInitials;
    }

    public onImgError(evt: any): void
    {
        this.imgError = true;
    }

    public randomBackgroundColor(seed: number, colors: string[]): string
    {
        return colors[seed % (colors.length)];
    }

    public lightenColor(hex: string, amt: number): string
    {
        let usePound = false;

        if (hex[0] === '#')
        {
            hex = hex.slice(1);
            usePound = true;
        }

        const num = parseInt(hex, 16);
        let r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        let g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    }
}
</script>

<style lang="scss" scoped>
.user-avatar {
    &--wrapper {
        display: flex;
        border-radius: 50%;
        font-weight: 600;
        letter-spacing: 1px;
        align-items: center;
        justify-content: center;
        text-align: center;
        user-select: none;
        overflow: hidden;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    }
}
</style>
