import { Form } from '@/helpers/Form';
import Pager from "@/helpers/Pager";

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            employee: null,
            companyId: null,
            documentType: null,
            register: null,
            dateCreatedFromUtc: null,
            dateCreatedToUtc: null,
            dateReceiptFromUtc: null,
            dateReceiptToUtc: null
        }),
        pager: new Pager(1, 50, '', 'ASC')
    }
};
