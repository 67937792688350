<template>
    <layout>
        <template #code>403</template>
        <template #header>Oops! This page is forbidden.</template>
        <template #description>You don't have permission to access this page.</template>
    </layout>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ErrorLayout from '@/views/layouts/ErrorLayout.vue';

@Options({
    name: 'error-page-403',
    components: {
        'layout': ErrorLayout
    }
})
export default class ErrorPage403 extends Vue
{
}
</script>
