<template>
    <component :is="tagName" v-bind="attrs()" :class="classNames">
        <template v-if="loaded">
            <ideo-dropdown v-if="mode === 'primary'" ref="dropdownRef" class="list-view-buttons" variant="light" :text="$t('[[[Akcje]]]')" dropleft boundary="body" no-flip no-caret @show="onShow" @hide="onHide">
                <template #button-content>
                    <i :class="iconClasses"></i>
                </template>
                <slot name="default"></slot>
            </ideo-dropdown>
            <slot v-else />
        </template>
        <placeholder :width="37" :height="32" v-else></placeholder>
    </component>
</template>

<script lang="ts" setup>
import { ref, computed, provide, inject, useAttrs, type Ref, type ComputedRef } from 'vue';
import { only } from '@/helpers/Utils';

export interface Props
{
    icon?: string;
    mode?: 'primary' | 'secondary'
}

defineOptions({
    name: 'list-view-buttons',
    inheritAttrs: false
});

const props = defineProps({
  "icon": { default: 'fas fa-ellipsis-vertical' },
  "mode": { default: 'primary' }
});

const emit = defineEmits(["show", "hide"]);

const dropdownRef = ref<any>(null);

provide('dropdownRef', dropdownRef);

const item = inject<ComputedRef<any>>('item');
const loaded = inject<Ref<boolean>>('loaded');
const layout = inject<Ref<string>>('layout');

const table = computed(() => layout.value == 'table');
const tagName = computed(() => table.value ? 'td' : 'div');
const classNames = computed(() => table.value ? 'actions-column text-end' : '');
const iconClasses = computed(() => ['bigger', props.icon === 'fas fa-ellipsis-vertical' ? dropdownRef.value?.visible ? 'fas fa-close' : 'fas fa-ellipsis-vertical' : props.icon]);

const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');

function onShow()
{
    emit('show', item.value);
}

function onHide()
{
    emit('hide', item.value);
}
</script>
