<script lang="ts" setup>
import { computed } from 'vue';
import { Definition, UrlType } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { ApiQueryCondition, KeyElement, MatchQueryCondition } from '@/helpers/ApiQueryBuilder';
import IdeoFilterText from '@/components/filters/types/IdeoFilterText.vue';

defineOptions({
    name: `${Definition.type}-filter`
});

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});

const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as MatchQueryCondition);
const entry = computed({
    get()
    {
        return condition.value?.match.value || '';
    },
    set(value)
    {
        if (condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                match: {
                    field: component.value.name,
                    value: value
                }
            });
        }

        condition.value.match.value = value;
    }
});

const display = computed(() => entry.value);
const label = computed(() => props.builder.localization.translate(component.value.label));
const placeholder = computed(() => props.builder.localization.translate(component.value.placeholder));

const reset = (): void =>
{
    model.value.remove(p => p.__key == component.value.id);
};
</script>

<template>
    <IdeoFilterText
        v-model="entry"
        :name="component.name"
        :placeholder="placeholder"
        :label="label"
        :index="index"
        :value="entry"
        :display="display"
        :visible="component.showFilter"
        @clear="reset"
        @reset="reset"
    />
</template>
