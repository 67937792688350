<script lang="ts" setup>
import { computed } from 'vue';
import { LineType } from '.';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DetailsBuilderContract } from '@/components/builder/details';
import properties from '../../properties';

defineOptions({
    name: 'LineBlueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const blueprint = computed(() => props.blueprint);
</script>

<template>
    <dynamic-details-component-wrapper
        class="line-component"
        :details="details"
        :parent="parent"
        :blueprint="blueprint"
    >
        <template #default>
            <div class="wrapper">
                <hr />
            </div>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <dynamic-details-accordion>
                <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                    <field-visible :details="details" :blueprint="blueprint" />
                </dynamic-details-accordion-item>
            </dynamic-details-accordion>
        </template>
    </dynamic-details-component-wrapper>
</template>

<style lang="scss" scoped>
.wrapper {
    padding: 5px 0;

    hr {
        margin: 0;
        border-top: 1px solid #999999;
    }
}
</style>
