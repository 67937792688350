<script lang="ts" setup>
import { computed } from 'vue';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';

const props = defineProps({
  "name": { default: '' },
  "valueField": { default: 'value' },
  "textField": { default: 'text' },
  "placeholder": { default: '' },
  "disabled": { type: Boolean, default: false },
  "options": null
});

function getValue(item: any)
{
    return item[props.valueField];
}

const model = defineModel<any[]>();
const internalModel = computed({
    get: () => props.options.filter(p => model.value.includes(getValue(p))),
    set: (value) => model.value = value.map(p => getValue(p))
});
</script>

<template>
    <Multiselect
        v-model="internalModel"
        :options="options"
        :name="props.name"
        :label="props.textField"
        :track-by="props.valueField"
        :placeholder="props.placeholder || $t('[[[wybierz...]]]')"
        :select-label="''"
        :selected-label="''"
        :deselect-label="''"
        :multiple="true"
        :taggable="true"
        :max-height="300"
        :show-no-results="true"
        :hide-selected="false"
        :disabled="props.disabled"
    >
        <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
        <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
        <template #singleLabel="{ option }"><slot name="selected" :option="option"></slot></template>
        <template #option="{ option }"><slot name="option" :option="option"></slot></template>
    </Multiselect>
</template>
