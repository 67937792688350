<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { Prop, Watch } from '@/helpers/Decorators';
import LinkDocumentsModal from './modals/LinkDocumentsModal.vue';
import DocumentRelationshipsService from '../services/DocumentRelationshipsService';

@Options({
    name: 'RelatedDocuments',
    components: {
        LinkDocumentsModal,
    },
})
export default class RelatedDocuments extends Vue
{
    @Prop() public parentType: string;
    @Prop() public documentId: string;
    @Prop({ default: false }) public allowBindings: boolean;
    @Prop() public blueprintId: string;
    @Prop({ default: '' }) public blueprintName: string;
    @Prop({ default: false }) public showFilters: boolean;

    public url: string = 'documents-related';
    public actionObjectType: string = 'related-document';
    public pager = new Pager(1, 10, 'Id', 'DESC');
    public filter = Form.create({});
    public filterBuilder: Record<string, unknown> = {};
    public canLink: boolean = false;
    public extraParams: any = {};

    public get filtersDone(): boolean
    {
        return this.$store.state.common.grid.filtersInitialized;
    }

    public get endpoint(): string
    {
        return `${this.parentType}/${this.documentId}/${this.url}`;
    }

    public get fileName(): string
    {
        return `${this.parentType}_${this.url}`;
    }

    public async created(): Promise<void>
    {
        this.canLink = await DocumentRelationshipsService.canLink(this.documentId, this.parentType, this.blueprintId);
    }

    public redirectToDocument(url: string): void
    {
        url && this.$router.push(url);
    }

    public overwriteFilter(event: { key: string; value: any; period: boolean, amount: boolean }): void
    {
        if (event.period)
        {
            this.filterBuilder[`${event.key}.FromUtc`] = event.value;
            this.filterBuilder[`${event.key}.ToUtc`] = event.value;
            this.filterBuilder[`${event.key}.DaysBeforeNow`] = event.value;
            this.filterBuilder[`${event.key}.DaysAfterNow`] = event.value;
        }
        else if (event.amount)
        {
            this.filterBuilder[`${event.key}.FromUtc`] = event.value;
            this.filterBuilder[`${event.key}.ToUtc`] = event.value;
        }
        else this.filterBuilder[event.key] = event.value;
    }

    public initializationChanged(newValue: boolean): void
    {
        if (newValue)
        {
            this.filter.setInitialValues(this.filterBuilder);
            this.filter.withData(this.filterBuilder);
        }
    }

    @Watch('filtersDone')
    public onFiltersDoneChanged(newValue: boolean): void
    {
        this.initializationChanged(newValue);
    }

    public beforeUnmount(): void
    {
        // ALWAYS CLEAR FILTER INITIALIZATION WHEN LEAVING A COMPONENT IN MODULE SCOPE
        this.$store.commit('common/grid/SET_INITIALIZATION', false);
    }
}
</script>

<template>
    <div>
        <dynamic-grid :pager="pager" :filter="filter" :endpoint="endpoint" :manual-init="true" :extra-params="{ blueprintId: blueprintId }">
            <!-- Header -->
            <template #header>
                <ideo-panel :column="false" :stretch="false" :start="desktop">
                    <template #start>
                        <action-button
                            v-if="canLink"
                            variant="primary"
                            icon="fas fa-plus"
                            :text="$t('[[[Powiąż dokument]]]')"
                            @click="$events.$emit('link-documents')"
                        />
                    </template>
                    <template #default v-if="showFilters">
                        <new-filters-wrapper :filter="filter" :url="url">
                            <dynamic-filters
                                :request-url="url"
                                :parent-type="parentType"
                                :parent-id="documentId"
                                :filter="filter"
                                :extra-params="{ blueprintId: blueprintId }"
                                @filter-update="overwriteFilter"
                            />
                        </new-filters-wrapper>
                    </template>
                    <template #end>
                        <!-- :actions="actions" -->
                        <export-manager
                            :excel="{ endpoint }"
                            :filter="filter"
                            :pager="pager"
                        />
                        <!-- <export-excel :file-name="fileName" :endpoint="endpoint" :filter="filter" :pager="pager" /> -->
                    </template>
                </ideo-panel>
            </template>

            <!-- Actions -->
            <template #actions="{ item }">
                <row-actions>
                    <ideo-dropdown-item-button
                        v-if="item?.result?.url"
                        @click="redirectToDocument(`${item.result.url}`)"
                    >
                        <i class="fas fa-square-list"></i>
                        <span>{{ $t('[[[Szczegóły]]]') }}</span>
                    </ideo-dropdown-item-button>
                </row-actions>
            </template>

            <!-- Modal -->
            <template #modal>
                <link-documents-modal :document-id="documentId" :licence="parentType" :blueprint-id="blueprintId" />
            </template>
        </dynamic-grid>
        <portal v-if="blueprintName" :to="`tab-counter-${blueprintName}`">
            {{ pager.getTotalRows() }}
        </portal>
    </div>
</template>
