import { Blueprint } from "../blueprints/Blueprint";

export interface HasWidth extends Blueprint
{
    width: number;
    minWidth: number;
    exceptWidth?: number[];

    setDefaultWidth(width: number): void
}

export const instanceOfHasWidth = (object: any): object is HasWidth =>
{
    return object && 'width' in object && 'minWidth' in object;
};
