<script lang="ts" setup>
import { computed } from 'vue';
import FieldChoice from './FieldChoice.vue';
import FieldCondition from './FieldCondition.vue';
import { Option } from '@/helpers/Interfaces';
import { useLocalization } from '@/plugins/localization';
import { FormBuilderContract } from '@/components/builder/form';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';

const { $t } = useLocalization();

defineOptions({
    name: 'field-filter',
    components: {
        'field-choice': FieldChoice,
        'field-condition': FieldCondition
    }
});

const props = defineProps({
  "blueprint": null,
  "form": null,
  "label": { default: null }
});
const blueprint = computed(() => props.blueprint);

const choiceOptions: Option<boolean>[] = [
    {value: false, text: $t('[[[Domyślnie ukryte]]]')},
    {value: true, text: $t('[[[Domyślnie widoczne]]]')}
];

</script>

<template>
    <field-choice v-model="blueprint.showFilter" :options="choiceOptions" :label="label || $t('[[[Filtrowanie]]]')" />
</template>
