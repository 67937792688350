<script lang="ts" setup>
import { ref, computed, Ref, inject } from 'vue';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DocumentEntry, DocumentType, instanceOfDocumentEntry } from '.';
import { FormEntry } from '../form';
import StorageService from '@/modules/core/files/services/StorageService';
import properties from '../../properties';

defineOptions({
    name: 'document-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $events } = useEvents();
const { $alert } = useAlerts();

const entryData = ref(new DocumentEntry()) as Ref<DocumentEntry>;

entryData.value = props.form.document.initEntry(
    props.blueprint,
    entryData.value,
    instanceOfDocumentEntry,
    props.index
);

const level = inject<number>('level', 0);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value != null ? [value] : null;
    },
    set(value: any[])
    {
        if (value == null)
        {
            entryData.value.data = null;
            uploadFiles(null);

            return;
        }

        if (Array.isArray(value)) value = value[0];

        uploadFiles(value);
        entryData.value.data = value;
    },
});

const blueprint = computed(() => props.blueprint);
const required = computed(() => props.form.expressions.required(props.blueprint));
const extensions = computed(() =>
    props.blueprint.extensions
        .split(',')
        .map((p) => p.trim())
        .filter((p) => !!p)
);

const uploadFiles = async (file: any) =>
{
    try
    {
        if (file && file.upload)
        {
            const uploaded = await StorageService.upload(file.upload, 'documents');

            uploaded.upload = null;

            entryData.value.data = uploaded;
            $events.$emit(`file-to-preview-${level}`, uploaded);
        }
        else
        {
            entryData.value.data = null;
            $events.$emit(`file-to-preview-${level}`, null);
        }
    }
    catch (ex: any)
    {
        $alert.danger(ex.message);
    }
};

const init = () =>
{
    $events.$emit(`file-to-preview-${level}`, entryData.value.data ?? null);
};

init();
</script>

<template>
    <form-component-wrapper class="document-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <fileupload
                    v-model="value"
                    :id="blueprint.id"
                    :max-size="blueprint.maxFileSize"
                    :max-files="blueprint.maxFiles"
                    :extensions="extensions"
                    :dark="false"
                    :margin-bottom="false"
                />
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-text v-model="blueprint.extensions" :label="$t('[[[Dozwolone rozszerzenia]]]')" />
            <field-numeric
                v-model="blueprint.maxFileSize"
                :label="$t('[[[Maksymalny rozmiar pliku w MB]]]')"
                :min="0"
                :max="100"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>
