// Blueprints
import { RootBlueprint } from "../../base/blueprints/RootBlueprint";
import { DetailsBlueprint } from "../blueprints/DetailsBlueprint";
import { RowBlueprint } from "../../form/blueprints/RowBlueprint";

// Services
import { BlueprintFactory } from "../../base/services/BlueprintFactory";

export class DetailsBlueprintFactory extends BlueprintFactory
{
    public createRoot(id: string, name: string): RootBlueprint
    {
        return this.createDetails(id, name);
    }

    public createDetails(id: string, name: string): DetailsBlueprint
    {
        return this.create('details', id, name) as DetailsBlueprint;
    }

    public createRow(id: string, name: string): RowBlueprint
    {
        return this.create('row', id, name) as any;
    }
}
