import { initializeApp, FirebaseApp } from 'firebase/app';
import { Messaging, getMessaging, onMessage, getToken } from 'firebase/messaging';
import { getCurrentInstance, Plugin } from 'vue';

export interface Firebase
{
    app: FirebaseApp,
    init(): Promise<string>
}

class FirebaseHelper implements Firebase
{
    public app: FirebaseApp;
    public messaging: Messaging;

    public constructor(config: any)
    {
        this.app = initializeApp(config);
    }

    public async init(): Promise<string>
    {
        this.messaging = getMessaging(this.app);

        onMessage(this.messaging, (payload) =>
        {
            // Todo: Remove console.log
            console.log('Message received', payload);
        });

        return await this.setToken();
    }

    private async setToken(): Promise<string>
    {
        try
        {
            const permission = await Notification.requestPermission();

            if (permission === 'granted')
            {
                const token = await getToken(this.messaging, { vapidKey: 'BBrJwMp-JuEuLqxCa8D1x8o0GaKMxU42hNEzTfJDwYzWaCIkHXETfwy0Y0W16N-CyZMt9OK3QcNnGBVwPWTT5Bk' });

                return token;
            }
            else
            {
                // eslint-disable-next-line no-console
                console.log('You have disabled notifications in your browser');
            }
        }
        catch (ex)
        {
            // eslint-disable-next-line no-console
            console.error(ex);
        }
    }
}

export const useFirebase = (): {$firebase: Firebase} =>
{
    const app = getCurrentInstance();

    return {
        $firebase: app.appContext.config.globalProperties.$firebase
    };
};

const FirebasePlugin: Plugin =
{
    install(app, options)
    {
        const firebaseConfig = {
            apiKey: "AIzaSyAUgfcKzmgsW-O2VmJH1VjVz-svy-MPIv0",
            authDomain: "test-2057f.firebaseapp.com",
            projectId: "test-2057f",
            storageBucket: "test-2057f.appspot.com",
            messagingSenderId: "149933137180",
            appId: "1:149933137180:web:20de4e9c9f28ef4e65aed9"
        };

        app.config.globalProperties.$firebase = new FirebaseHelper(firebaseConfig);
    }
};

export default FirebasePlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $firebase: Firebase
    }
}
