import { axios } from '@/plugins/axios';
import { KeyValuePair, Pagination, Resource } from '@/helpers/Interfaces';

export default class SearchEngineService
{
    public static async search(model: FormModel): Promise<SearchResultModel[]>
    {
        return (await axios.post<SearchResultModel[]>(`crm/dictionary/edoreczenia/search`, model)).data;
    }

    public static async getEdoreczeniaEnabled(): Promise<boolean>
    {
        return (await axios.get<boolean>(`crm/dictionary/edoreczenia/enabled`)).data;
    }

    public static async getEdoreczeniaOrganizationType(): Promise<string>
    {
        return (await axios.get<string>(`crm/dictionary/edoreczenia/organization-type`)).data;
    }
    public static async getEdoreczeniaSearchTypes(): Promise<Pagination<Resource<KeyValuePair>>>
    {
        return (await axios.get(`crm/dictionary/edoreczenia/search-types`)).data;
    }

}

export interface FormModel
{
    searchType: KeyValuePair;
    searchMode: KeyValuePair;
    nip: string;
    regon: string;
    krs: string;
    pesel: string;
    name: string;
    surname: string;
    address: Address;
}

export interface Address
{
    city: string;
    buildingNumber: string;
    flatNumber: string;
    street: string;
    postalCode: string;
}

export interface SearchResultModel
{
    nameOrEntity: string;
    correspondenceAddress: string;
    headquartersAddress: string;
    nip: string;
    krs: string;
    pesel: string;
    regon: string;
    recipientEda: string;
}
