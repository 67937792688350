<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';
import { ExchangeRate } from "@/modules/low-code/services/ExchangeRatesService";

@Options({
    name: 'ExchangeRateFormatter',
})
export default class ExchangeRateFormatter extends Vue
{
    @Prop() public property: ExchangeRate;
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;
}
</script>

<template>
    <div class="text-nowrap d-block">
        <span v-if="property">{{ `${property.currencyFrom} (${property.rate})` }}</span>
        <span v-else>-</span>
    </div>
</template>

