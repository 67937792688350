<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { $t } from '@/plugins/localization';
import Participant from './Participant.vue';
import { KeyValuePair, Option } from '@/helpers/Interfaces';
import EntityService, { AddParticipantModel } from '@/modules/common/services/EntityService';
import { Form, FormType } from '@/helpers/Form';
import { UserDisplayModel } from '@/modules/core/dictionaries/services/DictionaryService';

@Options({
    components: { Participant },
    emits: ['submited'],
})
export default class ParticipantAdder extends Vue
{
    @Prop({ default: null }) public publicId: string;
    @Prop({ default: null }) public licence: string;
    @Prop({ default: '' }) public openDirection: string;
    @Prop({ default: 300 }) public maxHeight: number;
    @Prop({ default: true }) public showAlert: boolean;
    @Prop({ default: null }) public eventBus: Vue;
    @Prop({ default: '', required: true }) public type: string;
    @Prop({ default: null, required: true }) public shareFor: any;

    public user: UserDisplayModel[] = [];
    public units: KeyValuePair[] = [];
    public teams: KeyValuePair[] = [];

    public form: FormType<AddParticipantModel> = Form.create<AddParticipantModel>({
        userId: [],
        departmentId: [],
        teams: [],
    });
    public source: string = 'user';

    public options: Option[] = [
        { value: 'user', text: $t('[[[Użytkownik]]]') },
        { value: 'units', text: $t('[[[Dział]]]') },
        { value: 'teams', text: $t('[[[Zespół]]]') },
    ];

    public async created(): Promise<void>
    {
        this.$events.$on('share', this.addParticipantsList);
        this.$events.$on('loadParticipantDetails', this.loadDetails);

        this.setOptions();
    }

    private async setOptions(): Promise<void>
    {
        if (this.shareFor == null) return;

        if (!this.shareFor.User) this.options = this.options.filter((p: any) => p.value != 'user');

        if (!this.shareFor.Unit) this.options = this.options.filter((p: any) => p.value != 'units');

        if (!this.shareFor.Team) this.options = this.options.filter((p: any) => p.value != 'teams');

        if (this.options.length > 0) this.source = this.options[0].value;
        else this.source = '';
    }

    public async addParticipantsList(publicId: string): Promise<void>
    {
        this.form.userId = this.user.map((user) => Number(user.key));
        this.form.departmentId = this.units.map((unit) => Number(unit.key));
        this.form.teams = this.teams.map((team) => Number(team.key));

        try
        {
            await EntityService.addParticipant(publicId || this.publicId, this.licence, this.form);

            if (this.showAlert)
                this.$alert.success(this.$t('[[[Dokument został udostępniony dla wybranych użytkowników.]]]'));

            this.$emit('submited');
            this.$events.$emit('refetchData');
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors),
            });
        }
    }

    public loadDetails(user: any): void
    {
        this.user = user.map((user: any) => user.result.user);
    }

    public unmounted(): void
    {
        this.$events.$off('share');
        this.$events.$off('loadParticipantDetails');
    }
}
</script>

<template>
    <IdeoFormGroup
        :label="$t('[[[Udostępnij dla]]]')"
        :invalid-feedback="form.$errors.first('userId')"
        :state="form.$errors.state('userId')"
    >
        <IdeoFormRadioGroup v-model="source" :options="options" id="source" class="mb-2" name="radio-source" />

        <IdeoSelect
            v-if="source == 'user'"
            v-model="user"
            :placeholder="$t('[[[Użytkownik]]]')"
            :multiselect="true"
            :endpoint="`simple-dictionary/${licence}/form/${publicId}/participants/userId`"
        >
            <template #option="{ option }">
                <UserAvatar
                    :src="$filters.baseurl(`storage/files/${option.pictureUrl}`)"
                    :username="option.value"
                    rounded="circle"
                />
                <span class="ms-2 me-1">{{ option.value }}</span>
                <span v-if="option.unit">({{ option.unit }})</span>
            </template>
        </IdeoSelect>

        <IdeoSelect
            v-if="source == 'units'"
            v-model="units"
            :placeholder="$t('[[[Dział]]]')"
            :multiselect="true"
            :endpoint="`simple-dictionary/${licence}/form/${publicId}/participants/departmentId`"
        />

        <IdeoSelect
            v-if="source == 'teams'"
            v-model="teams"
            :placeholder="$t('[[[Zespół]]]')"
            :multiselect="true"
            :endpoint="`simple-dictionary/${licence}/form/${publicId}/participants/teamId`"
        />
    </IdeoFormGroup>
</template>

<style lang="scss">
.participant {
    &__item {
        position: relative;
        margin-right: 15px;
    }
    .share__icon {
        text-decoration: none;
        &:hover,
        &.active {
            background: var(--primary);
            i {
                color: $white !important;
                transition: color 0.3s;
            }
        }
        i {
            top: -3px;
            left: -2px;
            font-size: 16px;
        }
    }
}
</style>
