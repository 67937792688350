<script lang="ts">
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { Column, ColumnDefinitionModel } from '@/modules/core/common/services/GridService';
import { entries } from 'lodash';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'ColumnsCustomizer',
    emits: ['definitionChanged', 'refresh'],
})
export default class ColumnsCustomizer extends Vue
{
    @Prop({ default: null })
    public columns: ColumnDefinitionModel;

    @Prop({ default: "view-manager-columns" })
    public portalName: string;

    @Prop({ default: "[[[Kolumny]]]" })
    public title: string;

    @Prop({ default: false })
    public refreshButton: boolean;

    public columnDef: ColumnDefinitionModel = null;
    public topOrBottom: string = '';
    public draggedOrder: string = '';
    public allCheckbox: boolean = false;

    public get sortedColumns(): [string, Column][]
    {
        this.columnDef = this.columns;

        if (this.columnDef)
        {
            const sorted = entries(this.columnDef)
                .sort((a, b) =>
                {
                    if (b[1].order < a[1].order) return 1;

                    return -1;
                })
                .filter(
                    (column) =>
                        column[0] !== 'id' && column[0] !== 'publicId' && column[0] !== 'userId' && column[1].headerName
                );

            if (sorted.length > 0) this.$store.commit('common/grid/SET_COLUMNS_VISIBILITY', true);
            else this.$store.commit('common/grid/SET_COLUMNS_VISIBILITY', false);

            return sorted;
        }

        return [];
    }

    // Drag and drop
    public startDrag(e: DragEvent, item: Column): void
    {
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('orderId', String(item.order));
        this.draggedOrder = String(item.order);
    }

    public checkInWhichSide(e: DragEvent): void
    {
        const target = e.target as HTMLDivElement;
        const link: HTMLAnchorElement = target.closest('[data-order]');
        const linkOrder = link.dataset.order;

        if (linkOrder === this.draggedOrder)
        {
            return;
        }

        const { height } = link.getBoundingClientRect();
        const halfHeight = height / 2;

        if (e.offsetY >= halfHeight)
        {
            link.classList.remove('top-bar');
            link.classList.add('bottom-bar');
            this.topOrBottom = 'bottom';
        }
        else
        {
            link.classList.remove('bottom-bar');
            link.classList.add('top-bar');
            this.topOrBottom = 'top';
        }
    }

    public clearBorders(e: DragEvent): void
    {
        const target = e.target as HTMLDivElement;
        const link: HTMLAnchorElement = target.closest('[data-order]');

        link && link.classList.remove('top-bar', 'bottom-bar');
    }

    @Emit('definitionChanged')
    public onDrop(e: DragEvent): ColumnDefinitionModel
    {
        this.draggedOrder = '';
        document.querySelectorAll('[data-order]').forEach((elem) => elem.classList.remove('top-bar', 'bottom-bar'));

        // all grid columns
        const columnsEntries = entries(this.columnDef);
        // target that u drop dragged element on
        const dropTarget = e.target as HTMLElement;
        // get parent link wrapper and its current order
        const link: HTMLAnchorElement = dropTarget.closest('[data-order]');
        const targetOrderId = Number(link.dataset.order);
        // get dragged item orderId
        const orderId = Number(e.dataTransfer.getData('orderId'));
        // find those items in grid columns
        const item = columnsEntries.find((column) =>
        {
            return column[1].order === orderId;
        });

        if (item[1].order === targetOrderId) return this.columnDef;

        if (this.topOrBottom === 'top')
        {
            // recalculate order for all items below dragged element
            if (orderId < targetOrderId)
            {
                item[1].order = targetOrderId - 1;

                const sorted = entries(this.columnDef)
                    .sort((a, b) =>
                    {
                        if (b[1].order < a[1].order) return 1;

                        return -1;
                    })
                    .filter((elem) => elem[1].headerName !== item[1].headerName);

                for (let i = targetOrderId - 3; i >= orderId - 1; i--)
                {
                    sorted[i][1].order = sorted[i][1].order - 1;
                }
            }
            else
            {
                item[1].order = targetOrderId;

                const sorted = entries(this.columnDef)
                    .sort((a, b) =>
                    {
                        if (b[1].order < a[1].order) return 1;

                        return -1;
                    })
                    .filter((elem) => elem[1].headerName !== item[1].headerName);

                for (let i = targetOrderId - 1; i < orderId - 1; i++)
                {
                    sorted[i][1].order = sorted[i][1].order + 1;
                }
            }
        }

        if (this.topOrBottom === 'bottom')
        {
            // recalculate order for all items above dragged element
            if (orderId < targetOrderId)
            {
                item[1].order = targetOrderId;

                const sorted = entries(this.columnDef)
                    .sort((a, b) =>
                    {
                        if (b[1].order < a[1].order) return 1;

                        return -1;
                    })
                    .filter((elem) => elem[1].headerName !== item[1].headerName);

                for (let i = targetOrderId - 2; i > orderId - 2; i--)
                {
                    sorted[i][1].order = sorted[i][1].order - 1;
                }
            }
            else
            {
                item[1].order = targetOrderId + 1;

                const sorted = entries(this.columnDef)
                    .sort((a, b) =>
                    {
                        if (b[1].order < a[1].order) return 1;

                        return -1;
                    })
                    .filter((elem) => elem[1].headerName !== item[1].headerName);

                for (let i = targetOrderId; i < orderId - 1; i++)
                {
                    sorted[i][1].order = sorted[i][1].order + 1;
                }
            }
        }

        return this.columnDef;
    }

    // mobile order switching

    public canSwitchUp(item: [string, Column]): boolean
    {
        return item[1].order !== 1;
    }

    @Emit('definitionChanged')
    public switchUp(item: [string, Column]): ColumnDefinitionModel
    {
        const columnsEntries = entries(this.columnDef);
        const column = columnsEntries
            .sort((a, b) =>
            {
                if (b[1].order < a[1].order) return 1;

                return -1;
            })
            .filter((column) => column[0] !== 'id')
            .find((column) =>
            {
                return item[1].order - column[1].order === 1;
            });

        column[1].order++;
        item[1].order--;

        return this.columnDef;
    }

    public get isCheckboxDisabled(): boolean
    {
        const visibleColumns = entries(this.columnDef).filter((column) =>
        {
            return column[1].visibility;
        });

        return visibleColumns.length <= 1;
    }

    public canSwitchDown(item: [string, Column]): boolean
    {
        const sorted = entries(this.columnDef)
            .sort((a, b) =>
            {
                if (b[1].order < a[1].order) return 1;

                return -1;
            })
            .filter((column) => column[0] !== 'id');

        return item[1].order !== sorted.length;
    }

    @Emit('definitionChanged')
    public switchDown(item: [string, Column]): ColumnDefinitionModel
    {
        const columnsEntries = entries(this.columnDef);
        const column = columnsEntries
            .sort((a, b) =>
            {
                if (b[1].order < a[1].order) return 1;

                return -1;
            })
            .filter((column) => column[0] !== 'id')
            .find((column) =>
            {
                return item[1].order - column[1].order === -1;
            });

        column[1].order--;
        item[1].order++;

        return this.columnDef;
    }

    @Emit('definitionChanged')
    public columnVisibilityChanged(value: boolean, item: Column): ColumnDefinitionModel
    {
        item.visibility = value;

        return this.columnDef;
    }

    @Watch('columns')
    public setColumns(columns: ColumnDefinitionModel): void
    {
        this.columnDef = columns;
    }

    @Watch('sortedColumns', { immediate: true, deep: true })
    public setColumnDef(sortedColumns: [string, any][] = []): void
    {
        const result = sortedColumns.some(([_, value]) => value.visibility);

        this.allCheckbox = result;
    }

    public changeAllCheckbox(value: boolean): void
    {
        this.allCheckbox = value;

        entries(this.columnDef).forEach((column) =>
        {
            column[1].visibility = this.allCheckbox;
        });

        this.$emit('definitionChanged', this.columnDef);
    }

    public refreshColumns(): void
    {
        this.$emit('refresh');
    }
}
</script>

<template>
    <portal :to="portalName">
        <data-card overflow scroll class="overflow-hidden flex-shrink-1">
            <template #header>
                <div class="d-flex justify-content-between">
                    <ideo-form-checkbox :model-value="allCheckbox" @change="changeAllCheckbox">
                        <strong>{{ $t(title) }}</strong>
                    </ideo-form-checkbox>
                    <ideo-tooltip v-if="refreshButton" :tooltip="$t('[[[Przywróć domyślną listę kolumn]]]')" position="left">
                        <ideo-button variant="primary" size="sm" icon="fas fa-refresh" @click="refreshColumns"></ideo-button>
                    </ideo-tooltip>
                </div>
            </template>
            <template #default>
                <div v-if="mobile" class="columns-customizer scroll scroll--overflow-x-hidden">
                    <div
                        v-for="(header, index) in sortedColumns"
                        :key="index"
                        :data-order="header[1].order"
                        data-unclosing="true"
                        class="dropdown-item px-3"
                    >
                        <div class="d-flex align-items-center">
                            <div class="d-flex flex-column me-2">
                                <button
                                    :disabled="!canSwitchUp(header)"
                                    class="border-0 bg-transparent mb-1 order-switcher"
                                    type="button"
                                    @click="switchUp(header)"
                                >
                                    <span class="fas fa-arrow-alt-up"></span>
                                </button>
                                <button
                                    :disabled="!canSwitchDown(header)"
                                    class="border-0 bg-transparent order-switcher"
                                    type="button"
                                    @click="switchDown(header)"
                                >
                                    <span class="fas fa-arrow-alt-down"></span>
                                </button>
                            </div>
                            <ideo-form-checkbox
                                :modelValue="header[1].visibility"
                                @change="columnVisibilityChanged($event, header[1])"
                            >
                                {{ header[1].headerName }}
                            </ideo-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="columns-customizer flex-shrink-1 scroll scroll--overflow-x-hidden" v-else>
                    <div
                        class="drop-zone"
                        @drop="onDrop($event)"
                        @dragover.prevent="checkInWhichSide"
                        @dragenter.prevent
                        @dragleave.prevent="clearBorders"
                    >
                        <div
                            v-for="(header, index) in sortedColumns"
                            :key="index"
                            draggable="true"
                            :data-order="header[1].order"
                            style="cursor: move"
                            class="dropdown-item py-2 px-3"
                            data-unclosing="true"
                            @dragstart="startDrag($event, header[1])"
                        >
                            <div class="d-flex align-items-center">
                                <span class="fas fa-grip-vertical me-2"></span>
                                <ideo-form-checkbox
                                    :modelValue="header[1].visibility"
                                    @change="columnVisibilityChanged($event, header[1])"
                                >
                                    {{ header[1].headerName }}
                                </ideo-form-checkbox>
                            </div>
                        </div>
                        <template v-if="!sortedColumns.length">
                            <p class="mb-0 p-2 px-3">{{ $t('[[[Brak kolumn]]]') }}</p>
                        </template>
                    </div>
                </div>
            </template>
        </data-card>
    </portal>
</template>

<style lang="scss" scoped>
.order-switcher {
    color: var(--filter-nav-link-color);
}

button[disabled] {
    opacity: 0.6;
}

.top-bar {
    box-shadow: 0 -3px 0 $danger;
    position: relative;
    z-index: 2;
}

.bottom-bar {
    box-shadow: 0 3px 0 0 $danger;
    position: relative;
    z-index: 2;
}

.columns-customizer {
    margin: -10px;

    .card-header {
        padding: 0.5rem 1rem;
    }

    .dropdown-item {
        &:last-of-type {
            border-bottom: 0;
        }
    }
}
</style>
