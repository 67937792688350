<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { DefaultAddressListItemModel } from '@/modules/logito/crm/services/BusinessPartnerService';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'PartnerFormatter',
})
export default class PartnerFormatter extends Vue
{
    @Prop({ default: null }) public property: DefaultAddressListItemModel;
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;

    public get getAddress(): string
    {
        return `${this.property.street} ${this.property.houseID} ${
            this.property.additionalHouseID ? '/ ' + this.property.additionalHouseID : ''
        }`;
    }

    public get getAddress2(): string
    {
        return `${this.property.postalCode} ${this.property.city}`;
    }
}
</script>

<template>
    <div class="d-flex align-items-center" v-if="property">
        <span class="fa fa-map-marker-alt text-primary me-2"></span>
        <div class="d-flex flex-column">
            <span v-html="getAddress"></span>
            <span v-html="getAddress2"></span>
        </div>
    </div>
</template>
