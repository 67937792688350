<template>
    <div class="d-flex flex-column small">
        <div class="d-flex align-items-center mb-2">
            <div class="changelog-label">
                <strong>{{ $t("[[[Utworzył]]]") }}:</strong>
            </div>
            <div class="original-value d-flex align-items-center" v-if="original && original.created.user">
                <user-avatar
                    :src="$filters.baseurl(`storage/files/${original.created.user.facePictureUrl}`)"
                    :username="original.created.user.name"
                    rounded="circle"
                    class="me-3"
                />
                <span class="ms-2">
                    {{ original.created.user.value }}
                </span>
            </div>
            <div class="current-value d-flex align-items-center" v-if="current && current.created.user">
                <user-avatar
                    :src="$filters.baseurl(`storage/files/${current.created.user.facePictureUrl}`)"
                    :username="current.created.user.value"
                    rounded="circle"
                    class="me-3"
                />
                <span class="ms-2">
                    {{ current.created.user.value }}
                </span>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="changelog-label">
                <strong>{{ $t("[[[Zmodyfikował]]]") }}:</strong>
            </div>
            <div class="original-value d-flex align-items-center" v-if="original && original.modified.user">
                <user-avatar
                    :src="$filters.baseurl(`storage/files/${original.modified.user.facePictureUrl}`)"
                    :username="original.modified.user.name"
                    rounded="circle"
                    class="me-3"
                />
                {{ original.modified.user.value }}
            </div>
            <div class="current-value d-flex align-items-center" v-if="current && current.modified.user">
                <user-avatar
                    :src="$filters.baseurl(`storage/files/${current.modified.user.facePictureUrl}`)"
                    :username="current.modified.user.value"
                    rounded="circle"
                    class="me-3"
                />
                {{ current.modified.user.value }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'AuditFormatter'
})
export default class DocumentAuditFormatter extends Vue
{
    @Prop({ default: null })
    public current: Record<string, any>;

    @Prop({ default: null })
    public original: Record<string, any>;

    @Prop({ default: null })
    public definition: ChangelogDefinitionModel;
}
</script>
