<template>
    <div class="dropdown-divider"></div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'ideo-dropdown-divider'
})
export default class IdeoDropdownDivider extends Vue
{
}
</script>
