import { KeyValuePair } from '@/helpers/Interfaces';
import { GatewayTypes, FormButtonStyle } from './Enumts';

interface ColorOption {
    stroke: string;
    fill: string;
    type: any;
}

interface FormButtonStyleOption {
    type: any;
    variant: string;
    icon: string;
}

const ProcessStatusVariants: KeyValuePair[] = [
    { key: 'Active', value: 'success' },
    { key: 'Draft', value: 'warning' },
    { key: null, value: 'secondary' },
];

enum ControlTypes {
    FormTask = 'FormTask',
    UserTask = 'UserTask',
    Task = 'Task',
    Condition = 'Condition',
    SingleChoiceTask = 'SingleChoiceTask',
    ServiceTask = 'ServiceTask',
}

const GatewayColorOptions: ColorOption[] = [
    { stroke: '#222222', fill: '#FFBA52', type: ControlTypes.Condition },
    { stroke: '#222222', fill: '#FFF3C3', type: GatewayTypes.DecisionGate },
    { stroke: '#222222', fill: '#FFBA52', type: GatewayTypes.LiveCheckGate },
    { stroke: '#222222', fill: '#FFF3C3', type: GatewayTypes.SingleCheckGate },
    { stroke: '#222222', fill: '#FFBA52', type: GatewayTypes.ExclusiveGate },
    { stroke: '#222222', fill: '#FFBA52', type: GatewayTypes.InclusiveGate },
];

const ControlsColorOptions: ColorOption[] = [
    { stroke: '#222222', fill: '#E4F6FF', type: ControlTypes.FormTask },
    { stroke: '#222222', fill: '#E4F6FF', type: ControlTypes.Task },
    { stroke: '#222222', fill: '#52BAE8', type: ControlTypes.ServiceTask },
    { stroke: '#222222', fill: '#52BAE8', type: ControlTypes.UserTask },
    { stroke: '#222222', fill: '#52BAE8', type: ControlTypes.SingleChoiceTask },
    { stroke: '#222222', fill: '#DC3D1B', type: GatewayTypes.Invalid },
    { stroke: '#222222', fill: '#FFBA52', type: GatewayTypes.ExclusiveGate },
    { stroke: '#222222', fill: '#FEE440', type: GatewayTypes.ParallelGate },
    { stroke: '#222222', fill: '#FEE440', type: GatewayTypes.Fetch },
    { stroke: '#222222', fill: '#FEE440', type: GatewayTypes.Join },
];

const FormButtonStyleOptions: FormButtonStyleOption[] = [
    { type: FormButtonStyle.Invalid, variant: 'secondary', icon: '' },
    { type: FormButtonStyle.Acceptance, variant: 'success', icon: 'fas fa-check' },
    { type: FormButtonStyle.Rejection, variant: 'danger', icon: 'fas fa-times' },
    { type: FormButtonStyle.Return, variant: 'info', icon: 'fas fa-undo' },
];

export {
    ProcessStatusVariants,
    ColorOption,
    GatewayColorOptions,
    FormButtonStyleOption,
    FormButtonStyleOptions,
    ControlsColorOptions,
};
