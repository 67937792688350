<script lang="ts" setup>
import { getCurrentInstance, computed } from 'vue';

defineOptions({
    name: 'field-textarea'
});

defineProps({
  "label": null,
  "modelValue": null,
  "placeholder": null
});

const emit = defineEmits(["update:modelValue"]);

const instance = getCurrentInstance();

const uid = computed(() => `form-field-${instance.uid}`);

const update = (e: any): void =>
{
    emit('update:modelValue', e?.target?.value);
};
</script>

<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <textarea :id="uid" class="form-control" :placeholder="placeholder" :value="modelValue" @input="update"></textarea>
    </div>
</template>
