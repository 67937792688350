<template>
    <div class="input-group">
        <slot name="prepend" v-if="hasPrepend && hasPrependSlot"></slot>
        <ideo-input-group-text v-else-if="hasPrepend && hasPrependText">
            <slot name="prepend-text">{{ prepend }}</slot>
        </ideo-input-group-text>

        <slot name="default"></slot>

        <slot name="append" v-if="hasAppend && hasAppendSlot"></slot>
        <ideo-input-group-text v-else-if="hasAppend && hasAppendText">
            <slot name="append-text">{{ append }}</slot>
        </ideo-input-group-text>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import IdeoInputGroupText from './IdeoInputGroupText.vue';

@Options({
    name: 'ideo-input-group',
    components: {
        'ideo-input-group-text': IdeoInputGroupText
    }
})
export default class IdeoInputGroup extends Vue
{
    @Prop({ default: null })
    public prepend: string;

    @Prop({ default: null })
    public append: string;

    public get hasPrependSlot(): boolean
    {
        return 'prepend' in this.$slots;
    }

    public get hasPrependTextSlot(): boolean
    {
        return 'prepend-text' in this.$slots;
    }

    public get hasPrependText(): boolean
    {
        return this.hasPrependTextSlot || (this.prepend != null && this.prepend != '');
    }

    public get hasPrepend(): boolean
    {
        return this.hasPrependSlot || this.hasPrependText;
    }

    public get hasAppendSlot(): boolean
    {
        return 'append' in this.$slots;
    }

    public get hasAppendTextSlot(): boolean
    {
        return 'append-text' in this.$slots;
    }

    public get hasAppendText(): boolean
    {
        return this.hasAppendTextSlot || (this.append != null && this.append != '');
    }

    public get hasAppend(): boolean
    {
        return this.hasAppendSlot || this.hasAppendText;
    }
}
</script>
