import cloneDeepWith from 'lodash/cloneDeepWith';

// Blueprints
import { Blueprint } from '../blueprints/Blueprint';
import { AggregateBlueprint } from '../blueprints/AggregateBlueprint';

// Managers
import { EventManager, Events } from './EventManager';
import { SchemaManager } from './SchemaManager';
import { LayoutManager } from './LayoutManager';

// --------------------------------------------------

type ClipboardMode = 'none'|'copy'|'cut';

export class ClipboardManager
{
    protected mode: ClipboardMode = 'none';
    protected target: Blueprint = null;
    protected source: Blueprint = null;

    protected events: EventManager;
    protected schema: SchemaManager;
    protected layout: LayoutManager;

    public constructor(eventManager: EventManager, schemaManager: SchemaManager, layoutManager: LayoutManager)
    {
        this.events = eventManager;
        this.schema = schemaManager;
        this.layout = layoutManager;

        this.events.subscribe(Events.FOCUS, (blueprint: Blueprint) =>
        {
            this.selectComponent(blueprint);
        });
    }

    public getTarget(): Blueprint
    {
        return this.target;
    }

    public getSource(): Blueprint
    {
        return this.source;
    }

    public copy(component: Blueprint): void
    {
        this.source = component;
        this.mode = 'copy';
    }

    public cut(component: Blueprint): void
    {
        this.source = component;
        this.mode = 'cut';
    }

    public paste(parent: AggregateBlueprint, before: Blueprint = null): void
    {
        if (this.mode == 'copy' && this.source != null && this.canPaste(parent))
        {
            const names: Record<string, string[]> = {};
            const component = cloneDeepWith(this.source, (value, key, item) =>
            {
                if (key == 'id')
                    return this.schema.newId();

                if (key == 'name')
                {
                    names[item.type] = names[item.type] || this.schema.names(item.type);

                    const name = this.schema.name(item.type, names[item.type]);

                    names[item.type].push(name);

                    return name;
                }
            });

            this.layout.insertComponent(parent, component, before);
            this.selectComponent(component);
        }

        if (this.mode == 'cut' && this.source != null && this.canPaste(parent))
        {
            this.layout.removeComponent(this.source);
            this.layout.insertComponent(parent, this.source, before);
            this.selectComponent(this.source);
        }

        this.source = null;
        this.mode = 'none';
    }

    public isCopy(component: Blueprint): boolean
    {
        return this.mode == 'copy' && this.source?.id == component.id;
    }

    public isCut(component: Blueprint): boolean
    {
        return this.mode == 'cut' && this.source?.id == component.id;
    }

    public canPaste(parent: AggregateBlueprint): boolean
    {
        return true;
    }

    public selectComponent(component: Blueprint): void
    {
        this.target = component;
    }

    public isSelected(component: Blueprint, type: string = null): boolean
    {
        component = component || this.target;
        type = type || component?.type;

        return this.target?.id == component?.id && this.target?.type == type;
    }

    public unselect()
    {
        this.target = null;
    }
}
