import { AggregateBlueprint, instanceOfAggregateBlueprint } from "../../base/blueprints/AggregateBlueprint";

export interface RowBlueprint extends AggregateBlueprint
{
    kind: 'row';
}

export const instanceOfRowBlueprint = (object: any): object is RowBlueprint =>
{
    return instanceOfAggregateBlueprint(object) && 'kind' in object && object.kind === 'row';
};
