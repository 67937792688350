import { Pagination, Resource, Statement } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { HistoryItemModel } from "@/modules/logito/office/services/OfficeDocuments";
import { ParticipantModel } from "@/modules/logito/office/services/RegisterService";
import { axios } from '@/plugins/axios';
import { merge } from 'lodash';

export default class EntityService
{
    public static async getEntityHistory(publicId: string, type: string, pager: Pager): Promise<Pagination<Resource<HistoryItemModel>>>
    {
        return (await axios.get(`simple-query/${type}/${publicId}/history`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async getEntityParticipants(publicId: string, licence: string, pager: Pager): Promise<Pagination<Resource<ParticipantModel>>>
    {
        return (await axios.get(`simple-query/${licence}/${publicId}/participants`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async addParticipant(publicId: string, licence: string, form: AddParticipantModel): Promise<Statement>
    {
        return (await axios.post(`simple-command/${licence}/${publicId}/participants`, {
            userId: form.userId,
            departmentId: form.departmentId,
            teamId: form.teams
        })).data;
    }

    public static async deleteParticipant(publicId: string): Promise<Statement>
    {
        return (await axios.delete(`simple-command/participants/${publicId}`)).data;
    }
}

export interface AddParticipantModel
{
    userId: number[];
    departmentId: number[];
    teams: number[];
}
