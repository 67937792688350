<template>
    <ideo-modal ref="link-documents-modal" :title="$t('[[[Powiąż dokument]]]')" centered>
        <template #default>
            <ideo-form-group :label="$t('[[[Dokument]]]')" :invalid-feedback="form.$errors.first('document')" :state="form.$errors.state('document')">
                <IdeoSelect
                    v-model="form.document"
                    :placeholder="$t('[[[Dokument]]]')"
                    :endpoint="`${licence}/${documentId}/related-documents/documents/${blueprintId}`"
                    :multiselect="false"
                    @update:modelValue="form.$errors.clear('document')"
                    class="flex-fill"
                >
                    <template #option="{ option }">
                        <div class="d-flex flex-column">
                            <strong v-if="option.value">{{ option.value }}</strong>
                            <small v-if="isContractors(option.contractor)">
                                {{ $t('[[[Kontrahent]]]') }}: {{ option.contractor.map((item: Contractor) => item.name).join(", ") }}
                            </small>
                            <small v-if="option.module">{{ $t('[[[Typ dokumentu]]]') }}: {{ option.module }}</small>
                        </div>
                    </template>
                </IdeoSelect>
            </ideo-form-group>
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button variant="success" @click="submit" :disabled="!form.active()">
                {{ $t('[[[Powiąż]]]') }}
            </ideo-button>
            <ideo-button variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from "@/helpers/Decorators";
import { Form, FormType } from "@/helpers/Form";
import IdeoModal from "@/components/ideo/modal/IdeoModal.vue";
import DocumentRelationshipsService, { CreateModel } from "@/modules/low-code/services/DocumentRelationshipsService";
import { Contractor } from '@/modules/studio/forms/services/FormsService';

@Options({
    components: {},
    emits: ['reload']
})
export default class LinkDocumentsModal extends Vue
{
    @Prop()
    public documentId: string;

    @Prop()
    public licence: string;

    @Prop()
    public blueprintId: string;

    @Ref('link-documents-modal')
    public modal: () => IdeoModal;

    public form: FormType<CreateModel> = Form.create<CreateModel>({
        document: null,
        blueprintId: ''
    });

    public mounted(): void
    {
        this.$events.$on('link-documents', () =>
        {
            this.form.clear();
            this.form.blueprintId = this.blueprintId;
            this.modal().show();
        });
    }

    public async submit(): Promise<void>
    {
        try
        {
            this.form.wait();

            await DocumentRelationshipsService.createRelation(this.documentId, this.licence, this.form.formatData());
            this.$alert.success(this.$t('[[[Dokument został powiązany.]]]'));

            this.$events.$emit('refetchData');
            this.modal().hide();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors)
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public unmounted(): void
    {
        this.$events.$off('link-documents');
    }

    public isContractors(contractor: Array<Contractor>): boolean
    {
        return contractor?.length > 0;
    }
}
</script>
