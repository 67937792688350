<template>
    <div class="form-check ms-2" :class="customClass">
        <input :id="uid" type="checkbox" class="form-check-input" ref="input" :disabled="disabled" :checked="isChecked" @change="updateModel" @click="handleClick">
        <label :for="uid" class="form-check-label" :class="{'me-3': 'default' in $slots}">
            <slot name="default"></slot>
        </label>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref, Watch } from '@/helpers/Decorators';

@Options({
    name: 'ideo-form-checkbox'
})
export default class IdeoFormCheckbox extends Vue
{
    public currentValue: any[] | any = null;

    @Ref()
    public input: () => HTMLInputElement;

    @Prop()
    public checked: any[] | any;

    @Prop({ default: false })
    public indeterminate: boolean;

    @Prop()
    public modelValue: any[] | any;

    @Prop({ default: true })
    public value: any;

    @Prop({ default: false })
    public uncheckedValue: any;

    @Prop({ default: false })
    public switch: boolean;

    @Prop({ default: false })
    public disabled: boolean;

    @Prop({ default: false })
    public inline: boolean;

    public get uid(): string
    {
        return `__IDEO__${this.$.uid}__`;
    }

    public get isChecked(): boolean
    {
        if (Array.isArray(this.currentValue))
            return this.currentValue.includes(this.value);
        else
            return this.currentValue === this.value;
    }

    public get customClass(): Record<string, boolean>
    {
        return {
            'custom-checkbox': this.switch === false,
            'form-switch': this.switch !== false,
            'form-check-inline': this.inline === true
        };
    }

    public mounted(): void
    {
        this.setIndeterminate(this.indeterminate);
    }

    @Emit('change')
    @Emit('update:modelValue')
    public updateModel(e: Event): any[] | any
    {
        const target = e.target as HTMLInputElement;
        let current = this.currentValue;

        if (Array.isArray(current))
        {
            current = current.filter((p: any) => p != this.value);

            if (target.checked)
                current.push(this.value);
        }
        else
        {
            current = target.checked ? this.value : this.uncheckedValue;
        }

        this.setIndeterminate(false);

        return current;
    }

    @Emit('click')
    public handleClick(e: any): any
    {
        return e;
    }

    public setIndeterminate(state: boolean): void
    {
        if (Array.isArray(this.currentValue))
        {
            state = false;
        }

        if (this.input)
        {
            this.input().indeterminate = state;
            this.triggerIndeterminate(state);
        }
    }

    @Emit('update:indeterminate')
    public triggerIndeterminate(state: boolean): boolean
    {
        return state;
    }

    @Watch('indeterminate')
    public onIndeterminateChanged(value: boolean, old: boolean): void
    {
        if (value !== undefined && value != old)
        {
            this.setIndeterminate(value);
        }
    }

    @Watch('checked', { immediate: true })
    public onCheckedChanged(value: any[] | any): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }

    @Watch('modelValue', { immediate: true })
    public onModelChanged(value: any[] | any): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }
}
</script>
