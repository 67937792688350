import { axios } from '@/plugins/axios';
import { Resource, KeyValuePair, Pagination, Statement } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { AuditModel } from '@/modules/core/control-codes/services/ControlCodesService';
import { IMetaModel } from '@/modules/core/common/services/GridService';

export default class BusinessPartnerService
{
    public static async findGus(nip: string): Promise<Resource<GusModel>>
    {
        return (await axios.get<Resource<GusModel>>('crm/partners/find-gus', {
            params: {
                nip: nip
            }
        })).data;
    }

    public static async canGusFind(): Promise<boolean>
    {
        return (await axios.get('crm/partners/can-gus-find')).data;
    }

    public static async addPartner(form: FormModel): Promise<Resource<DetailModel>>
    {
        return (await axios.post('simple-command/crm', form)).data;
    }

    public static async editPartner(id: string, form: FormModel): Promise<Resource<DetailModel>>
    {
        return (await axios.put(`simple-command/crm/${id}`, form)).data;
    }

    public static async deletePartner(id: string): Promise<boolean>
    {
        return (await axios.delete(`simple-command/crm/${id}`)).data;
    }

    public static async anonymizePartner(id: string): Promise<Statement>
    {
        return (await axios.get(`crm/partners/${id}/anonymize`)).data;
    }

    public static async getPartnerDetails(id: string): Promise<Resource<DetailModel>>
    {
        return (await axios.get(`simple-fetch/crm/${id}`)).data;
    }

    public static async getPartnerActions(id: string): Promise<Record<string, IMetaModel>>
    {
        return (await axios.get(`simple-query/crm/${id}/actions`)).data;
    }

    public static async getPartnersTypes(): Promise<KeyValuePair[]>
    {
        return (await axios.get(`simple-query/crm/types`)).data;
    }

    public static async getAddressList(id: string, pager: Pager): Promise<Pagination<Resource<AddressModel>>>
    {
        return (await axios.get(`simple-query/crm/${id}/address`, {
            params: pager.data()
        })).data;
    }

    public static async searchDetails(nip: string, pager: Pager): Promise<Pagination<Resource<DetailModel>>>
    {
        return (await axios.get(`simple-query/crm/details`, {
            params: { nip: nip, ...pager.data() }
        })).data;
    }
}

export interface FormModel {
    nip: string;
    givenName: string;
    surname: string;
    organizationName: string;
    shortName: string;
    commonType: number;
    roles: number[];
    site?: string;
    email?: string;
    phone?: string;
    bankAccount: string;
    manager: number;
    regon:string;
    country: KeyValuePair | string;
    city: string;
    postalCode: string;
    street: string;
    houseID: string;
    additionalHouseID: string;
    isDefault: boolean;
    externalNumber: string;
    eDoreczeniaAde: string;
    hasVisaCard: boolean;
    currency: KeyValuePair | string;
    externalName: string;
    role: number;
}

export interface ListItemModel {
    publicId: string;
    displayText: string;
    nip: string;
    email: string;
    commonType: KeyValuePair;
    created: any;
    manager: any;
    defaultAddress: DefaultAddressListItemModel;
}

export interface DefaultAddressListItemModel
{
    id: number;
    city: string;
    postalCode: string;
    street: string;
    houseID: string;
    additionalHouseID: string;
}

export interface GusModel {
    dateCreatedUtc: string;
    dateModifiedUtc: string;
    externalId: string;
    nip: string;
    givenName: string;
    surname: string;
    organizationName: string;
    name: string;
    email: string;
    phone: string;
    site: string;
    commonType: number;
    source: KeyValuePair;
    defaultAddress: AddressModel;
    roles: KeyValuePair[];
}

export interface DetailModel {
    publicId: string;
    dateCreated: string;
    dateModified: string;
    externalId: string;
    isPh: boolean;
    isSupplier: boolean;
    isContractor: boolean;
    isEmployee: boolean;
    source: KeyValuePair;
    defaultAddress?: AddressModel;
    audit: AuditModel,
    bankAccount: "",
    commonType: KeyValuePair,
    email: string,
    givenName: string,
    manager: KeyValuePair,
    name: string,
    nip: string,
    organizationName: string,
    phone: string,
    regon: string,
    shortName: string,
    site: string,
    surname: string,
    externalNumber: string,
    eDoreczeniaAde: string,
    hasVisaCard: boolean,
    currency: KeyValuePair | string;
    externalName: string;
    role: number | KeyValuePair
}

export interface AddressModel {
    id: number;
    validFromUtc: string;
    validToUtc: string;
    country: KeyValuePair | string;
    city: string;
    postalCode: string;
    street: string;
    houseID: string;
    additionalHouseID: string;
    isDefault: boolean;
}
