<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';
import { RelatedDocument } from '@/modules/studio/forms/services/FormsService';

@Options({
    name: 'DynamicDocumentArrayFormatter',
})
export default class DynamicDocumentArrayFormatter extends Vue
{
    @Prop({ default: (): RelatedDocument[] => ([]) }) public original: RelatedDocument[];
    @Prop({ default: (): RelatedDocument[] => ([]) }) public current: RelatedDocument[];
    @Prop() public definition: ChangelogDefinitionModel;
}
</script>

<template>
    <div>
        <div v-for="(doc, index) in original" :key="index">
            <div class="original-value small" v-if="doc && (doc.value || doc.title)">
                <span v-if="doc.value || doc.title">{{ doc.value || doc.title }}, </span>
                <span>{{ doc.module }}</span>
            </div>
        </div>
        <div v-for="(doc, index) in current" :key="index">
            <div class="current-value small" v-if="doc && (doc.value || doc.title)">
                <span v-if="doc.value || doc.title">{{ doc.value || doc.title }}, </span>
                <span>{{ doc.module }}</span>
            </div>
        </div>
    </div>
</template>

