<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { computedAsync } from '@vueuse/core';
import { useLocalization } from '@/plugins/localization';
import { Option } from '@/helpers/Interfaces';
import IdeoComboBoxContainer from './IdeoComboBoxContainer.vue';

type BaseType = string|number;

const model = defineModel<BaseType>();
const props = defineProps({
  "name": null,
  "placeholder": { default: '[[[Wyszukaj...]]]' },
  "options": { default: (): [] => ([]) },
  "valueField": { default: 'value' },
  "textField": { default: 'text' },
  "size": { default: 10 },
  "default": { type: Boolean, default: true },
  "defaultLabel": { default: '[[[Brak wyboru]]]' },
  "fetch": { type: Function, default: undefined },
  "search": { type: Function, default: undefined }
});
const emit = defineEmits(["change", "display"]);

const { $t } = useLocalization();

const query = ref('');
const items = computedAsync(async () =>
{
    if (props.search !== undefined)
    {
        return await props.search(query.value, props.size);
    }

    return props.options.map(p => ({
        value: p[props.valueField],
        text: p[props.textField]
    }));
});
const options = computed(() =>
{
    return props.default
        ? [{value: null, text: $t(props.defaultLabel)}, ...(items.value || [])]
        : (items.value || []);
});
const optionsFiltered = computed(() =>
{
    return options.value
        .filter(p =>
            p.text.toLowerCase().includes(query.value.toLowerCase())
        );
});
const selected = computedAsync(async () =>
{
    let result: Option<BaseType> = null;

    if (props.fetch !== undefined)
        result = model.value ? await props.fetch(model.value) : null;
    else
        result = items.value?.find(p => p.value == model.value);

    emit('change', result);

    return result;
});

watch(selected, (value) =>
{
    emit('display', value?.text?.replaceAll('\xa0', ' ') ?? (props.default ? $t(props.defaultLabel) : null));
});
</script>

<template>
    <IdeoComboBoxContainer
        v-model:query="query"
        :count="optionsFiltered?.length ?? 0"
        :total="options?.length ?? 0"
        :placeholder="$t(props.placeholder)"
    >
        <template #header>
            <slot name="header"></slot>
        </template>
        <template #default>
            <ideo-form-radio-group
                v-model="model"
                :name="props.name"
                :options="optionsFiltered"
                stacked
                v-if="optionsFiltered?.length > 0"
            />
            <div class="d-flex justify-content-center py-3" v-if="optionsFiltered?.length == 0">
                {{ $t('[[[Brak wyników]]]') }}
            </div>
            <div class="d-flex justify-content-center py-3" v-if="options?.length == 0 && !query">
                {{ $t('[[[Brak opcji]]]') }}
            </div>
        </template>
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </IdeoComboBoxContainer>
</template>
