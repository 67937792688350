import versions from "@/modules/low-code/common/store/versions";
import dynamicFormsList from "./dynamicFormsList";

export default {
    lowCode: {
        namespaced: true,
        modules: {
            versions,
        },
    },
    process: {
        namespaced: true,
        modules: {
            dynamicFormsList: dynamicFormsList
        }
    }
};
