<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import lodashLast  from 'lodash/last';
import { TabType } from '.';
import { RowContract } from '../row';
import { DetailsContract } from '../details';
import { DetailsBuilderContract } from '@/components/builder/details';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import ComponentHelper from '@/helpers/ComponentHelper';
import properties from '../../properties';

defineOptions({
    name: 'TabBlueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const showComponent = ref(true);

const rows = computed(() => props.blueprint.components as RowContract[]);
const last = computed(() => lodashLast(rows.value));
const design = computed(() => props.details.design);
const lastComponentsLength = computed(() => last.value.components.length);

showComponent.value = !ComponentHelper.isManualLoad(props.blueprint);

if (props.blueprint.components.length == 0)
{
    props.details.layout.addRow(props.blueprint);
}

const addRow = (before: RowContract): void =>
{
    const row = props.details.layout.addRow(props.blueprint, before);

    props.details.clipboard.selectPlaceholder(row, null);
};

const addComponent = (definition: BlueprintDefinition, before: RowContract): void =>
{
    const row = props.details.layout.addRow(props.blueprint, before);

    props.details.layout.addComponent(row, definition);
};

const dropComponent = (component: Blueprint, before: RowContract): void =>
{
    const row = props.details.layout.addRow(props.blueprint, before);

    props.details.clipboard.cut(component);
    props.details.clipboard.paste(row);
};

watch(lastComponentsLength, (length): void =>
{
    if (length && length > 0)
    {
        props.details.layout.addRow(props.blueprint);
    }
});

watch(() => props.blueprint.isActive, (value): void =>
{
    if (!props.blueprint.isCounter)
        showComponent.value = value;
});
</script>

<template>
    <dynamic-details-component :details="details" :blueprint="blueprint" :disabled="false">
        <template #default>
            <template v-for="(component, index) in blueprint.components" :key="component.id">
                <dynamic-details-design-ruller
                    v-if="blueprint.components.length > 0 && design"
                    :title="$t('[[[Wstaw kontrolkę]]]')"
                    :details="details"
                    :before="component"
                    @insert="addRow"
                    @add="addComponent"
                    @drop="dropComponent"
                />
                <component
                    v-if="showComponent"
                    :is="details.schema.designer(`dynamic-details-${component.type}`)"
                    :details="details"
                    :parent="blueprint"
                    :blueprint="blueprint.components[index]"
                />
            </template>
        </template>
    </dynamic-details-component>
</template>
