<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { usePermissions } from '@/plugins/permissions';
import { KeyValuePair } from '@/helpers/Interfaces';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { ContractorEntry, ContractorType, instanceOfContractorEntry } from '.';
import properties from '../../properties';
import { ContractorRole, ContractorRoleOptions } from '@/modules/logito/crm/inc/Enums';
import { computedAsync } from '@vueuse/core';
import Permissions from '@/settings/permissions';
import FormContractorModal from './modals/FormContractorModal.vue';

defineOptions({
    name: 'contractor-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $permissions } = usePermissions();
const { $t } = useLocalization();

const entryData = ref(new ContractorEntry()) as Ref<ContractorEntry>;
const formContractorModalRef = ref<InstanceType<typeof FormContractorModal> | null>(null);
const reloadKey = ref(0);
const partnerAddedPublicId = ref('');

entryData.value = props.form.document.initEntry(
    props.blueprint,
    entryData.value,
    instanceOfContractorEntry,
    props.index
);

const blueprint = computed(() => props.blueprint);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        if (!blueprint.value.multiselect) return value?.[0] ?? null;

        return value ?? null;
    },
    set(value: KeyValuePair[] | KeyValuePair)
    {
        entryData.value.data = Array.isArray(value) ? value : value ? [value] : [];
    },
});

const required = computed(() => props.form.expressions.required(props.blueprint));
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));
const design = computed(() => props.form.designMode());
const endpoint = computed(() =>
{
    if (design.value) return null;

    if (props.entry.fieldIndex)
    {
        return `modules/${props.entry.moduleId}/table-templates/select-options/${props.entry.fieldIndex}/${blueprint.value.name}`;
    }

    return `simple-dictionary/${props.entry.module}/form/${props.entry.formId}/${props.entry.actionName}/${blueprint.value.name}`;
});
const roleOptions = computed(() => ContractorRoleOptions().map(p =>
{
    return {
        value: ContractorRole[p.value],
        text: p.text
    };
}));

const isButton = computedAsync(async () => await $permissions.all([Permissions.Logito.Crm.CanEdit]));
const editMode = computed(() => isButton.value && !blueprint.value.multiselect && value.value);
const iconButton = computed(() => !editMode.value ? 'fa fa-plus' : 'fa fa-edit');
const titleButton = computed(() => !editMode.value ? $t('[[[Dodaj kontrahenta]]]') : $t('[[[Edytuj kontrahenta]]]'));
const publicId = computed(() => !Array.isArray(value.value) ? value.value?.key : '' );

const openModal = () =>
{
    formContractorModalRef.value.showModal();
};

const partnerAdded = (publicId: string) =>
{
    partnerAddedPublicId.value = publicId;
    reloadKey.value++;
};
</script>

<template>
    <form-component-wrapper class="register-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <div class="d-flex">
                    <ideo-select
                        v-model="value"
                        :placeholder="placeholder"
                        :multiselect="blueprint.multiselect"
                        :endpoint="endpoint"
                        :deselect="true"
                        :clear-button="!isButton"
                        class="flex-fill"
                        :reload-key="reloadKey"
                        :reload-value="partnerAddedPublicId"
                        :custom-classes="{ 'border-radius-right-none': isButton }"
                    >
                        <template #option="{ option }">
                            <div class="d-flex flex-column">
                                <strong>{{ option.value }}</strong>
                                <small v-if="option.nip">{{ $t('[[[NIP]]]') }}: {{ option.nip }}</small>
                                <small>{{ $t('[[[Adres]]]') }}: {{ option.adress }}</small>
                                <small v-if="option.companies">{{ $t('[[[Spółki]]]') }}: {{ option.companies }}</small>
                                <small v-if="option.source"> {{ $t('[[[Źródło]]]') }}: {{ option.source.value }}</small>
                            </div>
                        </template>
                    </ideo-select>
                    <ideo-button
                        v-if="isButton"
                        variant="success"
                        type="button"
                        class="border-radius-left-none"
                        :title="titleButton"
                        @click="openModal"
                    >
                        <i :class="iconButton"></i>
                    </ideo-button>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
            <form-contractor-modal ref="formContractorModalRef" :public-id="publicId" @partner-added="partnerAdded" />
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-checkbox
                v-model="blueprint.multiselect"
                :label="$t('[[[Wielokrotny wybór]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'multiselect')"
            />
            <field-checkbox v-model="blueprint.filterByRole" :label="$t('[[[Filtruj po roli]]]')" />
            <div class="mb-2 ps-2" v-if="blueprint.filterByRole">
                <ideo-form-radio-group v-model="blueprint.roleFilter" :options="roleOptions" />
            </div>
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>
