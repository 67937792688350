<template>
    <optgroup :label="label">
        <slot name="default">
            <ideo-form-select-option v-for="(option, i) in options" :value="option[valueField]" :key="i">
                {{ option[textField] }}
            </ideo-form-select-option>
        </slot>
    </optgroup>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import IdeoFormSelectOption from './IdeoFormSelectOption.vue';

@Options({
    name: 'ideo-form-select-option-group',
    components: {
        'ideo-form-select-option': IdeoFormSelectOption
    }
})
export default class IdeoFormSelectOptionGroup extends Vue
{
    @Prop()
    public label: string;

    @Prop({ default: 'value' })
    public valueField: string;

    @Prop({ default: 'text' })
    public textField: string;

    @Prop({ default: (): any => [] })
    public options: Record<string, any>[];
}
</script>
