import userAmountLimits from '@/modules/core/invoices/store';
import bulkNotifications from "@/modules/core/bulk-notifications/store";

// common
import grid from '@/modules/common/store/gridEntities';
import paths from '@/modules/core/common/store/paths';
import lineDefinitions from '@/modules/core/line-definitions/store';

export default {
    core: {
        namespaced: true,
        modules: {
            lineDefinitions: lineDefinitions,
            userAmountLimits: userAmountLimits,
            bulkNotifications: bulkNotifications
        }
    },
    common: {
        namespaced: true,
        modules: {
            grid: grid,
            paths: paths
        }
    }
};
