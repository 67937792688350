<template>
    <div class="d-flex flex-column w-100 h-100">
        <RouterView v-slot="{ Component }">
            <Transition name="fade" mode="out-in">
                <component :is="Component"></component>
            </Transition>
        </RouterView>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class EmptyLayout extends Vue
{
    public unmounted(): void
    {
        this.$store.commit("lowCode/versions/SET_VERSION", null);
    }
}
</script>
