import { Plugin, getCurrentInstance, reactive } from 'vue';
import trim from 'lodash/trim';
import { RouteLocationNormalizedLoaded as Route } from 'vue-router';
import { Permissions } from '@/plugins/permissions';
import ModulesService from '@/modules/low-code/services/ModulesService';

export interface Sitemap
{
    extraCrumb: string;
    append(name: string): void;
    purge(fetched?: boolean): void;
    all(): Promise<SitemapNode[]>;
    find(route: Partial<Route>): Promise<SitemapNode>;
    crumbs(route: Route): Promise<SitemapNode[]>;
    path(node: SitemapNode): SitemapNode[];
    active(node: SitemapNode, route: Route, recursive: boolean): boolean;
    url(node: SitemapNode, route: Route): any;
    reload(): Promise<void>;
    onReload(callback: () => Promise<void>): void;
}

export interface SitemapNode
{
    name: string;
    short?: string;
    route?: string;
    preventCloseMenu?: boolean;
    routeParams?: any;
    query?: object;
    url?: string;
    icon?: string;
    chevron?: string;
    namespace?: string;
    allowed?: boolean;
    event?: string,
    visible?: boolean;
    auth?: {
        all?: string[];
        any?: string[];
    };
    parent?: SitemapNode;
    children?: SitemapNode[];
    readOnly: boolean;
}

export class SitemapOptions
{
    public sitemap: SitemapNode[];
}

export class SitemapBuilder
{
    private permissions: Permissions = null;
    private sitemap: SitemapNode[] = null;
    private staticSitemap: SitemapNode[] = null;
    private fetched: boolean = false;

    public constructor(permissions: Permissions, options: SitemapOptions)
    {
        this.permissions = permissions;
        this.sitemap = options.sitemap;
        this.staticSitemap = options.sitemap;
    }

    public purge(fetched: boolean = true): void
    {
        this.permissions.purge();
        this.fetched = fetched;
    }

    protected inspect(items: SitemapNode[], namespace: string = ''): Record<string, boolean>
    {
        let permissions: Record<string, boolean> = {};

        for (let i = 0; i < items.length; i++)
        {
            const item = items[i];
            const ns = item.namespace || namespace;

            if (item.auth && 'all' in item.auth)
            {
                (item.auth.all as string[]).forEach(p => { permissions[trim(`${ns}.${p}`, '.')] = false; });
            }

            if (item.auth && item.auth.any)
            {
                (item.auth.any as string[]).forEach(p => { permissions[trim(`${ns}.${p}`, '.')] = false; });
            }

            if (item.children)
            {
                permissions = Object.assign(permissions, this.inspect(item.children, ns));
            }
        }

        return permissions;
    }

    protected async verify(permissions: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        return await this.permissions.get(Object.keys(permissions));
    }

    protected clone(items: SitemapNode[]): SitemapNode[]
    {
        return JSON.parse(JSON.stringify(items));
    }

    protected apply(items: SitemapNode[], permissions: Record<string, boolean>, namespace: string = ''): SitemapNode[]
    {
        for (let i = 0; i < items.length; i++)
        {
            const item = items[i];
            const ns = item.namespace || namespace;

            item.parent = item.parent || null;
            item.allowed = item.parent ? item.parent.allowed : true;
            item.visible = item.visible == undefined ? true : item.visible;
            item.children = item.children == undefined ? [] : item.children;

            if (item.allowed && item.auth && item.auth.all && item.auth.all.length > 0)
            {
                item.allowed = this.all((item.auth.all as string[]).map(p => trim(`${ns}.${p}`, '.')), permissions);
            }

            if (item.allowed && item.auth && item.auth.any && item.auth.any.length > 0)
            {
                item.allowed = this.any((item.auth.any as string[]).map(p => trim(`${ns}.${p}`, '.')), permissions);
            }

            if (item.children.length > 0)
            {
                item.children.forEach((p: any) => { p.parent = item; });
                this.apply(item.children, permissions, ns);
            }

            item.visible = item.allowed && this.visible(item);
        }

        return items;
    }

    protected all(required: string[], permissions: Record<string, boolean>): boolean
    {
        return required.every(p => permissions[p] == true);
    }

    protected any(required: string[], permissions: Record<string, boolean>): boolean
    {
        return required.some(p => permissions[p] == true);
    }

    protected visible(node: SitemapNode): boolean
    {
        return (node.route || node.url) ? node.visible : node.visible && this.visibleChildren(node);
    }

    protected visibleChildren(node: SitemapNode): boolean
    {
        return node.children && node.children.some((p: any) => this.visible(p));
    }

    public async build(): Promise<SitemapNode[]>
    {
        if (!this.fetched)
        {
            let items: SitemapNode[] = [];

            try
            {
                const response = await ModulesService.getModulesSitemap();

                items = response;
            }
            catch
            {
                items = [];
            }

            if (Array.isArray(items)) this.sitemap = [...this.staticSitemap, ...items];

            this.fetched = true;
        }

        let permissions = this.inspect(this.sitemap);

        permissions = await this.verify(permissions);

        let items = this.clone(this.sitemap);

        items = this.apply(items, permissions);

        return items;
    }
}

class SitemapHelper implements Sitemap
{
    private reactiveData = reactive({ extraCrumb: '' });
    private builder: SitemapBuilder;
    private sitemap: Promise<SitemapNode[]>;
    private callback: () => Promise<void>;

    public constructor(builder: SitemapBuilder)
    {
        this.builder = builder;
    }

    public get extraCrumb(): string
    {
        return this.reactiveData.extraCrumb;
    }

    public set extraCrumb(value: string)
    {
        this.reactiveData.extraCrumb = value;
    }

    public append(name: string): void
    {
        this.extraCrumb = name;
    }

    public purge(fetched: boolean): void
    {
        this.builder.purge(fetched);
        this.sitemap = null;
    }

    public async all(): Promise<SitemapNode[]>
    {
        if (!this.sitemap)
        {
            this.sitemap = this.builder.build();
        }

        return await this.sitemap;
    }

    public async find(route: Partial<Route>): Promise<SitemapNode>
    {
        const find = (nodes: SitemapNode[], route: Partial<Route>): SitemapNode =>
        {
            let result: SitemapNode = null;

            for (let i = 0; i < nodes.length; i++)
            {
                if (this.active(nodes[i], route))
                {
                    result = nodes[i];
                }
                else if (nodes[i].children)
                {
                    result = result || find(nodes[i].children, route);
                }

                if (result != null)
                {
                    break;
                }
            }

            return result;
        };

        return find(await this.all(), route);
    }

    public async crumbs(route: Route): Promise<SitemapNode[]>
    {
        const node = await this.find(route);

        return this.path(node);
    }

    public path(node: SitemapNode): SitemapNode[]
    {
        const nodes: SitemapNode[] = [];

        while (node)
        {
            nodes.push(node);
            node = node.parent;
        }

        return nodes.reverse();
    }

    public active(node: SitemapNode, route: Partial<Route>, recursive: boolean = false): boolean
    {
        if (route.fullPath && node.url && route.fullPath === node.url)
        {
            return true;
        }
        else if (route.name && node.route && node.route === route.name)
        {
            if (node.routeParams && route.params)
            {
                return Object.keys(node.routeParams).every(key =>
                {
                    return key in route.params && JSON.stringify(node.routeParams[key]) == JSON.stringify(route.params[key]);
                });
            }

            return true;
        }
        else if (recursive == true && node.children.length > 0)
        {
            for (let i = 0; i < node.children.length; i++)
            {
                if (this.active(node.children[i], route, recursive))
                {
                    return true;
                }
            }
        }

        return false;
    }

    public url(node: SitemapNode, route: Route): any
    {
        if (node.visible && node.allowed)
        {
            if (node.route)
            {
                return { name: node.route, params: node.routeParams || {}, query: node.query || {} };
            }
            else if (node.url)
            {
                return node.url;
            }
        }

        return route.fullPath;
    }

    public async reload(): Promise<void>
    {
        if (this.callback)
        {
            await this.callback();
        }
    }

    public onReload(callback: () => Promise<void>): void
    {
        this.callback = callback;
    }
}

export const useSitemap = () =>
{
    const app = getCurrentInstance();

    return {
        $sitemap: app.appContext.config.globalProperties.$sitemap
    };
};

const SitemapPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;

        if (!vue.$permissions)
        {
            throw new Error("Vue:permissions must be set.");
        }

        if (!options || !options.sitemap)
        {
            throw new Error("SitemapOptions.sitemap must be set.");
        }

        const builder = new SitemapBuilder(vue.$permissions, options);

        vue.$sitemap = new SitemapHelper(builder);
    }
};

export default SitemapPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $sitemap: Sitemap;
    }
}
