<template>
    <div>
        <fileupload
            v-model="attachments"
            :dark="false"
            @update:modelValue="uploadFileAttachment"
            :extensions="extensions"
        ></fileupload>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import OfficeDocumentsService, { MainFileModel } from "@/modules/logito/office/services/OfficeDocuments";
import StorageService from "@/modules/core/files/services/StorageService";

@Options({
    components: {},
    emits: ['update:modelValue', 'uploaded']
})
export default class AddAttachments extends Vue
{
    @Prop({ default: '' }) public publicId: string;

    @Prop({ default: (): string[] => [] })
    public extensions: string[];

    @Prop({ default: "public" }) public scope: string;
    @Prop({ default: false }) public preview: boolean;
    @Prop({ default: null }) public documentsPreview: MainFileModel[];
    @Prop({ default: "" }) public container: string;

    public attachments: MainFileModel[] = null;

    @Watch('documentsPreview')
    public async fileEditMode(): Promise<void>
    {
        await this.getAttachmentsFile();
    }

    public async created(): Promise<void>
    {
        if (!this.preview)
            await this.getAttachmentsFile();
    }

    public async uploadFileAttachment(): Promise<void>
    {
        const uploadedFilesIds = [];

        for (const file of this.attachments)
        {
            if (file.publicId?.length === 0)
            {
                try
                {
                    const { publicId } = await StorageService.upload(file.upload, this.container, true, false, false, this.scope);

                    uploadedFilesIds.push(publicId);
                    file.publicId = publicId;
                }
                catch (ex)
                {
                    const err: any = ex;

                    this.$alert.danger(err.data.message);
                    this.attachments = this.attachments.filter(file => file.name !== err.data.fileName);
                }
            }
            else
            {
                uploadedFilesIds.push(file.publicId);
            }
        }

        this.$emit('update:modelValue', uploadedFilesIds.slice());
    }

    public async getAttachmentsFile(): Promise<void>
    {
        if (!this.publicId) return;

        try
        {
            let response = null;

            if (this.preview && this.documentsPreview)
                response = this.documentsPreview;
            else if (this.preview)
                response = null;
            else
                response = await OfficeDocumentsService.getAttachmentsFiles(this.publicId);

            this.attachments = response?.map((file) =>
            {
                return {
                    publicId: file.publicId,
                    url: '',
                    name: file.name,
                    thumb: this.$filters.isImage(file.url) ? this.$filters.baseurl(file.url) : ''
                };
            });

            this.$emit('update:modelValue', response?.map((file) => file.publicId));
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
    }
}
</script>
