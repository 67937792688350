import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    // --------------------------------------------------------------------------
    // Pracownicy
    // --------------------------------------------------------------------------
    {
        path: 'staff/people',
        name: 'staff-people',
        meta: { auth: true },
        component: () => import('@/modules/logito/staff/views/PeopleList.vue'),
    },

    {
        path: 'staff/people/:id(\\d+)',
        name: 'staff-people-organization',
        meta: { auth: true },
        component: () => import('@/modules/logito/staff/views/PeopleList.vue'),
    },
];

export default routes;
