import { ColumnDefinitionModel } from "@/modules/core/common/services/GridService";
import { FilterDefinitionModel } from "@/modules/core/common/services/FiltersDefinitionService";

export default {
    namespaced: true,
    state: {
        columns: null,
        filters: null,
        showColumns: true,
        showFilters: true,
        filtersInitialized: false
    } as State,
    mutations: {
        SET_COLUMNS: (state: State, columns: ColumnDefinitionModel): void =>
        {
            state.columns = columns;
        },
        SET_FILTERS: (state: State, filters: FilterDefinitionModel): void =>
        {
            state.filters = filters;
        },
        SET_FILTERS_VISIBILITY: (state: State, value: boolean): void =>
        {
            state.showFilters = value;
        },
        SET_COLUMNS_VISIBILITY: (state: State, value: boolean): void =>
        {
            state.showColumns = value;
        },
        SET_INITIALIZATION: (state: State, value: boolean): void =>
        {
            state.filtersInitialized = value;
        },
        CLEAR_ALL: (state: State): void =>
        {
            state.columns = null;
            state.filters = null;
            state.showColumns = true;
            state.showFilters = true;
        }
    }
};

interface State {
    columns: ColumnDefinitionModel,
    filters: FilterDefinitionModel,
    showColumns: boolean,
    showFilters: boolean,
    filtersInitialized: boolean
}
