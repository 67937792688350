<template>
    <div>
        <list-view
            v-if="pager"
            :items="items"
            :pager="pager"
            :row-click="(item: Resource<ListItemModel>) => $router.push({ name:'core-substitutions-edit', params: { id: item.result.id }})"
            @change="loadData()"
        >
            <template #row="{ item }">
                <list-view-header :title="$t('[[[Imię i Nazwisko]]]')">
                    {{ item.result.replacedByUserFirstName }}
                    {{ item.result.replacedByUserLastName }}
                </list-view-header>
                <list-view-item :title="$t('[[[Zastępuje osobę]]]')">
                    {{ item.result.userBeingReplacedFirstName }}
                    {{ item.result.userBeingReplacedLastName }}
                </list-view-item>
                <list-view-item
                    :title="$t('[[[Data rozpoczęcia]]]')"
                    sort="dateBeginUtc"
                    width="150"
                >
                    {{ $filters.datetime(item.result.dateBeginUtc) }}
                </list-view-item>
                <list-view-item
                    :title="$t('[[[Data zakończenia]]]')"
                    sort="dateEndUtc"
                    width="150"
                >
                    {{ $filters.datetime(item.result.dateEndUtc) }}
                </list-view-item>
                <list-view-buttons width="50">
                    <list-view-button
                        :title="$t('[[[Edytuj]]]')"
                        @click="
                            $router.push({
                                name: 'core-substitutions-edit',
                                params: { id: item.result.id },
                            })
                        "
                    >
                        <i class="icon far fa-pencil-alt"></i>
                        {{ $t("[[[Edytuj]]]") }}
                    </list-view-button>
                    <list-view-button :title="$t('[[[Usuń]]]')" :disabled="!canDelete(item)" :message="$t('[[[Potwierdzenie usunięcia]]]')" @confirm="deleteItem">
                        <i class="icon far fa-times"></i> {{ $t('[[[Usuń]]]') }}
                    </list-view-button>
                </list-view-buttons>
            </template>
        </list-view>

        <Portal :to="`base-widget-header-${publicId}`">
            <IdeoButton
                variant="primary"
                class="me-0"
                @click="redirectToAddSubstitution"
            >
                {{ $t(`[[[Dodaj zastępstwo]]]`) }}
            </IdeoButton>
        </Portal>

        <Portal :to="`base-widget-footer-${publicId}`">
            <IdeoButton
                variant="light"
                class="me-0"
                @click="redirectToSubstitutionsList"
            >
                {{ $t(`[[[Więcej]]]`) }}
            </IdeoButton>
        </Portal>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "@/helpers/Decorators";
import { WidgetModel } from "@/modules/core/home/services/HomeService";
import Pager from "@/helpers/Pager";
import { Resource } from "@/helpers/Interfaces";
import { Form } from "@/helpers/Form";
import SubstitutionService, {
    ListItemModel,
} from "@/modules/core/substitutions/services/SubstitutionService";

@Options({
    name: "ActiveSubstitutionsWidget",
})
export default class ActiveSubstitutionsWidget extends Vue
{
    @Prop({ default: () => ({}) })
    public config: WidgetModel;

    @Prop()
    public publicId: string;

    @Prop()
    public width: number;

    @Prop()
    public height: number;

    public items: Resource<ListItemModel>[] = [];
    public filter = Form.create({
        userBeingReplaced: null,
        replacingUser: null,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        onlyActive: true,
    });
    public pager: Pager = null;

    public async mounted(): Promise<void>
    {
        const pageSize = (this.config.sourceConfig as Record<string, any>)?.pageSize ?? 20;

        this.pager = new Pager(1, pageSize, "DateBeginUtc", "DESC");
        await this.loadData();
    }

    public redirectToAddSubstitution(): void
    {
        this.$router.push({ name: "core-substitutions-add" });
    }

    public redirectToSubstitutionsList(): void
    {
        this.$router.push({ name: "core-substitutions" });
    }

    public canDelete(item: Resource<ListItemModel>): boolean
    {
        return item.meta.canDelete && item.result.canBeDeleted;
    }

    public async deleteItem(item: Resource<ListItemModel>): Promise<void>
    {
        try
        {
            await SubstitutionService.remove(item.result.id);
            this.$alert.success(this.$t('[[[Zastępstwo zostało usunięte.]]]'));
            await this.loadData();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message)
            });
        }
    }

    public async loadData(): Promise<boolean>
    {
        this.items = [];

        try
        {
            const response = await SubstitutionService.getList(
                this.filter.data(),
                this.pager
            );

            this.pager.setTotalRows(response.totalRows);
            this.items = response.items;
        }
        catch (ex)
        {
            this.pager.setTotalRows(0);
            this.items = [];
            this.$log.debug(ex);

            return false;
        }

        return true;
    }
}
</script>
