import { Blueprint, instanceOfBlueprint } from '../../base/blueprints/Blueprint';
import { AlwaysChoice } from '../enums/AlwaysChoice';
import { NeverChoice } from '../enums/NeverChoice';
import { InternallyChoice } from '../enums/InternallyChoice';
import { WhenChoice } from '../enums/WhenChoice';

export interface VisibleBlueprint extends Blueprint
{
    visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    visibleWhen: string;
}

export const instanceOfVisibleBlueprint = (object: any): object is VisibleBlueprint =>
{
    return instanceOfBlueprint(object) && 'visible' in object;
};
