<template>
    <layout>
        <template #code>401</template>
        <template #header>Oops! You are unauthorized.</template>
        <template #description>You are not authorized due to invalid authentication.</template>
    </layout>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ErrorLayout from '@/views/layouts/ErrorLayout.vue';

@Options({
    name: 'error-page-401',
    components: {
        'layout': ErrorLayout
    }
})
export default class ErrorPage401 extends Vue
{
}
</script>
