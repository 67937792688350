import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'personal',
    name: '[[[Dane osobowe]]]',
    icon: 'fa-user',
    group: 'special',
    position: 3,
    disabled: true
};

export class PersonalEntryData
{
    public title?: string = null;
    public givenName?: string = null;
    public middleName?: string = null;
    public surname?: string = null;
}

export class PersonalEntry extends ValidEntry<PersonalEntryData>
{
    public type: string = Definition.type;
    public data: PersonalEntryData = {};

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = {
                title: data.title,
                givenName: data.givenName,
                middleName: data.middleName,
                surname: data.surname
            };
        }
    }

    public async collect(blueprint: PersonalContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        const result = await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            data: {
                title: this.data.title,
                givenName: this.data.givenName,
                middleName: this.data.middleName,
                surname: this.data.surname
            },
            ...(result ?? {})
        });
    }

    public validate(blueprint: PersonalContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint))
            {
                if (blueprint.hasTitle && (this.data.title == null || this.data.title.length == 0))
                    this.errors.title = ['[[[Pole "Tytuł" jest wymagane.]]]'];

                if (this.data.givenName == null || this.data.givenName.length == 0)
                    this.errors.givenName = ['[[[Pole "Imię" jest wymagane.]]]'];

                if (blueprint.hasMiddleName && (this.data.middleName == null || this.data.middleName.length == 0))
                    this.errors.middleName = ['[[[Pole "Drugie imię" jest wymagane.]]]'];

                if (this.data.surname == null || this.data.surname.length == 0)
                    this.errors.surname = ['[[[Pole "Nazwisko" jest wymagane.]]]'];
            }

            if (Object.keys(this.errors).length == 0 && form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }
}

export const instanceOfPersonalEntry = (object: any): object is PersonalEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface PersonalContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth, HasFilter
{
    hasTitle: boolean;
    hasMiddleName: boolean;
}

export class PersonalType implements PersonalContract, ValidatableBlueprint, EntryFactory<PersonalEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public hasTitle: boolean;
    public hasMiddleName: boolean;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Dane osobowe' };
        this.showLabel = true;
        this.hasTitle = false;
        this.hasMiddleName = false;
        this.help = {};
        this.minWidth = 3;
        this.width = 0;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): PersonalEntry
    {
        return new PersonalEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
