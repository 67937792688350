<script lang="ts" setup>
import { computed } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, ChoiceType } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});

const display = computed(() =>
{
    return props.component.options
        .filter(p => props.value?.includes(p.value))
        .map(p => props.builder.localization.translate(p.text));
});
</script>

<template>
    <span class="badge bg-info me-1" v-for="(text, i) in display" :key="i">
        {{ text }}
    </span>
</template>
