<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'ListFormatter',
})
export default class ListFormatter extends Vue
{
    @Prop({ default: (): any => [] }) public property: any[];
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;
}
</script>

<template>
    <div v-if="property.length">
        <div v-for="(item, index) in property" :key="index">
            <span :title="item.name">
                {{ item.name }}
            </span>
        </div>
    </div>
</template>
