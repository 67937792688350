import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'composite',
    name: '[[[...]]]',
    icon: 'fa-cart-shopping-fast',
    group: 'composite',
    position: 1
};

export class CompositeEntry extends ValidEntry<any>
{
    public type: string = Definition.type;
    public composite: string = null;
    public data: any = null;

    public constructor(data: any = null, composite: string = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }

        if (composite !== null)
        {
            this.composite = composite;
        }
    }

    public async collect(blueprint: CompositeContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        const result = await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            composite: this.composite,
            data: this.data || form.expressions.executeExpression(blueprint.defaultValue),
            ...(result ?? {})
        });
    }

    public validate(blueprint: CompositeContract, form: FormBuilderContract): boolean
    {
        this.errors = {};
        this.data = this.data || form.expressions.executeExpression(blueprint.defaultValue);

        return this.valid();
    }
}

export const instanceOfCompositeEntry = (object: any): object is CompositeEntry =>
{
    return instanceOfEntry(object) && 'composite' in object && 'type' in object && object.type === Definition.type;
};

export interface CompositeContract extends Blueprint, HasWidth
{
    [key: string]: any;

    composite: string;
    defaultValue: string;
}

export class CompositeType implements CompositeContract, ValidatableBlueprint, EntryFactory<CompositeEntry>
{
    [key: string]: any;

    public composite: string;
    public id: string;
    public type: string;
    public name: string;
    public minWidth: number;
    public width: number;
    public errors: ValidationErrors;
    public defaultValue: any;

    public constructor(id: string, name: string)
    {
        this.composite = null;
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.minWidth = 1;
        this.width = 0;
        this.errors = {};
        this.defaultValue = null;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): CompositeEntry
    {
        return new CompositeEntry(data, this.composite);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
