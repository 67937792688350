// Blueprints
import { Blueprint } from "../../base/blueprints/Blueprint";
import { BlueprintDefinition } from "../../details/blueprints/BlueprintDefinition";
import { AggregateBlueprint } from "../../base/blueprints/AggregateBlueprint";
import { RowBlueprint } from "../blueprints/RowBlueprint";

// Managers
import { LayoutManager } from "../../base/managers/LayoutManager";
import { DimensionsManager } from "../../base/managers/DimensionsManager";
import { EventManager, Events } from "../../base/managers/EventManager";
import { SchemaManager } from "../../base/managers/SchemaManager";

// Services
import { DetailsBlueprintFactory } from "../services/DetailsBlueprintFactory";

// Traits
import { instanceOfHasWidth } from "../../base/traits/HasWidth";
import { instanceOfHasDefinition } from "../../details/traits/HasDefinition";

export class DetailsLayoutManager extends LayoutManager
{
    protected dimensions: DimensionsManager;

    public constructor(blueprintFactory: DetailsBlueprintFactory, eventManager: EventManager, schemaManager: SchemaManager, dimensionsManager: DimensionsManager)
    {
        super(blueprintFactory, eventManager, schemaManager);

        this.dimensions = dimensionsManager;
    }

    protected get details(): DetailsBlueprintFactory
    {
        return this.factory as DetailsBlueprintFactory;
    }

    public createResizableComponent(parent: AggregateBlueprint, definition: BlueprintDefinition): Blueprint
    {
        const component = super.createComponent(definition.type);

        if (instanceOfHasWidth(component))
        {
            component.setDefaultWidth(this.dimensions.space(parent));
        }

        if (instanceOfHasDefinition(component))
        {
            component.setDefinition(definition);
        }

        return component;
    }

    public insertComponent(parent: AggregateBlueprint, component: Blueprint, before: Blueprint = null): void
    {
        super.insertComponent(parent, component, before);

        this.dimensions.autoarrange(parent, false);
    }

    public addComponent(parent: AggregateBlueprint, definition: BlueprintDefinition, before: Blueprint = null): Blueprint
    {
        const component: any = this.createResizableComponent(parent, definition);

        // if (this.dimensions.available(parent) >= component.width)
        // {
        this.insertComponent(parent, component, before);
        this.events.emit(Events.FOCUS, component);

        if (component && 'composite' in component && 'key' in definition)
        {
            component.composite = definition.key;
        }

        return component;
        // }

        // return null;
    }

    public addComponentAfter(parent: AggregateBlueprint, definition: BlueprintDefinition, after: Blueprint = null): void
    {
        if (after == null)
        {
            this.addComponent(parent, definition);
        }
        else
        {
            const next = this.schema.next(after);

            this.addComponent(parent, definition, next);
        }
    }

    public addRow(parent: AggregateBlueprint, before: RowBlueprint = null): RowBlueprint
    {
        const slot = this.details.createRow(this.schema.newId(), 'row');

        slot.name = this.schema.name(slot.type);

        if (before != null)
        {
            parent.components.splice(parent.components.indexOf(before), 0, slot);
            this.events.emit(Events.FOCUS, slot);
        }
        else
        {
            parent.components.push(slot);
        }

        return slot;
    }

    public addRowBefore(before: RowBlueprint): void
    {
        this.addRow(this.schema.parent(before), before);
    }

    public addRowAfter(after: RowBlueprint): void
    {
        const next = this.schema.next(after);

        if (next != null)
        {
            if ((next as any as RowBlueprint).components.length > 0)
                this.addRow(this.schema.parent(after), next as any as RowBlueprint);
            else
                this.events.emit(Events.FOCUS, next);
        }
    }
}
