export default class Permissions
{
    public static Standard = class
    {
        public static AdminAccess = "Ideo.Core.StandardPermissions@AdminAccess";
        public static Superuser = "Ideo.Core.StandardPermissions@Superuser";
        public static Manage = "Ideo.Core.StandardPermissions@Manage";
    };

    public static Core = class
    {
        public static Users = class
        {
            public static View = "Ideo.Core.Common.Application.Permissions.Core.UsersPermissions@View";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Core.UsersPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Core.UsersPermissions@Delete";
        };

        public static Roles = class
        {
            public static View = "Ideo.Core.Common.Application.Permissions.Core.RolesPermissions@View";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Core.RolesPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Core.RolesPermissions@Delete";
        };

        public static Teams = class
        {
            public static View = "Ideo.Core.Common.Application.Permissions.Core.TeamsPermissions@View";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Core.TeamsPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Core.TeamsPermissions@Delete";
        };

        public static Organization = class
        {
            public static View = "Ideo.Core.Common.Application.Permissions.Core.OrganizationPermissions@View";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Core.OrganizationPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Core.OrganizationPermissions@Delete";
        };

        public static Substitutions = class
        {
            public static View = "Ideo.Core.Common.Application.Permissions.Core.SubstitutionsPermissions@View";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Core.SubstitutionsPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Core.SubstitutionsPermissions@Delete";
        };

        public static DataSources = class
        {
            public static View = "Ideo.Core.Common.Application.Permissions.Core.DataSourcesPermissions@View";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Core.DataSourcesPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Core.DataSourcesPermissions@Delete";
        };

        public static DynamicForms = class
        {
            public static Access = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@Access";
            public static Categories = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@Categories";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@Delete";
            public static Accept = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@Accept";
            public static Manage = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@Manage";
            public static EditAll = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@EditAll";
            public static DeleteAll = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@DeleteAll";
            public static AcceptAll = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@AcceptAll";
            public static ManageAll = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormsPermissions@ManageAll";
        };

        public static DynamicFormEntries = class
        {
            public static Access = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormEntriesPermissions@Access";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormEntriesPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormEntriesPermissions@Delete";
            public static Accept = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormEntriesPermissions@Accept";
            public static Manage = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormEntriesPermissions@Manage";
            public static EditAll = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormEntriesPermissions@EditAll";
            public static DeleteAll = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormEntriesPermissions@DeleteAll";
            public static AcceptAll = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormEntriesPermissions@AcceptAll";
            public static ManageAll = "Ideo.Core.Common.Application.Permissions.Core.DynamicFormEntriesPermissions@ManageAll";
        };

        public static Filters = class
        {
            public static Manage = "Ideo.Core.Common.Application.Permissions.Core.FiltersPermissions@Manage";
        };

        public static Admin = class
        {
            public static Security = "Ideo.Core.Common.Application.Permissions.Core.AdminPermissions@Security";
            public static Authlog = "Ideo.Core.Common.Application.Permissions.Core.AdminPermissions@Authlog";
            public static Changelog = "Ideo.Core.Common.Application.Permissions.Core.AdminPermissions@Changelog";
        };
    };

    public static Logito = class
    {
        public static Dictionaries = class
        {
            public static All = "Ideo.Logito.Common.CorePermissions@Dictionaries";
        };

        public static LowCode = class
        {
            public static Modules = "Ideo.Logito.Common.CorePermissions@Modules";
        };

        public static Crm = class
        {
            public static CanEdit = "Ideo.Logito.Crm.CrmPartnerPermissions@CanEdit";
        };
    };

    public static Cms = class
    {
        public static Pages = class
        {
            public static View = "Ideo.Core.Edito.Application.Permissions.Cms.PagesPermissions@View";
            public static Edit = "Ideo.Core.Edito.Application.Permissions.Cms.PagesPermissions@Edit";
            public static Delete = "Ideo.Core.Edito.Application.Permissions.Cms.PagesPermissions@Delete";
        };

        public static Layouts = class
        {
            public static View = "Ideo.Core.Edito.Application.Permissions.Cms.LayoutsPermissions@View";
            public static Compose = "Ideo.Core.Edito.Application.Permissions.Cms.LayoutsPermissions@Compose";
            public static Publish = "Ideo.Core.Edito.Application.Permissions.Cms.LayoutsPermissions@Publish";
            public static Edit = "Ideo.Core.Edito.Application.Permissions.Cms.LayoutsPermissions@Edit";
            public static Delete = "Ideo.Core.Edito.Application.Permissions.Cms.LayoutsPermissions@Delete";
        };

        public static Websites = class
        {
            public static View = "Ideo.Core.Edito.Application.Permissions.Cms.WebsitesPermissions@View";
            public static Edit = "Ideo.Core.Edito.Application.Permissions.Cms.WebsitesPermissions@Edit";
            public static Delete = "Ideo.Core.Edito.Application.Permissions.Cms.WebsitesPermissions@Delete";
        };

        public static Articles = class
        {
            public static View = "Ideo.Core.Edito.Application.Permissions.Modules.ArticlesPermissions@View";
            public static Edit = "Ideo.Core.Edito.Application.Permissions.Modules.ArticlesPermissions@Edit";
            public static Delete = "Ideo.Core.Edito.Application.Permissions.Modules.ArticlesPermissions@Delete";
            public static Accept = "Ideo.Core.Edito.Application.Permissions.Modules.ArticlesPermissions@Accept";
            public static Publish = "Ideo.Core.Edito.Application.Permissions.Modules.ArticlesPermissions@Publish";
            public static EditAll = "Ideo.Core.Edito.Application.Permissions.Modules.ArticlesPermissions@EditAll";
            public static DeleteAll = "Ideo.Core.Edito.Application.Permissions.Modules.ArticlesPermissions@DeleteAll";
            public static AcceptAll = "Ideo.Core.Edito.Application.Permissions.Modules.ArticlesPermissions@AcceptAll";
            public static PublishAll = "Ideo.Core.Edito.Application.Permissions.Modules.ArticlesPermissions@PublishAll";
        };

        public static Informations = class
        {
            public static View = "Ideo.Core.Edito.Application.Permissions.Modules.InformationsPermissions@View";
            public static Edit = "Ideo.Core.Edito.Application.Permissions.Modules.InformationsPermissions@Edit";
            public static Delete = "Ideo.Core.Edito.Application.Permissions.Modules.InformationsPermissions@Delete";
            public static Accept = "Ideo.Core.Edito.Application.Permissions.Modules.InformationsPermissions@Accept";
            public static Publish = "Ideo.Core.Edito.Application.Permissions.Modules.InformationsPermissions@Publish";
            public static EditAll = "Ideo.Core.Edito.Application.Permissions.Modules.InformationsPermissions@EditAll";
            public static DeleteAll = "Ideo.Core.Edito.Application.Permissions.Modules.InformationsPermissions@DeleteAll";
            public static AcceptAll = "Ideo.Core.Edito.Application.Permissions.Modules.InformationsPermissions@AcceptAll";
            public static PublishAll = "Ideo.Core.Edito.Application.Permissions.Modules.InformationsPermissions@PublishAll";
        };

        public static Faq = class
        {
            public static View = "Ideo.Core.Edito.Application.Permissions.Modules.FaqPermissions@View";
            public static Edit = "Ideo.Core.Edito.Application.Permissions.Modules.FaqPermissions@Edit";
            public static Delete = "Ideo.Core.Edito.Application.Permissions.Modules.FaqPermissions@Delete";
            public static Accept = "Ideo.Core.Edito.Application.Permissions.Modules.FaqPermissions@Accept";
            public static Publish = "Ideo.Core.Edito.Application.Permissions.Modules.FaqPermissions@Publish";
            public static EditAll = "Ideo.Core.Edito.Application.Permissions.Modules.FaqPermissions@EditAll";
            public static DeleteAll = "Ideo.Core.Edito.Application.Permissions.Modules.FaqPermissions@DeleteAll";
            public static AcceptAll = "Ideo.Core.Edito.Application.Permissions.Modules.FaqPermissions@AcceptAll";
            public static PublishAll = "Ideo.Core.Edito.Application.Permissions.Modules.FaqPermissions@PublishAll";
        };

        public static Tags = class
        {
            public static View = "Ideo.Core.Edito.Application.Permissions.Modules.TagsPermissions@View";
            public static Edit = "Ideo.Core.Edito.Application.Permissions.Modules.TagsPermissions@Edit";
            public static Delete = "Ideo.Core.Edito.Application.Permissions.Modules.TagsPermissions@Delete";
            public static Publish = "Ideo.Core.Edito.Application.Permissions.Modules.TagsPermissions@Publish";
        };

        public static ContactForms = class
        {
            public static View = "Ideo.Core.Edito.Application.Permissions.Modules.ContactFormsPermissions@View";
            public static Edit = "Ideo.Core.Edito.Application.Permissions.Modules.ContactFormsPermissions@Edit";
            public static Delete = "Ideo.Core.Edito.Application.Permissions.Modules.ContactFormsPermissions@Delete";
        };
    };

    public static Saas = class
    {
        public static Databases = class
        {
            public static View = "Ideo.Core.Common.Application.Permissions.Saas.DatabasesPermissions@View";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Saas.DatabasesPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Saas.DatabasesPermissions@Delete";
        };

        public static Storages = class
        {
            public static View = "Ideo.Core.Common.Application.Permissions.Saas.StoragesPermissions@View";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Saas.StoragesPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Saas.StoragesPermissions@Delete";
        };

        public static Tenants = class
        {
            public static View = "Ideo.Core.Common.Application.Permissions.Saas.TenantsPermissions@View";
            public static Edit = "Ideo.Core.Common.Application.Permissions.Saas.TenantsPermissions@Edit";
            public static Delete = "Ideo.Core.Common.Application.Permissions.Saas.TenantsPermissions@Delete";
        };
    };

    public static Storage = class
    {
        public static Access = "Ideo.Core.Common.Application.Permissions.Storage.StoragePermissions@Access";
        public static EditFolders = "Ideo.Core.Common.Application.Permissions.Storage.StoragePermissions@EditFolders";
        public static DeleteFolders = "Ideo.Core.Common.Application.Permissions.Storage.StoragePermissions@DeleteFolders";
        public static EditFiles = "Ideo.Core.Common.Application.Permissions.Storage.StoragePermissions@EditFiles";
        public static DeleteFiles = "Ideo.Core.Common.Application.Permissions.Storage.StoragePermissions@DeleteFiles";
        public static Manage = "Ideo.Core.Common.Application.Permissions.Storage.StoragePermissions@Manage";
    };

    public static Trash = class
    {
        public static View = "Ideo.Core.Common.Application.Permissions.Core.TrashPermissions@View";
        public static Restore = "Ideo.Core.Common.Application.Permissions.Core.TrashPermissions@Restore";
        public static Delete = "Ideo.Core.Common.Application.Permissions.Core.TrashPermissions@Delete";
    };
}
