<template>
    <div class="d-flex align-items-center small text-formatter">
        <span v-if="original" class="original-value">{{ original.rate }}</span>
        <span class="current-value">{{ current?.rate }} </span>
    </div>
</template>

<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { Options, Vue } from "vue-class-component";
import { ExchangeRate } from "@/modules/low-code/services/ExchangeRatesService";

@Options({
    name: "ExchangeRateFormatterChangelog",
})
export default class ExchangeRateFormatterChangelog extends Vue
{
    @Prop({ default: null })
    public original: ExchangeRate | null;

    @Prop({ default: null })
    public current: ExchangeRate | null;
}
</script>
