<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, ChoicePlusType, FREE_ANSWER_VALUE } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { ApiQueryCondition, KeyElement, TermsQueryCondition } from '@/helpers/ApiQueryBuilder';
import IdeoFilterMultiple from '@/components/filters/types/IdeoFilterMultiple.vue';

defineOptions({
    name: `${Definition.type}-filter`
});

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});

const { $t } = useLocalization();

const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as TermsQueryCondition);
const entry = computed({
    get()
    {
        return condition.value?.terms.values || [];
    },
    set(value)
    {
        if (condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                terms: {
                    field: `${component.value.name}.Values`,
                    values: value || []
                }
            });
        }

        condition.value.terms.values = value || [];

        if (condition.value.terms.values.length == 0)
        {
            reset();
        }
    }
});

const label = computed(() => props.builder.localization.translate(component.value.label));
const freeAnswerLabel = computed(() => props.builder.localization.translate(component.value.freeAnswerLabel) || $t('[[[Odpowiedź dowolna]]]'));

const reset = (): void =>
{
    model.value.remove(p => p.__key == component.value.id);
};

const options = computed(() =>
{
    return [
        ...component.value.options.map(p => ({ ...p, text: props.builder.localization.translate(p.text) })),
        {
            value: FREE_ANSWER_VALUE,
            text: freeAnswerLabel.value
        }
    ];
});

const display = computed(() =>
{
    if (entry.value.length > 0)
        return $t('[[[Wybrane: %0|||{0}]]]', entry.value.length);

    return null;
});
</script>

<template>
    <IdeoFilterMultiple
        v-model="entry"
        :name="component.name"
        :label="label"
        :index="index"
        :value="entry"
        :display="display"
        :visible="component.showFilter"
        :options="options"
        @clear="reset"
    />
</template>
