<script lang="ts" setup>
import { computed } from 'vue';
import FieldChoice from './FieldChoice.vue';
import FieldCondition from './FieldCondition.vue';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';

defineOptions({
    name: 'field-readonly',
    components: {
        'field-choice': FieldChoice,
        'field-condition': FieldCondition
    }
});

const props = defineProps({
  "blueprint": null,
  "form": null,
  "label": { default: null }
});
const blueprint = computed(() => props.blueprint);

const readonlyOptions: Option<AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice>[] = [
    { value: AlwaysChoice.Always, text: '[[[Zawsze]]]' },
    { value: NeverChoice.Never, text: '[[[Nigdy]]]' },
    { value: InternallyChoice.Internally, text: '[[[Wewnętrznie]]]' },
    { value: WhenChoice.When, text: '[[[Warunkowo]]]' }
];
</script>


<template>
    <div>
        <field-choice v-model="blueprint.readonly" :options="readonlyOptions" :label="label || $t('[[[Tylko do odczytu]]]')" />
        <field-condition v-model="blueprint.readonlyWhen" :label="label" :show-label="false" :form="form" :when="blueprint.readonly" />
    </div>
</template>
