<script lang="ts" setup>
import { computed } from 'vue';
import { computedAsync } from '@vueuse/core';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { RelatedDocumentsType } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { handleException } from '@/helpers/Utils';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import properties from '@/components/forms/properties';
import ModulesService from '@/modules/studio/modules/services/ModulesService';
import CollectionModal from '@/modules/studio/documents/views/templates/CollectionModal.vue';

defineOptions({
    name: 'related-documents-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});

const { $alert } = useAlerts();
const { $log } = useLogging();

const blueprint = computed(() => props.blueprint);
const module = computedAsync(async () =>
{
    if (!blueprint.value.moduleId) return null;

    try
    {
        const response = await ModulesService.fetch(blueprint.value.moduleId);

        return response.result;
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });

        return null;
    }
});
const symbol = computed(() => module.value.sections?.first()?.symbol);
const section = computed(() => module.value.sections?.filter(p => p.id == blueprint.value.sectionId)?.first());
const routeKey = computed(() => `embedded-${blueprint.value.sectionId}`);
</script>

<template>
    <form-component-wrapper class="related-documents-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" />
                <form @submit.prevent v-if="module && section && (props.form.design || props.form.entryId > 0)" class="table-container">
                    <CollectionModal :symbol="symbol" :module="module" :section="section" :route-key="routeKey" :embedded="true" :key="blueprint.sectionId" />
                </form>
                <div class="alert alert-light text-center" v-else>{{ $t('[[[Brak danych]]]') }}</div>
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <div class="alert alert-light p-2 pb-0">
                <h5>{{ $t('[[[Żródło danych]]]') }}</h5>
                <field-module v-model="blueprint" :form="form" />
            </div>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>

<style>
.related-documents-component .card-form > .card-footer::after {
    display: none;
}
</style>
