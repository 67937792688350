import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

const requireComponents = import.meta.glob('@/components/dynamic-details/blueprints/**/Blueprint.vue', { eager: true });
const components = Object.values(requireComponents);

const requireTypes = import.meta.glob('@/components/dynamic-details/blueprints/**/index.ts', { eager: true });
const definitions = Object.values(requireTypes).select((p: any) => p.Definition).toArray();
const types = Object.assign({}, ...Object.values(requireTypes).map((module: any) =>
{
    const type = upperFirst(camelCase(`${module.Definition.type}-type`));

    return {[type]: module[type]};
}));

export {
    components,
    definitions,
    types
};
