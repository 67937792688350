import { Blueprint, instanceOfBlueprint } from '../../base/blueprints/Blueprint';
import { AlwaysChoice } from '../enums/AlwaysChoice';
import { NeverChoice } from '../enums/NeverChoice';
import { InternallyChoice } from '../enums/InternallyChoice';
import { WhenChoice } from '../enums/WhenChoice';

export interface ReadonlyBlueprint extends Blueprint
{
    readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    readonlyWhen: string;
}

export const instanceOfReadonlyBlueprint = (object: any): object is ReadonlyBlueprint =>
{
    return instanceOfBlueprint(object) && 'readonly' in object;
};
