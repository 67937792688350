export {};

declare global {
    interface Array<T> {
        clear(): void;
        first(): T;
        last(): T;
        skip(count: number): Array<T>;
        take(count: number): Array<T>;
        where(predicate: (value: T) => boolean): IEnumerable<T>;
        orderBy(selector: (value: T) => string|number|boolean, ascending?: boolean): IOrderedEnumerable<T>;
        select<V>(selector: (value: T) => V): IEnumerable<V>;
        selectMany<V>(selector: (value: T) => V[]): IEnumerable<V>;
        remove(predicate: (value: T) => boolean): void;
        record<K extends string|number, V>(keySelector: (value: T) => K, valueSelector: (value: T) => V): Record<K, V>;
        any(): boolean;
        none(): boolean;
    }
}

Object.defineProperties(Array.prototype, {
    clear: {
        value: function()
        {
            this.length = 0;
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    first: {
        value: function()
        {
            return new Enumerable(this).first();
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    last: {
        value: function()
        {
            return new Enumerable(this).last();
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    skip: {
        value: function(count: number)
        {
            return new Enumerable(this).skip(count);
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    take: {
        value: function(count: number)
        {
            return new Enumerable(this).take(count);
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    where: {
        value: function<T>(predicate: (value: T) => boolean)
        {
            return new Enumerable<T>(this).where(predicate);
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    orderBy: {
        value: function<T>(selector: (value: T) => string|number|boolean, ascending?: boolean)
        {
            return new Enumerable<T>(this).orderBy(selector, ascending);
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    select: {
        value: function<T, V>(selector: (value: T) => V)
        {
            return new Enumerable<T>(this).select(selector);
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    selectMany: {
        value: function<T, V>(selector: (value: T) => V[])
        {
            return new Enumerable<T>(this).selectMany(selector);
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    remove: {
        value: function<T>(predicate: (value: T) => boolean)
        {
            const result = this.filter(predicate);

            for (const item of result)
            {
                this.splice(this.indexOf(item), 1);
            }
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    record: {
        value: function<T, K extends string|number, V>(keySelector: (value: T) => K, valueSelector: (value: T) => V)
        {
            return new Enumerable<T>(this).record(keySelector, valueSelector);
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    any: {
        value: function()
        {
            return this.length > 0;
        },
        enumerable: false,
        writable: true,
        configurable: true
    },
    none: {
        value: function()
        {
            return this.length == 0;
        },
        enumerable: false,
        writable: true,
        configurable: true
    }
});

// Array.prototype.clear = function()
// {
//     this.length = 0;
// };

// Array.prototype.first = function()
// {
//     return new Enumerable(this).first();
// };

// Array.prototype.last = function()
// {
//     return new Enumerable(this).last();
// };

// Array.prototype.skip = function(count: number)
// {
//     return new Enumerable(this).skip(count);
// };

// Array.prototype.take = function(count: number)
// {
//     return new Enumerable(this).take(count);
// };

// Array.prototype.where = function<T>(predicate: (value: T) => boolean)
// {
//     return new Enumerable(this).where(predicate);
// };

// Array.prototype.orderBy = function<T>(selector: (value: T) => string|number|boolean, ascending?: boolean)
// {
//     return new Enumerable(this).orderBy(selector, ascending);
// };

// Array.prototype.select = function<T, V>(selector: (value: T) => V)
// {
//     return new Enumerable(this).select(selector);
// };

// Array.prototype.selectMany = function<T, V>(selector: (value: T) => V[])
// {
//     return new Enumerable(this).selectMany(selector);
// };

// Array.prototype.remove = function<T>(predicate: (value: T) => boolean)
// {
//     const result = this.filter(predicate);

//     for (const item of result)
//     {
//         this.splice(this.indexOf(item), 1);
//     }
// };

// Array.prototype.record = function<T, K extends string|number, V>(keySelector: (value: T) => K, valueSelector: (value: T) => V)
// {
//     return new Enumerable(this).record(keySelector, valueSelector);
// };

// Array.prototype.any = function()
// {
//     return this.length > 0;
// };

// Array.prototype.none = function()
// {
//     return this.length == 0;
// };

interface IEnumerable<T>
{
    toArray(): T[];
    where(predicate: (value: T) => boolean): IEnumerable<T>;
    orderBy(selector: (value: T) => string|number|boolean, ascending?: boolean): IOrderedEnumerable<T>;
    select<V>(selector: (value: T) => V): IEnumerable<V>;
    selectMany<V>(selector: (value: T) => V[]): IEnumerable<V>;
    first(): T;
    last(): T;
    skip(count: number): Array<T>;
    take(count: number): Array<T>;
    record<K extends string|number, V>(keySelector: (value: T) => K, valueSelector: (value: T) => V): Record<K, V>;
    any(): boolean;
    none(): boolean;
}

interface IOrderedEnumerable<T> extends IEnumerable<T>
{
    thenBy(selector: (value: T) => string|number|boolean, ascending?: boolean): IOrderedEnumerable<T>;
}


class Enumerable<T> implements IEnumerable<T>
{
    protected source: T[];

    public constructor(source: T[])
    {
        this.source = source;
    }

    public toArray(): T[]
    {
        return this.source.slice();
    }

    public where(predicate: (value: T) => boolean): IEnumerable<T>
    {
        return new Enumerable(this.toArray().filter(predicate));
    }

    public orderBy(selector: (value: T) => string|number|boolean, ascending: boolean = true): IOrderedEnumerable<T>
    {
        return new OrderedEnumerable(this.toArray(), selector, ascending);
    }

    public select<V>(selector: (value: T) => V): IEnumerable<V>
    {
        return new Enumerable(this.toArray().map(selector));
    }

    public selectMany<V>(selector: (value: T) => V[]): IEnumerable<V>
    {
        return new Enumerable(this.toArray().map(selector).reduce((result, item) => ([...result, ...item]), []));
    }

    public first(): T|null
    {
        return this.toArray().slice(0, 1).shift() || null;
    }

    public last(): T|null
    {
        return this.toArray().slice(-1).shift() || null;
    }

    public skip(count: number): T[]
    {
        return this.toArray().slice(count);
    }

    public take(count: number): T[]
    {
        return this.toArray().slice(0, count);
    }

    public record<K extends string|number, V>(keySelector: (value: T) => K, valueSelector: (value: T) => V): Record<K, V>
    {
        return this
            .toArray()
            .reduce((result, item) =>
            {
                const key = keySelector(item);

                if (key in result)
                    throw new Error(`An item with the same key (${key}) has already been added.`);

                result[key] = valueSelector(item);

                return result;
            },
            {} as Record<K, V>);
    }

    public any(): boolean
    {
        return this.toArray().any();
    }

    public none(): boolean
    {
        return this.toArray().none();
    }
}

interface IOrderingRule<T>
{
    selector: (value: T) => any;
    ascending: boolean;
}

class OrderedEnumerable<T> extends Enumerable<T> implements IOrderedEnumerable<T>
{
    protected rules: IOrderingRule<T>[] = [];

    public constructor(source: T[], selector: (value: T) => any, ascending: boolean)
    {
        super(source);

        this.rules.push({
            selector,
            ascending
        });
    }

    public toArray(): T[]
    {
        return this.source.slice().sort((a, b) => this.rules
            .map(r =>
            {
                let result = 0;
                const va = r.selector(a);
                const vb = r.selector(b);

                if (typeof(va) == "string" && typeof(vb) == "string")
                    result = va.localeCompare(vb);

                if (typeof(va) == "number" && typeof(vb) == "number")
                    result = va - vb;

                if (typeof(va) == "boolean" && typeof(vb) == "boolean")
                    result = Number(va) - Number(vb);

                return result * (r.ascending ? 1 : -1);
            })
            .reduce((result, value) => result != 0 ? result : value, 0)
        );
    }

    public thenBy(selector: (value: T) => string|number|boolean, ascending: boolean = true): IOrderedEnumerable<T>
    {
        this.rules.push({
            selector,
            ascending
        });

        return this;
    }
}
