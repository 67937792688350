<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';
import { calculateSummary } from '@/components/common/dynamic-grid/helpers/CalculateSummary';

@Options({
    name: 'TableFormatter',
})
export default class TableFormatter extends Vue
{
    @Prop() public property: Record<string, any>[];
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;

    public summary: { netAmount: string; grossAmount: string; vatAmount: string } = {
        netAmount: '0',
        grossAmount: '0',
        vatAmount: '0',
    };

    public mounted(): void
    {
        this.calculateSummary();
    }

    public calculateSummary(): void
    {
        this.summary = calculateSummary(this.property ?? []);
    }
}
</script>

<template>
    <div class="scroll scroll--overflow-y-hidden">
        <table class="table table-bordered mb-2">
            <thead>
                <tr>
                    <th>{{ $t('[[[Stawka VAT]]]') }}</th>
                    <th>{{ $t('[[[Wartość netto]]]') }}</th>
                    <th>{{ $t('[[[VAT]]]') }}</th>
                    <th>{{ $t('[[[Wartość brutto]]]') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, indexRow) in property" :key="indexRow">
                    <td class="text-nowrap">{{ row.taxCode?.value }}</td>
                    <td class="text-nowrap text-lg-end">
                        {{ $filters.currencyFormat(row.netAmount) }}
                    </td>
                    <td class="text-nowrap text-lg-end">
                        {{ $filters.currencyFormat(row.vatAmount) }}
                    </td>
                    <td class="text-nowrap text-lg-end">
                        {{ $filters.currencyFormat(row.grossAmount) }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th>
                        {{ $t('[[[Suma]]]') }}
                    </th>
                    <th class="text-nowrap text-lg-end">
                        {{ $filters.currencyFormat(summary.netAmount) }}
                    </th>
                    <th class="text-nowrap text-lg-end">{{ $filters.currencyFormat(summary.vatAmount) }}</th>
                    <th class="text-nowrap text-lg-end">{{ $filters.currencyFormat(summary.grossAmount) }}</th>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<style lang="scss" scoped>
.table {
    table-layout: fixed;
    width: 100%;

    thead,
    tfoot {
        tr {
            height: 36.5px;
            background: var(--border-color-nav);

            th {
                width: 100px;
            }
        }
    }

    &--border {
        background: var(--border-color-nav);
        border: 1px solid var(--bs-table-border-color);
    }
}
</style>
