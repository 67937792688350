<script lang="ts" setup>
import { computed, inject, ref, Ref } from 'vue';
import { Option } from '@/helpers/Interfaces';
import { FormType } from '@/helpers/Form';
import IdeoComboBoxMultiple from '@/components/ideo/combobox/IdeoComboBoxMultiple.vue';

const model = defineModel<(string|number)[]>();
const props = defineProps({
  "name": null,
  "label": null,
  "placeholder": { default: '[[[Wyszukaj...]]]' },
  "index": { default: 0 },
  "visible": { type: Boolean, default: true },
  "value": { default: undefined },
  "display": { type: [Function, String], default: undefined },
  "menuClass": { default: 'p-0' },
  "dropdown": { type: Boolean, default: true },
  "autoApply": { type: Boolean, default: true },
  "options": { default: (): [] => ([]) },
  "valueField": { default: 'value' },
  "textField": { default: 'text' },
  "size": { default: 10 },
  "default": { type: Boolean, default: true },
  "fetch": { type: Function, default: undefined },
  "search": { type: Function, default: undefined }
});
const emit = defineEmits(["clear", "click", "open", "close"]);

const displayModel = ref<string>(null);
const display = computed<string>(() =>
{
    return props.display != undefined
        ? props.display instanceof Function ? props.display() : props.display
        : displayModel.value;
});

function onDisplay(value: string)
{
    displayModel.value = model.value?.length > 0 ? value : null;
}

function onClear(name: string)
{
    displayModel.value = null;
    emit('clear', name);
}

const filter = inject<FormType<any>>('filter');
const dirty = inject<Ref<string[]>>('dirty');
const reset = inject<(name?: string) => void>('reset');
const apply = inject<() => void>('apply');
</script>

<template>
    <ideo-filter
        :value="model"
        :display="display"
        :name="props.name"
        :label="props.label"
        :index="props.index"
        :visible="props.visible"
        :menu-class="props.menuClass"
        :dropdown="props.dropdown"
        :auto-apply="props.autoApply"
        @clear="onClear"
        @click="emit('click')"
        @open="emit('open')"
        @close="emit('close')"
    >
        <IdeoComboBoxMultiple
            v-model="model"
            :name="props.name"
            :placeholder="props.placeholder"
            :options="props.options"
            :value-field="props.valueField"
            :text-field="props.textField"
            :size="props.size"
            :default="props.default"
            :fetch="props.fetch"
            :search="props.search"
            @display="onDisplay"
        >
            <template #header>
                <slot name="header"></slot>
            </template>
            <template #footer>
                <slot name="footer"></slot>
            </template>
        </IdeoComboBoxMultiple>
    </ideo-filter>
</template>
