<script lang="ts" setup>
import { computed, inject, watch } from 'vue';
import { Definition, BooleanType } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { ApiQueryCondition, KeyElement, TermQueryCondition } from '@/helpers/ApiQueryBuilder';
import IdeoFilterCheckbox from '@/components/filters/types/IdeoFilterCheckbox.vue';

defineOptions({
    name: `${Definition.type}-filter`
});

const model = defineModel<(ApiQueryCondition & KeyElement)[]>();
const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});

const component = computed(() => props.component);
const condition = computed(() => model.value.find(p => p.__key == component.value.id) as TermQueryCondition);
const entry = computed({
    get()
    {
        return condition.value != null;
    },
    set(value)
    {
        if (value == true && condition.value == null)
        {
            model.value.push({
                __key: component.value.id,
                term: {
                    field: component.value.name,
                    value: "true"
                }
            });
        }

        if (value == false && condition.value != null)
        {
            model.value.remove(p => p.__key == component.value.id);
        }
    }
});

const label = computed(() => props.builder.localization.translate(component.value.label));

const change = inject<(name: string) => void>('change');

watch(entry, () =>
{
    change(props.component.name);
});

function reset()
{
    model.value.remove(p => p.__key == component.value.id);
}
</script>

<template>
    <IdeoFilterCheckbox
        v-model="entry"
        :name="component.name"
        :label="label"
        :index="index"
        :value="entry"
        :visible="component.showFilter"
        @clear="reset"
    />
</template>
