import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { BlueprintDefinition } from '@/components/builder/details/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { TabContract } from '../tab';

export const Definition: BlueprintDefinition = {
    type: 'tabs',
    name: '[[[Zakładki]]]',
    icon: 'fa-bookmark',
    group: 'functionalities',
    position: 3,
};

export interface TabsContract extends Blueprint, VisibleBlueprint, HasWidth {
    defaultTab: string;
    components: TabContract[];
}

export class TabsType implements TabsContract
{
    public id: string;
    public type: string;
    public name: string;
    public width: number;
    public minWidth: number;
    public defaultTab: string;
    public components: TabContract[];
    public visible: AlwaysChoice | WhenChoice;
    public visibleWhen: string;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 3;
        this.defaultTab = '';
        this.components = [];
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = 6;
    }
}
