import * as Validator from 'yup';
import Errors from './Errors';
import { $t } from "@/plugins/localization";

export { Validator, Errors };

export default class ValidationSchema
{
    public string(): Validator.StringSchema
    {
        return Validator.string();
    }

    public number(): Validator.NumberSchema
    {
        return Validator.number();
    }

    public boolean(): Validator.BooleanSchema
    {
        return Validator.boolean();
    }

    public date(): Validator.DateSchema
    {
        return Validator.date();
    }

    public array(schema: Validator.AnySchema): Validator.ArraySchema<Validator.AnySchema>
    {
        return Validator.array().of(schema);
    }

    public object(shape: any): Validator.ObjectSchema<any>
    {
        return Validator.object().shape(shape);
    }
}

Validator.setLocale({
    mixed: {
        test: () => $t('[[[Wprowadzona wartość jest nieprawidłowa]]]'),
        required: () => $t('[[[To pole jest wymagane]]]')
    },
    string: {
        email: () => $t('[[[Nieprawidłowy adres email]]]'),
        url: () => $t('[[[Nieprawidłowy adres URL]]]'),
        min: () => $t('[[[Wprowadzona wartość jest za krótka]]]'),
        max: () => $t('[[[Wprowadzona wartość jest za długa]]]'),
        matches: () => '[[[Wprowadzona wartość nie jest poprawna]]]'
    },
    number: {
        min: () => $t('[[[Wprowadzona wartość jest za niska]]]'),
        max: () => $t('[[[Wprowadzona wartość jest za wysoka]]]'),
        lessThan: () => $t('[[[Wprowadzona wartość jest za wysoka]]]'),
        moreThan: () => $t('[[[Wprowadzona wartość jest za niska]]]'),
        positive: () => $t('[[[Wartość musi być liczbą dodatnią]]]'),
        negative: () => $t('[[[Wartość musi być liczbą ujemną]]]'),
        integer: () => $t('[[[Wartość musi być liczbą całkowitą]]]')
    }
});
