<template>
    <div v-if="loading" class="position-absolute top-50 start-50 translate-middle">
        <loader />
    </div>
    <div v-else>
        <full-calendar ref="calendar" :options="options">
            <template #eventContent="arg">
                <row-actions
                    :endpoint="`${licence}/${arg.event.id}/actions`"
                    :id="arg.event.id"
                    :url="licence"
                    :except="[
                        'document-notifications-create',
                        'canManageParticipants',
                        'canUploadOneDrive',
                        'bpmn-set-targets',
                        'canSetPrivate',
                    ]"
                    :options="{
                        calendar: true,
                        dropdown: { position: arg.view.type === 'dayGridMonth' ? 'bottom' : 'left' },
                    }"
                >
                    <template #button-content>
                        <div class="w-100 h-100">
                            <template v-if="arg.view.type === 'dayGridMonth'">
                                <span class="d-inline-block mx-1">
                                    {{ arg.timeText }}
                                </span>
                                <strong>{{ arg.event.title }}</strong>
                            </template>

                            <div v-else class="d-flex flex-column overflow-hidden text-wrap">
                                <span class="d-block">{{ arg.timeText }}</span>
                                <strong class="mb-1">{{ arg.event.title }}</strong>
                                <span v-html="arg.event.extendedProps.description"></span>
                            </div>
                        </div>
                    </template>
                    <template #default>
                        <ideo-dropdown-item-button @click="onRowClick(arg.event.id)">
                            <i class="fas fa-square-list"></i>
                            <span>{{ $t('[[[Szczegóły]]]') }}</span>
                        </ideo-dropdown-item-button>
                    </template>
                </row-actions>
            </template>
        </full-calendar>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { Form } from '@/helpers/Form';
import { APIWidget } from "@/modules/core/home/services/HomeService";
import CalendarDataHelper from '@/modules/low-code/components/helpers/CalendarDataHelper';
import { CalendarOptions } from '@fullcalendar/core';
import Loader from '@/components/common/Loader.vue';
import FullCalendar from '@fullcalendar/vue3';

@Options({
    name: 'CalendarWidget',
    components: {Loader, FullCalendar}
})
export default class CalendarWidget extends Vue
{
    @Prop({ default: () => {} })
    public config: APIWidget;

    @Prop({ default: () => ('') })
    public emitId: string;

    @Prop({ default: () => (Form.create({})) })
    public filter: any;

    public loading: boolean = true;
    public calendarHelper = new CalendarDataHelper();
    public licence: string = '';
    public sitemapId: string = '';

    public options: CalendarOptions = null;

    public async created(): Promise<void>
    {
        this.licence = this.config.licenseUrl;
        this.sitemapId = this.config.sitemapEntryDetails.publicId;

        if (this.emitId)
        {
            this.$events.$on(this.emitId, async () =>
            {
                await this.getData();
            });
        }
    }

    public async mounted(): Promise<void>
    {
        await this.getData();
    }

    public async getData(): Promise<void>
    {
        try
        {
            await Promise.allSettled([this.calendarHelper.fetchWorkHours(), this.calendarHelper.loadData(this.licence, this.sitemapId, this.filter, this.emitId)]);
            this.options = this.calendarHelper.getCalendarData(this.licence, this.sitemapId, this.filter, this.emitId);
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
        finally
        {
            this.loading = false;
        }
    }

    public onRowClick(publicId: string)
    {
        if (!publicId) return;

        this.$router.push({
            name: 'module-form-details',
            params: { licence: this.licence, publicId },
        });
    }


    public async beforeUnmount(): Promise<void>
    {
        if (this.emitId)
            this.$events.$off(this.emitId);

        this.calendarHelper.destroyTooltip();
    }
}
</script>

<style lang="scss">
.tooltip-calendar {
    background: var(--bs-gray-600);
    color: var(--bs-white);
    font-size: 0.9em;
    line-height: 1;
    text-overflow: ellipsis;
    padding: 0.5rem;
    border-radius: 0.3rem;
    box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
    z-index: 1001;
    position: relative;
    max-width: 300px;

    &::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
        content: '';
    }

    &[data-popper-placement^='top']::before {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        bottom: -4px;
    }

    &[data-popper-placement^='bottom']::before {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        top: -4px;
    }

    &[data-popper-placement^='left']::before {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        right: -4px;
    }

    &[data-popper-placement^='right']::before {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        left: -4px;
    }
}

.fc-icon.fc-icon-fas {
    font-weight: 900;
    width: 12px;
    font-size: unset;
    font-family: 'Font Awesome 6 Pro' !important;
}

// Month grid
.fc :is(.fc-event):has(.dropdown.show) {
    z-index: 9999;
}

// Week grid
.fc :is(.fc-daygrid-event, .fc-timegrid-col-events):has(.fc-event-main .dropdown.show) {
    z-index: 9999;
}
</style>

<style lang="scss" scoped>
.calendar-actions {
    z-index: 999;
}
</style>
