<script lang="ts" setup>
import { ref, Ref, computed } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { instanceOfAddressEntry, AddressType, AddressEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'address-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new AddressEntry()) as Ref<AddressEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfAddressEntry, props.index);

const country = computed({
    get()
    {
        return entryData.value.data.country;
    },
    set(value: string)
    {
        entryData.value.data.country = value;
    }
});

const province = computed({
    get()
    {
        return entryData.value.data.province;
    },
    set(value: string)
    {
        entryData.value.data.province = value;
    }
});

const city = computed({
    get()
    {
        return entryData.value.data.city;
    },
    set(value: string)
    {
        entryData.value.data.city = value;
    }
});

const zip = computed({
    get()
    {
        return entryData.value.data.zip;
    },
    set(value: string)
    {
        entryData.value.data.zip = value;
    }
});

const street = computed({
    get()
    {
        return entryData.value.data.street;
    },
    set(value: string)
    {
        entryData.value.data.street = value;
    }
});

const building = computed({
    get()
    {
        return entryData.value.data.building;
    },
    set(value: string)
    {
        entryData.value.data.building = value;
    }
});

const apartment = computed({
    get()
    {
        return entryData.value.data.apartment;
    },
    set(value: string)
    {
        entryData.value.data.apartment = value;
    }
});

const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const required = computed(() => props.form.expressions.required(props.blueprint));

const fieldsCount = computed(() =>
{
    const { hasCountry, hasProvince, hasCity, hasZip, hasStreet, hasBuilding, hasApartment } = props.blueprint;

    return (
        +hasCountry +
        +hasProvince +
        +hasCity +
        +hasZip +
        +hasStreet +
        +hasBuilding +
        +hasApartment
    );
});

const fieldsDisabled = (fieldEnabled: boolean): boolean =>
{
    return fieldEnabled && fieldsCount.value <= 1;
};
</script>

<template>
    <form-component-wrapper class="address-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <div class="row g-0 mb-2 me-n2" v-if="blueprint.hasStreet || blueprint.hasBuilding || blueprint.hasApartment">
                    <div class="col pe-2" v-if="blueprint.hasStreet">
                        <input type="text" class="form-control" v-model="street" :placeholder="$t(blueprint.getStreetLabel())" :readonly="readonly">
                    </div>
                    <div class="col-2 pe-2" v-if="blueprint.hasBuilding">
                        <input type="text" class="form-control" v-model="building" :placeholder="$t(blueprint.getBuildingLabel())" :readonly="readonly">
                    </div>
                    <div class="col-2 pe-2" v-if="blueprint.hasApartment">
                        <input type="text" class="form-control" v-model="apartment" :placeholder="$t(blueprint.getApartmentLabel())" :readonly="readonly">
                    </div>
                </div>
                <div class="row g-0 mb-2 me-n2" v-if="blueprint.hasCity || blueprint.hasZip">
                    <div class="col pe-2" v-if="blueprint.hasCity">
                        <input type="text" class="form-control" v-model="city" :placeholder="$t(blueprint.getCityLabel())" :readonly="readonly">
                    </div>
                    <div class="col-4 pe-2" v-if="blueprint.hasZip">
                        <input type="text" class="form-control" v-model="zip" :placeholder="$t(blueprint.getZipLabel())" :readonly="readonly">
                    </div>
                </div>
                <div class="row g-0 mb-2 me-n2" v-if="blueprint.hasProvince || blueprint.hasCountry">
                    <div class="col pe-2" v-if="blueprint.hasProvince">
                        <input type="text" class="form-control" v-model="province" :placeholder="$t(blueprint.getProvinceLabel())" :readonly="readonly">
                    </div>
                    <div class="col pe-2" v-if="blueprint.hasCountry">
                        <input type="text" class="form-control" v-model="country" :placeholder="$t(blueprint.getCountryLabel())" :readonly="readonly">
                    </div>
                </div>
                <form-error-message :entry="entryData" name="street" />
                <form-error-message :entry="entryData" name="building" />
                <form-error-message :entry="entryData" name="apartment" />
                <form-error-message :entry="entryData" name="city" />
                <form-error-message :entry="entryData" name="zip" />
                <form-error-message :entry="entryData" name="province" />
                <form-error-message :entry="entryData" name="country" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <div class="row g-0">
                <div class="col-6">
                    <h6>{{ $t('[[[Pole zawiera]]]') }}</h6>
                    <field-checkbox v-model="blueprint.hasCountry" :label="$t('[[[Kraj]]]')" :disabled="fieldsDisabled(blueprint.hasCountry)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasProvince" :label="$t('[[[Województwo]]]')" :disabled="fieldsDisabled(blueprint.hasProvince)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasCity" :label="$t('[[[Miejscowość]]]')" :disabled="fieldsDisabled(blueprint.hasCity)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasZip" :label="$t('[[[Kod pocztowy]]]')" :disabled="fieldsDisabled(blueprint.hasZip)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasStreet" :label="$t('[[[Ulica]]]')" :disabled="fieldsDisabled(blueprint.hasStreet)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasBuilding" :label="$t('[[[Numer domu]]]')" :disabled="fieldsDisabled(blueprint.hasBuilding)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasApartment" :label="$t('[[[Numer mieszkania]]]')" :disabled="fieldsDisabled(blueprint.hasApartment)" class="subfield" />
                </div>
                <div class="col-6">
                    <h6>{{ $t('[[[Etykiety]]]') }}</h6>
                    <field-text v-model="blueprint.countryLabel" :placeholder="$t(blueprint.getCountryLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.provinceLabel" :placeholder="$t(blueprint.getProvinceLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.cityLabel" :placeholder="$t(blueprint.getCityLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.zipLabel" :placeholder="$t(blueprint.getZipLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.streetLabel" :placeholder="$t(blueprint.getStreetLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.buildingLabel" :placeholder="$t(blueprint.getBuildingLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.apartmentLabel" :placeholder="$t(blueprint.getApartmentLabel())" size="sm" class="subfield" />
                </div>
            </div>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
.subfield {
    margin: 0;
    height: 36px;
    display: flex;
    align-items: center;
}
</style>
