<script lang="ts" setup>
import { FormBuilderContract } from '@/components/builder/form';
import { Definition, EmailType } from '.';

defineOptions({
    name: `${Definition.type}-presenter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "value": null
});

</script>

<template>
    <span class="d-flex align-items-end" v-if="props.value">
        <i class="fa-light fa-envelope me-2"></i>
        <span>{{ props.value }}</span>
    </span>
    <span v-else>-</span>
</template>

<style scoped>
.fa-envelope {
    font-size: 16px;
}
</style>
