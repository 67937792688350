<script lang="ts" setup>
import { computed } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { ContentType } from '.';
import StorageService from '@/modules/core/files/services/StorageService';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'content-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

const value = computed(() => props.form.localization.translate(props.blueprint.content));

const content = (): string =>
{
    let match: any = null;
    let content = value.value;
    const regex = /"(#\(storage\):(.+?))"/gm;

    while ((match = regex.exec(value.value)) !== null)
    {
        if (match.index === regex.lastIndex)
            regex.lastIndex++;

        content = content.split(match[1]).join(StorageService.previewResource(match[2]));
    }

    return content || '<p>Lorem ipsum...</p>';
};
</script>

<template>
    <form-component-wrapper class="content-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="content" v-html="content()"></div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-editor v-model="blueprint.content[locale]" :label="$t('[[[Treść]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
        </template>
    </form-component-wrapper>
</template>
