import { axios } from '@/plugins/axios';
import { KeyValuePair, Statement } from '@/helpers/Interfaces';
import { FormContract } from '@/components/forms/blueprints/form';

export default class TableTemplatesService
{
    public static async get(publicId: string): Promise<FormModel>
    {
        return (await axios.get(`simple-fetch/table-template/${publicId}`)).data.result;
    }

    public static async create(form: FormModel, moduleId: string): Promise<Statement>
    {
        return (await axios.post(`simple-command/modules/${moduleId}/table-templates`, form)).data;
    }

    public static async update(form: FormModel, publicId: string): Promise<Statement>
    {
        return (await axios.put(`simple-command/table-template/${publicId}`, form)).data;
    }

    public static async delete(publicId: string): Promise<Statement>
    {
        return (await axios.delete(`simple-command/table-template/${publicId}`)).data;
    }

    public static async blueprint(moduleId: string, fieldIndex: string): Promise<FormContract>
    {
        return (await axios.get(`modules/${moduleId}/table-templates/blueprint/${fieldIndex}`)).data;
    }

    public static async getTemplates(module: string, endpointId: string, fieldName: string): Promise<any>
    {
        return (await axios.get(`${module}/table-templates/${endpointId}/${fieldName}`)).data;
    }
}

export interface FormModel {
    name: Record<string, string>;
    fieldIndex: KeyValuePair;
    template: any[];
}
