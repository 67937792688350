<script lang="ts" setup>
import { ref, Ref, computed, watch, getCurrentInstance } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { Definition as RelatedSelfDefinition } from '../related-self';
import { RelatedParentEntry, instanceOfRelatedParentEntry, Definition, RelatedParentType } from '.';
import { Option } from '@/helpers/Interfaces';
import { useLocalization } from '@/plugins/localization';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { Definition as TextDefinition, TextFieldTypes, TextType } from '../text';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import DocumentsService from '@/modules/studio/documents/services/DocumentsService';

defineOptions({
    name: 'related-parent-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const { $t } = useLocalization();

const entryData = ref(new RelatedParentEntry()) as Ref<RelatedParentEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfRelatedParentEntry, props.index);

const instance = getCurrentInstance();
const uid = computed(() => `form-control-${instance.uid}`);

const model = computed({
    get(): Option<number>|Option<number>[]
    {
        if (props.blueprint.multiple)
        {
            return entryData.value.data ?? [];
        }
        else
        {
            return entryData.value.data?.first();
        }
    },
    set(model: Option<number>|Option<number>[])
    {
        if (props.blueprint.multiple)
        {
            entryData.value.data = model as Option<number>[];
        }
        else
        {
            entryData.value.data = model != null ? [model as Option<number>] : [];
        }
    }
});

const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder) || $t(`[[[wybierz...]]]`));
const required = computed(() => props.form.expressions.required(props.blueprint));

const components = computed<Option[]>(() =>
{
    return props.form.schema
        .components([Definition.type, RelatedSelfDefinition.type], props.blueprint)
        .map(p => ({ value: p.name, text: props.form.localization.translate((p as HasLabel).label) }));
});

const scope = computed<number[]>(() =>
{
    return components.value
        .where(p => props.blueprint.dependsOn.includes(p.value))
        .select(p => props.entry[p.value].values as Option<number>[])
        .where(p => p != null)
        .selectMany(p => p.select((q: any) => q.value).toArray())
        .toArray();
});

const disabled = computed(() =>
{
    if (props.blueprint.dependsOn.length > 0)
    {
        return props.blueprint.dependsOn.length != scope.value.length;
    }

    return false;
});

async function fetch(ids: number|number[])
{
    try
    {
        if (!props.blueprint.formId || !props.blueprint.componentId)
            return [];

        if (!ids || (Array.isArray(ids) && ids.length == 0)) return [];

        const response = await DocumentsService.getOptions(props.form.blueprintId, props.blueprint.id, scope.value, ids, null, 10000);

        const result = response.map(p => ({
            value: p.value,
            text: p.text
        }));

        return props.blueprint.multiple ? result : result.first();
    }
    catch (ex)
    {
        return [];
    }
}

async function search(query: string)
{
    try
    {
        if (!props.blueprint.formId || !props.blueprint.componentId)
            return [];

        const response = await DocumentsService.getOptions(props.form.blueprintId, props.blueprint.id, scope.value, null, query);

        return response.map(p => ({
            value: p.value,
            text: p.text
        }));
    }
    catch (ex)
    {
        return [];
    }
}

watch(scope, (val: number[], old: number[]): void =>
{
    if (JSON.stringify(val) != JSON.stringify(old))
    {
        model.value = null;
    }
});

const values = ref<number|number[]>(null);

function onChange(value: Option<number>|Option<number>[])
{
    model.value = value;
}

watch(model, (val, old) =>
{
    if (JSON.stringify(val) == JSON.stringify(old)) return;

    if (props.blueprint.multiple)
        values.value = (model.value as Option<number>[])?.map(p => p.value) ?? [];
    else
        values.value = (model.value as Option<number>)?.value;
},
{ immediate: true });
</script>

<template>
    <form-component-wrapper class="related-parent-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <ideo-combo-box
                    v-model="values"
                    :name="uid"
                    :fetch="fetch"
                    :search="search"
                    :placeholder="placeholder"
                    :multiple="blueprint.multiple"
                    :disabled="disabled"
                    @change="onChange"
                />
                <form-error-message :entry="entryData" name="values" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <div class="alert alert-light p-2">
                <h5>{{ $t('[[[Źródło danych]]]') }}</h5>
                <field-category
                    v-model="blueprint.categoryId"
                    :label="$t('[[[Kategoria]]]')"
                    :invalid-feedback="() => form.schema.errorMessage(blueprint, 'categoryId')"
                    required
                />
                <field-form
                    v-model="blueprint.formId"
                    :category="blueprint.categoryId"
                    :label="$t('[[[Formularz]]]')"
                    :invalid-feedback="() => form.schema.errorMessage(blueprint, 'formId')"
                    :disabled="!blueprint.categoryId"
                    required
                />
                <field-component
                    v-model="blueprint.componentId"
                    :form="form"
                    :form-id="blueprint.formId"
                    :component-type="TextDefinition.type"
                    :component-filter="(p: Blueprint) => (p as TextType).fieldType == TextFieldTypes.Text"
                    :label="$t('[[[Komponent źródłowy]]]')"
                    :description="$t('[[[Komponent którego wartość zostanie użyta jako nazwa elementu nadrzędnego. Dostępne tylko komponenty typu ``Tekst``.]]]')"
                    :invalid-feedback="() => form.schema.errorMessage(blueprint, 'componentId')"
                    :disabled="!blueprint.formId"
                    required
                />
                <div class="mt-3" v-if="components.length > 0">
                    <hr>
                    <field-checkboxlist v-model="blueprint.dependsOn" :options="components" :label="$t('[[[Zależna od kontrolki]]]')" class="mb-0" />
                    <small class="form-text text-muted d-block">
                        {{ $t('[[[Lista elementów zostanie zawężona tylko do tych, które są pozwiązane z wybranymi wartościami z zaznaczonych komponentów.]]]') }}
                    </small>
                </div>
            </div>
            <field-checkbox v-model="blueprint.multiple" :label="$t('[[[Możliwość wielokrotnego wyboru]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" :placeholder="$t('[[[wybierz...]]]')" />
            </ideo-form-localize>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
