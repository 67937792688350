<template>
    <div class="form-group">
        <label :for="uid" v-if="showLabel">{{ label }}</label>
        <div class="input-group">
            <input type="text" class="form-control pointer" :readonly="true" :id="uid" :value="modelValue" :placeholder="showLabel ? '' : label" @click="openEditor()">
            <span class="btn btn-secondary" @click="openEditor()">
                <i class="fas fa-cog"></i>
            </span>
        </div>
        <small class="form-text text-danger" v-if="errorMessage">
            {{ $t(errorMessage) }}
        </small>
        <ideo-modal id="field-with-helper-modal" v-model="modal" :title="label" size="xxl" centered scrollable>
            <template #default>
                <div class="row">
                    <div class="col-lg-7 mb-3 mb-lg-0">
                        <Codemirror
                            v-model="buffer"
                            :style="{ maxHeight: '800px;', minHeight: '800px;' }"
                            :autofocus="true"
                            :indent-with-tab="false"
                            :tab-size="4"
                            :extensions="extensionsJs"
                        />
                    </div>
                    <div class="col-lg-5">
                        <slot name="helper"></slot>
                    </div>
                </div>
            </template>
            <template #modal-footer>
                <button class="btn btn-primary" @click.stop.prevent="saveExpression()">
                    {{ $t('[[[Zapisz]]]') }}
                </button>
                <ideo-button variant="secondary" @click.stop.prevent="closeEditor()">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { FormEntry } from '../blueprints/form/Form';
import { Codemirror } from 'vue-codemirror';
import { javascript as jsLang } from '@codemirror/lang-javascript';


@Options({
    name: 'field-with-helper',
    components: {
        Codemirror
    }
})
export default class FieldWithHelper extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public entry!: FormEntry;
    @Prop({ default: true }) public showLabel: boolean;
    @Prop() public label: string;
    @Prop() public modelValue: string;
    @Prop({ default: 'text' }) public type: string; // text, number, bool, date

    public modal: boolean = false;
    public sidebar: boolean = false;
    public buffer: string = null;
    public errorMessage: string = null;
    public textcomplete: any = null;

    public get extensionsJs(): any[]
    {
        return [jsLang()];
    }

    public mounted(): void
    {
        this.buffer = this.modelValue;
    }

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    @Emit('update:modelValue')
    public triggerInput(value: string): string
    {
        return value;
    }

    public saveExpression(): void
    {
        this.triggerInput(this.buffer);
        this.closeEditor();
    }

    public openEditor(): void
    {
        this.buffer = this.modelValue;
        this.modal = true;
    }

    public closeEditor(): void
    {
        this.buffer = this.modelValue;
        this.modal = false;
    }

    @Watch('value')
    public onValueChanged(value: string): void
    {
        this.buffer = value;
    }
}
</script>
