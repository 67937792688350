<template>
    <embed
        v-if="fileUrl"
        :src="fileUrl"
        style="min-height: 800px; object-fit: contain"
        :class="isImg ? 'image-viewer' : 'pdf-viewer'"
        width="100%"
    />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "@/helpers/Decorators";
import { isImage } from "@/helpers/Utils";

@Options({
    methods: { isImage },
})
export default class FileReader extends Vue
{
    @Prop({ default: "" }) public src: string;

    public get isImg(): boolean
    {
        return isImage(this.fileUrl);
    }

    public get fileUrl(): string
    {
        const fileUrl = this.$filters.baseurl(this.src);
        const canRead = /\.(gif|jpe?g|tiff?|png|webp|bmp|svg|pdf)$/i.test(fileUrl);

        return canRead && fileUrl ? fileUrl : null;
    }
}
</script>
