<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { MainFileModel } from "@/modules/logito/office/services/OfficeDocuments";

@Options({
    name: 'MainFileFormatter',
})
export default class MainFileFormatter extends Vue
{
    @Prop({ default: null }) public property: MainFileModel;
    @Prop() public features: KeyValuePair[];
}
</script>

<template>
    <div v-if="property">
        <a :href="$filters.baseurl(property.url)" target="_blank" class="d-flex align-items-baseline" @click.stop>
            <i class="fa fa-file me-1"></i> {{ property.name }}
        </a>
    </div>
</template>
