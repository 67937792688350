<template>
    <div class="d-flex flex-column w-100 h-100">
        <RouterView v-slot="{ Component }">
            <Transition name="fade" mode="out-in">
                <component :is="Component" :key="keyComponent"></component>
            </Transition>
        </RouterView>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
    name: "ModuleLayout",
})
export default class ModuleLayout extends Vue
{
    public get keyComponent(): string
    {
        return `${Object.values(this.$route.params).join("/")}`;
    }

    public unmounted(): void
    {
        this.$store.commit("lowCode/versions/SET_VERSION", null);
    }
}
</script>
