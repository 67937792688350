<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Ref } from '@/helpers/Decorators';
import { $t } from '@/plugins/localization';
import { Form, FormType } from '@/helpers/Form';
import BpmnService, { BpmnEmitOptions, BpmnFormModel } from '@/modules/low-code/process/services/BpmnService';
import { FrontActionModal } from '@/components/common/dynamic-grid/helpers/GridEnums';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';
import AddAttachments from '@/views/partials/components/attachments/AddAttachments.vue';

@Options({
    components: { AddAttachments },
})
export default class BPMNFinishDescriptionModal extends Vue
{
    @Ref('modal') public modal: () => IdeoModal;

    public form: FormType<BpmnFormModel> = Form.create<BpmnFormModel>({
        comment: '',
        attachments: [],
    });

    public action = Form.create<BpmnEmitOptions>({
        id: '',
        licence: '',
        actionName: '',
        buttonId: '',
        displayMode: 'Modal',
        displaySize: 'Medium',
        title: '',
        buttonName: $t('[[[Akceptuj]]]'),
        buttonVariant: 'success',
        buttonIcon: '',
        groupAction: false,
    });

    public async mounted(): Promise<void>
    {
        this.$events.$on(FrontActionModal.FinishDescriptionModel, (action: BpmnEmitOptions) =>
        {
            this.action.withData(action);
            this.modal().show();
        });
    }

    public unmounted(): void
    {
        this.$events.$off(FrontActionModal.FinishDescriptionModel);
    }

    public async submit(): Promise<void>
    {
        try
        {
            this.form.wait();

            // Group action
            if (this.action.groupAction)
            {
                const endpoint = `${this.action.licence}/${this.action.buttonId}/bpmn-execute/${this.action.id}`;
                const response: any = await BpmnService.execute(endpoint, this.form.formatData());

                if (response?.length)
                {
                    this.$alert.warning(`${this.$t('[[[Dla dokumentów o sygnaturach]]]')}: ${response.join(', ')} ${this.$t('[[[nie udało się wykonać akcji]]]')}`);
                }
                else
                {
                    this.$alert.success(this.$t('[[[Akcja wykonana pomyślnie]]]'));
                }
            }
            else
            {
                const endpoint = `${this.action.licence}/${this.action.id}/${this.action.buttonId}/bpmn-execute`;

                await BpmnService.execute(endpoint, this.form.formatData());
                this.$alert.success(this.$t('[[[Akcja wykonana pomyślnie]]]'));
            }

            this.modal().hide();

            setTimeout(() =>
            {
                this.$events.$emit('refetchData');
                this.form.reset();
            }, 500);
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.$alert.warning(
                        this.$t('[[[Dokument jest niepoprawnie opisany]]]') + `\n${this.form.$errors.first('')}`
                    ),
                ],
            });
        }
        finally
        {
            this.form.continue();
        }
    }
}
</script>

<template>
    <ideo-modal ref="modal" :title="action.title" centered @close="form.reset()" @hidden="form.reset()">
        <template #default>
            <ideo-form-group :label="$t('[[[Dodaj załączniki]]]')">
                <add-attachments v-model="form.attachments" :container="'documents'" />
            </ideo-form-group>
            <ideo-form-group
                :label="$t('[[[Uwagi]]]')"
                :invalid-feedback="form.$errors.first('comment')"
                :state="form.$errors.state('comment')"
            >
                <ideo-form-textarea v-model="form.comment" rows="6" @input="form.$errors.clear('comment')" />
            </ideo-form-group>
        </template>
        <template #modal-footer="{ cancel }">
            <ideo-button :variant="action.buttonVariant" @click="submit" :disabled="!form.active()">
                <i v-if="action.buttonIcon" class="me-2" :class="action.buttonIcon"></i>
                <span>{{ action.buttonName }}</span>
            </ideo-button>
            <ideo-button variant="secondary" @click="cancel()">
                {{ $t('[[[Anuluj]]]') }}
            </ideo-button>
        </template>
    </ideo-modal>
</template>

