import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: 'simply-sign',
        name: 'simply-sign-auth',
        meta: { breadcrumbs: '[[[Autentykacja SimplySign]]]' },
        component: () => import('@/modules/logito/signatures/views/Auth.vue'),
    },
    {
        path: 'redirect',
        name: 'sign-redirect',
        meta: { breadcrumbs: '[[[Przekierowanie]]]' },
        component: () => import('@/modules/logito/signatures/views/Redirect.vue')
    }
];

export default routes;
