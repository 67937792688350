<script lang="ts" setup>
import { useRouter } from 'vue-router';

defineOptions({
    name: 'back-button',
});

const props = defineProps({
  "toolbar": { type: Boolean,  },
  "fallback": null
});

const router = useRouter();

const redirect = () =>
{
    if (window.history.state?.back) router.back();
    else  router.push(props.fallback || '/');
};
</script>

<template>
    <action-button
        back
        toolbar
        variant="outline-dark"
        icon="fas fa-caret-left"
        :text="$t('[[[Powrót]]]')"
        @click="redirect"
    />
</template>
