import { Blueprint } from "../../base/blueprints/Blueprint";

export interface HasPresenter extends Blueprint
{
    getColumnExpression(): string|null;
}

export const instanceOfHasPresenter = (object: any): object is HasPresenter =>
{
    return object && 'getColumnExpression' in object;
};
