import { axios } from "@/plugins/axios";
import { DateTime } from "luxon";
import Pager from "@/helpers/Pager";
import { merge } from "lodash";
import { Resource, Pagination, KeyValuePair } from "@/helpers/Interfaces";

export default class ExchangeRatesService
{
    public static async GetCurrencies(pager: Pager): Promise<Pagination<Resource<KeyValueIsoSymbol>>>
    {
        return (
            await axios.get("costs/exchange-rates/currencies", {
                params: merge({}, pager.data()),
            })
        ).data;
    }

    public static async GetExchangeRate(currencyFrom: string, currencyTo: string, date: DateTime | string): Promise<ExchangeRate>
    {
        return (
            await axios.get("costs/exchange-rates", {
                params: {
                    currencyFrom,
                    currencyTo,
                    date,
                },
            })
        ).data;
    }
}

export interface ExchangeRate {
    key: string;
    value: string;
    rate: number;
    currencyFrom: string;
    currencyTo: string;
    date: string | DateTime;
}

export interface KeyValueIsoSymbol extends KeyValuePair {
    isoSymbol: string;
}
