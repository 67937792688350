enum EntityType{
    Document = "document",
    User = "user",
    Substitution = "substitution",
    Dictionary = "dictionary",
    Crm = "crm",
    Adresses = "crm-address"
}

enum ObjectTypeKey{
    Participant = "FcDocumentParticipant",
    Incoming = "FcIncoming"
}

export {
    EntityType,
    ObjectTypeKey
};
