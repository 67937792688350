<template>
    <div class="d-flex align-items-end key-value-formatter">
        <div v-if="Array.isArray(original) && original.length" class="me-2">
            <span v-for="item in original" :key="item.key" class="small original-value me-1">{{ item.value }}</span>
        </div>
        <div v-if="Array.isArray(current) && current.length">
            <span v-for="item in current" :key="item.key" class="small current-value me-1">{{ item.value }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { ChangelogDefinitionModel } from '@/modules/common/services/ChangelogService';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'KeyValueFormatterArray'
})
export default class KeyValueFormatterArray extends Vue
{
    @Prop()
    public original: KeyValuePair | null;

    @Prop()
    public current: KeyValuePair | null;

    @Prop({ default: null })
    public definition: ChangelogDefinitionModel;
}
</script>
