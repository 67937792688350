<script lang="ts" setup>
import { computed, inject, ComputedRef } from 'vue';
import { StatusGraphType } from '.';
import { ProcessFlow } from '@/modules/low-code/inc/ProcessFlow';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DetailsBuilderContract } from '@/components/builder/details';
import StepWizard from '@/components/common/StepWizard.vue';
import properties from '../../properties';

defineOptions({
    name: 'StatusGraphBlueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "details": null,
  "parent": null
});

const processFlow = inject<ComputedRef<ProcessFlow>>('process-flow', null);

const blueprint = computed(() => props.blueprint);
const design = computed(() => props.details.designMode());
const flow = computed(() =>
{
    if (!design.value && processFlow.value) return processFlow.value;
    else if (design.value)
        return {
            currentPriority: 1,
            nodes: [
                {
                    key: '1',
                    value: 'Status 1',
                },
                {
                    key: '2',
                    value: 'Status 2',
                },
                {
                    key: '3',
                    value: 'Status 3',
                },
            ],
        };

    return null;
});
</script>

<template>
    <dynamic-details-component-wrapper :details="details" :parent="parent" :blueprint="blueprint">
        <template #default>
            <step-wizard v-if="flow" :process-flow="flow" />
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" />
            <dynamic-details-accordion>
                <dynamic-details-accordion-item icon="far fa-table" :header="$t('[[[Prezentacja]]]')">
                    <field-visible :details="details" :blueprint="blueprint" />
                </dynamic-details-accordion-item>
            </dynamic-details-accordion>
        </template>
    </dynamic-details-component-wrapper>
</template>
