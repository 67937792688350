import { axios } from '@/plugins/axios';
import { KeyValue, Statement } from '@/helpers/Interfaces';

/**
 * PermissionsService
 */
export default class PermissionsService
{
    /**
     * @returns Promise<PermissionsGroup[]>
     */
    public static async getList(): Promise<PermissionsGroup[]>
    {
        return (await axios.get<PermissionsGroup[]>('admin/permissions')).data;
    }

    /**
     * @returns Promise<PermissionsGroup[]>
     */
    public static async checkPermissions(permissions: string[]): Promise<Record<string, boolean>>
    {
        return (await axios.post<Record<string, boolean>>('admin/permissions', {
            permissions: permissions
        })).data;
    }

    /**
     * @returns Promise<PermissionsGroup[]>
     */
    public static async inspect(id: number): Promise<KeyValue<string, Record<string, string>>[]>
    {
        return (await axios.get<KeyValue<string, Record<string, string>>[]>(`admin/permissions/inspect/${id}`)).data;
    }

    /**
     * @returns Promise<PersonasGroup[]>
     */
    public static async getIdentities(query: string): Promise<PersonasGroup[]>
    {
        return (await axios.get<PersonasGroup[]>('admin/permissions/personas', {
            params: {
                query: query
            }
        })).data;
    }

    /**
     * @returns Promise<PermissionsGroup>
     */
    public static async resourcePermissions(resource: string): Promise<PermissionsGroup>
    {
        return (await axios.get<PermissionsGroup>(`admin/permissions/resource/${resource}`)).data;
    }

    /**
     * @param resource string
     * @param resourceId number
     * @param model ResourcePermissionsModel
     *
     * @return  Promise<Statement>
     */
    public static async saveResourcePermissions(resourceType: string, resourceId: number, model: ResourcePermissionsModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/permissions/resource/${resourceType}/${resourceId}`, model)).data;
    }

    /**
     * @returns Promise<ResourceOwner[]>
     */
    public static async resourceOwners(resourceType: string, resourceId: number): Promise<ResourceOwner[]>
    {
        return (await axios.get<ResourceOwner[]>(`admin/permissions/resource/${resourceType}/${resourceId}`)).data;
    }
}

export enum PermissionType {
    Access = 0,
    Resource = 1,
    All = 2
}

export interface PermissionsGroup
{
    key: string;
    name: string;
    sections: KeyValue<string, PermissionDescriptor>[][];
}

export interface PermissionDescriptor
{
    description: string;
    type: PermissionType;
    alt: string;
}

export interface PersonasGroup
{
    group: string;
    type: string;
    values: KeyValue<number, string>[];
}

export enum PermissionStateEnum {
    Allow = 'Allow',
    Deny = 'Deny'
}

export interface ResourcePermissionsModel
{
    ownerId: number,
    ownerType: string,
    permissionsType: string,
    permissions: Record<string, PermissionStateEnum>
}

export interface ResourceOwner
{
    ownerId: number,
    ownerType: string,
    ownerName: string,
    permissions: Record<string, PermissionStateEnum>
}
