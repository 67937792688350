<script lang="ts">
import { Prop, Watch } from "@/helpers/Decorators";
import { Options, Vue } from "vue-class-component";
import { EntityType } from "@/components/common/dynamic-changelog/ChangelogEnums";
import ChangelogService, { ChangelogDataModel, ChangelogDefinitionModel } from "@/modules/common/services/ChangelogService";
import { contractAction } from "@/modules/logito/office/inc/contracts/helpers";
import Participant from "@/modules/low-code/components/Participant.vue";
import { entries } from "lodash";
import ChangesRenderer from "./ChangesRenderer.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { KeyValue, KeyValuePair, Resource } from "@/helpers/Interfaces";
import Pager from "@/helpers/Pager";
import Substitute from '@/modules/core/substitutions/views/Substitute.vue';
import { DateTime } from "luxon";

@Options({
    name: "Changelog",
    components: { Participant, ChangesRenderer, Substitute },
})
export default class DynamicChangelog extends Vue
{
    @Prop({ default: null }) public entityType: EntityType;
    @Prop({ default: false}) public hasAddress: boolean;
    @Prop({default: ''}) public address: string;
    @Prop({ default: null }) public id: string;
    @Prop({ default: false }) public design: boolean;
    @Prop({ default: "" }) public blueprintName: string;

    public get getId(): string
    {
        return String(this.id);
    }

    public changesPagerCount: number = 20;
    public changelogDef: KeyValue<string, ChangelogDefinitionModel>[] = [];
    public changelogData: ChangelogDataModel[] = [];
    public changesPager = new Pager(1, this.changesPagerCount, "Id", "desc");
    public definitionPager = new Pager(1, 100);
    public contractAction = contractAction;

    public mounted(): void
    {
        this.changesPager.setTotalRows(0);

        if (this.design) return;

        this.$events.$on("refetchData", this.initChangelog);
    }

    public unmounted(): void
    {
        if (this.design) return;
    }

    public showInfo(id: string): void
    {
        this.$events.$emit("fetchUser", { id });
    }

    public isNotAllChanges(): boolean
    {
        return this.changesPager.getTotalRows() > this.changesPagerCount;
    }

    public getChangesCounter(): string
    {
        if (this.isNotAllChanges())
            return this.changesPagerCount + '+';

        return this.changesPager.getTotalRows().toString();
    }

    public async getAllChanges(): Promise<void>
    {
        this.changesPagerCount = 999999;
        this.changesPager.setPageSize(this.changesPagerCount);
        this.initChangelog();
    }

    public async initChangelog(): Promise<void>
    {
        if (this.design)
            return;

        try
        {
            const { totalRows: rows, items: definitionItem } = await ChangelogService.fetchChangelogDefinition(
                this.entityType,
                this.id,
                this.definitionPager
            );

            this.definitionPager.setTotalRows(rows);
            this.changelogDef = definitionItem.map((item) => item.result);

            const { items, totalRows } = await ChangelogService.fetchChangelogData(this.entityType, this.id, this.changesPager);

            if (this.hasAddress && this.address != "")
            {
                const address = await ChangelogService.fetchChangelogData(EntityType.Adresses,this.address, this.changesPager);
                const addressItems = address.items;

                addressItems.forEach(item =>
                {
                    const matched = items.find((element) => element?.result?.requestId == item?.result?.requestId);

                    if (!matched)
                    {
                        item.result.objectType = items[0].result.objectType;
                        items.push(item);
                    }
                    else
                    {
                        matched.result.current.city = item.result.current.city;
                        matched.result.current.houseID = item.result.current.house ?? item.result.original.house2;
                        matched.result.current.country = item.result.current.countryId;
                        matched.result.current.postalCode = item.result.current.streetPostal;
                        matched.result.current.street = item.result.current.streetName;

                        matched.result.original.city = item.result.original.city;
                        matched.result.original.houseID = item.result.original.house ?? item.result.original.house2;
                        matched.result.original.country = item.result.original.countryId;
                        matched.result.original.postalCode = item.result.original.streetPostal;
                        matched.result.original.street = item.result.original.streetName;
                    }
                });

                items.sort((a,b) =>
                {
                    // @ts-ignore
                    return DateTime.fromISO(b.result.audit.dateUtc) - DateTime.fromISO(a.result.audit.dateUtc);
                });
            }

            const totalRowsCalc = items.length;
            const resultItems = items.take(this.changesPagerCount);

            this.changesPager.setTotalRows(totalRowsCalc);
            this.changelogData = resultItems.map((item) => item.result);
        }
        catch (ex)
        {
            this.changesPager.setTotalRows(0);
            this.changelogData = [];
        }
    }

    public GetWrapperType(item: ChangelogDataModel): Record<string, any>
    {
        const {
            original,
            current,
            objectType: { mapName },
        } = item;

        const currentChanged = {};
        const originalChanged = {};
        const keys = entries(original);

        keys.forEach((item) =>
        {
            const [key] = item;
            const originalProperty = original[key];
            const currentProperty = current[key];

            if (typeof originalProperty === "object" && originalProperty !== null)
            {
                if (JSON.stringify(originalProperty) !== JSON.stringify(currentProperty))
                {
                    currentChanged[key] = currentProperty;
                    originalChanged[key] = originalProperty;
                }
            }
            else if (currentProperty !== originalProperty && (currentProperty || originalProperty))
            {
                currentChanged[key] = currentProperty;
                originalChanged[key] = originalProperty;
            }
        });

        const attributes = { original: originalChanged, current: currentChanged, map: mapName };

        return attributes;
    }

    @Watch("getId", { immediate: true })
    public idCame(id: string): void
    {
        if (!this.design)
        {
            if (id) this.initChangelog();
        }
    }
}
</script>

<template>
    <div v-if="getId">
        <ListView :items="changelogData" :preload="false" class="changelog-table">
            <template #row="{ item, index }">
                <ListViewItem :title="$t('[[[Pracownik]]]')" width="250">
                    <div class="d-flex align-items-center" v-if="item.audit.user && (item.audit.user as KeyValuePair).value">
                        <user-avatar
                            :src="$filters.baseurl(`storage/files/${(item.audit.user as KeyValuePair).pictureUrl}`)"
                            :username="(item.audit.user as KeyValuePair).value"
                            :key="(item.audit.user as KeyValuePair).pictureUrl"
                            class="cursor-pointer"
                            @click="showInfo(item.audit.user.key)"
                        />
                        <Substitute
                            v-if="item.audit.impersonator?.key"
                            :user="item.audit.impersonator?.value"
                        />
                        <p class="ms-2 mb-0">
                            <strong class="text-primary cursor-pointer" @click="showInfo(item.audit.user.key)">
                                <span>{{ (item.audit.user as KeyValuePair).value }}</span>
                            </strong>
                        </p>
                    </div>
                </ListViewItem>
                <ListViewItem :title="$t('[[[Data]]]')" width="150">
                    {{ $filters.datetime(item.audit.dateUtc as string) }}
                </ListViewItem>
                <ListViewItem :title="$t('[[[Rodzaj akcji]]]')" width="150">
                    {{ contractAction(item.action.value) }}
                </ListViewItem>
                <ListViewItem :title="$t('[[[Dane]]]')">
                    <div v-if="item.objectType.key === 'FcDocumentParticipant'" class="participant d-flex">
                        <participant :user="item.current" :key="item.publicId" :index="'document-log-' + index" />
                    </div>
                    <ChangesRenderer v-else v-bind="{ ...GetWrapperType(item) }" :definition="changelogDef" />
                </ListViewItem>
            </template>
        </ListView>
        <div class="d-flex justify-content-center col-12" v-if="isNotAllChanges()">
            <ideo-button variant="primary" size="sm" class="rounded mt-3" @click="getAllChanges()">
                <i class="fa fa-plus me-1"></i>
                {{ $t("[[[Wyświetl wszystko]]]") }}
            </ideo-button>
        </div>
        <portal v-if="blueprintName" :to="`tab-counter-${blueprintName}`">
            {{ getChangesCounter() }}
        </portal>
    </div>
</template>
