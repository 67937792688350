<template>
    <ideo-form-group id="publication-status" :label="$t('[[[Status edytowanej wersji dokumentu]]]')" tag="h5" :disabled="isDisabled()">
        <ideo-dropdown ref="dropdown" block menu-class="w-100" :split-variant="statusVariant('outline-{0}', status)" :variant="statusVariant('outline-{0}', status)" toggle-class="d-flex align-items-center py-2 btn-subtle" :disabled="isDisabled()">
            <template #button-content>
                <i class=" fs-5 me-2" :class="statusIcon(status)"></i>
                <span class="publication-status-text me-auto">{{ statusName(status) }}</span>
            </template>
            <ideo-dropdown-header>
                {{ $t('[[[Zmień status edytowanej wersji]]]') }}
            </ideo-dropdown-header>
            <ideo-dropdown-item-button
                v-for="(option, index) in statusOptions"
                :key="option.value"
                ref="items"
                :prevent-close="true"
                @click.stop.prevent="onClick(option.value as PublicationEnum, $refs.items[index].$el)"
            >
                <i class="icon" :class="option.icon"></i>
                <span>{{ option.text }}</span>
            </ideo-dropdown-item-button>
        </ideo-dropdown>
        <confirmation
            ref="confirm"
            :message="$t('[[[Potwierdzenie zmiany statusu]]]')"
            target="publication-status"
            placement="left"
            :value="{}"
            @confirm="confirm"
            @cancel="cancel"
            v-if="!isDisabled()"
        />
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref } from '@/helpers/Decorators';
import { PublicationEnum, PublicationOptions, PublicationName, PublicationStyle, PublicationIcon } from '@/helpers/Enums';
import IdeoDropdown from '@/components/ideo/dropdown/IdeoDropdown.vue';
import Confirmation from './Confirmation.vue';
import { Option } from '@/helpers/Interfaces';

@Options({
    name: 'publication-status'
})
export default class PublicationStatus extends Vue
{
    @Prop()
    public modelValue!: PublicationEnum;

    @Prop({ default: false })
    public disabled: boolean;

    @Prop({ default: false })
    public canEdit: boolean;

    @Prop({ default: false })
    public canAccept: boolean;

    @Ref('dropdown')
    public dropdown: () => typeof IdeoDropdown;

    @Ref('confirm')
    public popover: () => typeof Confirmation;

    public clipboard: PublicationEnum = null;

    public get status(): PublicationEnum
    {
        return this.modelValue;
    }

    public get statusEnum(): typeof PublicationEnum
    {
        return PublicationEnum;
    }

    public get statusOptions(): Option[]
    {
        return PublicationOptions().filter(p => this.allowedState(this.status, p.value as PublicationEnum));
    }

    public statusName(status: PublicationEnum): string
    {
        return PublicationName(status);
    }

    public statusIcon(status: PublicationEnum): string
    {
        return PublicationIcon(status).replace(new RegExp('text-.+', 'g'), '');
    }

    public statusStyle(classTemplate: string, status: PublicationEnum): Record<string, boolean>
    {
        return PublicationStyle(classTemplate, status);
    }

    public statusVariant(classTemplate: string, status: PublicationEnum): string
    {
        return Object.entries(PublicationStyle(classTemplate, status)).find(p => p[1])[0];
    }

    public allowedState(currentStatus: PublicationEnum, newStatus: PublicationEnum): boolean
    {
        if (this.canAccept)
        {
            switch (currentStatus)
            {
                case PublicationEnum.Draft:
                    return [PublicationEnum.Finished, PublicationEnum.Accepted].includes(newStatus);
                case PublicationEnum.Finished:
                    return [PublicationEnum.Draft, PublicationEnum.Accepted, PublicationEnum.Rejected].includes(newStatus);
                case PublicationEnum.Rejected:
                    return [PublicationEnum.Draft, PublicationEnum.Finished, PublicationEnum.Accepted].includes(newStatus);
                case PublicationEnum.Accepted:
                    return newStatus == PublicationEnum.Rejected;
            }
        }

        if (this.canEdit)
        {
            switch (currentStatus)
            {
                case PublicationEnum.Draft:
                    return newStatus == PublicationEnum.Finished;
                case PublicationEnum.Finished:
                    return newStatus == PublicationEnum.Draft;
                case PublicationEnum.Rejected:
                    return [PublicationEnum.Draft, PublicationEnum.Finished].includes(newStatus);
            }
        }

        return false;
    }

    public onClick(status: PublicationEnum, button: HTMLElement): void
    {
        this.popover().open(button);
        this.clipboard = status;
    }

    @Emit('change-status')
    @Emit('update:modelValue')
    public changeStatus(value: PublicationEnum): PublicationEnum
    {
        return value;
    }

    public confirm(): void
    {
        this.changeStatus(this.clipboard);
        this.cancel();
    }

    public cancel(): void
    {
        this.dropdown().hide();
        this.clipboard = null;
    }

    public isDisabled(): boolean
    {
        return this.disabled || this.statusOptions.length == 0;
    }
}
</script>

<style lang="scss">
.publication-status {
    &-text {
        text-transform: none;
    }
}
</style>
