import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { TermKeyValuePair } from "@/helpers/Interfaces";

export const Definition: BlueprintDefinition = {
    type: "logito-dictionary",
    name: "[[[Słownik Logito]]]",
    icon: "far fa-clipboard-list",
    group: "logito",
    position: 7,
};

export class LogitoDictionaryEntry extends ValidEntry<TermKeyValuePair[]>
{
    public type: string = Definition.type;
    public data: TermKeyValuePair[] = null;
    public defaultValue: TermKeyValuePair[] = [];

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(
        blueprint: LogitoDictionaryContract,
        form: FormBuilderContract,
        preprocess: ProcessCallback
    ): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        const data = this.getValues(blueprint);

        return entry(data);
    }

    public validate(blueprint: LogitoDictionaryContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const value = this.data;

        if (form.expressions.required(blueprint) && (value === null || !value.length))
        {
            this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
        }

        return this.valid();
    }

    private getValues(blueprint: LogitoDictionaryContract): string[]
    {
        if (this.data !== null) return this.data.map(item => item.key);

        if (blueprint.defaultValue?.length) return this.defaultValue.map(item => item.key);

        return [];
    }
}

export const instanceOfLogitoDictionaryEntry = (object: any): object is LogitoDictionaryEntry =>
{
    return object && "type" in object && object.type === Definition.type;
};

export interface LogitoDictionaryContract extends Blueprint, VisibleBlueprint, RequiredBlueprint, HasLabel, HasPlaceholder, HasHelp, HasWidth {
    dataSource: string;
    multiselect: boolean;
    filterScript: string;
    defaultValue: string[];
    letUsersAddTerms: boolean;
}

export class LogitoDictionaryType implements LogitoDictionaryContract, ValidatableBlueprint, EntryFactory<LogitoDictionaryEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public placeholder: Record<string, string>;
    public help: Record<string, string>;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public errors: ValidationErrors;
    public dataSource: string;
    public width: number;
    public minWidth: number;
    public exceptWidth?: number[];
    public multiselect: boolean;
    public filterScript: string;
    public defaultValue: string[];
    public letUsersAddTerms: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
        this.label = { 'pl-PL': 'Wybór' };
        this.showLabel = true;
        this.placeholder = { 'pl-PL': 'Wybierz...' };
        this.help = {};
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.dataSource = null;
        this.multiselect = false;
        this.filterScript = "";
        this.defaultValue = [];
        this.letUsersAddTerms = false;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): LogitoDictionaryEntry
    {
        return new LogitoDictionaryEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (!this.dataSource) this.errors.dataSource = ["[[[Wymagane jest wybranie źródła danych]]]"];

        return {
            [this.name]: this.errors,
        };
    }
}
