<template>
    <ideo-form-multiselect
        :modelValue="modelValue"
        :default-value="modelValue"
        :options="options"
        @search-change="getList"
        :track-by="'value'"
        :label="'value'"
        :open-direction="openDirection"
        :delete-button="false"
        @update:modelValue="onValueChanged"
        :autocomplete="true"
        :internal-search="true"
        name="taxCode"
    />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "@/helpers/Decorators";
import { Option } from "@/helpers/Interfaces";
import TaxLineService, { TaxCodeModel, TaxLineModel } from "@/modules/low-code/services/TaxLineService";

@Options({
    name: 'tax-code-multiselect',
    components: {},
    emits: ['update:modelValue', 'changed']
})
export default class PaymentFormsMultiselect extends Vue
{
    @Prop({ default: null }) public modelValue: any;
    @Prop({ default: '' }) public openDirection: string;
    @Prop({ default: [] }) public permittedTaxLines: TaxLineModel[];

    public options: TaxCodeModel[] = [];

    public async created(): Promise<void>
    {
        await this.getList();
    }

    protected async getList(): Promise<void>
    {
        let list = await TaxLineService.getTaxCodes();

        if (this.permittedTaxLines && this.permittedTaxLines.length > 0)
        {
            list = list.filter(x => this.permittedTaxLines.some(y => y.taxCode?.value == x.value));
        }

        this.options = list;
    }

    public onValueChanged(value: Option): void
    {
        this.$emit('update:modelValue', value);
        this.$emit('changed', value);
    }
}
</script>
