<template>
    <div class="d-flex align-items-center small text-formatter">
        <span v-if="original" class="original-value">{{ original }}</span>
        <span class="current-value">{{ current }} </span>
    </div>
</template>

<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { Options, Vue } from "vue-class-component";
import { Column } from "@/modules/core/common/services/GridService";

@Options({
    name: "TextFormatter",
})
export default class TextFormatter extends Vue
{
    @Prop({ default: null })
    public original: string | null;

    @Prop({ default: null })
    public current: string | null;

    @Prop({ default: null })
    public definition: Column;
}
</script>
