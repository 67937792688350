import { Plugin, getCurrentInstance } from "vue";
import PermissionsService from '@/modules/core/common/services/PermissionsService';

export interface Permissions
{
    purge(): void;
    get(keys: string[], throwError?: boolean): Promise<Record<string, boolean>>;
    all(keys: string[], throwError?: boolean): Promise<boolean>;
    any(keys: string[], throwError?: boolean): Promise<boolean>;
}

export const usePermissions = (): { $permissions: Permissions } =>
{
    const app = getCurrentInstance();

    return {
        $permissions: app.appContext.config.globalProperties.$permissions
    };
};

class PermissionsHelper implements Permissions
{
    private permissions: Record<string, boolean>;

    public constructor()
    {
        this.permissions = {};
    }

    public purge(): void
    {
        this.permissions = {};
    }

    public async get(keys: string[], throwError: boolean = false): Promise<Record<string, boolean>>
    {
        const request: string[] = [];
        const result: Record<string, boolean> = {};

        for (let i = 0; i < keys.length; i++)
        {
            const key = keys[i];

            result[key] = this.permissions[key] || false;

            if (!(key in this.permissions))
            {
                request.push(key);
            }
        }

        if (request.length > 0)
        {
            try
            {
                const response = await PermissionsService.checkPermissions(request);

                Object.keys(response).forEach(key =>
                {
                    this.permissions[key] = result[key] = response[key];
                });
            }
            catch (ex)
            {
                if (throwError)
                    throw ex;
            }
        }

        return result;
    }

    public async all(keys: string[], throwError: boolean = false): Promise<boolean>
    {
        const result = await this.get(keys, throwError);

        return Object.values(result).filter(value => value === false).length === 0;
    }

    public async any(keys: string[], throwError: boolean = false): Promise<boolean>
    {
        const result = await this.get(keys, throwError);

        return Object.values(result).filter(value => value === true).length > 0;
    }
}

const SitemapPlugin: Plugin =
{
    install(app, options)
    {
        app.config.globalProperties.$permissions = new PermissionsHelper();
    }
};

export default SitemapPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $permissions: Permissions;
    }
}
