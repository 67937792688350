<template>
    <div v-if="!loading">
        <ul class="d-flex list-unstyled mb-1">
            <li v-for="(item, index) in timetableHelper.years(year)" :key="index" class="month">
                <button
                    class="btn border-0 fs-5"
                    :class="{ 'text-primary': item.value === year }"
                    @click="changeYear(item.value, licence, sitemapId, filter, emitId)"
                >
                    {{ $filters.date(item.value, 'yyyy') }}
                </button>
            </li>
        </ul>

        <ul class="d-flex flex-wrap list-unstyled mb-3">
            <li v-for="(item, index) in timetableHelper.months" :key="index" class="month">
                <button
                    class="btn border-0 fs-6"
                    :class="{ 'text-primary': item.value === month }"
                    @click="changeMonth(item.value, licence, sitemapId, filter, emitId)"
                >
                    {{ item.text }}
                </button>
            </li>
        </ul>

        <ul class="d-flex list-unstyled legend mb-2">
            <li v-for="(status, index) in timetableHelper.statuses" :key="index" class="me-3">
                <div class="d-flex align-items-center">
                    <div :style="{ background: status.color }" class="legend__item"></div>
                    <span>{{ status.value }}</span>
                </div>
            </li>
        </ul>

        <transition name="fade" mode="out-in">
            <timetable-grid v-if="!loading" :licence="licence" :items="timetableHelper.items" :settings="settings" />
        </transition>
        <portal :to="`widget-footer-${config.publicId}`">
            <pagination :pager="pager" :pages-limit="pagesLimit" @change="getData()" v-if="showPager" />
            <div class="d-flex align-items-center">
                <pagination-info :pager="pager" class="ms-2" />
                <data-size :pager="pager" class="ms-2" direct-top="true" :custom-page-size="pageSize" @change="getData()" v-if="showPager" />
            </div>
        </portal>
    </div>
    <span v-else>{{ $t('[[[Brak danych]]]') }}</span>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { APIWidget } from "@/modules/core/home/services/HomeService";
import { DateTime } from 'luxon';
import TimetableDataHelper from '@/modules/low-code/components/helpers/TimetableDataHelper';
import TimetableGrid from '@/modules/low-code/components/TimetableGrid.vue';
import { Settings } from '@/plugins/viewstate';

@Options({
    name: 'TimetableWidget',
    components: {TimetableGrid}
})
export default class TimetableWidget extends Vue
{
    @Prop({ default: () => {} })
    public config: APIWidget;

    @Prop({ default: () => ({}) })
    public widgetConfig: Widget;

    @Prop({ default: () => ('') })
    public emitId: string;

    @Prop({ default: () => (Form.create({})) })
    public filter: any;

    public loading: boolean = true;
    public timetableHelper = new TimetableDataHelper();
    public licence: string;
    public sitemapId: string;
    public year: DateTime = DateTime.utc();
    public month: string = DateTime.utc().toFormat('MM');

    public pager: Pager = null;

    public settings: Settings =
        {
            start: DateTime.utc(this.year.year, +this.month).startOf('month').toISODate(),
            end: DateTime.utc(this.year.year, +this.month).endOf('month').toISODate(),
        };

    public get pagesLimit(): number
    {
        const result = this.widgetConfig.w <= 4 ? 1 : this.widgetConfig.w - 4 + 1;

        return result > 5 ? 5 : result;
    }

    public get showPager(): boolean
    {
        return this.config.showPager;
    }

    public get pageSize(): number
    {
        const sourceConfig = this.config?.sourceConfig as any;

        return sourceConfig?.pageSize ?? 5;
    }

    public created(): void
    {
        this.licence = this.config.licenseUrl;
        this.sitemapId = this.config.sitemapEntryDetails.publicId;

        this.pager = new Pager(1, this.pageSize ?? 5, 'Id');

        if (this.emitId)
        {
            this.$events.$on(this.emitId, async () =>
            {
                await this.getData(this.licence, this.sitemapId, this.filter, this.emitId);
            });
        }
    }

    public async mounted(): Promise<void>
    {
        await this.getData(this.licence, this.sitemapId, this.filter, this.emitId);
    }

    public async getData(licence: string, sitemapId: string, filter: any, emitId: string): Promise<void>
    {
        try
        {
            this.loading = true;
            await Promise.allSettled([this.timetableHelper.loadData(licence, sitemapId, filter, emitId, this.pager, this.settings)]);
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
        finally
        {
            this.loading = false;
        }
    }

    public changeYear = async (_year: DateTime, licence: string, sitemapId: string, filter: any, emitId: string) =>
    {
        if (this.year === _year) return;

        this.year = _year;
        this.settings.start = DateTime.utc(this.year.year, +this.month).startOf('month').toISODate();
        this.settings.end = DateTime.utc(this.year.year, +this.month).endOf('month').toISODate();

        await this.getData(licence, sitemapId, filter, emitId);
    };

    public changeMonth = async (_month: string, licence: string, sitemapId: string, filter: any, emitId: string) =>
    {
        if (this.month === _month) return;

        this.month = _month;
        this.settings.start = DateTime.utc(this.year.year, +this.month).startOf('month').toISODate();
        this.settings.end = DateTime.utc(this.year.year, +this.month).endOf('month').toISODate();

        await this.getData(licence, sitemapId, filter, emitId);
    };

    public async beforeUnmount(): Promise<void>
    {
        if (this.emitId)
            this.$events.$off(this.emitId);
    }
}
</script>
<style lang="scss" scoped>
.legend {
    .legend__item {
        width: 15px;
        min-width: 15px;
        height: 15px;
        margin-right: 6px;
    }
}
</style>
