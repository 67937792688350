<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';

defineOptions({
    name: 'field-name'
});

const props = defineProps({
  "form": null,
  "blueprint": null,
  "modelValue": null
});

const emit = defineEmits(["update:modelValue"]);

const buffer = ref('');
const unique = ref(true);

const check = (e: any): void =>
{
    unique.value = true;

    const value = e.target.value.replace(/[^A-Za-z0-9]/g, "");

    if (value && value.length > 0)
    {
        unique.value = props.form.schema.unique(value, props.blueprint);
    }
};

const triggerInput = (value: string): void =>
{
    emit('update:modelValue', value);
};

const update = (e: any): void =>
{
    if (buffer.value && buffer.value.length > 0 && unique.value)
    {
        triggerInput(buffer.value);
    }
};

const modelValue = computed(() => props.modelValue);

watch(modelValue, (value: string): void =>
{
    buffer.value = value;
}, { immediate: true });
</script>

<template>
    <div class="form-group">
        <input type="text" id="form-toolbox-name" class="form-control" spellcheck="false" :placeholder="$t('[[[Nazwa]]]')" v-model="buffer" @input="check" @blur="update">
        <small class="form-text text-danger" v-if="!buffer">{{ $t('[[[Podanie nazwy jest wymagane.]]]') }}</small>
        <small class="form-text text-danger" v-if="!unique">{{ $t('[[[Podana nazwa jest zajęta.]]]') }}</small>
    </div>
</template>

<style lang="scss" scoped>
.form-control {
    height: auto !important;
    padding: 0 0 10px !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid var(--bs-border-color) !important;
    border-radius: 0 !important;
    font-weight: 500 !important;
    font-size: 22.75px !important;
    color: var(--bs-body-color);

    &:focus {
        background: transparent !important;
        border: 1px solid transparent !important;
        border-bottom: 1px solid var(--bs-primary) !important;
        color: var(--bs-primary) !important;
    }
}

.dark-theme {
    .form-control {
        color: var(--bs-primary);
    }
}
</style>
