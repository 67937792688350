/* eslint-disable no-console */
import * as signalR from "@microsoft/signalr";
import { App, Plugin, getCurrentInstance, onUnmounted } from "vue";
import { Vue, createDecorator } from 'vue-class-component';
import { EventEmitter } from "events";
import { convertDates } from '@/helpers/Utils';
import { Auth } from "./auth";

const OPTIONS_KEY = '__channel_calls__';

export const Listen = (channel: string, method?: string): any =>
{
    return (target: Vue, propertyKey: string, descriptor: PropertyDescriptor) =>
    {
        const methodName = method || propertyKey;
        const decorator = createDecorator((options, propName) =>
        {
            options[OPTIONS_KEY] = options[OPTIONS_KEY] || [];
            options[OPTIONS_KEY].push(`Listen.${channel}.${methodName}.${propName}`);
        });

        decorator(target, propertyKey);
    };
};

export const Invoke = (channel: string, method?: string): any =>
{
    return (target: Vue, propertyKey: string) =>
    {
        const methodName = method || propertyKey;
        const decorator = createDecorator((options, propName) =>
        {
            options[OPTIONS_KEY] = options[OPTIONS_KEY] || [];
            options[OPTIONS_KEY].push(`Send.${channel}.${methodName}.${propName}`);

            options.methods = options.methods || {};

            options.methods[propName] = function(...args: any)
            {
                return (this as Vue).$channels.channel(channel).invoke(method || propertyKey, ...args).then(response =>
                {
                    return new Promise((resolve) =>
                    {
                        resolve(convertDates(response));
                    });
                });
            };
        });

        decorator(target, propertyKey);
    };
};

export interface SignalROptions
{
    endpoint: string;
}

class Channel extends EventEmitter
{
    private auth: Auth = null;
    private connection: signalR.HubConnection = null;
    public started = false;
    private name: string;

    public constructor(name: string, auth: Auth, options: SignalROptions)
    {
        super();

        this.auth = auth;
        this.name = name;
        this.connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Error)
            .withUrl(`${options.endpoint}/${name}`, {
                // accessTokenFactory: () => this.auth.token()
            })
            .build();

        this.connection
            .onclose((_: any) =>
            {
                console.log(`SignalR: Connection closed (${this.name}).`);

                // setTimeout(() =>
                // {
                //     if (this.started)
                //     {
                //         this.start();
                //     }
                // },
                // 5000);
            });
    }

    public start(): Channel
    {
        this.connection
            .start()
            .then(() => { this.emit('started'); console.log(`SignalR: Connection started (${this.name}).`); })
            .catch((err: any) => console.log(`Error while establishing connection (${this.name}).`, err));

        this.started = true;

        return this;
    }

    public stop(): Channel
    {
        this.connection.stop();
        this.started = false;

        return this;
    }

    public register(type: string, method: string, callback: any): void
    {
        if (type == 'Listen')
        {
            this.proxy.on(method, callback);
        }
    }

    public unregister(type: string, method: string, callback: any): void
    {
        if (type == 'Listen')
        {
            this.proxy.off(method, callback);
        }
    }

    public invoke(method: string, ...args: any[]): Promise<any>
    {
        return this.proxy.invoke(method, ...args);
    }

    private get proxy(): signalR.HubConnection
    {
        const channel = this;

        return new Proxy(this.connection, {
            get(connection: any, property: string)
            {
                return function(...args: any[])
                {
                    if (connection.state == signalR.HubConnectionState.Connected)
                    {
                        return connection[property](...args);
                    }

                    return new Promise((resolve) =>
                    {
                        return channel.once('started', () =>
                        {
                            const result = connection[property](...args);

                            if (result && result.then)
                            {
                                result.then((data: any) => resolve(data));
                            }
                            else if (result)
                            {
                                resolve(result);
                            }
                            else
                            {
                                resolve(null);
                            }
                        });
                    });
                };
            }
        });
    }
}

class SignalRHelper
{
    private auth: Auth = null;
    private options: SignalROptions = null;
    private channels: Record<string, Channel> = {};
    private components: Record<string, Vue[]> = {};

    public constructor(auth: Auth, options: SignalROptions)
    {
        this.auth = auth;
        this.options = options;
    }

    public channel(name: string): Channel
    {
        return this.channels[name] || this.connect(name);
    }

    public register(component: Vue|any): void
    {
        this.components = this.components || {};

        const calls = component.$options[OPTIONS_KEY] || [];

        calls.forEach((entry: string) =>
        {
            const [type, channel, method, callback] = entry.split('.');

            this.channel(channel).register(type, method, component[callback]);
        });
    }

    public unregister(component: Vue|any): void
    {
        this.components = this.components || {};

        const calls = component.$options[OPTIONS_KEY] || [];

        calls.forEach((entry: string) =>
        {
            const [type, channel, method, callback] = entry.split('.');

            this.channel(channel).unregister(type, method, component[callback]);
        });
    }

    private connect(name: string): Channel
    {
        return (this.channels[name] = new Channel(name, this.auth, this.options).start());
    }

    private disconnect(name: string): void
    {
        if (this.channels[name])
        {
            this.channels[name].stop();
            delete this.channels[name];
        }
    }
}

export const useSignalR = () =>
{
    return {
        channel
    };
};

type BasicCallback = (...args: any[]) => Promise<void>;
type TypedCallback = <T>(...args: any[]) => Promise<T>;

const channel = (channelName: string) =>
{
    const app = getCurrentInstance();
    const channels = app.appContext.config.globalProperties.$channels;

    return {
        on: new Proxy(channels, {
            set(channels, methodName, methodBody)
            {
                const channel = channels.channel(channelName);
                const method = (...args: any[]) =>
                {
                    Reflect.apply(methodBody, null, [...args]);
                };

                channel.register('Listen', methodName.toString(), method);

                if (!channel.started) channel.start();

                onUnmounted(() =>
                {
                    channel.unregister('Listen', methodName.toString(), method);
                });

                return true;
            }
        }) as unknown as Record<string, BasicCallback>,
        invoke: new Proxy(channels, {
            get(channels, method)
            {
                const channel = channels.channel(channelName);

                return (...args: any[]) => channel.invoke(method.toString(), ...args);
            }
        }) as unknown as Record<string, BasicCallback|TypedCallback>
    };
};

const SignalRPlugin: Plugin =
{
    install(app: App<any>, options: SignalROptions)
    {
        if (!options || !options.endpoint)
        {
            throw new Error("SignalROptions.endpoint must be set.");
        }

        const vue = app.config.globalProperties;
        const helper = new SignalRHelper(vue.$auth, options);

        app.mixin({
            async created()
            {
                helper.register(this);
            },
            async unmounted()
            {
                helper.unregister(this);
            }
        });

        vue.$channels = helper;
    }
};

export default SignalRPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $channels: SignalRHelper;
    }
}
