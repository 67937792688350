<script lang="ts" setup>
import { useClipboard } from '@vueuse/core';

defineOptions({
    name: 'field-name'
});

defineProps({
  "modelValue": null
});

const { copy, copied } = useClipboard();
</script>

<template>
    <div class="form-group mb-0">
        <div class="header">
            <ideo-tooltip
                :tooltip="!copied ? `${$t('[[[Kopiuj pole]]]')}` : `${$t('[[[Pole skopiowane]]]')}`"
                position="right"
            >
                <span class="header-text" @click="copy(modelValue)">{{ modelValue }}</span>
            </ideo-tooltip>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.header {
    height: 46px !important;
    padding: 0 0 10px !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid var(--bs-border-color) !important;
    border-radius: 0 !important;
    display: flex;
}

.header-text {
    font-weight: 500 !important;
    font-size: 22.75px !important;
    line-height: 1.375;
    color: var(--bs-body-color);
    padding-right: 4px;
    cursor: pointer;
}
</style>
