<template>
    <layout>
        <template #code>503</template>
        <template #header>System maintenance</template>
        <template #description>The server is currently unable to handle the request due to a maintenance of the server.</template>
    </layout>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ErrorLayout from '@/views/layouts/ErrorLayout.vue';

@Options({
    name: 'error-page-503',
    components: {
        'layout': ErrorLayout
    }
})
export default class ErrorPage503 extends Vue
{
}
</script>
