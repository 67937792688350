<script lang="ts" setup>
import { ref, watch } from 'vue';
import FormsService from '@/modules/studio/forms/services/FormsService';

const props = defineProps({
  "formId": null,
  "formOrigin": null
});
const emit = defineEmits(["update"]);

const visible = ref(false);
const formVersion = ref(0);

const update = (): void =>
{
    emit('update', formVersion.value);
};

watch(() => props.formId, async () =>
{
    visible.value = false;

    try
    {
        const result = await FormsService.fetchPublished(props.formOrigin);

        if (result && result.id != props.formId)
        {
            formVersion.value = result.id;
            visible.value = true;
        }
    }
    catch
    {
        visible.value = false;
    }
});
</script>

<template>
    <div class="alert alert-warning d-flex align-items-center" v-if="visible">
        <div>{{ $t('[[[Formularz bazuje na nieaktualnej wersji definicji.]]]') }}</div>
        <div class="ms-auto">
            <button class="btn btn-primary" @click.prevent="update">{{ $t('[[[Zaktualizuj formularz]]]') }}</button>
        </div>
    </div>
</template>
