import { App, Plugin } from 'vue';
import { Router } from 'vue-router';
import { FormType } from '@/helpers/Form';
import { Columns } from '@/helpers/Interfaces';
import Pager, { instanceOfPagerContract } from '@/helpers/Pager';
import { useViewStateStore, ViewStateStore } from '@/store/viewstate';
import { FilterState } from '@/modules/core/common/services/FilterService';

let currentInstance: ViewState | null = null;

export const restoreState = (key: string, filter?: FormType<any>, pager?: Pager, columns?: Columns, settings?: Settings): void =>
{
    return currentInstance?.restore(key, filter, pager, columns, settings);
};

export const persistState = (key: string, filter?: FormType<any>, pager?: Pager, columns?: Columns, settings?: Settings): void =>
{
    return currentInstance?.persist(key, filter, pager, columns, settings);
};

export const setFilterState = (state: FilterState): void =>
{
    return currentInstance?.setFilterState(state);
};

export const getFilterState = (): FilterState =>
{
    return currentInstance?.getFilterState();
};

export const setColumnsState = (state: Columns): void =>
{
    return currentInstance?.setColumnsState(state);
};

export const getColumnsState = (): Columns =>
{
    return currentInstance?.getColumnsState();
};

export const setSettings = (settings: Settings): void =>
{
    return currentInstance?.setSettings(settings);
};

export const getSettings = (): Settings =>
{
    return currentInstance?.getSettings();
};

interface ViewState
{
    restore(key: string, filter?: FormType<any>, pager?: Pager, columns?: Columns, settings?: any): void;
    persist(key: string, filter?: FormType<any>, pager?: Pager, columns?: Columns, settings?: any): void;
    setFilterState(state: FilterState): void;
    getFilterState(): FilterState;
    setColumnsState(state: Columns): void;
    getColumnsState(): Columns;
    setSettings(settings: any): void;
    getSettings(): any;
}

function isObjectEmpty(object: any)
{
    for (const prop in object)
    {
        if (object.hasOwnProperty(prop))
        {
            return false;
        }
    }

    return true;
}

export interface Settings {
    start: string;
    end: string;
    view?: string;
    isActiveWorkHours?: boolean;
}

export class ViewStateOptions
{
    public router: Router;
}

class ViewStateHelper implements ViewState
{
    private options: ViewStateOptions;
    private store: ViewStateStore;

    public constructor(app: App<any>, options: ViewStateOptions, store: ViewStateStore)
    {
        this.options = options;
        this.store = store;
    }

    private routeKey(): string
    {
        const currentRoute = this.options.router.currentRoute.value;
        const currentRouteName = currentRoute.name.toString();
        const currentRouteParams = Object.values(currentRoute.params).join('-');

        return `${currentRouteName}${currentRouteParams ? `-${currentRouteParams}` : ''}`;
    }

    public restore(key: string, filter?: FormType<any>, pager?: Pager, columns?: Columns, settings?: any): void
    {
        this.store.key = key || this.routeKey();

        const filterState = this.store.getFilters(this.store.key);
        const pagerState = this.store.getPager(this.store.key);
        const columnsState = this.store.getColumns(this.store.key);
        const settingsState = this.store.getSettings(this.store.key);

        if (filter != undefined)
        {
            filter.withData(filterState.form || JSON.parse(filterState.activeTemplate?.contentJson || '{}'));
        }

        if (pager != undefined && instanceOfPagerContract(pagerState))
        {
            pager.apply(pagerState);
        }

        if (columns != undefined)
        {
            if (!isObjectEmpty(columnsState.visible))
                columns.visible = columnsState.visible;

            if (!isObjectEmpty(columnsState.positions))
                columns.positions = columnsState.positions;
        }

        if (settings != undefined)
        {
            settings.view = settingsState.view;
            settings.start = settingsState.start;
            settings.end = settingsState.end;
            settings.isActiveWorkHours = settingsState.isActiveWorkHours;
        }
    }

    public persist(key: string, filter?: FormType<any>, pager?: Pager, columns?: Columns, settings?: any): void
    {
        this.store.key = key || this.routeKey();

        if (filter != undefined)
        {
            const filterState = this.store.getFilters(this.store.key);

            this.store.setFilters(this.store.key, {
                activeTemplate: filterState.activeTemplate,
                visible: filterState.visible,
                form: filter.data()
            });
        }

        if (pager != undefined)
        {
            this.store.setPager(this.store.key, pager.data());
        }

        if (columns != undefined)
        {
            this.store.setColumns(this.store.key, JSON.parse(JSON.stringify(columns)));
        }

        if (settings != undefined)
        {
            this.store.setSettings(this.store.key, settings);
        }
    }

    public getFilterState(): FilterState
    {
        return this.store.getFilters(this.store.key || this.routeKey());
    }

    public setFilterState(state: FilterState): void
    {
        this.store.setFilters(this.store.key || this.routeKey(), state);
    }

    public getColumnsState(): Columns
    {
        return this.store.getColumns(this.store.key || this.routeKey());
    }

    public setColumnsState(state: Columns): void
    {
        this.store.setColumns(this.store.key || this.routeKey(), JSON.parse(JSON.stringify(state)));
    }

    public getSettings(): Settings
    {
        return this.store.getSettings(this.store.key || this.routeKey());
    }

    public setSettings(settings: Settings): void
    {
        this.store.setSettings(this.store.key || this.routeKey(), settings);
    }
}

const ViewStatePlugin: Plugin =
{
    install(app, options)
    {
        if (!options || !options.router)
        {
            throw new Error("ViewStateOptions.router must be set.");
        }

        currentInstance = new ViewStateHelper(app, options, useViewStateStore());
    }
};

export default ViewStatePlugin;
