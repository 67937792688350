<script setup lang="ts">
import { ref, computed, watch, Ref, inject, onBeforeUnmount } from 'vue';
import { useEvents } from '@/plugins/events';
import { useLocalization } from '@/plugins/localization';
import Pager from '@/helpers/Pager';
import { kebabCase } from 'lodash';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Definition as ContractorDefinition } from '../logito-contractor';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { FormEntry } from '../form';
import { RelatedDocumentType, RelatedDocumentEntry, instaceOfRelatedDocumentEntry, Definition as RelatedDocumentDefinition } from '.';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import FormsService, { Contractor, ModuleOption } from '@/modules/studio/forms/services/FormsService';
import AutocompleteService from '@/modules/core/common/services/AutocompleteService';
import GridService, { IMetaModel } from '@/modules/core/common/services/GridService';
import DocumentsListModal from './modals/DocumentsListModal.vue';
import properties from '../../properties';

defineOptions({
    name: 'related-document-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $events } = useEvents();
const { $t } = useLocalization();

const entryData = ref(new RelatedDocumentEntry()) as Ref<RelatedDocumentEntry>;
const documentsListModalRef = ref<InstanceType<typeof DocumentsListModal | null>>(null);
const reloadKey = ref(0);
const viewPermissions = ref<Record<string, IMetaModel>>({});
const selectedModules = ref<ModuleOption[]>([]);
const moduleOptions = ref<ModuleOption[]>([]);
const options = ref<Record<string, any>[]>([]);
const moduleBaseUrl = ref<string | null>(null);
const filters = ref<any>(null);
const fieldOptions = ref<Record<string, string>[]>([]);
const importFilterField = ref<KeyValuePair | null>(null);

entryData.value = props.form.document.initEntry(
    props.blueprint,
    entryData.value,
    instaceOfRelatedDocumentEntry,
    props.index
);

const level = inject<number>('level', 0);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        if (!blueprint.value.multiselect)
        {
            return value?.[0] ?? null;
        }

        return value;
    },
    set(value: KeyValuePair[] | KeyValuePair)
    {
        entryData.value.data = Array.isArray(value) ? value : [value];
    },
});

const blueprint = computed(() => props.blueprint);
const required = computed(() => props.form.expressions.required(props.blueprint));
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));
const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const design = computed(() => props.form.designMode());
const endpoint = computed(() =>
    !design.value
        ? `simple-dictionary/${props.entry.module}/form/${props.entry.formId}/${props.entry.actionName}/${blueprint.value.name}`
        : null
);

const scope = computed(() =>
{
    const result: Record<string, string> = {};

    if (blueprint.value.hasFilterByContractor)
    {
        result[blueprint.value.contractorFilter.key] = (
            props.entry[blueprint.value.contractorFilter.key]?.data as KeyValuePair[]
        )?.first()?.key;
    }

    if (blueprint.value.hasFilterByRelatedDocuments)
    {
        result[blueprint.value.relatedDocumentsFilter] = (
            props.entry[blueprint.value.relatedDocumentsFilter]?.data as KeyValuePair[]
        )?.first()?.key;
    }

    return result;
});

const actionsLength = computed(() => Object.entries(viewPermissions.value ?? {}).length);
const isFilters = computed(() => selectedModules.value?.length == 1);

const contractorComponents = computed(() =>
    props.form.schema.components(ContractorDefinition.type).map((p) => ({
        key: p.name,
        value: `${props.form.localization.translate((p as HasLabel).label)} (${p.name})`,
    }))
);

const contractorFilter = computed(() => ({
    key: contractorComponents.value.find((p) => p.key == blueprint.value.contractorFilter.key) ?? null,
    value: fieldOptions.value.find((p) => p.key == blueprint.value.contractorFilter.value) ?? null,
}));


const relatedDocumentComponents = computed(() =>
    props.form.schema.components(RelatedDocumentDefinition.type, blueprint.value).map((p) => ({
        key: p.name,
        value: `${props.form.localization.translate((p as HasLabel).label)} (${p.name})`,
    }))
);

const relatedDocumentsFilter = computed(() =>
    relatedDocumentComponents.value.find((p) => p.key == blueprint.value.relatedDocumentsFilter) ?? null
);

const updateContractorFilter = (type: string, value: KeyValuePair): void =>
{
    if (type == 'key') blueprint.value.contractorFilter.key = value?.key ?? null;
    else if (type == 'value') blueprint.value.contractorFilter.value = value?.key ?? null;
};

const updateRelatedDocumentsFilter = (value: KeyValuePair): void =>
{
    blueprint.value.relatedDocumentsFilter = value?.key ?? null;
};

const isContractors = (contractor: Array<Contractor>) => contractor?.length > 0;
const contractorText = (contractor: Array<Contractor>) =>
    `${$t('[[[Kontrahent]]]')}: ${contractor.map((item: Contractor) => item.name).join(', ')}`;

const openModal = () =>
{
    documentsListModalRef.value.showModal();
};

const fetchModuleOptions = async () =>
{
    const response = (await FormsService.getModuleOptions(new Pager(1, 999))).items.map((p) => p.result);

    moduleOptions.value = response;
};

const fetchOptionsSelect = async () =>
{
    if (design.value) return;

    const response = await AutocompleteService.fetchOptionsByCustomEndpoint(
        endpoint.value,
        filters.value,
        new Pager(1, 999, '', 'ASC')
    );

    if (!response) return;

    options.value = response.items.map((item) => item.result);
};

const fetchModuleBaseUrl = async () =>
{
    const response =
        blueprint.value.modules?.length == 1
            ? await FormsService.getModuleBaseUrl(Number(blueprint.value.modules[0]))
            : null;

    moduleBaseUrl.value = response;

    fetchActions();
};

const fetchActions = async () =>
{
    if (!moduleBaseUrl.value)
    {
        viewPermissions.value = {};

        return;
    }

    const response = await GridService.fetchActions(moduleBaseUrl.value);

    viewPermissions.value = response ?? {};
};

const fetchValueOptions = async () =>
{
    const response = await AutocompleteService.fetchOptionsByCustomEndpoint(
        `admin/forms/module-fields/${kebabCase(selectedModules.value[0]?.key)}`,
        null,
        new Pager(1, 999, '', 'ASC')
    );

    if (!response) return;

    fieldOptions.value = response.items
        .map((item) => item.result)
        .map((p) => ({ ...p, value: `${p.value} (${p.key})` }));
};

const onDocumentChanged = (option: any) =>
{
    if (blueprint.value.modules?.length != 1 || !Object.keys(blueprint.value.fieldMappings ?? {}).length) return;

    for (const item in blueprint.value.fieldMappings)
    {
        const blueprint = props.form.schema.find(item) as EntryFactory<any>;
        const parent = props.form.schema.parent(blueprint);

        if (!blueprint) continue;

        const data = option.additionalFields?.[props.blueprint.fieldMappings?.[item]]?.data;

        if (parent?.type === 'table')
        {
            // TODO: Do not mutate props
            props.entry[parent.name].data[props.index][blueprint.name] = blueprint.createEntry(data); // eslint-disable-line

            continue;
        }

        // TODO: Do not mutate props
        props.entry[item] = blueprint.createEntry(data); // eslint-disable-line
    }

    $events.$emit('form-reload');
};

const onModalDocumentSelected = async (publicId: string) =>
{
    const response = await AutocompleteService.fetchOptionsByCustomEndpoint(
        endpoint.value,
        null,
        new Pager(1),
        { key: publicId }
    );

    if (props.blueprint.multiselect)
    {
        if (!value.value) value.value = [];

        (value.value as KeyValuePair[]).push(response.items[0].result as any);
    }
    else
    {
        value.value = response.items[0].result as any;
    }

    onDocumentChanged(value.value);
};

const init = async () =>
{
    if (design.value)
    {
        await fetchModuleOptions();

        selectedModules.value = blueprint.value.modules?.map?.((p) => moduleOptions.value.find((x) => x.key == p));
        filters.value = blueprint.value.filtersJson ? JSON.parse(blueprint.value.filtersJson) : null;

        if (selectedModules.value?.length == 1) await fetchValueOptions();

        importFilterField.value = fieldOptions.value.find((p) => p.key == blueprint.value.importFilterField) as any;
    }

    await fetchModuleBaseUrl();

    $events.$on(`update-related-documents-${level ?? 0}`, async () =>
    {
        await fetchOptionsSelect();
    });

    $events.$on('reload-related-documents', async () =>
    {
        await fetchOptionsSelect();

        const option = options.value.find((p) => p.key == (value.value as KeyValuePair)?.key);

        if (!option) return;

        onDocumentChanged(option);
    });
};

onBeforeUnmount(() =>
{
    $events.$off('reload-related-documents');
});

watch(() => blueprint.value.modules, () => fetchModuleBaseUrl());
watch(() => importFilterField.value, () => blueprint.value.importFilterField = importFilterField.value?.key);
watch(() => filters.value, () => blueprint.value.filtersJson = filters.value ? JSON.stringify(filters.value) : null, { deep: true });
watch(() => selectedModules.value, async (newValue: any[]) =>
{
    blueprint.value.modules = newValue.map((p) => p.key);

    if (newValue.length == 1)
    {
        if (blueprint.value.contractorFilter.value == 'Contractor')
            blueprint.value.contractorFilter.value = null;

        await fetchValueOptions();
    }
    else updateContractorFilter('value', { key: 'Contractor', value: $t('[[[Kontrahent]]]') });
});

init();
</script>

<template>
    <form-component-wrapper class="relatedDocument-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <div class="d-flex">
                    <ideo-select
                        v-model="value"
                        :disabled="readonly"
                        :id="blueprint.id"
                        :placeholder="placeholder"
                        :endpoint="endpoint"
                        :multiselect="blueprint.multiselect"
                        :deselect="actionsLength"
                        :clear-button="!actionsLength"
                        :reload-key="reloadKey"
                        :narrow-options="scope"
                        :custom-classes="{ 'border-radius-right-none': actionsLength }"
                        class="flex-fill"
                        @update:modelValue="onDocumentChanged"
                    >
                        <template #option="{ option }">
                            <div class="d-flex flex-column">
                                <strong v-if="option.value">{{ option.value }}</strong>
                                <small v-if="isContractors(option.contractor)">
                                    {{ contractorText(option.contractor) }}
                                </small>
                                <small v-if="option.module">{{ $t('[[[Typ dokumentu]]]') }}: {{ option.module }}</small>
                            </div>
                        </template>
                    </ideo-select>
                    <view-action-button
                        v-show="actionsLength"
                        :permissions="viewPermissions"
                        :licence="moduleBaseUrl"
                        :custom-classes="{ 'border-radius-left-none m-0': actionsLength }"
                        :only-icons="true"
                        :is-modal-mode="true"
                        :level="level ?? 0"
                        :blueprint="blueprint"
                    />
                    <ideo-button variant="secondary" icon="fa-solid fa-search" class="ms-2" @click="openModal" />
                </div>
                <form-error-message :entry="entryData" name="values" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
            <documents-list-modal
                ref="documentsListModalRef"
                :blueprint="blueprint"
                :entry="entry"
                :module-licence="moduleBaseUrl"
                @document-selected="onModalDocumentSelected"
            />
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-checkbox v-model="blueprint.multiselect" :label="$t('[[[Wielokrotny wybór]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <ideo-form-group :label="$t('[[[Moduły]]]')">
                <template #default>
                    <ideo-select v-model="selectedModules" :options="moduleOptions" :multiselect="true" />
                </template>
                <template #description>
                    {{ $t('[[[Wybierz moduł aby ograniczyć wiązanie do wybranego typu dokumentu]]]') }}
                </template>
            </ideo-form-group>
            <field-filters
                v-if="isFilters"
                v-model="filters"
                :selected-modules="selectedModules"
                :label="$t('[[[Filtry]]]')"
            />
            <field-checkbox
                v-model="blueprint.onlyUnfinished"
                :label="$t('[[[Pozwalaj wiązać tylko niezakończone dokumenty]]]')"
            />
            <ideo-form-group
                v-if="isFilters && parent.type == 'table'"
                :label="$t('[[[Pole do filtrowania przy imporcie tabeli]]]')"
            >
                <ideo-select v-model="importFilterField" :options="fieldOptions" />
            </ideo-form-group>
            <field-checkbox
                v-model="blueprint.hasFilterByContractor"
                :label="$t('[[[Zawęż listę dokumentów na podstawie kontrahenta]]]')"
            />
            <ideo-form-group
                v-if="blueprint.hasFilterByContractor"
                :invalid-feedback="$t(form.schema.errorMessage(blueprint, 'contractorFilter'))"
            >
                <div class="d-flex gap-1 flex-fill">
                    <ideo-select
                        :model-value="contractorFilter.key"
                        :clear-button="false"
                        :deselect="true"
                        :options="contractorComponents"
                        class="w-50"
                        @update:model-value="updateContractorFilter('key', $event)"
                    />
                    <ideo-select
                        v-if="selectedModules.length === 1"
                        :model-value="contractorFilter.value"
                        :clear-button="false"
                        :deselect="true"
                        :options="fieldOptions"
                        class="w-50"
                        @update:model-value="updateContractorFilter('value', $event)"
                    />
                    <ideo-form-input v-else :model-value="$t('[[[Kontrahent]]]')" class="w-50" :disabled="true" />
                </div>
            </ideo-form-group>
            <field-checkbox
                v-model="blueprint.hasFilterByRelatedDocuments"
                :label="$t('[[[Zawęź listę dokumentów do dokumentów powiązanych]]]')"
            />
            <ideo-form-group
                v-if="blueprint.hasFilterByRelatedDocuments"
                :invalid-feedback="$t(form.schema.errorMessage(blueprint, 'relatedDocumentsFilter'))"
            >
                <ideo-select
                    :model-value="relatedDocumentsFilter"
                    :options="relatedDocumentComponents"
                    @update:model-value="updateRelatedDocumentsFilter"
                />
            </ideo-form-group>
            <field-map
                v-if="isFilters"
                v-model="blueprint.fieldMappings"
                :label="$t('[[[Pola mapowane]]]')"
                :form="form"
                :blueprint="blueprint"
                :options="fieldOptions"
                :selected-modules="selectedModules"
            />
            <ideo-form-group :label="$t('[[[Wyświetlaj tylko dokumenty których użytkownik:]]]')">
                <ideo-form-checkbox v-model="blueprint.allowAuthor">
                    {{ $t('[[[Jest autorem]]]') }}
                </ideo-form-checkbox>
                <ideo-form-checkbox v-model="blueprint.allowTargets">
                    {{ $t('[[[Realizuje obecnie zadanie w obiegu dokumentu]]]') }}
                </ideo-form-checkbox>
                <ideo-form-checkbox v-model="blueprint.allowAll">
                    {{ $t('[[[Ma dostęp do dokumentu]]]') }}
                </ideo-form-checkbox>
            </ideo-form-group>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>
