<template>
    <div class="filter-dropdown-object">
        <button type="button" :class="toggleClasses" class="btn btn-secondary me-0 w-100 text-nowrap" @click.stop="clickHandler">
            <span v-if="label && modelValue" class="me-1">{{ label }}:</span>
            <span v-if="!!modelValue && type === 'date'">
                {{ $filters.date(modelValue, 'dd-MM-yyyy', 'local') }}
            </span>
            <span v-if="!!modelValue && type === 'datetime'">
                {{ $filters.date(modelValue, 'dd-MM-yyyy HH:mm', 'local') }}
            </span>
            <span v-if="!!modelValue && type === 'time'">
                {{ $filters.date(modelValue, 'HH:mm', 'local') }}
            </span>
            <span v-if="!modelValue">
                {{ placeholder }}
            </span>
            <div>
                <span class="fas fa-calendar-alt ms-2"></span>
                <span class="fas fa-times ms-2" v-if="!!modelValue" @click.stop="clearDate"></span>
            </div>
        </button>

        <teleport to="body">
            <!-- <portal to="body"> -->
            <div class="date-modals" style="z-index: 999999999;">
                <transition-group name="vdatetime-fade" tag="div">
                    <div key="overlay" v-if="isOpen && !hideBackdrop" class="vdatetime-overlay" @click.self="clickOutside"></div>
                    <datetime-popup
                        v-if="isOpen"
                        :type="type"
                        :datetime="popupDate"
                        :phrases="phrases"
                        :use12-hour="use12Hour"
                        :hour-step="hourStep"
                        :minute-step="minuteStep"
                        :min-datetime="popupMinDatetime"
                        :max-datetime="popupMaxDatetime"
                        @confirm="confirm"
                        @cancel="cancel"
                        :auto="false"
                        :week-start="weekStart"
                        :flow="flow"
                        :title="title"
                    >
                        <template #button-cancel__internal="scope">
                            <slot name="button-cancel" :step="scope.step">{{ phrases.cancel }}</slot>
                        </template>
                        <template #button-confirm__internal="scope">
                            <slot name="button-confirm" :step="scope.step">{{ phrases.ok }}</slot>
                        </template>
                    </datetime-popup>
                </transition-group>
            </div>
            <!-- </portal> -->
        </teleport>
    </div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { DateTime } from 'luxon';
import { Options, Vue } from 'vue-class-component';
import { datetimeFromISO, weekStart } from '@/components/datetime/util';
import DatetimePopup from '@/components/datetime/DatetimePopup.vue';
import { $t } from '@/plugins/localization';

@Options({
    name: 'filters-datetime',
    emits: ['update:modelValue', 'close', 'open'],
    components: { DatetimePopup }
})
export default class FiltersDatePicker extends Vue
{
    public isOpen: boolean = false;
    public title: string = "";
    public auto: boolean = true;
    public weekStart: number = weekStart();
    public hideBackdrop: boolean = false;
    public backdropClick: boolean = true;
    public phrases: Record<string, string> = {
        cancel: $t('[[[Anuluj]]]'),
        ok: $t('[[[Ok]]]')
    };

    // props
    @Prop({ default: null })
    public modelValue: string;

    @Prop({ default: 'date' })
    public type: string;

    @Prop({ default: 'local' })
    public zone: string;

    @Prop({ default: null })
    public minDatetime: string;

    @Prop({ default: null })
    public maxDatetime: string;

    @Prop({ default: 1 })
    public minuteStep: number;

    @Prop({ default: 1 })
    public hourStep: number;

    @Prop({ default: false })
    public use12Hour: boolean;

    @Prop({ default: (): any[] => [] })
    public flow: Array<any>;

    @Prop({ default: "" })
    public placeholder: string;

    @Prop({ default: "" })
    public label: string;

    // getters and computed
    public get datetime(): DateTime
    {
        return datetimeFromISO(this.modelValue);
    }

    public get popupMinDatetime(): any
    {
        return this.minDatetime ? DateTime.fromISO(this.minDatetime).setZone(this.zone) : null;
    }

    public get popupMaxDatetime(): any
    {
        return this.maxDatetime ? DateTime.fromISO(this.maxDatetime).setZone(this.zone) : null;
    }

    public get popupDate(): DateTime
    {
        return this.datetime ? this.datetime.setZone(this.zone) : this.newPopupDatetime();
    }

    public newPopupDatetime(): DateTime
    {
        let datetime = DateTime.utc().setZone(this.zone).set({ second: 0, millisecond: 0 });

        if (this.popupMinDatetime && datetime < this.popupMinDatetime)
        {
            datetime = this.popupMinDatetime.set({ seconds: 0, milliseconds: 0 });
        }

        if (this.popupMaxDatetime && datetime > this.popupMaxDatetime)
        {
            datetime = this.popupMaxDatetime.set({ seconds: 0, milliseconds: 0 });
        }

        if (this.minuteStep === 1)
        {
            return datetime;
        }

        const roundedMinute = Math.round(datetime.minute / this.minuteStep) * this.minuteStep;

        if (roundedMinute === 60)
        {
            return datetime.plus({ hours: 1 }).set({ minute: 0 });
        }

        return datetime.set({ minute: roundedMinute });
    }

    public get toggleClasses(): Record<string, boolean>
    {
        return {
            'filter-button': true,
            'bg-primary': !!this.modelValue || this.isOpen
        };
    }

    public get menuClasses(): Record<string, boolean>
    {
        return {
            'p-0': true,
            'filter-dropdown-object__dropdown': true,
            'border-0': true
        };
    }

    public get containerClasses(): Record<string, boolean>
    {
        return {
            'filter-container': true
        };
    }

    public close(): void
    {
        this.isOpen = false;

        // const nav = document.querySelector('.card-header-content') as HTMLDivElement;

        // nav.style.zIndex = '998';
        this.$emit('close');
    }

    public confirm(datetime: DateTime): void
    {
        const date = datetime.toUTC();

        this.$emit("update:modelValue", date ? date.toISO() : "");
        this.close();
    }

    public cancel(): void
    {
        this.close();
    }

    public clickHandler(): void
    {
        if (this.isOpen)
        {
            this.close();
        }
        else
        {
            this.isOpen = true;

            // const nav = document.querySelector('.card-header-content') as HTMLDivElement;

            // nav.style.zIndex = '999999';
        }
    }

    public clickOutside(): void
    {
        if (this.backdropClick === true) { this.cancel(); }
    }

    public clearDate(): void
    {
        this.$emit("update:modelValue", null);
        this.$events.$emit('refetchData');
    }
}
</script>
